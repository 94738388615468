import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiUser } from 'react-icons/fi';
import md5 from 'blueimp-md5';
import { startOfDay } from 'date-fns';
import groupBy from 'lodash/groupBy';
import { STATUSES } from '../../shared/purchaseOrders/index.js';
import { useAccount } from '../AccountProvider.jsx';
import { toDate } from '../Utils/dates.js';

const MESSAGES = {
  skus: {
    created: 'Product created',
    'bulk-updated': 'Product updated in bulk',
    'lead-time-updated': 'Lead time updated',
    'default-name-selected': 'Default name selected',
    default: 'Product updated',
  },
  'purchase-orders': {
    'status-updated': 'Status updated to ',
    drafted: 'Draft created',
    'removed-item': 'Item removed',
    submitted: 'Purchase order submitted',
    'transfer-created': 'Shipment created',
    'transfer-updated': 'Shipment updated',
    'transfer-removed': 'Shipment removed',
    updated: 'Purchase order updated',
    default: 'Purchase order updated',
  },
};

const auditTrailMessage = ({ logType, logContext }) => {
  const [type, action] = logType.split('.');
  if (MESSAGES[type][action]) {
    const message = MESSAGES[type][action];
    if (type === 'purchase-orders' && action === 'status-updated') {
      return `${message}${STATUSES[logContext.status]}`;
    }
    return message;
  }
  return MESSAGES[type].default;
};

const ImageWithFallback = ({ user = {} }) => {
  const gravatar = `https://www.gravatar.com/avatar/${md5(user.email)}?d=404`;

  const [imgSrc, setImgSrc] = useState(gravatar);

  if (!imgSrc) {
    return <FiUser className="h-10 w-10 rounded-full bg-gray-50 stroke-gray-75 p-2" size={38} />;
  }

  const onError = () => setImgSrc(false);

  return (
    <img className="h-10 w-10 rounded-full" alt={user.fullName} src={imgSrc} onError={onError} />
  );
};

ImageWithFallback.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

// The AuditTrailRow component loops through all logs for the given day
const AuditTrailRow = ({ logs = [] }) => {
  const { user: accountUser } = useAccount();
  return (
    <ul className="mt-4">
      {logs.map(({ _id, logType, user, logContext, createdAt }, index) => (
        <li key={_id}>
          {index !== 0 && <div className="my-1 ml-[18px] h-8 w-0.5 bg-purple-100" />}
          <div className="flex items-center">
            <ImageWithFallback user={user} />
            <div className="ml-4">
              <span className="font-bold">{auditTrailMessage({ logType, logContext })}</span>&nbsp;
              by&nbsp;
              {accountUser.email === user.email ? 'you' : user.fullName}
              <br />
              <span className="text-midnight-75">
                {toDate(createdAt).toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

AuditTrailRow.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      logType: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      user: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
      logContext: PropTypes.shape({}),
    }).isRequired,
  ).isRequired,
};

const AuditTrailWidget = ({ trailLogs }) => {
  // This gives us an object with keys as dates and values as an array of logs for that date
  const groupedLogsByDay = groupBy(trailLogs, ({ createdAt }) => startOfDay(toDate(createdAt)));

  // This gives us an array of the days containing logs, sorted by most recent
  const sortedLogDays = Object.keys(groupedLogsByDay).sort((a, b) => toDate(b) - toDate(a));

  // This sorts the logs for each day, because we want the most recent logs at the top
  sortedLogDays.forEach((day) => {
    groupedLogsByDay[day] = groupedLogsByDay[day].sort(
      (a, b) => toDate(b.createdAt) - toDate(a.createdAt),
    );
  });

  return (
    <div className="col-span-1">
      <div className="relative bg-white px-7 pb-8 pt-6 text-midnight-100">
        <h1 className="mb-4 font-bold">Activity</h1>
        <div className="max-h-[30rem] space-y-6 overflow-auto pb-6 text-sm">
          {sortedLogDays.map((day) => (
            <span key={day}>
              <h2 className="mt-6 font-bold uppercase text-gray-100">
                {toDate(day).toLocaleDateString(navigator.language, {
                  day: 'numeric',
                  month: 'short',
                })}
              </h2>
              <AuditTrailRow logs={groupedLogsByDay[day]} />
            </span>
          ))}
        </div>
        <div className="absolute bottom-0 left-0 h-16 w-full bg-gradient-to-b from-transparent to-white" />
      </div>
    </div>
  );
};

AuditTrailWidget.propTypes = {
  trailLogs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      logType: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      user: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
    }).isRequired,
  ).isRequired,
};

export default AuditTrailWidget;
