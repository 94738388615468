import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiChevronDown } from 'react-icons/fi';
import cn from 'classnames';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { isSameDay, parseISO } from 'date-fns';
import { marketingEventTypes } from '../../../shared/marketingEventTypes.js';
import { useAccount } from '../../AccountProvider.jsx';
import confirm from '../../Components/ConfirmDialog.jsx';
import DeleteButton from '../../Components/DeleteButton.jsx';
import { useDemo } from '../../Components/DemoProvider.jsx';
import PageHeader from '../../Components/PageHeader.jsx';
import { useDocumentTitle } from '../../Hooks/index.js';
import AddEventPopupButton, { MARKETING_ACTIVITIES } from './EventPopup.jsx';

const REMOVE_MARKETING_ACTIVITY = gql`
  mutation removeMarketingActivity($_id: String!) {
    removeMarketingActivity(_id: $_id) {
      _id
      title
    }
  }
`;

const formatEventDate = (date) =>
  new Date(date).toLocaleDateString(navigator.language, {
    day: 'numeric',
    weekday: 'short',
  });

const Index = ({ isPopupOpen }) => {
  useDocumentTitle('Marketing Events');
  const { isDemo } = useDemo();
  const { user } = useAccount();

  const [eventSkuListOpen, setEventSkuListOpen] = useState(null);

  const { data } = useQuery(MARKETING_ACTIVITIES);

  const [removeMarketingActivity] = useMutation(REMOVE_MARKETING_ACTIVITY, {
    refetchQueries: [MARKETING_ACTIVITIES],
  });

  const handleRemoveEvent = async (_id) => {
    if (await confirm('Are you sure you want to remove this event?')) {
      removeMarketingActivity({ variables: { _id } });
    }
  };

  const marketingEvents = useMemo(
    () =>
      data?.marketingActivities.edges.map(({ node }) => ({
        id: node._id,
        title: node.title,
        start: node.startsAt,
        end: node.endsAt,
      })),
    [data],
  );

  const groupedEvents = useMemo(
    () =>
      data?.marketingActivities.edges.reduce((groupedEvents, { node }) => {
        const key = new Date(node.startsAt).toLocaleDateString(navigator.language, {
          month: 'short',
          year: '2-digit',
        });

        // Group by month
        return {
          ...groupedEvents,
          [key]: groupedEvents[key] ? [...groupedEvents[key], node] : [node],
        };
      }, {}),
    [data],
  );

  const handleShowSkus = (id) => {
    setEventSkuListOpen(eventSkuListOpen === id ? null : id);
  };

  return (
    <div className="max-w-[840px] text-midnight-100">
      <PageHeader text="Marketing Events Calendar" />

      <div className="mb-4 mt-8 flex justify-end">
        {!user.isReadOnly && <AddEventPopupButton isPopupInitiallyOpen={isPopupOpen} />}
      </div>

      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={marketingEvents}
        height={500}
        firstDay={1}
        fixedWeekCount={false}
        showNonCurrentDates
        dayMaxEventRows
        eventOrder="-duration,title"
        displayEventTime={false}
        headerToolbar={{
          start: 'title prev next today',
          center: '',
          end: '',
        }}
        buttonText={{
          prev: '',
          next: '',
          today: 'Today',
        }}
        buttonIcons={false}
        titleFormat={{ month: 'long' }}
        dayPopoverFormat={(dateObj) => {
          const date = new Date(dateObj.date.marker);
          return new Date(date).toLocaleDateString(navigator.language, {
            day: 'numeric',
            weekday: 'long',
          });
        }}
        validRange={{
          start: new Date().toISOString().slice(0, 7),
        }}
      />

      {marketingEvents?.length > 0 && (
        <div className="ml-0.5 mt-12 border-l-[3px] border-purple-100">
          {Object.keys(groupedEvents).map((month, index) => (
            <div key={month} className="mb-7 flex w-full flex-col text-xs">
              <div className="mb-5 flex pl-4 text-base font-bold">
                <h5 className="flex-grow uppercase">{month.split(' ')[0]}</h5>
                <div className={`flex ${index > 0 && 'hidden'}`}>
                  <div className="w-40 text-right">Product Sales Lift</div>
                  <div className="mr-16 w-36 text-right">Products Impacted</div>
                </div>
              </div>
              <ul>
                {groupedEvents[month].map((event) => (
                  <li
                    key={event._id}
                    className={`py-3 pl-4 pr-2 text-sm hover:bg-purple-10 ${
                      eventSkuListOpen === event._id && 'bg-purple-10'
                    }`}
                  >
                    <div className="flex h-5 w-full items-center justify-start">
                      <div className="w-40 shrink-0">
                        {formatEventDate(event.startsAt)}
                        {isSameDay(parseISO(event.startsAt), parseISO(event.endsAt))
                          ? ''
                          : ` \u2013 ${formatEventDate(event.endsAt)}`}
                      </div>

                      <div className={cn('font-bold grow truncate', isDemo && 'blur-sm')}>
                        {event.title}
                      </div>

                      <div className="w-16 shrink-0 text-right font-bold">
                        {(event.percentageLift / 100).toFixed(1)}x
                      </div>

                      <div className="mr-6 flex w-[9.5rem] shrink-0 justify-end">
                        <button
                          type="button"
                          className={`flex items-center rounded px-2 py-1 font-bold focus:outline-none focus:ring-2 focus:ring-purple-100 ${
                            event.skus?.length === 0 && 'pointer-events-none'
                          }`}
                          onClick={() => handleShowSkus(event._id)}
                        >
                          {!event.appliesToAllSkus && event.skus?.length > 0 ? (
                            <>
                              {event.skus.length}
                              <FiChevronDown
                                strokeWidth={1.5}
                                className={`ml-1 h-5 w-5 origin-center ${
                                  eventSkuListOpen === event._id && 'rotate-180'
                                }`}
                              />
                            </>
                          ) : (
                            <div className="mr-1.5">ALL</div>
                          )}
                        </button>
                      </div>
                      <div>
                        <DeleteButton onDelete={() => handleRemoveEvent(event._id)} />
                      </div>
                    </div>

                    <div className="ml-40 text-xs">{marketingEventTypes[event.types[0]].value}</div>

                    <div className={`pb-1 pt-3 ${eventSkuListOpen !== event._id && 'hidden'}`}>
                      {event.skus?.length > 0 && (
                        <div className="relative">
                          <ul className="mb-2 ml-[9.5rem] max-h-44 space-y-2.5 overflow-y-auto py-2">
                            {event.skus.map((sku) => (
                              <li
                                key={sku}
                                className={cn('-ml-1 pl-3 mr-12 truncate', isDemo && 'blur-sm')}
                              >
                                {sku}
                              </li>
                            ))}
                          </ul>
                          <div className="absolute left-0 top-0 h-3 w-[calc(100%-1rem)] bg-gradient-to-b from-purple-10 to-transparent" />
                          <div className="absolute bottom-0 left-0 h-3 w-[calc(100%-1rem)] bg-gradient-to-b from-transparent to-purple-10" />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Index.propTypes = {
  isPopupOpen: PropTypes.bool,
};

Index.defaultProps = {
  isPopupOpen: false,
};

export default Index;
