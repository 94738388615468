import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar } from 'recharts';

const WowChart = ({ weeklySales }) => {
  // Only displays last 10 days of data
  const weeklySalesMap = weeklySales.slice(-10)
    .map((entry) => ({ week: entry.value, name: entry.date }));

  return (
    <BarChart barCategoryGap={1} barGap={0} data={weeklySalesMap} width={48} height={30}>
      <Bar
        dataKey="week"
        className="fill-purple-100"
        minPointSize={1}
        barSize={2}
        radius={4}
      />
    </BarChart>
  );
};

WowChart.propTypes = {
  weeklySales: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

export default WowChart;
