import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { AiOutlineTable } from 'react-icons/ai';
import classnames from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import pluralize from 'pluralize';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import confirm from '../Components/ConfirmDialog.jsx';
import DeleteButton from '../Components/DeleteButton.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { Pagination } from '../Components/Pagination.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle, useQueryParams } from '../Hooks/index.js';
import { StatusListBox } from '../PurchaseOrders/Form.jsx';
import CreateTransferPopupButton from './CreateTransferPopup.jsx';
import { clearTransfersCache, REMOVE_TRANSFER, TRANSFERS, UPDATE_SOURCE } from './Queries.js';
import UpdateSourcePopup from './UpdateSourcePopup.jsx';

const statusMapping = {
  DRAFT: 'Draft',
  SHIPMENT: 'Shipment',
  DELIVERED: 'Delivered',
};

const Index = () => {
  useDocumentTitle('Transfers');
  const navigate = useNavigate();

  const { user } = useAccount();
  const { addAlert } = useAlerts();

  const showActions = !user.isReadOnly;

  const [queryParams] = useQueryParams(['after', 'before']);
  const [updateSource] = useMutation(UPDATE_SOURCE);

  const isShopifySyncEnabled = useFeature('transfers-shopify-sync').on;

  const { data, loading } = useQuery(TRANSFERS, {
    variables: {
      first: 25,
      after: queryParams.after,
      before: queryParams.before,
    },
  });

  const [removeTransfer] = useMutation(REMOVE_TRANSFER);

  const [deleteTransferPopup, setDeleteTransferPopup] = React.useState(null);

  const onRemoveTransfer = async (transfer, input = {}) => {
    const {
      syncInventoryToSource = transfer.inventorySourceToUpdate?.updateInventory,
      isInventoryChoicePermanent,
    } = input;

    if (isInventoryChoicePermanent && transfer.inventorySourceToUpdate) {
      await updateSource({
        variables: {
          input: { updateInventory: !!syncInventoryToSource },
          sourceId: transfer.inventorySourceToUpdate.sourceId,
        },
      });
    }

    await removeTransfer({
      variables: {
        transferId: transfer._id,
        syncInventoryToSource: !!syncInventoryToSource,
        sourceToUpdate: transfer.inventorySourceToUpdate?.sourceId,
      },
      update: clearTransfersCache,
      onCompleted: async () => {
        if (syncInventoryToSource) {
          addAlert('Inventory quantity is being updated on Shopify', {
            level: 'success',
          });
        }
        // check if this is the only item on the current page
        // and hasPreviousPage = true
        // and navigate manually to the first page because it's impossible to go to the
        // previous with cursor based pagination
        if (data?.transfers?.edges.length === 1 && data?.transfers?.pageInfo.hasPreviousPage) {
          navigate('/transfers');
        }
      },
    });

    setDeleteTransferPopup(null);
  };

  const handleRemove = async (e, transferToDelete) => {
    e.preventDefault();
    if (
      transferToDelete.inventorySourceToUpdate?.updateInventory === null &&
      isShopifySyncEnabled &&
      transferToDelete.issuedAt
    ) {
      setDeleteTransferPopup(transferToDelete);
    } else if (await confirm('Are you sure you want to delete this transfer?')) {
      await onRemoveTransfer(transferToDelete);
    }
  };

  const handleSubmit = (transfer) => async (input) => {
    await onRemoveTransfer(transfer, input);
  };

  const isEmpty = data?.transfers.edges.length === 0;

  return (
    <div className="-mb-12 min-h-screen bg-white text-midnight-100">
      <div className="-mt-12 bg-purple-5 pb-6 pl-9 pr-6 pt-12">
        <PageHeader text="Transfers" />
        <div className="mt-8 flex justify-end">
          <CreateTransferPopupButton />
        </div>
      </div>

      {!isEmpty && !loading && (
        <>
          <div className="grid grid-cols-[repeat(3,15%),repeat(3,12%),15%,4%] px-9 pb-2 pt-8 text-left text-sm font-bold">
            <div>Transfer Name</div>
            <div>Source</div>
            <div>Destination</div>
            <div>Draft</div>
            <div>Shipment</div>
            <div>Received</div>
            <div>Status</div>
            {showActions && <div>&nbsp;</div>}
          </div>

          {data.transfers.edges.map(({ node: transfer }) => (
            <Link
              className={classnames(
                'mx-6 mt-4 grid cursor-pointer grid-cols-[repeat(3,15%),repeat(3,12%),15%,4%] items-center rounded-lg border border-transparent bg-white p-4 text-left text-xs shadow hover:border-purple-90 hover:bg-leafy-10',
                transfer.sourceId !== 'cogsy' && 'cursor-text',
              )}
              to={transfer.sourceId === 'cogsy' ? `/transfers/${transfer._id}` : '/transfers'}
              key={transfer._id}
            >
              <div className="font-bold">
                <div className="mr-2 truncate" title={transfer.customId || transfer.transferId}>
                  {transfer.customId || transfer.transferId}
                </div>
                <div className="mt-2 text-midnight-75">
                  {transfer.items.length.toLocaleString()}&nbsp;
                  {pluralize('product', transfer.items.length)}
                </div>
              </div>
              <div className="mr-2 truncate" title={transfer.fromLocation?.name}>
                {transfer.fromLocation?.name}
              </div>
              <div className="mr-2 truncate" title={transfer.toLocation.name}>
                {transfer.toLocation.name}
              </div>
              <div
                className={classnames(
                  'h-1 w-4/5 rounded-sm',
                  ['DRAFT', 'SHIPMENT', 'DELIVERED'].includes(transfer.status)
                    ? 'bg-leafy-75'
                    : 'bg-gray-75/25',
                )}
              />
              <div
                className={classnames(
                  'h-1 w-4/5 rounded-sm',
                  ['SHIPMENT', 'DELIVERED'].includes(transfer.status)
                    ? 'bg-leafy-75'
                    : 'bg-gray-75/25',
                )}
              />
              <div
                className={classnames(
                  'h-1 w-4/5 rounded-sm',
                  transfer.status === 'DELIVERED' ? 'bg-leafy-75' : 'bg-gray-75/25',
                )}
              />
              <StatusListBox
                selected={transfer.status}
                options={[{ name: statusMapping[transfer.status], value: transfer.status }]}
                disabled
              />
              {showActions ? (
                <div className="text-right">
                  {transfer.sourceId === 'cogsy' && !transfer.deliveredAt && (
                    <DeleteButton onDelete={(e) => handleRemove(e, transfer)} />
                  )}
                </div>
              ) : null}
            </Link>
          ))}

          <div className="mb-8 flex justify-center">
            <Pagination
              items={data?.transfers}
              isLoading={loading}
              className="mt-6 flex justify-center space-x-4"
            >
              <Pagination.PrevButton />
              <Pagination.NextButton />
            </Pagination>
          </div>
        </>
      )}
      {isEmpty && (
        <div className="flex flex-col items-center space-y-6 p-24 text-center text-xs text-gray-100">
          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-gray-50">
            <AiOutlineTable className="text-gray-75" size={32} />
          </div>
          <div className="text-sm">
            <p>There are no transfers to show.</p>
            <p className="mt-2">Click the &ldquo;Create a Transfer&rdquo; button to add one.</p>
          </div>
        </div>
      )}
      {loading && (
        <div className="mt-32 text-center">
          <Spinner />
        </div>
      )}

      {deleteTransferPopup ? (
        <UpdateSourcePopup
          onClose={() => setDeleteTransferPopup(null)}
          onSubmit={handleSubmit(deleteTransferPopup)}
          location={deleteTransferPopup.fromLocation}
          type="delete"
        />
      ) : null}
    </div>
  );
};

export default Index;
