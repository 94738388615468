import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import pluralize from 'pluralize';
import { DEFAULT_PAGE_SIZE } from '../../shared/skus.js';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import AssignVendorPopup from './Popups/AssignVendorPopup.jsx';
import MergeSkusPopup from './Popups/MergeSkusPopup.jsx';
import { UPDATE_SKUS } from './Queries.js';

const SkusReplenishmentSettings = ({ selectedSkus, onSaveSettings, removeSku }) => {
  const [skusMergePopupOpen, setSkusMergePopupOpen] = useState(false);
  const [assignVendorPopupOpen, setAssignVendorPopupOpen] = useState(false);
  const [updateSkus] = useMutation(UPDATE_SKUS);
  const { addAlert } = useAlerts();

  const toggleMergeSkusPopup = React.useCallback(() => {
    setSkusMergePopupOpen(!skusMergePopupOpen);
  }, [skusMergePopupOpen]);

  const toggleAssignVendorPopup = React.useCallback(() => {
    setAssignVendorPopupOpen(!assignVendorPopupOpen);
  }, [assignVendorPopupOpen]);

  const onMergeSkus = () => {
    toggleMergeSkusPopup();
    onSaveSettings();
  };

  const onAssignVendor = () => {
    toggleAssignVendorPopup();
    onSaveSettings();
  };

  const handleArchiveSkus = async () => {
    await updateSkus({
      variables: { skus: selectedSkus.map(({ sku }) => sku), input: { isArchived: true } },
      update: (cache) => {
        cache.evict({ _id: cache.identify({ __typename: 'SkuConnection' }) });
        cache.evict({ _id: cache.identify({ __typename: 'SkuCategoryConnection' }) });
        cache.gc();
      },
    });
    addAlert(`${pluralize('Product', selectedSkus.length)} archived successfully`, {
      level: 'success',
    });
    onSaveSettings();
  };

  return (
    <div className="flex items-center border-b border-gray-50 p-5 pb-4">
      <Button
        slim
        label="Archive"
        variant="tertiary"
        onClick={handleArchiveSkus}
        disabled={!selectedSkus.length}
      />

      <Button
        slim
        label="Merge Products"
        className="ml-4"
        variant="tertiary"
        onClick={toggleMergeSkusPopup}
        disabled={selectedSkus.length < 2}
      />

      <Button
        slim
        label="Assign Vendor"
        className="ml-4"
        variant="tertiary"
        onClick={toggleAssignVendorPopup}
        disabled={!selectedSkus.length}
      />

      <p className="ml-4 text-xs text-gray-100">
        Up to {DEFAULT_PAGE_SIZE} products can be updated at once.
      </p>

      {selectedSkus.length > 1 && (
        <MergeSkusPopup
          open={skusMergePopupOpen}
          onClose={toggleMergeSkusPopup}
          onSave={onMergeSkus}
          selectedSkus={selectedSkus}
        />
      )}

      {selectedSkus.length > 0 && (
        <AssignVendorPopup
          open={assignVendorPopupOpen}
          onClose={toggleAssignVendorPopup}
          onSave={onAssignVendor}
          selectedSkus={selectedSkus}
          removeSku={removeSku}
        />
      )}
    </div>
  );
};

SkusReplenishmentSettings.propTypes = {
  selectedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      sku: PropTypes.string,
      productName: PropTypes.string,
    }),
  ).isRequired,
  onSaveSettings: PropTypes.func.isRequired,
  removeSku: PropTypes.func.isRequired,
};

export default SkusReplenishmentSettings;
