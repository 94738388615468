import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.css';

const ProgressBar = ({ value }) => (
  <div className="h-1 overflow-hidden rounded-lg bg-purple-10">
    {value ? (
      <div className="h-full rounded-lg bg-purple-100" style={{ width: `${value}%` }} />
    ) : (
      <div className="progress-bar-indeterminate h-full rounded-lg bg-purple-100" />
    )}
  </div>
);

ProgressBar.propTypes = {
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  value: null,
};

export default ProgressBar;
