import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const CONNECT_WITH_CIN7 = gql`
  mutation ConnectWithCin7($apiUsername: String!, $connectionKey: String!) {
    connectWithCin7(apiUsername: $apiUsername, connectionKey: $connectionKey) {
      _id
    }
  }
`;

const Cin7Popup = ({ open, onClose }) => {
  const { addAlert } = useAlerts();

  const [connectWithCin7, { loading }] = useMutation(CONNECT_WITH_CIN7);

  const handleCin7Connect = React.useCallback(
    (e) => {
      e.preventDefault();

      const variables = Object.fromEntries(new FormData(document.querySelector('form')));

      const connect = async () => {
        await connectWithCin7({ variables });
        addAlert('Successfully connected to Cin7', { level: 'success' });
        onClose();
      };

      connect();
    },
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Cin7 Omni</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleCin7Connect}>
            <div>
              <p className="mt-2 text-gray-100">
                Create a new API Connection on&nbsp;
                <a
                  href="https://go.cin7.com/Cloud/apiSetup/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-100"
                >
                  Cin7 Omni
                </a>
                &nbsp;and copy your username and connection key down below.
              </p>
              <br />
              <label htmlFor="cin7-apiUsername">
                Cin7 Omni API Username*
                <input
                  type="text"
                  id="cin7-apiUsername"
                  required
                  className="input"
                  name="apiUsername"
                />
              </label>
            </div>
            <div className="mt-4">
              <label htmlFor="cin7-connectionKey">
                Cin7 Omni Connection Key*
                <input
                  type="text"
                  id="cin7-connectionKey"
                  required
                  className="input"
                  name="connectionKey"
                />
              </label>
            </div>
            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

Cin7Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Cin7Popup;
