import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ForecastRuns from './ForecastRuns.jsx';
import Accounts from './Accounts.jsx';

const Index = () => (
  <Routes>
    <Route path="forecast-runs" element={<ForecastRuns />} />
    <Route path="accounts" element={<Accounts />} />
  </Routes>
);

export default Index;
