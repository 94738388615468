import React from 'react';
import PropTypes from 'prop-types';
import { useCSVDownloader } from 'react-papaparse';

const ExportToCSV = ({
  data, fileName, children,
}) => {
  const { CSVDownloader } = useCSVDownloader();
  return (
    <CSVDownloader
      data={data}
      filename={fileName}
      type="link"
    >
      {children}
    </CSVDownloader>
  );
};

ExportToCSV.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  fileName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ExportToCSV;
