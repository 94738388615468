import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDebounce = (value, delay = 400) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return [debouncedValue, setDebouncedValue];
};

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  useEffect(() => {
    document.title = `${title} | Cogsy`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
};

export const useCmdDown = () => {
  const [cmdDown, setCmdDown] = useState(false);

  useEffect(() => {
    const handleCmdKeydown = ({ key }) => {
      if (key === 'Meta') setCmdDown(true);
    };
    window.addEventListener('keydown', handleCmdKeydown);

    const handleCmdKeyup = ({ key }) => {
      if (key === 'Meta') setCmdDown(false);
    };
    window.addEventListener('keyup', handleCmdKeyup);

    return () => {
      window.removeEventListener('keydown', handleCmdKeydown);
      window.removeEventListener('keyup', handleCmdKeyup);
    };
  }, []);

  return cmdDown;
};

export const useQueryParams = (paramsNamesArray) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const updatedQueryParams = Object.fromEntries([
      ...paramsNamesArray.map((paramName) => [paramName, undefined]),
      ...searchParams,
    ]);

    setQueryParams(updatedQueryParams);
  }, [searchParams]);

  const setNewValue = (newValue) => {
    const updatedQueryParams = { ...Object.fromEntries(searchParams), ...newValue };

    const definedSearchParams = Object.entries(updatedQueryParams).reduce(
      (acc, [key, value]) => (value !== undefined ? { ...acc, [key]: value } : acc),
      {},
    );

    setSearchParams(definedSearchParams);
  };

  return [queryParams, setNewValue];
};

export const usePagination = (items) => {
  const [after, setAfter] = useState('');
  const [before, setBefore] = useState('');

  const handleNextClick = () => {
    setBefore('');
    setAfter(items.edges.slice(-1)[0].cursor);
  };
  const handlePrevClick = () => {
    setAfter('');
    setBefore(items?.edges[0]?.cursor);
  };

  const hasNext = items?.pageInfo?.hasNextPage;
  const hasPrev = items?.pageInfo?.hasPreviousPage;

  return {
    after,
    before,
    handleNextClick,
    handlePrevClick,
    hasNext,
    hasPrev,
  };
};
