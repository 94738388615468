import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaShopify } from 'react-icons/fa';
import classnames from 'classnames';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import cogsyLogo from '../../public/cogsy-logo-on-white.svg';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import { shopify as shopifyConfig } from '../config.js';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ mode: 'all' });

  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const [loading, setLoading] = React.useState(false);
  const token = window.localStorage.getItem('token');

  React.useEffect(() => {
    if (token) {
      window.location.assign(query.get('u') || '/');
    }
  }, [token]);

  const handleLogin = React.useCallback((formData) => {
    setLoading(true);

    const doRegister = async () => {
      try {
        const {
          data: { token },
        } = await axios('/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(formData),
        });

        window.localStorage.setItem('token', token);
        axios.defaults.headers = { Authorization: `Bearer ${token}` };
        window.location.assign(query.get('u') || '/');
      } catch (e) {
        setLoading(false);

        if (e.response?.status === 400) {
          setError('email', {
            type: 'manual',
            message: 'Email or password are not correct.',
          });
        } else {
          setError('email', {
            type: 'manual',
            message: `Internal server error: ${e.response?.status}`,
          });

          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    };

    doRegister();
  }, []);

  return (
    <main className="mx-auto mt-20 max-w-md px-6 text-midnight-100 md:px-0">
      <div>
        <img src={cogsyLogo} alt="Cogsy Logo" className="mx-auto" />
      </div>
      <h1 className="mt-20 text-3xl">Log in to Cogsy</h1>
      <form className="mt-8" noValidate onSubmit={handleSubmit(handleLogin)}>
        <div>
          <label htmlFor="email" className="text-sm">
            Email*
            <input
              type="email"
              id="email"
              className={classnames('input', errors.email && 'input-error')}
              placeholder="john@acme.com"
              {...register('email', {
                required: 'Please provide a valid email',
                pattern: {
                  value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                  message: 'Please provide a valid email',
                },
              })}
            />
          </label>
          <InputErrorMessage message={errors.email?.message} />
        </div>
        <div className="mt-4">
          <label htmlFor="password" className="text-sm">
            Password*
            <input
              type="password"
              id="password"
              placeholder="Your password"
              className={classnames('input', errors.password && 'input-error')}
              {...register('password', {
                required: 'Please provide your password',
              })}
            />
          </label>
          <InputErrorMessage message={errors.password?.message} />
        </div>
        <div className="mt-8 space-y-4">
          <Button
            type="submit"
            disabled={loading}
            fullWidth
            label={loading ? 'Please hold...' : 'Log in'}
          />
          <Button
            href={shopifyConfig.appUrl}
            fullWidth
            variant="tertiary"
            icon={FaShopify}
            iconSize={16}
            label="Log in with Shopify"
            target="_blank"
          />
        </div>
      </form>
      <div className="flex justify-between pt-8 text-xs">
        <div>
          Don&apos;t have an account?&nbsp;
          <Link to="/register" className="text-purple-100">
            Sign up
          </Link>
        </div>
        <div>
          <Link to="/forgot-password" className="text-purple-100">
            Forgot your password?
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Login;
