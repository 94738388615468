import React from 'react';
import PropTypes from 'prop-types';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

const PercentageChange = ({ color, direction, percentage }) => (
  <div className={`flex items-center ${color}`}>
    <span className="mr-0.5 font-medium">{percentage}%</span>
    {direction === 'up' ? (
      <FiArrowUp strokeWidth="2.5" className="-mt-0.5" />
    ) : (
      <FiArrowDown strokeWidth="2.5" className="-mt-0.5" />
    )}
  </div>
);

PercentageChange.propTypes = {
  color: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default PercentageChange;
