import React from 'react';
import PropTypes from 'prop-types';
import { Dot } from 'recharts';

const isBetweenDates = (fromDate, toDate) => (date) => date > fromDate && date <= toDate;

const ForecastInventoryDot = ({ cx, cy, index, purchaseOrders, data, sku }) => {
  const { date, inventoryQuantity } = data[index];
  const { date: previousDate } = data[index - 1] || {};

  if (!inventoryQuantity) return null;

  const isInPeriod = isBetweenDates(previousDate, date);

  const hasPurchaseOrder = purchaseOrders.some(({ items }) =>
    items.some((item) => item.sku === sku && isInPeriod(item.expectedDeliveryDate)),
  );

  if (!hasPurchaseOrder) return null;

  return (
    <Dot
      fill="rgb(122, 77, 255)"
      stroke="rgb(122, 77, 255)"
      fillOpacity={1}
      r={5}
      cx={cx}
      cy={cy}
    />
  );
};

ForecastInventoryDot.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      inventoryQuantity: PropTypes.number,
    }).isRequired,
  ).isRequired,
  purchaseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      expectedDeliveryDate: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  sku: PropTypes.string.isRequired,
  index: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
};

ForecastInventoryDot.defaultProps = {
  index: 0,
  cx: 0,
  cy: 0,
};

export default ForecastInventoryDot;
