import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { useAccount } from '../../../AccountProvider.jsx';
import { SkeletonBlock } from '../../../Components/Skeletons.jsx';
import Tag from '../../../Components/Tag.jsx';
import Widget from '../Widget.jsx';
import RevenueGraph from './RevenueGraph.jsx';

const Index = ({ growthModel, loading }) => {
  const { plannedRevenue = [], stats = { currentMonthPlanTotal: 0, monthToDate: { actual: 0 } } } =
    growthModel;
  const { account } = useAccount();

  const currencyOptions = {
    style: 'currency',
    currency: account.preferences?.currency || 'USD',
    notation: 'compact',
    maximumFractionDigits: 2,
  };

  const revenueChartData = plannedRevenue.map((md) => ({
    monthlySales: md.value,
    name: new Date(md.date).toLocaleDateString(navigator.language, {
      month: 'short',
      year: '2-digit',
      timeZone: 'UTC',
    }),
  }));

  return (
    <Widget barColor="green">
      <div className="flex items-center justify-between">
        <Widget.Title>Planned Growth</Widget.Title>
        <Tag color="green" className="my-auto">
          {new Date().toLocaleDateString(navigator.language, { month: 'long' })}
        </Tag>
      </div>
      {loading && <SkeletonBlock className="mt-6 h-[292px]" />}
      {!loading && (
        <Widget.Content>
          <p className="text-base">
            Target revenue:
            <span className="ml-1 text-2xl font-bold">
              {stats.currentMonthPlanTotal.toLocaleString(navigator.language, currencyOptions)}
            </span>
          </p>
          <p className="mt-3 text-base">
            MTD:
            <span className="ml-1 text-2xl font-bold">
              {stats.monthToDate.actual.toLocaleString(navigator.language, currencyOptions)}
            </span>
          </p>
          <div className="mt-8">
            <RevenueGraph revenueChartData={revenueChartData} />
          </div>
          <p className="mt-6 font-semibold text-purple-100">
            <RouterLink to="/planning/growth">
              Edit my Growth Plan
              <FiChevronRight size={20} className="inline" />
            </RouterLink>
          </p>
        </Widget.Content>
      )}
    </Widget>
  );
};

Index.propTypes = {
  growthModel: PropTypes.shape({
    plannedRevenue: PropTypes.arrayOf(PropTypes.shape({})),
    stats: PropTypes.shape({}),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Index;
