import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import Button from '../Components/Button.jsx';

const UpdateSourcePopup = ({ onClose, onSubmit, location, type }) => {
  const [syncInventoryToSource, setSyncInventoryToSource] = useState(false);
  const [isInventoryChoicePermanent, setIsInventoryChoicePermanent] = useState(false);

  let title = 'Mark as shipped';

  if (type === 'delete') title = 'Delete transfer';

  return (
    <Dialog
      open
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-sm rounded bg-white shadow">
          <div className="z-10 m-0 mx-auto w-full rounded bg-purple-10 p-5">
            <Dialog.Title className="ml-3 text-lg font-bold text-purple-100">{title}</Dialog.Title>
          </div>
          <div className="pl-8">
            <div className="mt-5">
              <div className="mb-3 text-sm font-bold">
                Do you want to {type === 'ship' ? 'deduct ' : 'increment'} the on-hand quantities
                from <span className="italic">{location.name}</span> on Shopify ?
              </div>
              <RadioGroup onChange={setSyncInventoryToSource} value={syncInventoryToSource}>
                <div className="ml-1 inline-flex text-sm">
                  {[
                    ['Yes', true],
                    ['No', false],
                  ].map((item) => (
                    <RadioGroup.Option value={item[1]} key={item[0]}>
                      {({ checked }) => (
                        <div className="mr-7 flex cursor-pointer items-center">
                          <div
                            className={classnames(
                              checked
                                ? 'mr-3 h-2 w-2 rounded-full bg-purple-100 ring-2 ring-purple-100 ring-offset-2 ring-offset-gray-10'
                                : 'mr-2 h-4 w-4 rounded-full border border-gray-75 bg-white',
                            )}
                          />
                          <span>{item[0]}</span>
                        </div>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <div className="mt-8">
              <label className="cursor-pointer text-sm" htmlFor="isInventoryChoicePermanent">
                <input
                  type="checkbox"
                  id="isInventoryChoicePermanent"
                  checked={isInventoryChoicePermanent}
                  className="mb-1 mr-2 cursor-pointer rounded border-gray-75 text-purple-100"
                  onChange={() =>
                    setIsInventoryChoicePermanent(
                      (isInventoryChoicePermanent) => !isInventoryChoicePermanent,
                    )
                  }
                />
                Don&apos;t ask me again
              </label>
            </div>
          </div>

          <div className="my-4 mr-3 mt-8 flex items-center justify-end space-x-2">
            <Button label="Cancel" variant="text" onClick={onClose} slim />
            <Button
              variant="text"
              label={title}
              onClick={() => onSubmit({ syncInventoryToSource, isInventoryChoicePermanent })}
              slim
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

UpdateSourcePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default UpdateSourcePopup;
