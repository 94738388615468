import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { addCountsToHeatmap } from '../../../Planning/utils/heatmap.js';

const heatmapFormats = [
  {
    value: 0.5,
    bgColorClass: 'bg-red-75',
    amountBgColorClass: 'bg-red-85',
    borderColorClass: 'border-red-100',
    textColorClass: 'text-red-100',
  },
  {
    value: 0.6,
    bgColorClass: 'bg-red-10',
    amountBgColorClass: 'bg-red-25',
    borderColorClass: 'border-red-75',
    textColorClass: 'text-red-100',
  },
  {
    value: 0.7,
    bgColorClass: 'bg-yellow-10',
    amountBgColorClass: 'bg-yellow-75',
    borderColorClass: 'border-yellow-100',
    textColorClass: 'text-yellow-100',
  },
  {
    value: 0.8,
    bgColorClass: 'bg-leafy-10',
    amountBgColorClass: 'bg-leafy-50',
    borderColorClass: 'border-leafy-75',
    textColorClass: 'text-leafy-100',
  },
  {
    value: 1,
    bgColorClass: 'bg-leafy-75',
    amountBgColorClass: 'bg-leafy-65',
    borderColorClass: 'border-leafy-100',
    textColorClass: 'text-leafy-100',
  },
];

const bgColorClass = (value) => heatmapFormats.find((format) => value <= format.value).bgColorClass;

const amountBgColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).amountBgColorClass;

const textColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).textColorClass;

const borderColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).borderColorClass;

const Heatmap = ({ draftPurchaseOrders, heatmapData }) => {
  const updatedHeatmap = addCountsToHeatmap(heatmapData || [], draftPurchaseOrders);
  return (
    <div className="grid grid-cols-12 gap-1 text-xs">
      {updatedHeatmap.map(({ date, percentage, draftPurchaseOrderCount }) => (
        <div
          className={classnames(
            'flex flex-col items-center rounded border py-4 font-bold',
            borderColorClass(percentage),
            textColorClass(percentage),
            bgColorClass(percentage),
          )}
          key={date}
        >
          <span className="uppercase">
            {new Date(date).toLocaleDateString(undefined, {
              month: 'short',
              timeZone: 'UTC',
            })}
          </span>
          <span
            className={classnames(
              'mt-5 flex h-7 w-7 items-center justify-center rounded-full',
              amountBgColorClass(percentage),
            )}
          >
            {draftPurchaseOrderCount}
          </span>
        </div>
      ))}
    </div>
  );
};

Heatmap.propTypes = {
  heatmapData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      percentage: PropTypes.number,
    }),
  ).isRequired,
  draftPurchaseOrders: PropTypes.shape({}).isRequired,
};

export default Heatmap;
