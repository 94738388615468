import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import { addDays } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useAccount } from '../../AccountProvider.jsx';
import Button from '../../Components/Button.jsx';
import LocationFilter from '../../Components/LocationFilter.jsx';
import RadioButton from '../../Components/RadioButton.jsx';

const TransferDialog = ({ purchaseOrder, transfer, onSave, onClose }) => {
  const { account } = useAccount();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      customId: transfer?.customId,
      toLocation: transfer?.toLocation?._id || purchaseOrder.location._id,
      expectedDeliveryDate: transfer?.expectedDeliveryDate,
      trackingUrl: transfer?.trackingUrl,
      trackingCode: transfer?.trackingCode,
      wroCode: transfer?.wroCode,
      wroTextUrl: transfer?.wroTextUrl,
      notes: transfer?.notes,
    },
  });

  const [trackingFieldSelected, setTrackingFieldSelected] = useState(
    transfer?.trackingCode ? 'trackingCode' : 'trackingUrl',
  );

  useEffect(() => {
    if (trackingFieldSelected === 'trackingCode') setValue('trackingUrl', '');
    if (trackingFieldSelected === 'trackingUrl') setValue('trackingCode', '');
  }, [trackingFieldSelected]);

  return (
    <Dialog
      open
      onClose={onClose}
      className="relative z-50 text-xs text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="w-[26.5rem] overflow-hidden rounded bg-white shadow">
            <div className="bg-purple-10 p-8 py-6">
              <Dialog.Title className="text-lg font-bold text-purple-100">
                Add a shipment
              </Dialog.Title>
            </div>

            <form className="p-8" onSubmit={handleSubmit(onSave)}>
              <div className="text-sm">
                <div>
                  <label htmlFor="customId">
                    <span className="font-bold">Shipment Name</span>
                    <input
                      type="text"
                      id="customId"
                      className={classnames(
                        'mt-2 block w-56 rounded border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100',
                        errors.customId && 'input-error',
                      )}
                      {...register('customId', {
                        required: 'Please provide a shipment name',
                      })}
                    />
                  </label>
                </div>

                <div className="mt-8">
                  <span className="font-bold">Ship to</span>
                  <Controller
                    name="toLocation"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <LocationFilter
                        value={value}
                        onChange={onChange}
                        label="Select a destination"
                        showAllLocations={false}
                      />
                    )}
                    rules={{ required: 'Please select a destination' }}
                  />
                  {errors.toLocation ? (
                    <span className="text-red-100">{errors.toLocation.message}</span>
                  ) : null}
                </div>

                <div className="mt-8">
                  <label htmlFor="expectedDeliveryDate">
                    <span className="font-bold">Target Delivery Date</span>
                    <input
                      type="date"
                      id="expectedDeliveryDate"
                      className={classnames(
                        'mt-2 block w-56 rounded border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100',
                        errors.expectedDeliveryDate && 'input-error',
                      )}
                      defaultValue={addDays(
                        new Date(),
                        purchaseOrder.vendor.leadTime ||
                          account.preferences.averageOrderLeadTime ||
                          30,
                      )
                        .toISOString()
                        .slice(0, 10)}
                      {...register('expectedDeliveryDate', {
                        required: 'Please provide an expected delivery date',
                      })}
                    />
                  </label>
                </div>

                <div className="mt-8">
                  <span className="font-bold">Enter tracking details</span>

                  <RadioGroup
                    className="mt-2 flex space-x-6 text-midnight-75"
                    onChange={setTrackingFieldSelected}
                    value={trackingFieldSelected}
                  >
                    <RadioGroup.Option value="trackingUrl" className="cursor-pointer">
                      {({ checked }) => (
                        <div className="flex items-center space-x-2 font-bold">
                          <RadioButton isChecked={checked} />
                          <div className="vertical-fix ml-2">Tracking URL</div>
                        </div>
                      )}
                    </RadioGroup.Option>

                    <RadioGroup.Option value="trackingCode" className="cursor-pointer">
                      {({ checked }) => (
                        <div className="flex items-center space-x-2 font-bold">
                          <RadioButton isChecked={checked} />
                          <div className="vertical-fix ml-2">Tracking Code</div>
                        </div>
                      )}
                    </RadioGroup.Option>
                  </RadioGroup>

                  <input
                    type={trackingFieldSelected === 'trackingUrl' ? 'url' : 'hidden'}
                    id="trackingUrl"
                    className={classnames(
                      'mt-6 block w-full rounded border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100',
                      errors.trackingUrl && 'input-error',
                    )}
                    {...register('trackingUrl')}
                  />

                  <input
                    type={trackingFieldSelected === 'trackingCode' ? 'text' : 'hidden'}
                    id="trackingCode"
                    className={classnames(
                      'mt-6 block w-full rounded border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100',
                      errors.trackingCode && 'input-error',
                    )}
                    {...register('trackingCode')}
                  />
                </div>

                <div className="mt-8">
                  <label htmlFor="customId">
                    <span className="font-bold">WRO Name</span>
                    <input
                      type="text"
                      id="wroCode"
                      className={classnames(
                        'mt-2 block w-56 rounded border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100',
                        errors.customId && 'input-error',
                      )}
                      placeholder="Name/ID"
                      {...register('wroCode')}
                    />
                  </label>
                </div>

                <div className="mt-8">
                  <label htmlFor="customId">
                    <span className="font-bold">WRO Link/Details</span>
                    <input
                      type="text"
                      id="wroTextUrl"
                      className={classnames(
                        'mt-2 block w-56 rounded border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100',
                        errors.customId && 'input-error',
                      )}
                      placeholder="Link/Details"
                      {...register('wroTextUrl')}
                    />
                  </label>
                </div>

                <div className="mt-8">
                  <label htmlFor="notes">
                    <span className="font-bold">Notes</span>
                    <textarea
                      id="notes"
                      className={classnames(
                        'mt-2 w-full rounded border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100',
                        errors.notes && 'input-error',
                      )}
                      {...register('notes')}
                    />
                  </label>
                </div>
              </div>

              <div className="mt-8 flex justify-end space-x-2">
                <Button label="Cancel" variant="text" slim onClick={onClose} />
                <Button label="Save" type="submit" slim />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

TransferDialog.propTypes = {
  transfer: PropTypes.shape({
    _id: PropTypes.string,
    customId: PropTypes.string,
    toLocation: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
    expectedDeliveryDate: PropTypes.string,
    trackingUrl: PropTypes.string,
    trackingCode: PropTypes.string,
    wroCode: PropTypes.string,
    wroTextUrl: PropTypes.string,
    notes: PropTypes.string,
  }),
  purchaseOrder: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
      }),
    ).isRequired,
    location: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
    vendor: PropTypes.shape({
      leadTime: PropTypes.number,
    }).isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

TransferDialog.defaultProps = {
  transfer: null,
};

export default TransferDialog;
