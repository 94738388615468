import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiCheck, FiChevronDown } from 'react-icons/fi';
import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';
import { differenceInDays } from 'date-fns';
import pluralize from 'pluralize';
import { PLANS } from '../../shared/billing/plans.js';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import confirm from '../Components/ConfirmDialog.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription
  }
`;

const BILLING_DETAILS_QUERY = gql`
  query {
    account {
      billing {
        platform
        canCancel
        trialEndsAt
        planDetails {
          plan
          tier
          nextChargeDate
        }
      }
    }
  }
`;

const Card = ({ children }) => (
  <div className="mt-8 rounded-b-lg bg-white shadow">
    <div className="h-1 bg-purple-100" />
    <div className="p-8">{children}</div>
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

const Billing = () => {
  const { addAlert } = useAlerts();

  useDocumentTitle('Billing Settings');

  const { data: billingAccountData } = useQuery(BILLING_DETAILS_QUERY);

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);

  const [loading, setLoading] = React.useState(false);

  const handleUnsubscribe = async () => {
    if (await confirm('Are you sure you want to cancel your subscription?')) {
      setLoading(true);
      const doCancel = async () => {
        try {
          await cancelSubscription();
          window.location.assign('/billing/check');
        } catch (error) {
          addAlert('An error has occurred! Please refresh the page and try again.', { error });
        }

        setLoading(false);
      };
      doCancel();
    }
  };

  if (!billingAccountData) return <Spinner />;

  const billingData = billingAccountData.account.billing;

  const fullPlan = PLANS[billingData.planDetails.plan || 'manual'];
  const fullTier = fullPlan.tiers.find(
    ({ revenueTo }) => revenueTo >= (billingData.planDetails.tier || 0),
  );

  const trialDays = differenceInDays(new Date(billingData.trialEndsAt), new Date());

  return (
    <div className="col-span-6 text-midnight-100">
      <h1 className="text-xl">Billing</h1>

      <Card>
        <h2 className="text-2xl font-bold">{fullPlan.name} Plan</h2>

        {billingData.platform !== 'manual' && (
          <>
            {!billingData.canCancel && fullTier.price > 0 && (
              <div className="mt-4 inline-block rounded bg-purple-10 px-2 py-1 text-xs font-bold tracking-[0.01rem] text-purple-100">
                Your subscription is set to be canceled at the end of the billing period.
              </div>
            )}

            {trialDays > 0 && fullTier.price > 0 && (
              <div className="mt-4 inline-block rounded bg-purple-10 px-2 py-1 text-xs font-bold tracking-[0.01rem] text-purple-100">
                {trialDays} {pluralize('day', trialDays)} remaining in your free trial then:
              </div>
            )}

            {Number.isFinite(fullTier.price) ? (
              <>
                <div className="mt-4 text-sm">
                  <span className="text-3xl font-bold text-purple-100">
                    {fullTier.price.toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                      notation: 'compact',
                    })}
                    &nbsp;
                    <span className="font-normal">/</span>
                  </span>
                  month
                </div>

                {billingData.planDetails.nextChargeDate ? (
                  <div className="mt-6 text-sm">
                    <span className="font-bold text-midnight-100 opacity-60">Next billing date:</span>
                    &nbsp;
                    <span className="font-bold tracking-wide">
                      {new Date(billingData.planDetails.nextChargeDate).toLocaleDateString()}
                    </span>
                    &nbsp;for&nbsp;
                    {fullTier.price.toLocaleString(navigator.language, {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                      notation: 'compact',
                    })}
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        )}

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="mt-6 flex items-center justify-self-end text-sm font-bold text-purple-100">
                What&apos;s included?
                <FiChevronDown size={18} className={classnames(open && 'rotate-180', 'ml-1')} />
              </Disclosure.Button>
              <Disclosure.Panel as="ul" className="mt-6 space-y-4 text-sm font-bold">
                {[
                  'Demand planning',
                  'Replenishment recommendations & alerts',
                  'Purchase orders',
                  'Multi-location support',
                  'Advanced demand planning',
                  'Marketing events calendar',
                  'New product planning',
                ].map((item, index) => (
                  <li key={item} className="flex">
                    <div className="w-6 shrink-0">
                      {!fullPlan.hasAllOptions && index > 3 ? null : (
                        <FiCheck className="mt-px text-purple-100" strokeWidth={2.5} size={16} />
                      )}
                    </div>
                    <div
                      className={classnames(
                        !fullPlan.hasAllOptions && index > 3 && 'text-gray-75 line-through',
                      )}
                    >
                      {item}
                    </div>
                  </li>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Card>

      {billingData.canCancel && (
        <Button
          label="Cancel subscription"
          variant="danger-text"
          className="mt-6"
          onClick={handleUnsubscribe}
          disabled={loading}
        />
      )}
    </div>
  );
};

export default Billing;
