import React from 'react';
import { gql, useMutation } from '@apollo/client';
import LoadingPage from '../Components/LoadingPage.jsx';

const REFRESH_BILLING_STATUS = gql`
  mutation {
    refreshBillingStatus
  }
`;

const Check = () => {
  const [refreshBillingStatus] = useMutation(REFRESH_BILLING_STATUS);

  React.useEffect(() => {
    const doCheck = async () => {
      let url = '/';

      const {
        data: { refreshBillingStatus: isSubscribed },
      } = await refreshBillingStatus();

      if (isSubscribed) url += '?trial_started=true';

      window.location.assign(url);
    };

    doCheck();
  }, []);

  return <LoadingPage />;
};

export default Check;
