import React from 'react';
import { AiOutlineTable } from 'react-icons/ai';

const EmptyResult = () => (
  <div className="grid grid-cols-12">
    <div className="col-span-12 mt-8">
      <div className="rounded-lg bg-white pb-20 pt-5 text-midnight-100">
        <div className="mx-auto mt-12 flex h-16 w-16 items-center justify-center rounded-full bg-gray-50">
          <AiOutlineTable className="text-gray-75" size={32} />
        </div>
        <div className="mx-auto mt-6 text-center text-sm text-gray-100">
          <p>No products to show at this time</p>
        </div>
      </div>
    </div>
  </div>
);

export default EmptyResult;
