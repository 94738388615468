import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import Spinner from '../Components/Spinner.jsx';
import Button from '../Components/Button.jsx';
import BlankWrapper from '../Components/BlankWrapper.jsx';
import { useAccount } from '../AccountProvider.jsx';

const PARTNER_APPLICATION = gql`
  query($clientId: String!) {
    partnerApplication(clientId: $clientId) {
      name
    }
  }
`;

const AUTHORIZE_PARTNER_APPLICATION = gql`
  mutation($clientId: String!, $redirectUrl: URL!, $state: String) {
    authorizePartnerApplication(clientId: $clientId, redirectUrl: $redirectUrl, state: $state) {
      redirectUrl
    }
  }
`;

const Index = () => {
  const { account } = useAccount();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const clientId = query.get('client_id');
  const redirectUrl = query.get('redirect_uri');
  const state = query.get('state');

  const { loading, data } = useQuery(PARTNER_APPLICATION, { variables: { clientId } });

  const [authorizePartnerApplication] = useMutation(AUTHORIZE_PARTNER_APPLICATION);

  const handleAuthorize = React.useCallback(() => {
    const doAuthorize = async () => {
      const { data } = await authorizePartnerApplication({
        variables: { clientId, redirectUrl, state },
      });
      window.location.assign(data.authorizePartnerApplication.redirectUrl);
    };

    doAuthorize();
  }, []);

  if (loading) return <Spinner />;

  if (!data?.partnerApplication) return <p>Client id {clientId} not found.</p>;

  const { partnerApplication } = data;

  return (
    <BlankWrapper>
      <p>{partnerApplication.name} would like to access your data (account: {account.name}).</p>

      <div className="mt-2 text-center">
        <Button label="Authorize" slim onClick={handleAuthorize} />
      </div>
    </BlankWrapper>
  );
};

export default Index;
