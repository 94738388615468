import React from 'react';

const MapPinIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 20" fill="none" {...props}>
    <path
      d="M6.5 9.064a2.085 2.085 0 0 0 2.086-2.09A2.084 2.084 0 0 0 6.5 4.887a2.084 2.084 0 0 0-2.086 2.09A2.085 2.085 0 0 0 6.5 9.063Zm6.5-2.09c0 1.781-.718 3.892-1.75 5.932-1.288 2.546-3.069 4.988-4.558 6.548a.27.27 0 0 1-.389-.005c-1.484-1.554-3.265-3.997-4.553-6.543C.718 10.866 0 8.756 0 6.975a6.49 6.49 0 0 1 1.904-4.601A6.476 6.476 0 0 1 6.5.467c1.794 0 3.42.728 4.596 1.906A6.494 6.494 0 0 1 13 6.975Z"
      fill="currentColor"
    />
  </svg>
);

export default MapPinIcon;
