import React from 'react';
import PropTypes from 'prop-types';
import PercentageChange from './PercentageChange.jsx';

const WowPercentage = ({ weeklySales }) => {
  const array = [...(weeklySales || [])];

  const oneWeekAgo = array.pop().value || 0;
  const twoWeeksAgo = array.pop().value || 0;

  const percentage = Math.round(((oneWeekAgo - twoWeeksAgo) / (twoWeeksAgo || 1)) * 100.0);
  const direction = percentage >= 0 ? 'up' : 'down';

  let color = 'text-leafy-100';
  if (percentage < 8) {
    color = 'text-gray-100';
  }
  if (percentage < 0) {
    color = 'text-red-100';
  }

  return <PercentageChange color={color} direction={direction} percentage={percentage} />;
};

WowPercentage.propTypes = {
  weeklySales: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

export default WowPercentage;
