import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { useAccount } from '../../AccountProvider.jsx';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';
import confirm from '../../Components/ConfirmDialog.jsx';
import { useDocumentTitle } from '../../Hooks/index.js';

const GROWTH_MODEL_QUERY = gql`
  query GetGrowthModel {
    growthModel {
      baselineUpdateLog {
        startedAt
        completedAt
        status
        errorMessage
      }
    }
  }
`;

const RUN_GROWTH_MODEL_UPDATE = gql`
  mutation RunGrowthModelUpdate {
    runGrowthModelUpdate {
      _id
    }
  }
`;

const GROWTH_MODEL_UPDATE = gql`
  mutation UpdateGrowthModel($input: GrowthModelUpdateInput!) {
    updateGrowthModel(input: $input) {
      _id
    }
  }
`;

const GENERATE_PAST_PREDICTIONS = gql`
  mutation GeneratePastPredictions {
    generatePastPredictions
  }
`;

const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($input: UpdatePreferencesInput!) {
    updateAccountPreferences(input: $input) {
      growthPlanOptions {
        growthPlanType
      }
    }
  }
`;

const Planning = () => {
  const { account } = useAccount();
  const { addAlert } = useAlerts();
  useDocumentTitle('Planning Settings');

  const { loading, data } = useQuery(GROWTH_MODEL_QUERY);
  const [runGrowthModelUpdate] = useMutation(RUN_GROWTH_MODEL_UPDATE);
  const [updateGrowthModel] = useMutation(GROWTH_MODEL_UPDATE);
  const [generatePastPredictions] = useMutation(GENERATE_PAST_PREDICTIONS);
  const [updateAccountPreferences] = useMutation(UPDATE_PREFERENCES);

  const handleRunGrowthModelUpdate = async () => {
    await runGrowthModelUpdate({
      update: (cache) => {
        cache.evict({ __typename: 'GrowthModel' });
        cache.gc();
      },
    });
    addAlert('Growth model data updated successfully', { level: 'success' });
  };

  const handleRunPastPredictions = async () => {
    await generatePastPredictions();
    addAlert('Predicting the past in background...', { level: 'success' });
  };

  const onResetAll = async () => {
    if (
      await confirm(
        'Are you sure?\nThis will remove all growth model data for the account and will update inventory forecasts and any existing draft POs.',
      )
    ) {
      const updated = {
        activePlan: [],
        baseline: [],
        growthData: [],
      };
      await updateGrowthModel({
        variables: { input: updated },
        update: (cache) => {
          cache.evict({ _id: cache.identify({ _id: account._id, __typename: 'Account' }) });
          cache.gc();
        },
      });
      addAlert('Plans and predictions have been reset.', { level: 'success' });
    }
  };

  const {
    register,
    handleSubmit,
    // control,
    formState: { errors },
    watch,
  } = useForm({ mode: 'all' });

  const onSubmit = React.useCallback((data) => {
    const doSubmit = async () => {
      try {
        await updateAccountPreferences({ variables: { input: data } });
        addAlert('Changes saved successfully', { level: 'success' });
      } catch (error) {
        addAlert('An error has occurred! Please refresh the page and try again.', { error });
      }
    };

    doSubmit();
  });

  const handleChangeType = async (e) => {
    const input = { growthPlanOptions: { growthPlanType: e.target.value } };
    await updateAccountPreferences({ variables: { input } });
    addAlert('Growth model mode saved, now re-run the growth plan.', { level: 'success' });
  };

  const selectedGrowthPlanType =
    watch('growthPlanType') || account.preferences?.growthPlanOptions?.growthPlanType || 'simple';

  return (
    <div className="m-3 mt-6 rounded-lg bg-white px-6 pb-7 pt-5 text-midnight-100 shadow">
      <h5 className="mb-3 border-b border-gray-50 pb-4 text-sm font-bold">Planning</h5>
      {!loading && data.growthModel?.baselineUpdateLog && (
        <div className="mt-4 text-xs md:whitespace-nowrap">
          <p>
            <strong>Status:</strong> {data.growthModel?.baselineUpdateLog?.status}
          </p>
          <p>
            <strong>Started:</strong> {data.growthModel?.baselineUpdateLog?.startedAt}
          </p>
          <p>
            <strong>Finished:</strong> {data.growthModel?.baselineUpdateLog?.completedAt}
          </p>
          {data.growthModel?.baselineUpdateLog?.errorMessage && (
            <>
              <p>
                <strong>Error message:</strong>
              </p>
              <textarea className="border-none text-xs" id="items" rows="2" cols="120">
                {data.growthModel?.baselineUpdateLog?.errorMessage}
              </textarea>
            </>
          )}
        </div>
      )}
      <div className="mt-4 space-x-2 text-xs md:whitespace-nowrap">
        {account.onboarding.hasOrdersSyncCompleted && (
          <>
            <Button
              label="Generate growth predictions"
              variant="secondary"
              onClick={handleRunGrowthModelUpdate}
              slim
            />
            <Button
              label="Reset plans &amp; predictions"
              variant="secondary"
              onClick={onResetAll}
              slim
            />
            <Button
              label="Generate past predictions"
              variant="secondary"
              onClick={handleRunPastPredictions}
              slim
            />
          </>
        )}
      </div>
      <div className="mt-4 w-1/2">
        <label htmlFor="growthPlanType">
          Growth Plan Type
          <select
            id="growthPlanType"
            className="input"
            defaultValue={account.preferences?.growthPlanOptions?.growthPlanType || 'simple'}
            onChange={handleChangeType}
          >
            <option value="simple">Simple</option>
            <option value="arima">ARIMA</option>
            <option value="flat">Flat (this disables baseline calculations)</option>
          </select>
        </label>
      </div>
      <div className="mt-8 w-1/2">
        <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
          {(!selectedGrowthPlanType || selectedGrowthPlanType === 'simple') && (
            <>
              <h2 className="mt-4 text-base">Simple Options</h2>
              <div className="mt-4">
                <label htmlFor="growthPlanOptions_maximumGrowthValue">
                  Prediction Cap
                  <div className="relative">
                    <input
                      type="number"
                      step="any"
                      min="0"
                      id="growthPlanOptions_maximumGrowthValue"
                      className={classnames(
                        'input pr-14',
                        errors.growthPlanOptions?.maximumGrowthValue && 'input-error',
                      )}
                      placeholder="Default: 4"
                      defaultValue={account.preferences.growthPlanOptions?.maximumGrowthValue}
                      {...register('growthPlanOptions[maximumGrowthValue]', {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: "Growth cap value can't be negative",
                        },
                      })}
                    />
                  </div>
                </label>
                {errors.growthPlanOptions?.maximumGrowthValue && (
                  <p className="mt-2 text-xs text-red-100">
                    {errors.growthPlanOptions?.maximumGrowthValue.message}
                  </p>
                )}
              </div>
            </>
          )}
          {selectedGrowthPlanType === 'arima' && (
            <>
              <h2 className="mt-4 text-base">ARIMA Options</h2>
              <div className="mt-4">
                <label htmlFor="growthPlanOptions_horizon">
                  Horizon
                  <div className="relative">
                    <input
                      type="number"
                      step="1"
                      min="0"
                      id="growthPlanOptions_horizon"
                      className={classnames(
                        'input pr-14',
                        errors.growthPlanOptions?.horizon && 'input-error',
                      )}
                      placeholder="Default: 12 months"
                      defaultValue={account.preferences.growthPlanOptions?.horizon}
                      {...register('growthPlanOptions[horizon]', {
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: "Horizon months value can't be negative",
                        },
                      })}
                    />
                    <span className="absolute right-4 top-4">months </span>
                  </div>
                </label>
                {errors.growthPlanOptions?.horizon && (
                  <p className="mt-2 text-xs text-red-100">
                    {errors.growthPlanOptions?.horizon.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label htmlFor="granularity">
                  Granularity
                  <select
                    id="growthPlanOptions_granularity"
                    className="input"
                    defaultValue={account.preferences.growthPlanOptions?.granularity || 'monthly'}
                    {...register('growthPlanOptions[granularity]')}
                  >
                    <option value="monthly">Month</option>
                    <option value="daily">Day</option>
                  </select>
                </label>
              </div>

              <div className="mt-4">
                <div>
                  <label htmlFor="growthPlanOptions_lookback">
                    Lookback Period
                    <div className="relative">
                      <input
                        type="number"
                        step="1"
                        min="0"
                        id="growthPlanOptions_lookback"
                        className={classnames(
                          'input pr-14',
                          errors.growthPlanOptions?.lookback && 'input-error',
                        )}
                        placeholder="Default: 12 months"
                        defaultValue={account.preferences.growthPlanOptions?.lookback}
                        {...register('growthPlanOptions[lookback]', {
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message: "Lookback value can't be negative",
                          },
                        })}
                      />
                      <span className="absolute right-4 top-4">
                        {account.preferences.growthPlanOptions?.granularity === 'monthly'
                          ? 'months'
                          : 'weeks'}
                      </span>
                    </div>
                  </label>
                  {errors.growthPlanOptions?.lookback && (
                    <p className="mt-2 text-xs text-red-100">
                      {errors.growthPlanOptions?.lookback.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2">
                {['d', 'D', 'm'].map((key) => (
                  <div className="mt-4" key={key}>
                    <label htmlFor={`growthPlanOptions_params_${key}`}>
                      {key}

                      <div className="relative">
                        <input
                          type="number"
                          step="1"
                          min="0"
                          id={`growthPlanOptions_params_${key}`}
                          className={classnames(
                            'input',
                            errors.growthPlanOptions?.[key] && 'input-error',
                          )}
                          defaultValue={account.preferences.growthPlanOptions?.[key]}
                          {...register(`growthPlanOptions[${key}]`, {
                            valueAsNumber: true,
                            min: {
                              value: 0,
                              message: `${key} value can't be negative`,
                            },
                          })}
                        />
                      </div>
                    </label>
                    {errors.growthPlanOptions?.[key] && (
                      <p className="mt-2 text-xs text-red-100">
                        {errors.growthPlanOptions?.[key].message}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="mt-4">
            <Button type="submit" variant="primary" label="Save Growth Plan Options" slim />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Planning;
