/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiPlus } from 'react-icons/fi';
import Button from '../../Components/Button.jsx';
import confirm from '../../Components/ConfirmDialog.jsx';
import DeleteButton from '../../Components/DeleteButton.jsx';
import LoadingIcon from '../../Components/LoadingIcon.jsx';
import PageHeader from '../../Components/PageHeader.jsx';
import Spinner from '../../Components/Spinner.jsx';
import { useDocumentTitle } from '../../Hooks/index.js';

export const compactNumber = (number) =>
  number.toLocaleString(navigator.language, {
    notation: 'compact',
  });

export const PLANNED_SKUS_QUERY = gql`
  query GetPlannedSkus {
    plannedSkus(first: 100) {
      edges {
        node {
          _id
          name
          launchDate
          multipleVariants
          similarSkus
          simulationsCount
          stats {
            sales30
            sales60
            sales90
          }
        }
      }
    }
  }
`;

const REMOVE_PLANNED_SKU = gql`
  mutation RemovePlannedSku($_id: ID!) {
    removePlannedSku(_id: $_id) {
      _id
    }
  }
`;

const Index = () => {
  useDocumentTitle('Planned Products');
  const [skuIdToDelete, setSkuIdToDelete] = useState(null);

  const { loading, data } = useQuery(PLANNED_SKUS_QUERY);

  const [removePlannedSku] = useMutation(REMOVE_PLANNED_SKU, {
    refetchQueries: [PLANNED_SKUS_QUERY],
    onCompleted: () => {
      setSkuIdToDelete(null);
    },
  });

  const handleRemoveSku = async (e, _id) => {
    e.preventDefault();
    if (await confirm('Are you sure you want to remove this planned product?')) {
      setSkuIdToDelete(_id);
      removePlannedSku({ variables: { _id } });
    }
  };

  return (
    <div className="text-midnight-100">
      <PageHeader text="Planned Products" />

      <div className="mt-8 flex h-[28px] justify-end">
        {data?.plannedSkus?.edges?.length > 0 && (
          <Button label="Add Planned Product" icon={FiPlus} href="new" slim />
        )}
      </div>

      {loading && (
        <div className="flex h-96 w-full items-center justify-center ">
          <Spinner />
        </div>
      )}

      {data?.plannedSkus?.edges?.length > 0 ? (
        <ul className="mt-8 space-y-4">
          {data.plannedSkus.edges.map(
            ({ node: { _id, name, launchDate, stats, simulationsCount = 0 } }) => (
              <li
                key={_id}
                className="rounded-lg border border-transparent bg-white shadow transition-shadow hover:border-purple-90 hover:bg-leafy-10"
              >
                <Link to={`${_id}`} className="flex items-center justify-start p-4 text-sm">
                  <div className="w-32 shrink-0">
                    <span className="ml-2 rounded-full bg-leafy-10/50 px-4 py-1 align-middle text-purple-100">
                      {new Date(launchDate).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="grow truncate font-bold">
                    <span>{name}</span>
                    {simulationsCount > 0 ? (
                      <span className="ml-2 text-sm font-normal">
                        ({simulationsCount + 1} simulations)
                      </span>
                    ) : null}
                  </div>
                  <div className="w-36 shrink-0">
                    <span className="font-bold">
                      {compactNumber(
                        (stats?.sales30 || 0) + (stats?.sales60 || 0) + (stats?.sales90 || 0),
                      )}
                    </span>
                    &nbsp;units
                  </div>
                  {skuIdToDelete && skuIdToDelete === _id ? (
                    <div className="inline-flex h-6 w-6 items-center justify-center">
                      <LoadingIcon className="stroke-gray-100 text-midnight-100" />
                    </div>
                  ) : (
                    <DeleteButton onDelete={(e) => handleRemoveSku(e, _id)} />
                  )}
                </Link>
              </li>
            ),
          )}
        </ul>
      ) : (
        !loading && (
          <div className="flex items-center">
            <div className="relative">
              <div className="mt-16 font-bold">You don't have any Planned Products</div>
              <div className="mt-5 whitespace-nowrap text-sm">
                Calculate how much inventory you need for your new product
                <br /> launches by running different calculations with your existing products.
              </div>
              <div className="flex">
                <div className="mt-8 text-base">
                  <Button icon={FiPlus} href="new" label="Plan a New Product" slim />
                </div>
                <div className="ml-8 mt-10 w-28">
                  <img src="/wobbly-arrow-to-left.svg" alt="Pointer Arrow" />
                </div>
              </div>
            </div>
            <div className="ml-12">
              <img src="/empty-state-product-planning.svg" alt="Planned Products Empty List" />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Index;
