import { isWithinInterval, parseISO } from 'date-fns';

export const getSafetyStock = (
  firstOutOfStockDate,
  reserveInventoryHorizon,
  { reserveInventoryByDate = [], bundleReserveInventoryByDate = [] },
) => {
  const interval = {
    start: parseISO(firstOutOfStockDate),
    end: parseISO(reserveInventoryHorizon),
  };

  let safetyStock = reserveInventoryByDate
    .filter(({ date }) => isWithinInterval(parseISO(date), interval))
    .reduce((sum, curr) => sum + curr.reserveInventory, 0);

  safetyStock += bundleReserveInventoryByDate
    .filter(({ date }) => isWithinInterval(parseISO(date), interval))
    .reduce((sum, curr) => sum + curr.reserveInventory, 0);

  return safetyStock;
};
