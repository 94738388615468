export const calculateLostSales = (sku, locations) => {
  if (sku.isOnBackorder) return 0;

  return locations.map((location) => (
    location.forecastedInventoryQuantities.reduce((totalLostSales, item, currentIndex, allItems) => {
      if (currentIndex === 0) return totalLostSales;

      const openingInventoryQuantity = allItems[currentIndex - 1].inventoryQuantity;

      return openingInventoryQuantity >= 0 && item.sales - openingInventoryQuantity > 0
        ? totalLostSales + item.sales - openingInventoryQuantity
        : totalLostSales;
    }, 0)
  )).reduce((sales, locationSales) => sales + locationSales, 0);
};

export const calculateOutOfStockDays = (sku, locations) => {
  if (sku.isOnBackorder) return 0;

  return locations.map((location) => {
    if (!location.isShippingEnabled) return 0;

    return location.forecastedInventoryQuantities?.filter(
      ({ inventoryQuantity }) => inventoryQuantity <= 0,
    ).length;
  }).reduce((days, locationDays) => days + locationDays, 0);
};
