import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Rank = ({ category, velocity, className = '' }) => {
  let color = 'text-leafy-100';
  let background = 'bg-leafy-75';
  let formattedVelocity;
  if ((category === 'A' && velocity === 'MEDIUM') || (category === 'B' && velocity === 'HIGH')) {
    color = 'text-leafy-100';
    background = 'bg-leafy-50';
  } else if (
    (category === 'A' && velocity === 'LOW') ||
    (category === 'B' && velocity === 'MEDIUM') ||
    (category === 'C' && velocity === 'HIGH')
  ) {
    color = 'text-yellow-100';
    background = 'bg-yellow-10';
  } else if (
    (category === 'B' && velocity === 'LOW') ||
    (category === 'C' && velocity === 'MEDIUM')
  ) {
    color = 'text-yellow-100';
    background = 'bg-yellow-75';
  } else if (category === 'C' && velocity === 'LOW') {
    color = 'text-red-100';
    background = 'bg-red-75';
  }

  if (velocity === 'HIGH') {
    formattedVelocity = '+';
  } else if (velocity === 'LOW') {
    formattedVelocity = '-';
  } else {
    formattedVelocity = '';
  }

  return (
    <div
      className={classnames(
        'flex h-6 w-6 items-center justify-center rounded text-xs font-medium',
        background,
        color,
        className,
      )}
    >
      {category}
      {formattedVelocity}
    </div>
  );
};

Rank.propTypes = {
  category: PropTypes.string.isRequired,
  velocity: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Rank.defaultProps = {
  className: null,
};

export default Rank;
