import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';

const Spinner = ({ className, color }) => {
  const colorMap = {
    purple: 'border-purple-100 border-l-transparent',
    gray: 'border-gray-100 border-l-transparent',
  };

  return <div className={`spinner ${colorMap[color]} ${className}`} />;
};

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['purple', 'gray']),
};

Spinner.defaultProps = {
  className: '',
  color: 'purple',
};
export default Spinner;
