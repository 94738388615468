import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import Panel from '../../Dashboard/Widgets/Widget.jsx';
import { getSkuPagePath } from '../utils/helpers.js';

const FinishedProductsWidget = ({ finishedProducts = [] }) => (
  <Panel barColor="purple" className="col-span-4 h-auto self-start">
    <Panel.Title>Finished Products</Panel.Title>
    <Panel.Content>
      <div className="grid grid-cols-[1.25fr,1.5fr] items-center pb-2 text-xs font-bold">
        <div className="pl-1 text-left">SKU</div>
        <div className="text-right">Component Units</div>
      </div>
      {finishedProducts.map((finishedProduct) => (
        <div
          key={finishedProduct.sku}
          className="group grid grid-cols-[1fr,1.5fr] items-center text-xs"
        >
          <div className="flex rounded-l py-2 group-hover:bg-leafy-10">
            <div className="flex items-center rounded bg-leafy-10">
              <Link to={getSkuPagePath(finishedProduct.sku)} className="w-20">
                <div className="truncate rounded-l py-1" title={finishedProduct.sku}>
                  <span className="ml-1 uppercase">{finishedProduct.sku}</span>
                </div>
              </Link>
              <div className="rounded-r p-1">
                <FiExternalLink className="text-gray-100" />
              </div>
            </div>
          </div>
          <div className="py-3 pr-1 text-right group-hover:bg-leafy-10">
            {finishedProduct.quantity}
          </div>
        </div>
      ))}
    </Panel.Content>
  </Panel>
);

FinishedProductsWidget.propTypes = {
  finishedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default FinishedProductsWidget;
