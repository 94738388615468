import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const USER_QUERY = gql`
  query GetUser {
    user {
      email
      firstName
      lastName
      fullName
    }
  }
`;

const USER_UPDATE = gql`
  mutation UpdateUser($input: UserInput!) {
    updateUser(input: $input) {
      email
    }
  }
`;

const ProfileForm = ({ user }) => {
  const { addAlert } = useAlerts();
  useDocumentTitle('Profile Settings');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const [updateUser] = useMutation(USER_UPDATE);

  const onSubmit = async (data) => {
    try {
      await updateUser({ variables: { input: data } });
      addAlert('Changes saved successfully', { level: 'success' });
    } catch (error) {
      addAlert('An error has occurred! Please refresh the page and try again.', { error });
    }
  };

  return (
    <div className="col-span-4 text-midnight-100">
      <h5 className="text-xl">Profile</h5>
      <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="profile_email">
            Email
            <input
              type="email"
              id="profile_email"
              className={cn('input', errors.email && 'input-error')}
              defaultValue={user.email}
              {...register('email', {
                required: 'Please provide a valid email',
                pattern: {
                  value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                  message: 'Please provide a valid email',
                },
              })}
            />
          </label>
          <InputErrorMessage message={errors.email?.message} />
        </div>
        <div className="mt-4">
          <label htmlFor="profile_full_name">
            Name
            <input
              type="text"
              id="profile_full_name"
              className={cn('input', errors.fullName && 'input-error')}
              defaultValue={user.fullName}
              {...register('fullName', { required: 'Please provide your full name' })}
            />
          </label>
          <InputErrorMessage message={errors.fullName?.message} />
        </div>
        <div className="mt-8">
          <Button type="submit" fullWidth variant="primary" label="Save changes" />
        </div>
      </form>
    </div>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
};

const Profile = () => {
  const { loading, data } = useQuery(USER_QUERY);

  if (loading || !data) return <Spinner />;

  return <ProfileForm user={data.user} />;
};

export default Profile;
