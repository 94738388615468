import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useAccount } from '../../AccountProvider.jsx';
import { SkeletonBlock } from '../../Components/Skeletons.jsx';
import Tag from '../../Components/Tag.jsx';
import Widget from './Widget.jsx';

const PurchaseOrdersWidget = ({ draftPurchaseOrders, loading }) => {
  const { account } = useAccount();
  const { count, workingCapital } = draftPurchaseOrders;

  const currencyOptions = {
    style: 'currency',
    currency: account.preferences?.currency || 'USD',
    notation: 'compact',
    maximumFractionDigits: 2,
  };

  return (
    <Widget>
      <Tag color="purple" hasDot hasDashedBorder>
        Purchase orders
      </Tag>
      {loading && <SkeletonBlock className="mt-6 h-[91px]" />}
      {!loading && (
        <>
          <Widget.Content>
            {count > 0 ? (
              <>
                <p>
                  <span className="text-2xl font-bold leading-6">{count}</span>&nbsp; purchase&nbsp;
                  {pluralize('order', count)} will need to be submitted soon
                </p>
                <p>
                  Estimated working capital needed:&nbsp;
                  <span className="text-base font-bold leading-6">
                    {workingCapital.toLocaleString(navigator.language, currencyOptions)}
                  </span>
                </p>
              </>
            ) : (
              <p>You don&apos;t have any Draft Purchase Orders.</p>
            )}
          </Widget.Content>
          <Widget.Link to="/purchase-orders?status=DRAFT">
            {count > 0 ? 'View Purchase Orders' : 'Create a Purchase Order'}
          </Widget.Link>
        </>
      )}
    </Widget>
  );
};

PurchaseOrdersWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  draftPurchaseOrders: PropTypes.shape({
    count: PropTypes.number,
    workingCapital: PropTypes.number,
  }).isRequired,
};

export default PurchaseOrdersWidget;
