import React from 'react';
import PropTypes from 'prop-types';
import { FiCheck, FiRefreshCw } from 'react-icons/fi';
import { format } from 'date-fns';
import { SkeletonBlock } from '../Components/Skeletons.jsx';
import OnboardingChecklists from './OnboardingChecklists.jsx';

const SyncStatusItem = ({ status, completedText, inProgressText }) => (
  <div>
    {status ? (
      <div className="flex items-center">
        <div className="rounded-full bg-leafy-50 p-1.5 text-leafy-100">
          <FiCheck />
        </div>
        <p className="ml-2">{completedText}</p>
      </div>
    ) : (
      <div className="flex items-center">
        <div className="rounded-full bg-yellow-10 p-1.5 text-yellow-100">
          <FiRefreshCw className="animate-spin" />
        </div>
        <p className="ml-2">{inProgressText}</p>
      </div>
    )}
  </div>
);

SyncStatusItem.propTypes = {
  status: PropTypes.bool,
  completedText: PropTypes.string.isRequired,
  inProgressText: PropTypes.string.isRequired,
};

SyncStatusItem.defaultProps = {
  status: false,
};

const Onboarding = ({ account, user, supportsInventoryQuantities }) => {
  const hasSkusSyncCompleted = account.onboarding?.hasSkusSyncCompleted;
  const hasOrdersSyncCompleted = account.onboarding?.hasOrdersSyncCompleted;
  const hasPurchaseOrdersSyncCompleted = account.onboarding?.hasPurchaseOrdersSyncCompleted;
  const hasBasicSyncCompleted = account.onboarding?.hasBasicSyncCompleted;
  const hasInventorySyncCompleted = account.onboarding?.hasInventorySyncCompleted;
  const hasSourceConnected = account.sources?.length > 0;

  return (
    <>
      <header className="text-midnight-100">
        <div className="font-semibold uppercase text-midnight-75">
          {format(new Date(), 'cccc, LLLL d')}
        </div>
        <h2 className="mt-2 text-2xl">
          <span className="font-bold">Welcome to Cogsy, {user.firstName}! 👋</span>
        </h2>
        <p className="mt-8">Let&apos;s get your account setup.</p>
      </header>

      <div className="mt-8 flex space-x-12">
        <OnboardingChecklists>
          {(pf) => (
            <>
              {!pf.productFruitsHaveLoaded && (
                <div className="w-1/2 rounded-lg bg-white p-7 shadow">
                  <SkeletonBlock className="h-[550px]" />
                </div>
              )}

              {pf.productFruitsHaveLoaded &&
                (pf.showFirstChecklist || pf.showSecondChecklist || pf.showThirdChecklist) && (
                  <div className="w-1/2 rounded-lg bg-white p-7 shadow">
                    {pf.showFirstChecklist && (
                      <div>
                        <div ref={pf.firstChecklistContainer} />
                      </div>
                    )}
                    {(pf.showSecondChecklist || pf.showThirdChecklist) && (
                      <div className="mt-7 border-t border-gray-50 pt-7">
                        <div ref={pf.secondChecklistContainer} />
                        <div ref={pf.thirdChecklistContainer} />
                      </div>
                    )}
                  </div>
                )}
            </>
          )}
        </OnboardingChecklists>

        {hasSourceConnected && (
          <div className="w-1/2 space-y-4">
            <SyncStatusItem
              status={hasBasicSyncCompleted}
              completedText="Your vendors have been synced."
              inProgressText="We are syncing your vendors."
            />

            <SyncStatusItem
              status={hasSkusSyncCompleted}
              completedText="Your products have been synced."
              inProgressText="We are syncing your products."
            />

            {supportsInventoryQuantities && (
              <SyncStatusItem
                status={hasInventorySyncCompleted}
                completedText="Your inventory have been synced."
                inProgressText="We are syncing your inventory."
              />
            )}

            <SyncStatusItem
              status={hasOrdersSyncCompleted}
              completedText="Your orders have been synced."
              inProgressText="We are syncing your orders."
            />

            <SyncStatusItem
              status={hasPurchaseOrdersSyncCompleted}
              completedText="Your purchase orders have been synced."
              inProgressText="We are syncing your purchase orders."
            />
          </div>
        )}
      </div>
    </>
  );
};

Onboarding.propTypes = {
  account: PropTypes.shape({
    onboarding: PropTypes.shape({
      hasOrdersSyncCompleted: PropTypes.bool,
      hasSkusSyncCompleted: PropTypes.bool,
      hasBasicSyncCompleted: PropTypes.bool,
      hasPurchaseOrdersSyncCompleted: PropTypes.bool,
      hasInventorySyncCompleted: PropTypes.bool,
    }),
    sources: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
  supportsInventoryQuantities: PropTypes.bool,
};

Onboarding.defaultProps = {
  supportsInventoryQuantities: false,
};

export default Onboarding;
