import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FiCheck, FiCopy } from 'react-icons/fi';
import classnames from 'classnames';
import { useAlerts } from './AlertsProvider.jsx';

const CopyButton = ({ clipboardValue, copySubject }) => {
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef(null);
  const { addAlert } = useAlerts();

  const handleCopy = async () => {
    inputRef.current.focus();
    inputRef.current.select();
    try {
      await navigator.clipboard.writeText(clipboardValue);
      setIsCopied(true);
    } catch {
      addAlert(`Failed to copy the ${copySubject}! Please refresh the page and try again.`);
    }
    inputRef.current.blur();
  };

  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);
  return (
    <div className="relative">
      {isCopied && (
        <div className="absolute -left-10 bottom-7 w-max rounded bg-purple-100 px-2 py-1 text-white shadow-lg">
          {copySubject} copied!
        </div>
      )}
      <button
        className={classnames(
          'flex h-4 w-4 items-center justify-center rounded-full',
          isCopied && 'bg-leafy-75',
        )}
        onClick={handleCopy}
        type="button"
      >
        <input
          className="-ml-px w-[1px] opacity-0"
          value={clipboardValue}
          ref={inputRef}
          readOnly
        />
        {isCopied ? (
          <FiCheck className="stroke-leafy-100" size={10} />
        ) : (
          <FiCopy className="stroke-purple-100" size={10} />
        )}
      </button>
    </div>
  );
};

CopyButton.propTypes = {
  clipboardValue: PropTypes.string.isRequired,
  copySubject: PropTypes.string.isRequired,
};

export default CopyButton;
