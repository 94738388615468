import React from 'react';
import { gql, useMutation } from '@apollo/client';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const ACCOUNT_UPDATE = gql`
  mutation UpdateAccount($input: AccountInput!) {
    updateAccount(input: $input) {
      _id
    }
  }
`;

const Account = () => {
  const { account } = useAccount();
  const { addAlert } = useAlerts();
  useDocumentTitle('Account Settings');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const [updateAccount] = useMutation(ACCOUNT_UPDATE);

  const onSubmit = async (data) => {
    try {
      await updateAccount({ variables: { input: data } });
      addAlert('Changes saved successfully', { level: 'success' });
    } catch (error) {
      addAlert('An error has occurred! Please refresh the page and try again.', { error });
    }
  };

  return (
    <div className="col-span-4 text-midnight-100">
      <h5 className="text-xl">Account</h5>
      <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="account_name">
            Store Name
            <input
              type="text"
              id="account_name"
              className={cn('input', errors.name && 'input-error')}
              defaultValue={account.name}
              {...register('name', {
                required: 'Please provide the store name',
              })}
            />
          </label>
          <InputErrorMessage message={errors.name?.message} />
        </div>
        <div className="mt-4">
          <label htmlFor="account_url">
            Store URL
            <input
              type="url"
              id="account_url"
              className={cn('input', errors.url && 'input-error')}
              defaultValue={account.url}
              {...register('url', {
                required: 'Please provide the store URL',
              })}
            />
          </label>
          <InputErrorMessage message={errors.url?.message} />
        </div>
        <div className="mt-8">
          <Button type="submit" fullWidth variant="primary" label="Save changes" />
        </div>
      </form>
    </div>
  );
};

export default Account;
