/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { SkeletonBlock } from '../../Components/Skeletons.jsx';

const ProductUpdatesWidget = () => (
  <div className="relative h-[592px] overflow-hidden rounded-b-lg bg-white shadow">
    <div className="h-1 bg-purple-100" />
    <div className="bg-white px-7">
      <h3 className="relative z-20 flex items-center bg-white pb-2 pt-7 text-xl font-bold text-midnight-100">
        Product Updates
        <a
          href="https://announcekit.co/cogsy-app/product-updates/"
          target="_blank"
          rel="noreferrer"
          className="ml-2 inline-flex h-6 w-6 items-center justify-center rounded-full text-sm hover:bg-midnight-10"
        >
          <FiExternalLink className="stroke-gray-100" />
        </a>
      </h3>
    </div>
    <div className="relative -mt-12 max-h-full overflow-y-scroll">
      <div className="absolute inset-x-0 top-[60] z-10 h-[38px] bg-white text-midnight-100" />
      <div className="announcekit-widget bg-white px-7 pb-16" />
      <div className="-mt-2 px-7">
        <SkeletonBlock className="h-[476px]" />
      </div>
    </div>
    <div className="absolute bottom-0 left-0 h-16 w-full bg-gradient-to-b from-transparent to-white" />
  </div>
);

export default ProductUpdatesWidget;
