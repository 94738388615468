import React from 'react';
import PropTypes from 'prop-types';
import { gql, useApolloClient } from '@apollo/client';
import { FiCheck } from 'react-icons/fi';
import { useAccount } from '../AccountProvider.jsx';
import ComboBox from '../Components/ComboBox.jsx';

const ACCOUNTS_RESULTS_PER_PAGE = 100;

const ACCOUNTS_QUERY = gql`
  query GetAccounts($after: String, $before: String, $search: String, $first: Int) {
    accounts(after: $after, before: $before, first: $first, search: $search) {
      edges {
        node {
          _id
          name
        }
      }
    }
  }
`;

const ListItem = ({ item, index, highlightedIndex, getItemProps }) => {
  const { account } = useAccount();
  return (
    <li
      className={`${
        highlightedIndex === index ? 'bg-purple-100 text-white' : ''
      } flex cursor-pointer py-2 text-midnight-100 first:rounded-t-lg last:rounded-b-lg`}
      {...getItemProps({
        key: item.node._id,
        item,
        index,
      })}
    >
      {item.node._id === account._id ? (
        <>
          <FiCheck className="mx-1 inline" size={16} />
          <span className="text-left font-bold">
            {item.node.name} ({item.node._id})
          </span>
        </>
      ) : (
        <span className="flex pl-2">
          {item.node.name} ({item.node._id})
        </span>
      )}
    </li>
  );
};

ListItem.propTypes = {
  item: PropTypes.shape({
    node: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  getItemProps: PropTypes.func.isRequired,
};

const AccountSwitcher = ({ className, isInNavbar }) => {
  const client = useApolloClient();
  const [accounts, setAccounts] = React.useState(null);
  const [showSwitcher, setShowSwitcher] = React.useState(false);
  const { account, axios } = useAccount();

  const currentAccount = accounts?.edges?.find((acc) => acc?.node?._id === account?._id);

  const switchAccount = async (accountId) => {
    if (accountId === account?._id) return;

    const {
      data: { token },
    } = await axios(`/auth/switch/${accountId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });

    window.localStorage.setItem('token', token);
    window.location.assign('/');
  };

  const onSelectItemChange = (item) => {
    switchAccount(item.node._id);
  };

  const doSearch = async (searchText) => {
    if (searchText === currentAccount?.node?.name) {
      return;
    }
    const { data } = await client.query({
      query: ACCOUNTS_QUERY,
      variables: searchText
        ? {
            first: ACCOUNTS_RESULTS_PER_PAGE,
            search: searchText,
          }
        : {
            first: ACCOUNTS_RESULTS_PER_PAGE,
          },
    });
    setAccounts(data?.accounts ?? { edges: [] });
    if (!showSwitcher && data?.accounts?.edges?.length > 1) {
      setShowSwitcher(true);
    }
  };

  const itemToString = (item) => item?.node?.name ?? '';

  const itemToId = (item) => item?.node?._id ?? '';

  React.useEffect(() => {
    doSearch('');
  }, []);

  const onSearchBoxReset = () => {
    doSearch('');
  };

  if (!accounts || !showSwitcher) return null;

  return (
    <div className={`w-full ${className}`}>
      <h4 className="mt-3 w-full text-xs">Switch account</h4>
      <ComboBox
        results={accounts?.edges}
        onSelectedItemChange={onSelectItemChange}
        doSearch={doSearch}
        listItem={ListItem}
        containerClassName="w-full"
        selectedItemIndex={accounts?.edges?.indexOf(currentAccount)}
        initialSearchText={account?.name}
        itemToString={itemToString}
        itemToId={itemToId}
        listClassName={isInNavbar ? 'transform -translate-y-full w-52 top-0' : ''}
        onReset={onSearchBoxReset}
      />
    </div>
  );
};

AccountSwitcher.propTypes = {
  className: PropTypes.string,
  isInNavbar: PropTypes.bool,
};

AccountSwitcher.defaultProps = {
  className: '',
  isInNavbar: false,
};

export default AccountSwitcher;
