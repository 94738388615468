import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@headlessui/react';
import Button from '../Components/Button.jsx';

const RemoveSourcePopup = ({ open, onClose, handleRemoveSource }) => {
  const [confirmationText, setConfirmationText] = React.useState('');

  const handleConfirm = React.useCallback(
    (e) => {
      e.preventDefault();

      if (confirmationText === 'DELETE') {
        const doConfirm = async () => {
          await handleRemoveSource();
        };

        doConfirm();
        onClose();
      }
    },
    [open, confirmationText],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Remove Source</Dialog.Title>

          <div className="mt-4">
            <label htmlFor="confirmation-text">
              <span className="font-bold">This action is irreversible.</span> To confirm that you
              want to remove this source, type &lsquo;DELETE&rsquo; and click remove.
              <input
                type="text"
                id="confirmation-text"
                value={confirmationText}
                onChange={(e) => setConfirmationText(e.target.value)}
                required
                className="input"
                name="confirmation-text"
                placeholder="DELETE"
              />
            </label>
          </div>
          <div className="mt-8 flex items-center justify-end space-x-2">
            <Button label="Cancel" variant="text" onClick={onClose} slim />
            <Button label="Remove" onClick={handleConfirm} slim />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

RemoveSourcePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRemoveSource: PropTypes.func.isRequired,
};

export default RemoveSourcePopup;
