const sourceFields = {
  sourceId: 'cogsy-test.myshopify.com',
  sourceType: 'shopify',
  sourceUrl: 'https://google.com',
};

const coreFields = {
  vendor: {
    _id: '12345',
    name: 'Demo Vendor',
  },
  vendorName: 'Demo Vendor',
  variantName: 'By Cogsy',
  availableForSale: true,
  active: true,
  isOnBackorder: false,
};

export const sourceTemplate = {
  ...sourceFields,
  locations: [
    {
      ...sourceFields,
      locationId: 'location-1',
      location: 'Demo Location 1',
    },
    {
      ...sourceFields,
      locationId: 'location-2',
      location: 'Demo Location 2',
    },
  ],
};

export const skuTemplate = {
  ...coreFields,
  purchaseOrders: {
    edges: [],
  },
  transfers: { edges: [] },
  sources: [{ ...sourceTemplate }],
};

export const skus = {
  'demo-sku-1': {
    details: {
      _id: '_id123451',
      variantId: '_id123451',
      productName: 'Plate - 20cm',
      price: 21,
      cost: 4,
      ltv: 501,
      inventoryQuantity: 1814,
    },
    stats: {
      category: 'A',
      velocity: 'HIGH',
      targetInventoryQuantity: 2412,
      totalQuantitySold: 44031,
      totalSkuSales: 220155,
      averageOrderValue: 57,
      daysLeft: 13,
      incomingInventoryQuantity: 800,
      inProductionInventoryQuantity: 0,
      locations: [],
      firstOutOfStockDate: 13,
      replenishmentStatus: 'replenish',
    },
    dataPattern: [260, 200, 150, 700, 100, 100, 50, 400, 500, 350],
  },
  'demo-sku-2': {
    details: {
      _id: '_id123452',
      variantId: '_id123452',
      productName: 'Knife - Stainless steel',
      price: 14,
      cost: 10,
      ltv: 101,
      inventoryQuantity: 1158,
      bundlesContainingSku: [
        {
          sku: 'demo-sku-4',
          bundledSkus: [
            { sku: 'demo-sku-3', bundledQuantity: 1 },
            { sku: 'demo-sku-2', bundledQuantity: 1 },
          ],
        },
      ],
    },
    stats: {
      category: 'B',
      velocity: 'HIGH',
      targetInventoryQuantity: 1200,
      totalQuantitySold: 20129,
      totalSkuSales: 12093,
      averageOrderValue: 21,
      daysLeft: 270,
      incomingInventoryQuantity: 700,
      inProductionInventoryQuantity: 0,
      locations: [],
      replenishmentStatus: 'replenish',
    },
    dataPattern: [120, 100, 50, 700, 100, 100, 50, 150, 400, 500],
  },
  'demo-sku-3': {
    details: {
      _id: '_id123453',
      variantId: '_id123453',
      productName: 'Fork - Stainless steel',
      price: 6,
      cost: 3,
      ltv: 30,
      inventoryQuantity: 300,
      bundlesContainingSku: [
        {
          sku: 'demo-sku-4',
          bundledSkus: [
            { sku: 'demo-sku-3', bundledQuantity: 1 },
            { sku: 'demo-sku-2', bundledQuantity: 1 },
          ],
        },
      ],
    },
    stats: {
      category: 'C',
      velocity: 'LOW',
      targetInventoryQuantity: 1500,
      totalQuantitySold: 240,
      totalSkuSales: 560,
      averageOrderValue: 12,
      daysLeft: 342,
      incomingInventoryQuantity: 100,
      locations: [],
      replenishmentStatus: 'replenish',
    },
    dataPattern: [20, 40, 20, 10, 10, 10, 50, 150, 25, 150],
  },
  'demo-sku-4': {
    details: {
      _id: '_id123454',
      variantId: '_id123454',
      productName: 'Knife & Fork set',
      price: 6,
      cost: 3,
      ltv: 30,
      isBundle: true,
      bundledSkus: [
        { sku: 'demo-sku-2', bundledQuantity: 1 },
        { sku: 'demo-sku-3', bundledQuantity: 1 },
      ],
      inventoryQuantity: 223,
    },
    stats: {
      category: 'A',
      velocity: 'MEDIUM',
      targetInventoryQuantity: 300,
      totalQuantitySold: 240,
      totalSkuSales: 5060,
      averageOrderValue: 12,
      daysLeft: 37,
      incomingInventoryQuantity: 50,
      locations: [],
      firstOutOfStockDate: 37,
      replenishmentStatus: 'replenish',
    },
    dataPattern: [500, 200, 230, 400, 400, 420, 320, 210, 250, 250],
  },
};
