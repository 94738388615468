import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@headlessui/react';
import { confirmable, createConfirmation } from 'react-confirm';
import Button from './Button.jsx';
import CloseButton from './CloseButton.jsx';

const ConfirmDialog = ({ show, proceed, confirmation }) => (
  <Dialog
    open={show}
    onClose={() => proceed(false)}
    className="relative z-50"
    style={{ fontFamily: 'Gilroy, sans-serif' }}
  >
    <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
    <div className="fixed inset-0 flex items-center justify-center">
      <Dialog.Panel className="mx-auto max-w-md rounded bg-white">
        <div className="flex justify-between rounded-t bg-purple-10">
          <Dialog.Title className="p-6 text-xl font-medium text-purple-100">
            {confirmation}
          </Dialog.Title>
          <CloseButton onClose={() => proceed(false)} />
        </div>
        <div className="flex justify-end space-x-2 px-6 pb-6 pt-8">
          <Button label="Cancel" variant="text" slim onClick={() => proceed(false)} />
          <Button label="Yes" slim onClick={() => proceed(true)} />
        </div>
      </Dialog.Panel>
    </div>
  </Dialog>
);

ConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  proceed: PropTypes.func.isRequired,
  confirmation: PropTypes.string.isRequired,
};

const confirm = (confirmation) => createConfirmation(confirmable(ConfirmDialog))({ confirmation });
export default confirm;
