import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiSearch, FiX } from 'react-icons/fi';
import { useQueryParams } from '../Hooks/index.js';

const Search = ({ handleSearch, placeHolder, className }) => {
  const [queryParams] = useQueryParams(['search']);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(queryParams.search || '');
  }, [queryParams.search]);

  const handleFormSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      handleSearch(query);
    },
    [handleSearch, query],
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={`relative flex items-center ${className}`}>
        <FiSearch size={14} strokeWidth={2.5} className="absolute ml-3 text-purple-100" />
        <input
          type="text"
          placeholder={placeHolder}
          className="h-8 w-56 rounded-lg border-midnight-10 pl-8 pr-3 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {query && (
          <FiX
            size={14}
            className="absolute right-3 w-4 cursor-pointer rounded-full text-gray-100 hover:bg-gray-50"
            onClick={() => {
              handleSearch('');
              setQuery('');
            }}
          />
        )}
      </div>
    </form>
  );
};

Search.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  className: PropTypes.string,
};

Search.defaultProps = {
  placeHolder: 'Search products',
  className: '',
};

export default Search;
