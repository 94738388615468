import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { apiUrl } from '../config.js';
import LoadingPage from '../Components/LoadingPage.jsx';

const Callback = () => {
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (window.localStorage.getItem('token')) {
      query.set('state', `${query.get('state')},${window.localStorage.getItem('token')}`);
    }

    window.location.assign(`${apiUrl}/auth/shopify/callback?${query.toString()}`);
  }, []);

  return <LoadingPage />;
};

export default Callback;
