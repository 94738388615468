import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SubscriptionIcon from '../Icons/SubscriptionIcon.jsx';
import Tooltip from './Tooltip.jsx';

const SubscriptionIndicator = ({ isAtRisk, info, tooltipBgColorClass, tooltipTextColorClass }) => (
  <Tooltip>
    <Tooltip.Element>
      <div
        className={classnames(
          'h-6 w-6 shrink-0 rounded-full p-[5px]',
          isAtRisk ? 'bg-red-25 text-red-100' : 'bg-purple-10 text-purple-100',
        )}
      >
        <SubscriptionIcon />
      </div>
    </Tooltip.Element>

    {isAtRisk && info && (
      <Tooltip.Body
        align="left"
        bgColorClass={tooltipBgColorClass}
        textColorClass={tooltipTextColorClass}
      >
        <div className="max-w-xs font-normal">{info}</div>
      </Tooltip.Body>
    )}
  </Tooltip>
);

SubscriptionIndicator.propTypes = {
  isAtRisk: PropTypes.bool,
  info: PropTypes.string,
  tooltipBgColorClass: PropTypes.string,
  tooltipTextColorClass: PropTypes.string,
};

SubscriptionIndicator.defaultProps = {
  isAtRisk: false,
  info: '',
  tooltipBgColorClass: 'bg-purple-100',
  tooltipTextColorClass: 'text-white',
};

export default SubscriptionIndicator;
