import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const CONNECT_WITH_SMARTRR = gql`
  mutation ConnectWithSmartrr($apiKey: String!, $shop: String!) {
    connectWithSmartrr(apiKey: $apiKey, shop: $shop) {
      _id
    }
  }
`;

const SmartrrPopup = ({ open, onClose }) => {
  const { addAlert } = useAlerts();

  const [connectWithSmartrr, { loading }] = useMutation(CONNECT_WITH_SMARTRR);

  const handleSmartrrConnect = React.useCallback(
    (e) => {
      e.preventDefault();

      const variables = Object.fromEntries(new FormData(document.querySelector('form')));

      const connect = async () => {
        await connectWithSmartrr({ variables });
        addAlert('Successfully connected to Smartrr', { level: 'success' });
        onClose();
      };

      connect();
    },
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Smartrr</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleSmartrrConnect}>
            <div>
              <label htmlFor="shopify-domain">
                Shopify domain*
                <input type="text" id="shopify-domain" required className="input" name="shop" />
                <p className="mt-2 text-gray-100">
                  Your Shopify account domain. e.g: <em>example.myshopify.com</em>
                </p>
              </label>
            </div>

            <div className="mt-4">
              <label htmlFor="api-key">
                Smartrr API Key*
                <input type="text" id="api-key" required className="input" name="apiKey" />
                <p className="mt-2 text-gray-100">
                  Click&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold text-purple-100"
                    href="https://app.smartrr.com/admin/configure/integrations"
                  >
                    this link
                  </a>
                  &nbsp;to sign-in to Smartrr and create an API Key for Cogsy
                </p>
              </label>
            </div>

            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

SmartrrPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SmartrrPopup;
