import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { FiCheck, FiX } from 'react-icons/fi';
import axios from 'axios';
import Spinner from '../../Components/Spinner.jsx';
import { fileUploadOptions } from '../../config.js';

const GET_PRESIGNED_URL = gql`
  mutation getPresignedUrl {
    getPresignedUrl(type: bundleImports)
  }
`;

const PROCESS_IMPORT = gql`
  mutation ProcessImport($url: URL!) {
    processImport(url: $url)
  }
`;

const BundleImports = () => {
  const [getPresignedUrl] = useMutation(GET_PRESIGNED_URL);
  const [processImport] = useMutation(PROCESS_IMPORT);

  const [loading, setLoading] = React.useState(false);
  const [uploadResult, setUploadResult] = React.useState({ msg: false });

  /**
   * 1. Request a presigned url from our server
   * 2. Use this to send the file to s3
   * 3. On success, request that the server processes the import
   */
  const doFileUpload = async (selectedFile) => {
    setLoading(true);

    try {
      const {
        data: { getPresignedUrl: url },
      } = await getPresignedUrl({
        variables: { type: 'bundleImports' },
      });

      const response = await axios.put(url, selectedFile, {
        headers: {
          'Content-Type': 'text/csv',
        },
      });

      if (response.status < 400) {
        await processImport({ variables: { url } });

        setUploadResult({
          msg: 'File uploaded successfully. You will recieve an email when the import is complete.',
          ok: true,
        });
      } else {
        setUploadResult({
          msg: `${response.status}: There was an error uploading the file.`,
          ok: false,
        });
      }
    } catch (e) {
      setUploadResult({
        msg: 'There was an error processing the file.',
        ok: false,
      });
    }
    setLoading(false);
  };

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileCorrectSize = selectedFile.size < fileUploadOptions.maxFileSize;

    if (selectedFile.type === 'text/csv' && fileCorrectSize) {
      doFileUpload(selectedFile);
    } else {
      setUploadResult({ msg: fileCorrectSize ? 'Incorrect file type' : 'File must be under 1kb' });
    }
  };

  return (
    <div className="m-3 mt-6 rounded-lg bg-white px-6 pb-7 pt-5 text-sm text-midnight-100 shadow">
      <h5 className="mb-3 border-b border-gray-50 pb-4 text-sm font-bold">Bundle Import</h5>
      <div className="flex items-center">
        <input
          className="block cursor-pointer rounded-lg border-2 border-purple-100 bg-gray-50 p-2 text-sm text-purple-100 focus:outline-none"
          id="file_input"
          type="file"
          onChange={onFileChange}
          accept="text/csv"
        />
        {loading && <Spinner />}
        {uploadResult.msg && uploadResult.ok && (
          <div className="m-1 w-8 rounded-full bg-leafy-50 p-1 text-center text-leafy-100">
            <FiCheck className="inline text-leafy-100" />
          </div>
        )}
        {uploadResult.msg && !uploadResult.ok && (
          <div className="m-1 w-8 rounded-full bg-red-50 p-1 text-center text-leafy-100">
            <FiX className="inline text-red-100" />
          </div>
        )}
      </div>
      {uploadResult.msg ? (
        <p className="mt-4">
          <strong>Upload result</strong>
          <br />
          {uploadResult.msg}
        </p>
      ) : (
        <div className="mx-1">
          <p className="mt-4">Upload a CSV with the following format:</p>
          <table className="mt-2 w-full table-fixed border">
            <tbody>
              <tr className="">
                <td className="border p-1 font-bold">master</td>
                <td className="border p-1 font-bold">child</td>
                <td className="border p-1 font-bold">quantity</td>
              </tr>
              <tr>
                <td className="border p-1">master-sku</td>
                <td className="border p-1">child-sku</td>
                <td className="border p-1">1</td>
              </tr>
            </tbody>
          </table>
          <p className="mt-4">
            Make sure that the file is comma-delimited and sorted by master SKU
          </p>
          <p>SKUs that do not exist on Cogsy will be skipped</p>
        </div>
      )}
    </div>
  );
};

export default BundleImports;
