import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ text }) => (
  <header>
    <h2 className="flex max-w-4xl items-center text-3xl text-midnight-100">{text}</h2>
  </header>
);

PageHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageHeader;
