import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import classnames from 'classnames';

const barColorMap = {
  purple: 'bg-purple-100',
  red: 'bg-red-75',
  yellow: 'bg-yellow-75',
  green: 'bg-leafy-75',
  gradient: 'to-red-75 bg-gradient-to-r from-purple-100',
};

const colorMap = {
  purple: ['bg-purple-10 border-purple-10', 'bg-purple-100'],
  red: ['bg-red-10 border-red-10', 'bg-red-75'],
  yellow: ['bg-yellow-10 border-yellow-10', 'bg-yellow-75'],
};

const Widget = ({ children, barColor, className, ...restProps }) => (
  <div
    className={`h-full rounded-b-lg bg-white text-midnight-100 shadow ${className}`}
    {...restProps}
  >
    <div className={classnames('h-1', barColorMap[barColor])} />
    <div className="flex h-full flex-col p-7">{children}</div>
  </div>
);

Widget.propTypes = {
  children: PropTypes.node.isRequired,
  barColor: PropTypes.oneOf(['purple', 'red', 'yellow', 'green', 'gradient']),
  className: PropTypes.string,
};

Widget.defaultProps = {
  barColor: 'purple',
  className: '',
};

const Title = ({ children, ...restProps }) => (
  <h3 className="text-xl font-bold" {...restProps}>
    {children}
  </h3>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

const Tag = ({ children, color, ...restProps }) => (
  <div
    className={classnames(
      'inline-flex h-5 items-center self-start rounded-full pl-1 pr-2',
      colorMap[color][0],
    )}
    {...restProps}
  >
    <div className={classnames('h-3 w-3 rounded-full', colorMap[color][1])} />
    <h4 className="vertical-fix ml-2 text-xs font-bold uppercase leading-none tracking-widest">
      {children}
    </h4>
  </div>
);

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['purple', 'red', 'yellow']),
};

Tag.defaultProps = { color: 'purple' };

const Content = ({ children, className, ...restProps }) => (
  <div className={classnames('mb-5 mt-6 text-sm leading-6', className)} {...restProps}>
    {children}
  </div>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Content.defaultProps = { className: '' };

const Link = ({ children, to = '', ...restProps }) => (
  <div className="mt-auto" {...restProps}>
    <RouterLink to={to} className="flex items-center text-sm font-bold text-purple-100">
      {children}
      <FiChevronRight size={18} className="ml-1" />
    </RouterLink>
  </div>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

Widget.Title = Title;
Widget.Tag = Tag;
Widget.Content = Content;
Widget.Link = Link;

export default Widget;
