import React from 'react';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import classnames from 'classnames';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import DeleteButton from '../../Components/DeleteButton.jsx';
import LocationFilter from '../../Components/LocationFilter.jsx';
import { toDate } from '../../Utils/dates.js';

const SplitTransfer = ({ purchaseOrder, formTransfer, onRemove, transferIndex, isReadOnly }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const formItems =
    useWatch({
      control,
      name: `transfers.${transferIndex}.items`,
    }) || [];

  const formTransfers =
    useWatch({
      control,
      name: 'transfers',
    }) || [];

  const totalQuantity = formItems.reduce((total, { quantity }) => total + quantity || 0, 0);

  const totalReceived = formItems.reduce(
    (total, { receivedQuantity }) => total + receivedQuantity || 0,
    0,
  );

  const progressBarWidth = totalQuantity !== 0 ? (totalReceived / totalQuantity) * 100 : 0;

  return (
    <div className="w-52 shrink-0 self-start rounded-lg bg-purple-5 p-5 pb-6">
      <div className="flex justify-between font-bold">
        <label htmlFor={`customId-${transferIndex}`}>
          <div className="flex items-center justify-between space-x-2">
            <input
              type="text"
              id={`customId-${transferIndex}`}
              data-testid={`customId-${transferIndex}`}
              className={classnames(
                'mt-1 w-full rounded border border-midnight-10 px-2 py-1 text-sm font-normal focus:border-transparent focus:ring-2 focus:ring-purple-100',
                errors.transfers?.[transferIndex]?.customId && 'input-error',
              )}
              {...register(`transfers.${transferIndex}.customId`, {
                required: 'Please provide a shipment name',
              })}
              disabled={isReadOnly}
            />
            {isReadOnly ? null : <DeleteButton onDelete={onRemove} />}
          </div>
        </label>
      </div>

      <div className="mt-8 font-bold">
        Ship to
        <Controller
          name={`transfers.${transferIndex}.toLocation`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <LocationFilter
              value={value}
              onChange={onChange}
              label="Select a destination"
              showAllLocations={false}
              disabled={isReadOnly}
            />
          )}
          rules={{ required: 'Please select a destination' }}
        />
        {errors.transfers?.[transferIndex]?.toLocation ? (
          <span className="text-red-100">{errors.transfers[transferIndex].toLocation.message}</span>
        ) : null}
      </div>

      <div className="mt-6 font-bold">Item Quantities</div>

      <ul className="mt-6 space-y-4">
        {formTransfer.items.map((item, itemIndex) => {
          const { quantity: availableQuantity } = purchaseOrder.items.find(
            ({ sku }) => item.sku === sku,
          );

          const shippedQuantity = formTransfers
            .filter((_, index) => index !== transferIndex)
            .map(({ items }) => items.find(({ sku }) => sku === item.sku))
            .reduce(
              (total, { quantity, receivedQuantity }) => total + (receivedQuantity ?? quantity),
              0,
            );

          const { quantity: currentQuantity } = formItems.find(({ sku }) => sku === item.sku);

          return (
            <li className="flex h-16 items-center" key={`${transferIndex}-${item.sku}`}>
              <div className="relative">
                <input
                  className={classnames(
                    'w-20 rounded border border-midnight-10 px-2 py-1 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100',
                    errors.transfers?.[transferIndex]?.items?.[itemIndex]?.quantity &&
                      'input-error',
                  )}
                  data-testid={`quantity-${transferIndex}`}
                  type="number"
                  step="1"
                  min="0"
                  {...register(`transfers.${transferIndex}.items.${itemIndex}.quantity`, {
                    required: true,
                    valueAsNumber: true,
                    min: {
                      message: "Quantity can't be negative.",
                      value: 0,
                    },
                  })}
                  title={errors.transfers?.[transferIndex]?.items?.[itemIndex]?.quantity?.message}
                  disabled={isReadOnly}
                />

                {item.receivedQuantity !== null && item.receivedQuantity < item.quantity ? (
                  <div className="absolute left-0 top-9 flex w-44 text-xs text-gray-100">
                    (Undelivered: {item.quantity - item.receivedQuantity})
                  </div>
                ) : null}

                {!isReadOnly && currentQuantity > availableQuantity - shippedQuantity ? (
                  <div className="absolute left-0 top-9 flex w-44 text-xs">
                    <FiAlertTriangle className="mr-1 text-yellow-100" size={14} />
                    Not enough units available
                  </div>
                ) : null}
              </div>
            </li>
          );
        })}
      </ul>
      <div className="mt-4 h-24">
        <div className="font-bold">Total Shipment Quantity</div>

        <div>
          <div className="mt-3 text-xs text-gray-100">Received/Ordered</div>
          <div className="mt-1 text-xs font-bold">
            {`${totalReceived.toLocaleString() || 0} / ${totalQuantity.toLocaleString() || 0}`}
          </div>
          <div className="mt-1 h-1 overflow-hidden rounded-sm bg-purple-50">
            <div className="h-full bg-purple-100" style={{ width: `${progressBarWidth}%` }} />
          </div>
        </div>
      </div>

      {formTransfer.deliveredAt ? (
        <div className="space-y-2 font-bold">
          <div>Delivery date</div>
          <div className="text-xs text-midnight-70">
            {toDate(formTransfer.deliveredAt).toLocaleDateString()}
          </div>
        </div>
      ) : (
        <div>
          <label htmlFor="expectedDeliveryDate">
            <span className="font-bold">Target Delivery Date</span>
            <input
              type="date"
              id="expectedDeliveryDate"
              className={classnames(
                'mt-1 block w-full rounded-lg border-midnight-10 text-xs focus:ring-2 focus:ring-purple-100',
                errors.transfers?.[transferIndex]?.expectedDeliveryDate && 'input-error',
              )}
              {...register(`transfers.${transferIndex}.expectedDeliveryDate`, {
                required: 'Please provide an expected delivery date',
              })}
              disabled={isReadOnly}
            />
          </label>
        </div>
      )}
    </div>
  );
};

SplitTransfer.propTypes = {
  purchaseOrder: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    transfers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
              PropTypes.shape({
                sku: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
              }).isRequired,
            ).isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
  formTransfer: PropTypes.shape({
    _id: PropTypes.string,
    customId: PropTypes.string.isRequired,
    toLocation: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    expectedDeliveryDate: PropTypes.string.isRequired,
    deliveredAt: PropTypes.string,
  }).isRequired,
  transferIndex: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default SplitTransfer;
