import React from 'react';
import PropTypes from 'prop-types';
import { FiCheck } from 'react-icons/fi';
import classnames from 'classnames';
import Button from '../Components/Button.jsx';
import Widget from '../Dashboard/Widgets/Widget.jsx';

const BillingWidget = ({
  hasAllOptions,
  title,
  description,
  price,
  revenueLimit,
  onClick,
  loading,
  isDisabled,
}) => (
  <div className="w-96">
    <Widget
      barColor={hasAllOptions ? 'gradient' : 'purple'}
      className={classnames('pb-2 text-sm', isDisabled ? 'text-gray-75' : 'text-midnight-75')}
    >
      <h3
        className={classnames(
          'text-2xl font-bold',
          isDisabled ? 'text-gray-75' : 'text-midnight-85',
        )}
      >
        {title}
      </h3>
      <div className="mt-1">{description}</div>
      <div
        className={classnames(
          'mt-6 self-start rounded px-2 py-1 text-xs font-bold tracking-[0.01rem]',
          isDisabled ? 'bg-gray-50 text-gray-75' : 'bg-purple-10 text-purple-100',
        )}
      >
        14 day free trial then:
      </div>
      <div className="relative mb-1 mt-2 h-10">
        <div
          className={classnames(
            'absolute text-lg font-bold leading-[3.7rem] text-purple-100 transition-opacity',
            isDisabled ? 'opacity-100' : 'opacity-0 transition-none',
          )}
        >
          Not available on over $5M in revenue
        </div>
        <div
          className={classnames(
            'absolute flex h-full w-full items-end justify-between text-xs transition-opacity',
            isDisabled ? 'opacity-0 transition-none' : 'opacity-100',
          )}
        >
          <div>
            <span className="text-4xl font-bold leading-[0] text-purple-100">
              ${price}
              <span className="font-normal">/</span>
            </span>
            month
          </div>
          <div className="text-xs font-bold">Up to ${revenueLimit} in revenue</div>
        </div>
      </div>
      <Button
        label={loading && !isDisabled ? 'Please wait…' : 'Get started for free'}
        variant={hasAllOptions ? 'primary' : 'tertiary'}
        className={classnames('mt-5 h-10 text-sm', isDisabled && 'pointer-events-none')}
        onClick={onClick}
        disabled={loading || isDisabled}
      />

      <h4
        className={classnames(
          'mt-8 font-bold uppercase',
          isDisabled ? 'text-gray-75' : 'text-purple-100',
        )}
      >
        Features
      </h4>
      <ul className="mt-4 space-y-4 font-bold">
        {[
          'Demand planning',
          'Replenishment recommendations & alerts',
          'Purchase orders',
          'Multi-location support',
          'Advanced demand planning',
          'Production planning',
          'Marketing events calendar',
          'New product planning',
        ].map((item, index) => (
          <li key={item} className="flex">
            <div className="w-6 shrink-0">
              {!hasAllOptions && index > 3 ? null : (
                <FiCheck
                  className={classnames('mt-px', isDisabled ? 'text-gray-75' : 'text-purple-100')}
                  strokeWidth={2.5}
                  size={16}
                />
              )}
            </div>
            <div className={classnames(!hasAllOptions && index > 3 && 'text-gray-75 line-through')}>
              {item}
            </div>
          </li>
        ))}
      </ul>

      <h4
        className={classnames(
          'mt-8 font-bold uppercase',
          isDisabled ? 'text-gray-75' : 'text-purple-100',
        )}
      >
        Integrations
      </h4>
      <ul className="mt-4 space-y-4 font-bold">
        <li className="flex">
          <div className="w-6 shrink-0">
            <FiCheck
              className={classnames('mt-px', isDisabled ? 'text-gray-75' : 'text-purple-100')}
              strokeWidth={2.5}
              size={16}
            />
          </div>
          <div>
            Shopify, Amazon, Shipbob, Google Analytics,&nbsp;
            <span className={classnames(!hasAllOptions && 'text-gray-75 line-through')}>
              Linnworks, Extensiv Order Manager, Fulfil, Anvyl, Flexe, Shiphero
            </span>
          </div>
        </li>
      </ul>
    </Widget>
  </div>
);

BillingWidget.propTypes = {
  hasAllOptions: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number,
  revenueLimit: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

BillingWidget.defaultProps = {
  hasAllOptions: false,
  price: null,
  loading: false,
  isDisabled: false,
};

export default BillingWidget;
