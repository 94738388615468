import React from 'react';
import { PropTypes } from 'prop-types';
import { FiAlertTriangle, FiCheckCircle, FiInfo, FiX, FiXCircle } from 'react-icons/fi';

const levelsMap = {
  error: {
    styles: 'text-red-100 bg-red-25',
    buttonStyles: 'hover:bg-red-50 focus:ring-red-100',
    icon: <FiXCircle />,
  },
  warning: {
    styles: 'text-yellow-100 bg-yellow-10',
    buttonStyles: 'hover:bg-yellow-75 focus:ring-yellow-100',
    icon: <FiAlertTriangle />,
  },
  info: {
    styles: 'text-purple-100 bg-purple-10',
    buttonStyles: 'hover:bg-purple-75 focus:ring-purple-100',
    icon: <FiInfo />,
  },
  success: {
    styles: 'text-leafy-100 bg-leafy-10',
    buttonStyles: 'hover:bg-leafy-50 focus:ring-leafy-100',
    icon: <FiCheckCircle />,
  },
};

const Alert = ({ children, level, onClose }) => (
  <div
    className={`flex items-center rounded-lg px-3 py-2 text-sm shadow-lg ${levelsMap[level].styles}`}
  >
    <div className="mr-1 p-1">{levelsMap[level].icon}</div>
    <div className="vertical-fix mr-4">{children}</div>
    {onClose && (
      <button
        type="button"
        className={`ml-auto rounded-full p-1 focus:outline-none focus:ring-1 ${levelsMap[level].buttonStyles}`}
        onClick={onClose}
      >
        <FiX />
      </button>
    )}
  </div>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  level: 'error',
  onClose: null,
};

export default Alert;
