import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import classnames from 'classnames';
import SkuDetails from '../Components/SkuDetails.jsx';
import Table from '../Components/Table.jsx';
import { usePagination } from '../Hooks/index.js';
import { getSafetyStock } from '../Utils/subscriptions.js';
import OnHandTarget from './OnHandTarget.jsx';
import { getSkuPagePath } from './utils/helpers.js';

const SKUS = gql`
  query GetSkus($after: String, $before: String, $subscriptionStatus: SkuSubscriptionStatuses) {
    skus(after: $after, before: $before, subscriptionStatus: $subscriptionStatus) {
      edges {
        cursor
        node {
          _id
          hasAtRiskSubscriptions
          inventoryQuantity
          isBundle
          isWithoutSku
          leadTime
          productName
          sku
          stats {
            category
            firstOutOfStockDate
            subscriptions {
              aggregateBundleReserveInventory
              aggregateReserveInventory
              bundleReserveInventoryByDate {
                date
                reserveInventory
              }
              reserveInventoryByDate {
                date
                reserveInventory
              }
              reserveInventoryHorizon
            }
            targetInventoryQuantity
            velocity
          }
          variantName
          vendor {
            _id
            leadTime
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const getColumns = (isAtRisk) => [
  { field: 'product', content: 'Product', widthClass: 'w-[60%]' },
  { field: 'onHand', content: 'On Hand / Target', widthClass: isAtRisk ? 'w-[20%]' : 'w-[40%]' },
  ...(isAtRisk
    ? [{ field: 'stock', content: 'Safety Stock', widthClass: 'w-[20%]', align: 'right' }]
    : []),
];

const getRows = (skus, isAtRisk) =>
  skus.map(({ node: sku }) => {
    const {
      stats: {
        firstOutOfStockDate = null,
        subscriptions: {
          reserveInventoryHorizon = null,
          reserveInventoryByDate = null,
          bundleReserveInventoryByDate = null,
          aggregateReserveInventory = null,
          aggregateBundleReserveInventory = null,
        } = {},
      } = {},
    } = sku;

    return {
      id: sku._id,
      className: 'hover:bg-gray-10',
      link: getSkuPagePath(sku),
      product: <SkuDetails sku={sku} isRankVisible />,
      onHand: (
        <div className="w-24">
          <OnHandTarget
            quantity={sku.inventoryQuantity}
            target={sku.stats.targetInventoryQuantity}
          />
        </div>
      ),
      stock:
        isAtRisk &&
        (firstOutOfStockDate
          ? getSafetyStock(firstOutOfStockDate, reserveInventoryHorizon, {
              reserveInventoryByDate,
              bundleReserveInventoryByDate: bundleReserveInventoryByDate || [],
            })
          : aggregateReserveInventory + aggregateBundleReserveInventory),
    };
  });

const SkusSubscriptionTable = ({ isAtRisk }) => {
  const [paginationItems, setPaginationItems] = useState(null);

  const { hasNext, hasPrev, handleNextClick, handlePrevClick, after, before } =
    usePagination(paginationItems);

  const { data, previousData, loading } = useQuery(SKUS, {
    variables: {
      after,
      before,
      subscriptionStatus: isAtRisk ? 'atRisk' : 'healthy',
    },
  });

  const skus = data?.skus.edges || previousData?.skus.edges;

  useEffect(() => {
    setPaginationItems(data?.skus);
  }, [data]);

  return (
    <div className="mt-7 space-y-7">
      <Table
        columns={getColumns(isAtRisk)}
        rows={skus?.length > 0 ? getRows(skus, isAtRisk) : null}
        isLoading={loading}
        noDataText={`There are no ${isAtRisk ? 'at-risk' : 'healthy'} subscriptions`}
      >
        <Table.Header>
          {isAtRisk ? (
            <span className="text-red-100">At-Risk Subscriptions</span>
          ) : (
            'Healthy Subscriptions'
          )}
        </Table.Header>
        <Table.Body />
      </Table>

      {skus?.length > 0 && (
        <div className="flex justify-center space-x-2">
          <button
            type="button"
            onClick={handlePrevClick}
            aria-label="previous"
            className={classnames(
              'flex h-9 w-9 items-center justify-center rounded-full hover:bg-purple-110',
              !hasPrev ? 'pointer-events-none bg-gray-50 text-gray-75' : 'bg-purple-100 text-white',
              loading && 'pointer-events-none',
            )}
          >
            <FiChevronLeft size={22} strokeWidth={2.2} className="pr-0.5" />
          </button>
          <button
            type="button"
            onClick={handleNextClick}
            aria-label="next"
            className={classnames(
              'flex h-9 w-9 items-center justify-center rounded-full hover:bg-purple-110',
              !hasNext ? 'pointer-events-none bg-gray-50 text-gray-75' : 'bg-purple-100 text-white',
              loading && 'pointer-events-none',
            )}
          >
            <FiChevronRight size={22} strokeWidth={2.2} className="pl-0.5" />
          </button>
        </div>
      )}
    </div>
  );
};

SkusSubscriptionTable.propTypes = {
  isAtRisk: PropTypes.bool,
};

SkusSubscriptionTable.defaultProps = {
  isAtRisk: false,
};

export default SkusSubscriptionTable;
