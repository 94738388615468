/* eslint-disable
  no-param-reassign,
  no-multi-assign,
  func-names,
  prefer-rest-params,
  vars-on-top,
  one-var,
  no-var,
  no-plusplus,
  no-use-before-define
*/
const init = () => {
  var _cio = (window._cio = window._cio || []);
  (function () {
    var a, b, c;
    a = function (f) {
      return function () {
        _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
      };
    };
    b = ['load', 'identify', 'sidentify', 'track', 'page'];
    for (c = 0; c < b.length; c++) {
      _cio[b[c]] = a(b[c]);
    }
    var t = document.createElement('script'),
      s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id = 'cio-tracker';
    t.setAttribute('data-site-id', '2a8b92d9adb133d44a88');
    t.setAttribute('data-use-array-params', 'true');
    t.setAttribute('data-in-app-org-id', '0eb2dc32-34da-41d6-89db-b808739f38a6');
    t.setAttribute('data-use-in-app', 'true');
    t.src = 'https://assets.customer.io/assets/track-eu.js';
    s.parentNode.insertBefore(t, s);
  })();
};

export default { init };
