import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FiChevronLeft, FiTrash } from 'react-icons/fi';
import confirm from '../Components/ConfirmDialog.jsx';
import { useDemo } from '../Components/DemoProvider.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import NotFound from '../Layout/NotFound.jsx';
import Form from './Form.jsx';
import {
  clearPurchaseOrdersCache,
  PO,
  REMOVE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from './Queries.js';

const Edit = () => {
  const { _id } = useParams();
  const { isDemo } = useDemo();
  const navigate = useNavigate();

  const { loading, data } = useQuery(PO, { variables: { _id } });

  const [removePurchaseOrder] = useMutation(REMOVE_PURCHASE_ORDER, {
    variables: { purchaseOrderId: _id },
    update: clearPurchaseOrdersCache,
    onCompleted: () => navigate('/purchase-orders'),
  });

  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER, {
    variables: { purchaseOrderId: _id },
    update: clearPurchaseOrdersCache,
  });

  const handleRemove = async () => {
    if (await confirm('Are you sure you want to delete this purchase order?')) {
      removePurchaseOrder();
    }
  };

  const handleChanges = (input) => {
    updatePurchaseOrder({
      variables: {
        input: {
          customPurchaseOrderNumber: input.customPurchaseOrderNumber,
          issueDate: input.issueDate,
          dateCreated: input.dateCreated,
          items: input.items.map((item) => ({
            sku: item.sku,
            quantity: item.quantity,
            unitCost: item.unitCost,
          })),
          expectedDeliveryDate: input.expectedDeliveryDate,
          expectedShipmentDate: input.expectedShipmentDate,
          expectedProductionCompletionDate: input.expectedProductionCompletionDate,
          costAdjustments: input.costAdjustments.map(({ label, amount }) => ({ label, amount })),
          recommendInventoryForLocations: input.recommendInventoryForLocations,
        },
      },
    });
  };

  useDocumentTitle(
    data && !isDemo
      ? `Edit Purchase Order for ${data?.purchaseOrder.vendor.name}`
      : 'Edit Purchase Order',
  );

  if (loading) return <Spinner />;

  if (!data) return <NotFound />;

  return (
    <div className="-my-12 min-h-screen bg-purple-5 pb-12">
      <div className="mb-8 flex items-center justify-between bg-purple-5 pl-1 pr-2 pt-4">
        <Link
          to="/purchase-orders"
          className="group inline-flex items-center rounded-full px-4 py-2 text-sm font-bold leading-none text-midnight-100 hover:bg-purple-10"
        >
          <FiChevronLeft
            className="mr-1 transition-transform group-hover:-translate-x-1"
            size={20}
          />
          <span>All Purchase Orders</span>
        </Link>
        {data?.purchaseOrder.sourceId === 'cogsy' ? (
          <button
            type="button"
            className="flex items-center rounded-full px-4 py-2 text-sm font-bold leading-none text-midnight-70 hover:bg-purple-10"
            onClick={handleRemove}
          >
            <FiTrash className="mr-1" size={16} />
            <span>Remove</span>
          </button>
        ) : null}
      </div>
      <Form
        purchaseOrder={data.purchaseOrder}
        onChange={handleChanges}
        showSuggestedSkus={data.purchaseOrder.sourceId === 'cogsy'}
      />
    </div>
  );
};

export default Edit;
