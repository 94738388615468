import React from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { useDocumentTitle } from '../Hooks/index.js';

const Password = () => {
  useDocumentTitle('Password Settings');
  const { register, handleSubmit } = useForm();
  const [passwordShow, setPasswordShow] = React.useState(false);
  const [newPasswordShow, setNewPasswordShow] = React.useState(false);
  const [confirmNewPasswordShow, setConfirmNewPasswordShow] = React.useState(false);

  const onSubmit = (data) => data;

  return (
    <div className="col-span-4 text-midnight-100">
      <h5 className="text-xl">Password</h5>
      <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="password">
            Password
            <div className="relative">
              {passwordShow ? (
                <button
                  type="button"
                  className="absolute right-4 top-4"
                  onClick={() => setPasswordShow(false)}
                >
                  <FiEyeOff size={16} className="stroke-gray-100" />
                </button>
              ) : (
                <button
                  type="button"
                  className="absolute right-4 top-4"
                  onClick={() => setPasswordShow(true)}
                >
                  <FiEye size={16} className="stroke-gray-100" />
                </button>
              )}
              <input
                type={passwordShow ? 'text' : 'password'}
                id="password"
                className="input"
                onBlur={() => setPasswordShow(false)}
                {...register('password')}
              />
            </div>
          </label>
        </div>
        <div className="mt-4">
          <label htmlFor="new_password">
            New Password
            <div className="relative">
              {newPasswordShow ? (
                <button
                  type="button"
                  className="absolute right-4 top-4"
                  onClick={() => setNewPasswordShow(false)}
                >
                  <FiEyeOff size={16} className="stroke-gray-100" />
                </button>
              ) : (
                <button
                  type="button"
                  className="absolute right-4 top-4"
                  onClick={() => setNewPasswordShow(true)}
                >
                  <FiEye size={16} className="stroke-gray-100" />
                </button>
              )}
              <input
                type={newPasswordShow ? 'text' : 'password'}
                id="new_password"
                name="newPassword"
                className="input"
                onBlur={() => setNewPasswordShow(false)}
                {...register('newPassword')}
              />
            </div>
          </label>
        </div>
        <div className="mt-4">
          <label htmlFor="confirm_new_password">
            Confirm New Password
            <div className="relative">
              {confirmNewPasswordShow ? (
                <button
                  type="button"
                  className="absolute right-4 top-4"
                  onClick={() => setConfirmNewPasswordShow(false)}
                >
                  <FiEyeOff size={16} className="stroke-gray-100" />
                </button>
              ) : (
                <button
                  type="button"
                  className="absolute right-4 top-4"
                  onClick={() => setConfirmNewPasswordShow(true)}
                >
                  <FiEye size={16} className="stroke-gray-100" />
                </button>
              )}
              <input
                type={confirmNewPasswordShow ? 'text' : 'password'}
                id="confirm_new_password"
                name="confirmNewPassword"
                className="input"
                onBlur={() => setConfirmNewPasswordShow(false)}
                {...register('confirmNewPassword')}
              />
            </div>
          </label>
        </div>
        <button
          type="submit"
          className="mt-8 w-full rounded-full bg-purple-100 py-4 font-bold text-white"
        >
          Save changes
        </button>
      </form>
    </div>
  );
};

export default Password;
