import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { FiDownloadCloud, FiRotateCcw } from 'react-icons/fi';
import { Dialog } from '@headlessui/react';
import cn from 'classnames';
import { addMonths, endOfDay, isAfter, isBefore, isSameMonth, startOfMonth } from 'date-fns';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import ExportToCSV from '../Components/ExportToCSV.jsx';
import LoadingIcon from '../Components/LoadingIcon.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { SkeletonBlock } from '../Components/Skeletons.jsx';
import Tag from '../Components/Tag.jsx';
import VideoPopup from '../Components/VideoPopup.jsx';
import { planning } from '../config.js';
import { useDocumentTitle } from '../Hooks/index.js';
import SkusPlanning from '../Skus/SkusPlanning.jsx';
import omitDeep from '../Utils/omitDeep.js';
import Heatmap from './Heatmap.jsx';
import PlanningGraph from './PlanningGraph.jsx';
import TrackingGraph from './TrackingGraph.jsx';
import { calculateNewPlan } from './utils/growthPlanning.js';

const ACCOUNT_METRICS = gql`
  query AccountMetrics($metric: AccountMetricEnumType!) {
    accountMetrics(metric: $metric) {
      date
      value
    }
  }
`;

export const GROWTH_MODEL_UPDATE = gql`
  mutation UpdateGrowthModel($input: GrowthModelUpdateInput!) {
    updateGrowthModel(input: $input) {
      _id
    }
  }
`;

export const GROWTH_MODEL_QUERY = gql`
  query GetGrowthModel {
    growthModel {
      _id
      baseline {
        value
        date
        monthGrowth
      }
      growthData {
        value
        date
        monthGrowth
      }
      activePlan {
        value
        date
        monthGrowth
      }
      heatmapData {
        date
        percentage
        outOfStock {
          vendors
          skusCount
        }
      }
      stats {
        currentMonthPlanTotal
      }
      baselineUpdateLog {
        isReviewNeeded
        status
      }
    }
  }
`;

export const SALES_QUERY = gql`
  query TotalSales($from: DateTime!, $to: DateTime!) {
    account {
      _id
      totalSales(from: $from, to: $to)
    }
  }
`;

export const HEATMAP_REFRESH = gql`
  query HeatmapRefresh($input: [HeatmapRefresh]) {
    heatmapRefresh(input: $input) {
      date
      percentage
      outOfStock {
        vendors
        skusCount
      }
    }
  }
`;

export const GET_PURCHASE_ORDERS = gql`
  query getPos {
    purchaseOrders(first: 100, excludeStatuses: [DRAFT]) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          expectedDeliveryDate
        }
      }
    }
  }
`;

export const GET_DRAFT_PURCHASE_ORDERS = gql`
  query getDraftPurchaseOrders {
    purchaseOrders(first: 100, statuses: [DRAFT]) {
      edges {
        node {
          expectedDeliveryDate
          totalCost
        }
      }
    }
  }
`;

const sortByDate = (a, b) => new Date(a?.date) - new Date(b?.date);

const isSameMonthOrAfter = (timestamp, compareTo) =>
  isAfter(timestamp, compareTo) || isSameMonth(timestamp, compareTo);

const isSameMonthOrBefore = (timestamp, compareTo) =>
  isBefore(timestamp, compareTo) || isSameMonth(timestamp, compareTo);

const GrowthPlanning = () => {
  useDocumentTitle('Planning');
  const { account, user } = useAccount();
  const { addAlert } = useAlerts();
  const [isEditMode, setIsEditMode] = useState(false);
  const [plan, setPlan] = useState(null);
  const today = new Date();
  const [from] = useState(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth())));
  const [to] = useState(addMonths(from, 11));
  const [selectedMetric, setSelectedMetric] = useState('');
  const [currentMonthSales, setCurrentMonthSales] = useState(null);
  const [videoPopupOpen, setVideoPopupOpen] = useState(false);
  const [heatmapCurrentData, setHeatmapCurrentData] = useState([]);
  const [hasActivePlanChanged, setHasActivePlanChanged] = useState(false);
  const [dialogMode, setDialogMode] = useState(null);
  const [planUpdating, setPlanUpdating] = useState(false);

  const [accountMetrics, { data: accountMetricsData }] = useLazyQuery(ACCOUNT_METRICS);
  const { data: purchaseOrderData } = useQuery(GET_PURCHASE_ORDERS, { variables: { first: 100 } });
  const { data: draftPurchaseOrderData } = useQuery(GET_DRAFT_PURCHASE_ORDERS, {
    variables: { first: 100 },
  });

  const hasGoogleAnalytics = account.sources?.find(
    (source) => source.sourceType === 'googleAnalytics',
  )?.syncAnalytics;

  const onSelectMetric = async (event) => {
    const metric = event.target.value;

    if (!metric) {
      setSelectedMetric('');
      return;
    }

    if (!hasGoogleAnalytics && (metric === 'conversionRate' || metric === 'abandonedCartRate')) {
      addAlert(
        <Link to="/settings/sources" className="underline">
          Please connect a Google Analytics account to display this metric
        </Link>,
        { level: 'info' },
      );
      setSelectedMetric('');
      return;
    }

    setSelectedMetric(metric);
    await accountMetrics({ variables: { metric: event.target.value } });
  };

  const [updateGrowthModel] = useMutation(GROWTH_MODEL_UPDATE);

  const { loading, data, refetch } = useQuery(GROWTH_MODEL_QUERY);

  const [getSales, { data: salesData }] = useLazyQuery(SALES_QUERY);

  const [heatmapRefresh] = useLazyQuery(HEATMAP_REFRESH);

  useEffect(() => {
    if (data?.growthModel?.baselineUpdateLog?.isReviewNeeded === true) {
      updateGrowthModel({
        variables: { input: { baselineUpdateLog: { isReviewNeeded: false } } },
      });
    }
  }, [data?.growthModel?.baselineUpdateLog?.isReviewNeeded]);

  const currencyOptions = {
    style: 'currency',
    currency: account.preferences?.currency || 'USD',
    notation: 'compact',
    maximumFractionDigits: 2,
  };

  const initialHeatmap = () => data?.growthModel?.heatmapData || [];

  const initialPlan = () => {
    if (!data?.growthModel) return null;

    const { baseline = [], activePlan = [] } = data.growthModel;

    // Active plan is 12 months minimum, but new baseline months are now appended automatically,
    // so active plans will be > 12. However, we always want the latest 12
    if (activePlan?.length) return [...activePlan].sort(sortByDate).slice(-12);

    // Baselines are now 12 months instead of 18 but leaving this to cover
    // accounts with 18 months until the next monthly baseline refresh
    if (baseline?.length) return [...baseline].sort(sortByDate).slice(-12);

    return null;
  };

  const totalRevenue = (target) => Math.round(target.reduce((v, a) => v + a.value, 0));

  const getCurrentPlanTotal = () => {
    if (data?.growthModel) {
      const { activePlan, baseline } = data.growthModel;
      if (activePlan?.length) {
        return totalRevenue(activePlan);
      }
      return totalRevenue(baseline?.length ? baseline.slice(0, 12) : baseline);
    }
    return 0;
  };

  const setInitialData = async () => {
    const plan = initialPlan();
    setPlan(plan);

    const heatmap = initialHeatmap();
    setHeatmapCurrentData(heatmap);

    if (!salesData && plan?.length) {
      const monthSales = await getSales({
        variables: {
          from: startOfMonth(new Date()),
          to: endOfDay(new Date()),
        },
      });
      setCurrentMonthSales(monthSales?.data?.account?.totalSales);
    }
  };

  useEffect(() => {
    setInitialData();
  }, [data]);

  const updatePlan = async (updated) => {
    await updateGrowthModel({
      variables: { input: updated },
      update: (cache) => {
        cache.evict({ _id: cache.identify({ _id: account._id, __typename: 'Account' }) });
        cache.gc();
      },
    });
  };

  const completeUpdate = (message) => {
    setPlanUpdating(false);
    setDialogMode();
    addAlert(message, { level: 'success' });
    setIsEditMode(false);
    setHasActivePlanChanged(false);
    refetch();
  };

  const savePlan = async () => {
    const values = Object.values({ ...omitDeep(plan, ['__typename', '_id']) });
    const newPlan = values.map(({ monthGrowth, date, value }) => ({ monthGrowth, date, value }));
    setPlanUpdating(true);
    await updatePlan({
      activePlan: newPlan,
    });

    completeUpdate('Plan changes saved successfully');
  };

  const resetPlan = async () => {
    setPlanUpdating(true);
    await updatePlan({
      activePlan: [],
    });
    completeUpdate('Plan has been reset successfully.');
  };

  const onEditPlan = async () => {
    setIsEditMode(true);
    setSelectedMetric('');
  };

  const onExitEdit = async () => {
    setHasActivePlanChanged(false);
    setIsEditMode(false);
    setInitialData();
  };

  const csvData = () => {
    if (plan?.length) {
      return plan.map((monthPlan) => {
        const row = {
          Month: new Date(monthPlan.date).toLocaleDateString(navigator.language, {
            month: 'short',
            year: '2-digit',
          }),
        };
        row[`Planned Revenue (${account.preferences.currency || 'USD'})`] = parseFloat(
          monthPlan?.value ?? 0,
        ).toFixed(2);
        row['Planned MoM Growth Rate %'] = parseFloat(monthPlan.monthGrowth * 100 - 100).toFixed(2);
        return row;
      });
    }
    return [];
  };

  const csvFileName = `Plan-${(plan?.length
    ? new Date(plan[0]?.date)
    : new Date()
  ).toLocaleDateString(navigator.language, { month: 'short', year: '2-digit' })}-${(plan?.length
    ? new Date(plan[plan.length - 1]?.date)
    : new Date()
  ).toLocaleDateString(navigator.language, { month: 'short', year: '2-digit' })}`.replaceAll(
    ' ',
    '-',
  );

  const formattedGrowthModel = {
    activePlan: data?.growthModel?.activePlan?.slice(-12), // last 12 now as active plans are auto-updated
    baseline: data?.growthModel?.baseline?.slice(0, 12),
    heatmapData: data?.growthModel?.heatmapData,
    growthData: data?.growthModel?.growthData,
    metricsOverlay: selectedMetric
      ? {
          metrics: accountMetricsData?.accountMetrics,
          selectedMetric,
        }
      : null,
    currentMonthSales,
  };

  const updateHeatmap = async (newPlan) => {
    if (!newPlan?.length || !hasActivePlanChanged) return;
    const planData = newPlan.map(({ monthGrowth, date }) => ({ month: date, lift: monthGrowth }));
    const { data: updatedHeatmap } = await heatmapRefresh({
      variables: { input: planData },
    });
    setHeatmapCurrentData(updatedHeatmap?.heatmapRefresh || []);
  };

  // When plan is updated, recalculate heatmap
  useEffect(() => {
    updateHeatmap(plan);
  }, [plan]);

  const onMonthGrowthChange = (selectedDate, newLift) => {
    const newUserLift = 1 + newLift / 100;

    setPlan((oldPlan) => {
      const updatedIndex = oldPlan.findIndex((op) =>
        isSameMonth(new Date(selectedDate), new Date(op.date)),
      );

      // Nothing has been changed, so nothing needs to be updated
      if (oldPlan[updatedIndex].monthGrowth === newUserLift) return oldPlan;

      setHasActivePlanChanged(true);

      return calculateNewPlan(
        Math.max(data?.growthModel?.growthData.slice(-1)[0].value, 1),
        oldPlan,
        updatedIndex,
        newUserLift,
      );
    });
  };

  const getProposedPlanTotal = () => totalRevenue(plan);

  const plannedPeriod = plan?.length
    ? `${new Date(plan[0].date).toLocaleDateString(navigator.language, {
        month: 'short',
        year: 'numeric',
      })} - ${new Date(plan[plan.length - 1].date).toLocaleDateString(navigator.language, {
        month: 'short',
        year: 'numeric',
      })}`
    : '';

  const toggleVideoPopupOpen = () => {
    setVideoPopupOpen(!videoPopupOpen);
  };

  const draftPOs = draftPurchaseOrderData?.purchaseOrders?.edges.map((e) => e.node) || [];

  const capitalPlannedPeriod = draftPOs
    .filter(
      ({ expectedDeliveryDate }) =>
        isSameMonthOrAfter(new Date(expectedDeliveryDate), from) &&
        isSameMonthOrBefore(new Date(expectedDeliveryDate), to),
    )
    .reduce((total, draftPO) => total + draftPO.totalCost, 0);
  return (
    <>
      <PageHeader text="Planning" />
      {!account?.onboarding?.hasOrdersSyncCompleted && (
        <div className="mt-16 flex items-center">
          <div className="text-midnight-100">
            <div className="font-bold">Order syncronisation needed</div>
            <div className="flex">
              <div className="mt-8 text-base">
                <p>Thanks for your interest in our planning feature.</p>
                <p>
                  To start creating your growth plan go to your&nbsp;
                  <a className="font-bold text-purple-100" href="/settings/sources">
                    settings
                  </a>
                  &nbsp;and connect a source
                </p>
                <p className="mt-2">
                  In the meantime, check out our video below on how Planning works.
                </p>
                {planning.videoUrl && (
                  <div className="my-4">
                    <button
                      type="button"
                      className="inline-flex items-center rounded text-sm font-bold text-midnight-100 focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2"
                      onClick={toggleVideoPopupOpen}
                    >
                      <BsFillPlayCircleFill size={20} className="mr-2 text-purple-100" />
                      Learn how to use the planning feature
                    </button>
                    <VideoPopup
                      videoUrl={planning.videoUrl}
                      open={videoPopupOpen}
                      onClose={toggleVideoPopupOpen}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ml-12 max-w-md">
            <img src="/empty-growth-plan-bar-chart.png" alt="" />
          </div>
        </div>
      )}
      {account?.onboarding?.hasOrdersSyncCompleted &&
        data?.growthModel?.baselineUpdateLog?.status !== 'completed' && (
          <div className="mt-16 flex items-center">
            <div className="text-midnight-100">
              <div className="font-bold">We&apos;re generating your Growth Plan</div>
              <div className="mt-5 whitespace-nowrap">
                However, it looks like the calculations are taking a little longer than expected.
              </div>
              <div className="flex">
                <div className="mt-4 text-base">
                  If this message persists, please get in contact with us using the support widget
                  at the bottom right.
                  {planning.videoUrl && (
                    <div className="my-4">
                      <button
                        type="button"
                        className="inline-flex items-center rounded text-sm font-bold text-midnight-100 focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2"
                        onClick={toggleVideoPopupOpen}
                      >
                        <BsFillPlayCircleFill size={20} className="mr-2 text-purple-100" />
                        Learn how to use the planning feature
                      </button>
                      <VideoPopup
                        videoUrl={planning.videoUrl}
                        open={videoPopupOpen}
                        onClose={toggleVideoPopupOpen}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ml-12 max-w-md">
              <img src="/empty-growth-plan-bar-chart.png" alt="" />
            </div>
          </div>
        )}
      {account?.onboarding?.hasOrdersSyncCompleted &&
        data?.growthModel?.baselineUpdateLog?.status === 'completed' && (
          <>
            <div className="my-8 grid grid-cols-12 gap-x-6">
              <section className="col-span-6 rounded-r-lg border-l-4 border-purple-75 bg-white px-7 py-5 text-midnight-100 shadow">
                {loading ? (
                  <SkeletonBlock className="h-[108px]" />
                ) : (
                  <>
                    <div className="text-xs uppercase">
                      <span>Current Month:</span>
                      <Tag className="ml-2">
                        {new Date().toLocaleDateString(navigator.language, { month: 'long' })}
                      </Tag>
                    </div>
                    <div className="mt-4 text-lg font-bold">
                      Target revenue:
                      <span className="ml-2 text-2xl">
                        {(data?.growthModel?.stats?.currentMonthPlanTotal ?? 0).toLocaleString(
                          navigator.language,
                          currencyOptions,
                        )}
                      </span>
                    </div>
                  </>
                )}
              </section>
              <section className="col-span-6 rounded-r-lg border-l-4 border-purple-75 bg-white px-7 py-5 text-midnight-100 shadow">
                {loading ? (
                  <SkeletonBlock className="h-[108px]" />
                ) : (
                  <>
                    <div className="text-xs uppercase">
                      <span>Planned Period:</span>
                      <Tag className="ml-2">{plannedPeriod}</Tag>
                    </div>
                    <div className="mt-4 text-lg font-bold">
                      Target revenue:
                      <span className="ml-2 text-2xl">
                        {getCurrentPlanTotal().toLocaleString(navigator.language, currencyOptions)}
                      </span>
                    </div>
                    <div className="mt-4 w-fit rounded border-2 border-purple-75 bg-purple-10 px-2">
                      <div className=" text-sm font-bold text-purple-100">
                        Est. Working Capital Required:&nbsp;
                        {capitalPlannedPeriod.toLocaleString(navigator.language, currencyOptions)}
                      </div>
                    </div>
                  </>
                )}
              </section>
            </div>
            <div className="mt-8 grid grid-cols-12 gap-x-6 space-y-8">
              <div className="col-span-12">
                <div className="grid grid-cols-12 gap-x-6 text-midnight-100">
                  <div
                    className={`col-span-12 mb-12 rounded-lg border-2 border-dashed bg-white p-4 shadow ${
                      isEditMode ? 'border-purple-100' : 'border-transparent'
                    }`}
                  >
                    {loading ? (
                      <SkeletonBlock className="mt-0 h-[822px]" />
                    ) : (
                      <>
                        <div className="flex items-center font-bold">
                          <div className="flex items-center py-2">
                            <span className="text-midnight-75">Growth Plan</span>
                            <Tag className="ml-2">{plannedPeriod}</Tag>
                          </div>
                          {!isEditMode && (
                            <div className="ml-6 font-bold">
                              <label htmlFor="plan-metric-type" className="flex items-center">
                                Overlay a metric
                                <select
                                  id="plan-metric-type"
                                  className="ml-2 w-44 rounded-lg border-none p-2 text-xs shadow focus:ring-2 focus:ring-purple-100"
                                  onChange={onSelectMetric}
                                  value={selectedMetric}
                                >
                                  <option value="">No Metric</option>
                                  <option value="avgOrderValue">✔️ Average Order Value</option>
                                  <option value="conversionRate">
                                    {hasGoogleAnalytics ? '✔️' : '⚠️'} Conversion Rate
                                  </option>
                                  <option value="abandonedCartRate">
                                    {hasGoogleAnalytics ? '✔️' : '⚠️'} Abandoned Cart Rate
                                  </option>
                                </select>
                              </label>
                            </div>
                          )}
                          {isEditMode && plan?.length > 0 && (
                            <div className="ml-6 font-bold">
                              <span className="text-midnight-75">
                                Planned Annual Revenue:&nbsp;
                              </span>
                              {getProposedPlanTotal().toLocaleString(
                                navigator.language,
                                currencyOptions,
                              )}
                            </div>
                          )}
                          {planning.videoUrl && !isEditMode && (
                            <>
                              <button
                                type="button"
                                className="ml-auto mr-6 inline-flex items-center rounded text-sm font-bold text-midnight-100 focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2"
                                onClick={toggleVideoPopupOpen}
                              >
                                <BsFillPlayCircleFill size={20} className="mr-2 text-purple-100" />
                                Learn how to use this model
                              </button>
                              <VideoPopup
                                videoUrl={planning.videoUrl}
                                open={videoPopupOpen}
                                onClose={toggleVideoPopupOpen}
                              />
                            </>
                          )}
                        </div>
                        <div className="relative z-0 -mb-12 mt-8 flex items-center space-x-2">
                          {!isEditMode && !user.isReadOnly && plan?.length > 0 && (
                            <Button
                              label={
                                !data?.growthModel?.activePlan?.length
                                  ? 'Configure your Plan'
                                  : 'Edit Plan'
                              }
                              onClick={onEditPlan}
                              slim
                            />
                          )}
                          {!isEditMode && plan?.length > 0 && (
                            <ExportToCSV data={csvData()} fileName={csvFileName}>
                              <Button
                                variant="text"
                                slim
                                label="Export to CSV"
                                icon={FiDownloadCloud}
                                iconSize={18}
                              />
                            </ExportToCSV>
                          )}
                          {isEditMode && (
                            <div className="flex items-center space-x-4 text-xs">
                              <Button
                                label="Exit Editing"
                                slim
                                variant="tertiary"
                                onClick={onExitEdit}
                              />
                              {(!data?.growthModel?.activePlan?.length || hasActivePlanChanged) && (
                                <Button
                                  label="Apply this Plan"
                                  slim
                                  onClick={() => setDialogMode('save')}
                                />
                              )}
                              {data?.growthModel?.activePlan?.length > 0 && (
                                <button
                                  type="button"
                                  onClick={() => setDialogMode('reset')}
                                  className="flex items-center px-2 py-1.5 font-bold text-purple-100"
                                >
                                  <FiRotateCcw size={18} className="mr-1" />
                                  Reset Plan
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        {!isEditMode && plan?.length > 0 && (
                          <TrackingGraph trackingChartData={formattedGrowthModel} />
                        )}
                        {isEditMode && plan?.length > 0 && (
                          <PlanningGraph
                            planningChartData={formattedGrowthModel}
                            plan={plan}
                            onUpdate={onMonthGrowthChange}
                          />
                        )}
                        <div className={cn('mt-8', selectedMetric && 'pr-[76px]')}>
                          <Heatmap
                            heatmapData={heatmapCurrentData || []}
                            variantSize={isEditMode ? 'medium' : 'small'}
                            variantPosition="top"
                            activeChanges={hasActivePlanChanged}
                            purchaseOrders={purchaseOrderData}
                            draftPurchaseOrders={draftPurchaseOrderData}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {plan?.length > 0 && (
                  <div className="grid grid-cols-12 gap-x-6 text-midnight-100">
                    <div className="col-span-12 rounded-lg">
                      <SkusPlanning variantTitle="subheader" setDocumentTitle={false} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      <Dialog
        open={!!dialogMode}
        onClose={() => setDialogMode()}
        className="text-xs text-midnight-100"
        style={{ fontFamily: 'Gilroy, sans-serif' }}
      >
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="mx-auto max-w-xl overflow-hidden rounded bg-white shadow">
              <div className="bg-purple-10 p-8 py-6">
                <Dialog.Title className="text-lg font-bold text-purple-100">
                  {dialogMode === 'save' ? 'Apply this Plan' : 'Reset your Plan'}
                </Dialog.Title>

                <div className="mt-2 text-sm">
                  {dialogMode === 'save' ? (
                    <>
                      <p>
                        Adjusting your Growth Plan will affect your inventory forecasting and POs.
                      </p>
                      <p className="mt-2">You can always reset your Growth Plan.</p>
                    </>
                  ) : (
                    <>
                      <p>
                        If you reset your Growth Plan, your inventory forecasts and purchase orders
                        will follow Cogsy&apos;s default predictions.
                      </p>
                      <p className="mt-2">You can always re-create your Growth Plan.</p>
                    </>
                  )}
                </div>
              </div>

              <div className="p-8">
                <div className="flex justify-end space-x-2">
                  <Button label="Cancel" variant="text" slim onClick={() => setDialogMode()} />
                  {dialogMode === 'save' ? (
                    <Button
                      label={
                        planUpdating ? (
                          <>
                            <LoadingIcon className="-ml-1 mr-2" />
                            Applying Plan...
                          </>
                        ) : (
                          'Apply Plan'
                        )
                      }
                      slim
                      onClick={() => savePlan()}
                      disabled={!!planUpdating}
                    />
                  ) : (
                    <Button
                      label={
                        planUpdating ? (
                          <>
                            <LoadingIcon className="-ml-1 mr-2" />
                            Resetting...
                          </>
                        ) : (
                          'Reset Plan'
                        )
                      }
                      slim
                      onClick={() => resetPlan()}
                      disabled={!!planUpdating}
                    />
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
        <div className="fixed inset-0 z-20 bg-black/50" aria-hidden="true" />
      </Dialog>
    </>
  );
};

export default GrowthPlanning;
