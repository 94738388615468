import React from 'react';
import PropTypes from 'prop-types';

const CustomLabel = ({ title, xOffset, yOffset, viewBox: { x, height } }) => {
  const xPosition = x - xOffset;
  const yPosition = height + yOffset;
  return (
    <foreignObject className="h-8 w-28" x={xPosition} y={yPosition}>
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        className="flex h-full items-center justify-center rounded-lg border border-gray-50 bg-white text-xs"
      >
        <div className="font-bold">{title}</div>
      </div>
    </foreignObject>
  );
};

CustomLabel.propTypes = {
  title: PropTypes.string.isRequired,
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
  viewBox: PropTypes.shape({
    height: PropTypes.number,
    x: PropTypes.number,
  }),
};

CustomLabel.defaultProps = {
  xOffset: 0,
  yOffset: 0,
  viewBox: {
    height: 288,
    x: 426.353,
  },
};

export default CustomLabel;
