/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import Toast from './Toast.jsx';

const AlertsContext = React.createContext(null);

const useAlerts = () => React.useContext(AlertsContext);

const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = React.useState([]);

  const addAlert = (message, { level = 'error', error, autoHideDuration } = {}) => {
    if (error) {
      Sentry.captureException(error);
    }
    setAlerts((prevState) => [
      ...prevState,
      { message, level, autoHideDuration, open: true, id: `${message}-${Math.random()}` },
    ]);
  };

  const handleClose = React.useCallback(
    (alert) => {
      setAlerts((prevState) => prevState.filter((a) => a.id !== alert.id));
    },
    [setAlerts, alerts],
  );

  const value = React.useMemo(() => ({ addAlert }), []);

  return (
    <AlertsContext.Provider value={value}>
      <div className="fixed left-1/2 top-8 z-10 flex -translate-x-1/2 flex-col items-center space-y-4">
        {alerts.map((alert) => (
          <Toast
            open={alert.open}
            level={alert.level}
            onClose={() => handleClose(alert)}
            key={alert.id}
            autoHideDuration={alert.autoHideDuration}
          >
            {alert.message}
          </Toast>
        ))}
      </div>
      {children}
    </AlertsContext.Provider>
  );
};

AlertsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AlertsProvider;
export { useAlerts };
