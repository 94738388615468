import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useAccount } from '../AccountProvider.jsx';
import AuditTrailWidget from '../Components/AuditTrail.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import NotFound from '../Layout/NotFound.jsx';
import SelectSkuNamePopup from './Popups/SelectSkuNamePopup.jsx';
import FinishedProductsWidget from './Product/FinishedProductsWidget.jsx';
import POsWidget from './Product/POsWidget.jsx';
import ProductWidget from './Product/ProductWidget.jsx';
import ReplenishmentTable from './Product/ReplenishmentTable.jsx';
import Summary from './Product/Summary.jsx';
import { calculateLostSales } from './Product/utils.js';
import { REMOVE_VENDOR_SKU, SKU } from './Queries.js';

const EditProduct = () => {
  const { account, locations } = useAccount();
  const { sku: parameter } = useParams();

  const [removeVendor] = useMutation(REMOVE_VENDOR_SKU);

  const [skuNamePopupOpen, setSkuNamePopupOpen] = useState(false);

  const { loading, data, refetch } = useQuery(SKU, {
    variables: { sku: decodeURIComponent(parameter) },
  });

  const handleRemoveVendor = async (vendorId) => {
    await removeVendor({
      variables: { sku: data.sku.sku, vendorId },
    });
    refetch();
  };

  const toggleSkuNamePopup = React.useCallback(() => {
    setSkuNamePopupOpen(!skuNamePopupOpen);
  }, [skuNamePopupOpen]);

  useDocumentTitle(data?.sku?.productName || 'Loading...');

  const expectedRevenueLoss = React.useMemo(() => {
    if (!data?.sku) return 0;
    return calculateLostSales(data.sku, data.sku.inventoryBreakdown) * data.sku.price;
  }, [data]);

  if (loading || !data) return <Spinner />;

  if (!data.sku) return <NotFound />;

  const inTransitPurchaseOrders = data.sku.purchaseOrders.edges.map(({ node: po }) => ({
    ...po,
    item: po.items.find((item) => item.sku === parameter),
  }));

  return (
    <div>
      <Summary
        sku={data.sku}
        handleSkuNamePopup={toggleSkuNamePopup}
        expectedRevenueLoss={expectedRevenueLoss}
        handleRemoveVendor={handleRemoveVendor}
      />
      <div className="text-midnight-100">
        {account.preferences.forecastEnabled !== false && (
          <ReplenishmentTable
            data={data}
            locations={locations}
            expectedRevenueLoss={expectedRevenueLoss}
          />
        )}

        <div className="mt-6 grid grid-cols-12 gap-7">
          {data.sku.finishedProducts?.length > 0 && (
            <FinishedProductsWidget finishedProducts={data.sku.finishedProducts} />
          )}
          {inTransitPurchaseOrders.length > 0 && (
            <POsWidget
              purchaseOrders={inTransitPurchaseOrders}
              transfers={data.sku.transfers.edges}
            />
          )}
          {data.sku.transfers.edges.length > 0 && (
            <ProductWidget
              items={data.sku.transfers.edges.map(({ node }) => node)}
              title="Incoming Transfers/Shipments"
              parameter={parameter}
            />
          )}
        </div>
        <div className="mt-8 grid grid-cols-2 gap-x-5">
          {data.sku.trailLogs.length > 0 && <AuditTrailWidget trailLogs={data.sku.trailLogs} />}
        </div>
      </div>

      <SelectSkuNamePopup open={skuNamePopupOpen} onClose={toggleSkuNamePopup} sku={data.sku} />
    </div>
  );
};

export default EditProduct;
