import React from 'react';

const SubscriptionIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <g stroke="currentColor" fill="currentColor" strokeWidth="0.8">
      <path d="M3.968 1.6A6.2 6.2 0 0 1 12.2 3.624l-1.6-.246a.2027.2027 0 0 0-.2033.0957.2014.2014 0 0 0-.023.1531.2017.2017 0 0 0 .1663.1512l2.012.312h.032a.2.2 0 0 0 .2-.168l.304-2.018a.2003.2003 0 0 0-.166-.228.2.2 0 0 0-.228.166l-.228 1.468a6.5999 6.5999 0 0 0-11.276.5713A6.6 6.6 0 0 0 .52 8.258a.2.2 0 0 0 .2.162.116.116 0 0 0 .038 0 .1998.1998 0 0 0 .16-.234A6.184 6.184 0 0 1 3.968 1.6Zm9.504 4.106a.2047.2047 0 0 0-.0862-.13.203.203 0 0 0-.1528-.031.2036.2036 0 0 0-.161.239 6.2002 6.2002 0 0 1-7.9069 7.1199A6.2005 6.2005 0 0 1 1.8181 10.4l1.6.162a.2012.2012 0 0 0 .22-.18.2012.2012 0 0 0-.18-.22l-2.028-.2h-.02a.2.2 0 0 0-.2.178l-.2 2.026a.1999.1999 0 0 0 .18.22h.02a.1998.1998 0 0 0 .19-.186l.148-1.478A6.6173 6.6173 0 0 0 7.016 13.6a6.5996 6.5996 0 0 0 5.093-2.4217 6.6006 6.6006 0 0 0 1.363-5.4723Z" />
      <path d="M7.166 10.658v-.936l.082-.014C8.076 9.572 8.61 9 8.61 8.252s-.4-1.19-1.356-1.582c-.8-.328-1.2-.6-1.2-1.134 0-.442.332-.888 1.07-.888a2.2959 2.2959 0 0 1 1.1.268l.156-.414a2.3718 2.3718 0 0 0-1.066-.266h-.098v-.888h-.364v.91l-.084.014c-.786.128-1.296.668-1.296 1.374 0 .662.4 1.08 1.446 1.488.542.22 1.096.532 1.096 1.182 0 .586-.488 1-1.188 1a2.5541 2.5541 0 0 1-1.264-.342L5.41 9.4a2.68 2.68 0 0 0 1.28.342h.098v.922l.378-.006Z" />
    </g>
  </svg>
);

export default SubscriptionIcon;
