/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const DemoContext = React.createContext(null);

const useDemo = () => React.useContext(DemoContext);

const DemoProvider = ({ children }) => {
  const [isDemo, setIsDemo] = React.useState(String(window.localStorage.getItem('cogsy-demo')) === 'true');

  const toggleDemo = React.useCallback(() => {
    setIsDemo(!isDemo);
    window.localStorage.setItem('cogsy-demo', !isDemo);
  }, [isDemo]);

  const value = React.useMemo(() => ({ isDemo, toggleDemo }), [isDemo]);

  return (
    <DemoContext.Provider value={value}>
      {children}
    </DemoContext.Provider>
  );
};

DemoProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default DemoProvider;
export { useDemo };
