import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import LoadingPage from '../Components/LoadingPage.jsx';

const Auth = ({ shop, appId }) => {
  useEffect(() => {
    const doRegister = async () => {
      try {
        let url = '/auth/shopify/auth';

        if (appId) url += `/${appId}`;

        const res = await axios(url, { params: { shop } });
        return window.location.assign(res.data.redirectUrl);
      } catch (e) {
        // console.error(e);
        return null;
      }
    };

    doRegister();
  }, [shop]);

  return <LoadingPage />;
};

Auth.propTypes = {
  shop: PropTypes.string.isRequired,
  appId: PropTypes.string,
};

Auth.defaultProps = {
  appId: null,
};

export default Auth;
