import React from 'react';
import PropTypes from 'prop-types';
import { addDays, getDay, isSameWeek, subDays } from 'date-fns';
import {
  Bar,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useAccount } from '../../../AccountProvider.jsx';
import Toggle from '../../../Components/Toggle.jsx';
import CustomLabel from './CustomLabel.jsx';
import CustomTooltip from './CustomTooltip.jsx';
import ForecastInventoryDot from './ForecastInventoryDot.jsx';

const dateEquals = (today) => (item) => item.date === today;
const dateNotEquals = (today) => (item) => item.date !== today;

const removeSubscriptionData = (skuData, subscriptionData, date) => {
  const withoutSubscriptions =
    (skuData.find(dateEquals(date))?.value ?? 0) -
    (subscriptionData.find(dateEquals(date))?.value ?? 0);

  return Math.max(withoutSubscriptions, 0);
};

const ProductChart = ({ sku }) => {
  const { account } = useAccount();
  const supportsGoogleAnalytics = account.sources?.some(
    (source) => source.sourceType === 'googleAnalytics',
  );

  const [showPageViews, setShowPageViews] = React.useState(false);

  const {
    weeklyPageViews = [],
    weeklySales = [],
    weeklyPrediction: weeklySalesPrediction = [],
    bundlesContainingSku = [],
  } = sku;

  const hasBundles = bundlesContainingSku.length > 0;
  const hasSubscriptions = !!sku.stats.subscriptions;

  // Get bundleSales per week
  const bundleSales = weeklySales.map((week) => {
    const totalBundleSalesForWeek = bundlesContainingSku.reduce((totalSales, bundle) => {
      const { bundledQuantity } = bundle.bundledSkus.find(
        ({ sku: bundledSku }) => bundledSku === sku.sku,
      );

      const bundleSalesForWeek = bundle.weeklySales.find(dateEquals(week.date))?.value;

      return totalSales + bundleSalesForWeek * bundledQuantity;
    }, 0);

    return {
      date: week.date,
      value: totalBundleSalesForWeek,
    };
  });

  // Get bundlePredictedSales per week
  const bundlePredictedSales = weeklySalesPrediction.map((week) => {
    const totalBundleSalesForWeek = bundlesContainingSku.reduce((totalSales, bundle) => {
      const { bundledQuantity } = bundle.bundledSkus.find(
        ({ sku: bundledSku }) => bundledSku === sku.sku,
      );

      const bundleSalesForWeek = bundle.weeklyPrediction.find(dateEquals(week.date))?.value;

      return totalSales + bundleSalesForWeek * bundledQuantity;
    }, 0);

    return {
      date: week.date,
      value: totalBundleSalesForWeek,
    };
  });

  // Build up an array of objects with dates that will be used for subscriptions
  const dates = [...weeklySales, ...weeklySalesPrediction.slice(1)].map((i) => ({
    date: i.date,
    value: 0,
  }));

  // ReserveInventory and bundleReserveInventory arrays aren't always defined
  const { reserveInventoryByDate = [], bundleReserveInventoryByDate = [] } =
    sku.stats.subscriptions ?? {};

  // Group subscriptions by weeks
  const weeklySubscriptions = reserveInventoryByDate.reduce((weeks, subscriptionDay) => {
    const subscriptionSales = subscriptionDay.reserveInventory ?? 0;
    return weeks.map((week) => ({
      ...week,
      value: isSameWeek(new Date(week.date), new Date(subscriptionDay.date), {
        weekStartsOn: getDay(new Date(week.date)),
      })
        ? week.value + subscriptionSales
        : week.value,
    }));
  }, dates);

  // Group bundle subscriptions by weeks
  const weeklyBundleSubscriptions = bundleReserveInventoryByDate.reduce(
    (weeks, subscriptionDay) => {
      const subscriptionSales = subscriptionDay.reserveInventory ?? 0;
      return weeks.map((week) => ({
        ...week,
        value: isSameWeek(new Date(week.date), new Date(subscriptionDay.date), {
          weekStartsOn: getDay(new Date(week.date)),
        })
          ? week.value + subscriptionSales
          : week.value,
      }));
    },
    dates,
  );

  const forecastedInventoryQuantities = sku.stats.forecastedInventoryQuantities || [];

  const purchaseOrders = sku.purchaseOrders.edges.map((po) => po.node);
  const transfers = sku.transfers.edges.map((transfer) => transfer.node);

  // We need these three dates to visually separate the current week
  // Today is used for the vertical reference line
  const today = new Date().toISOString().substring(0, 10);
  // Yesterday is used for displaying actual sales
  const yesterday = subDays(new Date(), 1).toISOString().substring(0, 10);
  // Tomorrow is used for displaying forecasted sales
  const tomorrow = addDays(new Date(), 1).toISOString().substring(0, 10);

  // We add this object to the dataset to have numbers for the tooltip for the "fake"
  // yesterday that represents this week's actual sales
  const todaysDataActual = {
    date: yesterday,
    weeklyPageViews: weeklyPageViews.find(dateEquals(today))?.value,
    individualWeeklySales: removeSubscriptionData(weeklySales, weeklySubscriptions, today),
    bundleWeeklySales: removeSubscriptionData(bundleSales, weeklyBundleSubscriptions, today),
    weeklySubscriptionSales:
      (weeklySubscriptions.find(dateEquals(today))?.value ?? 0) +
      (weeklyBundleSubscriptions.find(dateEquals(today))?.value ?? 0),
  };

  const todaysDataForecasted = {
    date: tomorrow,
  };

  if (account.preferences.forecastEnabled !== false) {
    todaysDataForecasted.individualWeeklySalesPrediction = removeSubscriptionData(
      weeklySalesPrediction,
      weeklySubscriptions,
      today,
    );
    todaysDataForecasted.bundleWeeklySalesPrediction = removeSubscriptionData(
      bundlePredictedSales,
      weeklyBundleSubscriptions,
      today,
    );
    todaysDataForecasted.inventoryQuantity = forecastedInventoryQuantities.find(
      dateEquals(today),
    )?.inventoryQuantity;
    todaysDataForecasted.weeklySubscriptionSalesPrediction =
      (weeklySubscriptions.find(dateEquals(today))?.value ?? 0) +
      (weeklyBundleSubscriptions.find(dateEquals(today))?.value ?? 0);
  }

  const formattedWeeklyData = weeklySales.map((item) => ({
    date: item.date,
    individualWeeklySales: Math.max(
      item.value - (weeklySubscriptions.find(dateEquals(item.date))?.value ?? 0),
      0,
    ),
    bundleWeeklySales: removeSubscriptionData(bundleSales, weeklyBundleSubscriptions, item.date),
    weeklyPageViews: weeklyPageViews.find(dateEquals(item.date))?.value,
    weeklySubscriptionSales:
      (weeklySubscriptions.find(dateEquals(item.date))?.value ?? 0) +
      (weeklyBundleSubscriptions.find(dateEquals(item.date))?.value ?? 0),
  }));

  const predictionData = weeklySalesPrediction.map((item) => ({
    date: item.date,
    individualWeeklySalesPrediction: Math.max(
      item.value - (weeklySubscriptions.find(dateEquals(item.date))?.value ?? 0),
      0,
    ),
    bundleWeeklySalesPrediction: removeSubscriptionData(
      bundlePredictedSales,
      weeklyBundleSubscriptions,
      item.date,
    ),
    ...forecastedInventoryQuantities.find(dateEquals(item.date)),
    weeklySubscriptionSalesPrediction:
      (weeklySubscriptions.find(dateEquals(item.date))?.value ?? 0) +
      (weeklyBundleSubscriptions.find(dateEquals(item.date))?.value ?? 0),
  }));

  // This object is used for the reference line that has no tooltip
  const pseudoTodayForReferenceLine = {
    date: today,
    individualWeeklySales: 0,
  };

  const combinedData = [
    ...formattedWeeklyData.filter(dateNotEquals(today)),
    todaysDataActual,
    pseudoTodayForReferenceLine,
    // Don't show pseudo-tomorrow for forecasted data if the forecast isn't enabled
    ...(account.preferences.forecastEnabled ? [todaysDataForecasted] : []),
  ];

  if (account.preferences.forecastEnabled !== false) {
    const forecastData = predictionData.filter(dateNotEquals(today)).splice(0, 7);
    combinedData.push(...forecastData);
  }

  return (
    <>
      <div className="flex px-6 pt-8">
        {supportsGoogleAnalytics && (
          <div>
            <Toggle enabled={showPageViews} onChange={setShowPageViews} label="Show page views" />
          </div>
        )}
        <div className="ml-auto flex space-x-4 text-xs">
          {showPageViews && (
            <ul>
              <li className="flex items-center">
                <div className="mr-2 h-3 w-3 rounded-full bg-[rgba(255,224,101)]" />
                Page Views
              </li>
            </ul>
          )}

          <ul className="space-y-1">
            <li className="flex items-center">
              <div className="mr-2 h-3 w-3 rounded-sm bg-leafy-75" />
              Individual Units sold
            </li>
            {hasBundles && (
              <li className="flex items-center">
                <div className="mr-2 h-3 w-3 rounded-sm bg-leafy-50" />
                Bundles
              </li>
            )}
            {hasSubscriptions && (
              <li className="flex items-center">
                <div
                  className="mr-2 h-3 w-3 rounded-sm border border-[rgb(3,126,93)]"
                  style={{
                    background:
                      'repeating-linear-gradient(45deg, white, white 1px, rgb(3, 126, 93) 3px, rgb(3, 126, 93) 2px)',
                  }}
                />
                Subscriptions
              </li>
            )}
          </ul>

          <ul className="space-y-1">
            <li className="flex items-center">
              <div className="mr-2 h-3 w-3 rounded-sm bg-purple-100" />
              Forecasted Units Sold
            </li>
            {hasBundles && (
              <li className="flex items-center">
                <div className="mr-2 h-3 w-3 rounded-sm bg-purple-75" />
                Bundles
              </li>
            )}
            {hasSubscriptions && (
              <li className="flex items-center">
                <div
                  className="mr-2 h-3 w-3 rounded-sm border border-purple-100"
                  style={{
                    background:
                      'repeating-linear-gradient(45deg, white, white 1px, rgb(122, 77, 255) 3px, rgb(122, 77, 255) 2px)',
                  }}
                />
                Subscriptions
              </li>
            )}
          </ul>

          <ul>
            <li className="flex items-center">
              <div className="mr-2 flex space-x-0.5">
                <div className="h-0.5 w-0.5 bg-purple-100" />
                <div className="h-0.5 w-0.5 bg-purple-100" />
                <div className="h-0.5 w-0.5 bg-purple-100" />
              </div>
              Forecasted Units on Hand
            </li>
          </ul>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={484}>
        <ComposedChart
          data={combinedData}
          margin={{
            top: 36,
            right: 16,
            left: 16,
            bottom: 32,
          }}
        >
          <pattern
            id="diagonalHatchPast"
            x="0"
            y="0"
            width="6"
            height="6"
            patternTransform="rotate(135 0 0)"
            patternUnits="userSpaceOnUse"
          >
            <line x1="0" y1="0" x2="0" y2="10" strokeWidth="2" stroke="rgba(120,214, 188, .8)" />
          </pattern>
          <pattern
            id="diagonalHatchForecast"
            x="0"
            y="0"
            width="6"
            height="6"
            patternTransform="rotate(135 0 0)"
            patternUnits="userSpaceOnUse"
          >
            <line x1="0" y1="0" x2="0" y2="10" stroke="rgba(100, 46, 225, .8)" strokeWidth="2" />
          </pattern>
          <XAxis
            dataKey="date"
            tickFormatter={(value) => {
              if ([yesterday, tomorrow].includes(value)) return '';
              return new Date(value).toLocaleDateString(
                navigator.language,
                value === today
                  ? {}
                  : {
                      day: '2-digit',
                      month: '2-digit',
                    },
              );
            }}
            axisLine={{ stroke: '#f2f2f2' }}
            tickLine={false}
            style={{ fontSize: '.8rem', fontWeight: '500' }}
            dy={10}
          />
          <YAxis
            axisLine={{ stroke: '#f2f2f2' }}
            tickLine={false}
            tickCount={3}
            tickFormatter={(value) =>
              value.toLocaleString(navigator.language, { notation: 'compact' })
            }
            allowDecimals={false}
            style={{ fontSize: '.8rem', fontWeight: '500' }}
            label={{
              value: 'Actual / Forecasted Units Sold',
              angle: -90,
              position: 'center',
              fontSize: '.8rem',
              fontWeight: '500',
              dx: -20,
            }}
          />
          {account.preferences.forecastEnabled !== false && (
            <YAxis
              axisLine={{ stroke: '#f2f2f2' }}
              tickLine={false}
              tickCount={3}
              tickFormatter={(value) =>
                value.toLocaleString(navigator.language, { notation: 'compact' })
              }
              allowDecimals={false}
              style={{ fontSize: '.8rem', fontWeight: '500' }}
              yAxisId="inventoryQuantity"
              orientation="right"
              label={{
                value: 'Forecasted Units on Hand',
                angle: 90,
                position: 'center',
                fontSize: '.8rem',
                fontWeight: '500',
                dx: 20,
              }}
            />
          )}
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            content={
              <CustomTooltip
                transfers={transfers}
                sku={sku.sku}
                bundleSales={(hasBundles && bundleSales) || []}
                bundlePredictedSales={(hasBundles && bundlePredictedSales) || []}
                showPageViews={showPageViews}
                hasBundles={hasBundles}
                hasSubscriptions={hasSubscriptions}
              />
            }
          />
          <Bar
            dataKey="weeklySubscriptionSales"
            stackId="bar"
            maxBarSize={34}
            fill="url(#diagonalHatchPast)"
            stroke="rgba(120,214, 188, .8)"
            strokeWidth="1"
          />
          <Bar
            dataKey="bundleWeeklySales"
            stackId="bar"
            fill="rgb(210, 238, 231)"
            stroke="rgb(210, 238, 231)"
            strokeWidth="1"
            maxBarSize={34}
          />
          <Bar
            dataKey="individualWeeklySales"
            stackId="bar"
            fill="rgb(120, 214, 188)"
            stroke="rgb(120, 214, 188)"
            strokeWidth="1"
            maxBarSize={34}
          />

          {account.preferences.forecastEnabled !== false && (
            <>
              <Bar
                dataKey="weeklySubscriptionSalesPrediction"
                stackId="bar"
                maxBarSize={34}
                fill="url(#diagonalHatchForecast)"
                stroke="rgba(100, 46, 225, .8)"
              />
              <Bar
                dataKey="bundleWeeklySalesPrediction"
                stackId="bar"
                fill="rgb(152, 123, 248)"
                stroke="rgb(152, 123, 248)"
                maxBarSize={34}
              />
              <Bar
                dataKey="individualWeeklySalesPrediction"
                stackId="bar"
                fill="rgb(122, 77, 255)"
                stroke="rgb(122, 77, 255)"
                maxBarSize={34}
              />
              <Line
                dataKey="inventoryQuantity"
                strokeDasharray="3 4"
                strokeWidth={2}
                stroke="rgb(100, 46, 225)"
                dot={
                  <ForecastInventoryDot
                    purchaseOrders={purchaseOrders}
                    data={combinedData}
                    sku={sku.sku}
                  />
                }
                activeDot={{ r: 8 }}
                yAxisId="inventoryQuantity"
              />
            </>
          )}
          {showPageViews && (
            <Line
              dataKey="weeklyPageViews"
              stroke="rgb(255, 224, 101)"
              strokeWidth={2}
              dot={{
                fill: 'rgb(255, 224, 101)',
                stroke: 'rgb(255, 224, 101)',
                fillOpacity: 1,
                r: 5,
              }}
              activeDot={{ r: 8 }}
            />
          )}

          <ReferenceLine y={0} stroke="#f2f2f2" />

          <ReferenceLine
            x={today}
            stroke="rgb(103, 94, 120)"
            strokeDasharray="3 1"
            strokeWidth={2}
            label={<CustomLabel title="This week" xOffset={56} yOffset={-16} />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

ProductChart.propTypes = {
  sku: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    weeklyPageViews: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    weeklySales: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    weeklyPrediction: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    bundlesContainingSku: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
        weeklySales: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            value: PropTypes.number,
          }),
        ).isRequired,
        weeklyPrediction: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            value: PropTypes.number,
          }),
        ).isRequired,
      }),
    ),
    stats: PropTypes.shape({
      forecastedInventoryQuantities: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          inventoryQuantity: PropTypes.number,
        }),
      ).isRequired,
      subscriptions: PropTypes.shape({
        reserveInventoryByDate: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            reserveInventory: PropTypes.number,
          }),
        ),
        bundleReserveInventoryByDate: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            reserveInventory: PropTypes.number,
          }),
        ),
      }),
    }).isRequired,
    purchaseOrders: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            _id: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    transfers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            transferId: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ProductChart;
