import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiChevronDown, FiPlus } from 'react-icons/fi';
import { Dialog, Listbox } from '@headlessui/react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const GET_VENDORS = gql`
  query getVendors($after: String, $before: String) {
    vendors(first: 500, after: $after, before: $before) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          _id
          name
          sources {
            sourceId
            isDefault
          }
        }
      }
    }
  }
`;

const ADD_VENDOR_SKUS = gql`
  mutation AddVendorToSkus($skus: [String]!, $vendorId: String!, $setAsPrimary: Boolean) {
    addVendorToSkus(skus: $skus, vendorId: $vendorId, setAsPrimary: $setAsPrimary) {
      _id
    }
  }
`;

const AssignVendorPopup = ({ open, onClose, onSave, selectedSkus, removeSku }) => {
  const { addAlert } = useAlerts();

  const { data, loading } = useQuery(GET_VENDORS);
  const [addVendorToSkus] = useMutation(ADD_VENDOR_SKUS);

  const [vendorId, setVendorId] = useState('');
  const [showProducts, setShowProducts] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);

  const handleAssignVendor = async (e) => {
    e.preventDefault();
    await addVendorToSkus({
      variables: {
        skus: selectedSkus.map((sku) => sku.sku),
        vendorId,
        setAsPrimary: isPrimary,
      },
      update: (cache) => {
        cache.evict({ _id: cache.identify({ __typename: 'SkuConnection' }) });
        cache.evict({ _id: cache.identify({ __typename: 'SkuCategoryConnection' }) });
        cache.gc();
      },
    });
    addAlert('Vendor added successfully', { level: 'success' });
    onSave();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-sm rounded bg-white shadow">
          <div className="rounded-t bg-purple-10 px-8 py-5">
            <Dialog.Title className="text-lg font-bold text-purple-100 antialiased">
              Assign Vendor
            </Dialog.Title>
            <div className="inline-flex align-middle text-xs font-medium text-purple-100 opacity-80 hover:text-purple-75 ">
              <button
                className="inline-flex items-center"
                type="button"
                onClick={() => setShowProducts((v) => !v)}
              >
                Assigning vendor to {selectedSkus.length}&nbsp;
                {pluralize('product', selectedSkus.length)}
                <FiChevronDown
                  className={classnames(
                    'transform stroke-current p-1 transition-transform duration-200 ease-out',
                    showProducts && 'scale-y-[-1]',
                  )}
                  size={20}
                  strokeWidth={4}
                />
              </button>
            </div>
            {showProducts && (
              <ul className="mt-2 max-h-[170px] overflow-y-auto text-xs">
                {selectedSkus.map((sku) => (
                  <li key={sku._id} className="flex items-center pb-2">
                    <span className="mr-1 w-2/5 truncate font-bold uppercase antialiased">
                      {sku.sku}
                    </span>
                    <span className="w-3/5 truncate ">{sku.productName}</span>
                    <button
                      type="button"
                      className="group flex h-5 w-5 items-center justify-center rounded-full hover:bg-midnight-10 active:bg-midnight-75"
                      onClick={() => removeSku(sku)}
                      title="Remove product"
                    >
                      <FiPlus
                        strokeWidth={3}
                        className="h-3 w-3 rotate-45 group-active:text-gray-10"
                      />
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <form className="text-xs" onSubmit={handleAssignVendor}>
            <div className="px-8 py-6">
              <Listbox value={vendorId} onChange={setVendorId}>
                {({ open }) => (
                  <>
                    <Listbox.Button className="relative h-8 w-full cursor-default rounded-lg border border-midnight-10 bg-white px-4 py-2 pr-10 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-2">
                      <div className="truncate">
                        {loading
                          ? 'Loading vendors...'
                          : (vendorId &&
                              data.vendors.edges.find(({ node: { _id } }) => vendorId === _id).node
                                .name) ||
                            'Select vendor'}
                      </div>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <FiChevronDown
                          className={classnames(
                            'ml-2 h-5 w-5 text-purple-100 transition-transform duration-200 ease-out',
                            open && '-rotate-180',
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    {data && (
                      <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full max-w-xs overflow-auto rounded-md bg-white shadow-lg ring-2 ring-purple-100 ring-opacity-5 focus:outline-none">
                        {data.vendors.edges.map(({ node: vendor }) => (
                          <Listbox.Option
                            key={vendor._id}
                            value={vendor._id}
                            className={({ active }) =>
                              classnames(
                                'px-4 py-2',
                                active ? 'bg-purple-100 text-white' : 'bg-white text-midnight-100',
                              )
                            }
                          >
                            {({ active }) => (
                              <div className="flex items-center">
                                <div className="truncate">
                                  {vendor.name}
                                  <br />
                                  <span
                                    className={classnames(
                                      'text-xs italic',
                                      active ? 'text-white' : 'text-gray-100',
                                    )}
                                  >
                                    {vendor.sources.find((s) => s.isDefault).sourceId}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    )}
                  </>
                )}
              </Listbox>
              <div className="mt-4 ">
                <label className="cursor-pointer font-bold" htmlFor="setPrimary">
                  <input
                    type="checkbox"
                    id="setPrimary"
                    checked={isPrimary}
                    className="mb-1 mr-2 cursor-pointer rounded border-gray-75 text-purple-100"
                    onChange={() => setIsPrimary((v) => !v)}
                  />
                  Set as primary
                </label>
              </div>
            </div>
            <div className="mb-4 mr-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Assign Vendor'}
                disabled={loading || !vendorId}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

AssignVendorPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      sku: PropTypes.string,
      productName: PropTypes.string,
    }),
  ).isRequired,
  removeSku: PropTypes.func.isRequired,
};

export default AssignVendorPopup;
