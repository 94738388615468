import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import Spinner from '../Components/Spinner.jsx';

export const CONNECT_WITH_GOOGLE = gql`
  mutation ConnectWithGoogle($code: String!) {
    connectWithGoogle(code: $code) {
      id
      name
    }
  }
`;

const CONNECT_WITH_GOOGLE_SHEETS = gql`
  mutation ConnectWithGoogleSheets($code: String!) {
    connectWithGoogleSheets(code: $code)
  }
`;

export const LIST_WEB_PROPERTIES = gql`
  query ListWebProperties($analyticsAccountId: String!) {
    listGoogleAnalyticsWebProperties(analyticsAccountId: $analyticsAccountId) {
      id
      name
      websiteUrl
    }
  }
`;

export const LIST_VIEWS = gql`
  query ListViews($analyticsAccountId: String!, $webPropertyId: String!) {
    listGoogleAnalyticsViews(
      analyticsAccountId: $analyticsAccountId
      webPropertyId: $webPropertyId
    ) {
      id
      name
    }
  }
`;

const SELECT_ACCOUNT = gql`
  mutation SelectAccount($analyticsAccountId: String!, $webPropertyId: String!, $viewId: String!) {
    selectGoogleAnalyticsAccountDetails(
      analyticsAccountId: $analyticsAccountId
      webPropertyId: $webPropertyId
      viewId: $viewId
    ) {
      _id
    }
  }
`;

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addAlert } = useAlerts();

  const query = new URLSearchParams(location.search);

  let isFirstRender = true;

  if (query.get('scope')?.includes('sheets')) {
    const [connectWithGoogleSheets] = useMutation(CONNECT_WITH_GOOGLE_SHEETS, {
      onCompleted: () => navigate('/settings/sources'),
    });

    useEffect(() => {
      if (!isFirstRender) return;

      connectWithGoogleSheets({ variables: { code: query.get('code') } });

      isFirstRender = false;
    }, []);

    return <Spinner />;
  }

  const [analyticsAccountId, setAnalyticsAccountId] = React.useState(null);
  const [webPropertyId, setWebPropertyId] = React.useState(null);
  const [viewId, setViewId] = React.useState(null);

  const [analyticsAccounts, setAnalyticsAccounts] = React.useState(null);

  const [connectWithGoogle] = useMutation(CONNECT_WITH_GOOGLE);
  const [selectAccount, { loading: selectAccountLoading }] = useMutation(SELECT_ACCOUNT);

  const [fetchWebProperties, { loading: webPropertiesLoading, data: webProperties }] = useLazyQuery(
    LIST_WEB_PROPERTIES,
    {
      onCompleted: ({ listGoogleAnalyticsWebProperties }) =>
        setWebPropertyId(listGoogleAnalyticsWebProperties[0]?.id),
    },
  );

  const [fetchViews, { loading: viewsLoading, data: views }] = useLazyQuery(LIST_VIEWS, {
    onCompleted: ({ listGoogleAnalyticsViews }) => setViewId(listGoogleAnalyticsViews[0].id),
  });

  const handleAnalyticsAccountChange = React.useCallback((e) => {
    setAnalyticsAccountId(e.target.value);
    setWebPropertyId(null);
    setViewId(null);
  }, []);

  const handleWebPropertyChange = React.useCallback((e) => {
    setWebPropertyId(e.target.value);
    setViewId(null);
  }, []);

  const handleViewChange = React.useCallback((e) => {
    setViewId(e.target.value);
  }, []);

  useEffect(() => {
    const doConnect = async () => {
      const { data } = await connectWithGoogle({ variables: { code: query.get('code') } });
      setAnalyticsAccounts(data.connectWithGoogle);
      setAnalyticsAccountId(data.connectWithGoogle[0].id);
    };

    doConnect();
  }, []);

  useEffect(() => {
    if (!analyticsAccountId) {
      setWebPropertyId(null);
      return;
    }

    fetchWebProperties({ variables: { analyticsAccountId } });
  }, [analyticsAccountId]);

  useEffect(() => {
    if (!webPropertyId) {
      setViewId(null);
      return;
    }

    fetchViews({ variables: { analyticsAccountId, webPropertyId } });
  }, [analyticsAccountId, webPropertyId]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      const doSubmit = async () => {
        try {
          await selectAccount({ variables: { analyticsAccountId, webPropertyId, viewId } });
        } catch (err) {
          addAlert(err.message, { level: 'error' });
        }
        navigate('/settings/sources');
      };

      doSubmit();
    },
    [analyticsAccountId, webPropertyId, viewId],
  );

  if (!analyticsAccounts || !analyticsAccountId) return <Spinner />;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-4">
        <label htmlFor="account">
          Select the GA account you want to connect
          <select
            id="account"
            className="input max-w-[10rem] pr-8"
            value={analyticsAccountId}
            onChange={handleAnalyticsAccountChange}
          >
            {analyticsAccounts.map((googleAnalyticsAccount) => (
              <option key={googleAnalyticsAccount.id} value={googleAnalyticsAccount.id}>
                {googleAnalyticsAccount.name}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div className="mt-4">
        <label htmlFor="web-property">
          Select the web property
          {webPropertiesLoading && <Spinner />}
          {webProperties && webPropertyId && (
            <select
              id="web-property"
              className="input max-w-[10rem] pr-8"
              value={webPropertyId}
              onChange={handleWebPropertyChange}
            >
              {webProperties.listGoogleAnalyticsWebProperties.map((webProperty) => (
                <option key={webProperty.id} value={webProperty.id}>
                  {webProperty.id} - {webProperty.name} - {webProperty.websiteUrl}
                </option>
              ))}
            </select>
          )}
        </label>
      </div>

      <div className="mt-4">
        <label htmlFor="view">
          Select the view
          {viewsLoading && <Spinner />}
          {views && viewId && (
            <select
              id="view"
              className="input max-w-[10rem] pr-8"
              value={viewId}
              onChange={handleViewChange}
            >
              {views.listGoogleAnalyticsViews?.map((view) => (
                <option key={view.id} value={view.id}>
                  {view.name}
                </option>
              ))}
            </select>
          )}
        </label>
      </div>

      <div className="mt-4">
        <Button
          type="submit"
          label={selectAccountLoading ? 'Please wait...' : 'Continue'}
          slim
          disabled={!analyticsAccountId || !webPropertyId || !viewId || selectAccountLoading}
        />
      </div>
    </form>
  );
};

export default Callback;
