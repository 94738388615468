/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FiAlertTriangle, FiArrowLeft, FiSettings } from 'react-icons/fi';
import classnames from 'classnames';
import { DEFAULT_PAGE_SIZE } from '../../shared/skus.js';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { Pagination } from '../Components/Pagination.jsx';
import Search from '../Components/Search.jsx';
import Spinner from '../Components/Spinner.jsx';
import Tooltip from '../Components/Tooltip.jsx';
import { useCmdDown, useDocumentTitle, useQueryParams } from '../Hooks/index.js';
import EmptyResult from './EmptyResult.jsx';
import ProductLink from './ProductLink.jsx';
import { SKUS_SETTINGS, SKUS_WITH_ERRORS } from './Queries.js';
import RestoreProductButton from './RestoreProductButton.jsx';
import SkusReplenishmentSettings from './SkusReplenishmentSettings.jsx';
import { getSkuPagePath, isMergedSku } from './utils/helpers.js';

const SETTINGS_NAVS = {
  active: 'Active',
  archived: 'Archived',
};

const SETTINGS_TABLE_HEADERS = ['Product', 'SKU', 'Source', 'Vendors'];

const SkuSettingsRow = ({
  sku,
  isSettingsActive,
  handleSkuSelectedChange,
  selectedSkus,
  isArchivedView,
}) => {
  const { account } = useAccount();
  const cmdDown = useCmdDown();
  const navigate = useNavigate();
  const pathname = useLocation();

  const isMerged = isMergedSku(account, sku.sku);
  const skuMergedTo = isMerged ? account.skuMerges.find((s) => s.skuFrom === sku.sku).skuTo : null;
  const skuPagePath = getSkuPagePath(sku);

  const handleClick = () => {
    if (isSettingsActive) {
      if (!isMerged) handleSkuSelectedChange(sku);
      return;
    }
    if (isArchivedView) return;
    if (cmdDown) {
      window.open(skuPagePath, '_blank');
    } else {
      navigate(skuPagePath, { state: { fromPath: pathname } });
    }
  };

  const handleMouseDown = ({ button }) => {
    if (isSettingsActive || isArchivedView) return;
    if (button === 1) {
      window.open(skuPagePath, '_blank');
    }
  };

  return (
    <tr
      className={classnames('group contents', !isArchivedView && 'cursor-pointer')}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <td
        className="h-[4.5rem] rounded-l pl-2 pr-5 group-hover:bg-leafy-10"
        onClick={!isSettingsActive ? (e) => e.stopPropagation() : undefined}
      >
        <div className="flex items-center space-x-5 ">
          <ProductLink
            sku={sku}
            isSettingsActive={isSettingsActive}
            isArchivedView={isArchivedView}
            selectedSkus={selectedSkus}
            pathname={pathname}
          />
        </div>
      </td>
      {!isArchivedView && (
        <>
          <td className="flex items-center text-left text-xs font-bold uppercase group-hover:bg-leafy-10">
            {sku.sku}
          </td>
          <td className="flex items-center text-left text-xs capitalize group-hover:bg-leafy-10">
            {sku.sources.map(({ sourceType }, i) => (i > 0 ? `, ${sourceType}` : sourceType))}
          </td>
          <td className="flex items-center text-left text-xs group-hover:bg-leafy-10">
            {sku.vendorName}
          </td>
        </>
      )}
      {isArchivedView && (
        <>
          <td className="flex items-center group-hover:bg-leafy-10">
            {isMerged && (
              <p>
                This product has been merged into:&nbsp;
                <Link
                  to={getSkuPagePath(skuMergedTo)}
                  state={{ fromPath: pathname }}
                  className="font-bold text-purple-100 underline"
                >
                  {skuMergedTo}
                </Link>
              </p>
            )}
          </td>
          <td className="col-span-2 flex items-center justify-end rounded-r pr-12 text-xs group-hover:bg-leafy-10">
            <RestoreProductButton account={account} sku={sku} isMerged={isMerged} />
          </td>
        </>
      )}
    </tr>
  );
};

SkuSettingsRow.propTypes = {
  sku: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    sku: PropTypes.string,
    productName: PropTypes.string,
    variantName: PropTypes.string,
    vendorName: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.shape()),
    isArchived: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  isSettingsActive: PropTypes.bool.isRequired,
  handleSkuSelectedChange: PropTypes.func.isRequired,
  selectedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      productName: PropTypes.string,
      variantId: PropTypes.string,
    }),
  ).isRequired,
  isArchivedView: PropTypes.bool,
};

SkuSettingsRow.defaultProps = {
  isArchivedView: false,
};

const SkuSettings = () => {
  useDocumentTitle('Products | Settings');

  const { user } = useAccount();
  const { addAlert } = useAlerts();

  const [queryParams, setQueryParams] = useQueryParams([
    'view',
    'skusAfter',
    'skusBefore',
    'search',
  ]);

  const isArchivedView = queryParams.view === 'archived';

  const { data: syncErrors } = useQuery(SKUS_WITH_ERRORS, {
    variables: {
      isWithSyncErrors: true,
      first: 1,
    },
  });

  const settingsNavs = {
    ...SETTINGS_NAVS,
    ...(syncErrors?.skus?.edges?.length && { syncErrors: 'Sync issues' }),
  };

  const {
    data: dataSkus,
    previousData: previousDataSkus,
    loading: loadingSkus,
  } = useQuery(SKUS_SETTINGS, {
    variables: {
      after: queryParams.skusAfter,
      before: queryParams.skusBefore,
      search: queryParams.search,
      isArchived: isArchivedView,
      isWithSyncErrors: queryParams.view === 'syncErrors',
    },
  });

  const [isSettingsActive, setIsSettingsActive] = React.useState(false);
  const [displaySettingsOptions, setDisplaySettingsOptions] = React.useState(false);
  const [selectedSkus, setSelectedSkus] = useState([]);

  const skus = dataSkus?.skus || previousDataSkus?.skus;

  const setNavUrl = (view) => {
    const params = new URLSearchParams({
      ...(view !== 'active' && { view }),
    });
    return `?${params.toString()}`;
  };

  const handleToggleSettingsMode = React.useCallback(() => {
    setIsSettingsActive(!isSettingsActive);
    setDisplaySettingsOptions(!displaySettingsOptions);
    setSelectedSkus([]);
  }, [isSettingsActive]);

  const handleSkuSelectedChange = (selectedSku) => {
    if (selectedSkus.find(({ sku }) => sku === selectedSku.sku)) {
      setSelectedSkus(selectedSkus.filter(({ sku }) => sku !== selectedSku.sku));
    } else {
      if (selectedSkus.length === DEFAULT_PAGE_SIZE) {
        addAlert(`Cannot edit more than ${DEFAULT_PAGE_SIZE} products at once.`, {
          level: 'warning',
        });
        return;
      }
      setSelectedSkus([...selectedSkus, selectedSku]);
    }
  };

  const handleSearch = (searchQuery) => {
    setQueryParams({
      skusAfter: undefined,
      skusBefore: undefined,
      search: searchQuery || undefined,
    });
  };

  const onSaveSettings = () => {
    setSelectedSkus([]);
  };

  const onToggleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedSkus(skus.edges.map(({ node: sku }) => sku));
    } else {
      setSelectedSkus([]);
    }
  };

  const resultData = skus?.edges;

  const displaySearch = resultData?.length > 0 || queryParams.search;
  const displaySettings = !user.isReadOnly && !isArchivedView && resultData?.length > 0;
  const displaySelectAll =
    isSettingsActive &&
    displaySettings &&
    (selectedSkus.length === 0 || selectedSkus.length === skus.edges.length);
  const displayClearSelected =
    isSettingsActive &&
    displaySettings &&
    selectedSkus.length > 0 &&
    selectedSkus.length !== skus.edges.length;

  return (
    <>
      <PageHeader text="Products Settings" />
      <div className="mt-20 flex justify-between font-bold text-midnight-75">
        <div className="flex items-center space-x-4 pt-[3px] text-base">
          {Object.entries(settingsNavs).map(([navKey, navLabel]) => (
            <Link
              key={navKey}
              to={setNavUrl(navKey)}
              className={classnames(
                'pb-[3px] outline-none ring-purple-100 ring-offset-4 focus-visible:rounded focus-visible:border-none focus-visible:pb-0 focus-visible:ring-2',
                (navKey === queryParams.view || (navKey === 'active' && !queryParams.view)) &&
                  'border-b-2 border-purple-100 text-purple-100',
                navKey === 'syncErrors' && 'flex',
              )}
            >
              {navKey === 'syncErrors' && (
                <Tooltip className="mr-1 flex items-center">
                  <Tooltip.Element className="w-5">
                    <FiAlertTriangle className="text-red-100" />
                  </Tooltip.Element>
                  <Tooltip.Body bgColorClass="bg-red-25">
                    <div className="relative text-red-100">
                      These products are missing data and need fixing
                    </div>
                  </Tooltip.Body>
                </Tooltip>
              )}
              {navLabel}
            </Link>
          ))}
        </div>
      </div>
      {loadingSkus && (
        <div className="mt-7">
          <Spinner />
        </div>
      )}

      {!loadingSkus && (
        <div className="mt-7 text-xs text-midnight-100">
          <div className="rounded-lg bg-white shadow">
            {(resultData?.length > 0 || displaySearch) && (
              <div className="flex items-center justify-between border-b border-gray-50 p-5 pb-4">
                <div className="flex">
                  {queryParams.search && (
                    <div className="group mr-5 flex items-center text-sm font-bold text-purple-100">
                      <button
                        type="button"
                        onClick={() => handleSearch(undefined)}
                        className="flex items-center py-1.5 font-bold"
                      >
                        <FiArrowLeft
                          size={18}
                          className="mr-1 transition-transform group-hover:-translate-x-1"
                        />
                        Back
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex">
                  {displaySearch && <Search handleSearch={handleSearch} />}
                  {displaySettings && (
                    <Button
                      variant="text"
                      slim
                      label={isSettingsActive ? 'Exit' : 'Manage Products'}
                      icon={FiSettings}
                      iconSize={16}
                      className={classnames('ml-2 text-sm', isArchivedView ? 'hidden' : '')}
                      onClick={handleToggleSettingsMode}
                    />
                  )}
                </div>
              </div>
            )}

            {displaySettingsOptions && displaySettings && (
              <SkusReplenishmentSettings
                selectedSkus={selectedSkus}
                onSaveSettings={onSaveSettings}
                removeSku={handleSkuSelectedChange}
              />
            )}

            <div className="p-5 pt-4 text-xs">
              {resultData?.length === 0 && <EmptyResult />}
              {resultData?.length > 0 && (
                <table className="grid grid-cols-[20rem,1.5fr,1.5fr,repeat(1,1fr)] gap-y-1.5 pt-4">
                  <thead className="contents">
                    <tr className="contents whitespace-nowrap">
                      {SETTINGS_TABLE_HEADERS.map((item) => (
                        <th
                          key={item}
                          className={classnames('h-8 text-left', item === 'Product' && 'pl-2')}
                        >
                          {item === 'Product' && (
                            <div className="flex items-center">
                              {displayClearSelected && (
                                <button
                                  type="button"
                                  className="flex items-center font-bold"
                                  onClick={() => setSelectedSkus([])}
                                >
                                  <div className="mr-4 flex h-4 w-4 items-center justify-center rounded bg-purple-100">
                                    <div className="h-0.5 w-2 rounded bg-white" />
                                  </div>
                                </button>
                              )}
                              {displaySelectAll && (
                                <input
                                  type="checkbox"
                                  readOnly
                                  className="mr-4 rounded border-gray-75 text-purple-100 focus:ring-purple-100"
                                  onChange={(evt) => onToggleSelectAll(evt)}
                                  checked={selectedSkus.length === skus.edges.length}
                                />
                              )}

                              <span
                                className={classnames(
                                  isSettingsActive && !isArchivedView ? 'ml-1' : '',
                                )}
                              >
                                {item}
                              </span>
                              {isSettingsActive && displaySettings && selectedSkus.length > 0 && (
                                <span className="ml-2">({selectedSkus.length} selected)</span>
                              )}
                            </div>
                          )}
                          {item !== 'Product' && !isArchivedView && item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="contents">
                    {resultData?.map(({ node: sku }) => (
                      <SkuSettingsRow
                        key={sku.sku}
                        sku={sku}
                        isSettingsActive={isSettingsActive && displaySettings}
                        handleSkuSelectedChange={handleSkuSelectedChange}
                        selectedSkus={selectedSkus}
                        isArchivedView={isArchivedView}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {resultData && (
            <Pagination
              prefix="skus"
              items={skus}
              isLoading={loadingSkus}
              className="mt-6 flex justify-center space-x-4"
            >
              <Pagination.PrevButton />
              <Pagination.NextButton />
            </Pagination>
          )}
        </div>
      )}
    </>
  );
};

export default SkuSettings;
