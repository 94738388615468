import React from 'react';
import { useAccount } from '../AccountProvider.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import ForecastRun from './ForecastRun.jsx';

const ForecastRuns = () => {
  const { axios } = useAccount();
  useDocumentTitle('Forecast Logs');

  const [forecastRuns, setForecastRuns] = React.useState(null);

  React.useEffect(() => {
    const loadForecastRuns = async () => {
      const { data: forecastRuns } = await axios('/api/admin/forecast-runs');

      setForecastRuns(forecastRuns);
    };

    loadForecastRuns();
  }, []);

  return (
    <>
      <PageHeader text="Forecast logs (all accounts)" />

      {forecastRuns && (
        <div className="mt-16 grid grid-cols-12 gap-x-6">
          {forecastRuns.map((forecastRun) => (
            <div
              className={`col-span-11 mt-6 rounded-lg px-6 pb-7 pt-5 text-midnight-100 shadow ${
                forecastRun.status === 'error' ? 'bg-red-75' : ''
              }`}
              key={`${forecastRun.account._id}-${forecastRun.completedAt}`}
            >
              <h5 className="mb-3 border-b border-gray-50 pb-4 font-bold">
                {forecastRun.account.name} ~ Status: {forecastRun.status}
              </h5>

              <ForecastRun forecastRun={forecastRun} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ForecastRuns;
