import { gql } from '@apollo/client';

export const FRAGMENT_TRANSFER = gql`
  fragment TransferFields on Transfer {
    _id
    transferId
    customId
    fromLocation {
      _id
      name
      sources {
        sourceId
      }
    }
    toLocation {
      _id
      name
      sources {
        sourceId
      }
    }
    expectedDeliveryDate
    issuedAt
    deliveredAt
    items {
      sku
      variantId
      quantity
      receivedQuantity
    }
    trackingCode
    trackingUrl
    wroCode
    wroTextUrl
    notes
    updatedAt
    createdAt
    status
    inventorySourceToUpdate {
      sourceType
      sourceId
      hasRequiredPermission
      updateInventory
      shopifyRedirectUrl {
        redirectUrl
      }
    }
    sourceId
  }
`;

export const TRANSFERS = gql`
  ${FRAGMENT_TRANSFER}

  query transfers($after: String, $before: String, $first: Int, $sortKey: TransferSortKeys) {
    transfers(after: $after, before: $before, first: $first, sortKey: $sortKey, type: internal) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          ...TransferFields
        }
      }
    }
  }
`;

export const TRANSFER_QUERY = gql`
  ${FRAGMENT_TRANSFER}

  query GetTransfer($_id: String!) {
    transfer(_id: $_id) {
      ...TransferFields
    }
  }
`;

export const CREATE_TRANSFER = gql`
  ${FRAGMENT_TRANSFER}

  mutation CreateTransfer($input: TransferInput!) {
    createTransfer(input: $input) {
      ...TransferFields
    }
  }
`;

export const UPDATE_TRANSFER = gql`
  ${FRAGMENT_TRANSFER}

  mutation UpdateTransfer($input: TransferInput!, $transferId: ID!) {
    updateTransfer(input: $input, _id: $transferId) {
      ...TransferFields
    }
  }
`;

export const MARK_AS_SHIPPED = gql`
  ${FRAGMENT_TRANSFER}

  mutation MarkAsShipped(
    $transferId: ID!
    $syncInventoryToSource: Boolean!
    $sourceToUpdate: String
  ) {
    markAsShipped(
      _id: $transferId
      syncInventoryToSource: $syncInventoryToSource
      sourceToUpdate: $sourceToUpdate
    ) {
      ...TransferFields
    }
  }
`;

export const MARK_AS_DELIVERED = gql`
  ${FRAGMENT_TRANSFER}

  mutation MarkAsDelivered(
    $transferId: ID!
    $input: TransferInput!
    $syncInventoryToSource: Boolean!
    $sourceToUpdate: String
  ) {
    markAsDelivered(
      _id: $transferId
      input: $input
      syncInventoryToSource: $syncInventoryToSource
      sourceToUpdate: $sourceToUpdate
    ) {
      ...TransferFields
    }
  }
`;

export const REMOVE_TRANSFER = gql`
  mutation RemoveTransfer(
    $transferId: ID!
    $syncInventoryToSource: Boolean!
    $sourceToUpdate: String
  ) {
    removeTransfer(
      _id: $transferId
      syncInventoryToSource: $syncInventoryToSource
      sourceToUpdate: $sourceToUpdate
    )
  }
`;

export const UPDATE_SOURCE = gql`
  mutation UpdateSource($input: UpdateSourceInput!, $sourceId: String!) {
    updateSource(input: $input, sourceId: $sourceId) {
      sourceId
      updateInventory
    }
  }
`;

export const clearTransfersCache = (cache) => {
  cache.evict({ fieldName: 'transfers' });
  cache.gc();
};
