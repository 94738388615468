import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  FiActivity,
  FiBox,
  FiChevronRight,
  FiClipboard,
  FiCreditCard,
  FiGrid,
  FiHelpCircle,
  FiKey,
  FiLogOut,
  FiSettings,
  FiShoppingCart,
  FiTruck,
} from 'react-icons/fi';
import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import { hasSupportFor } from '../../shared/sources.js';
import { useAccount } from '../AccountProvider.jsx';
import { useDemo } from '../Components/DemoProvider.jsx';
import ProgressBar from '../Components/ProgressBar.jsx';
import Toggle from '../Components/Toggle.jsx';
import { formatSourceName } from '../Skus/utils/formatSourceNames.js';
import AccountSwitcher from './AccountSwitcher.jsx';

const Navbar = () => {
  const { user, account, showHints, setShowHints } = useAccount();
  const { isDemo, toggleDemo } = useDemo();
  const location = useLocation();
  const learningModeEnabled = useFeature('learning-mode').on;
  const TransfersIndexEnabled = useFeature('transfers-index-page').on;
  const skuSettingsEnabled = useFeature('sku-settings-index-page').on;

  const isSettingsMenuActive =
    location.pathname.includes('/settings') && !location.pathname.includes('skus/settings');
  const isActiveSubMenu = (mainNavUrl) => location.pathname.includes(mainNavUrl);

  const handleHintsToggle = () => {
    setShowHints(!showHints);
    localStorage.setItem('showHints', !showHints);
  };

  return (
    <nav className="fixed inset-y-0 flex w-52 flex-col pb-6 pl-6 pt-10 text-sm text-midnight-100">
      <NavLink
        to="/"
        className="mb-16 ml-1 rounded-full py-2 pl-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100"
      >
        <img src="/cogsy-logo-on-white.svg" alt="Cogsy - Dashboard" />
      </NavLink>

      {account.hasActivePlan && (
        <div className="mb-8">
          <Link
            to="/planning/growth"
            className="inline-block whitespace-nowrap rounded border border-leafy-75 bg-leafy-10 px-2 py-1 align-middle text-xs font-medium text-leafy-100"
          >
            Custom Growth Plan active
          </Link>
        </div>
      )}

      <ul className="-ml-1 space-y-3 overflow-y-auto pl-1">
        <li>
          <NavLink
            to="/"
            end
            className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
          >
            <FiGrid className="mr-2 text-purple-100" />
            Dashboard
          </NavLink>
        </li>
        <li>
          <Disclosure defaultOpen={isActiveSubMenu('/skus')}>
            {({ open }) => (
              <>
                <Disclosure.Button className="nav-item">
                  <FiBox className="mr-2 text-purple-100" />
                  Products
                  <FiChevronRight className={classnames('ml-2', open && 'rotate-90')} />
                </Disclosure.Button>
                <Disclosure.Panel className="mr-4 mt-2 space-y-1 pl-5">
                  <NavLink
                    to="/skus/replenishment"
                    className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
                  >
                    Replenishment
                  </NavLink>
                  <NavLink
                    to="skus/planning"
                    className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
                  >
                    Planning
                  </NavLink>
                  {skuSettingsEnabled && (
                    <NavLink
                      to="skus/settings"
                      className={({ isActive }) =>
                        classnames('nav-item', isActive && 'bg-purple-10')
                      }
                    >
                      Settings
                    </NavLink>
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </li>
        {account.preferences.forecastEnabled !== false && (
          <li>
            <NavLink
              to="/purchase-orders"
              className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
            >
              <FiShoppingCart className="mr-2 text-purple-100" />
              Purchase Orders
            </NavLink>
          </li>
        )}
        {TransfersIndexEnabled && (
          <li>
            <NavLink
              to="/transfers"
              className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
            >
              <FiTruck className="mr-2 text-purple-100" />
              Transfers
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            to="/backorders"
            className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
          >
            <FiClipboard className="mr-2 text-purple-100" />
            Backorders
          </NavLink>
        </li>
        <li>
          <Disclosure defaultOpen={isActiveSubMenu('/planning')}>
            {({ open }) => (
              <>
                <Disclosure.Button className="nav-item">
                  <FiActivity className="mr-2 text-purple-100" />
                  Planning
                  <FiChevronRight className={classnames('ml-2', open && 'rotate-90')} />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-2 space-y-1 pl-5">
                  <NavLink
                    to="/planning/growth"
                    className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
                  >
                    Growth Plan
                  </NavLink>
                  <NavLink
                    to="/planning/marketing-events"
                    className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
                  >
                    Marketing Events
                  </NavLink>
                  <NavLink
                    to="/planning/planned-products"
                    className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
                  >
                    Planned Products
                  </NavLink>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </li>
        <li>
          <NavLink
            to="/vendors"
            className={({ isActive }) => classnames('nav-item', isActive && 'bg-purple-10')}
          >
            <FiCreditCard className="mr-2 text-purple-100" />
            Vendors
          </NavLink>
        </li>

        {user.isAdmin && (
          <>
            <li>
              <Disclosure defaultOpen={isActiveSubMenu('/admin')}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="nav-item">
                      <FiKey className="mr-2 text-purple-100" />
                      Admin
                      <FiChevronRight className={classnames('ml-2', open && 'rotate-90')} />
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-2 space-y-1 pl-5">
                      <NavLink
                        to="/admin/accounts"
                        className={({ isActive }) =>
                          classnames('nav-item', isActive && 'bg-purple-10')
                        }
                      >
                        Accounts
                      </NavLink>
                      <NavLink
                        to="/admin/forecast-runs"
                        className={({ isActive }) =>
                          classnames('nav-item', isActive && 'bg-purple-10')
                        }
                      >
                        Forecast Log
                      </NavLink>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </li>
            <li className="px-3 py-1.5">
              <Toggle enabled={isDemo} onChange={toggleDemo} label="Demo mode" />
            </li>
          </>
        )}
        {learningModeEnabled && (
          <li className="px-3 py-1.5" id="learningModeToggle">
            <Toggle enabled={showHints} onChange={handleHintsToggle} label="Learning mode" />
          </li>
        )}
      </ul>
      <div className="mt-auto">
        {account.hasSyncError && (
          <div className="mt-6 pl-3">
            <div className="mb-2 text-red-100">Sync on hold. We are looking into it 👀</div>
            <ProgressBar value={50} />
          </div>
        )}
        {account.sources &&
          account.sources
            .filter(
              ({ sourceContext }) =>
                sourceContext.skusSyncStatus &&
                sourceContext.skusSyncStatus !== 'completed' &&
                sourceContext.skusSyncStatus !== 'error',
            )
            .map(({ sourceId, sourceType, sourceContext }) =>
              sourceContext.skusSyncStatus === 'suspended' ? (
                <div key={sourceId} className="mt-6 pl-3">
                  <div className="mb-2 text-red-100">
                    <span className="capitalize">{formatSourceName({ sourceType })}</span> sync is
                    suspended
                  </div>
                  <ProgressBar value={50} />
                </div>
              ) : (
                <div key={sourceId} className="mt-6 pl-3">
                  <div className="mb-2">
                    Syncing products from&nbsp;
                    <span className="capitalize">{formatSourceName({ sourceType })}</span>
                  </div>
                  <ProgressBar value={(sourceContext.skusSynced / sourceContext.skusTotal) * 100} />
                </div>
              ),
            )}

        {account.sources &&
          account.sources
            .filter(
              ({ sourceContext }) =>
                sourceContext.ordersSyncStatus &&
                sourceContext.ordersSyncStatus !== 'completed' &&
                sourceContext.ordersSyncStatus !== 'error',
            )
            .map(({ sourceId, sourceType, sourceContext }) => (
              <div key={sourceId} className="mt-3 pl-3">
                <div className="mb-2">
                  Syncing orders from&nbsp;
                  <span className="capitalize">{formatSourceName({ sourceType })}</span>
                </div>
                <ProgressBar
                  value={(sourceContext.ordersSynced / sourceContext.ordersTotal) * 100}
                />
              </div>
            ))}

        {account.sources &&
          account.sources
            .filter(
              ({ sourceType, sourceContext }) =>
                hasSupportFor(sourceType, 'analytics') &&
                sourceContext.analyticsSyncStatus &&
                sourceContext.analyticsSyncStatus !== 'completed' &&
                sourceContext.analyticsSyncStatus !== 'error',
            )
            .map(({ sourceId, sourceType, sourceContext }) => (
              <div key={sourceId} className="mt-3 pl-3">
                <div className="mb-2">
                  Syncing analytics from&nbsp;
                  <span className="capitalize">{formatSourceName({ sourceType })}</span>
                </div>
                <ProgressBar
                  value={(sourceContext.analyticsSynced / sourceContext.analyticsTotal) * 100}
                />
              </div>
            ))}

        {account.sources &&
          account.sources
            .filter(
              ({ sourceType, sourceContext }) =>
                hasSupportFor(sourceType, 'subscriptions') &&
                sourceContext.subscriptionsSyncStatus &&
                sourceContext.subscriptionsSyncStatus !== 'completed',
            )
            .map(({ sourceId, sourceType }) => (
              <div key={sourceId} className="mt-3 pl-3">
                <div className="mb-2">
                  Syncing subscriptions from&nbsp;
                  <span className="capitalize">{formatSourceName({ sourceType })}</span>
                </div>
                <ProgressBar />
              </div>
            ))}
        {account.forecast?.status && !['completed', 'error'].includes(account.forecast?.status) && (
          <div className="mt-3 pl-3">
            Running forecast: {account.forecast.status}
            <br />
            This may take some time...
          </div>
        )}
        <div className="mt-3 pl-3">
          <AccountSwitcher isInNavbar />
        </div>
        <ul className="mt-5">
          <li>
            <NavLink
              to="/settings/profile"
              className={() => classnames('nav-item', isSettingsMenuActive && 'bg-purple-10')}
            >
              <FiSettings className="mr-2 text-purple-100" />
              Settings
            </NavLink>
          </li>
          <li className="mt-3">
            <a href="https://help.cogsy.com/" className="nav-item" target="_blank" rel="noreferrer">
              <FiHelpCircle className="mr-2 text-purple-100" />
              Help Docs
            </a>
          </li>
          <li className="mt-3">
            <Link to="/logout" className="nav-item">
              <FiLogOut className="mr-2 text-purple-100" />
              Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
