import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown } from 'react-icons/fi';
import { Listbox } from '@headlessui/react';
import classnames from 'classnames';
import LoadingIcon from './LoadingIcon.jsx';

const SplitButton = ({ options, onClick, isLoading }) => {
  const [selectedOptionId, setSelectedOptionId] = useState(0);

  return (
    <div className="inline-flex h-7 text-xs">
      <button
        type="button"
        className="h-full whitespace-nowrap rounded-l-full border-r border-purple-75 bg-purple-100 pl-4 pr-3 text-xs font-bold text-white outline-none ring-purple-100 ring-offset-1 hover:bg-purple-90 focus-visible:ring-2 disabled:border-purple-10/30 disabled:bg-purple-75 disabled:text-purple-10"
        onClick={() => onClick(options[selectedOptionId].key)}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <LoadingIcon className="-ml-1 mr-2" />
            {options[selectedOptionId].loadingLabel || 'Please wait…'}
          </>
        ) : (
          options[selectedOptionId].label
        )}
      </button>

      <Listbox value={selectedOptionId} onChange={setSelectedOptionId}>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className={classnames(
                'h-full rounded-r-full bg-purple-100 pl-2 pr-3 text-xs outline-none ring-purple-100 ring-offset-1 focus-visible:ring-2',
                isLoading
                  ? 'pointer-events-none bg-purple-75 text-purple-10'
                  : 'hover:bg-purple-90',
              )}
            >
              <div>
                <FiChevronDown
                  strokeWidth={3}
                  size={14}
                  className={classnames(
                    `text-white transition-transform duration-200 ease-out`,
                    open && 'rotate-180',
                  )}
                  aria-hidden="true"
                />
              </div>
            </Listbox.Button>

            <Listbox.Options className="absolute -top-2 right-0 z-10 w-64 -translate-y-full rounded-lg border-gray-50 bg-white shadow outline-none">
              {options.map((option, index) => (
                <Listbox.Option key={option.key} value={index}>
                  {({ active }) => (
                    <div
                      className={classnames(
                        'inset-1 cursor-pointer border-x px-3 py-2 first-of-type:pt-2.5 last-of-type:pb-2.5',
                        active ? 'border-purple-100 bg-purple-100 text-white' : 'border-gray-50',
                        index === 0 && 'rounded-t-lg border-t',
                        index === options.length - 1 && 'rounded-b-lg border-b',
                      )}
                    >
                      <p className="font-bold">{option.menuTitle}</p>
                      {option.menuDescription ? (
                        <p className="mt-0.5">{option.menuDescription}</p>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
    </div>
  );
};

SplitButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      loadingLabel: PropTypes.string,
      menuTitle: PropTypes.string.isRequired,
      menuDescription: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

SplitButton.defaultProps = {
  isLoading: false,
};

export default SplitButton;
