export const STATUSES = {
  DRAFT: 'Draft',
  PRE_PRODUCTION: 'Pre-Production',
  PRODUCTION: 'Production',
  PRE_SHIPMENT: 'Pre-Shipment',
  SHIPMENT: 'Shipment',
  PARTIALLY_DELIVERED: 'Partially Delivered',
  DELIVERED: 'Delivered',
};

export const ordered = [
  'DRAFT',
  'PRE_PRODUCTION',
  'PRODUCTION',
  'PRE_SHIPMENT',
  'SHIPMENT',
  'PARTIALLY_DELIVERED',
  'DELIVERED',
];

export const orderedCompact = [
  'DRAFT', 'PRODUCTION', 'PRE-SHIP', 'SHIPMENT', 'DELIVERED',
];

export const all = Object.keys(STATUSES);

export const checkStatus = (status) => ({
  isCompleted: (milestone) => ordered.indexOf(status) > ordered.indexOf(milestone),
  isActiveOrCompleted: (milestone) => ordered.indexOf(status) >= ordered.indexOf(milestone),
});

export const statusToText = (status) => STATUSES[status];

export default {
  statusToText, STATUSES, ordered, orderedCompact, all, checkStatus,
};
