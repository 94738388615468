import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ProductFruits, useProductFruitsApi } from 'react-product-fruits';
import AccountProvider, { useAccount } from './AccountProvider.jsx';
import Admin from './Admin/Index.jsx';
import AmazonCallback from './Amazon/Callback.jsx';
import Backorders from './Backorders/Index.jsx';
import BillingCheck from './Billing/Check.jsx';
import BillingPlans from './Billing/Plans.jsx';
import AlertsProvider from './Components/AlertsProvider.jsx';
import DemoProvider from './Components/DemoProvider.jsx';
import LoadingPage from './Components/LoadingPage.jsx';
import Dashboard from './Dashboard/Index.jsx';
import FulfilCallback from './Fulfil/Callback.jsx';
import GoogleCallback from './Google/Callback.jsx';
import InternalTransfers from './InternalTransfers/Index.jsx';
import InternalTransfer from './InternalTransfers/InternalTransfer.jsx';
import Navbar from './Layout/Navbar.jsx';
import NotFound from './Layout/NotFound.jsx';
import OAuth from './OAuth/Index.jsx';
import Planning from './Planning/Index.jsx';
import PurchaseOrder from './PurchaseOrders/Edit.jsx';
import PurchaseOrders from './PurchaseOrders/List.jsx';
import PurchaseOrderTransfers from './PurchaseOrders/Transfers.jsx';
import Settings from './Settings/Index.jsx';
import ShipbobCallback from './Shipbob/Callback.jsx';
import ShopifyAuth from './Shopify/Auth.jsx';
import EditProduct from './Skus/EditProduct.jsx';
import Product from './Skus/Product.jsx';
import SkusPlanning from './Skus/SkusPlanning.jsx';
import SkusReplenishment from './Skus/SkusReplenishment.jsx';
import SkusSettings from './Skus/SkusSettings.jsx';
import Vendors from './Vendors/Index.jsx';
import Vendor from './Vendors/Vendor.jsx';

const BillingGatedApp = () => {
  const { account, user, showHints } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();

  const search = new URLSearchParams(location.search);
  React.useEffect(() => {
    if (!account.isSubscribed) {
      navigate('/billing/plans');
    }
  }, [account]);

  const skusUrl = search.has('category')
    ? `/skus/replenishment?category=${search.get('category')}`
    : '/skus/replenishment';

  if (!account?.isSubscribed) return <LoadingPage />;

  const productFruitsUserData = {
    username: user._id, // username should be a unique identifier hence the _id
    signUpAt: new Date(parseInt(user._id.substring(0, 8), 16) * 1000).toJSON(),
    email: user.email,
    group: {
      groupId: account._id,
    },
  };

  useProductFruitsApi(
    (api) => {
      api.hints.toggleVisibility(showHints);
    },
    [showHints],
  );

  return (
    <Routes>
      <Route path="/oauth/start" element={<OAuth />} />
      <Route
        path="*"
        element={
          <>
            <Navbar />
            <ProductFruits
              workspaceCode="YhUexlE66QBlpXeT"
              language="en"
              user={productFruitsUserData}
            />

            <main className="px-14 py-12" style={{ marginLeft: 'calc(100% - (100% - 208px))' }}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/skus/:sku" element={<Product />} />
                <Route path="/skus/:sku/edit" element={<EditProduct />} />
                <Route path="/skus/settings" element={<SkusSettings />} />
                <Route path="/skus/replenishment" element={<SkusReplenishment />} />
                <Route path="/skus/planning" element={<SkusPlanning />} />
                <Route path="/skus" element={<Navigate replace to={skusUrl} />} />
                <Route path="/purchase-orders" element={<PurchaseOrders />} />
                <Route path="/purchase-orders/:_id" element={<PurchaseOrder />} />
                <Route
                  path="/purchase-orders/:_id/shipments"
                  element={<PurchaseOrderTransfers />}
                />
                <Route path="/settings/*" element={<Settings />} />
                <Route path="/google-analytics/callback" element={<GoogleCallback />} />
                <Route path="/shipbob/callback" element={<ShipbobCallback />} />
                <Route path="/amazon/callback" element={<AmazonCallback />} />
                <Route path="/fulfil/callback" element={<FulfilCallback />} />
                <Route path="/backorders" element={<Backorders />} />
                <Route path="/admin/*" element={<Admin />} />
                <Route path="/planning/*" element={<Planning />} />
                <Route path="/vendors" element={<Vendors />} />
                <Route path="/vendors/:_id" element={<Vendor />} />
                <Route path="/transfers" element={<InternalTransfers />} />
                <Route path="/transfers/:_id" element={<InternalTransfer />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </>
        }
      />
    </Routes>
  );
};

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const search = new URLSearchParams(location.search);
  const token = window.localStorage.getItem('token');

  React.useEffect(() => {
    if (search.has('token')) {
      window.localStorage.setItem('token', search.get('token'));
      window.location.assign('/');
    }

    if (!token) {
      navigate(`/login?u=${encodeURIComponent(window.location.href)}`);
    }
  }, []);

  if (search.has('shop') && search.has('hmac')) {
    return <ShopifyAuth shop={search.get('shop')} />;
  }

  if (!token) return <LoadingPage />;

  return (
    <AlertsProvider>
      <AccountProvider token={token}>
        <DemoProvider>
          <Routes>
            <Route path="/billing/plans" element={<BillingPlans />} />
            <Route path="/billing/check" element={<BillingCheck />} />
            <Route path="*" element={<BillingGatedApp />} />
          </Routes>
        </DemoProvider>
      </AccountProvider>
    </AlertsProvider>
  );
};

export default App;
