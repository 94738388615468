import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import pluralize from 'pluralize';
import CopyButton from '../../Components/CopyButton.jsx';
import Panel from '../../Dashboard/Widgets/Widget.jsx';
import { toDate } from '../../Utils/dates.js';

const ProductWidget = ({ title, items, baseUrl, parameter }) => (
  <Panel barColor="purple" className="col-span-4 h-auto self-start">
    <Panel.Title>{title}</Panel.Title>
    <Panel.Content>
      <table className="grid grid-cols-[1fr,1fr,.5fr,1fr] text-xs">
        <thead className="contents">
          <tr className="contents">
            <th className="pb-3 pl-1 text-left">ID</th>
            <th className="pl-4 text-left">Destination</th>
            <th className="text-right">Units</th>
            <th className="pr-1 text-right">ETA</th>
          </tr>
        </thead>
        <tbody className="contents">
          {items.map((order) => (
            <tr key={order.customId ?? order.transferId ?? order._id} className="group contents">
              <td className="flex rounded-l py-2 pr-2 group-hover:bg-leafy-10">
                <div className="flex items-center rounded bg-leafy-10">
                  <Link
                    to={baseUrl ? `${baseUrl}/${order._id}` : null}
                    className={classnames(!baseUrl && 'pointer-events-none', 'w-20')}
                  >
                    <div
                      className="truncate rounded-l py-1"
                      title={order.customId ?? order.transferId ?? order._id}
                    >
                      <span className="ml-1">
                        {order.customId ?? order.transferId ?? order._id}
                      </span>
                    </div>
                  </Link>
                  <div className="rounded-r p-1">
                    <CopyButton
                      clipboardValue={order.customId ?? order.transferId ?? order._id}
                      copySubject={`${pluralize(title, 1)} ID`}
                    />
                  </div>
                </div>
              </td>
              <td className="flex items-center group-hover:bg-leafy-10">
                <Link
                  to={baseUrl ? `${baseUrl}/${order._id}` : null}
                  className={classnames(
                    !baseUrl && 'pointer-events-none',
                    'block w-24 truncate py-2 pl-4',
                  )}
                >
                  {order.toLocation?.name ?? order.location?.name}
                </Link>
              </td>
              <td className="flex items-center text-right group-hover:bg-leafy-10">
                <Link
                  to={baseUrl ? `${baseUrl}/${order._id}` : null}
                  className={classnames(!baseUrl && 'pointer-events-none', 'block w-full py-2')}
                >
                  {order.item?.inTransitQuantity ??
                    order.item?.quantity ??
                    order.items.find(({ sku }) => sku === parameter)?.quantity}
                </Link>
              </td>
              <td className="flex items-center rounded-r pr-1 text-right group-hover:bg-leafy-10">
                <Link
                  to={baseUrl ? `${baseUrl}/${order._id}` : null}
                  className={classnames(!baseUrl && 'pointer-events-none', 'block w-full py-2')}
                >
                  {order.item?.expectedDeliveryDate || order.expectedDeliveryDate
                    ? toDate(
                        order.item?.expectedDeliveryDate ?? order.expectedDeliveryDate,
                      ).toLocaleDateString(undefined, {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      })
                    : '-'}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel.Content>
  </Panel>
);

ProductWidget.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string,
  parameter: PropTypes.string,
};

ProductWidget.defaultProps = {
  baseUrl: null,
  parameter: null,
};

export default ProductWidget;
