import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-plugin-dragdata';
import { format, startOfMonth } from 'date-fns';
import { Line } from 'react-chartjs-2';
import { useAccount } from '../AccountProvider.jsx';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PlanningGraph = ({ planningChartData, plan, onUpdate }) => {
  const { account } = useAccount();
  const [currentRevPlan, setCurrentRevPlan] = useState(null);
  const baselineLabels = planningChartData.baseline.map((item) =>
    new Date(item.date).toLocaleDateString(navigator.language, {
      month: 'short',
      year: '2-digit',
      timeZone: 'UTC',
    }),
  );
  const labels = [...baselineLabels];

  const cogsyPrediction = planningChartData.baseline.map(
    ({ monthGrowth }) => monthGrowth * 100 - 100,
  );

  let activePlan = (plan || []).map(({ monthGrowth }) => monthGrowth * 100 - 100);

  // active plan is not updated monthly hence filter out previous month data
  const currentMonthIndex = (plan || []).findIndex(
    ({ date }) => date === format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );

  if (currentMonthIndex !== -1) {
    activePlan = activePlan.slice(currentMonthIndex);
  }
  const planToEdit = currentMonthIndex !== -1 ? (plan || []).slice(currentMonthIndex) : plan;
  const activePlanRevenue = planToEdit?.map((item) => item.value);
  const monthGrowths = planToEdit?.map((item) => item.monthGrowth);

  const remainingRevenue = planningChartData.baseline.map(({ value }) => value);
  activePlanRevenue.push(...remainingRevenue.slice(activePlanRevenue.length, 12));

  // Psuedo-dynamic y-axis
  const activePlanMax = Math.max(...activePlan) + 25;
  const activePlanMin = Math.min(...activePlan) - 25;
  const baselineMax = Math.max(...cogsyPrediction) + 25;
  const baselineMin = Math.min(...cogsyPrediction) - 25;

  const higherLimit = Math.max(activePlanMax, baselineMax);
  const lowerLimit = Math.min(activePlanMin, baselineMin);

  useEffect(() => {
    if (!currentRevPlan) setCurrentRevPlan(activePlanRevenue);
  }, [activePlanRevenue]);

  let backgroundColors = 'rgb(241, 237, 254)';
  if (currentRevPlan) {
    const delta = currentRevPlan.findIndex((c, i) => activePlanRevenue[i] !== c) || 0;
    backgroundColors = currentRevPlan.map((c, i) => {
      if (delta > -1 && i >= delta) return 'rgba(122, 77, 255, .6)';
      return 'rgb(241, 237, 254)';
    });
  }

  const graphData = {
    labels,
    datasets: [
      {
        label: 'Active Plan',
        data: [...activePlan],
        borderColor: 'rgb(122, 77, 255)',
        backgroundColor: 'rgb(122, 77, 255)',
        dragData: true,
      },
      {
        label: 'Cogsy Predicted Growth Rate',
        data: [...cogsyPrediction],
        borderColor: 'rgb(189, 189, 189)',
        backgroundColor: 'rgb(189, 189, 189)',
        borderDash: [5, 2],
        dragData: false,
      },
      {
        type: 'bar',
        label: 'Predicted Revenue',
        data: activePlanRevenue,
        backgroundColor: backgroundColors,
        borderRadius: 7,
        dragData: false,
        yAxisID: 'revenue',
      },
    ],
  };

  // this controls the margin between the legend and the graph
  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      // eslint-disable-next-line no-param-reassign
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        // eslint-disable-next-line no-multi-assign, react/no-this-in-sfc
        const height = (this.height += 32);
        return height;
      };
    },
  };
  const currencyFormat = {
    style: 'currency',
    currency: account.preferences?.currency || 'USD',
    maximumFractionDigits: 2,
    notation: 'compact',
  };
  const percentageFormat = {
    style: 'percent',
    maximumFractionDigits: 1,
  };

  // This assumes that the graphData.datasets above are configured with
  // activePlan as the first item in the array
  const titleContent = (context) => {
    const activePlan = context.find((c) => c.datasetIndex === 0);
    if (activePlan) {
      const { dataIndex, raw } = activePlan;
      const revenue = activePlanRevenue[dataIndex];

      const lift = 1 + raw / 100;
      const calculatedRevenue = lift * (revenue / monthGrowths[dataIndex]);

      return `💰 Predicted Revenue: ${calculatedRevenue.toLocaleString(
        navigator.language,
        currencyFormat,
      )}`;
    }
    return '';
  };

  const labelContent = (context) => {
    const { dataset, raw, dataIndex } = context;
    let labelContent;

    if (dataset.label === 'Active Plan' || dataset.label === 'Cogsy Predicted Growth Rate') {
      labelContent = `${(Number(raw) / 100).toLocaleString(navigator.language, percentageFormat)}`;
    }

    if (dataset.label === 'Predicted Revenue') {
      const revenue = activePlanRevenue[dataIndex];
      labelContent = `${revenue.toLocaleString(navigator.language, currencyFormat)}`;
    }

    return `${dataset.label}: ${labelContent}`;
  };

  const options = {
    onHover(e, chartElement) {
      if (
        (chartElement.length === 1 || chartElement.length === 2) &&
        chartElement[0].datasetIndex === 0
      ) {
        e.native.target.style.cursor = 'ns-resize';
      }
      if (chartElement.length === 0) {
        e.native.target.style.cursor = 'default';
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: lowerLimit,
        max: higherLimit,
        title: {
          display: true,
          text: 'Growth Rate',
        },
        ticks: {
          callback(value) {
            return (Number(value) / 100).toLocaleString(undefined, { style: 'percent' });
          },
        },
      },
      revenue: {
        position: 'right',
        display: false,
        grid: {
          display: false,
        },
      },
    },
    scale: {
      ticks: {
        precision: 0,
      },
    },
    elements: {
      point: {
        radius: 6,
        hoverRadius: 10,
      },
    },
    plugins: {
      legend: {
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          pointStyleWidth: 17,
          padding: 24,
        },
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          title: titleContent,
          label: labelContent,
        },
      },
      dragData: {
        round: 1,
        onDragEnd(e, datasetIndex, index, value) {
          onUpdate(planToEdit[index].date, value);
          setTimeout(() => {
            setCurrentRevPlan(null);
          }, 1200);
        },
      },
    },
  };

  return <Line options={options} data={graphData} plugins={[legendMargin]} />;
};

const planType = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string,
    monthGrowth: PropTypes.number,
    value: PropTypes.number,
  }),
);

PlanningGraph.propTypes = {
  planningChartData: PropTypes.shape({
    growthData: planType,
    baseline: planType,
    activePlan: planType,
    metricsOverlay: PropTypes.shape({
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          date: PropTypes.string,
        }),
      ),
      selectedMetric: PropTypes.string,
    }),
    currentMonthSales: PropTypes.number,
  }).isRequired,
  plan: planType.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default PlanningGraph;
