import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';

const VendorForm = ({ data, onSubmit, onCancel }) => {
  const vendor = data.vendor || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const showNameField = !!(
    !vendor._id ||
    (vendor._id &&
      vendor.sources.find(({ isDefault, sourceId }) => isDefault && sourceId === 'cogsy'))
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-8">
        {vendor._id && <input type="hidden" defaultValue={vendor._id} {...register('_id')} />}

        <div className="space-y-8 pt-4 text-sm">
          {showNameField && (
            <div>
              <label htmlFor="vendorName">
                <span className="font-bold">Vendor Name</span>
                <input
                  type="text"
                  id="vendorName"
                  className={classnames('input', errors.name && 'input-error')}
                  defaultValue={vendor.name}
                  {...register('name', {
                    required: 'Please provide a vendor name',
                  })}
                />
              </label>
              <InputErrorMessage message={errors.name?.message} />
            </div>
          )}

          <div>
            <label htmlFor="leadTime">
              <span className="font-bold">Vendor Order Lead Time</span>
              <div className="relative">
                <input
                  type="number"
                  step="1"
                  id="leadTime"
                  className={classnames('input pr-12', errors.leadTime && 'input-error')}
                  defaultValue={vendor.leadTime}
                  {...register('leadTime', {
                    valueAsNumber: true,
                    required: 'Order Lead Time is required',
                    min: {
                      value: 0,
                      message: "Order Lead Time can't be negative",
                    },
                  })}
                />
                <span className="absolute right-4 top-4">days</span>
              </div>
            </label>
            <InputErrorMessage message={errors.leadTime?.message} />
          </div>
          <div>
            <label htmlFor="contactName">
              <span className="font-bold">Contact Name</span>
              <input
                type="text"
                id="contactName"
                placeholder="John Smith"
                className="input"
                defaultValue={vendor.contactName}
                {...register('contactName')}
              />
            </label>
          </div>
          <div>
            <label htmlFor="contactEmail">
              <span className="font-bold">Contact Email</span>
              <input
                type="email"
                id="contactEmail"
                placeholder="john@mystore.com"
                className={classnames('input', errors.contactEmail && 'input-error')}
                defaultValue={vendor.contactEmail}
                {...register('contactEmail', {
                  pattern: {
                    value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                    message: 'Please provide a valid email',
                  },
                })}
              />
            </label>
            <InputErrorMessage message={errors.contactEmail?.message} />
          </div>
          <div>
            <label htmlFor="phoneNumber">
              <span className="font-bold">Contact Phone Number</span>
              <input
                type="tel"
                id="phoneNumber"
                placeholder="+44 20 7946 0933"
                className={classnames('input', errors.phoneNumber && 'input-error')}
                defaultValue={vendor.phoneNumber}
                {...register('phoneNumber')}
              />
            </label>
            <InputErrorMessage message={errors.phoneNumber?.message} />
          </div>
          <div>
            <label htmlFor="address">
              <span className="font-bold">Address</span>
              <textarea
                id="address"
                rows="5"
                className="input"
                defaultValue={vendor.address}
                {...register('address')}
              />
            </label>
          </div>
        </div>
        <div className="mt-12 text-right">
          <Button variant="text" slim label="Cancel" onClick={() => onCancel(false)} />
          <Button type="submit" variant="primary" slim label="Save" />
        </div>
      </div>
    </form>
  );
};

VendorForm.propTypes = {
  data: PropTypes.shape({
    vendor: PropTypes.shape({
      _id: PropTypes.string,
      leadTime: PropTypes.number,
      contactName: PropTypes.string,
      contactEmail: PropTypes.string,
      phoneNumber: PropTypes.string,
      address: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
VendorForm.defaultProps = {
  data: {
    vendor: PropTypes.shape,
  },
};
export default VendorForm;
