import { addDays, addMonths, addWeeks, isSameDay, subWeeks } from 'date-fns';
import { skus, skuTemplate, sourceTemplate } from './fixtures/skus.js';

const config = {
  weeklySalesCount: 10,
  predictionHorizon: 10,
};

/**
 * A wrapper for handling api-like responses, candidate for abstracting into
 * a shared module if we add more demo types in future
 */
const formatDemoApiResponse = (edges) => ({
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
  },
  edges,
});

/**
 * Returns an array of weekly sales summary data for the given sku,
 * formatting the date as needed by the UI and picking out the sales value
 * from the data pattern we assigned to each sku.
 */
const demoWeeklySales = (sku, date) =>
  Array.from(Array(config.weeklySalesCount))
    .map((_, i) => ({
      date: subWeeks(date, i).toISOString().split('T')[0],
      value: skus[sku].dataPattern[i],
    }))
    .reverse();

/**
 * Similar to demoWeeklySales, but fuzzes the data a little to give the
 * weekly page views value some variance against the data pattern.
 */
const demoWeeklyPageViews = (sku, date) =>
  Array.from(Array(config.weeklySalesCount))
    .map((_, i) => ({
      date: subWeeks(date, i).toISOString().split('T')[0],
      value: (i % 5 ? 2 : 8) * skus[sku].dataPattern[i],
    }))
    .reverse();

/**
 * Should be abstracted to it's own generator file if we want to add support for
 * demo POs properly but for now exists to provide some interaction with the
 * forecastedInventoryQuantities graph shown in ProductChart.jsx
 */
const demoPurchaseOrders = (date) => ({
  edges: [
    {
      node: {
        _id: '#',
        purchaseOrderId: 'cogsy-po-1',
        customPurchaseOrderNumber: 'cogsy-po-1',
        customId: 'cogsy-po-1',
        expectedDeliveryDate: addWeeks(date, 2).toISOString().split('T')[0],
        items: [
          {
            _id: '1234a',
            sku: 'demo-sku-1',
            quantity: 2,
          },
          {
            _id: '1234b',
            sku: 'demo-sku-1',
            quantity: 2,
          },
          {
            _id: '1235a',
            sku: 'demo-sku-2',
            quantity: 2,
          },
          {
            _id: '1235b',
            sku: 'demo-sku-2',
            quantity: 2,
          },
          {
            _id: '1236a',
            sku: 'demo-sku-3',
            quantity: 2,
          },
          {
            _id: '1236b',
            sku: 'demo-sku-3',
            quantity: 2,
          },
          {
            _id: '1237',
            sku: 'demo-sku-4',
            quantity: 2,
          },
        ],
      },
    },
  ],
});

/**
 * Our forecast horizon is 60 days by default, roughly 8-9 weeks in the future
 * Using our data pattern as the guiding shape we generate sales per day and deduct them
 * from the initial inventoryQuantity. Incoming fake PO quantities are added.
 */
const demoForecast = (sku, date) => {
  const { inventoryQuantity } = skus[sku].details;
  const purchaseOrders = demoPurchaseOrders(date).edges[0].node.items;

  let runningDemoInventory = inventoryQuantity;

  return Array.from(Array(60)).map((_, i) => {
    const sales = 2 * Math.round(skus[sku].dataPattern[i % 10] / 7);
    runningDemoInventory -= sales;
    if (runningDemoInventory < 1) runningDemoInventory = 0;

    const currentDate = addDays(date, i);
    const { inTransitQuantity } =
      purchaseOrders.find(
        (p) => p.sku === sku && isSameDay(new Date(p.expectedDeliveryDate), currentDate),
      ) || 0;
    if (inTransitQuantity) runningDemoInventory += inTransitQuantity;

    return {
      date: currentDate.toISOString().split('T')[0],
      inventoryQuantity: runningDemoInventory,
      sales,
    };
  });
};

/**
 * This is the ouput usually generated by the heatmap that are using in SkusPlanning.jsx
 * The figures here are pretty random really, we're just using the data pattern to roughly
 * guide an increase in sales against a decreasing inventory quantity.
 */
const demoExpectedMonthlyLevels = (sku, date) => {
  const demoInventoryLevels = {};
  // eslint-disable-next-line array-callback-return
  Array.from(Array(12)).map((_, i) => {
    demoInventoryLevels[addMonths(date, i).toISOString().split('T')[0]] = {
      inventoryQuantity: skus[sku].dataPattern[i % 10] - i * 100,
      expectedSales: i * 100 + skus[sku].dataPattern[i % 10],
      incomingInventory: skus[sku].dataPattern[i % 10],
      lastMonthSales: skus[sku].dataPattern[i % 10],
    };
  });
  return demoInventoryLevels;
};

/**
 * The opposite of demoWeeklySales, we now create data for ProductChart,jsx
 * going into the future according the predictionHorizon
 * Again we use the shape of the dataPattern set to infer a level of intelligent prediction
 */
const demoWeeklySalesPrediction = (sku, date) =>
  Array.from(Array(config.predictionHorizon)).map((_, i) => ({
    date: addWeeks(date, i).toISOString().split('T')[0],
    value: 2 * skus[sku].dataPattern[i % 10],
  }));

/**
 * If the sku has bundle data in the fixture, generate demo data
 * using our existing sales and sales prediction functions, also fetching
 * the parent bundle data where needed
 */
const demoBundleDetails = (sku, date) => {
  if (skus[sku].details.bundlesContainingSku) {
    const parentSku = skus[sku].details.bundlesContainingSku[0].sku;
    return [
      {
        ...skus[sku].details.bundlesContainingSku[0],
        weeklySales: demoWeeklySales(parentSku, date),
        weeklyPrediction: demoWeeklySalesPrediction(parentSku, date),
        stats: { totalQuantitySold: skus[parentSku].stats.totalQuantitySold },
      },
    ];
  }
  return [];
};

const demoLocations = (sku, date) => ({
  edges: [
    {
      node: {
        _id: 'test1',
        name: 'Location 1',
        isShippingEnabled: true,
        inventoryQuantity: skus[sku].details.inventoryQuantity,
        ...skus[sku].stats,
        forecastedInventoryQuantities: demoForecast(sku, date),
        sources: [{ ...sourceTemplate, ...skus[sku].details }],
      },
    },
  ],
});

const demoOOS = (sku, date) => {
  if (!skus[sku].stats?.firstOutOfStockDate) return undefined;
  return {
    firstOutOfStockDate: addDays(date, skus[sku].stats.firstOutOfStockDate)
      .toISOString()
      .split('T')[0],
  };
};

const demoSubscriptions = (sku, date) => {
  if (sku === 'demo-sku-1') {
    return {
      subscriptions: {
        reserveInventoryByDate: Array.from(Array(config.predictionHorizon)).map((_, i) => ({
          date: addWeeks(date, i).toISOString().split('T')[0],
          reserveInventory: 50 + 1 * skus[sku].dataPattern[i % 10] * 0.25,
        })),
      },
    };
  }
  return {};
};

const demoSkuDetails = (i) => `demo-sku-${i}`;

/**
 * The main assembly function that takes our template fixtures and layers on
 * each dynamic function, mostly just handing the sku name and date to each
 * generator in turn
 */
const demoSku = (sku, date) => ({
  ...skuTemplate,
  ...skus[sku].details,
  purchaseOrders: demoPurchaseOrders(date),
  sku,
  isOnBackorder: true,
  inventoryBreakdown: [
    {
      _id: 'test1',
      name: 'Demo Location',
      inventoryQuantity: skus[sku].details.inventoryQuantity,
      forecastedInventoryQuantities: demoForecast(sku, date),
    },
  ],
  stats: {
    ...skuTemplate.stats,
    ...skus[sku].stats,
    ...(demoOOS(sku, date) || {}),
    forecastedInventoryQuantities: demoForecast(sku, date),
    expectedMonthlyInventoryLevels: demoExpectedMonthlyLevels(sku, date),
    locations: demoLocations(sku, date).edges.map(({ node }) => node),
    ...demoSubscriptions(sku, date),
  },
  weeklyPageViews: demoWeeklyPageViews(sku, date),
  weeklyPrediction: demoWeeklySalesPrediction(sku, date),
  weeklySales: demoWeeklySales(sku, date),
  bundlesContainingSku: demoBundleDetails(sku, date) || [],
  sources: [{ ...sourceTemplate, ...skus[sku].details }],
});

export const getDemoSkus = (date = new Date()) =>
  formatDemoApiResponse(
    Array.from(Array(Object.keys(skus).length)).map((_, i) => {
      const sku = demoSkuDetails(i + 1);
      return { node: demoSku(sku, date) };
    }),
  );

export const getDemoSingleSku = (sku, date = new Date()) => ({
  sku: demoSku(sku, date),
  locations: demoLocations(sku, date),
});

export const getDemoCategories = () =>
  formatDemoApiResponse([
    {
      node: {
        name: 'Uncategorized',
      },
    },
  ]);
