import React from 'react';
import PropTypes from 'prop-types';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { differenceInCalendarMonths } from 'date-fns';
import { Bar } from 'react-chartjs-2';
import { useAccount } from '../AccountProvider.jsx';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TrackingGraph = ({ trackingChartData }) => {
  const { account } = useAccount();
  const currencyOptions = {
    style: 'currency',
    currency: account.preferences?.currency || 'USD',
    notation: 'compact',
    maximumFractionDigits: 2,
  };

  const metrics = {
    avgOrderValue: {
      name: 'Average Order Value',
      unit: account.preferences.currency || 'USD',
    },
    conversionRate: {
      name: 'Conversion Rate',
      unit: '%',
    },
    abandonedCartRate: {
      name: 'Abandoned Cart Rate',
      unit: '%',
    },
  };

  // Note: we don't want to convert to local time or the months get skewed, as we use the
  // start of the month (e.g. 01/06/2022 00:00:00) to record monthly values
  const dateFormatOptions = { month: 'short', year: '2-digit', timeZone: 'UTC' };
  const growthLabels = trackingChartData.growthData.map((item) =>
    new Date(item.date).toLocaleDateString(navigator.language, dateFormatOptions).split(' '),
  );
  const baselineLabels = trackingChartData.baseline.map((item) =>
    new Date(item.date).toLocaleDateString(navigator.language, dateFormatOptions).split(' '),
  );
  const activePlanLabels = trackingChartData?.activePlan?.map((item) =>
    new Date(item.date).toLocaleDateString(navigator.language, dateFormatOptions).split(' '),
  );
  const actualRevenue = trackingChartData.growthData.map((item) => item.value);
  const baselineRevenue = trackingChartData.baseline.map((item) => item.value);
  const activePlanValues = trackingChartData?.activePlan?.map((item) => item.value) || [];
  const metricsValues = trackingChartData?.metricsOverlay?.metrics?.map((item) => item.value);

  const firstDate = trackingChartData.growthData?.length
    ? new Date(trackingChartData.growthData[0].date)
    : new Date();

  // Replacing differenceInCalendarMonths as the offset needs to be the length of the
  // preceding growth labels regardless
  const offsetMonths = growthLabels.length;

  const labels = [...growthLabels];

  const datasets = [];

  const scales = {
    x: {
      grid: {
        display: false,
      },
      stacked: true,
      ticks: {
        color: [...Array(12).fill(['grey']), ...Array(12).fill('black')],
        font: {
          weight: 'bold',
        },
      },
    },
    actualGrowthRate: {
      type: 'linear',
      position: 'left',
      grace: '10%',
      beginAtZero: true,
      title: {
        display: true,
        text: 'Revenue',
        color: 'black',
        font: {
          weight: 'bold',
        },
        padding: 7,
      },
      ticks: {
        callback(value) {
          return value.toLocaleString(undefined, currencyOptions);
        },
      },
    },
  };

  if (metricsValues?.length) {
    const offsetMonthsMetrics = differenceInCalendarMonths(
      new Date(trackingChartData.metricsOverlay.metrics[0].date),
      firstDate,
    );
    const selectedMetric = trackingChartData.metricsOverlay.selectedMetric;

    datasets.push({
      type: 'line',
      label: metrics[selectedMetric].name,
      data: [...Array(offsetMonthsMetrics), ...metricsValues],
      borderColor: 'rgb(253, 230, 138, 1)',
      backgroundColor: 'rgb(253, 230, 138)',
      yAxisID: 'yAccountMetrics',
    });

    scales.yAccountMetrics = {
      type: 'linear',
      position: 'right',
      beginAtZero: true,
      title: {
        display: true,
        text: metrics[selectedMetric].name,
      },
      grid: {
        display: false,
      },
      ticks: {
        callback(value) {
          if (metrics[selectedMetric].unit === '%')
            return (Number(value) / 100).toLocaleString(undefined, { style: 'percent' });
          return (value ?? 0).toLocaleString(navigator.language, currencyOptions);
        },
      },
    };
  }

  datasets.push({
    label: 'Actual Revenue',
    data: actualRevenue,
    borderColor: 'rgb(91, 205, 179)',
    backgroundColor: 'rgb(91, 205, 179)',
  });

  if (trackingChartData.currentMonthSales) {
    const lastMonthGrowth = trackingChartData.growthData?.length
      ? trackingChartData.growthData[trackingChartData.growthData.length - 1]
      : undefined;

    const offset = lastMonthGrowth
      ? differenceInCalendarMonths(new Date(lastMonthGrowth.date), firstDate) + 1
      : differenceInCalendarMonths(new Date(), firstDate);

    datasets.push({
      label: 'MTD Revenue',
      data: [...Array(offset), trackingChartData.currentMonthSales],
      borderColor: 'rgb(168, 201, 187)',
      backgroundColor: 'rgb(168, 201, 187)',
      categoryPercentage: 0.6,
    });
  }

  if (activePlanValues?.length) {
    labels.push(...activePlanLabels);
    datasets.push({
      label: 'Predicted Revenue',
      data: [...Array(offsetMonths), ...activePlanValues],
      borderColor: 'rgb(157, 112, 255)',
      backgroundColor: 'rgb(157, 112, 255)',
    });
  } else {
    labels.push(...baselineLabels);
    datasets.push({
      label: 'Predicted Revenue',
      data: [...Array(offsetMonths), ...baselineRevenue],
      borderColor: 'rgb(157, 112, 255)',
      backgroundColor: 'rgb(157, 112, 255)',
    });
  }

  const graphData = {
    labels,
    datasets,
  };

  // this controls the margin between the legend and the graph
  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      // eslint-disable-next-line no-param-reassign
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        // eslint-disable-next-line no-multi-assign, react/no-this-in-sfc
        const height = (this.height += 32);
        return height;
      };
    },
  };

  const options = {
    scale: {
      ticks: {
        precision: 0,
      },
    },
    scales,
    datasets: {
      bar: {
        borderRadius: 3,
      },
    },
    plugins: {
      legend: {
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          pointStyleWidth: 17,
          padding: 24,
        },
      },
      tooltip: {
        filter: (context) => {
          const { dataset, dataIndex } = context;
          if (dataset.label === 'MTD Revenue') {
            return dataIndex === dataset.data.length - 1;
          }
          return true;
        },
        callbacks: {
          title: (context) => {
            const { label } = context[0];
            return label.replaceAll(',', ' ');
          },
          label: (context) => {
            const { dataset, raw } = context;

            if (dataset.label === 'Conversion Rate' || dataset.label === 'Abandoned Cart Rate') {
              return `${dataset.label}: ${(Number(raw) / 100).toLocaleString(undefined, {
                style: 'percent',
                maximumFractionDigits: 2,
              })}`;
            }
            return `${dataset.label}: ${raw.toLocaleString(navigator.language, currencyOptions)}`;
          },
        },
      },
    },
  };

  return <Bar options={options} data={graphData} plugins={[legendMargin]} />;
};

const planType = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string,
    monthGrowth: PropTypes.number,
    value: PropTypes.number,
  }),
);

TrackingGraph.propTypes = {
  trackingChartData: PropTypes.shape({
    growthData: planType,
    baseline: planType,
    activePlan: planType,
    metricsOverlay: PropTypes.shape({
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          date: PropTypes.string,
        }),
      ),
      selectedMetric: PropTypes.string,
    }),
    currentMonthSales: PropTypes.number,
  }).isRequired,
};

export default TrackingGraph;
