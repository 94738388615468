import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FiAlertCircle } from 'react-icons/fi';
import { Dialog, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Button from '../../Components/Button.jsx';
import RadioButton from '../../Components/RadioButton.jsx';
import { SkeletonText } from '../../Components/Skeletons.jsx';
import omitDeep from '../../Utils/omitDeep.js';
import { SKU_QUERY } from '../Queries.js';

const TransferLineItem = ({ item, itemIndex }) => {
  const { loading, data } = useQuery(SKU_QUERY, { variables: { sku: item.sku } });
  const {
    register,
    formState: { errors },
  } = useFormContext();

  if (loading) {
    return (
      <div className="grid grid-cols-2">
        <SkeletonText />
        <SkeletonText />
      </div>
    );
  }

  return (
    <div className="mb-5 grid grid-cols-2">
      <div>
        <div className="truncate font-bold uppercase" title={data.sku.sku}>
          {data.sku.sku}
        </div>
        <div className="truncate" title={data.sku.productName}>
          {data.sku.productName}
        </div>
        <div className="truncate text-gray-75" title={data.sku.variantName}>
          {data.sku.variantName}
        </div>
      </div>
      <div className="self-center pr-9 text-right">
        <input
          className={classnames(
            'w-18 rounded border border-midnight-10 px-2 py-1 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100',
            errors.items?.[itemIndex]?.quantity && 'input-error',
          )}
          id="quantity"
          required
          type="number"
          step="1"
          min="0"
          max={item.quantity}
          placeholder={item.quantity}
          {...register(`items.${itemIndex}.receivedQuantity`, {
            required: true,
            valueAsNumber: true,
            min: {
              message: "Quantity can't be negative.",
              value: 0,
            },
            max: {
              message: "Received quantity can't exceed ordered quantity.",
              value: `${item.quantity}`,
            },
          })}
        />
      </div>
    </div>
  );
};

TransferLineItem.propTypes = {
  item: PropTypes.shape({
    sku: PropTypes.string,
    quantity: PropTypes.number,
  }).isRequired,
  itemIndex: PropTypes.number.isRequired,
};

const MarkAsReceivedPopup = ({ onClose, transfer, location, source, onSubmit }) => {
  const [isReceivedCompletely, setIsReceivedCompletely] = useState(true);
  const [syncInventoryToSource, setSyncInventoryToSource] = useState(
    transfer.inventorySourceToUpdate?.updateInventory ?? false,
  );
  const [isInventoryChoicePermanent, setIsInventoryChoicePermanent] = useState(false);

  const isShopifySyncEnabled = useFeature('transfers-shopify-sync').on;

  const today = new Date().toISOString().split('T')[0];

  const form = useForm({
    defaultValues: {
      items: transfer.items.map((item) =>
        Object.assign(omitDeep(item, ['__typename']), { receivedQuantity: item.quantity }),
      ),
      deliveredAt: today,
      _id: transfer._id,
    },
  });

  return (
    <Dialog
      open
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-sm rounded bg-white shadow">
          <div className="z-10 m-0 mx-auto w-full rounded bg-purple-10 p-5">
            <Dialog.Title className="ml-3 text-lg font-bold text-purple-100">
              Mark as received
            </Dialog.Title>
          </div>
          {!transfer.inventorySourceToUpdate && (
            <div className="flex pb-2 pl-8 pr-4 pt-4 text-xs">
              <FiAlertCircle size={20} className="mr-2 shrink-0 text-yellow-100" />
              This will only update quantities within Cogsy. Please update your inventory data
              source with the new stock levels.
            </div>
          )}
          <FormProvider {...form}>
            <form
              className="mt-4 text-xs"
              onSubmit={form.handleSubmit((data) =>
                onSubmit({ ...data, syncInventoryToSource, isInventoryChoicePermanent }),
              )}
            >
              <div className="pl-8">
                <label htmlFor="deliveredAt">
                  <div className="mb-3 text-sm font-bold">Shipment received on</div>
                  <div className="w-36">
                    <input
                      type="date"
                      required
                      id="deliveredAt"
                      className={classnames(
                        'input py-2 text-sm',
                        form.formState.errors.deliveredAt && 'input-error',
                      )}
                      max={today}
                      {...form.register(`deliveredAt`, {
                        required: 'Please provide the date this shipment was received',
                      })}
                    />
                  </div>
                </label>
                {isShopifySyncEnabled &&
                  source &&
                  transfer.inventorySourceToUpdate?.updateInventory === null && (
                    <>
                      <div className="mt-5 pr-8">
                        <div className="mb-3 text-sm font-bold">
                          Do you want to increment the on-hand quantities at{' '}
                          <span className="italic">{location.name}</span> on Shopify?
                        </div>
                        <RadioGroup
                          onChange={setSyncInventoryToSource}
                          value={syncInventoryToSource}
                          className="flex items-center space-x-7 text-sm"
                        >
                          {[
                            ['Yes', true],
                            ['No', false],
                          ].map((item) => (
                            <RadioGroup.Option value={item[1]} key={item[0]}>
                              {({ checked }) => (
                                <div className="flex items-center space-x-2">
                                  <RadioButton isChecked={checked} />
                                  <span>{item[0]}</span>
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </RadioGroup>
                      </div>
                      <div className="mt-3">
                        <label
                          className="cursor-pointer text-sm"
                          htmlFor="isInventoryChoicePermanent"
                        >
                          <input
                            type="checkbox"
                            id="isInventoryChoicePermanent"
                            checked={isInventoryChoicePermanent}
                            className="mb-1 mr-2 cursor-pointer rounded border-gray-75 text-purple-100"
                            onChange={() =>
                              setIsInventoryChoicePermanent(
                                (isInventoryChoicePermanent) => !isInventoryChoicePermanent,
                              )
                            }
                          />
                          Don&apos;t ask me again
                        </label>
                      </div>
                    </>
                  )}
                <div className="mt-7">
                  <div className="mb-3 text-sm font-bold">
                    Have you received 100% of this shipment?
                  </div>
                  <RadioGroup
                    onChange={setIsReceivedCompletely}
                    value={isReceivedCompletely}
                    className="flex items-center space-x-7 text-sm"
                  >
                    {[
                      ['Yes', true],
                      ['No', false],
                    ].map((item) => (
                      <RadioGroup.Option value={item[1]} key={item[0]}>
                        {({ checked }) => (
                          <div className="flex items-center space-x-2">
                            <RadioButton isChecked={checked} />
                            <span>{item[0]}</span>
                          </div>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </RadioGroup>
                </div>
                {!isReceivedCompletely && (
                  <div className="mt-7">
                    <div className="mb-4 grid grid-cols-2 font-bold">
                      <div>Products</div>
                      <div>Number of units received</div>
                    </div>
                    <div className=" max-h-[280px] overflow-y-auto">
                      {transfer.items.map((item, itemIndex) => (
                        <TransferLineItem key={item.sku} item={item} itemIndex={itemIndex} />
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="my-4 mb-8 mr-8 mt-10 flex items-center justify-end space-x-2">
                <Button label="Cancel" variant="text" onClick={onClose} slim />
                <Button type="submit" label="Mark as received" slim />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </Dialog>
  );
};

MarkAsReceivedPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  transfer: PropTypes.shape({
    _id: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ).isRequired,
    inventorySourceToUpdate: PropTypes.shape({
      sourceType: PropTypes.string,
      sourceId: PropTypes.string,
      hasRequiredPermission: PropTypes.bool,
      updateInventory: PropTypes.bool,
    }),
    toLocation: PropTypes.shape({
      _id: PropTypes.string,
      sources: PropTypes.arrayOf(
        PropTypes.shape({
          sourceId: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }),
  source: PropTypes.shape({
    sourceId: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

MarkAsReceivedPopup.defaultProps = {
  location: null,
  source: null,
};

export default MarkAsReceivedPopup;
