import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../Components/Button.jsx';
import Spinner from '../Components/Spinner.jsx';

const CONNECT_WITH_SHIPBOB = gql`
  mutation connectWithShipbob($code: String!, $state: String!, $scope: String!) {
    connectWithShipbob(code: $code, state: $state, scope: $scope) {
      id
      name
      application_name
    }
  }
`;

const SELECT_CHANNEL = gql`
  mutation selectChannel($channelId: String!) {
    selectShipbobChannel(channelId: $channelId) {
      _id
    }
  }
`;

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Shipbob returns the data as a fragment, hence we use location.hash
  const query = new URLSearchParams(location.hash.slice(1));

  const [channelId, setChannelId] = React.useState(null);

  const [channels, setChannels] = React.useState(null);

  const [connectWithShipbob] = useMutation(CONNECT_WITH_SHIPBOB);
  const [selectChannel, { loading: selectChannelLoading }] = useMutation(SELECT_CHANNEL);

  const handleChannelChange = React.useCallback((e) => {
    setChannelId(e.target.value);
  }, []);

  let isFirstRender = true;

  useEffect(() => {
    if (!isFirstRender) return;

    const doConnect = async () => {
      const { data } = await connectWithShipbob({
        variables: {
          code: query.get('code'),
          state: query.get('state'),
          scope: query.get('scope'),
        },
      });

      setChannels(data.connectWithShipbob);
      setChannelId(data.connectWithShipbob[0].id);
    };

    doConnect();

    isFirstRender = false;
  }, []);

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();

    const doSubmit = async () => {
      await selectChannel({ variables: { channelId } });
      navigate('/settings/sources');
    };

    doSubmit();
  }, [channelId]);

  if (!channels || !channelId) return <Spinner />;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-4">
        <label htmlFor="account">
          Select the Channel you want to connect to

          <select
            id="account"
            className="input max-w-[10rem] pr-8"
            value={channelId}
            onChange={handleChannelChange}
          >
            {channels.map((channel) => (
              <option key={channel.id} value={channel.id}>
                {channel.name || channel.application_name}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div className="mt-4">
        <Button
          type="submit"
          label={selectChannelLoading ? 'Please wait...' : 'Continue'}
          slim
          disabled={!channelId || selectChannelLoading}
        />
      </div>
    </form>
  );
};

export default Callback;
