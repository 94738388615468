import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useSearchParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { FiExternalLink, FiInfo } from 'react-icons/fi';
import { Transition } from '@headlessui/react';
import {
  arrow,
  FloatingArrow,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import Button from '../Components/Button.jsx';
import DeleteButton from '../Components/DeleteButton.jsx';
import Panel from '../Dashboard/Widgets/Widget.jsx';
import SkuSelector from '../PurchaseOrders/SkuSelector.jsx';

const UPDATE_RECIPE = gql`
  mutation UpdateRecipe($sku: String!, $items: [RecipeItemInput!]!) {
    updateRecipe(sku: $sku, items: $items) {
      _id
    }
  }
`;

const Recipe = ({ sku, recipeItems = [], onUpdate = () => {} }) => {
  const [show, setShow] = useState(recipeItems.length > 0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [search, setSearchParams] = useSearchParams();
  const formRef = useRef(null);

  const arrowRef = useRef(null);
  const { x, y, strategy, refs, context } = useFloating({
    open: showTooltip,
    onOpenChange: (isOpen) => {
      setShowTooltip(isOpen);
      if (!isOpen) {
        setSearchParams({});
      }
    },
    placement: 'right',
    middleware: [
      arrow({
        element: arrowRef,
      }),
      offset(10),
    ],
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    if (search.has('createRecipe')) {
      setShow(true);
      setShowTooltip(true);
      setSearchParams({});
    }
  }, [search]);

  useEffect(() => {
    if (show && showTooltip) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [show, search]);

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      items: recipeItems,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'items',
    control,
  });

  const [saveRecipe, { loading }] = useMutation(UPDATE_RECIPE, {
    variables: { sku, items: [] },
    onCompleted: () => {
      onUpdate();
      reset({}, { keepValues: true });
    },
  });

  const saveForm = ({ items }) => {
    saveRecipe({
      variables: { items },
    });
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <div
        className="col-span-4 h-auto self-start"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Panel barColor="purple" className="h-auto w-full">
          <Panel.Title>Recipe</Panel.Title>
          <Panel.Content>
            <form onSubmit={handleSubmit(saveForm)} ref={formRef}>
              <table className="w-full text-xs">
                <thead>
                  <tr>
                    <th className="pr-1 text-left">SKU</th>
                    <th className="px-1 text-right">Units</th>
                    <th className="pl-1">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((item, index) => (
                    <tr key={item.id}>
                      <td className="py-1 pr-1 text-left">
                        <Link to={`/skus/${item.sku}`} target="_blank">
                          <div className="flex items-center overflow-hidden rounded bg-leafy-10 p-[3px]">
                            <div className="min-w-0 flex-1 truncate py-px" title={item.sku}>
                              <span className="ml-1">{item.sku}</span>
                            </div>
                            <div className="rounded-r p-1">
                              <FiExternalLink className="text-gray-100" />
                            </div>
                          </div>
                        </Link>
                        <input
                          className="input !mt-0 p-1 text-xs"
                          type="hidden"
                          readOnly
                          {...register(`items.${index}.sku`, {
                            required: true,
                          })}
                        />
                      </td>
                      <td className="w-20 p-1 text-right">
                        <input
                          type="number"
                          className="input !mt-0 w-full !rounded p-1 pl-3 text-xs"
                          {...register(`items.${index}.quantity`, {
                            required: true,
                            min: 1,
                            valueAsNumber: true,
                          })}
                          min={1}
                        />
                      </td>
                      <td className="w-6 py-1 pl-1">
                        <DeleteButton onDelete={() => remove(index)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-4">
                <SkuSelector
                  inputClassNames="!px-2 !py-1"
                  items={[...fields, { sku }]}
                  onItemsAdd={(newItems) =>
                    newItems.forEach(([newSku]) => append({ sku: newSku, quantity: 1 }))
                  }
                  locations={[]}
                />
              </div>
              <Transition
                show={isDirty}
                enter="transition-opacity duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="mt-4 flex items-center justify-end space-x-2">
                  <Button
                    variant="text"
                    className="!px-3"
                    onClick={() => reset({ items: recipeItems })}
                    label="Cancel"
                    slim
                  />
                  <Button type="submit" label="Save" slim className="!px-3" disabled={loading} />
                </div>
              </Transition>
            </form>
          </Panel.Content>
        </Panel>
      </div>
      <Transition
        show={showTooltip}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute flex flex-1 items-center justify-between space-x-2 rounded-lg border-2 border-purple-100 bg-white px-4 py-3 text-xs font-bold"
          ref={refs.setFloating}
          style={{ position: strategy, left: x, top: y }}
          {...getFloatingProps()}
        >
          <FiInfo className="text-purple-75" size={16} />
          <p>Here you can define all components for this product</p>
          <FloatingArrow
            ref={arrowRef}
            context={context}
            className="
          fill-purple-100 
          [&>path:first-of-type]:stroke-purple-100
          [&>path:last-of-type]:stroke-purple-100
        "
          />
        </div>
      </Transition>
    </>
  );
};

Recipe.propTypes = {
  sku: PropTypes.string.isRequired,
  recipeItems: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Recipe;
