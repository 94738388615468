import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FiChevronDown } from 'react-icons/fi';
import { Listbox, Transition } from '@headlessui/react';
import classnames from 'classnames';
import { STATUSES } from '../../shared/purchaseOrders/index.js';
import { hasSupportFor } from '../../shared/sources.js';
import { useAccount } from '../AccountProvider.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { useDocumentTitle, useQueryParams } from '../Hooks/index.js';
import CreateDraftPopupButton from './CreateDraftPopup.jsx';
import POTable from './POTable.jsx';
import { VENDORS } from './Queries.js';
import SyncPurchaseOrdersButton from './Sync.jsx';

const FilterSelect = ({ value, onChange, options, defaultOption }) => (
  <Listbox value={value} onChange={onChange}>
    {({ open }) => (
      <div className="relative w-[140px]">
        <Listbox.Button className="flex h-full w-full items-center rounded-full border-0 bg-transparent py-1 pr-3 text-sm font-bold">
          <span className="truncate">
            {options.find(({ value: optionValue }) => optionValue === value)?.name || defaultOption}
          </span>
          <span>
            <FiChevronDown
              className={classnames('ml-1 h-5 w-5 text-purple-100', open && '-rotate-180')}
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-[21.2rem] w-[170px] overflow-y-auto rounded-lg border border-midnight-10 bg-white text-xs shadow-md">
            <Listbox.Option
              className={({ active }) =>
                classnames(
                  `px-3 py-1.5 font-medium first-of-type:pt-2.5 last-of-type:pb-2.5`,
                  active && 'bg-purple-100 text-white',
                )
              }
              value=""
              key="default"
            >
              <span className={classnames('block truncate', !value && 'font-bold')}>
                {defaultOption}
              </span>
            </Listbox.Option>
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                className={({ active }) =>
                  classnames(
                    `px-3 py-1.5 font-medium first-of-type:pt-2.5 last-of-type:pb-2.5`,
                    active && 'bg-purple-100 text-white',
                  )
                }
                value={String(option.value)}
              >
                {({ selected }) => (
                  <span className={classnames('block truncate', selected && 'font-bold')}>
                    {option.name}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
);

FilterSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string.isRequired,
};

FilterSelect.defaultProps = {
  value: '',
};

const List = () => {
  useDocumentTitle('Purchase Orders');
  const {
    account,
    user,
    locations: { edges: allLocations },
  } = useAccount();

  const [queryParams, setQueryParams] = useQueryParams([
    'location',
    'status',
    'vendor',
    'after',
    'before',
  ]);

  const { data } = useQuery(VENDORS, {
    fetchPolicy: 'cache-and-network',
  });

  const syncPOsEnabled = account.sources?.some(
    ({ sourceType, syncPurchaseOrders }) =>
      hasSupportFor(sourceType, 'purchaseOrders') && syncPurchaseOrders,
  );

  const resetQueryParams = () => ({
    ...queryParams,
    after: undefined,
    before: undefined,
  });

  const handleChangeLocation = (location) => {
    setQueryParams({
      ...resetQueryParams(),
      location: location || undefined,
    });
  };

  const handleChangeStatus = (status) => {
    setQueryParams({
      ...resetQueryParams(),
      status: status || undefined,
    });
  };

  const handleChangeVendor = (vendor) => {
    setQueryParams({
      ...resetQueryParams(),
      vendor: vendor || undefined,
    });
  };

  return (
    <>
      <div className="-mt-12 bg-purple-5 pl-9 pr-6 pt-12">
        <PageHeader text="Purchase Orders" />
        <div className="mt-16 flex justify-between pb-4">
          <div className="flex">
            <FilterSelect
              onChange={handleChangeStatus}
              value={queryParams.status}
              options={Object.entries(STATUSES).map(([key, value]) => ({
                name: value,
                value: key,
              }))}
              defaultOption="All Status"
            />
            <FilterSelect
              onChange={handleChangeLocation}
              value={queryParams.location}
              options={allLocations
                .filter(({ node: location }) => !location.hideReplenishment)
                .map(({ node: { _id, name } }) => ({
                  name,
                  value: String(_id),
                }))}
              defaultOption="All Locations"
            />
            {data?.skuVendors && (
              <FilterSelect
                onChange={handleChangeVendor}
                value={queryParams.vendor}
                options={data.skuVendors.map(({ vendorId, vendorName }) => ({
                  name: vendorName,
                  value: vendorId,
                }))}
                defaultOption="All Vendors"
              />
            )}
          </div>
          <div className="flex">
            {account.preferences.forecastEnabled !== false && !user.isReadOnly && (
              <CreateDraftPopupButton location={queryParams.location} />
            )}
            {account.sources?.length && !user.isReadOnly && syncPOsEnabled && (
              <div className="ml-2">
                <SyncPurchaseOrdersButton />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-x-6 space-y-8 bg-white pt-8">
        <POTable
          location={queryParams.location}
          statuses={queryParams.status ? [queryParams.status] : undefined}
          vendor={queryParams.vendor}
          onPagination={(after, before) => {
            setQueryParams({
              ...queryParams,
              after,
              before,
            });
          }}
          pageAfter={queryParams.after}
          pageBefore={queryParams.before}
        />
      </div>
    </>
  );
};

export default List;
