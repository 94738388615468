const marketplaces = [
  {
    name: 'Australia',
    id: 'A39IBJ37TRP1C6',
    sellerCentralUrl: 'https://sellercentral.amazon.com.au',
    region: 'fe',
    website: 'Amazon.com.au',
  },
  {
    name: 'Belgium',
    id: 'AMEN7PMS3EDWL',
    sellerCentralUrl: 'https://sellercentral.amazon.com.be',
    region: 'eu',
    website: 'Amazon.com.be',
  },
  {
    name: 'Brazil',
    id: 'A2Q3Y263D00KWC',
    sellerCentralUrl: 'https://sellercentral.amazon.com.br',
    region: 'na',
    website: 'Amazon.com.br',
  },
  {
    name: 'Canada',
    id: 'A2EUQ1WTGCTBG2',
    sellerCentralUrl: 'https://sellercentral.amazon.ca',
    region: 'na',
    website: 'Amazon.ca',
  },
  {
    name: 'Egypt',
    id: 'ARBP9OOSHTCHU',
    sellerCentralUrl: 'https://sellercentral.amazon.eg',
    region: 'eu',
    website: 'Amazon.eg',
  },
  {
    name: 'France',
    id: 'A13V1IB3VIYZZH',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    region: 'eu',
    website: 'Amazon.fr',
  },
  {
    name: 'Germany',
    id: 'A1PA6795UKMFR9',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    region: 'eu',
    website: 'Amazon.de',
  },
  {
    name: 'India',
    id: 'A21TJRUUN4KGV',
    sellerCentralUrl: 'https://sellercentral.amazon.in',
    region: 'eu',
    website: 'Amazon.in',
  },
  {
    name: 'Italy',
    id: 'APJ6JRA9NG5V4',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    region: 'eu',
    website: 'Amazon.it',
  },
  {
    name: 'Japan',
    id: 'A1VC38T7YXB528',
    sellerCentralUrl: 'https://sellercentral.amazon.co.jp',
    region: 'fe',
    website: 'Amazon.co.jp',
  },
  {
    name: 'Mexico',
    id: 'A1AM78C64UM0Y8',
    sellerCentralUrl: 'https://sellercentral.amazon.com.mx',
    region: 'na',
    website: 'Amazon.com.mx',
  },
  {
    name: 'Netherlands',
    id: 'A1805IZSGTT6HS',
    sellerCentralUrl: 'https://sellercentral.amazon.nl',
    region: 'eu',
    website: 'Amazon.nl',
  },
  {
    name: 'Poland',
    id: 'A1C3SOZRARQ6R3',
    sellerCentralUrl: 'https://sellercentral.amazon.pl',
    region: 'eu',
    website: 'Amazon.pl',
  },
  {
    name: 'Saudi Arabia',
    id: 'A17E79C6D8DWNP',
    sellerCentralUrl: 'https://sellercentral.amazon.com.sa',
    region: 'eu',
    website: 'Amazon.sa',
  },
  {
    name: 'Singapore',
    id: 'A19VAU5U5O7RUS',
    sellerCentralUrl: 'https://sellercentral.amazon.sg',
    region: 'fe',
    website: 'Amazon.sg',
  },
  {
    name: 'Spain',
    id: 'A1RKKUPIHCS9HS',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    region: 'eu',
    website: 'Amazon.es',
  },
  {
    name: 'Sweden',
    id: 'A2NODRKZP88ZB9',
    sellerCentralUrl: 'https://sellercentral.amazon.se',
    region: 'eu',
    website: 'Amazon.se',
  },
  {
    name: 'Turkey',
    id: 'A33AVAJ2PDY3EV',
    sellerCentralUrl: 'https://sellercentral.amazon.com.tr',
    region: 'eu',
    website: 'Amazon.com.tr',
  },
  {
    name: 'United Arab Emirates',
    id: 'A2VIGQ35RCS4UG',
    sellerCentralUrl: 'https://sellercentral.amazon.ae',
    region: 'eu',
    website: 'Amazon.ae',
  },
  {
    name: 'United Kingdom',
    id: 'A1F83G8C2ARO7P',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    region: 'eu',
    website: 'Amazon.co.uk',
  },
  {
    name: 'United States',
    id: 'ATVPDKIKX0DER',
    sellerCentralUrl: 'https://sellercentral.amazon.com',
    region: 'na',
    website: 'Amazon.com',
  },
];

export default marketplaces;
