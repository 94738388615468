import { gql } from '@apollo/client';

export const GROWTH_MODEL_QUERY = gql`
  query GetGrowthPlan {
    growthModel {
      _id
      baseline {
        value
        date
      }
      activePlan {
        value
        date
      }
    }
  }
`;

export const SALES_QUERY = gql`
  query MonthToDateSales($from: DateTime!, $to: DateTime!) {
    account {
      _id
      totalSales(from: $from, to: $to)
    }
  }
`;

export const UNMERGE_SKUS = gql`
  mutation unMergeSku($skuFrom: String!) {
    unMergeSku(skuFrom: $skuFrom) {
      skuMerges {
        skuFrom
        skuTo
      }
    }
  }
`;

export const UPDATE_SKUS = gql`
  mutation SkusBulkUpdate($skus: [String]!, $input: SkuBulkInput!) {
    skusBulkUpdate(skus: $skus, input: $input) {
      sku
    }
  }
`;

export const REMOVE_VENDOR_SKU = gql`
  mutation RemoveVendor($sku: String!, $vendorId: String!) {
    removeVendor(sku: $sku, vendorId: $vendorId) {
      _id
    }
  }
`;

export const SKUS_WITH_ERRORS = gql`
  query GetSkus($first: Int, $isWithSyncErrors: Boolean) {
    skus(first: $first, isWithSyncErrors: $isWithSyncErrors) {
      edges {
        node {
          sku
        }
      }
    }
  }
`;

export const SKUS = gql`
  query GetSkus(
    $after: String
    $before: String
    $category: SkuFilterCategories
    $location: String
    $first: Int
    $search: String
    $sortKey: SkuSortKeys
    $reverse: Boolean
    $productType: String
    $isArchived: Boolean
    $isWithSyncErrors: Boolean
  ) {
    skus(
      after: $after
      before: $before
      category: $category
      location: $location
      first: $first
      search: $search
      sortKey: $sortKey
      reverse: $reverse
      productType: $productType
      isArchived: $isArchived
      isWithSyncErrors: $isWithSyncErrors
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          _id
          sku
          productName
          variantName
          price
          vendor {
            _id
          }
          vendorName
          inventoryQuantity
          isBundle
          isWithoutSku
          type
          weeklySales {
            date
            value
          }
          hasAtRiskSubscriptions
          stats {
            category
            daysLeft
            targetInventoryQuantity
            targetBundleInventoryQuantity
            velocity
            incomingInventoryQuantity
            inProductionInventoryQuantity
            firstOutOfStockDate
            replenishmentStatusUpdatedAt
            subscriptions {
              aggregateBundleReserveInventory
              aggregateReserveInventory
              bundleReserveInventoryByDate {
                date
                reserveInventory
              }
              reserveInventoryByDate {
                date
                reserveInventory
              }
              reserveInventoryHorizon
            }
            expectedMonthlyInventoryLevels
            monthToDateSales {
              quantity
              revenue
              bundlesQuantity
            }
          }
          inventoryBreakdown(onlyActive: true) {
            _id
            name
            inventoryQuantity
            daysLeft
            firstOutOfStockDate
            incomingInventoryQuantity
            inventoryQuantity
            targetInventoryQuantity
          }
          isArchived
          updatedAt
        }
      }
    }
  }
`;

export const SKUS_SETTINGS = gql`
  query GetSkus(
    $after: String
    $before: String
    $first: Int
    $search: String
    $isArchived: Boolean
    $isWithSyncErrors: Boolean
  ) {
    skus(
      after: $after
      before: $before
      first: $first
      search: $search
      isArchived: $isArchived
      isWithSyncErrors: $isWithSyncErrors
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          _id
          sku
          productName
          variantName
          vendor {
            _id
          }
          vendorName
          isBundle
          isWithoutSku
          sources {
            sourceType
          }
          isArchived
          isWithoutSku
          updatedAt
          type
        }
      }
    }
  }
`;

export const SKU_CATEGORIES = gql`
  query GetSkuCategories($after: String, $before: String, $first: Int, $reverse: Boolean) {
    skuCategories(after: $after, before: $before, first: $first, reverse: $reverse) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          name
          stats {
            expectedMonthlyInventoryLevels
            monthToDateSales {
              quantity
              revenue
              bundlesQuantity
            }
          }
        }
      }
    }
  }
`;

export const SKU = gql`
  query GetSku($sku: String!) {
    sku(sku: $sku) {
      _id
      sku
      price
      cost
      productName
      sourceType
      type
      productNameVariantId
      variantName
      leadTime
      canEditLeadTime
      hasAtRiskSubscriptions
      vendor {
        leadTime
        _id
        name
      }
      sources {
        sourceType
        sourceId
        sourceUrl
        productName
        variantId
      }
      weeklyPageViews {
        date
        value
      }
      weeklySales {
        date
        value
      }
      weeklyPrediction {
        date
        value
      }
      ltv
      inventoryQuantity
      isBundle
      bundledSkus {
        sku
        bundledQuantity
      }
      bundlesContainingSku {
        sku
        bundledSkus {
          sku
          bundledQuantity
        }
        weeklySales {
          date
          value
        }
        weeklyPrediction {
          date
          value
        }
        stats {
          totalQuantitySold
        }
      }
      stats {
        averageOrderValue
        category
        daysLeft
        targetInventoryQuantity
        targetBundleInventoryQuantity
        incomingInventoryQuantity
        inProductionInventoryQuantity
        totalQuantitySold
        totalSkuSales
        velocity
        firstOutOfStockDate
        replenishmentStatus
        forecastedInventoryQuantities {
          date
          inventoryQuantity
          sales
        }
        subscriptions {
          aggregateBundleReserveInventory
          aggregateReserveInventory
          bundleReserveInventoryByDate {
            date
            reserveInventory
          }
          reserveInventoryByDate {
            date
            reserveInventory
          }
          reserveInventoryHorizon
        }
      }
      inventoryBreakdown(onlyActive: true) {
        _id
        name
        sourceCount
        inventoryQuantity
        targetInventoryQuantity
        targetBundleInventoryQuantity
        incomingInventoryQuantity
        inProductionInventoryQuantity
        daysLeft
        hideReplenishment
        forecastedInventoryQuantities {
          date
          inventoryQuantity
          sales
        }
      }
      purchaseOrders(
        first: 50
        sortKey: expectedDeliveryDate
        excludeStatuses: [DRAFT, PRE_PRODUCTION, DELIVERED]
      ) {
        edges {
          node {
            _id
            purchaseOrderId
            customId: customPurchaseOrderNumber
            expectedDeliveryDate
            items {
              sku
              quantity
            }
            location {
              name
            }
          }
        }
      }
      transfers(first: 50, isInTransit: true, onlyShippable: true, sortKey: expectedDeliveryDate) {
        edges {
          node {
            sourceId
            transferId
            customId
            expectedDeliveryDate
            items {
              sku
              quantity
            }
            toLocation {
              _id
              name
            }
            purchaseOrder
          }
        }
      }
      vendors {
        name
        _id
      }
      isArchived
      isOnBackorder
      isWithoutSku
      trailLogs {
        _id
        logType
        logContext
        createdAt
        user {
          email
          fullName
        }
      }
      finishedProducts {
        sku
        quantity
      }
    }
  }
`;
