import React from 'react';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import ForgotPassword from './Auth/ForgotPassword.jsx';
import Invitation from './Auth/Invitation.jsx';
import Login from './Auth/Login.jsx';
import Register from './Auth/Register.jsx';
import ResetPassword from './Auth/ResetPassword.jsx';
import LoadingPage from './Components/LoadingPage.jsx';
import { apiUrl, sentry as sentryConfig } from './config.js';
import Customerio from './Customerio.js';
import Hotjar from './Hotjar.js';
import './index.css';
import ErrorFallback from './Layout/ErrorFallback.jsx';
import PromptWrapper from './PurchaseOrders/Prompt.jsx';
import Segment from './Segment.js';
import ShopifyCallback from './Shopify/Callback.jsx';
import SkubanaAuth from './Skubana/Auth.jsx';

axios.defaults.baseURL = apiUrl;

const Logout = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.localStorage.removeItem('token');

    navigate('/');
  }, []);

  return <LoadingPage />;
};

Sentry.init({
  dsn: sentryConfig.dsn,
  integrations: [
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: [sentryConfig.tracingOrigin || 'localhost'],
    }),
  ],
  tracesSampleRate: sentryConfig.tracingSampleRate,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} resetError={resetError} />
        )}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/invitations/:invitationCode" element={<Invitation />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/skubana/callback" element={<SkubanaAuth />} />
          <Route path="/shopify/callback" element={<ShopifyCallback />} />
          <Route path="/prompts/:promptId" element={<PromptWrapper />} />
          <Route path="/*" element={<App />} />
        </Routes>
      </Sentry.ErrorBoundary>
    </Router>
  </React.StrictMode>,
);

if (import.meta.hot) {
  import.meta.hot.accept();
}

if (import.meta.env.PROD) {
  Hotjar.init();
  Segment.init();
  Customerio.init();
}
