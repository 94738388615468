import React from 'react';
import PropTypes from 'prop-types';
import { FiTrash } from 'react-icons/fi';

const DeleteButton = ({ onDelete }) => (
  <button
    type="button"
    onClick={onDelete}
    className="group inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full hover:bg-midnight-10 focus:bg-midnight-75 focus:outline-none active:bg-midnight-75"
  >
    <FiTrash className="stroke-gray-100 group-focus:stroke-gray-10 group-active:stroke-gray-10" />
  </button>
);

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default DeleteButton;
