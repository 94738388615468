import React from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import { Dialog } from '@headlessui/react';

const VideoPopup = ({ videoUrl, open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    className="fixed inset-0 z-40 overflow-y-auto text-midnight-100"
  >
    <div className="flex min-h-screen items-center justify-center">
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

      <div className="relative mx-auto w-full max-w-3xl rounded bg-gray-10 p-12 shadow">
        <button
          type="button"
          onClick={onClose}
          className="group absolute right-3 top-3 flex h-6 w-6 items-center justify-center rounded-full hover:bg-midnight-10 active:bg-midnight-75"
        >
          <FiX className="stroke-gray-100 group-active:stroke-gray-10" />
        </button>
        <iframe
          className="aspect-video w-full shadow-lg"
          src={videoUrl}
          title="Youtube video player"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </div>
  </Dialog>
);

VideoPopup.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VideoPopup;
