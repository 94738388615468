import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import GrowthPlanning from './GrowthPlanning.jsx';
import MarketingEvents from './MarketingEvents/Index.jsx';
import PlannedProducts from './PlannedProducts/Index.jsx';
import PlannedProduct from './PlannedProducts/PlannedProduct.jsx';

const Index = () => (
  <Routes>
    <Route path="growth" element={<GrowthPlanning />} />
    <Route path="marketing-events" element={<MarketingEvents />} />
    <Route path="marketing-events/new" element={<MarketingEvents isPopupOpen />} />
    <Route path="planned-products" element={<PlannedProducts />} />
    <Route path="planned-products/new" element={<PlannedProduct />} />
    <Route path="planned-products/:_id" element={<PlannedProduct />} />
    <Route path="*" element={<Navigate replace to="/planning/marketing-events" />} />
  </Routes>
);
export default Index;
