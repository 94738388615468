import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { FiChevronRight } from 'react-icons/fi';
import { useFeature } from '@growthbook/growthbook-react';
import { format, isFuture } from 'date-fns';
import { getTrialEndDate } from '../../shared/billing/trial.js';
import { marketingEventTypes } from '../../shared/marketingEventTypes.js';
import { useAccount } from '../AccountProvider.jsx';
import { SkeletonBlock } from '../Components/Skeletons.jsx';
import { trackGoogleAnalyticsEvent } from '../GoogleAnalytics.js';
import { useDocumentTitle } from '../Hooks/index.js';
import Onboarding from './Onboarding.jsx';
import OnboardingChecklists from './OnboardingChecklists.jsx';
import PastGrowthWidget from './Widgets/PastGrowthWidget/Index.jsx';
import PlannedGrowthWidget from './Widgets/PlannedGrowthWidget/Index.jsx';
import ProductUpdatesWidget from './Widgets/ProductUpdatesWidget.jsx';
import PurchaseOrdersWidget from './Widgets/PurchaseOrdersWidget.jsx';
import ReplenishNowWidget from './Widgets/ReplenishNowWidget.jsx';
import ReplenishSoonWidget from './Widgets/ReplenishSoonWidget.jsx';
import StockLevelsWidget from './Widgets/StockLevelsWidget/Index.jsx';
import UpcomingEventsWidget from './Widgets/UpcomingEventsWidget.jsx';

const GET_DASHBOARD_DATA = gql`
  query getDashboardData($types: [MarketingActivityTypes]) {
    growthModel {
      _id
      baseline {
        value
        date
      }
      activePlan {
        value
        date
      }
      heatmapData {
        date
        percentage
      }
      plannedRevenue {
        value
        date
      }
      stats {
        currentMonthPlanTotal
        monthToDate {
          actual
        }
        workingCapital {
          draftPurchaseOrders {
            count
            workingCapital
          }
        }
      }
      pastPredictions {
        actualRevenue
        forecastedRevenue
        dateFrom
        dateTo
      }
      baselineUpdateLog {
        isReviewNeeded
      }
    }
    replenishmentStats {
      replenishNow {
        count
        workingCapital
      }
      replenishSoon {
        count
        workingCapital
      }
    }
    purchaseOrders(first: 100, statuses: [DRAFT]) {
      edges {
        node {
          expectedDeliveryDate
        }
      }
    }
    marketingActivities(first: 3, types: $types) {
      edges {
        node {
          _id
          title
          startsAt
          endsAt
          percentageLift
          types
        }
      }
    }
    plannedSkus(first: 3) {
      edges {
        node {
          _id
          name
          launchDate
        }
      }
    }
  }
`;

const filteredMarketingEventTypes = Object.keys(marketingEventTypes).filter(
  (type) => type !== 'productLaunch',
);

const today = new Date();

const DashboardHeader = ({ user, isReviewNeeded }) => {
  const time = today.getHours();
  let greeting;

  if (time < 12) {
    greeting = 'Good Morning';
  }
  if (time >= 12 && time < 18) {
    greeting = 'Good Afternoon';
  }
  if (time >= 18) {
    greeting = 'Good Evening';
  }

  return (
    <header>
      <div className="font-semibold uppercase text-midnight-75">
        {format(new Date(), 'cccc, LLLL d')}
      </div>
      <div className="flex justify-between">
        <h2 className="mt-2 text-2xl font-bold text-midnight-100">
          {greeting}, {user.firstName}! 👋
        </h2>
        {isReviewNeeded && (
          <div className="flex items-center">
            <div className="mr-2 h-3 w-3 rounded-full bg-red-75" />
            <p className="text-sm font-bold text-midnight-100">
              A new month has been added to your&nbsp;
              <RouterLink to="/planning/growth" className="text-purple-100">
                Growth Plan
                <FiChevronRight size={20} className="inline" />
              </RouterLink>
            </p>
          </div>
        )}
      </div>
    </header>
  );
};

DashboardHeader.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
  isReviewNeeded: PropTypes.bool.isRequired,
};

const DashboardOnboarding = () => (
  <OnboardingChecklists>
    {(pf) => (
      <div>
        {!pf.productFruitsHaveLoaded &&
          (pf.showFirstChecklist || pf.showSecondChecklist || pf.showThirdChecklist) && (
            <div className="mt-8 bg-purple-10 p-8">
              <div className="rounded-lg bg-white p-7 shadow">
                <SkeletonBlock className="h-[364px]" />
              </div>
            </div>
          )}

        {pf.productFruitsHaveLoaded &&
          (pf.showFirstChecklist || pf.showSecondChecklist || pf.showThirdChecklist) && (
            <div className="mt-8 bg-purple-10 p-8">
              <header className="text-midnight-100">
                <h2 className="mt-2 text-xl font-bold">Yay! Your data has synced!</h2>
                <p className="mt-4">We just need few more details to get all our cogs going.</p>

                <div className="mt-8 flex space-x-8">
                  {pf.showFirstChecklist && (
                    <div className="w-1/2 rounded-lg bg-white p-4 shadow">
                      {pf.productFruitsHaveLoaded && <div ref={pf.firstChecklistContainer} />}
                    </div>
                  )}
                  {(pf.showSecondChecklist || pf.showThirdChecklist) && (
                    <div className="w-1/2 rounded-lg bg-white p-4 shadow">
                      {pf.productFruitsHaveLoaded && (
                        <>
                          <div ref={pf.secondChecklistContainer} />
                          <div ref={pf.thirdChecklistContainer} />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </header>
            </div>
          )}
      </div>
    )}
  </OnboardingChecklists>
);

DashboardOnboarding.propTypes = {
  user: PropTypes.shape({
    isAdmin: PropTypes.bool,
  }).isRequired,
};

const Index = () => {
  const { account, user } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();

  useDocumentTitle('Home');

  useEffect(() => {
    if (!searchParams.has('trial_started')) return;

    trackGoogleAnalyticsEvent('trial_started');
    searchParams.delete('trial_started');
    setSearchParams(searchParams);
  }, []);

  const pastPredictionsEnabled = useFeature('past-predictions').on;

  const { data, loading, client } = useQuery(GET_DASHBOARD_DATA, {
    variables: {
      types: filteredMarketingEventTypes,
    },
    onCompleted: (result) => {
      if (!result.growthModel) {
        // when we do not have growth model generated yet, do not cache the result in this case.
        client.cache.evict({ fieldName: 'growthModel' });
        client.cache.gc();
      }
    },
  });
  const hasSources = account.sources?.length > 0;
  const hasSkusSynced = account.onboarding?.hasSkusSyncCompleted;
  const hasOrdersSynced = account.onboarding?.hasOrdersSyncCompleted;
  const hasForecastCompleted = account.onboarding?.hasForecastCompleted;

  const supportsInventoryQuantities = !!account.sources?.find(
    (source) => source.syncInventoryQuantities,
  );

  const isInTrialPeriod = isFuture(new Date(getTrialEndDate(account)));

  const hasPastPredictions = data?.growthModel?.pastPredictions !== null;
  if (!hasSources || !hasSkusSynced || !hasOrdersSynced || !hasForecastCompleted) {
    return (
      <Onboarding
        account={account}
        user={user}
        supportsInventoryQuantities={supportsInventoryQuantities}
      />
    );
  }

  return (
    <>
      <DashboardHeader
        user={user}
        isReviewNeeded={data?.growthModel?.baselineUpdateLog?.isReviewNeeded || false}
      />
      <DashboardOnboarding user={user} />
      <div className="mt-8 grid grid-cols-12 gap-8">
        {isInTrialPeriod && hasPastPredictions && pastPredictionsEnabled && (
          <div className="col-span-4">
            <PastGrowthWidget growthModel={data?.growthModel || {}} loading={loading} />
          </div>
        )}
        <div className="col-span-4">
          <ReplenishNowWidget
            stats={data?.replenishmentStats.replenishNow || {}}
            loading={loading}
          />
        </div>
        <div className="col-span-4">
          <ReplenishSoonWidget
            stats={data?.replenishmentStats.replenishSoon || {}}
            loading={loading}
          />
        </div>
        {(!isInTrialPeriod || !hasPastPredictions || !pastPredictionsEnabled) && (
          <div className="col-span-4">
            <PurchaseOrdersWidget
              draftPurchaseOrders={
                data?.growthModel?.stats.workingCapital.draftPurchaseOrders || {}
              }
              loading={loading}
            />
          </div>
        )}
        <div className="col-span-8">
          <StockLevelsWidget
            draftPurchaseOrders={data || {}}
            heatmapData={data?.growthModel?.heatmapData || []}
            stats={data?.growthModel?.stats || {}}
            loading={loading}
          />
        </div>
        <div className="col-span-4">
          <PlannedGrowthWidget growthModel={data?.growthModel || {}} loading={loading} />
        </div>
        <div className="col-span-8">
          <ProductUpdatesWidget />
        </div>
        <div className="col-span-4">
          <UpcomingEventsWidget
            marketingEvents={data?.marketingActivities.edges || []}
            plannedProducts={data?.plannedSkus.edges || []}
            currentMonthPlanTotal={data?.growthModel?.stats.currentMonthPlanTotal || 0}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
