import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FiChevronDown, FiPlus } from 'react-icons/fi';
import { Dialog, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import { endOfDay, parseISO, startOfDay } from 'date-fns';
import { useController, useForm } from 'react-hook-form';
import { getTrackBackground, Range } from 'react-range';
import { marketingEventTypes } from '../../../shared/marketingEventTypes.js';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';
import { useDemo } from '../../Components/DemoProvider.jsx';
import InputErrorMessage from '../../Components/InputErrorMessage.jsx';
import Spinner from '../../Components/Spinner.jsx';
import { useDebounce } from '../../Hooks/index.js';

const SALES_LIFT_SLIDER = {
  step: 0.1,
  min: 0,
  max: 10,
  default: 0,
};

export const MARKETING_ACTIVITIES = gql`
  query {
    marketingActivities(first: 100) {
      edges {
        cursor
        node {
          _id
          title
          startsAt
          endsAt
          types
          percentageLift
          skus
          appliesToAllSkus
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const CREATE_MARKETING_ACTIVITY = gql`
  mutation createMarketingActivity($marketingActivity: MarketingActivityInput!) {
    createMarketingActivity(marketingActivity: $marketingActivity) {
      _id
    }
  }
`;

const SKUS = gql`
  query GetSkus($search: String) {
    skus(search: $search, sortKey: score, first: 100, reverse: true) {
      edges {
        node {
          _id
          sku
          productName
        }
      }
    }
  }
`;

const EventPopupBody = ({ onClose }) => {
  const skuQueryInput = useRef();

  const { addAlert } = useAlerts();
  const { isDemo } = useDemo();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      salesLift: SALES_LIFT_SLIDER.default,
      appliesToAllSkus: true,
    },
  });

  const [salesLiftValue, appliesToAllSkus, startsAt, endsAt] = watch([
    'salesLift',
    'appliesToAllSkus',
    'startsAt',
    'endsAt',
  ]);

  const [selectedSkusOpen, setSelectedSkusOpen] = useState(false);
  const [skuQuery, setSkuQuery] = useState('');
  const [selectedSkus, setSelectedSkus] = useState([]);

  const [createMarketingActivity, { loading }] = useMutation(CREATE_MARKETING_ACTIVITY, {
    refetchQueries: [MARKETING_ACTIVITIES],
  });

  const [getSkus, { loading: skusLoading, data, previousData }] = useLazyQuery(SKUS, {
    onCompleted: () => skuQueryInput?.current?.focus(),
  });

  const skus = data?.skus?.edges || previousData?.skus?.edges;

  const [debouncedSkuQuery] = useDebounce(skuQuery);

  const { field: appliesToAllSkusField } = useController({
    name: 'appliesToAllSkus',
    control,
    rules: {
      validate: (appliesToAllSkus) =>
        appliesToAllSkus || selectedSkus.length > 0 || 'Please select at least one product',
    },
  });

  useEffect(() => {
    if (skuQuery) {
      getSkus({ variables: { search: debouncedSkuQuery } });
    }
  }, [debouncedSkuQuery]);

  useEffect(() => {
    if (selectedSkus.length > 0) {
      trigger('appliesToAllSkus');
    } else {
      setSelectedSkusOpen(false);
    }
  }, [selectedSkus]);

  useEffect(() => {
    if (!selectedSkusOpen) skuQueryInput?.current?.focus();
  }, [selectedSkusOpen]);

  const selectedSkusSkus = selectedSkus.map(({ sku }) => sku);

  const handleSkusSelectAllChange = (e) => {
    if (!skus.length) return;

    // We're selecting all skus
    if (e.target.checked) {
      const skusToAdd = skus
        .filter(({ node: sku }) => !selectedSkusSkus.includes(sku.sku))
        .map(({ node }) => node);

      setSelectedSkus([...selectedSkus, ...skusToAdd]);
    } else {
      // We need to remove all sku results from selected skus
      const skuResults = skus.map(({ node }) => node.sku);

      setSelectedSkus(selectedSkus.filter(({ sku }) => !skuResults.includes(sku)));
    }
  };

  const handleRemoveSku = (skuToRemove) => {
    setSelectedSkus(selectedSkus.filter(({ sku }) => sku !== skuToRemove));
  };

  const handleSkuSelectedChange = (sku) => {
    if (selectedSkusSkus.includes(sku.sku)) handleRemoveSku(sku.sku);
    else setSelectedSkus([...selectedSkus, sku]);
  };

  const handleClearAllSkus = () => {
    setSelectedSkus([]);
  };

  const onSubmit = async ({ title, startsAt, endsAt, eventType, salesLift }) => {
    await createMarketingActivity({
      variables: {
        marketingActivity: {
          title,
          startsAt: startOfDay(parseISO(startsAt)).toISOString(),
          endsAt: endOfDay(parseISO(endsAt)).toISOString(),
          types: [eventType],
          percentageLift: Math.round(salesLift * 100),
          skus: selectedSkus.map((sku) => sku.sku),
          appliesToAllSkus,
        },
      },
    });
    addAlert('Event added successfully', { level: 'success' });

    onClose();
  };

  // filter the events that are meant to be created automatically and are read only at frontend
  const eventTypes = Object.keys(marketingEventTypes)
    .filter((type) => marketingEventTypes[type].isReadOnly === false)
    .reduce((arr, key) => [...arr, [key, marketingEventTypes[key].value]], []);

  return (
    <div className="relative z-10 overflow-hidden rounded bg-gray-10 p-7 shadow">
      <form className="w-[496px] text-sm" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="event_title">
          <span className="font-bold">Event Title</span>
          <input
            type="text"
            id="event_title"
            placeholder="Your event title"
            className={classnames(
              'input mt-2 py-3 text-sm placeholder-gray-75',
              errors.title && 'input-error',
            )}
            {...register('title', {
              required: 'Please provide the event title',
            })}
          />
          <InputErrorMessage message={errors.title?.message} />
        </label>

        <div className="mt-7 flex items-start space-x-6">
          <label htmlFor="starts_at" className="flex space-x-3">
            <span className="mt-2 font-bold">From</span>
            <div className="w-44">
              <input
                type="date"
                id="starts_at"
                min={new Date().toISOString().slice(0, 10)}
                max={endsAt}
                className={classnames('input mt-0 py-2 text-sm', errors.startsAt && 'input-error')}
                {...register('startsAt', {
                  required: 'Please provide the start date',
                  min: {
                    value: new Date().toISOString().slice(0, 10),
                    message: "Start date can't be earlier than today",
                  },
                  max: {
                    value: endsAt,
                    message: "Start date can't be later than the end date",
                  },
                })}
              />
              <InputErrorMessage message={errors.startsAt?.message} />
            </div>
          </label>

          <label htmlFor="ends_at" className="flex space-x-3">
            <span className="mt-2 font-bold">To</span>
            <div className="w-44">
              <input
                type="date"
                id="ends_at"
                min={startsAt || new Date().toISOString().slice(0, 10)}
                className={classnames('input mt-0 py-2 text-sm', errors.endsAt && 'input-error')}
                {...register('endsAt', {
                  required: 'Please provide the end date',
                  min: {
                    value: startsAt || new Date().toISOString().slice(0, 10),
                    message: startsAt
                      ? "End date can't be earlier than the start date"
                      : "End date can't be earlier than today",
                  },
                })}
              />
              <InputErrorMessage message={errors.endsAt?.message} />
            </div>
          </label>
        </div>

        <label htmlFor="event_type" className="mt-7 flex space-x-3">
          <span className="mt-2 font-bold">Event Type</span>
          <div className="flex flex-col items-start">
            <select
              id="event_type"
              className={classnames(
                'input mt-0 w-60 py-2 text-sm',
                errors.eventType && 'input-error',
              )}
              {...register('eventType', {
                required: 'Please provide the event type',
              })}
            >
              <option value="">Select event type</option>
              {eventTypes.map((eventType) => (
                <option key={eventType[0]} value={eventType[0]}>
                  {eventType[1]}
                </option>
              ))}
            </select>
            <InputErrorMessage message={errors.eventType?.message} />
          </div>
        </label>

        <label className="mt-7 inline-flex items-center" htmlFor="sales_lift">
          Product Sales Lift
          <input
            type="number"
            id="sales_lift"
            step={SALES_LIFT_SLIDER.step}
            min={SALES_LIFT_SLIDER.min}
            max={SALES_LIFT_SLIDER.max}
            className="input ml-3 mt-0 h-8 w-16 pr-3"
            {...register('salesLift')}
          />
        </label>

        <div className="mt-1 flex flex-col">
          <Range
            values={[salesLiftValue]}
            step={SALES_LIFT_SLIDER.step}
            min={SALES_LIFT_SLIDER.min}
            max={SALES_LIFT_SLIDER.max}
            onChange={([value]) => setValue('salesLift', value, { shouldDirty: true })}
            renderTrack={({ props: { ref }, children }) => (
              <div className="h-10 pb-4 pt-11">
                <div
                  ref={ref}
                  style={{
                    background: getTrackBackground({
                      values: [salesLiftValue],
                      colors: ['#7a4dff', '#7a4dff1a'],
                      min: SALES_LIFT_SLIDER.min,
                      max: SALES_LIFT_SLIDER.max,
                    }),
                  }}
                  className="h-1.5 rounded shadow-sm"
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                onKeyDown={null}
                onKeyUp={null}
                className="flex h-5 w-5 justify-center rounded-full bg-purple-100"
              >
                <div className="absolute -top-9 flex w-11 justify-center rounded bg-purple-100 pb-1 pt-1.5 text-xs text-white shadow">
                  <div className="absolute -bottom-4 left-1/2 -translate-x-1/2 border-8 border-t-[12px] border-transparent border-t-purple-100" />
                  {Number(salesLiftValue).toFixed(1)}x
                </div>
              </div>
            )}
          />

          <div className="mt-1 flex justify-between font-bold text-gray-100">
            <span>{SALES_LIFT_SLIDER.min}x</span>
            <span>{SALES_LIFT_SLIDER.max}x</span>
          </div>
        </div>

        <div className="mb-4 mt-7 flex flex-col">
          Products Impacted
          <div className="mt-4">
            <RadioGroup
              className="flex space-x-10 font-normal"
              onChange={appliesToAllSkusField.onChange}
              value={appliesToAllSkusField.value}
            >
              {[
                ['All products', true],
                ['Selected products', false],
              ].map((item) => (
                <RadioGroup.Option value={item[1]} key={item[0]}>
                  {({ checked }) => (
                    <div className="flex cursor-pointer items-center">
                      <div
                        className={classnames(
                          checked
                            ? 'mr-3 h-2 w-2 rounded-full bg-purple-100 ring-2 ring-purple-100 ring-offset-2 ring-offset-gray-10'
                            : 'mr-2 h-4 w-4 rounded-full border border-gray-75 bg-white',
                        )}
                      />
                      <span>{item[0]}</span>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
        </div>

        {!appliesToAllSkus && (
          <>
            {selectedSkus.length > 0 && (
              <div>
                <button
                  type="button"
                  onClick={() => setSelectedSkusOpen((prevState) => !prevState)}
                  className="mb-4 flex items-center rounded font-bold text-purple-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100"
                >
                  Selected products ({selectedSkus.length})
                  <FiChevronDown
                    strokeWidth={1.5}
                    className={`ml-1 h-6 w-6 origin-center ${selectedSkusOpen && 'rotate-180'}`}
                  />
                </button>

                <div hidden={!selectedSkusOpen}>
                  <button
                    type="button"
                    className="-ml-1 mb-2 inline-flex items-center rounded-full bg-leafy-10 pb-0.5 pl-1 pr-3 pt-1 text-xs font-bold hover:bg-leafy-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100"
                    onClick={handleClearAllSkus}
                  >
                    <FiPlus strokeWidth={2.5} className="h-4 w-4 rotate-45 pb-0.5" />
                    Clear All
                  </button>

                  <div className={`relative ${selectedSkus.length > 3 ? 'mb-6 mt-2' : 'mb-3'}`}>
                    <ul className="-ml-1 max-h-[146px] space-y-0.5 overflow-y-auto pb-2.5 pl-1 pt-1.5">
                      {selectedSkus.map((sku) => (
                        <li key={sku._id} className="flex h-9 items-center space-x-2 pr-4">
                          <span className={classnames('w-1/4 truncate', isDemo && 'blur-sm')}>
                            {sku.sku}
                          </span>

                          <span
                            className={classnames(
                              'w-3/4 truncate font-normal',
                              isDemo && 'blur-sm',
                            )}
                          >
                            {sku.productName}
                          </span>

                          <button
                            type="button"
                            className="group flex h-6 w-6 items-center justify-center rounded-full hover:bg-midnight-10 active:bg-midnight-75"
                            onClick={() => handleRemoveSku(sku.sku)}
                          >
                            <FiPlus
                              strokeWidth={2.5}
                              className="h-4 w-4 rotate-45 group-active:text-gray-10"
                            />
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="absolute -left-3 top-0 h-2 w-[calc(100%+0.75rem)] bg-gradient-to-b from-gray-10 to-transparent" />
                    <div className="absolute -left-3 bottom-0 h-3 w-[calc(100%+0.75rem)] bg-gradient-to-b from-transparent to-gray-10" />
                  </div>
                </div>
              </div>
            )}

            <label htmlFor="skus_search">
              <div className="relative mb-6">
                <span className="sr-only">Search for specific products</span>
                <input
                  type="text"
                  id="skus_search"
                  ref={skuQueryInput}
                  placeholder="Search for specific products"
                  disabled={skusLoading}
                  value={skuQuery}
                  onChange={(e) => setSkuQuery(e.target.value)}
                  onFocus={() => setSelectedSkusOpen(false)}
                  className="input mt-0 w-full py-3 pr-12 text-sm placeholder-gray-75"
                />
                <InputErrorMessage message={errors.appliesToAllSkus?.message} />

                {skusLoading && skuQuery && (
                  <div
                    style={{ borderTopColor: 'transparent' }}
                    className="absolute right-4 top-3.5 h-4 w-4 animate-spin rounded-full border-2 border-solid border-purple-100"
                  />
                )}
              </div>
            </label>

            {skus?.length > 0 && !selectedSkusOpen && debouncedSkuQuery && (
              <>
                <div className="mb-2 flex items-center justify-between">
                  <label
                    htmlFor="select-all"
                    className="inline-flex h-8 items-center whitespace-nowrap font-normal"
                  >
                    <input
                      type="checkbox"
                      id="select-all"
                      checked={skus.every(({ node: sku }) => selectedSkusSkus.includes(sku.sku))}
                      onChange={handleSkusSelectAllChange}
                      className="mr-2 rounded border-gray-75 text-purple-100 focus:ring-purple-100"
                    />
                    Select All
                  </label>
                </div>

                <div className={classnames('relative', skus.length > 3 ? 'mb-5' : 'mb-3')}>
                  <ul className="-ml-3 max-h-[146px] space-y-0.5 overflow-y-auto pb-2.5 pl-3 pt-1.5">
                    {skus.map(({ node: sku }) => (
                      <li key={sku._id}>
                        <label
                          htmlFor={sku._id}
                          className={classnames(
                            'relative -ml-3 flex h-9 items-center space-x-2 px-3',
                            selectedSkusSkus.includes(sku.sku) && 'bg-leafy-10',
                          )}
                        >
                          <input
                            type="checkbox"
                            id={sku._id}
                            checked={selectedSkusSkus.includes(sku.sku)}
                            onChange={() => handleSkuSelectedChange(sku)}
                            className="rounded border-gray-75 text-purple-100 focus:ring-purple-100"
                          />

                          <span className={classnames('w-1/4 truncate', isDemo && 'blur-sm')}>
                            {sku.sku}
                          </span>

                          <span
                            className={classnames(
                              'w-3/4 truncate font-normal',
                              isDemo && 'blur-sm',
                            )}
                          >
                            {sku.productName}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <div className="absolute -left-3 top-0 h-2 w-[calc(100%+0.75rem)] bg-gradient-to-b from-gray-10 to-transparent" />
                  <div className="absolute -left-3 bottom-0 h-3 w-[calc(100%+0.75rem)] bg-gradient-to-b from-transparent to-gray-10" />
                </div>
              </>
            )}
          </>
        )}

        <div className="flex items-center justify-end space-x-2">
          <Button label="Cancel" variant="text" onClick={onClose} slim />
          <Button type="submit" label="Save" slim />
        </div>
      </form>

      {loading && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white opacity-60">
          <Spinner />
        </div>
      )}
    </div>
  );
};

EventPopupBody.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const EventPopup = ({ open }) => {
  const navigate = useNavigate();

  const handleClose = () => navigate('/planning/marketing-events');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="fixed inset-0 z-10 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        {open && <EventPopupBody onClose={handleClose} />}
      </div>
    </Dialog>
  );
};

EventPopup.propTypes = {
  open: PropTypes.bool.isRequired,
};

const AddEventPopupButton = ({ isPopupInitiallyOpen }) => {
  const [popupOpen, setPopupOpen] = React.useState(false);

  useEffect(() => {
    setPopupOpen(isPopupInitiallyOpen);
  }, [isPopupInitiallyOpen]);

  return (
    <>
      <Button href="/planning/marketing-events/new" slim icon={FiPlus} label="Add Event" />
      <EventPopup open={popupOpen} />
    </>
  );
};

AddEventPopupButton.propTypes = {
  isPopupInitiallyOpen: PropTypes.bool,
};

AddEventPopupButton.defaultProps = {
  isPopupInitiallyOpen: false,
};

export default AddEventPopupButton;
