import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  FiChevronLeft,
  FiChevronRight,
  FiEdit2,
  FiExternalLink,
  FiTrash,
  FiTruck,
  FiX,
} from 'react-icons/fi';
import { HiOutlineSparkles } from 'react-icons/hi';
import classnames from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { STATUSES } from '../../shared/purchaseOrders/index.js';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import confirm from '../Components/ConfirmDialog.jsx';
import DeleteButton from '../Components/DeleteButton.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import { SkeletonBox } from '../Components/Skeletons.jsx';
import SkuDetails from '../Components/SkuDetails.jsx';
import Spinner from '../Components/Spinner.jsx';
import TimeAgo from '../Components/TimeAgo.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import { MapPinIcon } from '../Icons/index.js';
import NotFound from '../Layout/NotFound.jsx';
import { StatusListBox } from '../PurchaseOrders/Form.jsx';
import POMilestone from '../PurchaseOrders/POMilestone.jsx';
import { SKU_QUERY } from '../PurchaseOrders/Queries.js';
import SkuSelector from '../PurchaseOrders/SkuSelector.jsx';
import MarkAsReceivedPopup from '../PurchaseOrders/Transfers/MarkAsReceivedPopup.jsx';
import OnHandTarget from '../Skus/OnHandTarget.jsx';
import { getSkuPagePath } from '../Skus/utils/helpers.js';
import {
  clearTransfersCache,
  MARK_AS_DELIVERED,
  MARK_AS_SHIPPED,
  REMOVE_TRANSFER,
  TRANSFER_QUERY,
  UPDATE_SOURCE,
  UPDATE_TRANSFER,
} from './Queries.js';
import UpdateSourcePopup from './UpdateSourcePopup.jsx';

const SkeletonRow = () => (
  <tr className="border-t border-midnight-5">
    <td className="w-1/4 py-2 pl-6 pr-3 text-left align-top">
      <SkuDetails.Skeleton />
    </td>
    <td className="text-center">
      <SkeletonBox className="inline-block" />
    </td>
    <td>&nbsp;</td>
    <td className="text-center">
      <SkeletonBox className="inline-block" />
    </td>
    <td className="text-right">
      <SkeletonBox className="inline-block" />
    </td>
    <td className="text-right">
      <SkeletonBox className="inline-block" />
    </td>
    <td className="text-right">
      <SkeletonBox className="inline-block" />
    </td>
    <td className="pr-8 text-right">
      <SkeletonBox className="inline-block" />
    </td>
  </tr>
);

const TransferItem = ({
  item,
  itemIndex,
  fromLocation,
  toLocation,
  formItemUpdate,
  onItemRemove,
  highlight,
  isReadOnly,
  isDelivered,
}) => {
  const pathname = useLocation();
  const { loading, data } = useQuery(SKU_QUERY, { variables: { sku: item.sku } });
  const sku = data?.sku;
  const variantId = item.variantId;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { formatNumber } = useAccount();

  const fromLocationStats = sku?.inventoryBreakdown.find((l) => l._id === fromLocation._id) ?? {
    inventoryQuantity: 0,
    targetInventoryQuantity: 1,
    sourceCount: 0,
  };

  const toLocationStats = sku?.inventoryBreakdown.find((l) => l._id === toLocation._id) ?? {
    inventoryQuantity: 0,
    targetInventoryQuantity: 1,
    sourceCount: 0,
  };

  const hasDuplicates = fromLocationStats.sourceCount > 1 && !item.variantId;

  const handleVariantSelect = (selectedVariant) => {
    formItemUpdate(itemIndex, { ...item, variantId: selectedVariant });
  };

  if (loading) return <SkeletonRow />;

  return (
    <tr
      className={classnames(
        'border-t border-midnight-5',
        highlight && 'border-l-2 border-l-gray-75 bg-gray-10',
      )}
    >
      <td className="w-1/4 py-2 pl-8">
        {hasDuplicates ? (
          <SkuDetails
            sku={sku}
            variantId={variantId}
            hasDuplicates={hasDuplicates}
            handleVariantSelect={handleVariantSelect}
          />
        ) : (
          <Link to={getSkuPagePath(sku)} state={{ fromPath: pathname }}>
            <SkuDetails sku={sku} variantId={variantId} handleVariantSelect={handleVariantSelect} />
          </Link>
        )}
      </td>
      <td>
        <div className="mx-auto max-w-[130px] text-center">
          <OnHandTarget
            quantity={fromLocationStats.inventoryQuantity}
            target={fromLocationStats.targetInventoryQuantity}
            sourceCount={fromLocationStats.sourceCount}
          />
        </div>
      </td>
      <td>&nbsp;</td>
      <td>
        <div className="mx-auto max-w-[130px] text-center">
          <OnHandTarget
            quantity={toLocationStats.inventoryQuantity}
            target={toLocationStats.targetInventoryQuantity}
            sourceCount={toLocationStats.sourceCount}
          />
        </div>
      </td>
      {!isReadOnly && (
        <td className="text-right font-medium">{toLocationStats.incomingInventoryQuantity ?? 0}</td>
      )}

      <td className="text-right font-medium text-purple-100">
        {Number.isInteger(toLocationStats.targetMissingInventoryQuantity)
          ? formatNumber(Math.max(toLocationStats.targetMissingInventoryQuantity, 0))
          : '-'}
      </td>
      <td className="font-medium">
        <div className="relative flex flex-col items-end justify-end">
          <input
            type="number"
            step="1"
            min="0"
            max={fromLocationStats.inventoryQuantity}
            required
            className={classnames(
              'input mt-0 w-20 px-3 py-1',
              errors.items?.[itemIndex]?.quantity && 'input-error',
              isReadOnly && 'hidden',
            )}
            {...register(`items.${itemIndex}.quantity`, {
              valueAsNumber: true,
              required: 'Units are required',
              min: {
                message: 'Units cannot be negative',
                value: 0,
              },
              max: {
                message: 'Units cannot exceed on hand quantity',
                value: `${fromLocationStats.inventoryQuantity}`,
              },
            })}
          />
          {isReadOnly && <span className="mt-0 w-20 py-1 text-right">{item.quantity}</span>}
          <div className="absolute top-6 w-56 text-right">
            <InputErrorMessage message={errors.items?.[itemIndex]?.quantity?.message} />
          </div>
        </div>
      </td>
      {isDelivered && <td className="text-right">{item.receivedQuantity}</td>}
      <td className="pr-8 text-right">
        {isReadOnly ? null : <DeleteButton onDelete={() => onItemRemove(itemIndex)} />}
      </td>
    </tr>
  );
};

TransferItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    sku: PropTypes.string,
    variantId: PropTypes.string,
    quantity: PropTypes.number,
    receivedQuantity: PropTypes.number,
  }).isRequired,
  itemIndex: PropTypes.number.isRequired,
  fromLocation: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  toLocation: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onItemRemove: PropTypes.func.isRequired,
  formItemUpdate: PropTypes.func,
  highlight: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isDelivered: PropTypes.bool,
};

TransferItem.defaultProps = {
  highlight: false,
  isReadOnly: false,
  isDelivered: false,
  formItemUpdate: undefined,
};

const TransferForm = ({ transfer }) => {
  const [editIdMode, setEditIdMode] = useState(false);
  const [receivedTransferPopupOpen, setReceivedTransferPopupOpen] = useState(false);
  const [markAsShippedPopupOpen, setMarkAsShippedPopupOpen] = React.useState(false);
  const [deleteTransferPopupOpen, setDeleteTransferPopupOpen] = React.useState(false);

  const navigate = useNavigate();
  const { addAlert } = useAlerts();

  const isShopifySyncEnabled = useFeature('transfers-shopify-sync').on;

  const [removeTransfer] = useMutation(REMOVE_TRANSFER, {
    variables: { transferId: transfer._id },
    update: clearTransfersCache,
    onCompleted: () => navigate('/transfers'),
  });

  const formDefaultValues = {
    customId: transfer.customId,
    issuedAt: transfer.issuedAt,
    createdAt: transfer.createdAt,
    expectedDeliveryDate: transfer.expectedDeliveryDate,
    deliveredAt: transfer.deliveredAt,
    wroCode: transfer.wroCode,
    wroTextUrl: transfer.wroTextUrl,
    items: [...transfer.items],
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  const { register, handleSubmit, formState, reset, control, getValues, setValue, watch } = methods;

  const { fields, append, remove, update } = useFieldArray({ name: 'items', control });
  const currentItems = getValues('items');

  const [updateTransfer] = useMutation(UPDATE_TRANSFER, {
    variables: { transferId: transfer._id },
  });

  const [markAsShipped] = useMutation(MARK_AS_SHIPPED, {
    variables: { transferId: transfer._id },
  });

  const [markAsDelivered] = useMutation(MARK_AS_DELIVERED, {
    variables: { transferId: transfer._id },
  });

  const [updateSource] = useMutation(UPDATE_SOURCE);

  const customIdInput = watch('customId');
  const wroTextUrlInput = watch('wroTextUrl');
  const isUrl = wroTextUrlInput?.startsWith('https://') || wroTextUrlInput?.startsWith('http://');

  const onItemAdd = useCallback(
    (additionalSkus) => {
      const doAdd = async () => {
        additionalSkus.forEach(([sku, qty, options]) => {
          append({ sku, quantity: Math.max(1, qty), ...(options ? { options } : {}) });
        });
      };

      doAdd();
    },
    [append],
  );

  const onItemRemove = useCallback(
    (itemIndex) => {
      remove(itemIndex);
    },
    [remove],
  );

  const handleReset = () => {
    reset(formDefaultValues);
    setEditIdMode(false);
  };

  const onSubmit = async (input) => {
    await updateTransfer({
      variables: {
        input: {
          ...input,
          items: input.items.map((item) => ({
            sku: item.sku,
            variantId: item.variantId,
            quantity: item.quantity,
            receivedQuantity: item.receivedQuantity,
          })),
        },
      },
      onCompleted: () => {
        reset({}, { keepValues: true });
      },
    });
    setEditIdMode(false);
  };

  const updateSourcePreference = async (syncInventoryToSource) => {
    await updateSource({
      variables: {
        input: { updateInventory: !!syncInventoryToSource },
        sourceId: transfer.inventorySourceToUpdate?.sourceId,
      },
    });
  };

  const onRemove = async (input = {}) => {
    const {
      syncInventoryToSource = transfer.inventorySourceToUpdate?.updateInventory,
      isInventoryChoicePermanent,
    } = input;

    if (isInventoryChoicePermanent && transfer.inventorySourceToUpdate)
      await updateSourcePreference(syncInventoryToSource);

    await removeTransfer({
      variables: {
        syncInventoryToSource: !!syncInventoryToSource,
        sourceToUpdate: transfer.inventorySourceToUpdate?.sourceId,
      },
      onCompleted: async () => {
        if (syncInventoryToSource) {
          addAlert('Inventory quantity is being updated on Shopify', {
            level: 'success',
          });
        }
        navigate('/transfers');
      },
    });

    setDeleteTransferPopupOpen(false);
  };

  const handleRemove = async () => {
    if (await confirm('Are you sure you want to delete this transfer?')) {
      onRemove();
    }
  };

  const onMarkAsReceived = async (input = {}) => {
    const {
      syncInventoryToSource = transfer.inventorySourceToUpdate?.updateInventory,
      isInventoryChoicePermanent,
    } = input;

    if (isInventoryChoicePermanent && transfer.inventorySourceToUpdate) {
      await updateSource({
        variables: {
          input: { updateInventory: !!syncInventoryToSource },
          sourceId: transfer.inventorySourceToUpdate.sourceId,
        },
      });
    }

    await markAsDelivered({
      variables: {
        input: {
          deliveredAt: input.deliveredAt,
          items: input.items,
        },
        syncInventoryToSource: !!syncInventoryToSource,
        sourceToUpdate: transfer.inventorySourceToUpdate?.sourceId,
      },
      onCompleted: (data) => {
        if (syncInventoryToSource) {
          addAlert('Inventory quantity is being updated on Shopify', {
            level: 'success',
          });
        }
        setValue('deliveredAt', data.markAsDelivered.deliveredAt);
        setValue('items', data.markAsDelivered.items);
        setReceivedTransferPopupOpen(false);
      },
    });
  };

  const onMarkAsShipped = async (input = {}) => {
    const {
      syncInventoryToSource = transfer.inventorySourceToUpdate?.updateInventory,
      isInventoryChoicePermanent,
    } = input;

    if (isInventoryChoicePermanent && transfer.inventorySourceToUpdate)
      await updateSourcePreference(syncInventoryToSource);

    await markAsShipped({
      variables: {
        syncInventoryToSource: !!syncInventoryToSource,
        sourceToUpdate: transfer.inventorySourceToUpdate?.sourceId,
      },
      onCompleted: async (data) => {
        if (syncInventoryToSource) {
          addAlert('Inventory quantity is being updated on Shopify', {
            level: 'success',
          });
        }
        setValue('items', data.markAsShipped.items);
        setValue('issuedAt', data.markAsShipped.issuedAt);
      },
    });

    setMarkAsShippedPopupOpen(false);
  };

  const isDelivered = transfer.status === 'DELIVERED';

  return (
    <div className="-my-12 min-h-screen bg-purple-5 pb-12">
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {formState.isDirty ? (
            <div className="sticky top-0 z-10 mb-6 flex animate-scale items-center bg-midnight-100 py-3 pl-1 pr-6">
              <span className="pl-6 text-xs font-bold text-white">Unsaved changes</span>
              <button
                type="button"
                onClick={handleReset}
                className="ml-auto mr-4 text-xs font-bold text-white"
              >
                Cancel
              </button>
              <Button type="submit" label="Save" slim onClick={() => handleSubmit(onSubmit)} />
            </div>
          ) : (
            <div className="flex items-center justify-between space-x-4 bg-purple-5 pl-1 pr-2 pt-4">
              <Link
                to="/transfers"
                className="group inline-flex items-center rounded-full px-4 py-2 text-sm font-bold leading-none text-midnight-100 hover:bg-purple-10"
              >
                <FiChevronLeft
                  className="mr-1 transition-transform group-hover:-translate-x-1"
                  size={20}
                />
                <span>Transfers</span>
              </Link>

              {!isShopifySyncEnabled ||
              !transfer.inventorySourceToUpdate ||
              transfer.inventorySourceToUpdate.hasRequiredPermission ? null : (
                <div className="flex flex-1 items-center justify-between space-x-8 rounded-lg border-2 border-purple-100 bg-white px-6 py-3 text-xs font-bold">
                  <div className="flex items-center justify-between space-x-0.5 rounded bg-leafy-10 px-2 py-1 text-leafy-100">
                    <HiOutlineSparkles />
                    &nbsp;
                    <span>NEW!</span>
                  </div>
                  <p className="flex-1 text-purple-100">
                    You can now sync inventory updates from your transfers back to Shopify.
                    <br />
                    Reauthorize your integration ({transfer.inventorySourceToUpdate.sourceId}) to
                    have access to this feature.
                  </p>
                  <Button
                    label="Reauthorize"
                    href={transfer.inventorySourceToUpdate.shopifyRedirectUrl.redirectUrl}
                    target="_blank"
                    icon={FiExternalLink}
                    iconSize={12}
                    slim
                  />
                </div>
              )}
              {!transfer.deliveredAt && (
                <button
                  type="button"
                  className="flex items-center rounded-full px-4 py-2 text-sm font-bold leading-none text-midnight-70 hover:bg-purple-10"
                  onClick={
                    transfer.inventorySourceToUpdate?.updateInventory === null &&
                    isShopifySyncEnabled &&
                    transfer.issuedAt
                      ? () => setDeleteTransferPopupOpen(true)
                      : () => handleRemove()
                  }
                >
                  <FiTrash className="mr-1" size={16} />
                  <span>Remove</span>
                </button>
              )}
            </div>
          )}

          <div className="mt-20 px-6 text-midnight-100 first:mt-0">
            <div className="flex items-center space-x-2">
              {editIdMode ? (
                <input
                  type="text"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  placeholder="Custom ID"
                  className={classnames(
                    '-ml-2 appearance-none rounded-lg border-0 bg-purple-10 py-1 pl-2 pr-0 text-3xl placeholder:text-gray-75/50 focus:ring-2 focus:ring-purple-100',
                    formState.errors?.customId && 'ring-2 ring-red-100',
                  )}
                  {...register('customId', {
                    setValueAs: (v) => (!v ? null : v),
                    required: 'Custom ID is required',
                  })}
                />
              ) : (
                <div className="py-1 text-3xl">{customIdInput}</div>
              )}
              {!isDelivered && (
                <button
                  type="button"
                  className={classnames(
                    'flex items-center self-center rounded-full px-4 py-2 text-sm font-bold hover:bg-purple-10',
                    !customIdInput
                      ? 'cursor-not-allowed text-gray-100 hover:bg-gray-50'
                      : 'text-purple-100',
                  )}
                  onClick={() => setEditIdMode((prev) => !prev)}
                  disabled={!customIdInput}
                >
                  {editIdMode ? (
                    <>
                      <FiX size={16} />
                      <span className="ml-1">Close</span>
                    </>
                  ) : (
                    <>
                      <FiEdit2 size={16} />
                      <span className="ml-1">Edit</span>
                    </>
                  )}
                </button>
              )}

              <StatusListBox
                selected={transfer.status}
                options={[{ name: STATUSES[transfer.status], value: transfer.status }]}
                disabled
              />

              {transfer.status === 'DRAFT' && transfer.items.length > 0 && (
                <Button
                  label="Mark as shipped"
                  slim
                  className="self-center"
                  onClick={
                    transfer.inventorySourceToUpdate?.updateInventory === null &&
                    isShopifySyncEnabled
                      ? () => setMarkAsShippedPopupOpen(true)
                      : () => onMarkAsShipped()
                  }
                />
              )}
              {transfer.status === 'SHIPMENT' && (
                <Button
                  label="Mark as received"
                  slim
                  className="self-center"
                  onClick={() => setReceivedTransferPopupOpen(true)}
                />
              )}
            </div>
          </div>

          <div className="mt-6 flex justify-between px-6 text-sm font-bold text-midnight-100">
            <div className="flex items-center space-x-8">
              <div>
                <span className="text-midnight-70">Source Location:&nbsp;</span>
                <span>{transfer.fromLocation.name}</span>
              </div>
              <div>
                <span className="text-midnight-70">Destination Location:&nbsp;</span>
                <span>{transfer.toLocation.name}</span>
              </div>
            </div>
            <div>
              <span className="text-midnight-70">Last Updated:&nbsp;</span>
              <time>
                <TimeAgo date={transfer.updatedAt} />
              </time>
            </div>
          </div>

          <div className="mt-6 flex px-6 text-sm text-midnight-100">
            <div className="flex space-x-3">
              <POMilestone
                milestone={{
                  key: 'DRAFT',
                  description: 'Created date',
                  input: {
                    name: 'createdAt',
                    readOnly: true,
                  },
                }}
                resource={transfer}
              />
              <POMilestone
                milestone={{
                  key: 'SHIPMENT',
                  description: transfer.status === 'DRAFT' ? null : 'Shipment date',
                  ...(transfer.status === 'DRAFT'
                    ? {}
                    : {
                        input: {
                          name: 'issuedAt',
                          readOnly: true,
                        },
                      }),
                }}
                resource={transfer}
                isReadOnly
              />
              <POMilestone
                milestone={{
                  key: 'DELIVERED',
                  description: !isDelivered ? 'Stock due date' : 'Delivered date',
                  prompts: ['PRE_DELIVERED', 'DELIVERED'],
                  input: {
                    name: !isDelivered ? 'expectedDeliveryDate' : 'deliveredAt',
                    required: true,
                    readOnly: isDelivered,
                  },
                }}
                resource={transfer}
                isReadOnly={isDelivered}
                validateDate
              />
            </div>
            <label htmlFor="wroCode" className="ml-auto self-end">
              <span className="font-bold">WRO Name</span>
              <input
                type="text"
                placeholder="Name/ID"
                className="input mt-2 w-44 py-2"
                id="wroCode"
                {...register('wroCode', { setValueAs: (v) => (!v ? null : v) })}
              />
            </label>
            <div className="ml-6 flex items-end space-x-1 self-end">
              <div className="mb-1.5 flex h-6 w-6 items-stretch justify-stretch">
                {isUrl && (
                  <a
                    href={transfer.wroTextUrl}
                    target="_blank"
                    className="flex w-full items-center justify-center rounded-lg p-1 text-purple-100 hover:bg-purple-50"
                    rel="noreferrer"
                  >
                    <FiExternalLink size={16} />
                  </a>
                )}
              </div>
              <label htmlFor="wroTextUrl">
                <span className="font-bold">WRO Link/Details</span>
                <input
                  type="text"
                  placeholder="Link/Details"
                  className="input mt-2 w-44 py-2"
                  id="wroTextUrl"
                  {...register('wroTextUrl', { setValueAs: (v) => (!v ? null : v) })}
                />
              </label>
            </div>
          </div>

          <div className="mx-6 mt-6 bg-white py-8 text-midnight-100">
            <table className="mt-4 min-w-full border-collapse border-spacing-0 text-xs">
              <thead>
                <tr className="text-sm">
                  <th>&nbsp;</th>
                  <th className="pb-12">
                    <div className="flex items-center justify-center space-x-2">
                      <MapPinIcon className="text-purple-100" height="24" />
                      <span className="max-w-[8rem] truncate">{transfer.fromLocation.name}</span>
                    </div>
                  </th>
                  <th className="mt-1 flex w-16 items-center justify-center pb-12">
                    <hr className="w-full border-0 border-t-2 border-dashed border-midnight-75" />
                    <FiChevronRight size={20} />
                  </th>
                  <th className="pb-12">
                    <div className="flex items-center justify-center space-x-2">
                      <MapPinIcon className="text-purple-100" height="24" />
                      <span className="max-w-[8rem] truncate">{transfer.toLocation.name}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr className="align-top">
                  <th className="pb-4 pl-8 text-left">Product</th>
                  <th>On Hand / Target</th>
                  <th className="w-16">&nbsp;</th>
                  <th>On Hand / Target</th>
                  {transfer.status !== 'SHIPMENT' && transfer.status !== 'DELIVERED' && (
                    <th className="text-right">In Transit</th>
                  )}
                  <th className="text-right">Recommended</th>
                  <th className="text-right">Transfer Units</th>
                  {isDelivered && <th className="text-right">Received Units</th>}
                  <th className="pr-8">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {fields.length === 0 && (
                  <tr>
                    <td colSpan={8} className="p-8 text-sm">
                      <div>
                        <div className="mx-auto mt-12 flex h-16 w-16 items-center justify-center rounded-full bg-gray-50">
                          <FiTruck className="text-gray-75" size={32} />
                        </div>
                        <p className="mt-6 text-center">
                          Create your Transfer by searching for
                          <br />
                          products to add below
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
                {fields.map((item, itemIndex) => (
                  <TransferItem
                    key={item.sku}
                    itemIndex={itemIndex}
                    item={item}
                    onItemRemove={onItemRemove}
                    formItemUpdate={update}
                    fromLocation={transfer.fromLocation}
                    toLocation={transfer.toLocation}
                    highlight={item.options?.highlight}
                    isReadOnly={transfer.status !== 'DRAFT'}
                    isDelivered={isDelivered}
                  />
                ))}
                {transfer.status === 'DRAFT' && (
                  <>
                    {fields.length !== 0 && (
                      <tr>
                        <td colSpan={7}>
                          <h3 className="pl-8 pt-6 text-left text-lg font-bold">
                            Add more products
                          </h3>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={7} className="py-6 pl-8">
                        <SkuSelector
                          items={currentItems}
                          locations={[transfer.fromLocation._id]}
                          onItemsAdd={(skus) => onItemAdd(skus)}
                          onlyFromLocations
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </form>
      </FormProvider>

      {markAsShippedPopupOpen ? (
        <UpdateSourcePopup
          onClose={() => setMarkAsShippedPopupOpen(false)}
          onSubmit={onMarkAsShipped}
          location={transfer.fromLocation}
          type="ship"
        />
      ) : null}

      {deleteTransferPopupOpen ? (
        <UpdateSourcePopup
          onClose={() => setDeleteTransferPopupOpen(false)}
          onSubmit={onRemove}
          location={transfer.fromLocation}
          type="delete"
        />
      ) : null}

      {receivedTransferPopupOpen ? (
        <MarkAsReceivedPopup
          onClose={() => setReceivedTransferPopupOpen(false)}
          transfer={transfer}
          location={transfer.toLocation}
          source={transfer.inventorySourceToUpdate}
          onSubmit={onMarkAsReceived}
        />
      ) : null}
    </div>
  );
};

TransferForm.propTypes = {
  transfer: PropTypes.shape({
    _id: PropTypes.string,
    transferId: PropTypes.string,
    customId: PropTypes.string,
    toLocation: PropTypes.shape({
      name: PropTypes.string,
    }),
    fromLocation: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    status: PropTypes.string,
    issuedAt: PropTypes.string,
    deliveredAt: PropTypes.string,
    expectedDeliveryDate: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    wroCode: PropTypes.string,
    wroTextUrl: PropTypes.string,
    createdAt: PropTypes.string,
    inventorySourceToUpdate: PropTypes.shape({
      sourceType: PropTypes.string,
      sourceId: PropTypes.string,
      updateInventory: PropTypes.bool,
      hasRequiredPermission: PropTypes.bool,
      shopifyRedirectUrl: PropTypes.shape({
        redirectUrl: PropTypes.string,
      }),
    }),
    sourceId: PropTypes.string,
  }).isRequired,
};

const InternalTransfer = () => {
  const { _id } = useParams();
  const navigate = useNavigate();

  const { loading, data: { transfer } = {} } = useQuery(TRANSFER_QUERY, {
    variables: { _id },
  });

  useDocumentTitle(transfer?.customId ? `${transfer.customId} Transfer` : 'Transfer');

  if (loading) return <Spinner />;

  if (!transfer) return <NotFound />;

  if (transfer.sourceId !== 'cogsy' || !transfer.fromLocation) {
    navigate('/transfers');
    return <Spinner />;
  }

  return <TransferForm transfer={transfer} />;
};

export default InternalTransfer;
