import React from 'react';
import { gql, useMutation } from '@apollo/client';
import cn from 'classnames';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { Controller, useForm } from 'react-hook-form';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import Toggle from '../Components/Toggle.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($input: UpdatePreferencesInput!) {
    updateAccountPreferences(input: $input) {
      forecastEnabled
    }
  }
`;

const removeNullValues = (data) => {
  const cleanedObject = pickBy(
    data,
    (value) => !isObject(value) && value !== null && value !== '' && !Number.isNaN(value),
  );

  return {
    ...cleanedObject,
    ...mapValues(pickBy(data, isObject), removeNullValues),
  };
};

const Forecast = () => {
  const { account, axios } = useAccount();
  const { addAlert } = useAlerts();
  useDocumentTitle('Forecast Settings');

  const [updateAccountPreferences] = useMutation(UPDATE_PREFERENCES);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: 'all' });

  const onSubmit = React.useCallback((data) => {
    const doSubmit = async () => {
      try {
        await updateAccountPreferences({ variables: { input: removeNullValues(data) } });
        addAlert('Changes saved successfully', { level: 'success' });
      } catch (error) {
        addAlert('An error has occurred! Please refresh the page and try again.', { error });
      }
    };

    doSubmit();
  });

  const handleRecreatePredictor = React.useCallback(() => {
    const recreatePredictor = async () => {
      await axios.delete('/api/forecasts/predictor');
      addAlert('Forecast predictor has been recreated.', { level: 'success' });
    };
    recreatePredictor();
  }, []);

  const handleStartForecast = React.useCallback(() => {
    const startForecast = async () => {
      await axios.post('/api/forecasts/run');
      addAlert('Forecast has been started in background, please hold on...', { level: 'success' });
    };
    startForecast();
  }, []);

  const selectedForecastType = watch('forecastType') || account.preferences.forecastType;

  return (
    <div className="col-span-4 text-midnight-100">
      <h5 className="text-xl">Forecast</h5>

      <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="forecastEnabled"
            control={control}
            defaultValue={account.preferences.forecastEnabled ?? true}
            render={({ field: { onChange, value } }) => (
              <Toggle label="Enabled" spaceBetween enabled={value} onChange={onChange} />
            )}
          />
        </div>

        <div className="mt-4">
          <label htmlFor="forecastType">
            Type
            <select
              id="forecastType"
              className="input"
              defaultValue={account.preferences.forecastType}
              {...register('forecastType')}
            >
              <option value="aws">AWS</option>
              <option value="simple">Non-ML</option>
              <option value="arima">ARIMA</option>
            </select>
          </label>
        </div>

        {['aws', 'arima'].includes(selectedForecastType) && (
          <div className="mt-4">
            <label htmlFor="forecastOptions_horizon">
              Horizon
              <div className="relative">
                <input
                  type="number"
                  step="1"
                  min="0"
                  id="forecastOptions_horizon"
                  className={cn('input pr-12', errors.forecastOptions?.horizon && 'input-error')}
                  placeholder="Default: 63 days"
                  defaultValue={account.preferences.forecastOptions?.horizon}
                  {...register('forecastOptions[horizon]', {
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: "Horizon value can't be negative",
                    },
                  })}
                />
                <span className="absolute right-4 top-4">days</span>
              </div>
            </label>
            {errors.forecastOptions?.horizon && (
              <p className="mt-2 text-xs text-red-100">{errors.forecastOptions?.horizon.message}</p>
            )}
          </div>
        )}

        {selectedForecastType === 'aws' && (
          <>
            <h2 className="mt-4 text-base">AWS Forecast Options</h2>

            <div className="mt-4">
              <label htmlFor="forecastOptions_optimizationMetric">
                Optimization metric
                <select
                  id="forecastOptions_optimizationMetric"
                  className="input"
                  defaultValue={account.preferences.forecastOptions?.optimizationMetric}
                  {...register('forecastOptions[optimizationMetric]')}
                >
                  <option value="">Auto (default: WAPE)</option>
                  <option value="WAPE">WAPE</option>
                  <option value="AverageWeightedQuantileLoss">AverageWeightedQuantileLoss</option>
                  <option value="MASE">MASE</option>
                  <option value="MAPE">MAPE</option>
                </select>
              </label>
            </div>

            <div className="mt-4">
              <Controller
                name="forecastOptions[explainPredictor]"
                control={control}
                defaultValue={account.preferences.forecastOptions?.explainPredictor}
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    label="Explain predictor"
                    spaceBetween
                    enabled={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </>
        )}

        {['arima', 'simple'].includes(selectedForecastType) && (
          <div className="mt-4">
            <Controller
              name="forecastOptions[debug]"
              control={control}
              defaultValue={account.preferences.forecastOptions?.debug ?? false}
              render={({ field: { onChange, value } }) => (
                <Toggle label="Debug" spaceBetween enabled={value} onChange={onChange} />
              )}
            />
          </div>
        )}

        {selectedForecastType === 'arima' && (
          <>
            <h2 className="mt-4 text-base">ARIMA Forecast Options</h2>

            <div className="mt-4">
              <label htmlFor="forecastOptions_lookbackWeeks">
                Lookback Weeks
                <div className="relative">
                  <input
                    type="number"
                    step="1"
                    min="0"
                    id="forecastOptions_lookbackWeeks"
                    className={cn(
                      'input pr-14',
                      errors.forecastOptions?.lookbackWeeks && 'input-error',
                    )}
                    placeholder="Default: 52 weeks"
                    defaultValue={account.preferences.forecastOptions?.lookbackWeeks}
                    {...register('forecastOptions[lookbackWeeks]', {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: "Lookback Weeks value can't be negative",
                      },
                    })}
                  />
                  <span className="absolute right-4 top-4">weeks</span>
                </div>
              </label>
              {errors.forecastOptions?.lookbackWeeks && (
                <p className="mt-2 text-xs text-red-100">
                  {errors.forecastOptions?.lookbackWeeks.message}
                </p>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="granularity">
                Granularity
                <select
                  id="forecastOptions_arimaOptions_granularity"
                  className="input"
                  defaultValue={account.preferences.forecastOptions?.arimaOptions?.granularity}
                  {...register('forecastOptions[arimaOptions][granularity]')}
                >
                  <option value="">Auto (default: Week)</option>
                  <option value="week">Week</option>
                  <option value="day">Day</option>
                </select>
              </label>
            </div>

            <div className="grid grid-cols-4 gap-6">
              {['d', 'D'].map((key) => (
                <div className="mt-4" key={key}>
                  <label htmlFor={`forecastOptions_arimaOptions_${key}`}>
                    {key}

                    <div className="relative">
                      <input
                        type="number"
                        step="1"
                        min="0"
                        id={`forecastOptions_arimaOptions_${key}`}
                        className={cn(
                          'input',
                          errors.forecastOptions?.arimaOptions?.[key] && 'input-error',
                        )}
                        defaultValue={account.preferences.forecastOptions?.arimaOptions?.[key]}
                        {...register(`forecastOptions[arimaOptions][${key}]`, {
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message: `${key} value can't be negative`,
                          },
                        })}
                      />
                    </div>
                  </label>
                  {errors.forecastOptions?.arimaOptions?.[key] && (
                    <p className="mt-2 text-xs text-red-100">
                      {errors.forecastOptions?.arimaOptions?.[key].message}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}

        <div className="mt-4 space-x-2 text-xs md:whitespace-nowrap">
          <Button type="submit" variant="primary" label="Save" slim />

          <Button label="Run forecast" variant="secondary" onClick={handleStartForecast} slim />

          <Button
            label="Re-create predictor"
            variant="secondary"
            onClick={handleRecreatePredictor}
            slim
          />
        </div>
      </form>
    </div>
  );
};

export default Forecast;
