import { formatDistance } from 'date-fns';
import React from 'react';

const TimeAgo = ({ date }) => {
  const [now, setNow] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return formatDistance(new Date(date), now, { addSuffix: true });
};

export default TimeAgo;
