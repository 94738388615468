import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PageHeader from '../Components/PageHeader.jsx';
import Account from './Account.jsx';
import Backorders from './Backorders.jsx';
import Billing from './Billing.jsx';
import Forecast from './Forecast.jsx';
import Inventory from './Inventory.jsx';
import Locations from './Locations.jsx';
import Members from './Members.jsx';
import Navbar from './Navbar.jsx';
import Notifications from './Notifications.jsx';
import Password from './Password.jsx';
import Profile from './Profile.jsx';
import Sources from './Sources.jsx';
import Tools from './Tools.jsx';

const Index = () => (
  <>
    <PageHeader text="Settings" />
    <div className="mt-8 grid grid-cols-12 gap-x-12 pt-11">
      <div className="col-span-2">
        <Navbar />
      </div>
      <Routes>
        <Route path="profile" element={<Profile />} />
        <Route path="account" element={<Account />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="members" element={<Members />} />
        <Route path="password" element={<Password />} />
        <Route path="sources" element={<Sources />} />
        <Route path="inventory" element={<Inventory />} />
        <Route path="backorders" element={<Backorders />} />
        <Route path="billing" element={<Billing />} />
        <Route path="forecast" element={<Forecast />} />
        <Route path="locations" element={<Locations />} />
        <Route path="tools" element={<Tools />} />
        <Route path="*" element={<Navigate replace to="/settings/profile" />} />
      </Routes>
    </div>
  </>
);

export default Index;
