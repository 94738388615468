import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FiChevronLeft, FiPlus } from 'react-icons/fi';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import confirm from '../Components/ConfirmDialog.jsx';
import SkuDetails from '../Components/SkuDetails.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import { CREATE_TRANSFER, REMOVE_TRANSFER, UPDATE_TRANSFER } from '../InternalTransfers/Queries.js';
import NotFound from '../Layout/NotFound.jsx';
import { PO, SKU_QUERY } from './Queries.js';
import SplitTransfer from './Transfers/SplitTransfer.jsx';
import TransferDialog from './Transfers/TransferDialog.jsx';

const PurchaseOrderItem = ({ item }) => {
  const { loading, data } = useQuery(SKU_QUERY, { variables: { sku: item.sku } });

  const sku = data?.sku;

  return (
    <li className="mt-4 flex items-center justify-between" key={item.sku}>
      <div className="max-w-[10rem] text-xs">
        {loading ? <SkuDetails.Skeleton /> : <SkuDetails sku={sku} />}
      </div>

      <div className="mt-3 shrink-0 font-bold text-midnight-75">
        {item.quantity.toLocaleString()}
        {pluralize(' unit', item.quantity)}
      </div>
    </li>
  );
};

PurchaseOrderItem.propTypes = {
  item: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
};

const toFormValues = (transfer) => ({
  _id: transfer._id,
  customId: transfer.customId,
  toLocation: transfer.toLocation._id,
  expectedDeliveryDate: transfer.expectedDeliveryDate,
  items: transfer.items.map(({ sku, quantity, receivedQuantity }) => ({
    sku,
    quantity,
    receivedQuantity,
  })),
  deliveredAt: transfer.deliveredAt,
});

const TransfersForm = ({ purchaseOrder }) => {
  const refetchQueries = [{ query: PO, variables: { _id: purchaseOrder._id } }];

  const [removeTransfer, { loading: removeTransferLoading }] = useMutation(REMOVE_TRANSFER, {
    refetchQueries,
  });
  const [createTransfer, { loading: createTransferLoading }] = useMutation(CREATE_TRANSFER, {
    refetchQueries,
  });
  const [updateTransfer, { loading: updateTransferLoading }] = useMutation(UPDATE_TRANSFER, {
    refetchQueries,
  });

  const [transferIdsToRemove, setTransferIdsToRemove] = useState([]);
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);

  const form = useForm({
    defaultValues: {
      transfers: purchaseOrder.transfers.edges.map(({ node: transfer }) => toFormValues(transfer)),
    },
  });

  const {
    fields: formTransfers,
    remove: removeFormTransfer,
    append: appendFormTransfer,
  } = useFieldArray({
    control: form.control,
    name: 'transfers',
  });

  const onCreate = (data) => {
    appendFormTransfer({
      ...data,
      trackingUrl: data.trackingUrl || undefined,
      items: purchaseOrder.items.map(({ sku }) => ({ sku, quantity: 0 })),
      purchaseOrder: purchaseOrder._id,
    });

    setIsTransferDialogOpen(false);
  };

  const onRemove = async (transferIndex) => {
    if (await confirm('Are you sure you want to remove this shipment?')) {
      if (formTransfers[transferIndex]._id) {
        setTransferIdsToRemove([...transferIdsToRemove, formTransfers[transferIndex]._id]);
      }
      removeFormTransfer(transferIndex);
    }
  };

  const onSave = async (data) => {
    const newData = structuredClone(data);

    await Promise.all(
      data.transfers.map(async (transferData, transferIndex) => {
        const { _id, ...input } = transferData;

        if (_id) {
          await updateTransfer({ variables: { input, transferId: _id } });
          return;
        }

        const {
          data: {
            createTransfer: { _id: newId },
          },
        } = await createTransfer({ variables: { input } });

        newData.transfers[transferIndex]._id = newId;
      }),
    );

    await Promise.all(
      transferIdsToRemove.map(async (transferId) =>
        removeTransfer({ variables: { transferId, syncInventoryToSource: false } }),
      ),
    );

    setTransferIdsToRemove([]);

    form.reset(newData);
  };

  const { user } = useAccount();

  const isCreatedByCogsy = purchaseOrder.sourceId === 'cogsy';
  const isReadOnly = !isCreatedByCogsy || purchaseOrder.status === 'DELIVERED' || user.isReadOnly;
  const totalQuantity = purchaseOrder.items.reduce((total, { quantity }) => total + quantity, 0);

  const { transfers } = form.watch();
  const hasEmptyTransfers = transfers.some(
    ({ items }) => items.reduce((total, { quantity }) => total + quantity, 0) === 0,
  );

  return (
    <div className="-my-12 flex min-h-screen flex-col bg-purple-5">
      <div className="mb-8 flex items-center justify-between bg-purple-5 pl-1 pr-2 pt-4">
        <Link
          to={`/purchase-orders/${purchaseOrder._id}`}
          className="group inline-flex items-center rounded-full px-4 py-2 text-sm font-bold leading-none text-midnight-100 hover:bg-purple-10"
        >
          <FiChevronLeft
            className="mr-1 transition-transform group-hover:-translate-x-1"
            size={20}
          />
          <span>{purchaseOrder.customPurchaseOrderNumber}</span>
        </Link>
      </div>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSave)}>
          <div className="flex grow flex-col text-midnight-100">
            <div className="flex items-center justify-between px-6">
              <div className="py-1 text-3xl">Split Shipments</div>

              {isReadOnly ? null : (
                <div>
                  <Button
                    label="Cancel"
                    href={`/purchase-orders/${purchaseOrder._id}`}
                    variant="text"
                    className="ml-auto"
                    slim
                  />

                  <Button
                    label="Save"
                    type="submit"
                    slim
                    disabled={
                      !form.formState.isValid ||
                      !form.formState.isDirty ||
                      hasEmptyTransfers ||
                      updateTransferLoading ||
                      createTransferLoading ||
                      removeTransferLoading
                    }
                  />
                </div>
              )}
            </div>

            <div className="relative mt-5 flex grow pb-6">
              <div className="flex w-full space-x-7 overflow-x-auto bg-white px-6 py-7 text-sm">
                <div
                  className={classnames(
                    'sticky left-0 z-10 -mr-7 w-[21rem] shrink-0 bg-white pr-7',
                    isReadOnly ? 'pt-[9.875rem]' : 'pt-[6.125rem]',
                  )}
                >
                  {isReadOnly ? null : (
                    <Button
                      slim
                      label="Add Shipment"
                      className="mb-8"
                      icon={FiPlus}
                      onClick={() => setIsTransferDialogOpen(true)}
                    />
                  )}
                  <div className="flex justify-between font-bold">
                    <div>PO items</div>
                    <div>Quantities</div>
                  </div>

                  <ul className="space-y-4">
                    {purchaseOrder.items.map((item) => (
                      <PurchaseOrderItem key={item.sku} item={item} />
                    ))}
                  </ul>

                  <div className="mt-6">
                    <div className="font-bold">Total Quantities</div>
                    <div className="mt-3">
                      {totalQuantity.toLocaleString()}
                      {pluralize(' unit', totalQuantity)}
                    </div>
                  </div>
                </div>
                {formTransfers.map((formTransfer, transferIndex) => (
                  <SplitTransfer
                    key={formTransfer.id}
                    formTransfer={formTransfer}
                    transferIndex={transferIndex}
                    purchaseOrder={purchaseOrder}
                    onRemove={() => onRemove(transferIndex)}
                    isReadOnly={isReadOnly || !!formTransfer.deliveredAt}
                  />
                ))}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>

      {isTransferDialogOpen ? (
        <TransferDialog
          purchaseOrder={purchaseOrder}
          createSplitTransfer
          onClose={() => setIsTransferDialogOpen(false)}
          onSave={onCreate}
        />
      ) : null}
    </div>
  );
};

TransfersForm.propTypes = {
  purchaseOrder: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    sourceId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    customPurchaseOrderNumber: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    transfers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            _id: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

const Transfers = () => {
  const { _id } = useParams();
  useDocumentTitle('Shipments');

  const { loading, data } = useQuery(PO, { variables: { _id } });

  if (loading) return <Spinner />;

  if (!data) return <NotFound />;

  return <TransfersForm purchaseOrder={data.purchaseOrder} />;
};

export default Transfers;
