import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import Button from '../../Components/Button.jsx';

const CREATE_FULFIL_REDIRECT_URL = gql`
  mutation CreateFulfilRedirectUrl($domain: String!) {
    createFulfilRedirectUrl(domain: $domain) {
      redirectUrl
    }
  }
`;

const FulfilPopup = ({ open, onClose }) => {
  const [createFulfilRedirectUrl, { loading }] = useMutation(CREATE_FULFIL_REDIRECT_URL);

  const handleFulfilConnect = React.useCallback(
    (e) => {
      e.preventDefault();

      const variables = Object.fromEntries(new FormData(document.querySelector('form')));

      const doConnect = async () => {
        const {
          data: {
            createFulfilRedirectUrl: { redirectUrl },
          },
        } = await createFulfilRedirectUrl({ variables });

        window.location.assign(redirectUrl);
      };

      doConnect();
    },
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Fulfil</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleFulfilConnect}>
            <div>
              <label htmlFor="fulfil-domain">
                Fulfil domain*
                <input type="text" id="fulfil-domain" required className="input" name="domain" />
                <p className="mt-2 text-gray-100">
                  Your Fulfil account domain. e.g: <em>example.fulfil.io</em>
                </p>
              </label>
            </div>
            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

FulfilPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FulfilPopup;
