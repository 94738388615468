import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiAlertCircle, FiSend } from 'react-icons/fi';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import Button from '../Components/Button.jsx';
import confirm from '../Components/ConfirmDialog.jsx';
import DeleteButton from '../Components/DeleteButton.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const MEMBERS = gql`
  query {
    account {
      members {
        user {
          email
          fullName
        }
        email
        invitationCode
        role
      }
    }
  }
`;

const INVITE_MEMBER = gql`
  mutation InviteMember($email: EmailAddress!, $role: UserRoles!) {
    inviteMember(email: $email, role: $role) {
      invitationCode
    }
  }
`;

const REMOVE_MEMBER = gql`
  mutation RemoveMember($email: EmailAddress!) {
    removeMember(email: $email) {
      _id
    }
  }
`;

const Member = ({ member, onRemove }) => {
  const [removeMember] = useMutation(REMOVE_MEMBER);

  const handleRemove = async () => {
    if (await confirm('Are you sure you want to remove this member?')) {
      await removeMember({ variables: { email: member.email } });
      onRemove();
    }
  };

  return (
    <tr className="text-xs hover:bg-gray-10">
      <td className="w-2/3 max-w-[1px] py-2 pl-6">
        <div className="truncate">{member.user?.email || member.email}</div>
        {!member.user && (
          <span className="text-gray-100">
            <FiAlertCircle className="inline" /> Invitation pending...
          </span>
        )}
        {member.user?.fullName && <div className="truncate font-bold">{member.user.fullName}</div>}
      </td>
      <td className="px-4">{member.role === 'EDITOR' ? 'Editor' : 'Viewer'}</td>
      <td className="pr-6 text-right">
        <DeleteButton onDelete={handleRemove} />
      </td>
    </tr>
  );
};

Member.propTypes = {
  member: PropTypes.shape({
    email: PropTypes.string.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
      fullName: PropTypes.string,
    }),
    role: PropTypes.string.isRequired,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
};

const Members = () => {
  useDocumentTitle('Members Settings');
  const { data, loading: membersLoading, refetch } = useQuery(MEMBERS);
  const [inviteMember, { loading: inviteLoading }] = useMutation(INVITE_MEMBER);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const onInvite = React.useCallback(
    ({ email, role }) => {
      const doInvite = async () => {
        await inviteMember({ variables: { email, role } });

        setValue('email', '');
        setValue('role', 'EDITOR');

        refetch();
      };

      doInvite();
    },
    [refetch],
  );

  if (membersLoading || !data?.account.members) return <Spinner />;

  const { members } = data.account;

  return (
    <div className="col-span-4 text-midnight-100">
      {members.length > 0 && (
        <div className="mb-12">
          <h5 className="text-xl">Members</h5>
          <div className="mt-6 rounded-lg bg-white pb-7 pt-5 text-midnight-100 shadow">
            <h5 className="mx-6 mb-3 border-b border-gray-50 pb-4 text-sm font-bold">
              Members List
            </h5>
            <table className="min-w-full table-fixed">
              <tbody className="mt-2">
                {members.map((member) => (
                  <Member key={member.email} member={member} onRemove={refetch} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div>
        <h5 className="text-xl">Invite New Member</h5>
        <form className="text-xs" onSubmit={handleSubmit(onInvite)}>
          <div className="mt-4">
            <label htmlFor="invite_email">
              Email
              <input
                type="email"
                id="invite_email"
                className={cn('input', errors.email && 'input-error')}
                placeholder="name@yourstore.com"
                {...register('email', {
                  required: 'Please provide a valid email',
                  pattern: {
                    value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                    message: 'Please provide a valid email',
                  },
                })}
              />
            </label>
            <InputErrorMessage message={errors.email?.message} />
          </div>

          <div className="mt-4">
            <label htmlFor="invite_role">
              Role
              <select
                id="invite_role"
                className="input"
                defaultValue="EDITOR"
                {...register('role')}
              >
                <option value="EDITOR">Editor</option>
                <option value="READ_ONLY">Viewer</option>
              </select>
            </label>
          </div>

          <div className="mt-8">
            <Button
              type="submit"
              fullWidth
              icon={FiSend}
              iconSize={16}
              label={inviteLoading ? 'Please wait...' : 'Invite'}
              disabled={inviteLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Members;
