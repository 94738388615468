import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FiChevronRight, FiPlus } from 'react-icons/fi';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';
import confirm from '../../Components/ConfirmDialog.jsx';
import DeleteButton from '../../Components/DeleteButton.jsx';
import SplitArrowIcon from '../../Icons/SplitArrowIcon.jsx';
import {
  MARK_AS_DELIVERED,
  REMOVE_TRANSFER,
  UPDATE_SOURCE,
} from '../../InternalTransfers/Queries.js';
import { toDate } from '../../Utils/dates.js';
import { PO } from '../Queries.js';
import MarkAsReceivedPopup from './MarkAsReceivedPopup.jsx';

const TransfersWidget = ({ purchaseOrder, transfers, isReadOnly, setTransferDialogTransfer }) => {
  const { addAlert } = useAlerts();
  const [receivedTransferPopup, setReceivedTransferPopup] = useState(null);

  const refetchQueries = [{ query: PO, variables: { _id: purchaseOrder._id } }];

  const [removeTransfer] = useMutation(REMOVE_TRANSFER, { refetchQueries });
  const [updateSource] = useMutation(UPDATE_SOURCE, { refetchQueries });
  const [markAsDelivered] = useMutation(MARK_AS_DELIVERED, {
    variables: { transferId: receivedTransferPopup?._id },
    refetchQueries,
  });

  const onMarkAsReceived = async (input = {}) => {
    const {
      syncInventoryToSource = receivedTransferPopup.inventorySourceToUpdate?.updateInventory,
      isInventoryChoicePermanent,
    } = input;

    if (isInventoryChoicePermanent && receivedTransferPopup.inventorySourceToUpdate) {
      await updateSource({
        variables: {
          input: { updateInventory: !!syncInventoryToSource },
          sourceId: receivedTransferPopup.inventorySourceToUpdate.sourceId,
        },
      });
    }

    await markAsDelivered({
      variables: {
        input: {
          deliveredAt: input.deliveredAt,
          items: input.items,
        },
        syncInventoryToSource: !!syncInventoryToSource,
        sourceToUpdate: receivedTransferPopup.inventorySourceToUpdate?.sourceId,
      },
      onCompleted: () => {
        if (syncInventoryToSource) {
          addAlert('Inventory quantity is being updated on Shopify', {
            level: 'success',
          });
        }
        window.analytics?.track('purchase-orders.shipment-received', {
          purchaseOrderId: purchaseOrder._id,
          shipmentId: receivedTransferPopup._id,
          shipmentDeliveryDate: receivedTransferPopup.deliveredAt,
        });

        setReceivedTransferPopup(null);
      },
    });
  };

  if (purchaseOrder.status === 'DRAFT') return null;

  const convertToLink = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (urlRegex.test(text))
      return (
        <a href={text} className="text-purple-100" target="_blank" rel="noreferrer">
          {text}
        </a>
      );

    return text;
  };

  const handleRemove = async (transfer) => {
    if (await confirm('Are you sure you want to remove this shipment?')) {
      removeTransfer({
        variables: {
          transferId: transfer._id,
          syncInventoryToSource: false,
        },
      });
    }
  };

  return (
    <div className="col-span-1">
      <div className="bg-white px-7 py-6 text-midnight-100">
        <div className="mb-4 flex items-center justify-between">
          <div className="font-bold">
            {pluralize('Shipment', transfers.length)}
            &nbsp; ({transfers.length})
          </div>

          {!isReadOnly && transfers.length > 0 ? (
            <Link
              className="text-sm font-bold text-purple-100"
              to={`/purchase-orders/${purchaseOrder._id}/shipments`}
            >
              Edit
            </Link>
          ) : null}
        </div>

        <div
          className={classnames('max-h-[43.5rem] overflow-y-auto', transfers.length > 2 && 'pr-4')}
        >
          {transfers.length === 0 ? (
            <div className="mt-4 space-y-4 rounded bg-purple-5 p-9">
              <div className="text-center text-sm font-bold">No shipments found</div>

              {!isReadOnly && purchaseOrder.items.length > 0 ? (
                <div className="flex items-center justify-center space-x-4">
                  <Button
                    label="Add Single Shipment"
                    slim
                    icon={FiPlus}
                    iconSize={16}
                    onClick={() => setTransferDialogTransfer({})}
                  />

                  <Button
                    label={
                      <>
                        <SplitArrowIcon height="12" className="mr-1 group-hover:text-white" />
                        Split Shipment
                      </>
                    }
                    variant="tertiary"
                    slim
                    className="group"
                    href={`/purchase-orders/${purchaseOrder._id}/shipments`}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {transfers.length > 0 ? (
            <div className="space-y-8">
              {transfers.map((transfer) => {
                const totalUnits = transfer.items.reduce(
                  (total, { quantity }) => total + quantity,
                  0,
                );

                const totalReceived = transfer.items.reduce(
                  (total, { receivedQuantity }) => total + (receivedQuantity || 0),
                  0,
                );

                const progressBarWidth = totalUnits !== 0 ? (totalReceived / totalUnits) * 100 : 0;

                return (
                  <div className="space-y-4 rounded bg-purple-5 p-5 text-sm" key={transfer._id}>
                    <div className="flex">
                      {transfer.deliveredAt || isReadOnly ? (
                        <div className="flex items-center text-sm font-bold text-purple-100">
                          {transfer.customId}
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="flex items-center text-sm font-bold text-purple-100"
                          onClick={() => setTransferDialogTransfer(transfer)}
                        >
                          {transfer.customId}
                          <FiChevronRight size={18} className="ml-1" />
                        </button>
                      )}

                      <div className="ml-auto flex items-center space-x-2">
                        {isReadOnly || transfer.deliveredAt ? null : (
                          <Button
                            label="Mark as Received"
                            variant="tertiary"
                            slim
                            onClick={() => setReceivedTransferPopup(transfer)}
                          />
                        )}

                        {isReadOnly ? null : (
                          <DeleteButton onDelete={() => handleRemove(transfer)} />
                        )}
                      </div>
                    </div>

                    {transfer.deliveredAt ? (
                      <div className="space-y-2 font-bold">
                        <div>Delivery date</div>
                        <div className="text-xs text-midnight-70">
                          {toDate(transfer.deliveredAt).toLocaleDateString()}
                        </div>
                      </div>
                    ) : (
                      <div className="space-y-2 font-bold">
                        <div>Target delivery date</div>
                        <div className="text-xs text-midnight-70">
                          {toDate(transfer.expectedDeliveryDate).toLocaleDateString()}
                        </div>
                      </div>
                    )}

                    <div className="space-y-2 font-bold">
                      <div>Ship to</div>
                      <div className="text-xs text-midnight-70">{transfer.toLocation.name}</div>
                    </div>

                    <div className="space-y-2">
                      <div className="font-bold">Shipment Quantity</div>

                      <div className="w-44">
                        <div className="mt-3 text-xs text-gray-100">Received/Ordered</div>
                        <div className="mt-1 text-xs font-bold">
                          {`${totalReceived.toLocaleString() || 0} / ${
                            totalUnits.toLocaleString() || 0
                          }`}
                        </div>
                        <div className="mt-1 h-1 overflow-hidden rounded-sm bg-purple-50">
                          <div
                            className="h-full bg-purple-100"
                            style={{ width: `${progressBarWidth}%` }}
                          />
                        </div>
                      </div>
                    </div>

                    {transfer.trackingUrl ? (
                      <div className="space-y-2 font-bold">
                        <div>Tracking</div>
                        <div className="text-xs text-midnight-70">
                          <a href={transfer.trackingUrl} target="_blank" rel="noreferrer">
                            {transfer.trackingUrl}
                          </a>
                        </div>
                      </div>
                    ) : null}

                    {transfer.trackingCode ? (
                      <div className="space-y-2 font-bold">
                        <div>Tracking Code</div>
                        <div className="text-xs text-midnight-70">{transfer.trackingCode}</div>
                      </div>
                    ) : null}

                    {transfer.wroCode ? (
                      <div className="space-y-2 font-bold">
                        <div>WRO Name</div>
                        <div className="text-xs text-midnight-70">{transfer.wroCode}</div>
                      </div>
                    ) : null}

                    {transfer.wroTextUrl ? (
                      <div className="space-y-2 font-bold">
                        <div>WRO Link/Details</div>
                        <div className="text-xs text-midnight-70">
                          {convertToLink(transfer.wroTextUrl)}
                        </div>
                      </div>
                    ) : null}

                    {transfer.notes ? (
                      <div className="space-y-2 font-bold">
                        <div>Notes</div>
                        <div className="font-medium text-midnight-75">
                          {transfer.notes.split('\n').map((line) => (
                            <span key={`${transfer._id}-notes-${line}`}>
                              {line}
                              <br />
                            </span>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : null}

          {transfers.length > 0 || !purchaseOrder.expectedDeliveryDate ? null : (
            <div className="mt-8 font-bold">
              <div className="text-sm">Target delivery date</div>
              <div className="mt-2 text-xs text-midnight-100/60">
                Est. {toDate(purchaseOrder.expectedDeliveryDate).toLocaleDateString()}
              </div>
            </div>
          )}
        </div>
        {receivedTransferPopup ? (
          <MarkAsReceivedPopup
            onClose={() => setReceivedTransferPopup(null)}
            transfer={receivedTransferPopup}
            onSubmit={onMarkAsReceived}
            location={receivedTransferPopup.toLocation}
            source={receivedTransferPopup.inventorySourceToUpdate}
          />
        ) : null}
      </div>
    </div>
  );
};

TransfersWidget.propTypes = {
  purchaseOrder: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    expectedDeliveryDate: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
      }),
    ).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  transfers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      transferId: PropTypes.string.isRequired,
      customId: PropTypes.string,
      toLocation: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      expectedDeliveryDate: PropTypes.string,
      deliveredAt: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          sku: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
          receivedQuantity: PropTypes.number,
        }),
      ).isRequired,
      trackingCode: PropTypes.string,
      trackingUrl: PropTypes.string,
      notes: PropTypes.string,
    }),
  ).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  setTransferDialogTransfer: PropTypes.func.isRequired,
};

export default TransfersWidget;
