import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiPlus, FiX } from 'react-icons/fi';
import { Popover } from '@headlessui/react';
import cn from 'classnames';
import pluralize from 'pluralize';
import Button from '../Components/Button.jsx';
import Tag from '../Components/Tag.jsx';
import { addCountsToHeatmap } from './utils/heatmap.js';

const heatmapFormats = [
  {
    value: 0.5,
    bgColorClass: 'bg-red-75',
    dateBgColorClass: 'bg-red-85',
    borderColorClass: 'border-red-100',
    textColorClass: 'text-red-100',
    ctaBgColorClass: 'bg-red-100',
    ctaTextColorClass: 'text-red-75',
    status: '🙁 Inadequate stock levels across all products',
  },
  {
    value: 0.6,
    bgColorClass: 'bg-red-10',
    dateBgColorClass: 'bg-red-25',
    borderColorClass: 'border-red-75',
    textColorClass: 'text-red-100',
    ctaBgColorClass: 'bg-red-75',
    ctaTextColorClass: 'text-red-10',
    status: '😟 Poor stock levels across all products',
  },
  {
    value: 0.7,
    bgColorClass: 'bg-yellow-10',
    dateBgColorClass: 'bg-yellow-75',
    borderColorClass: 'border-yellow-100',
    textColorClass: 'text-yellow-100',
    ctaBgColorClass: 'bg-yellow-100',
    ctaTextColorClass: 'text-yellow-10',
    status: '🙂 Moderate stock levels across all products',
  },
  {
    value: 0.8,
    bgColorClass: 'bg-leafy-10',
    dateBgColorClass: 'bg-leafy-50',
    borderColorClass: 'border-leafy-75',
    textColorClass: 'text-leafy-100',
    ctaBgColorClass: 'bg-leafy-75',
    ctaTextColorClass: 'text-leafy-10',
    status: '😎 Good stock levels across all products',
  },
  {
    value: 1,
    bgColorClass: 'bg-leafy-75',
    dateBgColorClass: 'bg-leafy-65',
    borderColorClass: 'border-leafy-100',
    textColorClass: 'text-leafy-100',
    ctaBgColorClass: 'bg-leafy-100',
    ctaTextColorClass: 'text-leafy-75',
    status: '🚀 Excellent stock levels across all products',
  },
];

const bgColorClass = (value) => heatmapFormats.find((format) => value <= format.value).bgColorClass;

const dateBgColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).dateBgColorClass;

const textColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).textColorClass;

const borderColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).borderColorClass;

const ctaBgColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).ctaBgColorClass;

const ctaTextColorClass = (value) =>
  heatmapFormats.find((format) => value <= format.value).ctaTextColorClass;

const status = (value) => heatmapFormats.find((format) => value <= format.value).status;

const heatmapSizeStyles = [
  {
    variant: 'large',
    spacing: 'justify-between',
    boxWidth: 'w-[70px]',
    boxHeight: 'h-[96px]',
    pointerOffset: 'right-[104px]',
  },
  {
    variant: 'medium',
    spacing: 'justify-between ml-[84px] mr-5',
    boxWidth: 'w-[54px]',
    boxHeight: 'h-[96px]',
    pointerOffset: 'right-[98px]',
  },
  {
    variant: 'small',
    spacing: 'justify-end space-x-[15px]',
    boxWidth: 'w-[31px]',
    boxHeight: 'h-[30px]',
    pointerOffset: 'right-[86px]',
  },
];

const heatmapPositionStyles = [
  {
    variant: 'top',
    panelPosition: 'bottom-[92px]',
    pointerPosition: '-bottom-[9px] rotate-45',
  },
  {
    variant: 'bottom',
    panelPosition: 'top-[102px]',
    pointerPosition: '-top-[9px] rotate-[225deg]',
  },
];

const spacing = (variantSize) =>
  heatmapSizeStyles.find((style) => variantSize === style.variant).spacing;

const boxWidth = (variantSize) =>
  heatmapSizeStyles.find((style) => variantSize === style.variant).boxWidth;

const boxHeight = (variantSize) =>
  heatmapSizeStyles.find((style) => variantSize === style.variant).boxHeight;

const pointerOffset = (variantSize) =>
  heatmapSizeStyles.find((style) => variantSize === style.variant).pointerOffset;

const panelPosition = (variantPosition) =>
  heatmapPositionStyles.find((style) => variantPosition === style.variant).panelPosition;

const pointerPosition = (variantPosition) =>
  heatmapPositionStyles.find((style) => variantPosition === style.variant).pointerPosition;

const Heatmap = ({
  heatmapData,
  variantSize,
  variantPosition,
  purchaseOrders,
  draftPurchaseOrders,
}) => {
  const [heatmapCurrentData, setHeatmapCurrentData] = useState([]);

  useEffect(() => {
    const updatedHeatmap = addCountsToHeatmap(
      heatmapData || [],
      draftPurchaseOrders || {},
      purchaseOrders,
    );
    setHeatmapCurrentData(updatedHeatmap);
  }, [heatmapData, purchaseOrders, draftPurchaseOrders]);

  return (
    <>
      <div className={`flex ${spacing(variantSize)}`}>
        {heatmapCurrentData.map(
          ({ date, percentage, purchaseOrderCount, draftPurchaseOrderCount, outOfStock }) => (
            <Popover className="relative" key={date}>
              <Popover.Button
                className={`${bgColorClass(percentage)} ${borderColorClass(
                  percentage,
                )} h-24 rounded border-2 text-sm font-bold uppercase outline-none ${textColorClass(
                  percentage,
                )} ${boxWidth(variantSize)} ${boxHeight(
                  variantSize,
                )} flex flex-col items-center justify-between pt-2`}
                role="cell"
              >
                {variantSize !== 'small' && (
                  <>
                    <div className="leading-none">
                      {new Date(date).toLocaleDateString(navigator.language, {
                        month: 'short',
                        timeZone: 'UTC',
                      })}
                    </div>
                    <span
                      className={`${dateBgColorClass(
                        percentage,
                      )} flex h-7 w-7 items-center justify-center rounded-full`}
                    >
                      {purchaseOrderCount}
                    </span>
                    <div className={`${ctaBgColorClass(percentage)} w-full py-1`}>
                      <FiPlus className={`${ctaTextColorClass(percentage)} mx-auto`} />
                    </div>
                  </>
                )}
              </Popover.Button>
              <Popover.Panel
                className={`${borderColorClass(
                  percentage,
                )} absolute -right-20 z-10 w-96 rounded border-2 bg-white p-6 text-sm text-midnight-100 shadow ${panelPosition(
                  variantPosition,
                )}`}
              >
                {({ close }) => (
                  <>
                    <Tag>
                      {new Date(date).toLocaleDateString(navigator.language, {
                        month: 'short',
                        year: '2-digit',
                        timeZone: 'UTC',
                      })}
                    </Tag>
                    <Popover.Button
                      onClick={close}
                      className="group absolute right-3 top-3 flex h-6 w-6 items-center justify-center rounded-full hover:bg-midnight-10 active:bg-midnight-75"
                    >
                      <FiX className="stroke-gray-100 group-active:stroke-gray-10" />
                    </Popover.Button>
                    <p className="mt-8 font-bold">{status(percentage)}</p>

                    <div className="mt-6 rounded border-2 border-purple-75 bg-purple-10 px-3 py-4">
                      <div className="font-bold text-purple-100">Current Orders</div>
                      <div className="mt-2 flex items-baseline">
                        <div className="text-lg font-bold">{draftPurchaseOrderCount}</div>
                        <div className="ml-1">
                          {pluralize('Purchase Order', draftPurchaseOrderCount)} Drafted
                        </div>
                      </div>
                      <div className="mt-2 flex items-baseline">
                        <div className="text-lg font-bold">{purchaseOrderCount}</div>
                        <div className="ml-1">
                          {pluralize('Purchase Order', purchaseOrderCount)} Incoming
                        </div>
                      </div>
                    </div>

                    <p className="mt-3 text-gray-100">
                      <span className="font-bold">{outOfStock?.skusCount || 0} SKUs</span>
                      &nbsp;from&nbsp;
                      {outOfStock?.vendors?.length || 0} different vendors are expected to be out of
                      stock.
                    </p>

                    <div className="mt-6 flex justify-end space-x-2">
                      <Button slim label="Cancel" variant="text" onClick={close} />
                      <Button slim label="Create Purchase Order" href="/purchase-orders" />
                    </div>

                    <div
                      className={`absolute h-4 w-4 border-b-2 border-r-2 bg-white ${borderColorClass(
                        percentage,
                      )} ${pointerOffset(variantSize)} ${pointerPosition(variantPosition)}`}
                    />
                  </>
                )}
              </Popover.Panel>
            </Popover>
          ),
        )}
      </div>
      <div className={cn('flex justify-end items-center mt-2', variantSize === 'medium' && 'mr-5')}>
        <div
          className={cn('flex justify-end items-center mt-2', variantSize === 'medium' && 'mr-5')}
        >
          <div className="flex space-x-1">
            <div
              className="h-2 w-2 rounded-sm border border-red-100 bg-red-75"
              title="Inadequate stock levels across all products"
            />
            <div
              className="h-2 w-2 rounded-sm border border-red-75 bg-red-10"
              title="Poor stock levels across all products"
            />
            <div
              className="h-2 w-2 rounded-sm border border-yellow-100 bg-yellow-10"
              title="Moderate stock levels across all products"
            />
            <div
              className="h-2 w-2 rounded-sm border border-leafy-75 bg-leafy-10"
              title="Good stock levels across all products"
            />
            <div
              className="h-2 w-2 rounded-sm border border-leafy-100 bg-leafy-75"
              title="Excellent stock levels across all products"
            />
          </div>
          <span className="ml-2 text-xs text-gray-100">Inventory Levels</span>
        </div>
      </div>
    </>
  );
};

Heatmap.propTypes = {
  heatmapData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      percentage: PropTypes.number,
    }),
  ).isRequired,
  variantSize: PropTypes.oneOf(['small', 'medium', 'large']),
  variantPosition: PropTypes.oneOf(['top', 'bottom']),
  purchaseOrders: PropTypes.shape({}),
  draftPurchaseOrders: PropTypes.shape({}),
};

Heatmap.defaultProps = {
  variantSize: 'large',
  variantPosition: 'top',
  purchaseOrders: {},
  draftPurchaseOrders: {},
};

export default Heatmap;
