import React from 'react';
import PropTypes from 'prop-types';
import { useAccount } from '../../../AccountProvider.jsx';
import { SkeletonBlock } from '../../../Components/Skeletons.jsx';
import GrowthGraph from './GrowthGraph.jsx';

const Index = ({ growthModel, loading }) => {
  const { pastPredictions = {} } = growthModel;
  const { formatCurrency } = useAccount();

  const forecastedRevenue = pastPredictions?.forecastedRevenue;
  const actualRevenue = pastPredictions?.actualRevenue;

  const growthChartData = [
    {
      monthlySales: actualRevenue,
      name: ['Actual', ' Revenue'],
    },
    {
      monthlySales: forecastedRevenue,
      name: ['Forecasted', ' Revenue'],
    },
  ];

  const growthInferior = {
    title: 'Missed growth',
    text: 'of missed revenue in past 6 months 💸',
    revenue: forecastedRevenue - actualRevenue,
  };

  const growthSuperior = {
    title: 'Recent Growth',
    text: "beyond expectations! You're on track 😎",
    revenue: actualRevenue - forecastedRevenue,
  };

  const growth = actualRevenue > forecastedRevenue ? growthSuperior : growthInferior;

  return (
    <div className="h-full rounded bg-purple-110 text-midnight-100 shadow">
      <div className="flex h-full flex-col px-4 py-2">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-bold text-white">{growth.title}</h3>
          <div className="text-xs font-bold text-white">
            {new Date(pastPredictions?.dateFrom)?.toLocaleDateString(navigator.language, {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              timeZone: 'UTC',
            })}{' '}
            -{' '}
            {new Date(pastPredictions?.dateTo)?.toLocaleDateString(navigator.language, {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              timeZone: 'UTC',
            })}{' '}
          </div>
        </div>
        {loading && <SkeletonBlock className="mt-6 h-48" />}
        {!loading && (
          <>
            <div className="mt-6 grid grid-cols-2 gap-x-5 text-sm">
              <GrowthGraph growthChartData={growthChartData} />
              <div className="text-white">
                <div className="w-fit rounded bg-white px-2 text-3xl font-bold text-purple-100">
                  <span className="vertical-fix">
                    {formatCurrency(growth.revenue, {
                      notation: 'compact',
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <div className="mt-2">{growth.text}</div>
              </div>
            </div>
            <div className="-mt-16 ml-[15%] text-2xl font-bold text-white">VS</div>
          </>
        )}
      </div>
    </div>
  );
};

Index.propTypes = {
  growthModel: PropTypes.shape({
    pastPredictions: PropTypes.shape({}),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Index;
