export const adSpendIncrease = {
  value: 'Ad Spend Increase',
  isReadOnly: false,
};

export const newChannel = {
  value: 'New channel',
  isReadOnly: false,
};

export const influencer = {
  value: 'Influencer',
  isReadOnly: false,
};

export const discountedPromo = {
  value: 'Discounted Promo',
  isReadOnly: false,
};

export const productLaunch = {
  value: 'New Product Launch',
  isReadOnly: false,
};

export const emailCampaign = {
  value: 'Email Campaign',
  isReadOnly: false,
};

export const smsCampaign = {
  value: 'SMS Campaign',
  isReadOnly: false,
};

export const newAdCreative = {
  value: 'New Ad Campaign / Creative',
  isReadOnly: false,
};

export const tvCampaign = {
  value: 'TV Campaign',
  isReadOnly: false,
};

export const marketingEventTypes = {
  adSpendIncrease,
  newChannel,
  influencer,
  discountedPromo,
  productLaunch,
  emailCampaign,
  smsCampaign,
  newAdCreative,
  tvCampaign,
};

export default {
  marketingEventTypes,
};
