import marketplaces from '../../../shared/amazon/marketplaces.js';

/**
 *
 * @param {Object} source
 */

export const formatSourceName = (source) => {
  if (source.sourceType === 'skubana') return 'Extensiv Order Manager';
  if (source.sourceType === 'cin7') return 'Cin7 Omni';

  const idPattern = /^(.+?)-/;
  if (source.sourceType !== 'amazon' || !source.sourceId?.match(idPattern))
    return source.sourceType;
  const marketplaceId = source.sourceId?.match(idPattern)[1];
  const amazonMarketplace = marketplaces.find((m) => m.id === marketplaceId);
  return amazonMarketplace.name && `Amazon - ${amazonMarketplace.name}`;
};
