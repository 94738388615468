import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import classnames from 'classnames';
import { useCombobox } from 'downshift';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useDebounce } from '../Hooks/index.js';

const MIN_SEARCH_TEXT_LENGTH = 3;

const ComboBox = ({
  results,
  onSelectedItemChange,
  doSearch,
  listItem: ListItem,
  containerClassName,
  displayToggle,
  selectedItemIndex,
  scrollToResults,
  initialSearchText,
  itemToString,
  searchOnInitialInput,
  listClassName,
  itemToId,
  onReset,
}) => {
  const [searchText, setSearchText] = useState('');
  const [defaultText, setDefaultText] = useState('');
  const [inputChanged, setInputChanged] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [defaultIndex, setDefaultIndex] = useState(-1);
  const searchRef = useRef();

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: results,
    defaultHighlightedIndex: selectedIndex,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...changes,
            isOpen: true,
          };
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          setSearchText(defaultText);
          if (onReset) {
            onReset();
          }
          return {
            ...changes,
            highlightedIndex: defaultIndex,
          };
        case useCombobox.stateChangeTypes.InputBlur:
          if (!searchText) {
            setSearchText(defaultText);
            if (onReset) {
              onReset();
            }
            return {
              ...changes,
              highlightedIndex: defaultIndex,
            };
          }
          return changes;
        case useCombobox.stateChangeTypes.ItemClick:
          if (
            changes.highlightedIndex === defaultIndex &&
            results?.indexOf(changes.selectedItem) === defaultIndex
          ) {
            // if the item clicked is the default selected result item,
            // then do not reload search results
            setInputChanged(false);
          }
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
          };
        case useCombobox.stateChangeTypes.InputChange:
          setInputChanged(true);
          setSearchText(changes.inputValue);
          return changes;
        default:
          return changes;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      setInputChanged(true);
      setSearchText(inputValue);
    },
    onSelectedItemChange: ({ selectedItem, inputValue }) => {
      onSelectedItemChange(selectedItem);
      setSearchText(inputValue);
    },
    onIsOpenChange: () => {
      if (scrollToResults) {
        scrollIntoView(searchRef.current, {
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    itemToString: (item) => (item ? itemToString(item) : ''),
  });

  const [debouncedSearchTerm] = useDebounce(searchText, 100);

  React.useEffect(() => {
    if (debouncedSearchTerm?.length > 0 && debouncedSearchTerm?.length < MIN_SEARCH_TEXT_LENGTH) {
      return;
    }
    if (!inputChanged && !searchOnInitialInput) {
      return;
    }
    doSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  React.useEffect(() => {
    setSelectedIndex(selectedItemIndex);
    if (!searchText && !inputChanged) {
      setSearchText(initialSearchText);
      setDefaultText(initialSearchText);
      if (selectedItemIndex !== null && selectedItemIndex >= 0) {
        setDefaultIndex(selectedItemIndex);
      }
    }
  }, [selectedItemIndex, initialSearchText]);

  return (
    <div className={`relative pt-3 text-xs ${containerClassName}`} ref={searchRef}>
      <div className="relative flex">
        <input
          {...getInputProps()}
          type="text"
          placeholder="Start typing to search..."
          className="w-full rounded-lg border border-gray-50 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100"
          value={searchText}
        />
        {displayToggle && (
          <button
            type="button"
            {...getToggleButtonProps()}
            aria-label="toggle menu"
            className="absolute right-2 top-2"
          >
            {isOpen && (
              <FiChevronUp className="stroke-gray-100 group-active:text-gray-10" size={16} />
            )}
            {!isOpen && (
              <FiChevronDown className="stroke-gray-100 group-active:text-gray-10" size={16} />
            )}
          </button>
        )}
      </div>
      <ul
        {...getMenuProps()}
        className={classnames(
          isOpen && 'mb-8 max-h-52 overflow-y-auto border border-gray-50',
          listClassName,
          'absolute inset-x-0 z-10 rounded-lg bg-white shadow-lg',
        )}
      >
        {isOpen &&
          results?.map((item, index) => (
            <ListItem
              key={itemToId(item)}
              item={item}
              index={index}
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
            />
          ))}
      </ul>
    </div>
  );
};

ComboBox.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectedItemChange: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
  listItem: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  listClassName: PropTypes.string,
  displayToggle: PropTypes.bool,
  selectedItemIndex: PropTypes.number,
  scrollToResults: PropTypes.bool,
  initialSearchText: PropTypes.string,
  itemToString: PropTypes.func.isRequired,
  itemToId: PropTypes.func.isRequired,
  searchOnInitialInput: PropTypes.bool,
  onReset: PropTypes.func,
};

ComboBox.defaultProps = {
  results: null,
  containerClassName: '',
  listClassName: '',
  displayToggle: true,
  selectedItemIndex: -1,
  scrollToResults: false,
  initialSearchText: '',
  searchOnInitialInput: false,
  onReset: null,
};

export default ComboBox;
