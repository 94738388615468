import { isSameMonth } from 'date-fns';

/**
 * Merges purchase order data with heatmap data to add a count of purchase orders
 * @param {Array} heatmap
 * @param {Object} purchaseOrderData
 * @returns {Array}
 */
export const addCountsToHeatmap = (heatmap, draftPurchaseOrderData, purchaseOrderData = {}) => {
  const poMonths =
    purchaseOrderData?.purchaseOrders?.edges.map(({ node }) => node.expectedDeliveryDate) || [];
  const draftPoMonths =
    draftPurchaseOrderData?.purchaseOrders?.edges.map(({ node }) => node.expectedDeliveryDate) ||
    [];
  return heatmap.map((hm) => {
    const sameMonthPO = poMonths?.filter((date) => isSameMonth(new Date(date), new Date(hm.date)));
    const sameMonthDraftPO = draftPoMonths?.filter((date) =>
      isSameMonth(new Date(date), new Date(hm.date)),
    );

    return {
      ...hm,
      purchaseOrderCount: sameMonthPO?.length,
      draftPurchaseOrderCount: sameMonthDraftPO?.length || 0,
    };
  });
};
