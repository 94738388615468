/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiCheck, FiXCircle } from 'react-icons/fi';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import EmptyTable from '../Components/EmptyTable.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { PrevNextButtons } from '../Components/Pagination.jsx';
import SearchInput from '../Components/SearchInput.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import ForecastRun from './ForecastRun.jsx';

export const ACCOUNTS = gql`
  query GetAccounts($after: String, $before: String, $search: String) {
    accounts(after: $after, before: $before, first: 20, search: $search) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          _id
          name
          url
          isSubscribed
          billing {
            platform
          }
          forecast {
            initialDataset
            datasetGroupArn
            datasetMetadataArn
            datasetImportJobArn
            metadataImportJobArn
            predictorArn
            forecastArn
            forecastExportArn
            forecastType
            backtestExportJobArn
            status
            accuracyMetrics
            errorMessage
            startedAt
            completedAt
          }
          preferences {
            forecastEnabled
          }
        }
      }
    }
  }
`;

const SWITCH_BILLING_PLATFORM = gql`
  mutation SwitchBillingPlatform($newPlatform: String!, $accountId: String!) {
    switchBillingPlatform(newPlatform: $newPlatform, accountId: $accountId) {
      isSubscribed
    }
  }
`;

const AccountRow = ({ account, onChange }) => {
  const { addAlert } = useAlerts();
  const { account: currentAccount, axios } = useAccount();

  const [switchBillingPlatform] = useMutation(SWITCH_BILLING_PLATFORM);

  const [loading, setLoading] = React.useState(false);
  const [platform, setPlatform] = React.useState(account.billing.platform);

  const handlePlatformChangeSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      setLoading(true);

      const doSubmit = async () => {
        try {
          await switchBillingPlatform({
            variables: {
              newPlatform: platform,
              accountId: account._id,
            },
          });
          onChange();
        } catch (error) {
          addAlert('An error has occurred! Please refresh the page and try again.', { error });
        }

        setLoading(false);
      };

      doSubmit();
    },
    [platform, account._id],
  );

  const switchAccount = async (accountId) => {
    const {
      data: { token },
    } = await axios(`/auth/switch/${accountId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });

    window.localStorage.setItem('token', token);
    window.location.assign('/');
  };

  return (
    <tr>
      <td className="py-2 align-top text-xs">
        <strong>{account.name}</strong>
        <br />
        {account._id}
        {account._id !== currentAccount._id && (
          <div className="mt-4">
            <Button
              label="Impersonate"
              type="button"
              onClick={() => switchAccount(account._id)}
              slim
            />
          </div>
        )}
      </td>
      <td className="py-2 align-top text-xs">
        <strong>Platform:</strong> {account.billing?.platform}
        <br />
        <strong>Is subscribed:</strong>&nbsp;
        {account.isSubscribed ? (
          <FiCheck className="inline text-leafy-100" />
        ) : (
          <FiXCircle className="inline text-red-100" />
        )}
        <br />
        <form className="mt-4 text-xs" onSubmit={handlePlatformChangeSubmit}>
          <div className="mt-4 w-2/3">
            <label htmlFor="platform">
              <strong>Billing platform:</strong>
              <select
                id="platform"
                className="input py-2"
                name="platform"
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
              >
                <option value="manual">Manual</option>
                <option value="stripe">Stripe</option>
                <option value="shopify">Shopify</option>
              </select>
            </label>
          </div>

          <div className="mt-4">
            <Button
              label="Switch billing platform"
              type="submit"
              disabled={account.billing.platform === platform || loading}
              slim
            />
          </div>
        </form>
      </td>
      <td className="py-2 align-top text-xs">
        {account.forecast && account.preferences?.forecastEnabled !== false && (
          <ForecastRun forecastRun={account.forecast} />
        )}
      </td>
    </tr>
  );
};

AccountRow.propTypes = {
  account: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isSubscribed: PropTypes.bool.isRequired,
    forecast: PropTypes.object.isRequired,
    preferences: PropTypes.shape({
      forecastEnabled: PropTypes.bool,
    }).isRequired,
    billing: PropTypes.shape({
      platform: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const AccountsTable = ({ accounts, pageInfo, onAccountsChange }) => (
  <div className="grid grid-cols-12 gap-x-6">
    <div className="col-span-11">
      <div className="mt-6 rounded-lg bg-white px-6 pb-7 pt-5 text-midnight-100 shadow">
        <h5 className="mb-3 border-b border-gray-50 pb-4 text-sm font-bold">Accounts</h5>
        <table className="min-w-full table-fixed">
          <thead>
            <tr className="text-left">
              <th className="w-1/4 pb-9 text-xs font-normal">Account</th>
              <th className="w-1/4 pb-9 text-xs font-normal">Billing</th>
              <th className="w-2/4 pb-9 text-xs font-normal">Forecast Status</th>
            </tr>
          </thead>
          <tbody className="mt-9">
            {accounts.map(({ node: account }) => (
              <AccountRow key={account._id} account={account} onChange={onAccountsChange} />
            ))}
          </tbody>
        </table>
      </div>
      <PrevNextButtons edges={accounts} pageInfo={pageInfo} />
    </div>
  </div>
);

AccountsTable.propTypes = {
  accounts: PropTypes.array.isRequired,
  pageInfo: PropTypes.shape({
    hasPreviousPage: PropTypes.bool.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
  }).isRequired,
  onAccountsChange: PropTypes.func.isRequired,
};

const List = () => {
  useDocumentTitle('Accounts');

  const { search } = useLocation();

  const [variables, setVariables] = React.useState({});

  const { loading, data, refetch } = useQuery(ACCOUNTS, { variables });

  const navigate = useNavigate();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const handleSearch = React.useCallback(
    (search) => {
      query.delete('before');
      query.delete('after');
      query.set('search', search);

      navigate(`/admin/accounts?${query.toString()}`);
    },
    [query],
  );

  React.useEffect(() => {
    setVariables({
      ...variables,
      after: query.get('after'),
      before: query.get('before'),
      search: query.get('search'),
    });
  }, [search]);

  const onAccountsChange = React.useCallback(() => {
    refetch();
  }, []);

  return (
    <>
      <PageHeader text="Accounts" />

      <div className="mt-16 grid grid-cols-12">
        <div className="col-span-11 mt-8 flex justify-end">
          <SearchInput handleSearch={handleSearch} placeHolder="Search accounts" />
        </div>
      </div>

      {loading && <Spinner />}

      {!loading && data?.accounts?.edges?.length === 0 && (
        <div className="col-span-11 mt-8">
          <EmptyTable title="Accounts" bodyText="There are no accounts" />
        </div>
      )}
      {!loading && data?.accounts?.edges?.length > 0 && (
        <AccountsTable
          accounts={data.accounts.edges}
          pageInfo={data.accounts.pageInfo}
          onAccountsChange={onAccountsChange}
        />
      )}
    </>
  );
};

export default List;
