import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { FaShopify } from 'react-icons/fa';
import { FiAlertTriangle, FiCheck, FiEdit2, FiExternalLink, FiInfo, FiX } from 'react-icons/fi';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { useAccount } from '../../AccountProvider.jsx';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import { useDemo } from '../../Components/DemoProvider.jsx';
import InputErrorMessage from '../../Components/InputErrorMessage.jsx';
import Tooltip from '../../Components/Tooltip.jsx';
import Panel from '../../Dashboard/Widgets/Widget.jsx';
import { getDemoSingleSku } from '../../Demo/skus.js';
import {
  AmazonIcon,
  AnvylIcon,
  Cin7Icon,
  ExtensivIcon,
  FlexeIcon,
  FulfilIcon,
  LinnworksIcon,
  ShipbobIcon,
  ShipheroIcon,
} from '../../Icons/index.js';
import { compactNumber } from '../../Planning/PlannedProducts/Index.jsx';
import DaysLeft from '../DaysLeft.jsx';
import { calculateLostSales, calculateOutOfStockDays } from './utils.js';

const UPDATE_SKU_LEAD_TIME = gql`
  mutation UpdateSkuLeadTime($sku: String!, $leadTime: Int) {
    updateSkuLeadTime(sku: $sku, leadTime: $leadTime) {
      sku
      leadTime
    }
  }
`;

const iconMap = {
  amazon: <AmazonIcon />,
  anvyl: <AnvylIcon />,
  cin7: <Cin7Icon />,
  flexe: <FlexeIcon />,
  fulfil: <FulfilIcon />,
  linnworks: <LinnworksIcon />,
  shipbob: <ShipbobIcon />,
  shiphero: <ShipheroIcon />,
  skubana: <ExtensivIcon />,
  shopify: <FaShopify />,
};

const LocationIcon = ({ sourceType }) => iconMap[sourceType];

LocationIcon.propTypes = {
  sourceType: PropTypes.string.isRequired,
};

const RedHighlight = ({ children = null }) => (
  <span className="-mt-[3px] inline-block rounded bg-red-75 p-1.5 font-bold tabular-nums leading-none text-red-100">
    {children}
  </span>
);

RedHighlight.propTypes = {
  children: PropTypes.node.isRequired,
};

const LocationReplenishmentRow = ({ sku, location }) => {
  const { formatCurrency, account, locations: accountLocations } = useAccount();

  let locations = accountLocations;
  let sourceId;
  let sourceType;

  // Demo skus do not have an account locations counterpart, so adding a fallback here
  if (!account.onboarding?.hasSkusSyncCompleted) {
    locations = getDemoSingleSku(sku.sku).locations;
    sourceId = sku.sources[0].sourceId;
    sourceType = sku.sources[0].sourceType;
  } else {
    sourceId = locations.edges.find(({ node: l }) => l._id === location._id).node.sources[0]
      .sourceId;

    sourceType = account.sources.find((source) => source.sourceId === sourceId).sourceType;
  }

  const outOfStockDays = calculateOutOfStockDays(sku, [location]);
  const expectedRevenueLoss = calculateLostSales(sku, [location]) * sku.price;

  return (
    <tr
      key={location._id}
      className={classnames('group contents', location.hideReplenishment && 'text-gray-100')}
    >
      <td className="flex items-center space-x-1 rounded-l py-2 pl-4 pr-1 text-left text-base font-bold group-hover:bg-leafy-10">
        <span className="opacity-60">
          <LocationIcon sourceType={sourceType} />
        </span>
        <span className="text-xs">{location.name}</span>
      </td>
      <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
        {location.sourceCount > 1 ? (
          <Tooltip className="mr-1">
            <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-yellow-75">
              <FiAlertTriangle className="stroke-yellow-100" size={10} />
            </Tooltip.Element>
            <Tooltip.Body align="left">
              <div>
                Includes inventory from {location.sourceCount} variants. You may have multiple
                products that share this SKU.
              </div>
            </Tooltip.Body>
          </Tooltip>
        ) : null}
        {location.inventoryQuantity ?? '-'}
      </td>
      <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
        {location.targetBundleInventoryQuantity ? (
          <Tooltip className="mr-1">
            <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-gray-50">
              <FiInfo className="stroke-gray-100" size={10} />
            </Tooltip.Element>
            <Tooltip.Body align="right">
              <div>Includes Bundle target: {location.targetBundleInventoryQuantity || 0}</div>
            </Tooltip.Body>
          </Tooltip>
        ) : null}
        {location.targetInventoryQuantity ?? '-'}
      </td>
      <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
        {location.inProductionInventoryQuantity ?? '-'}
      </td>
      <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
        {location.incomingInventoryQuantity ?? '-'}
      </td>
      <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
        <DaysLeft daysLeft={location.daysLeft} />
      </td>
      <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
        {outOfStockDays > 0 ? (
          <RedHighlight>{compactNumber(outOfStockDays)}</RedHighlight>
        ) : (
          <span>-</span>
        )}
      </td>
      <td className="flex items-center justify-end rounded-r py-2 pl-1 pr-4 group-hover:bg-leafy-10">
        {expectedRevenueLoss > 0 ? (
          <RedHighlight>
            {formatCurrency(expectedRevenueLoss, { notation: 'compact' })}
          </RedHighlight>
        ) : (
          <span>-</span>
        )}
      </td>
    </tr>
  );
};

LocationReplenishmentRow.propTypes = {
  sku: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

const ReplenishmentTable = ({ data, expectedRevenueLoss, locations }) => {
  const { account, formatCurrency } = useAccount();
  const { isDemo } = useDemo();
  const location = useLocation();
  const { pathname } = location;
  const { addAlert } = useAlerts();
  const [updateSkuLeadTime] = useMutation(UPDATE_SKU_LEAD_TIME);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({ mode: 'all' });

  const onSubmit = async (values) => {
    await updateSkuLeadTime({
      variables: {
        leadTime: Number(values.leadTime) || null,
        sku: data.sku.sku,
      },
    });
    addAlert('Changes saved successfully', { level: 'success' });
    reset(values);
  };

  const outOfStockDays = React.useMemo(() => {
    if (!data?.sku) return 0;
    return calculateOutOfStockDays(data.sku, data.sku.inventoryBreakdown);
  }, [data]);

  return (
    <Panel barColor="gradient" className="mt-6 h-auto">
      {data.sku.isBundle && data.sku.bundledSkus.length ? (
        <div className="text-sm">
          <div className="flex flex-col">
            <span className="mb-3 border-b border-gray-50 pb-3">
              This product is a bundle. Replenishment stats are available in its constituent
              products:
            </span>
          </div>
          <table className="-mx-1">
            <thead>
              <tr>
                <th className="pb-1 pl-2 text-left font-normal">SKU</th>
                <th className="pl-6 pr-1 text-right font-normal">Bundled Units</th>
              </tr>
            </thead>
            <tbody>
              {data.sku.bundledSkus?.map((bundledSku) => (
                <tr key={bundledSku.sku} className="hover:bg-gray-10">
                  <td className="rounded-l">
                    <Link to={`/skus/${bundledSku.sku}`} state={{ fromPath: pathname }}>
                      <div className="flex items-center">
                        <div
                          className={classnames(
                            isDemo && 'blur',
                            'my-1 ml-1 min-w-0 flex-1 truncate rounded-l bg-leafy-10 py-px',
                          )}
                          title={bundledSku.sku}
                        >
                          <span className="ml-1">{bundledSku.sku}</span>
                        </div>
                        <div className="rounded-r bg-leafy-10 p-1">
                          <FiExternalLink className="text-gray-100" />
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td className="rounded-r pl-6 pr-1 text-right">{bundledSku.bundledQuantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <Panel.Title className="text-sm font-bold">
              Replenishment
              <span className="ml-1 text-xs text-gray-100">
                (Next {account.preferences.forecastOptions?.horizon || 60} Days)
              </span>
            </Panel.Title>

            <div className="text-xs">
              {data.sku.canEditLeadTime ? (
                <form onSubmit={handleSubmit(onSubmit)} className="relative flex items-center">
                  <label htmlFor="leadTime" className="flex items-center">
                    <span className="mr-1 font-bold">Lead Time (days)</span>

                    <Tooltip className="ml-1">
                      <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-gray-50">
                        <FiInfo className="stroke-gray-100" size={10} />
                      </Tooltip.Element>
                      <Tooltip.Body align="right">
                        Specifying product Order Lead Time will override
                        {data.sku.vendor?.leadTime
                          ? ` Vendor Order Lead Time (${data.sku.vendor?.leadTime} days)`
                          : ` Average Order Lead Time (${account.preferences.averageOrderLeadTime} days)`}
                      </Tooltip.Body>
                    </Tooltip>

                    <div className="relative ml-2 w-18">
                      <input
                        type="number"
                        id="leadTime"
                        className={`w-full rounded border border-gray-50 py-1 pl-3 pr-6 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100 ${
                          errors.leadTime && 'ring-2 ring-red-100'
                        }`}
                        placeholder={
                          data.sku.leadTime ??
                          data.sku.vendor?.leadTime ??
                          account.preferences?.averageOrderLeadTime
                        }
                        defaultValue={data.sku.leadTime}
                        {...register('leadTime', {
                          min: {
                            value: 0,
                            message: "SKU order lead time value can't be negative",
                          },
                        })}
                      />
                      <FiEdit2 className="absolute right-2 top-2 text-gray-100" />
                    </div>
                  </label>
                  <div className="absolute -top-7 right-0 whitespace-nowrap text-right">
                    <InputErrorMessage message={errors.leadTime?.message} />
                  </div>
                  {isDirty && (
                    <div className="ml-2">
                      <button
                        type="button"
                        title="Cancel"
                        className="rounded-full bg-purple-10 p-1 text-purple-100"
                        onClick={() => reset()}
                      >
                        <FiX />
                      </button>
                      <button
                        type="submit"
                        title="Save"
                        className="ml-2 rounded-full bg-purple-100 p-1 text-white"
                      >
                        <FiCheck />
                      </button>
                    </div>
                  )}
                </form>
              ) : (
                <div className="flex items-center font-bold">
                  <span className="mr-1 capitalize">Lead Time</span>
                  <span className="ml-auto">
                    {data.sku.leadTime ||
                      data.sku.vendor?.leadTime ||
                      account.preferences.averageOrderLeadTime}
                    &nbsp; days
                  </span>
                  {!data.sku.leadTime && (
                    <Tooltip className="ml-1">
                      <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-gray-50">
                        <FiInfo className="stroke-gray-100" size={10} />
                      </Tooltip.Element>
                      <Tooltip.Body align="right">
                        <span>
                          Since product Order Lead Time is not provided from this products source,
                          Lead Time is set by&nbsp;
                          {data.sku.vendor?.leadTime
                            ? ' Vendor Order Lead Time'
                            : ' Average Order Lead Time'}
                          .
                        </span>
                      </Tooltip.Body>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="-m-4">
            <table className="mt-8 grid w-full grid-cols-[1.6fr,1fr,0.8fr,0.7fr,0.7fr,0.7fr,1fr,1.3fr] text-right text-xs">
              <thead className="contents">
                <tr className="contents">
                  <th className="px-1 py-2 pl-4 text-left">Location</th>
                  <th className="px-1 py-2">Units on Hand</th>
                  <th className="px-1 py-2">Units Target</th>
                  <th className="px-1 py-2">In Production</th>
                  <th className="px-1 py-2">In Transit</th>
                  <th className="px-1 py-2">Days Left</th>
                  <th className="px-1 py-2">OOS Days Expected</th>
                  <th className="px-1 py-2 pr-4">Expected Revenue Loss</th>
                </tr>
              </thead>

              <tbody className="contents">
                {[...data.sku.inventoryBreakdown]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((location) => (
                    <LocationReplenishmentRow
                      location={{
                        ...location,
                        name: locations.edges.find(({ node: { _id } }) => _id === location._id).node
                          .name,
                        isShippingEnabled: locations.edges.find(
                          ({ node: { _id } }) => _id === location._id,
                        ).node.isShippingEnabled,
                      }}
                      sku={data.sku}
                      key={location._id}
                    />
                  ))}
                <tr className={classnames('group contents font-bold')}>
                  <td className="flex items-center rounded-l py-2 pl-4 pr-1 text-left group-hover:bg-leafy-10">
                    All Locations
                  </td>
                  <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
                    {data.sku.inventoryQuantity}
                  </td>
                  <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
                    {!!data.sku.stats?.targetBundleInventoryQuantity && (
                      <Tooltip className="mr-1">
                        <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-gray-50">
                          <FiInfo className="stroke-gray-100" size={10} />
                        </Tooltip.Element>
                        <Tooltip.Body align="right">
                          <div data-testid="allLocationsUnitsOnHandTooltip">
                            Includes Bundle target:&nbsp;
                            {data.sku.stats.targetBundleInventoryQuantity}
                          </div>
                        </Tooltip.Body>
                      </Tooltip>
                    )}
                    {data.sku.stats ? data.sku.stats.targetInventoryQuantity : 0}
                  </td>
                  <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
                    {data.sku.stats?.inProductionInventoryQuantity ?? 0}
                  </td>
                  <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
                    {data.sku.stats?.incomingInventoryQuantity ?? 0}
                  </td>
                  <td className="px-1 py-2 group-hover:bg-leafy-10">&nbsp;</td>
                  <td className="flex items-center justify-end px-1 py-2 group-hover:bg-leafy-10">
                    {outOfStockDays > 0 ? (
                      <RedHighlight>{outOfStockDays}</RedHighlight>
                    ) : (
                      <span>0</span>
                    )}
                  </td>
                  <td className="flex items-center justify-end rounded-r py-2 pl-1 pr-4 group-hover:bg-leafy-10">
                    {expectedRevenueLoss > 0 ? (
                      <RedHighlight>
                        {formatCurrency(expectedRevenueLoss, { notation: 'compact' })}
                      </RedHighlight>
                    ) : (
                      <span>{formatCurrency(0, { notation: 'compact' })}</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </Panel>
  );
};

ReplenishmentTable.propTypes = {
  data: PropTypes.shape({
    sku: PropTypes.shape({
      bundledSkus: PropTypes.arrayOf(PropTypes.shape({})),
      canEditLeadTime: PropTypes.bool,
      inventoryBreakdown: PropTypes.arrayOf(PropTypes.shape({})),
      inventoryQuantity: PropTypes.number,
      isBundle: PropTypes.bool,
      isOnBackorder: PropTypes.bool,
      leadTime: PropTypes.number,
      stats: PropTypes.shape({
        incomingInventoryQuantity: PropTypes.number,
        inProductionInventoryQuantity: PropTypes.number,
        targetBundleInventoryQuantity: PropTypes.number,
        targetInventoryQuantity: PropTypes.number,
      }),
      sku: PropTypes.string,
      vendor: PropTypes.shape({
        leadTime: PropTypes.number,
      }),
    }),
  }).isRequired,
  expectedRevenueLoss: PropTypes.number.isRequired,
  locations: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ReplenishmentTable;
