import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const CONNECT_WITH_MAYPLE = gql`
  mutation ConnectWithMayple($token: String!) {
    connectWithMayple(token: $token) {
      _id
    }
  }
`;

const MayplePopup = ({ open, onClose }) => {
  const { addAlert } = useAlerts();
  const [connectWithMayple, { loading }] = useMutation(CONNECT_WITH_MAYPLE);

  const handleMaypleConnect = React.useCallback(
    (e) => {
      e.preventDefault();

      const variables = Object.fromEntries(new FormData(document.querySelector('form')));

      const doConnect = async () => {
        await connectWithMayple({ variables });
        addAlert('Successfully connected to Mayple', { level: 'success' });
        onClose();
      };

      doConnect();
    },
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Mayple</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleMaypleConnect}>
            <div>
              <p className="mt-2 text-gray-100">
                In order to integrate with Mayple, you will need a token.
              </p>

              <div className="mt-2">
                <label htmlFor="mayple-token">
                  Mayple Token*
                  <input type="text" id="mayple-token" required className="input" name="token" />
                </label>
              </div>
            </div>
            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

MayplePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MayplePopup;
