import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Tag = ({ children, color, hasDot, hasDashedBorder, className, ...restProps }) => {
  const colorMap = {
    green: ['bg-leafy-10', 'bg-leafy-85', 'border-leafy-10', 'border-leafy-85'],
    purple: ['bg-purple-10', 'bg-purple-100', 'border-purple-10', 'border-purple-100'],
    red: ['bg-red-10', 'bg-red-75', 'border-red-10', 'border-red-75'],
    yellow: ['bg-yellow-5', 'bg-yellow-75', 'border-yellow-5', 'border-yellow-75'],
  };

  return (
    <div
      className={classnames(
        'inline-flex h-5 items-center self-start rounded-full border',
        hasDot ? 'px-2' : 'px-3',
        hasDashedBorder ? `border-dashed ${colorMap[color][3]}` : colorMap[color][2],
        colorMap[color][0],
        className
      )}
      {...restProps}
    >
      {hasDot && (
        <div className={classnames('-ml-1 mr-2 h-3 w-3 rounded-full', colorMap[color][1])} />
      )}
      <div className="vertical-fix text-xs font-bold uppercase leading-none tracking-wider">
        {children}
      </div>
    </div>
  );
};

export default Tag;

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['green', 'purple', 'red', 'yellow']),
  hasDot: PropTypes.bool,
  hasDashedBorder: PropTypes.bool,
  className: PropTypes.string,
};

Tag.defaultProps = {
  color: 'green',
  hasDot: false,
  hasDashedBorder: false,
  className: '',
};
