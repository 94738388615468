import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronRight, FiPlus } from 'react-icons/fi';
import { Combobox, Dialog } from '@headlessui/react';
import classnames from 'classnames';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import DeleteButton from '../Components/DeleteButton.jsx';

const CostCombobox = ({ name, value, onChange }) => {
  const options = [
    'Shipping costs',
    'Custom duties',
    'Discount',
    'Foreign transaction fee',
    'Freight fee',
    'Insurance',
    'Rush fee',
    'Surcharge',
  ];

  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((options) => options.toLowerCase().includes(query.toLowerCase()));

  return (
    <Combobox value={value} onChange={onChange}>
      {({ open }) => (
        <div className="relative">
          <Combobox.Input
            onChange={(e) => setQuery(e.target.value)}
            displayValue={(option) => option}
            className="w-full rounded-lg border border-midnight-10 text-sm focus:ring-1 focus:ring-purple-100"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <FiChevronDown
              className={classnames(
                'ml-2 h-5 w-5 text-purple-100 transition-transform duration-200 ease-out',
                open && '-rotate-180',
              )}
              aria-hidden="true"
            />
          </Combobox.Button>
          <Combobox.Options className="absolute z-10 mt-1 w-full min-w-[10rem] rounded-lg border border-midnight-10 bg-white text-sm leading-3 shadow-md outline-none">
            {query.length > 0 && (
              <Combobox.Option
                className={({ active }) =>
                  classnames(
                    'px-3 py-2 first-of-type:rounded-t-lg first-of-type:pt-2.5 last-of-type:rounded-b-lg last-of-type:pb-2.5',
                    active && 'bg-purple-100 text-white',
                  )
                }
                value={query}
              >
                Add &quot;{query}&quot;
              </Combobox.Option>
            )}
            {filteredOptions.map((option) => (
              <Combobox.Option
                className={({ active }) =>
                  classnames(
                    'px-3 py-2 first-of-type:rounded-t-lg first-of-type:pt-2.5 last-of-type:rounded-b-lg last-of-type:pb-2.5',
                    active && 'bg-purple-100 text-white',
                  )
                }
                key={`${name}${option}`}
                value={option}
              >
                {option}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  );
};

CostCombobox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CostAdjustmentsPopup = ({ open, onClose }) => {
  const { currencySymbol } = useAccount();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'costAdjustments',
  });

  useEffect(() => {
    if (open && fields.length === 0) {
      append({ label: 'Shipping costs', amount: null });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      unmount={false}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <div className="z-10 mx-auto w-full max-w-lg rounded bg-white shadow">
          <Dialog.Title className="rounded-t bg-purple-10 p-6 text-xl font-medium text-purple-100">
            Cost Adjustments
          </Dialog.Title>

          <div className="mt-8 px-6">
            {fields.length !== 0 && (
              <div className="grid grid-cols-6 gap-4 font-bold">
                <div className="col-span-3">Adjustment</div>
                <div className="col-span-2">Amount</div>
                <div className="col-span-1" />
              </div>
            )}

            {fields.map((field, index) => (
              <div key={field.id} className="mt-2 grid grid-cols-6 items-center gap-4">
                <div className="col-span-3">
                  <Controller
                    name={`costAdjustments.${index}.label`}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CostCombobox
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div className="col-span-2 text-xs">
                  <span>{currencySymbol}</span>
                  <input
                    className={classnames(
                      'ml-1 w-24 rounded border border-midnight-10 px-2 py-2.5 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100',
                      errors.costAdjustments?.[index]?.amount && 'input-error',
                    )}
                    type="number"
                    step="0.01"
                    {...register(`costAdjustments.${index}.amount`, {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                </div>
                <div className="col-span-1 flex items-center justify-self-end">
                  <DeleteButton onDelete={() => remove(index)} />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4 px-6">
            <button
              type="button"
              className="flex items-center text-sm font-bold text-purple-100"
              onClick={() => append({ amount: null, label: 'Shipping costs' })}
            >
              <FiPlus size={16} />
              &nbsp;
              {fields.length === 0 ? 'Add adjustment' : 'Add another adjustment'}
            </button>
          </div>

          <div className="mt-20 flex justify-end px-6 pb-6">
            <Button slim label="Close" onClick={onClose} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

CostAdjustmentsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CostAdjustmentsPopupButton = ({ label }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center rounded text-xs font-bold text-purple-100 focus:ring-0"
        onClick={togglePopup}
      >
        {label}
        <FiChevronRight size={14} className="ml-1" />
      </button>
      <CostAdjustmentsPopup open={popupOpen} onClose={togglePopup} />
    </>
  );
};

CostAdjustmentsPopupButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default CostAdjustmentsPopupButton;
