import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const CONNECT_WITH_SHIPHERO = gql`
  mutation ConnectWithShiphero($refreshToken: String!) {
    connectWithShiphero(refreshToken: $refreshToken)
  }
`;

const ShipheroPopup = ({ open, onClose }) => {
  const { addAlert } = useAlerts();
  const [connectWithShiphero, { loading }] = useMutation(CONNECT_WITH_SHIPHERO);

  const handleShipheroConnect = React.useCallback(
    (e) => {
      e.preventDefault();

      const variables = Object.fromEntries(new FormData(document.querySelector('form')));

      const doConnect = async () => {
        await connectWithShiphero({ variables });
        addAlert('Successfully connected to Shiphero', { level: 'success' });
        onClose();
      };

      doConnect();
    },
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Shiphero</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleShipheroConnect}>
            <div>
              <p className="mt-2 text-gray-100">
                In order to integrate with Shiphero, you will need to add Cogsy as a&nbsp;
                <a
                  href="https://app.shiphero.com/dashboard/users/add-developer"
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-100"
                >
                  third-party developer
                </a>
                . You will then need to copy and paste here the generated Refresh Token.
              </p>

              <div className="mt-2">
                <label htmlFor="refresh-token">
                  Refresh Token*
                  <input
                    type="text"
                    id="refresh-token"
                    required
                    className="input"
                    name="refreshToken"
                  />
                </label>
              </div>
            </div>
            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

ShipheroPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShipheroPopup;
