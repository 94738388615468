import React from 'react';
import PropTypes from 'prop-types';

const FulfilIcon = ({ className = '', ...props }) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M2.07848 0.155281C1.70344 0.246018 1.15296 0.566623 0.886802 0.856983C0.753721 1.00216 0.554099 1.30462 0.451263 1.52844C0.275838 1.89744 0.25769 1.98817 0.25769 2.5084C0.25769 3.02863 0.275838 3.11936 0.451263 3.48836C0.681131 3.98439 1.03198 4.36549 1.44332 4.58931C2.06034 4.92806 2.01799 4.92806 6.65769 4.92806H10.9344V5.71445V6.50084H12.0959C13.4085 6.50084 13.6626 6.45245 14.207 6.08345C14.5518 5.84753 14.9874 5.27286 15.1205 4.87967C15.1688 4.72239 15.1991 3.97834 15.2172 2.36927L15.2354 0.0887406L8.77489 0.0947897C5.12121 0.100839 2.21157 0.125035 2.07848 0.155281Z" />
    <path d="M1.93934 6.10765C1.28603 6.30727 0.632725 6.90614 0.408906 7.51105C-0.0447798 8.73298 0.463349 10.0214 1.61874 10.578L2.01193 10.7655L3.75409 10.7836L5.49019 10.8018V11.6184V12.4351L6.74842 12.4169L8.00059 12.3988L8.40588 12.2052C8.92006 11.9632 9.3193 11.564 9.56127 11.0498L9.75484 10.6445L9.77299 8.32769L9.79114 6.01691L5.99227 6.02296C3.02819 6.02296 2.13896 6.04111 1.93934 6.10765Z" />
    <path d="M1.90305 11.8906C1.5401 11.9753 1.12271 12.2173 0.856544 12.5016C0.52989 12.8464 0.439152 13.0097 0.31212 13.4332C0.0338594 14.401 0.541988 15.4838 1.48566 15.9254C1.78206 16.0645 1.9091 16.0887 2.37488 16.0887C2.84066 16.0887 2.9677 16.0645 3.26411 15.9254C3.70569 15.7197 4.09284 15.3326 4.31666 14.8729C4.49208 14.516 4.49208 14.516 4.51023 13.167L4.53443 11.8241L3.33065 11.8301C2.67129 11.8362 2.03008 11.8604 1.90305 11.8906Z" />
  </svg>
);

FulfilIcon.propTypes = {
  className: PropTypes.string,
};

FulfilIcon.defaultProps = {
  className: null,
};

export default FulfilIcon;
