import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FaSortAmountDown } from 'react-icons/fa';
import { FiArrowLeft, FiChevronDown, FiChevronUp, FiDownloadCloud, FiInfo } from 'react-icons/fi';
import { Dialog, Listbox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { addDays, addMonths, endOfDay, isSameMonth, startOfMonth } from 'date-fns';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { Pagination } from '../Components/Pagination.jsx';
import Search from '../Components/Search.jsx';
import SkuDetails from '../Components/SkuDetails.jsx';
import Spinner from '../Components/Spinner.jsx';
import Tooltip from '../Components/Tooltip.jsx';
import { getDemoCategories, getDemoSkus } from '../Demo/skus.js';
import { useCmdDown, useDocumentTitle, useQueryParams } from '../Hooks/index.js';
import { toDate } from '../Utils/dates.js';
import EmptyResult from './EmptyResult.jsx';
import { GROWTH_MODEL_QUERY, SALES_QUERY, SKU_CATEGORIES, SKUS } from './Queries.js';
import { getSkuPagePath, isMergedSku } from './utils/helpers.js';
import WowChart from './WowChart.jsx';
import WowPercentage from './WowPercentage.jsx';

const viewOptions = {
  list: 'List',
  categories: 'Grouped by Categories',
};

const today = endOfDay(new Date());

const monthToDateValueForDisplayType = (display, monthToDateSales, currency) => {
  switch (display) {
    case 'units':
      return (
        (monthToDateSales?.quantity || 0) + (monthToDateSales?.bundlesQuantity || 0)
      ).toLocaleString(navigator.language, {
        notation: 'compact',
      });
    case 'revenueValue':
      return (monthToDateSales?.revenue || 0).toLocaleString(navigator.language, {
        style: 'currency',
        currency,
        notation: 'compact',
        currencyDisplay: 'narrowSymbol',
      });
    default:
      return '—';
  }
};

export const calculateInventoryPercentage = ({ expectedSales, inventoryQuantity }) => {
  if (expectedSales > 0) {
    // We have inventory, so work out our stock level %
    if (inventoryQuantity > 0) {
      return Math.round((inventoryQuantity / expectedSales) * 100) / 100;
    }
    // whereas if inventory is 0 or less, we can't meet sales
    return 0;
  }
  // if there are no expected sales, we show our grey fallback
  return -1;
};

const SkuPlanningRow = ({ sku, display, arrOfMonthsDate }) => {
  const { account } = useAccount();
  const currency = account.preferences?.currency || 'USD';

  const cmdDown = useCmdDown();

  const navigate = useNavigate();
  const pathname = useLocation();

  const skuPagePath = getSkuPagePath(sku);

  const bgColorClass = (value) => {
    if (value === 'bundle') return 'border-2 border-gray-50';
    if (value === -1) return 'bg-gray-50';
    if (value <= 0.5) return 'bg-red-75';
    if (value <= 0.6) return 'bg-red-10';
    if (value <= 0.7) return 'bg-yellow-10';
    if (value <= 0.8) return 'bg-leafy-10';
    if (value <= 0.9) return 'bg-leafy-75';
    return 'bg-leafy-75';
  };

  const textColorClass = (value) => {
    if (value === 'bundle') return 'text-black';
    if (value === -1) return 'text-gray-100';
    if (value <= 0.5) return 'text-red-100';
    if (value <= 0.6) return 'text-red-100';
    if (value <= 0.7) return 'text-yellow-100';
    if (value <= 0.8) return 'text-leafy-100';
    if (value <= 0.9) return 'text-leafy-100';
    return 'text-leafy-100';
  };

  const handleClick = () => {
    if (cmdDown) {
      window.open(skuPagePath, '_blank');
    } else {
      navigate(skuPagePath, { state: { fromPath: pathname } });
    }
  };

  const handleMouseDown = ({ button }) => {
    if (button === 1) {
      window.open(skuPagePath, '_blank');
    }
  };

  const plannedValueForDisplayType = (date, expectedSales) => {
    switch (display) {
      case 'units':
        return expectedSales.toLocaleString(navigator.language, { notation: 'compact' });
      case 'revenueValue':
        return (sku.price * expectedSales).toLocaleString(navigator.language, {
          style: 'currency',
          currency,
          notation: 'compact',
          currencyDisplay: 'narrowSymbol',
        });
      default:
        return '—';
    }
  };

  const isMerged = isMergedSku(account, sku.sku);

  const monthToDateUnitsPercentage = (sku) => {
    if (sku.isBundle) return 'bundle';
    const expectedMonthlyInventoryLevels = sku.stats?.expectedMonthlyInventoryLevels || {};
    const monthToDateQuantity =
      (sku.stats?.monthToDateSales?.quantity || 0) +
      (sku.stats?.monthToDateSales?.bundlesQuantity || 0);
    if (monthToDateQuantity === 0) return -1;
    const dates = Object.keys(expectedMonthlyInventoryLevels);
    const expectedSales = dates?.length
      ? expectedMonthlyInventoryLevels[dates[0]].expectedSales
      : 0;
    return expectedSales > 0 ? monthToDateQuantity / expectedSales : 1;
  };

  return (
    <tr
      className="group contents cursor-pointer"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      key={sku}
    >
      <td className="sticky left-0 z-10 h-[4.5rem] rounded-l bg-white pl-3 pr-5 group-hover:bg-leafy-10">
        <Link to={skuPagePath} state={{ fromPath: pathname }}>
          <SkuDetails sku={sku} isRankVisible isMerged={isMerged} />
        </Link>
      </td>
      <td className="flex flex-col items-center justify-center group-hover:bg-leafy-10">
        {!isMerged && (
          <>
            <WowChart weeklySales={sku.weeklySales} />
            <div className="mt-1">
              <WowPercentage weeklySales={sku.weeklySales} />
            </div>
          </>
        )}
      </td>
      <td className="flex group-hover:bg-leafy-10">
        <div
          className={cn(
            'rounded-lg w-full h-full mx-[3px] flex justify-center items-center',
            display === 'units' && !isMerged && bgColorClass(monthToDateUnitsPercentage(sku)),
            display === 'units' && !isMerged && textColorClass(monthToDateUnitsPercentage(sku)),
            display === 'units' ? 'font-semibold' : 'font-medium',
          )}
        >
          <Tooltip className="h-full w-full">
            <Tooltip.Element className="flex h-full w-full items-center justify-center">
              {!isMerged &&
                monthToDateValueForDisplayType(display, sku.stats?.monthToDateSales, currency)}
            </Tooltip.Element>
            {sku.stats?.monthToDateSales?.bundlesQuantity > 0 && display === 'units' && (
              <Tooltip.Body align="rightCenter">
                <ul className="pl-2">
                  <li className="mt-0 flex">
                    <span className="mr-5 font-normal">Individual Products Sold:</span>
                    <span className="ml-auto">{sku.stats.monthToDateSales.quantity || 0}</span>
                  </li>
                  <li className="mt-2 flex">
                    <span className="mr-5 font-normal">Bundles Sold:</span>
                    <span className="ml-auto">{sku.stats.monthToDateSales.bundlesQuantity}</span>
                  </li>
                  <li className="mt-2 flex">
                    <span className="mr-5 font-normal">Total:</span>
                    <span className="ml-auto">
                      {(sku.stats.monthToDateSales.quantity || 0) +
                        sku.stats.monthToDateSales.bundlesQuantity}
                    </span>
                  </li>
                </ul>
              </Tooltip.Body>
            )}
          </Tooltip>
        </div>
      </td>
      {Object.entries(
        Object.keys(sku.stats?.expectedMonthlyInventoryLevels ?? {}).length > 0
          ? sku.stats.expectedMonthlyInventoryLevels
          : arrOfMonthsDate.reduce((arr, month) => ({ ...arr, [month]: {} }), []),
      ).map(([date, { inventoryQuantity = 0, expectedSales = 0 }]) => {
        const value = sku.isBundle
          ? 'bundle'
          : calculateInventoryPercentage({ expectedSales, inventoryQuantity });

        return (
          <td
            key={date}
            className="flex items-center justify-center last-of-type:rounded-r group-hover:bg-leafy-10"
          >
            <div
              className={cn(
                'rounded-lg h-full w-full flex justify-center items-center flex-col mx-[3px] relative',
                display === 'units' && !isMerged && bgColorClass(value),
                display === 'units' && !isMerged && textColorClass(value),
              )}
            >
              {!isMerged && (
                <div className="absolute top-2 hidden group-hover:block">
                  {toDate(date).toLocaleString(undefined, { month: 'short' })}
                </div>
              )}
              <div className={cn(display === 'units' ? 'font-semibold' : 'font-medium')}>
                {!isMerged && plannedValueForDisplayType(date, expectedSales)}
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

SkuPlanningRow.propTypes = {
  sku: PropTypes.shape({
    sku: PropTypes.string,
    price: PropTypes.number,
    weeklySales: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    stats: PropTypes.shape({
      category: PropTypes.string,
      velocity: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      expectedMonthlyInventoryLevels: PropTypes.objectOf(PropTypes.object),
      monthToDateSales: PropTypes.shape({
        quantity: PropTypes.number,
        revenue: PropTypes.number,
        bundlesQuantity: PropTypes.number,
      }),
    }),
    isBundle: PropTypes.bool,
  }).isRequired,
  display: PropTypes.string,
  arrOfMonthsDate: PropTypes.arrayOf(PropTypes.string),
};

SkuPlanningRow.defaultProps = {
  display: 'units',
  arrOfMonthsDate: [],
};

const SkuPlanningCategoryRow = ({ category, isInitiallyOpen, display, arrOfMonthsDate }) => {
  const { account } = useAccount();
  const currency = account.preferences?.currency || 'USD';
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [after, setAfter] = useState(undefined);
  const [before, setBefore] = useState(undefined);

  const [getSkusForCategory, { data, previousData, loading }] = useLazyQuery(SKUS, {
    variables: {
      productType: category?.name,
      after,
      before,
    },
  });

  const skus = data?.skus || previousData?.skus;

  useEffect(() => {
    if (isOpen) getSkusForCategory();
  }, [isOpen]);

  const plannedValueForDisplayType = (date, expectedSales, revenue) => {
    switch (display) {
      case 'units':
        return expectedSales.toLocaleString(navigator.language, { notation: 'compact' });
      case 'revenueValue':
        return revenue.toLocaleString(navigator.language, {
          style: 'currency',
          currency,
          notation: 'compact',
          currencyDisplay: 'narrowSymbol',
        });
      default:
        return '—';
    }
  };

  return (
    <>
      <tr className="contents" onClick={() => setIsOpen(!isOpen)}>
        <td
          className={cn(
            'sticky left-0 flex items-center h-8 px-3 font-bold text-purple-100 bg-white -mr-1.5',
            'after:absolute after:w-full after:h-full after:left-0 after:-z-10 after:bg-purple-10 after:rounded-l-lg',
            isOpen && skus && 'mb-1',
          )}
        >
          <FiChevronDown
            className={cn(
              'transition-transform duration-200 ease-out w-6 h-6',
              isOpen && '-rotate-180',
            )}
            aria-hidden="true"
          />
          <div className="vertical-fix ml-3 flex">
            {category?.name}
            {loading && <Spinner className="ml-4 h-4 w-4" />}
          </div>
        </td>
        <td
          className={cn(
            'flex items-center justify-center bg-purple-10 h-8 -mr-1.5',
            isOpen && 'mb-1',
          )}
        />
        <td
          className={cn(
            'flex items-center justify-center bg-purple-10 h-8 -mr-1.5',
            isOpen && 'mb-1',
          )}
        >
          {monthToDateValueForDisplayType(display, category.stats?.monthToDateSales, currency)}
          {category.stats?.monthToDateSales?.bundlesQuantity > 0 && display === 'units' && (
            <Tooltip className="ml-2">
              <Tooltip.Element className="flex h-full w-full items-center justify-center">
                <FiInfo className="stroke-gray-100" size={10} />
              </Tooltip.Element>

              <Tooltip.Body>Includes sales of individual product and bundles.</Tooltip.Body>
            </Tooltip>
          )}
        </td>
        {Object.entries(
          category.stats?.expectedMonthlyInventoryLevels || new Array(12).fill({}),
        ).map(([date, { expectedSales = 0, revenue = 0 }], index) => (
          <td
            key={date}
            className={cn(
              'flex items-center justify-center bg-purple-10 h-8 -mr-1.5',
              isOpen && 'mb-1',
              index === 11 && 'rounded-r-lg',
            )}
          >
            {plannedValueForDisplayType(date, expectedSales, revenue)}
          </td>
        ))}
      </tr>

      {isOpen &&
        skus?.edges?.map(({ node: sku }) => (
          <SkuPlanningRow
            key={sku.sku}
            sku={sku}
            display={display}
            arrOfMonthsDate={arrOfMonthsDate}
          />
        ))}

      {isOpen && skus && (skus.pageInfo.hasNextPage || skus.pageInfo.hasPreviousPage) && (
        <tr className="contents">
          <td className="sticky left-0 mb-2 w-[calc((100vw-360px)/2)]">
            <div className="translate-x-1/2">
              <Pagination
                items={skus}
                isLoading={loading}
                size={24}
                className="flex justify-center space-x-2"
              >
                <Pagination.PrevButton
                  onClick={(cursor) => {
                    setBefore(cursor);
                    setAfter(undefined);
                  }}
                />
                <Pagination.NextButton
                  onClick={(cursor) => {
                    setAfter(cursor);
                    setBefore(undefined);
                  }}
                />
              </Pagination>
            </div>
          </td>
          <td className="col-[span_14] w-0" />
        </tr>
      )}
    </>
  );
};

SkuPlanningCategoryRow.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    stats: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      expectedMonthlyInventoryLevels: PropTypes.objectOf(PropTypes.object),
      monthToDateSales: PropTypes.shape({
        quantity: PropTypes.number,
        revenue: PropTypes.number,
        bundlesQuantity: PropTypes.number,
      }),
    }),
  }).isRequired,
  isInitiallyOpen: PropTypes.bool,
  display: PropTypes.string,
  arrOfMonthsDate: PropTypes.arrayOf(PropTypes.string),
};

SkuPlanningCategoryRow.defaultProps = {
  isInitiallyOpen: false,
  display: 'units',
  arrOfMonthsDate: [],
};

const TotalRevenueRow = ({ mothToDateTotalRevenue, growthModel }) => {
  const { account } = useAccount();
  const currency = account.preferences?.currency || 'USD';

  const getPlannedRevenue = (monthIndex) => {
    if (growthModel) {
      const { activePlan = [], baseline = [] } = growthModel;
      let matchingMonth = (baseline || [])[monthIndex];
      if (activePlan?.length && matchingMonth) {
        const monthDate = matchingMonth.date;
        matchingMonth = activePlan.find(({ date }) =>
          isSameMonth(new Date(monthDate), new Date(date)),
        );
      }
      return matchingMonth?.value || 0;
    }
    return 0;
  };

  return (
    <tr className="contents">
      <td className="sticky left-0 z-10 my-4 flex items-center rounded-l-lg font-bold text-gray-100">
        Total Revenue
      </td>
      <td className="my-4 flex items-center justify-center font-bold text-gray-100" />
      <td className="my-4 flex items-center justify-center font-bold text-gray-100">
        {mothToDateTotalRevenue.toLocaleString(navigator.language, {
          style: 'currency',
          currency,
          notation: 'compact',
          currencyDisplay: 'narrowSymbol',
        })}
      </td>
      {new Array(12).fill('').map((_, index) => (
        <td
          key={`revenue-${Math.random()}`}
          className={cn(
            'flex items-center justify-center font-bold text-gray-100 my-4',
            index === 11 && 'rounded-r-lg',
          )}
        >
          {getPlannedRevenue(index).toLocaleString(navigator.language, {
            style: 'currency',
            currency,
            notation: 'compact',
            currencyDisplay: 'narrowSymbol',
          })}
        </td>
      ))}
    </tr>
  );
};

TotalRevenueRow.propTypes = {
  mothToDateTotalRevenue: PropTypes.number,
  growthModel: PropTypes.shape({
    baseline: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        date: PropTypes.string,
      }),
    ),
    activePlan: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        date: PropTypes.string,
      }),
    ),
  }),
};

TotalRevenueRow.defaultProps = {
  mothToDateTotalRevenue: 0,
  growthModel: null,
};

const SkusPlanning = ({ variantTitle, setDocumentTitle = true }) => {
  if (setDocumentTitle) useDocumentTitle('Products | Planning');
  const { account } = useAccount();
  const currency = account.preferences?.currency || 'USD';
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const displayOptions = {
    units: 'Unit Sales',
    revenueValue: `Revenue (${currency})`,
  };

  const { data: salesData } = useQuery(SALES_QUERY, {
    variables: {
      from: startOfMonth(today),
      to: today,
    },
  });
  const { data: growthModelData } = useQuery(GROWTH_MODEL_QUERY);

  const [queryParams, setQueryParams] = useQueryParams([
    'view',
    'display',
    'skusAfter',
    'skusBefore',
    'categoriesAfter',
    'categoriesBefore',
    'search',
    'sortKey',
    'reverse',
  ]);

  const view = queryParams.view === 'categories' ? 'categories' : 'list';
  const display = queryParams.display || 'units';

  const [getSkus, { data: dataSkus, previousData: previousDataSkus, loading: loadingSkus }] =
    useLazyQuery(SKUS, {
      variables: {
        after: queryParams.skusAfter,
        before: queryParams.skusBefore,
        search: queryParams.search,
        sortKey: queryParams.sortKey || 'sku',
        reverse: queryParams.reverse === 'true',
      },
    });

  let skus = dataSkus?.skus || previousDataSkus?.skus;

  // Demo data is loaded here if first sku sync has not completed
  if (!loadingSkus && !skus?.edges?.length && !account.onboarding?.hasSkusSyncCompleted) {
    skus = getDemoSkus();
  }

  // Adding 1 day to avoid timezone issues with beginning of the month
  const start = addDays(startOfMonth(new Date()), 1);
  const arrOfMonthsDate = [...Array(12)].map(
    (_, i) => `${addMonths(start, i).toISOString().substring(0, 7)}-01`,
  );

  const arrOfMonths =
    arrOfMonthsDate.map((date) =>
      toDate(date).toLocaleString(navigator.language, {
        month: 'short',
      }),
    ) || [];

  const planningColumns = ['Product', 'WoW Trend', 'Current MTD', ...arrOfMonths];

  const [
    getSkuCategories,
    {
      data: dataSkuCategories,
      previousData: previousDataSkuCategories,
      loading: loadingSkuCategories,
    },
  ] = useLazyQuery(SKU_CATEGORIES, {
    variables: {
      after: queryParams.categoriesAfter,
      before: queryParams.categoriesBefore,
    },
  });

  // Demo data is loaded here if first sku sync has not completed
  let skuCategories = dataSkuCategories?.skuCategories || previousDataSkuCategories?.skuCategories;
  if (!loadingSkuCategories && !skuCategories?.edges.length && !account.onboarding?.hasSkusSynced) {
    skuCategories = getDemoCategories();
  }

  useEffect(() => {
    getSkuCategories();
    if (view === 'list') {
      getSkus();
    }
  }, [view]);

  useEffect(() => {
    if (
      dataSkuCategories?.skuCategories?.edges?.length === 1 &&
      dataSkuCategories.skuCategories.edges[0].node.name === 'Uncategorized'
    ) {
      setQueryParams({ view: 'list' });
    }
  }, [dataSkuCategories]);

  const handleSearch = (searchQuery) => {
    setQueryParams({
      skusAfter: undefined,
      skusBefore: undefined,
      categoriesAfter: undefined,
      categoriesBefore: undefined,
      search: searchQuery || undefined,
      sortKey: searchQuery ? 'score' : 'sku',
      reverse: searchQuery ? true : undefined,
    });
  };

  const handleSort = (sortKey) => {
    const { reverse, sortKey: querySortKey, search } = queryParams;
    setQueryParams({
      skusAfter: undefined,
      skusBefore: undefined,
      categoriesAfter: undefined,
      categoriesBefore: undefined,
      search,
      sortKey,
      reverse: (querySortKey || 'sku') === sortKey ? reverse !== 'true' : sortKey !== 'sku',
    });
  };

  const getSortableHeader = (name) => {
    const reverse = queryParams.reverse === 'true';
    const querySortKey = queryParams.sortKey || 'sku';
    if (name === 'Product') {
      const sortKey = 'sku';
      return (
        <button type="button" onClick={() => handleSort(sortKey)}>
          <span className="border-b border-dotted">{name}</span>
          {querySortKey === sortKey && reverse && (
            <FiChevronDown className="inline-block text-purple-100" size={18} />
          )}
          {querySortKey === sortKey && !reverse && (
            <FiChevronUp className="inline-block text-purple-100" size={18} />
          )}
        </button>
      );
    }
    return name;
  };

  const resultData = view === 'categories' ? skuCategories?.edges : skus?.edges;

  const handleChangeView = (view) => {
    setQueryParams({
      view,
      skusAfter: undefined,
      skusBefore: undefined,
      categoriesAfter: undefined,
      categoriesBefore: undefined,
      search: undefined,
      sortKey: view === 'list' ? 'sku' : undefined,
      reverse: view === 'list' ? false : undefined,
    });
  };

  const handleChangeDisplay = (display) => {
    setQueryParams({ display });
  };

  const loadingData = loadingSkus || loadingSkuCategories;

  const disableCategoryView =
    (skuCategories?.edges?.length === 1 && skuCategories.edges[0].node.name === 'Uncategorized') ||
    queryParams.category ||
    queryParams.search;

  const displaySearch = view === 'list' && (resultData?.length > 0 || queryParams.search);

  const titleVariants = [
    {
      variant: 'header',
      margin: 'mt-20',
    },
    {
      variant: 'subheader',
      margin: 'mt-10',
    },
  ];

  const titleMargin = (variantTitle) =>
    titleVariants.find((style) => variantTitle === style.variant).margin;

  return (
    <>
      <PageHeader text="Products" />
      {loadingData && (
        <div className="mt-20">
          <Spinner />
        </div>
      )}
      {!loadingData && (
        <div className={`text-midnight-100 ${titleMargin(variantTitle)} text-xs`}>
          <div className="rounded-lg bg-white shadow">
            <div className="flex items-center justify-between border-b border-gray-50 p-5 pb-4">
              <div className="flex">
                {!disableCategoryView && (
                  <Listbox value={view} onChange={handleChangeView}>
                    {({ open }) => (
                      <div className="z-20 flex items-center font-medium">
                        <Listbox.Label className="text-sm font-bold">Products View</Listbox.Label>

                        <div className="relative ml-2.5">
                          <Listbox.Button className="flex items-center rounded-lg border border-midnight-10 py-1.5 pl-3 pr-2.5 font-medium outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-0">
                            {viewOptions[view || Object.keys(viewOptions)[0]]}
                            <FiChevronDown
                              className={cn(
                                'ml-2 text-purple-100 transition-transform duration-200 ease-out w-4 h-4',
                                open && '-rotate-180',
                              )}
                              aria-hidden="true"
                            />
                          </Listbox.Button>
                          <Transition
                            leave="transition-opacity"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full min-w-[10rem] overflow-y-auto rounded-lg border border-midnight-10 bg-white leading-3 shadow-md outline-none">
                              {Object.entries(viewOptions).map(([value, name]) => (
                                <Listbox.Option
                                  key={value}
                                  value={value}
                                  className={({ active }) =>
                                    cn(
                                      'py-2 px-3 first-of-type:pt-2.5 last-of-type:pb-2.5',
                                      active && 'text-white bg-purple-100',
                                    )
                                  }
                                >
                                  {name}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </div>
                    )}
                  </Listbox>
                )}
                {queryParams.search && (
                  <div className="mr-8 flex items-center text-sm font-bold">
                    <button
                      type="button"
                      onClick={() => handleSearch(undefined)}
                      className="flex items-center px-2 py-1.5 font-bold text-purple-100 transition-transform hover:-translate-x-1"
                      title="Back to all results"
                    >
                      <FiArrowLeft size={18} className="mr-1" />
                    </button>
                    Search Results List
                  </div>
                )}
                {resultData?.length > 0 && (
                  <Listbox value={display} onChange={handleChangeDisplay}>
                    {({ open }) => (
                      <div
                        className={cn(
                          'flex items-center font-medium z-20',
                          !disableCategoryView && 'ml-8',
                          queryParams.search && 'ml-2.5',
                        )}
                      >
                        <Listbox.Label className="text-sm font-bold">Displaying as</Listbox.Label>

                        <div className="relative ml-2.5">
                          <Listbox.Button className="flex items-center rounded-lg border border-midnight-10 py-1.5 pl-3 pr-2.5 font-medium outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-0">
                            {displayOptions[display || Object.keys(displayOptions)[0]]}
                            <FiChevronDown
                              className={cn(
                                'ml-2 text-purple-100 transition-transform duration-200 ease-out w-4 h-4',
                                open && '-rotate-180',
                              )}
                              aria-hidden="true"
                            />
                          </Listbox.Button>
                          <Transition
                            leave="transition-opacity"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full min-w-[10rem] overflow-y-auto rounded-lg border border-midnight-10 bg-white leading-3 shadow-md outline-none">
                              {Object.entries(displayOptions).map(([value, name]) => (
                                <Listbox.Option
                                  key={value}
                                  value={value}
                                  className={({ active }) =>
                                    cn(
                                      'py-2 px-3 first-of-type:pt-2.5 last-of-type:pb-2.5',
                                      active && 'text-white bg-purple-100',
                                    )
                                  }
                                >
                                  {name}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </div>
                    )}
                  </Listbox>
                )}

                {displaySearch && <Search handleSearch={handleSearch} className="ml-5" />}

                {queryParams.search && queryParams.sortKey !== 'score' && (
                  <button
                    type="button"
                    onClick={() => handleSort('score')}
                    className="ml-5 flex items-center text-sm font-bold text-purple-100 hover:text-purple-110"
                  >
                    <FaSortAmountDown className="mr-1" />
                    Sort by Relevance
                  </button>
                )}
              </div>
              <Button
                variant="text"
                slim
                label="Export"
                icon={FiDownloadCloud}
                iconSize={18}
                onClick={() => setIsDialogOpen(true)}
              />
            </div>

            <div className="px-5 pb-5 text-xs">
              {resultData?.length === 0 && <EmptyResult />}
              {resultData?.length > 0 && (
                <div className="relative">
                  {view === 'list' && (
                    <>
                      <div
                        className="absolute left-[20rem] top-3 z-10 h-[calc(100%-2.5rem)]"
                        style={{
                          boxShadow: '0 0 0.4rem 0.4rem rgba(0,0,0,0.05)',
                        }}
                      />
                      <div className="absolute left-0 z-10 h-full w-[20rem] bg-white" />
                    </>
                  )}
                  <div
                    className="absolute right-0 top-3 z-10 h-[calc(100%-2.5rem)]"
                    style={{
                      boxShadow: '0 0 0.4rem 0.4rem rgba(0,0,0,0.05)',
                    }}
                  />
                  <div className="absolute -right-4 z-10 h-full w-4 bg-white" />

                  <table
                    className={cn(
                      'grid gap-y-1.5 overflow-x-scroll pt-5 pr-2.5 pb-4',
                      'grid-cols-[20rem,repeat(14,4.875rem)]',
                    )}
                  >
                    <thead className="contents">
                      <tr className="contents whitespace-nowrap text-left">
                        {planningColumns.map((item, index) => (
                          <th
                            key={item}
                            className={cn(
                              'h-6',
                              index === 0 ? 'sticky left-0 z-10 bg-white' : 'text-center',
                            )}
                          >
                            {view === 'categories' && `${item}`}
                            {view === 'list' && getSortableHeader(item)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="contents">
                      {display !== 'units' && (
                        <TotalRevenueRow
                          mothToDateTotalRevenue={salesData?.account?.totalSales}
                          growthModel={growthModelData?.growthModel}
                        />
                      )}
                      {view === 'categories'
                        ? skuCategories?.edges?.map(({ node }, index) => (
                            <SkuPlanningCategoryRow
                              key={node.name}
                              category={node}
                              isInitiallyOpen={index === 0}
                              display={display}
                              arrOfMonthsDate={arrOfMonthsDate}
                            />
                          ))
                        : skus?.edges?.map(({ node: sku }) => (
                            <SkuPlanningRow
                              key={sku._id}
                              sku={sku}
                              display={display}
                              arrOfMonthsDate={arrOfMonthsDate}
                            />
                          ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          {view === 'list' && skus && (
            <Pagination
              prefix="skus"
              items={skus}
              isLoading={loadingSkus}
              className="mt-6 flex justify-center space-x-4"
            >
              <Pagination.PrevButton />
              <Pagination.NextButton />
            </Pagination>
          )}

          {view === 'categories' && skuCategories && (
            <Pagination
              prefix="categories"
              items={skuCategories}
              isLoading={loadingSkuCategories}
              className="mt-6 flex justify-center space-x-4"
            >
              <Pagination.PrevButton />
              <Pagination.NextButton />
            </Pagination>
          )}
        </div>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="fixed inset-0 z-20 overflow-y-auto text-midnight-100"
        style={{ fontFamily: 'Gilroy, sans-serif' }}
      >
        <div className="flex min-h-screen items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <div className="z-50 mx-auto w-full max-w-lg rounded bg-white shadow">
            <Dialog.Title className="rounded-t bg-purple-10 p-8 text-xl font-medium text-purple-100">
              Export Data
            </Dialog.Title>
            <div className="mt-8 px-8 text-sm">
              <p>
                The planning data is available in our Google Sheets export and is updated daily.
              </p>
              <p className="mt-2">
                Once you&apos;ve connected your Google Sheets account in our integrations section,
                you can view the data immediately.
              </p>
            </div>
            <div className="mt-12 flex justify-end space-x-2 px-8 pb-8">
              <Button label="Cancel" variant="text" slim onClick={() => setIsDialogOpen(false)} />
              <Button label="Go to Integrations" slim href="/settings/sources" />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

SkusPlanning.propTypes = {
  variantTitle: PropTypes.oneOf(['header', 'subheader']),
  setDocumentTitle: PropTypes.bool,
};

SkusPlanning.defaultProps = {
  variantTitle: 'header',
  setDocumentTitle: true,
};

export default SkusPlanning;
