import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SkeletonText = ({ className }) => (
  <div className={classnames(`my-0.5 h-4 w-2/3 animate-pulse rounded bg-gray-50`, className)} />
);

SkeletonText.propTypes = {
  className: PropTypes.string,
};

SkeletonText.defaultProps = {
  className: '',
};

const SkeletonBox = ({ className = '' }) => (
  <div className={classnames(`my-0.5 h-6 w-10 animate-pulse rounded-lg bg-gray-50`, className)} />
);

SkeletonBox.propTypes = {
  className: PropTypes.string,
};

SkeletonBox.defaultProps = {
  className: '',
};

const SkeletonBlock = ({ className }) => (
  <div
    className={classnames(
      'flex animate-pulse items-center justify-center rounded bg-gray-50',
      className,
    )}
  >
    <img src="/icon.png" alt="Cogsy Logo" className="h-16 opacity-10 grayscale" />
  </div>
);

SkeletonBlock.propTypes = {
  className: PropTypes.string,
};

SkeletonBlock.defaultProps = {
  className: '',
};

export { SkeletonText, SkeletonBox, SkeletonBlock };
