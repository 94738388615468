import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Title = ({ children, className = '', ...props }) => (
  <h1 className={classnames('text-sm font-bold text-midnight-100', className)} {...props}>
    {children ?? ''}
  </h1>
);

Title.defaultProps = {
  className: '',
  children: null,
};

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const Description = ({ children, className = '', ...props }) => (
  <p className={classnames('text-xs font-bold text-midnight-100/60', className)} {...props}>
    {children}
  </p>
);

Description.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Description.defaultProps = {
  children: null,
  className: '',
};

const Milestone = ({ completed = false, className = '', children }) => (
  <article
    className={classnames(
      'rounded-b-md border-t-4 bg-white p-4 shadow-sm',
      className,
      completed ? 'border-leafy-75' : 'border-purple-100',
    )}
  >
    {children}
  </article>
);
Milestone.propTypes = {
  children: PropTypes.node.isRequired,
  completed: PropTypes.bool,
  className: PropTypes.string,
};

Milestone.defaultProps = {
  completed: false,
  className: '',
};

Milestone.Title = Title;
Milestone.Description = Description;

export default Milestone;
