import React from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';

const CloseButton = ({ onClose }) => (
  <button
    type="button"
    onClick={onClose}
    className="group mr-2 mt-2 inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full hover:bg-purple-50 focus:bg-purple-50 focus:outline-none active:bg-purple-50"
  >
    <FiX size={20} className="stroke-purple-100" />
  </button>
);

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CloseButton;
