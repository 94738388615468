import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useAccount } from '../../AccountProvider.jsx';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';
import { useDocumentTitle } from '../../Hooks/index.js';

const EXPORT_DATA = gql`
  mutation ExportData($email: EmailAddress) {
    exportData(email: $email)
  }
`;

const DataExport = () => {
  const { user } = useAccount();
  const { addAlert } = useAlerts();

  useDocumentTitle('Data Export');

  const [exportData] = useMutation(EXPORT_DATA);

  const handleTriggerExportData = async () => {
    await exportData({ variables: { email: user.email } });
    addAlert('The export has been queued, you should soon receive it in your email.', {
      level: 'success',
    });
  };

  return (
    <div className="m-3 mt-6 rounded-lg bg-white px-6 pb-7 pt-5 text-midnight-100 shadow">
      <h5 className="mb-3 border-b border-gray-50 pb-4 text-sm font-bold">Data Export</h5>

      <div className="space-x-2 text-xs md:whitespace-nowrap">
        <Button
          label="Export account data"
          variant="secondary"
          onClick={handleTriggerExportData}
          slim
        />
      </div>
    </div>
  );
};

export default DataExport;
