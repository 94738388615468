import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineTable } from 'react-icons/ai';

const EmptyTable = ({ title, bodyText, cta, titleIcon, titleIconSize }) => (
  <div className="rounded-lg bg-white pb-20 pt-5 text-midnight-100 shadow">
    <div className="mx-6 flex items-center border-b border-gray-50 pb-4 text-sm font-bold">
      <h5>{title}</h5>
      {titleIcon && (
        <div className="ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-gray-50">
          {titleIcon({ className: 'text-gray-100', size: titleIconSize })}
        </div>
      )}
    </div>
    <div className="mx-auto mt-12 flex h-16 w-16 items-center justify-center rounded-full bg-gray-50">
      <AiOutlineTable className="text-gray-75" size={32} />
    </div>
    <div className="mx-auto mt-6 text-center text-sm text-gray-100">
      <p>{bodyText}</p>
      {cta && <p className="mt-2">{cta}</p>}
    </div>
  </div>
);

EmptyTable.propTypes = {
  title: PropTypes.node.isRequired,
  titleIcon: PropTypes.func,
  titleIconSize: PropTypes.number,
  bodyText: PropTypes.string.isRequired,
  cta: PropTypes.string,
};

EmptyTable.defaultProps = {
  cta: null,
  titleIcon: null,
  titleIconSize: null,
};

export default EmptyTable;
