import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FaCircle } from 'react-icons/fa';
import {
  FiCheck,
  FiChevronDown,
  FiDownloadCloud,
  FiEdit2,
  FiMapPin,
  FiSave,
  FiX,
} from 'react-icons/fi';
import { Listbox, Transition } from '@headlessui/react';
import classnames from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { STATUSES } from '../../shared/purchaseOrders/index.js';
import { useAccount } from '../AccountProvider.jsx';
import AuditTrailWidget from '../Components/AuditTrail.jsx';
import Button from '../Components/Button.jsx';
import { useDemo } from '../Components/DemoProvider.jsx';
import ExportToCSV from '../Components/ExportToCSV.jsx';
import SplitButton from '../Components/SplitButton.jsx';
import TimeAgo from '../Components/TimeAgo.jsx';
import Tooltip from '../Components/Tooltip.jsx';
import { CREATE_TRANSFER, UPDATE_TRANSFER } from '../InternalTransfers/Queries.js';
import omitDeep from '../Utils/omitDeep.js';
import CostAdjustmentsPopupButton from './CostAdjustmentsPopup.jsx';
import AdditionalSkus from './Form/AdditionalSkus.jsx';
import PurchaseOrderItem from './Form/PurchaseOrderItem.jsx';
import POMilestone from './POMilestone.jsx';
import {
  clearPurchaseOrdersCache,
  COMPLETE_DRAFT_PURCHASE_ORDER,
  PO,
  SKU_NAMES_QUERY,
  UPDATE_PURCHASE_ORDER,
} from './Queries.js';
import SkuSelector from './SkuSelector.jsx';
import TransferDialog from './Transfers/TransferDialog.jsx';
import TransfersWidget from './Transfers/Widget.jsx';

const StatusOptions = Object.entries(STATUSES).reduce(
  (acc, [key, value]) =>
    ['DRAFT', 'PARTIALLY_DELIVERED', 'DELIVERED'].includes(key)
      ? acc
      : [
          ...acc,
          {
            name: value,
            value: key,
          },
        ],
  [],
);

export const StatusListBox = ({ selected, options, disabled, onChange }) => (
  <Listbox
    value={selected}
    onChange={(newStatus) => {
      if (onChange) {
        onChange(newStatus);
      }
    }}
    disabled={disabled}
  >
    {({ open }) => (
      <div className="relative">
        <Listbox.Button
          className={classnames(
            'flex h-full items-center rounded-full border px-3 py-1 text-xs font-bold',
            selected === 'DRAFT' && 'border-gray-75 text-gray-100',
            ['PRE_PRODUCTION', 'PRODUCTION', 'PRE_SHIPMENT', 'SHIPMENT'].includes(selected) &&
              'border-purple-90 text-purple-100',
            selected === 'PARTIALLY_DELIVERED' && 'border-leafy-75 bg-leafy-10 text-leafy-85',
            selected === 'DELIVERED' && 'border-leafy-85 bg-leafy-10 text-leafy-100',
          )}
        >
          <span>
            <FaCircle className="h-2 w-2" aria-hidden="true" />
          </span>
          <span className="ml-1 truncate">{STATUSES[selected]}</span>
          {disabled || (
            <span>
              <FiChevronDown
                className={classnames('ml-1 h-4 w-4', open && '-rotate-180')}
                aria-hidden="true"
              />
            </span>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-[21.2rem] w-full overflow-y-auto rounded-lg border border-midnight-10 bg-white text-xs shadow-md">
            {options.map((status) => (
              <Listbox.Option
                key={status.value}
                className={({ active }) =>
                  classnames(
                    `px-3 py-1.5 text-purple-100 first-of-type:pt-2.5 last-of-type:pb-2.5`,
                    active && 'bg-purple-100 text-white',
                  )
                }
                value={status.value}
              >
                <span className="block truncate font-bold">{status.name}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
);

StatusListBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  selected: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

StatusListBox.defaultProps = {
  onChange: null,
};

export const milestones = [
  {
    key: 'DRAFT',
    description: 'Issue date',
    input: { name: 'issueDate', required: true },
  },
  {
    key: 'PRE_PRODUCTION',
    description: (po) => {
      const submittedAt =
        po.trailLogs?.find((log) => log.logType === 'purchase-orders.submitted')?.createdAt ?? null;
      return submittedAt ? `PO accepted ${new Date(submittedAt).toLocaleDateString()}` : '';
    },
  },
  {
    key: 'PRODUCTION',
    description: 'Completion date',
    input: { name: 'expectedProductionCompletionDate' },
    prompts: ['PRODUCTION'],
  },
  { key: 'PRE_SHIPMENT' },
  {
    key: 'SHIPMENT',
    description: 'Shipment date',
    input: {
      name: 'expectedShipmentDate',
    },
    prompts: ['SHIPMENT'],
  },
  { key: 'PARTIALLY_DELIVERED' },
  {
    key: 'DELIVERED',
    description: 'Stock due date',
    input: { name: 'expectedDeliveryDate', required: (po) => po.transfers.edges.length > 0 },
  },
];

const PurchaseOrder = ({ purchaseOrder, updatedAt, hasChanges, showSuggestedSkus }) => {
  const { user } = useAccount();
  const { isDemo } = useDemo();
  const {
    account,
    formatCurrency,
    locations: { edges: allLocations },
  } = useAccount();
  const { _id } = useParams();
  const navigate = useNavigate();

  const accountLocations = allLocations.filter((location) => !location.node.hideReplenishment);

  const [editNameMode, setEditNameMode] = useState(false);
  const [name, setName] = useState(purchaseOrder.customPurchaseOrderNumber ?? '');

  const isCreatedByCogsy = purchaseOrder.sourceId === 'cogsy';
  const isReadOnly = !isCreatedByCogsy || purchaseOrder.status === 'DELIVERED' || user.isReadOnly;
  const areItemsReadOnly = isReadOnly || purchaseOrder.transfers.edges.length > 0;

  const { getValues, setValue, trigger } = useFormContext();

  const { fields, append, remove } = useFieldArray({ name: 'items' });

  const [locations, setLocations] = useState(getValues('recommendInventoryForLocations') ?? []);
  const [csvData, setCSVData] = useState(
    purchaseOrder.items.map(({ sku, quantity }) => ({
      sku,
      quantity,
    })),
  );
  const [markAsSubmitted, { loading: submitting }] = useMutation(COMPLETE_DRAFT_PURCHASE_ORDER, {
    variables: { _id },
    update: clearPurchaseOrdersCache,
    onCompleted: () => {
      if (!isCreatedByCogsy) navigate('/purchase-orders');
    },
  });

  const [transferDialogTransfer, setTransferDialogTransfer] = useState(null);

  const refetchQueries = [{ query: PO, variables: { _id: purchaseOrder._id } }];

  const [updateTransfer] = useMutation(UPDATE_TRANSFER, { refetchQueries });
  const [createTransfer] = useMutation(CREATE_TRANSFER, { refetchQueries });

  const onSaveTransfer = async (data) => {
    if (transferDialogTransfer?._id) {
      await updateTransfer({
        variables: {
          input: {
            ...data,
            trackingUrl: data.trackingUrl || null,
          },
          transferId: transferDialogTransfer._id,
        },
      });
    } else {
      await createTransfer({
        variables: {
          input: {
            ...data,
            trackingUrl: data.trackingUrl || undefined,
            items: purchaseOrder.items.map(({ sku, quantity }) => ({ sku, quantity })),
            purchaseOrder: purchaseOrder._id,
          },
        },
      });
    }

    setTransferDialogTransfer(null);
  };

  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER, {
    variables: { purchaseOrderId: _id },
    update: clearPurchaseOrdersCache,
  });

  useEffect(() => {
    if (!isReadOnly) trigger();
  }, [isReadOnly]);

  useEffect(() => {
    setValue('recommendInventoryForLocations', locations, {
      shouldDirty: true,
    });
  }, [locations]);

  const onItemRemove = useCallback(
    (itemIndex) => {
      remove(itemIndex);
    },
    [remove],
  );

  const onItemAdd = useCallback(
    (additionalSkus) => {
      const doAdd = async () => {
        additionalSkus.forEach(([sku, qty, options]) => {
          append({ sku, quantity: Math.max(1, qty), ...(options ? { options } : {}) });
        });
      };

      doAdd();
    },
    [append],
  );

  const currentItems = getValues('items');

  const defaultSource = account.sources?.find((source) => source.isDefaultSource)?.sourceType;

  useQuery(SKU_NAMES_QUERY, {
    variables: { skus: purchaseOrder.items.map(({ sku }) => sku) },
    onCompleted: ({ skuNames }) => {
      setCSVData(
        csvData.map(({ sku, quantity }) => ({
          sku,
          quantity,
          productName: skuNames.find((n) => n.sku === sku).productName || '-',
        })),
      );
    },
    skip: !purchaseOrder.items.length,
  });

  const transfers = purchaseOrder.transfers.edges.map(({ node }) => node);

  const auditTrailIsEnabled = useFeature('purchase-orders-audit-trail').on;
  return (
    <div className="mt-20 px-6 text-midnight-100 first:mt-0">
      <div className="flex">
        {editNameMode ? (
          <input
            id="custom-name"
            type="text"
            value={name}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder="Custom name"
            className="-ml-2 appearance-none rounded-lg border-0 bg-purple-10 py-1 pl-2 pr-0 text-3xl placeholder:text-gray-75/50 focus:ring-2 focus:ring-purple-100"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        ) : (
          <div className="py-1 text-3xl">
            {purchaseOrder.customPurchaseOrderNumber ?? 'Custom name'}
          </div>
        )}
        {isReadOnly ? null : (
          <button
            type="button"
            className="ml-2 flex items-center self-center rounded-full px-4 py-2 text-sm font-bold text-purple-100 hover:bg-purple-10"
            onClick={() => setEditNameMode((prev) => !prev)}
          >
            {editNameMode ? (
              <>
                <FiX size={16} />
                <span className="ml-1">Cancel</span>
              </>
            ) : (
              <>
                <FiEdit2 size={16} />
                <span className="ml-1">Edit</span>
              </>
            )}
          </button>
        )}
        <button
          type="submit"
          className={classnames(
            !editNameMode && 'hidden',
            'ml-1 flex items-center self-center rounded-full px-4 py-2 text-sm font-bold text-purple-100 hover:bg-purple-10',
          )}
          onClick={() => {
            setValue('customPurchaseOrderNumber', name);
            setEditNameMode(!editNameMode);
          }}
        >
          <FiSave size={16} />
          <span className="ml-1">Save</span>
        </button>
      </div>
      <div className="mt-6 flex items-center space-x-8 text-sm font-bold">
        <div className="max-w-[14rem] truncate" title={purchaseOrder.vendor.name}>
          <span className="text-midnight-70">Vendor:&nbsp;</span>
          <span className={classnames(isDemo && 'blur')}>{purchaseOrder.vendor.name}</span>
        </div>
        <div className="max-w-[14rem] truncate" title={purchaseOrder.location?.name ?? '-'}>
          <span className="text-midnight-70">Destination:&nbsp;</span>
          <span>{purchaseOrder.location?.name ?? '-'}</span>
        </div>
        {['DRAFT', 'PARTIALLY_DELIVERED', 'DELIVERED', 'SHIPMENT'].includes(
          purchaseOrder.status,
        ) || (
          <StatusListBox
            selected={purchaseOrder.status}
            options={StatusOptions}
            disabled={isReadOnly}
            onChange={(status) => updatePurchaseOrder({ variables: { input: { status } } })}
          />
        )}
        {['DRAFT', 'PARTIALLY_DELIVERED', 'DELIVERED', 'SHIPMENT'].includes(
          purchaseOrder.status,
        ) && (
          <StatusListBox
            selected={purchaseOrder.status}
            options={[{ name: STATUSES[purchaseOrder.status], value: purchaseOrder.status }]}
            disabled
          />
        )}

        {purchaseOrder.items.length > 0 &&
          purchaseOrder.status === 'DRAFT' &&
          isCreatedByCogsy &&
          !user.isReadOnly && (
            <Button
              onClick={markAsSubmitted}
              slim
              icon={FiCheck}
              disabled={submitting}
              label={submitting ? 'Submitting...' : 'Mark as submitted'}
            />
          )}

        {purchaseOrder.items.length > 0 &&
        ['PRE_PRODUCTION', 'PRODUCTION', 'PRE_SHIPMENT', 'SHIPMENT'].includes(
          purchaseOrder.status,
        ) &&
        purchaseOrder.transfers.edges.length === 0 &&
        isCreatedByCogsy &&
        !user.isReadOnly ? (
          <div className="font-normal">
            <SplitButton
              options={[
                {
                  key: 'single',
                  label: 'Add Single Shipment',
                  menuTitle: 'Add Single Shipment',
                  menuDescription: 'Deliver this PO to a single location',
                },
                {
                  key: 'split',
                  label: 'Add Split Shipment',
                  menuTitle: 'Add Split Shipment',
                  menuDescription: 'Deliver this PO to more than one location',
                },
              ]}
              onClick={(key) => {
                if (key === 'single') setTransferDialogTransfer({});
                else navigate(`/purchase-orders/${purchaseOrder._id}/shipments`);
              }}
            />
          </div>
        ) : null}

        <div className="!ml-auto inline-flex shrink-0 items-center">
          <span className="text-midnight-70">Last Updated:</span>
          <time dateTime={updatedAt} className="ml-2 font-bold">
            <TimeAgo date={updatedAt} />
            {hasChanges && ' *'}
          </time>
        </div>
      </div>
      <section className="mt-8 grid grid-cols-7 gap-[10px]">
        {milestones.map((milestone) => (
          <POMilestone
            key={milestone.key}
            milestone={milestone}
            resource={purchaseOrder}
            resourceStatus={purchaseOrder.status}
            isReadOnly={isReadOnly}
          />
        ))}
      </section>
      <article className="mt-6 flex items-center rounded-t-md bg-[rgb(82,54,148)] px-6 py-5">
        <div className="space-x-2">
          <Listbox value={locations} onChange={setLocations} multiple disabled={isReadOnly}>
            <div className="relative inline-block">
              <Listbox.Button className="inline-flex items-center space-x-1 py-1">
                <FiMapPin className="text-white" size={16} />
                <span className="whitespace-nowrap text-sm font-bold text-white">
                  Recommendations for
                </span>
                {!isReadOnly && <FiChevronDown className="text-white" size={20} />}
              </Listbox.Button>
              <Listbox.Options className="absolute z-30 mt-1 max-h-72 min-w-[20rem] overflow-y-auto rounded-lg bg-white text-xs font-medium shadow-md outline-none">
                {accountLocations.map(({ node }) => (
                  <Listbox.Option key={node._id} value={node._id}>
                    {({ active, selected }) => (
                      <span
                        className={classnames(
                          'flex items-center space-x-2 px-3 py-2 first-of-type:pt-2.5 last-of-type:pb-2.5',
                          active && 'bg-purple-100 text-white',
                        )}
                      >
                        <FiCheck
                          className={classnames(
                            'text-purple-100 opacity-0',
                            selected && 'opacity-100',
                            active && 'text-white',
                          )}
                          size={14}
                        />
                        <span>{node.name}</span>
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>
        <div className="relative ml-4 flex-1 overflow-x-hidden">
          <div className="hide-scrollbars flex w-full snap-x snap-mandatory space-x-2 overflow-x-scroll">
            {purchaseOrder.recommendInventoryForLocations.length > 0 ? (
              purchaseOrder.recommendInventoryForLocations.map((locationId) => (
                <span
                  className="inline-flex shrink-0 snap-start items-center rounded-full border border-white/20 bg-white/10 py-0.5 pl-3 pr-1 text-xs font-bold text-white"
                  key={locationId}
                >
                  {accountLocations.find(({ node }) => node._id === locationId).node.name}
                  <button
                    type="button"
                    onClick={() => setLocations((prev) => prev.filter((id) => id !== locationId))}
                    className="-my-1 ml-1 rounded-full p-1 text-white/80 hover:text-white"
                  >
                    <FiX strokeWidth={3} size={14} />
                  </button>
                </span>
              ))
            ) : (
              <span className="inline-block rounded-full border border-white/20 bg-white/10 px-3 py-0.5 text-xs font-bold text-white">
                All locations
              </span>
            )}
            <span className="inline-block w-6 flex-shrink-0" />
          </div>
          <div className="absolute inset-y-0 right-0 w-6 bg-gradient-to-r from-transparent to-[rgb(82,54,148)]" />
        </div>
        {purchaseOrder.recommendInventoryForLocations.length > 1 && (
          <button
            type="button"
            className="!ml-3 py-0.5 text-xs font-bold text-white/70 hover:text-white"
            onClick={() => setLocations([])}
          >
            Remove all
          </button>
        )}
      </article>
      <div className="bg-white pb-7 pt-5">
        <div className="mx-6 flex justify-between text-sm">
          <h5 className="text-lg font-bold">
            Purchase order for
            <span className={classnames(isDemo && 'blur')}> {purchaseOrder.vendor.name}</span>
          </h5>
          {purchaseOrder.items.length > 0 && (
            <ExportToCSV
              data={csvData}
              fileName={`po-${purchaseOrder.customPurchaseOrderNumber}-${purchaseOrder.vendor.name}`}
            >
              <Button
                variant="text"
                slim
                icon={FiDownloadCloud}
                label="Export to CSV"
                iconSize={18}
                className="-mr-4 px-3"
              />
            </ExportToCSV>
          )}
        </div>
        <table className="mt-8 min-w-full border-collapse border-spacing-0 text-center text-xs">
          <thead>
            <tr className="align-top">
              <th className="pb-4 pl-6 pr-3 text-left font-bold">Product</th>
              {purchaseOrder.status === 'DELIVERED' ? null : (
                <th className="w-20 whitespace-nowrap px-3 pb-4 font-bold">On Hand / Target</th>
              )}
              {purchaseOrder.status === 'DELIVERED' ? null : (
                <th className="px-3 pb-4 font-bold">Recommended</th>
              )}
              <th className="w-20 px-3 pb-4 text-right font-bold">In Transit</th>
              <th className="px-3 pb-4 text-right font-bold">Order Unit</th>
              <th className="px-3 pb-4 text-right font-bold">Total</th>
              <th className="flex items-center justify-end px-3 pb-4 font-bold">
                Unit Cost
                {defaultSource && !isReadOnly && (
                  <Tooltip className="ml-1">
                    <Tooltip.Element />
                    <Tooltip.Body>
                      Unit costs synced from&nbsp;
                      <span className="capitalize">{defaultSource}.</span>&nbsp;You can override
                      them below.
                    </Tooltip.Body>
                  </Tooltip>
                )}
              </th>
              <th className="px-3 pb-4 text-right font-bold">Landed Cost</th>
              <th className="px-3 pb-4 text-right font-bold last:pr-6">Estimated Cost</th>
              {isReadOnly ? null : <th className="pl-3 pr-6">&nbsp;</th>}
            </tr>
          </thead>

          <tbody className="mt-9">
            {fields.length === 0 && (
              <tr>
                <td className="px-6 text-xs">Add products via input below.</td>
              </tr>
            )}
            {fields.map((item, itemIndex) => (
              <PurchaseOrderItem
                itemIndex={itemIndex}
                item={item}
                key={item.sku}
                onItemRemove={onItemRemove}
                poLocation={purchaseOrder.location}
                purchaseOrder={purchaseOrder}
                isReadOnly={areItemsReadOnly}
                defaultSource={defaultSource}
              />
            ))}
            <tr>
              <td colSpan={11}>
                <div className="mb-4 border-t border-gray-50" />
              </td>
            </tr>

            <tr className="text-right">
              <td
                colSpan={purchaseOrder.status === 'DELIVERED' ? 6 : 8}
                className="px-3 text-right font-bold"
              >
                Subtotal
              </td>
              <td className={classnames('pl-3', isReadOnly ? 'pr-6' : 'pr-3')}>
                {purchaseOrder.totalCost.toLocaleString(navigator.language, {
                  style: 'currency',
                  currency: account.preferences?.currency || 'USD',
                  maximumFractionDigits: 2,
                  notation: 'compact',
                }) || '-'}
              </td>
            </tr>
            {isCreatedByCogsy && (
              <>
                <tr>
                  <td
                    colSpan={purchaseOrder.status === 'DELIVERED' ? 6 : 8}
                    className="px-3 py-1 text-right font-bold"
                  >
                    Cost Adjustments
                  </td>
                  <td colSpan={1} className="py-1 pr-3 text-right">
                    {isReadOnly ? null : (
                      <div className="-mr-1">
                        <CostAdjustmentsPopupButton
                          label={purchaseOrder.costAdjustments?.length ? 'Edit' : 'Add'}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                {purchaseOrder.costAdjustments?.length > 0
                  ? purchaseOrder.costAdjustments.map(({ label, amount }) => (
                      <tr key={`${label}-${amount}`}>
                        <td
                          className="px-3 py-1 text-right"
                          colSpan={purchaseOrder.status === 'DELIVERED' ? 6 : 8}
                        >
                          {label}
                        </td>
                        <td
                          className={classnames(
                            'py-1 pl-3 text-right',
                            isReadOnly ? 'pr-6' : 'pr-3',
                          )}
                        >
                          {formatCurrency(
                            amount,
                            { maximumFractionDigits: 2, notation: 'compact' },
                            '-',
                          )}
                        </td>
                      </tr>
                    ))
                  : null}
              </>
            )}
            <tr>
              <td
                className="px-3 py-1 text-right font-bold"
                colSpan={purchaseOrder.status === 'DELIVERED' ? 6 : 8}
              >
                Total
              </td>
              <td
                className={classnames(
                  'py-1 pl-3 text-right font-bold',
                  isReadOnly ? 'pr-6' : 'pr-3',
                )}
              >
                {formatCurrency(
                  purchaseOrder.totalCost +
                    purchaseOrder.costAdjustments.reduce(
                      (total, adjustment) => total + adjustment.amount,
                      0,
                    ),
                  { maximumFractionDigits: 2, notation: 'compact' },
                  '-',
                )}
              </td>
            </tr>
            {areItemsReadOnly ? null : (
              <>
                <tr>
                  <td colSpan={9}>
                    <h3 className="ml-6 pt-6 text-left text-lg font-bold">Add more products</h3>
                  </td>
                </tr>
                <tr>
                  <td colSpan={9} className="p-6">
                    <SkuSelector
                      items={currentItems}
                      vendor={purchaseOrder.vendor}
                      onItemsAdd={(skus) => onItemAdd(skus)}
                      locations={purchaseOrder.recommendInventoryForLocations}
                    />
                  </td>
                </tr>
                {showSuggestedSkus && (
                  <AdditionalSkus
                    vendor={purchaseOrder.vendor._id}
                    locations={purchaseOrder.recommendInventoryForLocations}
                    currentItems={currentItems}
                    onItemAdd={onItemAdd}
                  />
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-8 grid grid-cols-2 gap-x-5">
        <TransfersWidget
          purchaseOrder={purchaseOrder}
          transfers={transfers}
          isReadOnly={isReadOnly}
          setTransferDialogTransfer={setTransferDialogTransfer}
        />
        {auditTrailIsEnabled && purchaseOrder.trailLogs.length > 0 ? (
          <AuditTrailWidget trailLogs={purchaseOrder.trailLogs} />
        ) : null}
      </div>

      {transferDialogTransfer ? (
        <TransferDialog
          purchaseOrder={purchaseOrder}
          transfer={transferDialogTransfer}
          onSave={onSaveTransfer}
          onClose={() => setTransferDialogTransfer(null)}
        />
      ) : null}
    </div>
  );
};

PurchaseOrder.propTypes = {
  purchaseOrder: PropTypes.shape({
    _id: PropTypes.string,
    sourceId: PropTypes.string,
    issueDate: PropTypes.string,
    updatedAt: PropTypes.string,
    expectedDeliveryDate: PropTypes.string,
    vendor: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    customPurchaseOrderNumber: PropTypes.string,
    status: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
      }),
    ).isRequired,
    totalCost: PropTypes.number.isRequired,
    recommendInventoryForLocations: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    costAdjustments: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      }),
    ),
    transfers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          // eslint-disable-next-line react/forbid-prop-types
          node: PropTypes.object.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    trailLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  updatedAt: PropTypes.string.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  showSuggestedSkus: PropTypes.bool.isRequired,
};

const Form = ({ purchaseOrder, onChange, showSuggestedSkus = true }) => {
  // Cloning purchaseOrder so that react-hook-form doesn't manipulate the original,
  // which is frozen
  const cloned = omitDeep(purchaseOrder, ['updatedAt']);

  const methods = useForm({
    defaultValues: cloned,
    mode: 'all',
  });

  useEffect(() => {
    if (!methods.formState.isValid || !methods.formState.isDirty) return;

    if (methods.watch.saveTimeout) clearTimeout(methods.watch.saveTimeout);

    methods.watch.saveTimeout = setTimeout(() => {
      methods.handleSubmit(onChange)();
    }, 1000);
  }, [methods.formState.isValid, methods.formState.isDirty]);

  // This will reset the formState.isDirty property
  useEffect(() => {
    methods.reset(cloned);
  }, [purchaseOrder]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onChange)} onChange={() => methods.trigger()}>
        <PurchaseOrder
          purchaseOrder={cloned}
          updatedAt={purchaseOrder.updatedAt}
          hasChanges={methods.formState.isDirty}
          showSuggestedSkus={showSuggestedSkus}
        />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  purchaseOrder: PropTypes.shape({
    issueDate: PropTypes.string,
    updatedAt: PropTypes.string,
    expectedDeliveryDate: PropTypes.string,
    vendor: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
      }),
    ).isRequired,
    totalCost: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  showSuggestedSkus: PropTypes.bool,
};

Form.defaultProps = {
  showSuggestedSkus: true,
};

export default Form;
