import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FiActivity,
  FiBell,
  FiCalendar,
  FiDollarSign,
  FiLink,
  FiMapPin,
  FiPackage,
  FiTag,
  FiTool,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import cn from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import { useAccount } from '../AccountProvider.jsx';

const Navbar = () => {
  const { user } = useAccount();
  const locationConsolidationEnabled = useFeature('location-consolidation').on;

  return (
    <nav className="flex flex-col text-sm text-midnight-100">
      <ul>
        <li>
          <NavLink
            to="profile"
            className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
          >
            <FiUser className="mr-2 text-purple-100" />
            Profile
          </NavLink>
        </li>

        {!user.isReadOnly && (
          <li className="mt-3">
            <NavLink
              to="account"
              className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
            >
              <FiPackage className="mr-2 text-purple-100" />
              Account
            </NavLink>
          </li>
        )}

        <li className="mt-3">
          <NavLink
            to="notifications"
            className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
          >
            <FiBell className="mr-2 text-purple-100" />
            Notifications
          </NavLink>
        </li>
        {!user.isReadOnly && (
          <>
            <li className="mt-3">
              <NavLink
                to="members"
                className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
              >
                <FiUsers className="mr-2 text-purple-100" />
                Members
              </NavLink>
            </li>
            <li className="mt-3">
              <NavLink
                to="billing"
                className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
              >
                <FiDollarSign className="mr-2 text-purple-100" />
                Billing
              </NavLink>
            </li>
            {locationConsolidationEnabled && (
              <li className="mt-3">
                <NavLink
                  to="locations"
                  className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
                >
                  <FiMapPin className="mr-2 text-purple-100" />
                  Locations
                </NavLink>
              </li>
            )}
            {user.isAdmin && (
              <li className="mt-3">
                <NavLink
                  to="forecast"
                  className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
                >
                  <FiActivity className="mr-2 text-purple-100" />
                  Forecast
                </NavLink>
              </li>
            )}
            <li className="mt-3">
              <NavLink
                to="inventory"
                className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
              >
                <FiTag className="mr-2 text-purple-100" />
                Inventory
              </NavLink>
            </li>
            <li className="mt-3">
              <NavLink
                to="backorders"
                className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
              >
                <FiCalendar className="mr-2 text-purple-100" />
                Backorders
              </NavLink>
            </li>
            <li className="mt-3">
              <NavLink
                to="sources"
                className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
              >
                <FiLink className="mr-2 text-purple-100" />
                Integrations
              </NavLink>
            </li>
            {user.isAdmin && (
              <li className="mt-3">
                <NavLink
                  to="tools"
                  className={({ isActive }) => cn('nav-item', isActive && 'bg-purple-10')}
                >
                  <FiTool className="mr-2 text-purple-100" />
                  Tools
                </NavLink>
              </li>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
