import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiCheck, FiChevronDown, FiChevronLeft, FiInfo } from 'react-icons/fi';
import { Listbox, RadioGroup } from '@headlessui/react';
import cn from 'classnames';
import { useController, useForm } from 'react-hook-form';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';
import DeleteButton from '../../Components/DeleteButton.jsx';
import { useDemo } from '../../Components/DemoProvider.jsx';
import InputErrorMessage from '../../Components/InputErrorMessage.jsx';
import LoadingIcon from '../../Components/LoadingIcon.jsx';
import PageHeader from '../../Components/PageHeader.jsx';
import SkuSelectSearch from '../../Components/SkuSelectSearch.jsx';
import Spinner from '../../Components/Spinner.jsx';
import Tooltip from '../../Components/Tooltip.jsx';
import { useDocumentTitle } from '../../Hooks/index.js';
import { compactNumber, PLANNED_SKUS_QUERY } from './Index.jsx';

const formatLaunchDate = (date) =>
  new Date(date).toLocaleDateString(navigator.language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    weekday: 'short',
  });

const CREATE_PLANNED_SKU = gql`
  mutation CreatePlannedSku($input: PlannedSkuInput!) {
    createPlannedSku(input: $input) {
      _id
    }
  }
`;

const UPDATE_PLANNED_SKU = gql`
  mutation UpdatePlannedSku($_id: ID!, $input: PlannedSkuInput!) {
    updatePlannedSku(_id: $_id, input: $input) {
      _id
    }
  }
`;

const REMOVE_PLANNED_SKU = gql`
  mutation RemovePlannedSku($_id: ID!) {
    removePlannedSku(_id: $_id) {
      _id
    }
  }
`;

const PLANNED_SKU = gql`
  query GetPlannedSku($_id: ID!) {
    plannedSku(_id: $_id) {
      _id
      name
      launchDate
      multipleVariants
      similarSkus
      marketingActivity
      stats {
        sales30
        sales60
        sales90
      }
      simulations {
        _id
        name
        launchDate
        multipleVariants
        similarSkus
        stats {
          sales30
          sales60
          sales90
        }
        createdAt
      }
    }
  }
`;

const saveOptions = [
  {
    label: 'Save changes',
    description: 'Update this planned product',
    value: false,
  },
  {
    label: 'Save changes as simulation',
    description: 'Create a new simulation for this planned product',
    value: true,
  },
];

const PlannedProduct = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const { addAlert } = useAlerts();
  const { isDemo } = useDemo();

  const isNew = !_id;

  const [selectedSkus, setSelectedSkus] = useState([]);
  const [nest, setNest] = useState(false);
  const [simulationOpen, setSimulationOpen] = useState(null);
  const [savingSku, setSavingSku] = useState(false);
  const [simulationIdToDelete, setSimulationIdToDelete] = useState(null);

  const {
    loading,
    data,
    refetch: refetchPlannedSku,
  } = useQuery(PLANNED_SKU, {
    skip: isNew,
    variables: { _id },
  });

  useDocumentTitle(data?.plannedSku?.name || 'Plan New Product');

  const [onSubmitMutation] = useMutation(isNew ? CREATE_PLANNED_SKU : UPDATE_PLANNED_SKU, {
    refetchQueries: [{ query: PLANNED_SKUS_QUERY }],
    onCompleted: (res) => {
      if (isNew) {
        addAlert('Planned product created', { level: 'success' });
        navigate(`/planning/planned-products/${res.createPlannedSku._id}`);
      } else {
        refetchPlannedSku({ _id });
        addAlert(nest ? 'New simulation created successfully' : 'Changes saved successfully', {
          level: 'success',
        });
      }
      setSavingSku(false);
    },
  });

  const [onRemoveMutation] = useMutation(REMOVE_PLANNED_SKU, {
    refetchQueries: [{ query: PLANNED_SKUS_QUERY }, { query: PLANNED_SKU, variables: { _id } }],
    onCompleted: () => {
      setSimulationIdToDelete(null);
    },
  });

  const removeSimulation = (input) => {
    setSimulationIdToDelete(input.variables._id);
    onRemoveMutation(input);
  };

  const isSelectedSkusDirty =
    data?.plannedSku?.similarSkus &&
    [...data.plannedSku.similarSkus].sort().join() !== [...selectedSkus].sort().join();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setFocus,
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      name: '',
      launchDate: '',
      skuWithVariants: true,
    },
  });

  const { field: skuWithVariants } = useController({
    name: 'skuWithVariants',
    control,
    rules: {
      validate: () => selectedSkus.length > 0 || 'Please select at least one product',
    },
  });

  const onSubmit = ({ name, launchDate, skuWithVariants }) => {
    setSavingSku(true);
    onSubmitMutation({
      variables: {
        _id,
        input: {
          name,
          launchDate: new Date(launchDate).toISOString(),
          multipleVariants: skuWithVariants,
          similarSkus: selectedSkus,
          nest,
        },
      },
    });
  };

  useEffect(() => {
    if (isNew) {
      setFocus('name');
    }
  }, []);

  useEffect(() => {
    if (data?.plannedSku === null) {
      addAlert('Planned product does not exist');
      navigate('/planning/planned-products');
    } else if (data?.plannedSku) {
      const { name, launchDate, multipleVariants } = data.plannedSku;

      reset({
        name,
        launchDate: launchDate?.slice(0, 10),
        skuWithVariants: multipleVariants,
      });

      setSelectedSkus(data.plannedSku.similarSkus);
    }
  }, [data]);

  useEffect(() => {
    if (selectedSkus.length > 0) trigger('skuWithVariants');
  }, [selectedSkus]);

  if (loading && !data?.plannedSku) {
    return (
      <div className="flex h-96 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="text-midnight-100">
      <Link
        to="/planning/planned-products"
        className="-ml-1 inline-flex items-center rounded py-1 pr-2 text-sm font-bold text-purple-100 focus:outline-purple-100"
      >
        <FiChevronLeft strokeWidth="2" className="h-5 w-5" />
        <span className="pt-0.5">Planned Products</span>
      </Link>

      <div className="mt-4">
        <PageHeader text={(isNew ? 'Plan New Product' : data?.plannedSku?.name) || ''} />
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-12 mt-8 rounded-lg bg-white px-8 pb-10 pt-7 shadow xl:col-span-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex text-sm">
              <div className="flex-grow">
                <h5 className="text-lg font-bold">Product Details</h5>

                <label htmlFor="name">
                  <div className="mt-6 font-bold text-midnight-70">Product Name</div>
                  <input
                    type="text"
                    id="name"
                    placeholder="Your new product name"
                    className={cn(
                      'input mt-3 placeholder-gray-75 text-sm py-3',
                      errors.name && 'input-error',
                    )}
                    {...register('name', {
                      required: 'Please provide the product name',
                    })}
                  />
                  <InputErrorMessage message={errors.name?.message} />
                </label>

                <label htmlFor="launch_date" className="mt-7 flex space-x-3">
                  <div className="mt-2 font-bold text-midnight-70">Launch Date</div>
                  <div className="w-44">
                    <input
                      type="date"
                      id="launch_date"
                      min={new Date().toISOString().slice(0, 10)}
                      className={cn('input py-2 input mt-0', errors.launchDate && 'input-error')}
                      {...register('launchDate', {
                        required: 'Please provide the launch date',
                        min: {
                          value: new Date().toISOString().slice(0, 10),
                          message: "Launch date can't be earlier than today",
                        },
                      })}
                    />
                    <InputErrorMessage message={errors.launchDate?.message} />
                  </div>
                </label>

                <h5 className="mt-8 text-lg font-bold">Stock calculation</h5>

                <div className="mt-6">
                  <div className="flex items-center font-bold text-midnight-70">
                    What type of product is it?
                    <Tooltip className="ml-1 font-normal">
                      <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-gray-50">
                        <FiInfo className="stroke-gray-100" size={10} />
                      </Tooltip.Element>
                      <Tooltip.Body>
                        <div className="max-w-xs">
                          Will you be selling this product in different sizes, colors or other
                          options? If so, choose Product with variants.
                          <br />
                          If this product doesn&apos;t have options, or it&apos;s a new option of an
                          existing product, choose Single product.
                        </div>
                      </Tooltip.Body>
                    </Tooltip>
                  </div>
                  <div className="mt-5">
                    <RadioGroup
                      className="flex space-x-6 font-normal"
                      onChange={skuWithVariants.onChange}
                      value={skuWithVariants.value}
                    >
                      {[
                        ['Product with variants', true],
                        ['Single product', false],
                      ].map((item) => (
                        <RadioGroup.Option value={item[1]} key={item[0]}>
                          {({ checked }) => (
                            <div className="flex cursor-pointer items-center">
                              <div className="flex w-5 items-center pb-0.5">
                                <div
                                  className={
                                    checked
                                      ? 'ml-1 h-2 w-2 rounded-full bg-purple-100 ring-2 ring-purple-100 ring-offset-2 ring-offset-gray-10'
                                      : 'h-4 w-4 rounded-full border border-gray-75 bg-white'
                                  }
                                />
                              </div>
                              <div>{item[0]}</div>
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  </div>
                </div>

                <div className="mt-6 font-bold text-midnight-70">
                  Pick similar products to consider:
                </div>

                <div className="min-h-[11rem]">
                  <SkuSelectSearch
                    selectedSkus={selectedSkus}
                    setSelectedSkus={setSelectedSkus}
                    placeholder="Search for specific products"
                    errorMessage={errors.skuWithVariants?.message}
                  />
                </div>
              </div>

              <div className="mx-4 border-l border-gray-50 lg:mx-8 xl:mx-16" />

              <div className="flex w-60 flex-col">
                <h5 className="text-lg font-bold">
                  Predicted Sales <span className="font-normal">(units)</span>
                </h5>

                <div className="mt-6 grid grid-cols-[min-content_2rem_minmax(6rem,auto)] font-bold">
                  {[30, 60, 90].map((item) => (
                    <Fragment key={item}>
                      <div className="flex h-24 items-start justify-end">
                        <div className="whitespace-nowrap rounded-full bg-leafy-10 px-2.5 pt-0.5 text-xs tracking-wider">
                          {item - 30} &ndash; {item} DAYS
                        </div>
                      </div>

                      <div
                        className={cn(
                          'border-r-[3px]',
                          item === 30 ? 'border-purple-100' : 'border-purple-10',
                        )}
                      />

                      <div className="ml-4 flex items-center">
                        <span className="text-lg">
                          {compactNumber(data?.plannedSku?.stats[`sales${item}`] || 0)}
                        </span>
                        <span className="ml-1.5 pt-0.5 text-midnight-75">Units</span>
                      </div>
                    </Fragment>
                  ))}

                  <div className="flex h-24 items-center justify-end text-base">TOTAL:</div>
                  <div />
                  <div className="ml-4 flex items-center font-bold">
                    <span className="text-lg">
                      {compactNumber(
                        Object.entries(data?.plannedSku?.stats || {}).reduce(
                          (acc, stat) => (stat[0].includes('sales') ? acc + stat[1] : 0),
                          0,
                        ) || 0,
                      )}
                    </span>
                    <span className="ml-1.5 pt-0.5  text-midnight-75">Units</span>
                  </div>
                </div>
              </div>
            </div>

            {data?.plannedSku?.simulations?.length > 0 && (
              <div className="ml-0.5 mt-12 border-l-[3px] border-purple-100">
                <div className="mb-7 flex w-full flex-col text-xs">
                  <div className="mb-5 flex space-x-6 pl-4 text-base font-bold">
                    <h5 className="flex-[2]">Simulations</h5>
                    <div className="flex-[2]">Launch date</div>
                    <div className="flex-[3]">Predicted Sales</div>
                    <div className="flex-[2]">Type</div>
                    <div className="flex-[2]">Considered products</div>
                    <div className="w-6" />
                  </div>
                  <ul>
                    <li
                      className={`py-3 pl-4 pr-2 text-sm hover:bg-purple-10 ${
                        simulationOpen === 'main' && 'bg-purple-10'
                      }`}
                    >
                      <div className="flex h-5 w-full items-center justify-start space-x-6">
                        <div className={cn('flex-[2] shrink-0', isDemo && 'blur-sm')}>Main</div>

                        <div className={cn('flex-[2] font-bold truncate', isDemo && 'blur-sm')}>
                          {formatLaunchDate(data.plannedSku.launchDate)}
                        </div>

                        <div className="flex-[3] shrink-0">
                          <span className="font-bold">
                            {compactNumber(
                              data.plannedSku.stats.sales30 +
                                data.plannedSku.stats.sales60 +
                                data.plannedSku.stats.sales90,
                            )}
                          </span>
                          <span className="ml-2">
                            ({compactNumber(data.plannedSku.stats.sales30)}/
                            {compactNumber(data.plannedSku.stats.sales60)}/
                            {compactNumber(data.plannedSku.stats.sales90)})
                          </span>
                        </div>

                        <div className="flex-[2]">
                          {data.plannedSku.multipleVariants ? 'With variants' : 'Single variant'}
                        </div>

                        <div className="mr-6 flex flex-[2] shrink-0 justify-end">
                          <button
                            type="button"
                            className={`flex items-center rounded px-2 py-1 font-bold focus:outline-none focus:ring-2 focus:ring-purple-100 ${
                              data.plannedSku.similarSkus?.length === 0 && 'pointer-events-none'
                            }`}
                            onClick={() =>
                              setSimulationOpen((oldVal) => (oldVal === 'main' ? null : 'main'))
                            }
                          >
                            {data.plannedSku.similarSkus.length}
                            <FiChevronDown
                              strokeWidth={1.5}
                              className={`ml-1 h-5 w-5 origin-center ${
                                simulationOpen === data.plannedSku._id && 'rotate-180'
                              }`}
                            />
                          </button>
                        </div>

                        <div className="h-6 w-6" />
                      </div>

                      <div className={`pb-1 pt-3 ${simulationOpen !== 'main' && 'hidden'}`}>
                        {data.plannedSku.similarSkus?.length > 0 && (
                          <div className="relative">
                            <ul className="mb-2 ml-[9.5rem] max-h-44 space-y-2.5 overflow-y-auto py-2">
                              {data.plannedSku.similarSkus.map((sku) => (
                                <li
                                  key={sku}
                                  className={cn('-ml-1 pl-3 mr-12 truncate', isDemo && 'blur-sm')}
                                >
                                  {sku}
                                </li>
                              ))}
                            </ul>
                            <div className="absolute left-0 top-0 h-3 w-[calc(100%-1rem)] bg-gradient-to-b from-purple-10 to-transparent" />
                            <div className="absolute bottom-0 left-0 h-3 w-[calc(100%-1rem)] bg-gradient-to-b from-transparent to-purple-10" />
                          </div>
                        )}
                      </div>
                    </li>
                    {data.plannedSku.simulations.map((simulation, index) => (
                      <li
                        key={simulation._id}
                        className={`py-3 pl-4 pr-2 text-sm hover:bg-purple-10 ${
                          simulationOpen === simulation._id && 'bg-purple-10'
                        }`}
                      >
                        <div className="flex h-5 w-full items-center justify-start space-x-6">
                          <div className={cn('flex-[2] shrink-0', isDemo && 'blur-sm')}>
                            Option {index + 2}
                          </div>

                          <div className={cn('flex-[2] font-bold truncate', isDemo && 'blur-sm')}>
                            {formatLaunchDate(simulation.launchDate)}
                          </div>

                          <div className="flex-[3] shrink-0">
                            <span className="font-bold">
                              {compactNumber(
                                simulation.stats.sales30 +
                                  simulation.stats.sales60 +
                                  simulation.stats.sales90,
                              )}
                            </span>
                            <span className="ml-2">
                              ({compactNumber(simulation.stats.sales30)}/
                              {compactNumber(simulation.stats.sales60)}/
                              {compactNumber(simulation.stats.sales90)})
                            </span>
                          </div>

                          <div className="flex-[2]">
                            {simulation.multipleVariants ? 'With variants' : 'Single variant'}
                          </div>

                          <div className="mr-6 flex flex-[2] shrink-0 justify-end">
                            <button
                              type="button"
                              className={`flex items-center rounded px-2 py-1 font-bold focus:outline-none focus:ring-2 focus:ring-purple-100 ${
                                simulation.similarSkus?.length === 0 && 'pointer-events-none'
                              }`}
                              onClick={() =>
                                setSimulationOpen((oldVal) =>
                                  oldVal === simulation._id ? null : simulation._id,
                                )
                              }
                            >
                              {simulation.similarSkus.length}
                              <FiChevronDown
                                strokeWidth={1.5}
                                className={`ml-1 h-5 w-5 origin-center ${
                                  simulationOpen === simulation._id && 'rotate-180'
                                }`}
                              />
                            </button>
                          </div>
                          {simulationIdToDelete && simulationIdToDelete === simulation._id ? (
                            <div className="inline-flex h-6 w-6 items-center justify-center">
                              <LoadingIcon className="stroke-gray-100 text-midnight-100" />
                            </div>
                          ) : (
                            <DeleteButton
                              onDelete={() =>
                                removeSimulation({
                                  variables: { _id: simulation._id },
                                })
                              }
                            />
                          )}
                        </div>

                        <div
                          className={`pb-1 pt-3 ${simulationOpen !== simulation._id && 'hidden'}`}
                        >
                          {simulation.similarSkus?.length > 0 && (
                            <div className="relative">
                              <ul className="mb-2 ml-[9.5rem] max-h-44 space-y-2.5 overflow-y-auto py-2">
                                {simulation.similarSkus.map((sku) => (
                                  <li
                                    key={sku}
                                    className={cn('-ml-1 pl-3 mr-12 truncate', isDemo && 'blur-sm')}
                                  >
                                    {sku}
                                  </li>
                                ))}
                              </ul>
                              <div className="absolute left-0 top-0 h-3 w-[calc(100%-1rem)] bg-gradient-to-b from-purple-10 to-transparent" />
                              <div className="absolute bottom-0 left-0 h-3 w-[calc(100%-1rem)] bg-gradient-to-b from-transparent to-purple-10" />
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="mt-6 flex items-center justify-end space-x-2 text-sm">
              <Button label="Cancel" variant="text" href="/planning/planned-products" slim />
              {isNew ? (
                <Button
                  label={savingSku ? 'Saving...' : 'Save planned product'}
                  type="submit"
                  slim
                  icon={savingSku ? LoadingIcon : undefined}
                  disabled={savingSku}
                  className="disabled:border-purple-75 disabled:bg-purple-75 disabled:text-purple-10"
                />
              ) : (
                <div className="inline-flex items-stretch rounded-full border-purple-100 bg-purple-100 ring-offset-white focus:ring-purple-100">
                  <button
                    disabled={(!nest && !isDirty && !isSelectedSkusDirty) || savingSku}
                    className={`whitespace-nowrap rounded-l-full px-4 py-2 text-xs font-bold text-white hover:border-purple-90 hover:bg-purple-90 disabled:cursor-not-allowed disabled:bg-purple-75 disabled:text-purple-10 ${
                      savingSku ? 'inline-flex' : ''
                    }`}
                    type="submit"
                  >
                    {savingSku ? (
                      <>
                        <LoadingIcon className="mr-2" /> Saving...
                      </>
                    ) : (
                      saveOptions.find(({ value }) => value === nest).label
                    )}
                  </button>
                  <div className="w-px bg-purple-75" />
                  <Listbox value={nest} onChange={setNest}>
                    {({ open }) => (
                      <div className="relative">
                        <Listbox.Button
                          className={`flex h-full items-center pl-2 pr-3 hover:border-purple-90 hover:bg-purple-90 ${
                            open ? 'bg-purple-90' : ''
                          } rounded-r-full`}
                        >
                          <FiChevronDown
                            strokeWidth={3}
                            className={`text-white transition-transform duration-200 ease-out ${
                              open ? '-rotate-180' : ''
                            }`}
                            aria-hidden="true"
                          />
                        </Listbox.Button>
                        <Listbox.Options className="absolute right-0 top-0 z-10 -mt-2 w-[320px] -translate-y-full transform overflow-hidden rounded-lg border border-gray-50 bg-white shadow">
                          {saveOptions.map((choice) => (
                            <Listbox.Option key={choice.description} value={choice.value}>
                              {({ selected, active }) => (
                                <div
                                  className={`relative cursor-pointer rounded-lg border-2 py-3 pl-6 pr-10 ${
                                    active ? 'text-purple-110' : ''
                                  } ${
                                    selected
                                      ? 'border-purple-100 bg-purple-10 text-purple-110'
                                      : 'border-transparent'
                                  }`}
                                >
                                  {selected && (
                                    <div className="absolute right-0 mr-4 mt-1 rounded-full bg-purple-100 p-1 text-white">
                                      <FiCheck size={14} />
                                    </div>
                                  )}
                                  <p className="font-bold">{choice.label}</p>
                                  <p className="mt-1">{choice.description}</p>
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    )}
                  </Listbox>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PlannedProduct;
