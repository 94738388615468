import React from 'react';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import Tooltip from '../Components/Tooltip.jsx';

const OnHandTarget = ({ quantity, target, format = {}, sourceCount }) => {
  const percent = target !== 0 ? (quantity / target) * 100 : 0;
  const progressBarWidth = quantity > 0 && target === 0 ? 100 : Math.max(percent, 0);

  let barBgColor;
  let progressBgColor;
  let textColor;

  // for example, if "on hand" percent is 3 and target is 0, we show green bar that is 100% full
  if (percent > 70 || (quantity > 0 && target === 0)) {
    barBgColor = 'bg-leafy-50';
    progressBgColor = 'bg-leafy-75';
    textColor = 'text-leafy-100';
  } else if (percent > 30) {
    barBgColor = 'bg-yellow-10';
    progressBgColor = 'bg-yellow-75';
    textColor = 'text-yellow-100';
  } else {
    barBgColor = 'bg-red-50';
    progressBgColor = 'bg-red-85';
    textColor = 'text-red-100';
  }

  const formatOptions = [navigator.language, format];
  const formattedLabel = `${quantity?.toLocaleString(...formatOptions) || '-'} / ${
    target?.toLocaleString(...formatOptions) || '-'
  }`;

  if (!Number.isInteger(quantity) && !Number.isInteger(target)) return '-';

  return (
    <div>
      <div className="flex items-center justify-center space-x-2">
        {sourceCount > 1 ? (
          <Tooltip className="mr-1">
            <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-yellow-75">
              <FiAlertTriangle className="stroke-yellow-100" size={10} />
            </Tooltip.Element>
            <Tooltip.Body align="left">
              <div>
                Includes inventory from {sourceCount} variants. You may have multiple products that
                share this SKU.
              </div>
            </Tooltip.Body>
          </Tooltip>
        ) : null}
        <div className={`text-xs font-bold ${textColor}`}>{formattedLabel}</div>
      </div>
      <div className={`mt-1 h-1 rounded-sm ${barBgColor} overflow-hidden`}>
        <div className={`h-full ${progressBgColor}`} style={{ width: `${progressBarWidth}%` }} />
      </div>
    </div>
  );
};

OnHandTarget.propTypes = {
  quantity: PropTypes.number,
  target: PropTypes.number,
  format: PropTypes.shape(),
  sourceCount: PropTypes.number,
};

OnHandTarget.defaultProps = {
  quantity: null,
  target: null,
  format: {},
  sourceCount: 0,
};

export default OnHandTarget;
