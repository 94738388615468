import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FiChevronDown, FiInfo } from 'react-icons/fi';
import { Menu } from '@headlessui/react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import CopyButton from '../../Components/CopyButton.jsx';
import Tooltip from '../../Components/Tooltip.jsx';
import Panel from '../../Dashboard/Widgets/Widget.jsx';
import { toDate } from '../../Utils/dates.js';

const POsWidget = ({ purchaseOrders, transfers }) => {
  // grouping shipments for each PO by destination locations
  const posWithLocations = purchaseOrders.map((po) => {
    const poTransfers = transfers.filter(({ node: { purchaseOrder } }) => purchaseOrder === po._id);
    const locationsWithCount = poTransfers.reduce((locations, transfer) => {
      const { _id, name } = transfer.node.toLocation;
      const { quantity } = transfer.node.items.find(({ sku }) => po.item.sku === sku);
      const existingLocation = locations.find((loc) => loc._id === _id);
      if (existingLocation) {
        existingLocation.count += quantity;
        return locations;
      }
      return [...locations, { _id, name, count: quantity }];
    }, []);
    return { ...po, locationsWithCount };
  });

  return (
    <Panel barColor="purple" className="col-span-4 h-auto self-start">
      <Panel.Title>Purchase Orders</Panel.Title>
      <Panel.Content>
        <div className="grid grid-cols-[1.25fr,1.5fr,.5fr,.75fr] items-center pb-2 text-xs font-bold">
          <div className="pl-1 text-left">ID</div>
          <div className="flex items-center pl-2 text-left">
            Destination
            <Tooltip>
              <Tooltip.Element className="ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-gray-50">
                <FiInfo className="stroke-gray-100" size={12} />
              </Tooltip.Element>
              <Tooltip.Body>
                Locations that have incoming shipments and their total shipment quantities.
              </Tooltip.Body>
            </Tooltip>
          </div>
          <div className="text-left">Units</div>
          <div className="pr-1 text-right">ETA</div>
        </div>
        {posWithLocations.map((po) => (
          <div
            key={po.customId ?? po._id}
            className="group grid grid-cols-[1fr,1.5fr,.5fr,1fr] items-center text-xs"
          >
            <div className="flex rounded-l py-2 group-hover:bg-leafy-10">
              <div className="flex items-center rounded bg-leafy-10">
                <Link to={`/purchase-orders/${po._id}`} className="w-20">
                  <div className="truncate rounded-l py-1" title={po.customId ?? po._id}>
                    <span className="ml-1">{po.customId ?? po._id}</span>
                  </div>
                </Link>
                <div className="rounded-r p-1">
                  <CopyButton
                    clipboardValue={po.customId ?? po._id}
                    copySubject="Purchase order ID"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center self-stretch py-1 group-hover:bg-leafy-10">
              <Menu className="relative" as="div">
                {({ open }) => (
                  <>
                    <Menu.Button className="-ml-1 flex w-[5.5rem] items-center justify-between py-1.5 font-bold">
                      {po.locationsWithCount.length > 0 ? (
                        <span>
                          {po.locationsWithCount.length}&nbsp;
                          {pluralize('location', po.locationsWithCount.length)}
                        </span>
                      ) : (
                        '-'
                      )}
                      {po.locationsWithCount.length > 0 && (
                        <FiChevronDown
                          size={16}
                          className={classnames(
                            open && 'rotate-180',
                            'stroke-purple-100 transition-transform',
                          )}
                        />
                      )}
                    </Menu.Button>
                    <Menu.Items
                      as="ul"
                      className="absolute z-20 -ml-3 space-y-2 rounded-lg bg-purple-100 py-2 text-white shadow-md"
                    >
                      {po.locationsWithCount.map((item) => (
                        <Menu.Item
                          key={item.name}
                          as="li"
                          className="flex items-center justify-between bg-purple-100 px-2 text-white first:rounded-t-md last:rounded-b-md"
                        >
                          <span className="block w-[8rem] truncate capitalize" title={item.name}>
                            {item.name}&nbsp;
                          </span>
                          {item.count}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </>
                )}
              </Menu>
            </div>
            <div className="py-3 pr-1 text-right group-hover:bg-leafy-10">
              {po.item?.inTransitQuantity ?? po.item?.quantity}
            </div>
            <div className="rounded-r py-3 pr-1 text-right group-hover:bg-leafy-10">
              {po.expectedDeliveryDate
                ? toDate(po.expectedDeliveryDate).toLocaleDateString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  })
                : '-'}
            </div>
          </div>
        ))}
      </Panel.Content>
    </Panel>
  );
};

POsWidget.propTypes = {
  purchaseOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transfers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default POsWidget;
