import isObject from 'lodash/isObject';

export const isMergedSku = (account, sku) =>
  account.skuMerges?.some((s) => s.skuFrom === sku) || false;

export const getSkuPagePath = (_sku) => {
  const path = '/skus';
  let sku = structuredClone(_sku);
  if (isObject(sku)) {
    if (sku.type === 'component') {
      return `${path}/${encodeURIComponent(sku.sku)}/edit`;
    }
    sku = sku.sku;
  }

  return `${path}/${encodeURIComponent(sku)}`;
};
