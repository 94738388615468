import React from 'react';
import PropTypes from 'prop-types';

const MaypleIcon = ({ className = '' }) => (
  <img src="https://app.maypleglobal.com/favicon.ico" className={className} alt="" />
);

MaypleIcon.propTypes = {
  className: PropTypes.string,
};

MaypleIcon.defaultProps = {
  className: null,
};

export default MaypleIcon;
