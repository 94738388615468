import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const CONNECT_WITH_FLEXE = gql`
  mutation ConnectWithFlexe($token: String!) {
    connectWithFlexe(token: $token) {
      _id
    }
  }
`;

const FlexePopup = ({ open, onClose }) => {
  const { addAlert } = useAlerts();

  const [connectWithFlexe, { loading }] = useMutation(CONNECT_WITH_FLEXE);

  const handleFlexeConnect = React.useCallback(
    (e) => {
      e.preventDefault();

      const variables = Object.fromEntries(new FormData(document.querySelector('form')));

      const connect = async () => {
        await connectWithFlexe({ variables });
        addAlert('Successfully connected to Flexe', { level: 'success' });
        onClose();
      };

      connect();
    },
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Flexe</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleFlexeConnect}>
            <div>
              <label htmlFor="flexe-token">
                Flexe Token*
                <input type="text" id="flexe-token" required className="input" name="token" />
                <p className="mt-2 text-gray-100">
                  Contact Flexe support and let them know that you are working with Cogsy to
                  integrate into Flexe. They will give you an API token, which you can use here.
                </p>
              </label>
            </div>
            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

FlexePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FlexePopup;
