import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = ({
  label,
  disabled,
  href,
  fullWidth,
  icon,
  iconSize,
  onClick,
  type,
  slim,
  variant,
  target,
  className,
}) => {
  let textColor = 'text-white';
  let hoverTextColor = 'hover:text-white';
  let backgroundColor = 'bg-purple-100';
  let hoverBackgroundColor = 'hover:bg-purple-90';
  let hoverBorderColor = 'hover:border-purple-90';
  let activeBackgroundColor = 'active:bg-purple-110';
  let activeBorderColor = 'active:border-purple-110';
  let borderColor = 'border-purple-100';
  let focusRingColor = 'focus:ring-purple-100';
  let ringOffsetColor = 'ring-offset-white';
  let padding = slim ? 'py-1 px-3' : 'py-3';
  let disabledStyles = 'disabled:bg-gray-75 disabled:border-gray-75 disabled:text-gray-100';

  if (variant === 'secondary') {
    textColor = 'text-white';
    hoverTextColor = 'hover:text-white';
    backgroundColor = 'bg-leafy-75';
    hoverBackgroundColor = 'hover:bg-leafy-65';
    hoverBorderColor = 'hover:border-leafy-65';
    activeBackgroundColor = 'active:bg-leafy-85';
    activeBorderColor = 'active:border-leafy-85';
    borderColor = 'border-leafy-75';
    focusRingColor = 'focus:ring-leafy-75';
  }

  if (variant === 'tertiary') {
    textColor = 'text-purple-100';
    backgroundColor = 'bg-transparent';
    disabledStyles = 'disabled:bg-white disabled:border-gray-75 disabled:text-gray-75';
  }

  if (variant === 'danger') {
    textColor = 'text-red-100';
    hoverTextColor = 'hover:text-red-100';
    backgroundColor = 'bg-red-75';
    hoverBackgroundColor = 'hover:bg-red-50';
    borderColor = 'border-red-75';
    hoverBorderColor = 'hover:border-red-50';
    activeBackgroundColor = 'active:bg-red-85';
    activeBorderColor = 'active:border-red-85';
    focusRingColor = 'focus:ring-red-75';
  }

  if (variant === 'text') {
    textColor = 'text-purple-100';
    hoverTextColor = 'hover:text-purple-110';
    backgroundColor = 'bg-transparent';
    hoverBackgroundColor = 'hover:bg-transparent';
    borderColor = 'border-transparent';
    hoverBorderColor = 'hover:border-transparent';
    activeBackgroundColor = 'active:bg-transparent';
    activeBorderColor = 'active:border-transparent';
    disabledStyles = 'disabled:bg-white disabled:text-gray-75';
  }

  if (variant === 'danger-text') {
    textColor = 'text-red-100';
    hoverTextColor = 'hover:text-red-100';
    backgroundColor = 'bg-transparent';
    hoverBackgroundColor = 'hover:bg-transparent';
    borderColor = 'border-transparent';
    hoverBorderColor = 'hover:border-transparent';
    activeBackgroundColor = 'active:bg-transparent';
    activeBorderColor = 'active:border-transparent';
    focusRingColor = 'ring-transparent';
    ringOffsetColor = 'ring-offset-transparent';
    padding = 'px-0 py-1';
  }

  if (href?.match(/^http/)) {
    return (
      <a
        href={href}
        className={`${fullWidth ? 'w-full' : 'px-6'} ${
          slim ? 'px-3 py-1' : 'py-3'
        } ${textColor} ${hoverTextColor} ${backgroundColor} ${hoverBackgroundColor} border-2 ${borderColor} ${hoverBorderColor} ${activeBorderColor} ${activeBackgroundColor} inline-flex items-center justify-center text-xs focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingColor} rounded-full font-bold ${
          disabled ? 'pointer-events-none' : ''
        }`}
        target={target}
      >
        {icon && icon({ className: 'mr-1', size: iconSize })}
        {label}
      </a>
    );
  }

  if (href) {
    return (
      <Link
        to={href}
        className={`${fullWidth ? 'w-full' : 'px-6'} ${
          slim ? 'px-3 py-1' : 'py-3'
        } ${textColor} ${hoverTextColor} ${backgroundColor} ${hoverBackgroundColor} border-2 ${borderColor} ${hoverBorderColor} ${activeBorderColor} ${activeBackgroundColor} inline-flex items-center justify-center text-xs focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingColor} rounded-full font-bold ${
          disabled ? 'pointer-events-none' : ''
        }`}
        target={target}
      >
        {icon && icon({ className: 'mr-1', size: iconSize })}
        {label}
      </Link>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${
        fullWidth ? 'w-full' : 'px-6'
      } ${padding} ${textColor} ${hoverTextColor} ${backgroundColor} ${hoverBackgroundColor} border-2 ${borderColor} ${hoverBorderColor} ${activeBorderColor} ${activeBackgroundColor} inline-flex items-center justify-center text-xs focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingColor} ${disabledStyles} rounded-full font-bold disabled:cursor-not-allowed ${ringOffsetColor} ${className}`}
    >
      {icon && icon({ className: 'mr-1', size: iconSize })}
      {label}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.func,
  iconSize: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  slim: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'text', 'danger', 'danger-text']),
  target: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  fullWidth: false,
  href: null,
  icon: null,
  iconSize: null,
  onClick: null,
  slim: false,
  type: 'button',
  variant: 'primary',
  target: null,
  className: '',
};

export default Button;
