import React from 'react';
import PropTypes from 'prop-types';

const InputErrorMessage = ({ message }) => (message && <p className="mt-2 text-xs text-red-100">{message}</p>);

InputErrorMessage.propTypes = {
  message: PropTypes.string,
};

InputErrorMessage.defaultProps = {
  message: null,
};

export default InputErrorMessage;
