/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  gql,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Query } from '@apollo/client/react/components';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import axios from 'axios';
import { useAlerts } from './Components/AlertsProvider.jsx';
import LoadingPage from './Components/LoadingPage.jsx';
import { apiUrl, disableWss, growthbook as growthbookConfig } from './config.js';
import env from './env.js';

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

const AccountContext = React.createContext(null);

const useAccount = () => React.useContext(AccountContext);

// eslint-disable-next-line import/no-unused-modules
export const ACCOUNT_FIELDS = gql`
  fragment AccountFields on Account {
    _id
    name
    url
    preferences {
      countryCode
      currency
      averageOrderLeadTime
      inventorySafetyMargin
      averageBackorderBufferTime
      outOfStockText
      forecastEnabled
      forecastType
      backorder
      blockedGroups
      forecastOptions
      replenishmentAlerts {
        enabled
        frequency
      }
      productsViewAdditionalColumns
      growthPlanOptions {
        growthPlanType
        granularity
        lookback
        horizon
        D
        d
        m
        maximumGrowthValue
      }
      smartPrompts
    }
    sources {
      sourceType
      sourceId
      sourceContext
      syncAnalytics
      syncBasics
      syncBundles
      syncExport
      syncInventoryQuantities
      syncOrders
      syncPurchaseOrders
      syncSkus
      syncTransfers
      syncSubscriptions
      isDefaultSource
      isSuspended
      updateInventory
    }
    onboarding {
      hasSkusSyncCompleted
      hasOrdersSyncCompleted
      hasForecastCompleted
      hasPurchaseOrdersSyncCompleted
      hasBasicSyncCompleted
      hasInventorySyncCompleted
    }
    forecast {
      status
    }
    isSubscribed
    billing {
      platform
      trialStartedAt
      trialEndsAt
    }
    hasActivePlan
    skuMerges {
      skuFrom
      skuTo
    }
    isSyncInProgress
    statsInfo {
      refreshViews
      refreshViewsStartedAt
      refreshViewsEndedAt
      refreshViewsLastError
      updatedData
    }
    hasSyncError
  }
`;

const ACCOUNT_QUERY = gql`
  ${ACCOUNT_FIELDS}

  query {
    account {
      ...AccountFields
    }

    user {
      _id
      email
      firstName
      lastName
      fullName
      isAdmin
      isReadOnly
    }

    locations(first: 100) {
      edges {
        node {
          _id
          name
          isShippingEnabled
          hideReplenishment
          sources {
            sourceId
          }
        }
      }
    }
  }
`;

const ACCOUNT_UPDATED_SUBSCRIPTION = gql`
  ${ACCOUNT_FIELDS}

  subscription {
    accountUpdated {
      ...AccountFields
    }
  }
`;

const handleUnauthorized = () => {
  window.localStorage.removeItem('token');
  window.location.assign(`/login?u=${encodeURIComponent(window.location.href)}`);
};

// eslint-disable-next-line import/no-unused-modules
export const GraphQLClientProvider = ({ token, children, customErrorHandler }) => {
  const [apolloClient, setApolloClient] = React.useState(null);
  const { addAlert } = useAlerts();
  const handleError = React.useCallback((error) => {
    if (customErrorHandler) {
      customErrorHandler(error);
      return;
    }

    if (error?.networkError?.statusCode === 401) {
      handleUnauthorized();
      return;
    }

    // Log error if not a 401
    Sentry.captureException(error);

    addAlert('An error has occurred! Please refresh the page and try again.', { error });
  }, []);

  React.useEffect(() => {
    const init = async () => {
      try {
        const authLink = setContext((_, { headers }) => ({
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        }));

        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (customErrorHandler) {
            customErrorHandler({ graphQLErrors, networkError });
            return;
          }
          if (networkError?.statusCode === 401) {
            handleUnauthorized();
            return;
          }

          let error;
          if (graphQLErrors)
            error = `[GraphQL error]: Message: ${graphQLErrors[0].message}, Path: ${graphQLErrors[0].path}`;

          if (networkError) error = `[Network error]: ${networkError}`;

          // eslint-disable-next-line no-console
          console.log(error);

          addAlert(error, { error });
        });

        let link = errorLink.concat(authLink.concat(createHttpLink({ uri: `${apiUrl}/graphql` })));

        if (!disableWss) {
          link = split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
              );
            },
            new WebSocketLink({
              uri: `wss://${new URL(apiUrl).host}/graphql`,
              options: {
                reconnect: true,
                connectionParams: { token },
              },
            }),
            link,
          );
        }

        const apolloClient = new ApolloClient({
          link,
          cache: new InMemoryCache({
            typePolicies: {
              LocationStats: {
                keyFields: false,
              },
              GrowthModelStats: {
                merge: true,
              },
              SkuStats: {
                merge: true,
              },
            },
          }),
          connectToDevTools: env.DEV,
        });

        if (!disableWss) {
          apolloClient.subscribe({ query: ACCOUNT_UPDATED_SUBSCRIPTION }).subscribe({
            complete: async () => apolloClient.resetStore(), // resetStore automatically refetches all active queries
          });
        }

        setApolloClient(apolloClient);
      } catch (e) {
        handleError(e);
      }
    };

    init();
  }, [token]);

  if (!apolloClient) return <LoadingPage />;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

GraphQLClientProvider.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  customErrorHandler: PropTypes.func,
};

GraphQLClientProvider.defaultProps = {
  customErrorHandler: null,
};

const AccountProvider = ({ token, children }) => {
  const { addAlert } = useAlerts();
  const location = useLocation();

  const handleError = React.useCallback((error) => {
    if (error?.networkError?.statusCode === 401) {
      handleUnauthorized();
      return;
    }

    // Log error if not a 401
    Sentry.captureException(error);

    addAlert('An error has occurred! Please refresh the page and try again.', { error });
  }, []);

  React.useEffect(() => {
    axiosInstance.defaults.headers = { Authorization: `Bearer ${token}` };
  }, [token]);

  return (
    <GraphQLClientProvider token={token}>
      <Query query={ACCOUNT_QUERY} onError={handleError}>
        {({ data }) => {
          const [growthbook, setGrowthbook] = React.useState(null);
          const [showHints, setShowHints] = React.useState(
            JSON.parse(localStorage.getItem('showHints')) ?? false,
          );

          React.useEffect(() => {
            if (!data?.account?._id) return;

            window.analytics?.identify(data.user._id, {
              name: data.user.fullName,
              email: data.user.email,
              url: data.account.url,
              company: data.account.name,
              account: data.account._id,
            });

            window._cio?.identify({
              id: data.user._id,
              email: data.user.email,
            });

            Sentry.getCurrentHub().configureScope((scope) => {
              scope.setUser({
                id: data.user._id,
                email: data.user.email,
                account: data.account._id,
                accountName: data.account.name,
              });
            });

            const growthbook = new GrowthBook({
              attributes: {
                id: data.account._id,
                loggedIn: true,
                company: data.account.name,
                isAdmin: data.user.isAdmin,
                isReadOnly: data.user.isReadOnly,
              },
            });

            setGrowthbook(growthbook);

            axios(growthbookConfig.apiEndpoint).then((res) =>
              growthbook.setFeatures(res.data.features),
            );
          }, [data?.account?._id]);

          const value = React.useMemo(
            () => ({
              account: data?.account,
              locations: data?.locations,
              user: data?.user,
              axios: axiosInstance,
              showHints,
              setShowHints,
              formatCurrency: (amount, options = {}, fallback = '') => {
                if (amount == null || Number.isNaN(amount)) return fallback;
                return amount.toLocaleString(navigator.language, {
                  style: 'currency',
                  currency: data?.account.preferences.currency || 'USD',
                  ...options,
                });
              },
              formatNumber: (number, fallback = '-') => {
                if (number == null) return fallback;
                return number.toLocaleString(navigator.language);
              },
              currencySymbol: Number(0)
                .toLocaleString(navigator.language, {
                  style: 'currency',
                  currency: data?.account.preferences.currency || 'USD',
                  maximumFractionDigits: 0,
                  notation: 'compact',
                })
                .slice(0, -1),
            }),
            [data, axiosInstance, showHints],
          );

          React.useEffect(() => {
            if (data) {
              const params = new URLSearchParams(location.search);
              window.analytics?.page({
                account: data.account._id,
                email: data.user.email,
                searchParams: Object.fromEntries(params),
              });
            }
          }, [location.pathname, location.search, data]);

          if (!data || !growthbook) return <LoadingPage />;

          return (
            <GrowthBookProvider growthbook={growthbook}>
              <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
            </GrowthBookProvider>
          );
        }}
      </Query>
    </GraphQLClientProvider>
  );
};

AccountProvider.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default AccountProvider;
export { useAccount };
