import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '../Components/Button.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const ErrorFallback = ({ error, resetError }) => {
  useDocumentTitle('There has been an error');
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center" style={{ minHeight: 'calc(100vh - 6rem)' }}>
      <div className="text-center text-midnight-100">
        <h1 className="text-6xl font-bold text-purple-100">Something has gone wrong!</h1>
        <pre className="mt-8 inline-block rounded-lg bg-gray-50 px-4 py-2">{error.message}</pre>
        <p className="mt-8 text-lg">
          Please try again later. If the problem persists, contact us at&nbsp;
          <a href="mailto:admin@cogsy.com?subject=Error on cogsy.com" className="text-purple-100">
            admin@cogsy.com
          </a>
        </p>
        <p className="text-lg">
          Meanwhile, you can click the button below to go back to the homepage.
        </p>
        <div className="mt-8">
          <Button
            label="Home"
            onClick={() => {
              resetError();
              navigate('/');
              window.location.reload();
            }}
          />
        </div>
      </div>
    </div>
  );
};

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

export default ErrorFallback;
