import React from 'react';
import PropTypes from 'prop-types';

const AmazonIcon = ({ className = '', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="14px"
    viewBox="0 0 16 16"
    className={className}
    {...props}
  >
    <path d="M7.61656 0.738555C7.25125 0.77859 6.62571 0.91871 6.2654 1.04882C5.65988 1.26401 5.22952 1.53424 4.78413 1.97461C4.44384 2.3149 4.33875 2.46003 4.14859 2.85537C3.82832 3.53595 3.72823 4.02137 3.88837 4.15648C3.95843 4.21653 5.66489 4.45674 6.02019 4.45674C6.25039 4.45674 6.32545 4.36666 6.46057 3.92628C6.59068 3.4809 6.89094 3.1256 7.29628 2.92543C7.68161 2.74027 8.22208 2.73026 8.60741 2.90041C9.16789 3.15062 9.38307 3.60101 9.38807 4.55682L9.39308 5.13232L8.41724 5.23741C7.24624 5.36752 6.68576 5.47261 6.11527 5.66777C4.92425 6.08313 4.20864 6.65362 3.79829 7.51435C3.67318 7.77458 3.54307 8.10986 3.50304 8.25999C3.40796 8.66534 3.42297 9.75627 3.53306 10.1616C3.97844 11.808 5.3296 12.5387 7.36634 12.2434C8.20706 12.1183 8.73752 11.8431 9.52319 11.1224L9.80343 10.8672L10.0336 11.2025C10.1637 11.3927 10.434 11.7029 10.6391 11.9031C11.1746 12.4286 11.1546 12.4336 12.1504 11.5678C13.0612 10.7822 13.1863 10.642 13.1413 10.4919C13.1263 10.4319 12.9811 10.2167 12.821 10.0115C12.5308 9.62616 12.4007 9.41098 12.3056 9.14075C12.2705 9.04066 12.2405 7.95473 12.2205 6.13317C12.1855 3.03052 12.1855 3.03052 11.8852 2.40498C11.695 2.00964 11.1896 1.52423 10.6942 1.26901C9.84847 0.828632 8.66746 0.628461 7.61656 0.738555ZM9.40809 7.61944C9.38807 8.33005 9.36305 8.53523 9.27798 8.77043C8.92768 9.73626 8.34718 10.2617 7.63657 10.2617C7.13615 10.2617 6.82088 10.0765 6.58568 9.65118C6.46557 9.42099 6.44055 9.3209 6.42554 8.90054C6.40052 8.32505 6.48059 8.00978 6.74581 7.64447C7.15616 7.07898 7.92682 6.80375 9.21292 6.76371L9.42811 6.75871L9.40809 7.61944Z" />
    <path d="M14.8178 11.5628C13.872 11.6679 13.0814 12.1934 13.2615 12.5887C13.3416 12.7588 13.5067 12.7839 14.0322 12.7188C14.2824 12.6838 14.4926 12.6688 14.5076 12.6838C14.5476 12.7238 13.2865 13.4895 12.9162 13.6446C11.7152 14.15 10.1238 14.4853 8.64256 14.5504C7.38648 14.6004 6.1204 14.4303 5.01946 14.065C4.35389 13.8498 2.70248 13.0641 2.03691 12.6538C1.40637 12.2634 1.19118 12.1633 0.996016 12.1633C0.740798 12.1633 0.560644 12.4186 0.645716 12.6588C0.690755 12.7739 1.53147 13.4995 2.27211 14.055C4.58909 15.7964 6.75594 16.452 9.14299 16.1417C11.0897 15.8865 12.691 15.281 14.4225 14.145C14.8228 13.8848 15.1531 13.6796 15.1631 13.6896C15.1731 13.6946 15.1081 13.8848 15.023 14.105C14.8529 14.5454 14.8579 14.7155 15.038 14.8406C15.2382 14.9807 15.3783 14.9357 15.6786 14.6204C15.8687 14.4253 16.0339 14.1901 16.169 13.9048C16.3742 13.4895 16.6244 12.6337 16.6244 12.3685C16.6244 12.1733 16.5293 11.8781 16.4442 11.788C16.2541 11.6029 15.4684 11.4928 14.8178 11.5628Z" />
  </svg>
);

AmazonIcon.propTypes = {
  className: PropTypes.string,
};

AmazonIcon.defaultProps = {
  className: null,
};

export default AmazonIcon;
