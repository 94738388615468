import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiArrowRight, FiAward, FiEdit2, FiPlus, FiRotateCcw } from 'react-icons/fi';
import { Dialog } from '@headlessui/react';
import classnames from 'classnames';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import EmptyTable from '../Components/EmptyTable.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { PrevNextButtons } from '../Components/Pagination.jsx';
import Spinner from '../Components/Spinner.jsx';
import Tooltip from '../Components/Tooltip.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import VendorForm from './Form.jsx';

export const FRAGMENT_VENDOR = gql`
  fragment VendorFields on Vendor {
    _id
    name
    leadTime
    sources {
      sourceId
      isDefault
    }
  }
`;

const GET_VENDORS = gql`
  ${FRAGMENT_VENDOR}

  query getVendors($first: Int, $after: String, $before: String) {
    vendors(first: $first, after: $after, before: $before) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          ...VendorFields
          activePurchaseOrdersCount
        }
      }
    }
  }
`;

const CREATE_VENDOR = gql`
  mutation CreateVendor($vendor: VendorInputType!) {
    createVendor(vendor: $vendor) {
      _id
    }
  }
`;

const UPDATE_VENDORS = gql`
  ${FRAGMENT_VENDOR}

  mutation UpdateVendors($vendors: [VendorInputType], $input: UpdatePreferencesInput!) {
    updateVendors(vendors: $vendors) {
      ...VendorFields
    }
    updateAccountPreferences(input: $input) {
      averageOrderLeadTime
    }
  }
`;

const MERGE_VENDORS = gql`
  ${FRAGMENT_VENDOR}

  mutation MergeVendors($input: [String]) {
    mergeVendors(vendors: $input) {
      ...VendorFields
    }
  }
`;

const VendorRow = ({
  vendor,
  mergeVendorsMode,
  isSelected,
  toggleIsSelected,
  isPrimaryVendor,
  averageOrderLeadTime,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleRowClick = () => {
    if (mergeVendorsMode) {
      toggleIsSelected(vendor);
    }
  };

  const vendorSource = vendor.sources.find(({ isDefault }) => isDefault) || vendor.sources[0];

  return (
    <tr
      className={classnames(
        'group',
        isSelected ? 'border-l-4 border-purple-100 bg-purple-10' : 'hover:bg-leafy-10',
        mergeVendorsMode && 'cursor-pointer',
      )}
      onClick={handleRowClick}
    >
      <td
        className={classnames(
          'w-4/12 max-w-[1px] truncate py-4 text-xs',
          isSelected ? 'pl-5.5' : 'pl-6',
        )}
      >
        <input type="hidden" defaultValue={vendor._id} {...register(`vendors.${vendor._id}._id`)} />
        <span>{vendor.name}</span>
        <br />
        <span className="text-xs italic text-gray-100">{vendorSource.sourceId}</span>
      </td>
      <td className="w-3/12 pl-2 text-xs">
        {!mergeVendorsMode ? (
          <div className="relative">
            <input
              type="number"
              step="1"
              className={classnames(
                'w-24 rounded border border-gray-50 py-1 pl-3 pr-11 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100',
                errors.vendors?.[vendor._id]?.leadTime && 'input-error',
              )}
              defaultValue={
                Number.isInteger(vendor.leadTime) ? vendor.leadTime : averageOrderLeadTime
              }
              {...register(`vendors.${vendor._id}.leadTime`, {
                valueAsNumber: true,
                required: 'Order Lead Time is required',
                min: {
                  value: 0,
                  message: "Order Lead Time can't be negative",
                },
              })}
              title={errors.vendors?.[vendor._id]?.leadTime.message}
            />
            <span className="absolute left-14 top-1.5">days</span>
          </div>
        ) : (
          <div>
            {Number.isInteger(vendor.leadTime) ? vendor.leadTime : averageOrderLeadTime} days
          </div>
        )}
      </td>
      <td className="w-2/12 px-6 text-right text-xs">{vendor.activePurchaseOrdersCount}</td>
      <td className="w-3/12 pr-6 text-right text-xs">
        {mergeVendorsMode ? (
          <div className="flex justify-end">
            {isPrimaryVendor && (
              <div className="mr-4 flex font-bold text-purple-100">
                <FiAward className="mr-1 stroke-2" />
                Main vendor
              </div>
            )}
            <input
              type="checkbox"
              checked={isSelected}
              className="rounded border-gray-75 text-purple-100 focus:ring-purple-100"
              onChange={() => toggleIsSelected(vendor)}
            />
          </div>
        ) : (
          <div className="flex justify-end text-purple-100">
            <FiEdit2 className="group-hover:hidden" size={12} />
            <Link
              icon={<FiEdit2 className="" size={12} />}
              className="hidden text-xs font-bold text-purple-100 group-hover:block"
              to={`/vendors/${vendor._id}`}
            >
              Edit
            </Link>
          </div>
        )}
      </td>
    </tr>
  );
};

VendorRow.propTypes = {
  mergeVendorsMode: PropTypes.bool.isRequired,
  toggleIsSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPrimaryVendor: PropTypes.bool.isRequired,
  averageOrderLeadTime: PropTypes.number.isRequired,
  vendor: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    leadTime: PropTypes.number,
    activePurchaseOrdersCount: PropTypes.number,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        sourceId: PropTypes.string,
      }),
    ),
  }),
};

VendorRow.defaultProps = {
  vendor: {
    leadTime: null,
  },
};

const Vendors = () => {
  const { addAlert } = useAlerts();
  const { account } = useAccount();
  const { search } = useLocation();
  const navigate = useNavigate();

  useDocumentTitle('Vendors');
  const [variables, setVariables] = useState({ first: 10 });
  const [mergeVendorsMode, setMergeVendorsMode] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);

  const { loading, data } = useQuery(GET_VENDORS, { variables, fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    const query = new URLSearchParams(search);

    setVariables({
      ...variables,
      after: query.get('after'),
      before: query.get('before'),
    });
  }, [search]);

  const [updateVendors] = useMutation(UPDATE_VENDORS);
  const [mergeVendors] = useMutation(MERGE_VENDORS);
  const [createVendor] = useMutation(CREATE_VENDOR, {
    refetchQueries: [GET_VENDORS],
    onCompleted: (result) => {
      navigate(`${result.createVendor._id}`);
    },
  });

  const toggleIsSelected = (vendor) => {
    if (!selectedVendors.some((selectedVendor) => selectedVendor._id === vendor._id)) {
      setSelectedVendors([...selectedVendors, vendor]);
    } else {
      setSelectedVendors(
        selectedVendors.filter((selectedVendor) => selectedVendor._id !== vendor._id),
      );
    }
  };

  const handleModeSwitch = () => {
    setMergeVendorsMode(!mergeVendorsMode);
    setSelectedVendors([]);
  };

  const methods = useForm({ mode: 'all' });

  const onSubmit = async ({ vendors = [], averageOrderLeadTime }) => {
    // Shape of the vendor parameter:
    // vendors = { _id: { _id, name, leadTime }, ... }

    await updateVendors({
      variables: {
        vendors: Object.keys(vendors).map((vendorId) => ({ ...vendors[vendorId] })),
        input: { averageOrderLeadTime },
      },
    });
    methods.reset({ vendors, averageOrderLeadTime });
    addAlert('Changes saved successfully', { level: 'success' });
  };

  const onMerge = async () => {
    if (selectedVendors.length < 2) {
      addAlert('Please select at least 2 vendors to perform a merge', { level: 'warning' });
    } else {
      const vendorIds = selectedVendors.map((vendor) => vendor._id);

      await mergeVendors({
        variables: { input: vendorIds },
        update: (cache) => {
          vendorIds
            .slice(1)
            .forEach((_id) => cache.evict({ _id: cache.identify({ _id, __typename: 'Vendor' }) }));
          cache.gc();
        },
      });
      addAlert('Vendors merged successfully', { level: 'success' });
      setSelectedVendors([]);
    }
  };

  const onCreateVendorSubmit = async (vendor) => {
    await createVendor({ variables: { vendor } });
    setVendorDialogOpen(false);
    addAlert('Vendor created successfully', { level: 'success' });
  };

  return (
    <>
      <div className="col-span-6 max-w-2xl text-midnight-100">
        <PageHeader text="Vendors" />
        {loading && <Spinner />}
        {!loading && (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {!mergeVendorsMode && (
                <div className="mt-12 flex items-end justify-between text-xs">
                  <label htmlFor="average_order_lead_time" className="inline-block">
                    <span className="font-bold">Average Order Lead Time</span>
                    <div className="relative w-28">
                      <input
                        id="average_order_lead_time"
                        type="number"
                        step="1"
                        className={classnames(
                          'input py-2 pr-12',
                          methods.formState.errors.averageOrderLeadTime && 'input-error',
                        )}
                        defaultValue={account.preferences.averageOrderLeadTime}
                        {...methods.register('averageOrderLeadTime', {
                          valueAsNumber: true,
                          required: 'Please provide Average Order Lead Time',
                          min: {
                            value: 0,
                            message: "Average Order Lead Time value can't be negative",
                          },
                        })}
                      />
                      <span className="absolute right-4 top-2">days</span>
                    </div>
                  </label>
                  <InputErrorMessage
                    message={methods.formState.errors.averageOrderLeadTime?.message}
                  />
                  <Button
                    slim
                    icon={FiPlus}
                    label="Add vendor"
                    onClick={() => setVendorDialogOpen(true)}
                  />
                </div>
              )}

              {data.vendors.edges.length === 0 && (
                <div className="mt-6">
                  <EmptyTable title="Vendors" bodyText="There are no vendors to show" />
                </div>
              )}

              {data.vendors.edges.length !== 0 && (
                <>
                  <div className="mt-6 rounded-lg bg-white pb-7 pt-3 shadow">
                    <div className="mx-6 mb-3 flex items-center justify-between border-b border-gray-50 pb-4 text-sm">
                      <h5 className="font-bold">Vendors</h5>
                      <Tooltip>
                        <Tooltip.Element className="font-bold">
                          <button
                            type="button"
                            onClick={handleModeSwitch}
                            className={classnames(
                              'group flex items-center font-bold disabled:cursor-not-allowed',
                              !methods.formState.isDirty ? 'text-purple-100' : 'text-gray-75',
                            )}
                            disabled={methods.formState.isDirty}
                          >
                            {mergeVendorsMode ? 'Update Order Lead Time' : 'Merge Vendors'}
                            <FiArrowRight
                              strokeWidth="2.5"
                              className={classnames(
                                'ml-1',
                                !methods.formState.isDirty &&
                                  'transition-transform group-hover:translate-x-1',
                              )}
                            />
                          </button>
                        </Tooltip.Element>
                        {methods.formState.isDirty && (
                          <Tooltip.Body>
                            <p className="max-w-[9rem]">
                              Save or reset your changes to access Merge Vendors mode.
                            </p>
                          </Tooltip.Body>
                        )}
                      </Tooltip>
                    </div>
                    <table className="min-w-full table-fixed">
                      <thead>
                        <tr className="text-left">
                          <th className="pb-7 pl-6 text-sm font-bold">Vendor</th>
                          <th className="pb-7 pl-2 text-sm font-bold">
                            <div className="flex items-center">
                              Order Lead Time
                              <Tooltip className="ml-2">
                                <Tooltip.Element />
                                <Tooltip.Body>
                                  <p className="max-w-[9rem]">
                                    Specifying Order Lead Time for a vendor will override the
                                    Average Order Lead Time.
                                  </p>
                                </Tooltip.Body>
                              </Tooltip>
                            </div>
                          </th>
                          <th className="pb-7 text-sm font-bold">
                            <div className="flex items-center">Active Orders</div>
                          </th>
                          {mergeVendorsMode && (
                            <th className="pb-7 pr-6 text-right text-sm font-bold">
                              <div className="inline-flex items-center">
                                Select to Merge
                                <Tooltip className="ml-2">
                                  <Tooltip.Element />
                                  <Tooltip.Body>
                                    <p className="max-w-[9rem]">
                                      Select vendors you want to merge. The first selected vendor
                                      will be treated as the main one.
                                    </p>
                                  </Tooltip.Body>
                                </Tooltip>
                              </div>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data.vendors.edges.map(({ node: vendor }) => (
                          <VendorRow
                            vendor={vendor}
                            key={vendor._id}
                            mergeVendorsMode={mergeVendorsMode}
                            toggleIsSelected={toggleIsSelected}
                            isSelected={selectedVendors.some(({ _id }) => _id === vendor._id)}
                            isPrimaryVendor={vendor._id === selectedVendors[0]?._id}
                            averageOrderLeadTime={account.preferences.averageOrderLeadTime}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <PrevNextButtons edges={data.vendors.edges} pageInfo={data.vendors.pageInfo} />
                  {selectedVendors.length > 1 && (
                    <div className="mt-8 rounded-lg border-2 border-purple-100 bg-purple-10 px-6 py-4 text-xs text-purple-110">
                      Vendors&nbsp;
                      <span className="font-semibold">
                        {selectedVendors.map((vendor) => vendor.name).join(', ')}
                      </span>
                      &nbsp;will be merged under&nbsp;
                      <span className="font-semibold">{selectedVendors[0].name}</span> when you
                      apply the merge!
                    </div>
                  )}
                </>
              )}
              <div className="mx-auto mt-8 w-2/3 space-y-4">
                {mergeVendorsMode ? (
                  <Button
                    fullWidth
                    variant="primary"
                    label="Apply merge"
                    onClick={() => onMerge(selectedVendors)}
                  />
                ) : (
                  <Button
                    type="submit"
                    disabled={!methods.formState.isDirty}
                    fullWidth
                    variant="primary"
                    label="Save changes"
                  />
                )}
                {methods.formState.isDirty && (
                  <Button
                    variant="text"
                    fullWidth
                    label="Reset"
                    icon={FiRotateCcw}
                    onClick={() => methods.reset()}
                  />
                )}
                {mergeVendorsMode && selectedVendors.length > 0 && (
                  <Button
                    variant="text"
                    fullWidth
                    label="Deselect all"
                    icon={FiRotateCcw}
                    onClick={() => setSelectedVendors([])}
                  />
                )}
              </div>
            </form>
          </FormProvider>
        )}
      </div>
      <Dialog
        open={!!vendorDialogOpen}
        onClose={() => setVendorDialogOpen(false)}
        className="text-xs text-midnight-100"
        style={{ fontFamily: 'Gilroy, sans-serif' }}
      >
        <div className="fixed inset-0 top-12 z-30 overflow-y-auto">
          {data && (
            <Dialog.Panel className="mx-auto max-w-xl overflow-hidden rounded bg-white shadow">
              <div className="bg-purple-10 p-8 py-6">
                <Dialog.Title className="text-lg font-bold text-purple-100">
                  New Vendor
                </Dialog.Title>
              </div>
              <VendorForm
                data={data}
                onSubmit={onCreateVendorSubmit}
                onCancel={setVendorDialogOpen}
              />
            </Dialog.Panel>
          )}
        </div>
        <div className="fixed inset-0 z-20 bg-black/50" aria-hidden="true" />
      </Dialog>
    </>
  );
};

export default Vendors;
