/* eslint-disable no-nested-ternary */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FiChevronDown, FiMapPin } from 'react-icons/fi';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAccount } from '../../AccountProvider.jsx';
import DeleteButton from '../../Components/DeleteButton.jsx';
import { SkeletonBox } from '../../Components/Skeletons.jsx';
import SkuDetails from '../../Components/SkuDetails.jsx';
import OnHandTarget from '../../Skus/OnHandTarget.jsx';
import { getSkuPagePath } from '../../Skus/utils/helpers.js';
import { SKU_QUERY } from '../Queries.js';

export const LocationRow = ({ location, status, sku, transfers = {}, className = '' }) => {
  const { formatNumber } = useAccount();

  const locationOrderUnit =
    status !== 'DELIVERED'
      ? null
      : transfers?.edges
          ?.filter(({ node: transfer }) => transfer.toLocation._id === location._id)
          .reduce(
            (quantity, { node: transfer }) =>
              quantity + (transfer.items.find((item) => item.sku === sku)?.receivedQuantity ?? 0),
            0,
          );

  return (
    <tr className={className}>
      <td className="relative py-3 pl-16 pr-6 text-left">
        <div className="absolute inset-y-0 left-6 w-[1px] bg-purple-100" />
        <div className="-ml-10 mb-1 mr-2 inline-block h-[1px] w-1.5 bg-purple-100" />
        <FiMapPin size={14} className="inline-block stroke-current text-gray-75" />
        <span className="ml-2 flex-1">{location.name}</span>
      </td>
      {status === 'DELIVERED' ? null : (
        <td className="px-3 pb-1 pt-3">
          <OnHandTarget
            quantity={location.inventoryQuantity}
            target={location.targetInventoryQuantity}
          />
        </td>
      )}
      {status === 'DELIVERED' ? null : (
        <td className="px-3 py-1">
          {Number.isInteger(location.targetMissingInventoryQuantity)
            ? formatNumber(Math.max(location.targetMissingInventoryQuantity, 0))
            : '-'}
        </td>
      )}
      <td className="px-3 py-1 text-right">{formatNumber(location.incomingInventoryQuantity)}</td>
      {status === 'DELIVERED' ? (
        <td className="px-3 py-1 text-right">{formatNumber(locationOrderUnit)}</td>
      ) : null}
      <td className="px-3 py-1" colSpan={5} />
    </tr>
  );
};

LocationRow.propTypes = {
  location: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    inventoryQuantity: PropTypes.number.isRequired,
    targetInventoryQuantity: PropTypes.number.isRequired,
    targetMissingInventoryQuantity: PropTypes.number.isRequired,
    incomingInventoryQuantity: PropTypes.number.isRequired,
  }).isRequired,
  className: PropTypes.string,
  transfers: PropTypes.shape({}),
  status: PropTypes.string,
  sku: PropTypes.string,
};

LocationRow.defaultProps = {
  className: '',
  status: '',
  sku: '',
  transfers: {},
};

const SkeletonRow = ({ purchaseOrder, isReadOnly }) => (
  <tr className="border-t border-midnight-5">
    <td className="w-1/4 py-2 pl-6 pr-3 text-left align-top">
      <SkuDetails.Skeleton />
    </td>
    {purchaseOrder.status === 'DELIVERED' ? null : (
      <td className="px-3">
        <SkeletonBox />
      </td>
    )}
    <td className="px-3">
      <SkeletonBox />
    </td>
    <td className="px-3 text-right">
      <SkeletonBox />
    </td>
    <td className="px-3 text-right">
      <SkeletonBox />
    </td>
    <td className="px-3 text-right">
      <SkeletonBox />
    </td>
    <td className="px-3 text-right">
      <SkeletonBox />
    </td>
    {isReadOnly ? null : (
      <>
        <td className="px-3 text-right">
          <SkeletonBox />
        </td>
        <td className="px-3 text-right">
          <SkeletonBox />
        </td>
        <td className="pl-3 pr-6 text-xs" />
      </>
    )}
  </tr>
);

SkeletonRow.propTypes = {
  purchaseOrder: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

const calculateLandedCost = (purchaseOrder, unitCost = 0, currency = 'USD') => {
  if (!purchaseOrder.costAdjustments?.length) {
    return '-';
  }
  const totalCostAdjustments = purchaseOrder.costAdjustments.reduce(
    (total, { amount }) => total + amount,
    0,
  );
  const allLineItemsCost = (purchaseOrder.items || []).reduce(
    (total, item) => total + item.quantity * (item.unitCost ?? 0),
    0,
  );

  const landedCost = (unitCost / allLineItemsCost) * totalCostAdjustments + unitCost;

  return Number.isNaN(landedCost)
    ? '-'
    : landedCost.toLocaleString(navigator.language, {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
        notation: 'compact',
      });
};

export const byTargetAndLocationName =
  (locations = []) =>
  (a, b) => {
    const targetA = Math.max(a.targetMissingInventoryQuantity, 0);
    const targetB = Math.max(b.targetMissingInventoryQuantity, 0);

    if (targetA > targetB) return -1;
    if (targetA < targetB) return 1;

    const nameA = locations.find(({ node }) => node._id === a._id).node.name;
    const nameB = locations.find(({ node }) => node._id === b._id).node.name;
    return nameA.localeCompare(nameB);
  };

const PurchaseOrderItem = ({
  item,
  onItemRemove,
  itemIndex,
  purchaseOrder,
  isReadOnly,
  defaultSource,
}) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const liveUnitCost = watch(`items.${itemIndex}.unitCost`) ?? 0;

  const {
    account,
    currencySymbol,
    formatCurrency,
    formatNumber,
    locations: { edges: allLocations },
  } = useAccount();
  const location = useLocation();
  const { pathname } = location;
  const unitCostRef = useRef(null);

  const accountLocations = allLocations.filter((location) => !location.node.hideReplenishment);

  const [showLocations, setShowLocations] = useState(false);
  const [showOthers, setShowOthers] = useState(false);

  const { loading, data } = useQuery(SKU_QUERY, { variables: { sku: item.sku } });

  const [itemQuantity, unitCost] = useWatch({
    control,
    name: [`items.${itemIndex}.quantity`, `items.${itemIndex}.unitCost`],
  });

  const { ref, ...rest } = register(`items.${itemIndex}.unitCost`, {
    required: true,
    valueAsNumber: true,
    min: {
      message: "Unit cost can't be negative.",
      value: 0,
    },
  });

  const needsHighlight = item?.options?.highlight;

  const sku = data?.sku;

  const cost = unitCost ?? sku?.cost;

  const hasCustomUnitCost = Number.isFinite(item.unitCost) && item.unitCost !== sku?.cost;

  useEffect(() => {
    if (sku) {
      let unitCost;
      if (item.unitCost >= 0 && item.unitCost !== null) {
        unitCost = item.unitCost;
      } else if (sku.cost) {
        unitCost = sku.cost;
      } else {
        unitCost = Number.isNaN(unitCost) ? 0 : unitCost;
      }
      setValue(`items.${itemIndex}.unitCost`, unitCost, { shouldValidate: true });
      if (unitCostRef.current && unitCost != null) {
        // temporarily making it text so we can place the cursor to the end of value
        unitCostRef.current.type = 'text';
        unitCostRef.current.setSelectionRange(
          unitCost.toString().length,
          unitCost.toString().length,
        );
        unitCostRef.current.type = 'number';
      }
    }
  }, [sku, setValue, item]);

  const handleRemove = (e) => {
    e.stopPropagation();
    onItemRemove(itemIndex);
  };

  const selectedLocations =
    loading || !sku
      ? []
      : sku.inventoryBreakdown
          .filter((location) => !!accountLocations.find(({ node }) => node._id === location._id))
          .filter((l, _, locations) => {
            if (purchaseOrder.recommendInventoryForLocations.length > 0) {
              return purchaseOrder.recommendInventoryForLocations.includes(l._id);
            }
            // no selected locations means "all replenishable locations" if there are any
            if (
              locations.filter(({ replenishmentStatus }) => replenishmentStatus === 'replenishNow')
                .length > 0
            ) {
              return l.replenishmentStatus === 'replenishNow';
            }
            // or all locations if there are no replenishable locations
            return true;
          })
          .sort(byTargetAndLocationName(accountLocations));

  const otherLocations =
    loading || !sku
      ? []
      : sku.inventoryBreakdown
          .filter((location) => !!accountLocations.find(({ node }) => node._id === location._id))
          .filter((l) => !selectedLocations.find(({ _id }) => _id === l._id))
          .sort(byTargetAndLocationName(accountLocations));

  const targetInventoryQuantity = useMemo(() => {
    const targetInventoryQuantity = selectedLocations.reduce(
      (acc, location) => acc + location.targetInventoryQuantity,
      0,
    );
    return Math.max(0, targetInventoryQuantity);
  }, [sku, purchaseOrder.recommendInventoryForLocations]);

  const targetMissingInventoryQuantity = useMemo(() => {
    const targetMissingInventoryQuantity = selectedLocations.reduce(
      (acc, location) => acc + location.targetMissingInventoryQuantity,
      0,
    );
    return Math.max(0, targetMissingInventoryQuantity);
  }, [sku, purchaseOrder.recommendInventoryForLocations]);

  const inventoryQuantity = useMemo(
    () => selectedLocations.reduce((acc, location) => acc + location.inventoryQuantity, 0),
    [sku, purchaseOrder.recommendInventoryForLocations],
  );

  const incomingInventory = useMemo(
    () => selectedLocations.reduce((acc, location) => acc + location.incomingInventoryQuantity, 0),
    [sku, purchaseOrder.recommendInventoryForLocations],
  );

  useEffect(() => {
    if (selectedLocations.length === 0 && otherLocations.length > 0) {
      setShowOthers(true);
    }
  }, [loading]);

  if (loading) return <SkeletonRow purchaseOrder={purchaseOrder} isReadOnly={isReadOnly} />;

  return (
    <>
      <tr
        className={classnames(
          'border-t border-midnight-5',
          showLocations && 'bg-leafy-10',
          needsHighlight && 'border-l-2 border-l-gray-75 bg-gray-10',
        )}
      >
        <td className=" w-1/4 py-2 pl-6 pr-3 text-left align-top">
          <div className="flex items-start">
            <Link to={getSkuPagePath(sku)} state={{ fromPath: pathname }}>
              <SkuDetails sku={sku} />
            </Link>
            {selectedLocations.length > 0 || otherLocations.length > 0 ? (
              <button
                className="ml-auto inline-flex items-center whitespace-nowrap rounded-full p-1 text-purple-75 opacity-70 transition-colors duration-150 ease-out hover:bg-purple-75 hover:text-white"
                type="button"
                title={`${showLocations ? 'Hide' : 'Show'} locations`}
                onClick={() => setShowLocations((v) => !v)}
              >
                <div>
                  <FiChevronDown
                    className={classnames(
                      'transform stroke-current transition-transform duration-200 ease-out',
                      showLocations && 'scale-y-[-1]',
                    )}
                    size={20}
                    strokeWidth={3}
                  />
                </div>
              </button>
            ) : null}
          </div>
        </td>
        {purchaseOrder.status === 'DELIVERED' ? null : (
          <td className="px-3">
            {selectedLocations.length === 0 ? (
              <span>-</span>
            ) : (
              <OnHandTarget quantity={inventoryQuantity} target={targetInventoryQuantity} />
            )}
          </td>
        )}
        {purchaseOrder.status === 'DELIVERED' ? null : (
          <td className="px-3">
            <div
              className={classnames(
                'inline-block w-12 rounded-lg py-1 text-center',
                selectedLocations.length > 0 && 'bg-gray-100 font-semibold text-white',
              )}
            >
              {selectedLocations.length === 0 ? '-' : formatNumber(targetMissingInventoryQuantity)}
            </div>
          </td>
        )}
        <td className="px-3 text-right">
          {selectedLocations.length === 0 ? '-' : formatNumber(incomingInventory)}
        </td>
        {isReadOnly ? (
          <td className="px-3 text-right">{formatNumber(Number(itemQuantity) || 0)}</td>
        ) : (
          <td className="px-3">
            <div className="flex items-center justify-center">
              <input
                className={classnames(
                  'w-16 rounded border border-midnight-10 px-2 py-1 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100',
                  errors.items?.[itemIndex]?.quantity && 'input-error',
                )}
                type="number"
                step="1"
                min="0"
                {...register(`items.${itemIndex}.quantity`, {
                  required: true,
                  valueAsNumber: true,
                  min: {
                    message: "Quantity can't be negative.",
                    value: 0,
                  },
                })}
                title={errors.items?.[itemIndex]?.quantity?.message}
              />
            </div>
          </td>
        )}
        <td className="px-3 text-right">
          {formatNumber((Number(itemQuantity) || 0) + inventoryQuantity + incomingInventory)}
        </td>
        {isReadOnly ? (
          <td className="px-3 text-right">
            {formatCurrency(cost, { maximumFractionDigits: 2, notation: 'compact' }, '-')}
            {defaultSource && (
              <div className="capitalize italic text-midnight-70 opacity-75">
                {hasCustomUnitCost ? 'Custom' : defaultSource}
              </div>
            )}
          </td>
        ) : (
          <td className="px-3 text-right">
            <div className="flex items-center justify-center">
              <span>{currencySymbol}</span>
              <input
                className={classnames(
                  'ml-1 w-[84px] rounded border border-midnight-10 px-2 py-1 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100',
                  errors.items?.[itemIndex]?.unitCost && 'input-error',
                )}
                type="number"
                step="0.01"
                min="0"
                {...rest}
                ref={(e) => {
                  ref(e);
                  unitCostRef.current = e;
                }}
                title={errors.items?.[itemIndex]?.unitCost?.message}
              />
            </div>
          </td>
        )}
        <td className="px-3 text-right">
          {calculateLandedCost(purchaseOrder, liveUnitCost, account.preferences?.currency)}
        </td>
        <td className="px-3 text-right last:pr-6">
          {formatCurrency(
            cost * (Number(itemQuantity) || 0),
            {
              maximumFractionDigits: 2,
              notation: 'compact',
            },
            '-',
          )}
        </td>
        {isReadOnly ? null : (
          <td className="pl-3 pr-6 text-xs">
            <DeleteButton onDelete={handleRemove} />
          </td>
        )}
      </tr>
      {showLocations &&
        !loading &&
        selectedLocations.map((loc) => (
          <LocationRow
            location={loc}
            key={loc._id}
            status={purchaseOrder.status}
            transfers={purchaseOrder.transfers}
            sku={item.sku}
          />
        ))}
      {showLocations && !loading && otherLocations.length > 0 && (
        <tr>
          <td colSpan={10}>
            <p
              className="flex cursor-pointer items-center px-6 py-3 text-left italic opacity-70"
              onClick={() => setShowOthers((v) => !v)}
            >
              <span className="mr-2">
                {`${showOthers ? 'Hide' : 'Show'} ${pluralize(
                  'location',
                  otherLocations.length,
                  true,
                )} ${
                  purchaseOrder.recommendInventoryForLocations.length > 0
                    ? 'not selected for'
                    : 'not needing'
                } replenishment`}
              </span>
              <FiChevronDown
                className={classnames(
                  'transition-transform duration-200 ease-out',
                  showOthers && 'scale-y-[-1]',
                )}
              />
            </p>
          </td>
        </tr>
      )}
      {!loading &&
        showLocations &&
        showOthers &&
        otherLocations.map((loc) => (
          <LocationRow
            location={loc}
            key={loc._id}
            status={purchaseOrder.status}
            className="opacity-60"
          />
        ))}
      {/* additional vetical spacer element for when the location breakdown is expanded */}
      {showLocations && showOthers && (
        <tr>
          <td className="h-4" />
        </tr>
      )}
    </>
  );
};

PurchaseOrderItem.propTypes = {
  item: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    unitCost: PropTypes.number,
    options: PropTypes.shape({
      highlight: PropTypes.bool,
    }),
  }).isRequired,
  onItemRemove: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  poLocation: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
  purchaseOrder: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number.isRequired,
        unitCost: PropTypes.number,
      }),
    ).isRequired,
    costAdjustments: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      }),
    ),
    transfers: PropTypes.shape({}),
    recommendInventoryForLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  defaultSource: PropTypes.string,
};

PurchaseOrderItem.defaultProps = {
  poLocation: null,
  defaultSource: '',
};

export default PurchaseOrderItem;
