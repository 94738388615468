import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@headlessui/react';
import { useAccount } from '../../AccountProvider.jsx';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const LocatePopup = ({ open, onClose }) => {
  const { addAlert } = useAlerts();
  const { axios } = useAccount();

  const [loading, setLoading] = React.useState(false);

  const handleLocateConnect = React.useCallback(
    (e) => {
      e.preventDefault();

      setLoading(true);

      const data = Object.fromEntries(new FormData(document.querySelector('form')));

      const connect = async () => {
        try {
          await axios('/api/sources/locate', { data, method: 'POST' });

          addAlert('Successfully connected to Locate', { level: 'success' });
        } catch (error) {
          addAlert(error.response.data.error, { error });
        }

        onClose();

        setLoading(false);
      };

      connect();
    },
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Locate</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleLocateConnect}>
            <div>
              <label htmlFor="locate-api-key">
                Locate API key*
                <input type="text" id="locate-api-key" required className="input" name="apiKey" />
                <p className="mt-2 text-gray-100">
                  Create a user on&nbsp;
                  <a
                    href="https://locateinv.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-100"
                  >
                    LocateInv
                  </a>
                  &nbsp;with the <em>API</em> Type and copy the API Key here.
                </p>
              </label>
            </div>
            <div className="mt-4">
              <label htmlFor="locate-domain">
                Locate Domain*
                <input type="text" id="locate-domain" required className="input" name="domain" />
                <p className="mt-2 text-gray-100">
                  Your LocateInv account domain. e.g: <em>example.locateinv.com</em>
                </p>
              </label>
            </div>
            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

LocatePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LocatePopup;
