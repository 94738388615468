import React from 'react';
import PropTypes from 'prop-types';

const ShipbobIcon = ({ className = '', ...props }) => (
  <svg
    width="12px"
    height="14px"
    viewBox="0 0 12 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.70128 6.38741V9.59945L5.95001 12.1691L10.2032 9.59945V5.37788L6.57676 7.56214C6.1783 7.80069 5.72164 7.80069 5.32318 7.56214L0.389492 4.58408C-0.129831 4.26746 -0.129831 3.47357 0.389492 3.1616L5.32318 0.178973C5.72164 -0.0596576 6.1783 -0.0596576 6.57676 0.178973L10.2032 2.37236L8.53327 3.37724L5.95001 1.82167L4.7412 2.55589L2.5564 3.88196L3.70701 4.57487L5.95001 5.94235L10.5345 3.17993C10.7942 3.02851 11.1479 3.04684 11.4075 3.18449C11.6895 3.33592 11.9 3.63421 11.9 4.00136V9.87941C11.9 10.3382 11.6761 10.7374 11.2911 10.9715L6.57676 13.821C6.18284 14.0597 5.72164 14.0597 5.32318 13.821L0.608879 10.9715C0.22386 10.7374 0.00447259 10.3428 0.00447259 9.87941V8.94787V5.36876L1.70128 6.38741Z"
    />
  </svg>
);

ShipbobIcon.propTypes = {
  className: PropTypes.string,
};

ShipbobIcon.defaultProps = {
  className: null,
};

export default ShipbobIcon;
