import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import { GraphQLClientProvider } from '../AccountProvider.jsx';
import AlertsProvider from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import Spinner from '../Components/Spinner.jsx';
import { milestones } from './Form.jsx';

const transferMilestone = {
  key: 'DELIVERED',
  description: 'Delivery date',
  input: {
    name: 'expectedDeliveryDate',
    required: true,
  },
};

const FRAGMENT_PROMPT = gql`
  fragment PromptFields on Prompt {
    _id
    milestone
    completedAt
    oldValue
    newValue
    resourceType
    resource {
      ... on PurchaseOrder {
        _id
        items {
          sku
        }
        issueDate
        customPurchaseOrderNumber
        expectedProductionCompletionDate
        expectedShipmentDate
        expectedDeliveryDate
      }
      ... on Transfer {
        _id
        trackingCode
        trackingUrl
        issuedAt
        expectedDeliveryDate
        customId
        items {
          sku
        }
        toLocation {
          name
        }
      }
    }
  }
`;

const GET_PROMPT = gql`
  ${FRAGMENT_PROMPT}

  query GetPrompt($_id: String!) {
    prompt: PROMPT_get(_id: $_id) {
      ...PromptFields
    }
  }
`;

const ResourceType = PropTypes.oneOfType([
  PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    customPurchaseOrderNumber: PropTypes.string,
    expectedProductionCompletionDate: PropTypes.string,
    expectedShipmentDate: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    customId: PropTypes.string,
    expectedDeliveryDate: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
]);

const PromptType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  milestone: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  resource: ResourceType,
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
});

const ACTION_PROMPT = gql`
  ${FRAGMENT_PROMPT}

  mutation ActionPrompt($_id: String!, $newDate: Date, $action: PromptActionType!) {
    actionPrompt: PROMPT_action(_id: $_id, newDate: $newDate, action: $action) {
      ...PromptFields
    }
  }
`;

const PromptFooter = ({ resource }) => (
  <footer className="mt-auto bg-purple-120 p-16 text-xl text-white md:text-2xl">
    <p className="flex-shrink-0 font-bold uppercase opacity-70">
      Products in this {resource.__typename === 'PurchaseOrder' ? 'purchase order' : 'transfer'}:
    </p>
    <ul className="ml-2 mt-1 space-y-1 font-bold">
      {resource.items.slice(0, 3).map((sku) => (
        <li className="uppercase" key={sku.sku}>
          - {sku.sku}
        </li>
      ))}
      {resource.items.length > 3 && (
        <li className="text-lg font-medium">
          ... and {resource.items.length - 3} other{' '}
          {pluralize('product', resource.items.length - 3)}
        </li>
      )}
    </ul>
    {/* <div className="mt-4 flex items-center font-bold">
      <span className="uppercase opacity-70">Fedex:&nbsp;</span>
      <a href="/" className="underline">
        566789440023
      </a>
      <FiLink2 className="ml-2 -rotate-45" />
    </div>
    <div className="mt-4 lg:flex lg:items-center">
      <span className="opacity-70">Received by&nbsp;</span>
      <span className="font-bold opacity-70">ShipBob (California) on 31/03/2023&nbsp;</span>
      <div className="flex items-center">
        <span className="opacity-70">ID:&nbsp;</span>
        <a href="/" className="font-bold underline">
          50252
        </a>
        <FiLink2 className="ml-2 -rotate-45" />
      </div>
    </div> */}
  </footer>
);

PromptFooter.propTypes = {
  resource: ResourceType.isRequired,
};

const PromptHeader = () => (
  <header className="bg-white px-16 py-12 text-center md:text-left">
    <NavLink
      to="/"
      className="rounded-full py-2 pl-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100"
    >
      <img src="/cogsy-logo-on-white.svg" className="inline" alt="Cogsy Logo" />
    </NavLink>
  </header>
);

const CompletedPromptScreen = ({ prompt }) => {
  const milestone = useMemo(
    () =>
      prompt.resourceType === 'purchaseOrder'
        ? milestones.find((m) => m.key === prompt.milestone)
        : transferMilestone,
    [prompt],
  );
  return (
    <div className="flex min-h-screen flex-col">
      <PromptHeader />
      <section className="bg-purple-5 p-16 text-2xl leading-normal text-purple-120 sm:text-3xl">
        <strong>{prompt.resource.customPurchaseOrderNumber ?? prompt.resource.customId}</strong>
        &nbsp; estimated&nbsp;
        <span className="lowercase">{milestone.description}</span> has been updated
      </section>
      <section className="flex-1 bg-white p-16">
        <div className="flex justify-center space-x-6 sm:justify-start sm:text-lg">
          <article className="font-semibold text-midnight-100/75">
            <p className="text-sm uppercase">Old date:</p>
            <p>{new Date(prompt.oldValue).toLocaleDateString()}</p>
          </article>
          <article className="font-semibold text-midnight-100">
            <p className="text-sm uppercase">New date:</p>
            <p>{new Date(prompt.newValue).toLocaleDateString()}</p>
          </article>
        </div>
        <div className="mt-6 flex flex-col justify-center space-y-4 sm:flex-row sm:justify-start sm:space-x-6 sm:space-y-0">
          <Button
            label="View purchase order"
            href={`/purchase-orders/${prompt.resource._id}`}
            variant="primary"
            slim
          />
          <Button label="Go to dashboard" href="/" variant="tertiary" slim />
        </div>
      </section>
      <PromptFooter resource={prompt.resource} />
    </div>
  );
};

CompletedPromptScreen.propTypes = {
  prompt: PromptType.isRequired,
};

const Question = ({ prompt }) => {
  if (prompt.resourceType === 'purchaseOrder') {
    // purchase order prompts are either about production completion or shipment date
    const milestone = milestones.find((m) => m.key === prompt.milestone);
    const key = milestone.input.name;
    const dateName = milestone.description;
    if (prompt.milestone === 'PRODUCTION') {
      return (
        <p className="space-x-1">
          <span>The expected {dateName.toLowerCase()} date for Purchase Order</span>
          <strong className="">{prompt.resource.customPurchaseOrderNumber}</strong>
          <span>is on</span>
          <strong>{new Date(prompt.resource[key]).toLocaleDateString()}</strong>.
        </p>
      );
    }
    if (prompt.milestone === 'SHIPMENT') {
      return (
        <p className="space-x-1">
          <span>The expected shipment date for Purchase Order</span>
          <strong className="">{prompt.resource.customPurchaseOrderNumber}</strong>
          <span>is on </span>
          <strong>{new Date(prompt.resource[key]).toLocaleDateString()}</strong>.
        </p>
      );
    }
  }
  // all transfer prompts are about expected delivery date
  return (
    <p className="space-x-2">
      <span>Transfer</span>
      <strong>{prompt.resource.customId}</strong> is due to arrive on
      <strong>{new Date(prompt.resource.expectedDeliveryDate).toLocaleDateString()}</strong>.
    </p>
  );
};

Question.propTypes = {
  prompt: PromptType.isRequired,
};

const PromptForm = ({ prompt }) => {
  const milestone = useMemo(
    () =>
      prompt.resourceType === 'purchaseOrder'
        ? milestones.find((m) => m.key === prompt.milestone)
        : transferMilestone,
    [prompt],
  );
  const key = milestone.input.name;
  const [searchParams] = useSearchParams();

  const [date, setDate] = useState(prompt.resource[key]);
  const action = searchParams.get('action') ?? 'custom'; // this is the default

  const [actionPrompt, { loading: loadingMutation }] = useMutation(ACTION_PROMPT, {
    variables: { _id: prompt._id, newDate: date, action },
  });

  useEffect(() => {
    if (action !== 'custom') {
      actionPrompt();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    actionPrompt();
  };

  return (
    <div className="flex min-h-screen flex-col">
      <PromptHeader />
      <section className="bg-purple-5 p-16 text-2xl leading-normal text-purple-120 sm:text-3xl">
        <Question prompt={prompt} />
      </section>
      <form
        className="flex-1 bg-white p-16 text-lg text-midnight-100 sm:text-xl"
        onSubmit={handleSubmit}
      >
        <p>
          You can update the expected <strong>{milestone.description}</strong> here:
        </p>
        <input
          type="date"
          className="input mx-auto mt-4 block w-72 sm:mx-0"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <div className="mt-8 flex justify-center sm:justify-start">
          <Button
            label={loadingMutation ? 'Updating...' : 'Update'}
            disabled={loadingMutation}
            slim
            onClick={handleSubmit}
          />
        </div>
      </form>
      <PromptFooter resource={prompt.resource} />
    </div>
  );
};

PromptForm.propTypes = {
  prompt: PromptType.isRequired,
};

const Prompt = () => {
  const { promptId } = useParams();

  const { data, loading } = useQuery(GET_PROMPT, {
    variables: { _id: promptId },
  });

  if (loading)
    return (
      <div className="flex min-h-screen items-center justify-center">
        <Spinner />
      </div>
    );

  if (data.prompt.completedAt) {
    return <CompletedPromptScreen prompt={data.prompt} />;
  }
  return <PromptForm prompt={data.prompt} />;
};

const ExpiredPrompt = () => (
  <div className="flex min-h-screen flex-col">
    <PromptHeader />
    <section className="bg-purple-5 p-16 text-2xl font-medium leading-normal text-purple-120 sm:text-3xl">
      <p>This link has expired 🙁</p>
    </section>
    <section className="flex-1 bg-white p-16 text-base text-midnight-100 sm:text-lg">
      <p className="">
        But you can update Purchase Orders and Transfers <br className="hidden sm:inline" />
        directly in your Cogsy account.
      </p>
      <div className="mt-12">
        <Button label="Go to dashboard" href="/" variant="primary" slim />
      </div>
    </section>
  </div>
);

const PromptWrapper = () => {
  const [search] = useSearchParams();
  const token = window.localStorage.getItem('token');
  const [showError, setShowError] = useState(false);

  return (
    <AlertsProvider>
      <GraphQLClientProvider
        token={token ?? search.get('token')}
        customErrorHandler={() => setShowError(true)}
      >
        {showError ? <ExpiredPrompt /> : <Prompt />}
      </GraphQLClientProvider>
    </AlertsProvider>
  );
};

export default PromptWrapper;
