import React from 'react';
import PropTypes from 'prop-types';

const ExtensivIcon = ({ className = '', ...props }) => (
  <svg
    width="14px"
    height="15px"
    viewBox="0 0 15 17"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M12.9826 0.832993L11.6506 2.16495H14.3001L12.9826 0.832993Z" />
    <path d="M6.67022 11.5321L3.05078 16.9467L6.67022 13.1101L12.9825 6.81231L6.67022 0.5V11.5321Z" />
    <path d="M6.67027 6.85574V0.5H0.300049L6.67027 6.85574Z" />
    <path d="M3.05077 16.9467L6.67021 11.5321L2.21106 13.5734L3.05077 16.9467Z" />
    <path d="M11.6507 2.16495L9.98572 3.81541L12.9826 6.81231V2.16495H11.6507Z" />
  </svg>
);

ExtensivIcon.propTypes = {
  className: PropTypes.string,
};

ExtensivIcon.defaultProps = {
  className: null,
};

export default ExtensivIcon;
