import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import LoadingPage from '../Components/LoadingPage.jsx';
import Register from './Register.jsx';

const Invitation = () => {
  const { invitationCode } = useParams();
  const location = useLocation();

  if (window.localStorage.getItem('token')) {
    window.localStorage.removeItem('token');
    window.location.reload();
    return <LoadingPage />;
  }

  const search = new URLSearchParams(location.search);

  return <Register invitationCode={invitationCode} email={search.get('email') || ''} />;
};

export default Invitation;
