import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react';

const Toggle = ({ label, labelStyle, enabled, onChange, spaceBetween }) => (
  <Switch.Group
    as="div"
    className={`flex w-full items-center ${spaceBetween ? 'justify-between' : ''}`}
  >
    {label && (
      <Switch.Label className={`${spaceBetween ? '' : 'mr-2'} ${labelStyle || 'text-xs'}`}>
        {label}
      </Switch.Label>
    )}

    <Switch
      checked={enabled}
      onChange={onChange}
      className={`${
        enabled ? 'border-purple-100 bg-purple-100' : 'border-gray-75 bg-gray-75'
      } relative inline-flex h-4 w-8 cursor-pointer items-center rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2`}
    >
      <span
        className={`${
          enabled ? 'translate-x-4' : 'translate-x-0'
        } pointer-events-none inline-block h-3 w-3 rounded-full bg-white ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  </Switch.Group>
);

Toggle.propTypes = {
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  enabled: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  label: null,
  labelStyle: null,
  enabled: false,
  spaceBetween: false,
};

export default Toggle;
