import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SkuDetails from '../Components/SkuDetails.jsx';
import { getSkuPagePath } from './utils/helpers.js';

const ProductLink = ({
  sku,
  isSettingsActive,
  isArchivedView,
  selectedSkus,
  handleSkuSelectedChange,
  pathname,
  isRankVisible,
}) => {
  if (isArchivedView || sku.isArchived)
    return <SkuDetails isRankVisible={isRankVisible} sku={sku} />;
  if (isSettingsActive) {
    return (
      <>
        <input
          type="checkbox"
          readOnly
          className="mb-8 rounded border-gray-75 text-purple-100 focus:outline-none focus:ring-2 focus:ring-purple-100"
          id={sku.sku}
          onChange={() => handleSkuSelectedChange(sku)}
          checked={selectedSkus.some(({ sku: skuCode }) => skuCode === sku.sku)}
        />
        <SkuDetails
          isRankVisible={isRankVisible}
          sku={sku}
          handleOnClick={handleSkuSelectedChange}
        />
      </>
    );
  }
  return (
    <Link className="w-full" to={getSkuPagePath(sku)} state={{ fromPath: pathname }}>
      <SkuDetails isRankVisible={isRankVisible} sku={sku} />
    </Link>
  );
};

ProductLink.propTypes = {
  selectedSkus: PropTypes.arrayOf(PropTypes.string),
  isArchivedView: PropTypes.bool,
  isSettingsActive: PropTypes.bool,
  sku: PropTypes.shape({
    type: PropTypes.string,
    sku: PropTypes.string,
    isArchived: PropTypes.bool,
  }).isRequired,
  handleSkuSelectedChange: PropTypes.func,
  pathname: PropTypes.shape(),
  isRankVisible: PropTypes.bool,
};
ProductLink.defaultProps = {
  selectedSkus: [],
  isArchivedView: false,
  isSettingsActive: false,
  handleSkuSelectedChange: undefined,
  pathname: undefined,
  isRankVisible: false,
};

export default ProductLink;
