/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FiExternalLink, FiMoreVertical } from 'react-icons/fi';
import { Menu, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import marketplaces from '../../shared/amazon/marketplaces.js';
import { hasSupportFor } from '../../shared/sources.js';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import RadioButton from '../Components/RadioButton.jsx';
import Toggle from '../Components/Toggle.jsx';
import { formatSourceName } from '../Skus/utils/formatSourceNames.js';
import RemoveSourcePopup from './RemoveSourcePopup.jsx';

const SOURCE_SYNC_UPDATE = gql`
  mutation UpdateSourcesSync($input: UpdateSourceInput!, $sourceId: String!) {
    updateSource(input: $input, sourceId: $sourceId) {
      sourceId
      syncBasics
      syncSkus
      syncInventoryQuantities
      syncOrders
      syncPurchaseOrders
      syncBundles
      syncAnalytics
      syncExport
      syncTransfers
      syncSubscriptions
      isDefaultSource
    }
  }
`;

const RESUME_INITIAL_SYNC = gql`
  mutation resumeInitialSync($sourceId: String!) {
    resumeInitialSync(sourceId: $sourceId)
  }
`;

const REMOVE_SOURCE = gql`
  mutation RemoveSource($sourceId: String!) {
    removeSource(sourceId: $sourceId)
  }
`;

const TOGGLE_SOURCE_SYNC = gql`
  mutation ToggleSourceSync($what: ToggleSourceSyncType!, $sourceId: String!) {
    toggleSourceSync(what: $what, sourceId: $sourceId)
  }
`;

const Source = ({ source }) => {
  const { addAlert } = useAlerts();
  const { user, axios } = useAccount();
  const [updateSourcesSync] = useMutation(SOURCE_SYNC_UPDATE);
  const [resumeInitialSync] = useMutation(RESUME_INITIAL_SYNC);
  const [removeSource] = useMutation(REMOVE_SOURCE);
  const [toggleSourceSync] = useMutation(TOGGLE_SOURCE_SYNC);

  const [removeSourcePopupOpen, setRemoveSourcePopupOpen] = React.useState(false);

  const toggleRemoveSourcePopupOpen = () => {
    setRemoveSourcePopupOpen(!removeSourcePopupOpen);
  };

  // eslint-disable-next-line consistent-return
  const doUpdateSourcesSync = async (data) => {
    try {
      const {
        data: { updateSource },
      } = await updateSourcesSync({ variables: { input: data, sourceId: source.sourceId } });
      return updateSource;
    } catch (error) {
      addAlert(`Sync toggle failed: ${error.message}. Please refresh the page and try again.`, {
        error,
      });
    }
  };

  const triggerSync = async (what, { full = false, force = false } = {}) => {
    try {
      await axios.post(`/api/sources/${source.sourceId}/sync/${what}`, { full, force });
      addAlert('The sync has been triggered in background, please hold on...', {
        level: 'success',
      });
    } catch (e) {
      if (e.response?.status !== 400) throw e;

      addAlert(
        <>
          <div className="mr-2">{e.response.data}</div>
          <Button
            label="Force sync"
            slim
            onClick={() => triggerSync(what, { full, force: true })}
          />
        </>,
        { level: 'warning' },
      );
    }
  };

  const handleResumeSyncSkus = async () => {
    try {
      await resumeInitialSync({ variables: { sourceId: source.sourceId } });
      addAlert('The initial sync has been resumed in the background, please hold on...', {
        level: 'success',
      });
    } catch (err) {
      addAlert(`Resuming initial sync failed: ${err.message}.`, { err });
      throw err;
    }
  };

  const handleRemoveSource = async () => {
    try {
      await removeSource({ variables: { sourceId: source.sourceId } });
      addAlert('The Integration is being removed in the background, please hold on...', {
        level: 'success',
      });
    } catch (err) {
      addAlert(`Removing Integration failed: ${err.message}.`, { err });
      throw err;
    }
  };

  const handleSyncBasics = React.useCallback(() => {
    triggerSync('basics', { full: true });
  }, []);

  const handleSyncSkus = React.useCallback(() => {
    triggerSync('skus', { full: true });
  }, []);

  const handleIncrementalSyncSkus = React.useCallback(() => {
    triggerSync('skus');
  }, []);

  const handleSyncOrders = React.useCallback(() => {
    triggerSync('orders', { full: true });
  }, []);

  const handleIncrementalSyncOrders = React.useCallback(() => {
    triggerSync('orders');
  }, []);

  const handleSyncSubscriptions = React.useCallback(() => {
    triggerSync('subscriptions', { full: true });
  }, []);

  const handleIncrementalSyncSubscriptions = React.useCallback(() => {
    triggerSync('subscriptions');
  }, []);

  const handleSyncPurchaseOrders = React.useCallback(() => {
    triggerSync('purchaseOrders', { full: true });
  }, []);

  const handleIncrementalSyncPurchaseOrders = React.useCallback(() => {
    triggerSync('purchaseOrders');
  }, []);

  const handleIncrementalSyncTransfers = React.useCallback(() => {
    triggerSync('transfers');
  }, []);

  const handleSyncTransfers = React.useCallback(() => {
    triggerSync('transfers', { full: true });
  }, []);

  const handleSyncBundles = React.useCallback(() => {
    triggerSync('bundles', { full: true });
  }, []);

  const handleSyncAnalytics = React.useCallback(() => {
    triggerSync('analytics', { full: true });
  }, []);

  const handleIncrementalSyncAnalytics = React.useCallback(() => {
    triggerSync('analytics');
  }, []);

  const handleSyncExport = React.useCallback(() => {
    triggerSync('export');
  }, []);

  const handleSkusToggle = React.useCallback(
    (enabled) => {
      if (enabled !== source.syncSkus) {
        toggleSourceSync({ variables: { what: 'skus', sourceId: source.sourceId } });
      }
    },
    [source.syncSkus],
  );

  const handleInventoryQuantitiesToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'inventoryQuantities', sourceId: source.sourceId } });
  }, []);

  const handleOrdersToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'orders', sourceId: source.sourceId } });
  }, []);

  const handleSubscriptionsToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'subscriptions', sourceId: source.sourceId } });
  }, []);

  const handlePosToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'purchaseOrders', sourceId: source.sourceId } });
  }, []);

  const handleTransfersToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'transfers', sourceId: source.sourceId } });
  }, []);

  const handleBundlesToggle = React.useCallback((enabled) => {
    if (enabled) addAlert('Checking Bundles API Access.', { level: 'warning' });

    const doUpdate = async () => {
      await toggleSourceSync({ variables: { what: 'bundles', sourceId: source.sourceId } });
      if (enabled)
        addAlert('Successfully connected to Bundles App! Data will sync on the next daily run.', {
          level: 'success',
        });
    };

    doUpdate();
  }, []);

  const handleBasicsToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'basics', sourceId: source.sourceId } });
  }, []);

  const handleAnalyticsToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'analytics', sourceId: source.sourceId } });
  }, []);

  const handleExportToggle = React.useCallback(() => {
    toggleSourceSync({ variables: { what: 'export', sourceId: source.sourceId } });
  }, []);

  const handleDefaultSourceOption = React.useCallback(() => {
    const updateDefaultSource = async () => {
      await doUpdateSourcesSync({ isDefaultSource: true });
      await triggerSync('skus', { full: true });
    };

    updateDefaultSource();
  });

  const handleSetUpdateInventory = React.useCallback((value) => {
    // value can be true, false or null
    doUpdateSourcesSync({ updateInventory: value });
  }, []);

  const doesSupport = hasSupportFor.bind(null, source.sourceType);

  const timeDisplayOptions = {
    day: 'numeric',
    weekday: 'short',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  };

  const syncStatus = (type, context) => {
    const status = context[`${type}SyncStatus`];
    if (!status) {
      return <span className="text-xs text-gray-100">Not synced</span>;
    }

    let statusText = 'Sync started at';
    let statusDate = 'SyncStartedAt';
    if (status === 'completed') {
      statusText = 'Sync completed at';
      statusDate = 'SyncFinishedAt';
    }

    return (
      <span className="text-xs text-gray-100">
        {statusText}&nbsp;
        {new Date(context[`${type}${statusDate}`]).toLocaleDateString(
          navigator.language,
          timeDisplayOptions,
        )}
      </span>
    );
  };

  let details;

  if (source.sourceType === 'amazon') {
    const marketplace = marketplaces.find((m) => m.id === source.sourceContext.marketPlaceId);
    details = `(${marketplace.name})`;
  }

  return (
    <div
      className={classnames(
        'relative rounded-lg bg-white pb-7 pt-5 text-midnight-100 shadow',
        source.isDefaultSource && 'border-2 border-purple-100',
      )}
    >
      {source.isDefaultSource && (
        <div className="absolute -top-6 right-3 rounded-t bg-purple-100 px-2 py-1 text-xs font-bold text-white">
          Default Source
        </div>
      )}

      <div className="mx-6 mb-4 flex justify-between border-b border-gray-50 pb-4 text-xs">
        <div className="max-w-[calc(100%-1rem)]">
          <h5 className="text-sm font-bold capitalize">
            {formatSourceName(source)} {details}
          </h5>
          <p className="truncate" title={source.sourceId}>
            {source.sourceId}
          </p>
        </div>
        {user.isAdmin && (
          <div className="relative -mr-3">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button
                    className={`${
                      open ? 'bg-purple-100 text-white' : ''
                    } block rounded p-1 focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2`}
                  >
                    <FiMoreVertical size={16} />
                  </Menu.Button>

                  <Menu.Items className="absolute right-0 z-10 mt-2 w-44 overflow-hidden rounded-lg border border-gray-50 bg-white shadow focus:outline-none">
                    {source.isSuspended && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={`font-bold text-red-100 ${
                              active && 'bg-purple-100 font-bold text-white'
                            } w-full px-4 py-2 text-left`}
                            onClick={handleResumeSyncSkus}
                          >
                            Resume initial sync
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {doesSupport('basics') && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={`${
                              active && 'bg-purple-100 font-bold text-white'
                            } w-full px-4 py-2 text-left`}
                            onClick={handleSyncBasics}
                          >
                            Sync basics data
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {(doesSupport('skus') || doesSupport('inventoryQuantities')) && (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleIncrementalSyncSkus}
                            >
                              Incremental sync products
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleSyncSkus}
                            >
                              Full sync products
                            </button>
                          )}
                        </Menu.Item>
                      </>
                    )}

                    {doesSupport('orders') && (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleIncrementalSyncOrders}
                            >
                              Incremental sync orders
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleSyncOrders}
                            >
                              Full sync orders
                            </button>
                          )}
                        </Menu.Item>
                      </>
                    )}

                    {doesSupport('subscriptions') && (
                      <>
                        {source.sourceType === 'shopify' && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={`${
                                  active && 'bg-purple-100 font-bold text-white'
                                } w-full px-4 py-2 text-left`}
                                onClick={handleIncrementalSyncSubscriptions}
                              >
                                Incremental sync subscriptions
                              </button>
                            )}
                          </Menu.Item>
                        )}

                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleSyncSubscriptions}
                            >
                              Full sync subscriptions
                            </button>
                          )}
                        </Menu.Item>
                      </>
                    )}

                    {doesSupport('purchaseOrders') && (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleIncrementalSyncPurchaseOrders}
                            >
                              Incremental sync purchase orders
                            </button>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleSyncPurchaseOrders}
                            >
                              Full sync purchase orders
                            </button>
                          )}
                        </Menu.Item>
                      </>
                    )}

                    {doesSupport('transfers') && (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleIncrementalSyncTransfers}
                            >
                              Incremental sync transfers
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleSyncTransfers}
                            >
                              Full sync transfers
                            </button>
                          )}
                        </Menu.Item>
                      </>
                    )}

                    {doesSupport('bundles') && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={`${
                              active && 'bg-purple-100 font-bold text-white'
                            } w-full px-4 py-2 text-left`}
                            onClick={handleSyncBundles}
                          >
                            Sync Bundles
                          </button>
                        )}
                      </Menu.Item>
                    )}

                    {doesSupport('analytics') && (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleIncrementalSyncAnalytics}
                            >
                              Incremental Sync Analytics
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={`${
                                active && 'bg-purple-100 font-bold text-white'
                              } w-full px-4 py-2 text-left`}
                              onClick={handleSyncAnalytics}
                            >
                              Full Sync Analytics
                            </button>
                          )}
                        </Menu.Item>
                      </>
                    )}

                    {doesSupport('export') && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={`${
                              active && 'bg-purple-100 font-bold text-white'
                            } w-full px-4 py-2 text-left`}
                            onClick={handleSyncExport}
                          >
                            Sync Data Export
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {doesSupport('skus') && !source.isDefaultSource && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={`${
                              active && 'bg-purple-100 font-bold text-white'
                            } w-full px-4 py-2 text-left`}
                            onClick={handleDefaultSourceOption}
                          >
                            Make default product source
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={`${
                            active && 'bg-red-100 text-white'
                          } w-full px-4 py-2 text-left font-bold text-red-100`}
                          onClick={toggleRemoveSourcePopupOpen}
                        >
                          Remove Source
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </>
              )}
            </Menu>
          </div>
        )}
      </div>

      <div className="mx-6 mb-6 border-b border-gray-50 pb-4 text-xs">
        <div className="space-y-4">
          <Toggle
            label="Enabled"
            labelStyle="text-sm"
            spaceBetween
            enabled={source.syncBasics ?? true}
            onChange={handleBasicsToggle}
          />
        </div>
      </div>

      {(source.syncBasics ?? true) && (
        <div className="mx-6 mb-12 space-y-4">
          {doesSupport('analytics') && (
            <>
              <Toggle
                label="Analytics"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncAnalytics}
                onChange={handleAnalyticsToggle}
              />
              {source.syncAnalytics && syncStatus('analytics', source.sourceContext)}
            </>
          )}
          {doesSupport('export') && (
            <>
              <Toggle
                label="Data Export"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncExport}
                onChange={handleExportToggle}
              />

              {source.sourceContext.spreadsheetId && (
                <Button
                  href={`https://docs.google.com/spreadsheets/d/${source.sourceContext.spreadsheetId}/edit`}
                  target="_blank"
                  rel="noreferrer"
                  label="Open Google Sheet"
                  slim
                  variant="secondary"
                  icon={FiExternalLink}
                />
              )}
            </>
          )}
          {doesSupport('skus') && (
            <>
              <Toggle
                label="Products"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncSkus}
                onChange={handleSkusToggle}
              />
              {source.syncSkus && syncStatus('skus', source.sourceContext)}
            </>
          )}
          {doesSupport('inventoryQuantities') && (
            <>
              <Toggle
                label="Inventory levels"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncInventoryQuantities}
                onChange={handleInventoryQuantitiesToggle}
              />
              {source.syncInventoryQuantities && syncStatus('skus', source.sourceContext)}
            </>
          )}
          {doesSupport('orders') && (
            <>
              <Toggle
                label="Orders"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncOrders}
                onChange={handleOrdersToggle}
              />
              {source.syncOrders && syncStatus('orders', source.sourceContext)}
            </>
          )}
          {doesSupport('subscriptions') && (
            <>
              <Toggle
                label="Subscriptions"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncSubscriptions}
                onChange={handleSubscriptionsToggle}
              />
              {source.syncSubscriptions && syncStatus('subscriptions', source.sourceContext)}
            </>
          )}
          {doesSupport('purchaseOrders') && (
            <>
              <Toggle
                label="POs"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncPurchaseOrders}
                onChange={handlePosToggle}
              />
              {source.syncPurchaseOrders && syncStatus('purchaseOrders', source.sourceContext)}
            </>
          )}
          {doesSupport('transfers') && (
            <>
              <Toggle
                label="Transfers"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncTransfers}
                onChange={handleTransfersToggle}
              />
              {source.syncTransfers && syncStatus('transfers', source.sourceContext)}
            </>
          )}
          {doesSupport('bundles') && (
            <div>
              <Toggle
                label="Bundles"
                labelStyle="text-sm"
                spaceBetween
                enabled={source.syncBundles}
                onChange={handleBundlesToggle}
              />
              {source.syncBundles && syncStatus('bundles', source.sourceContext)}
              {source.sourceType === 'shopify' && (
                <a
                  href="https://bundles.app/settings/app/cogsy"
                  target="_blank"
                  rel="noreferrer"
                  className="group flex items-center text-xs text-gray-100 hover:text-midnight-100"
                >
                  {source.syncBundles ? 'Revoke Bundles access' : 'Grant Bundles access'}
                  <FiExternalLink className="ml-2 stroke-gray-100 group-hover:stroke-midnight-100" />
                </a>
              )}
            </div>
          )}

          {source.sourceType === 'shopify' && source.syncInventoryQuantities && (
            <div className="mt-6 border-t border-gray-50 pt-4 text-sm">
              <div>Sync transfers inventory to Shopify</div>

              <RadioGroup
                onChange={handleSetUpdateInventory}
                value={source.updateInventory}
                className="mt-4 flex-col justify-items-start space-y-2 text-sm"
              >
                {[
                  ['Always', true],
                  ['Never', false],
                  ['Ask each time', null],
                ].map((item) => (
                  <RadioGroup.Option value={item[1]} key={item[0]}>
                    {({ checked }) => (
                      <div className="mr-7 flex cursor-pointer items-center space-x-2">
                        <RadioButton isChecked={checked} />
                        <span>{item[0]}</span>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
          )}
        </div>
      )}

      <RemoveSourcePopup
        open={removeSourcePopupOpen}
        onClose={toggleRemoveSourcePopupOpen}
        handleRemoveSource={handleRemoveSource}
      />
    </div>
  );
};

Source.propTypes = {
  source: PropTypes.shape({
    sourceType: PropTypes.string.isRequired,
    sourceId: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    sourceContext: PropTypes.object.isRequired,
    syncAnalytics: PropTypes.bool,
    syncBasics: PropTypes.bool,
    syncBundles: PropTypes.bool,
    syncExport: PropTypes.bool,
    syncInventoryQuantities: PropTypes.bool,
    syncOrders: PropTypes.bool,
    syncPurchaseOrders: PropTypes.bool,
    syncSkus: PropTypes.bool,
    syncTransfers: PropTypes.bool,
    syncSubscriptions: PropTypes.bool,
    isDefaultSource: PropTypes.bool,
    isSuspended: PropTypes.bool,
    updateInventory: PropTypes.bool,
  }).isRequired,
};

export default Source;
