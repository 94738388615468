import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import { useAccount } from '../../AccountProvider.jsx';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const MERGE_SKUS = gql`
  mutation MergeSkus($skusFrom: [String]!, $skuTo: String!) {
    mergeSkus(skusFrom: $skusFrom, skuTo: $skuTo) {
      skuMerges {
        skuFrom
        skuTo
      }
    }
  }
`;

const MergeSkusPopup = ({ open, onClose, onSave, selectedSkus }) => {
  const [mainSku, setMainSku] = useState(selectedSkus[0]?.sku);
  const [mergeSkus, { loading }] = useMutation(MERGE_SKUS);
  const { addAlert } = useAlerts();
  const { account } = useAccount();

  const handleMergingSkus = React.useCallback((e) => {
    e.preventDefault();

    const doConnect = async () => {
      const skusFrom = selectedSkus.filter((sku) => sku.sku !== mainSku).map(({ sku }) => sku);

      await mergeSkus({
        variables: { skusFrom, skuTo: mainSku },
        update: (cache) => {
          cache.evict({ _id: cache.identify({ _id: account._id, __typename: 'Account' }) });
          cache.evict({ _id: cache.identify({ __typename: 'SkuConnection' }) });
          cache.evict({ _id: cache.identify({ __typename: 'SkuCategoryConnection' }) });
          cache.gc();
        },
      });
      addAlert('Products merged successfully', {
        level: 'success',
      });
      onSave();
    };

    doConnect();
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-xl rounded bg-white shadow">
          <div className="z-10 m-0 mx-auto w-full rounded bg-purple-10 p-5">
            <Dialog.Title className="ml-3 text-lg font-bold text-purple-100">
              Merging Products
            </Dialog.Title>
            <p className="ml-3 mt-2 text-xs text-gray-100">Select your main product below.</p>
            <p className="ml-3 text-xs text-gray-100">
              This will be the product into which the others will merge.
            </p>
            <p className="ml-3 text-xs text-gray-100">
              Once merged, the old products will be archived.
            </p>
          </div>

          <form className="mt-4 text-xs" onSubmit={handleMergingSkus}>
            <div className="p-8">
              <RadioGroup
                className="max-h-60 space-y-5 overflow-y-auto font-normal"
                onChange={setMainSku}
                value={mainSku}
              >
                {selectedSkus.map(({ sku, productName }) => (
                  <RadioGroup.Option value={sku} key={sku}>
                    {({ checked }) => (
                      <div className="flex cursor-pointer">
                        {checked && <span className="text-sm font-bold text-purple-100">Main</span>}
                        <div
                          className={classnames(
                            'shrink-0 self-start rounded-full',
                            checked &&
                              'ml-5 mr-3 mt-1.5 h-2 w-2 bg-purple-100 ring-2 ring-purple-100 ring-offset-2 ring-offset-gray-10',
                            !checked && 'ml-12 mr-2 mt-0.5 h-4 w-4 border border-gray-75 bg-white',
                          )}
                        />
                        <div className="ml-3">
                          <div className="text-sm font-bold uppercase">{sku}</div>
                          <div className="mt-1 text-xs text-gray-100">{productName}</div>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>

            <div className="my-4 mr-3 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Merge Products'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

MergeSkusPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      productName: PropTypes.string,
      variantId: PropTypes.string,
    }),
  ).isRequired,
};

export default MergeSkusPopup;
