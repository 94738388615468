import React, { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { FiSave } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { useAccount } from '../../AccountProvider.jsx';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';
import DeleteButton from '../../Components/DeleteButton.jsx';
import EmptyTable from '../../Components/EmptyTable.jsx';
import Table from '../../Components/Table.jsx';

const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($input: UpdatePreferencesInput!) {
    updateAccountPreferences(input: $input) {
      blockedGroups
    }
  }
`;

const BlockedGroups = () => {
  const { account } = useAccount();
  const { addAlert } = useAlerts();
  const [updateAccountPreferences] = useMutation(UPDATE_PREFERENCES);
  const { register, handleSubmit, setValue } = useForm({ mode: 'all' });

  const doSubmit = async (blockedGroups) => {
    try {
      await updateAccountPreferences({ variables: { input: { blockedGroups } } });
      addAlert(
        'Blocked collections have been updated. You may need to resync to archive existing products.',
        { level: 'success' },
      );
      setValue('blockedId', '');
    } catch (error) {
      addAlert('An error has occurred! Please refresh the page and try again.', { error });
    }
  };

  const onSubmit = useCallback(({ blockedId }) => {
    const trimmedId = blockedId.trim();
    if (!trimmedId.match(`gid://shopify/Collection/[0-9]*$`)) {
      addAlert('Invalid format, use gid://shopify/Collection/', { level: 'error' });
      return;
    }
    const blockedGroups = account.preferences.blockedGroups?.map((b) => b) || [];
    if (blockedGroups.includes(blockedId.trim())) {
      addAlert('ID already blocked', { level: 'error' });
      return;
    }
    blockedGroups.push(blockedId.trim());
    doSubmit(blockedGroups);
  });

  const columns = [
    { field: 'id', content: 'ID', widthClass: 'w-[50%]' },
    { field: 'action', content: 'Action', widthClass: 'w-[60%]' },
  ];

  const rows = account.preferences?.blockedGroups?.map((id) => ({
    id,
    action: (
      <DeleteButton
        className="-ml-6"
        onDelete={() => doSubmit(account.preferences.blockedGroups?.filter((b) => b !== id))}
      />
    ),
  }));

  return (
    <div className="m-3 rounded-lg bg-white px-6 pb-7 pt-5 text-midnight-100 shadow">
      {account.preferences?.blockedGroups?.length ? (
        <div>
          <Table columns={columns} rows={rows} noDataText="There are no ids">
            <Table.Header>Blocked Collections</Table.Header>
            <Table.Body />
          </Table>
        </div>
      ) : (
        <EmptyTable
          title="No Blocked Collections added"
          bodyText="You can add collection ids from Shopify in the format gid://shopify/Collection/123456789"
        />
      )}
      <div className="mt-6">
        <p className="text-sm">
          To exclude products from Cogsy, we can add them to a collection in Shopify, then add the
          ID of that collection here. When the next full product sync runs, any product in the
          matching collection will be archived.
        </p>
        <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            id="blockedId"
            placeholder="gid://shopify/Collection/292168794274"
            className="input"
            name="apiKey"
            {...register(`blockedId`)}
          />
          <Button
            icon={FiSave}
            type="submit"
            className="mt-4"
            variant="primary"
            label="Block this ID"
            slim
          />
        </form>
      </div>
    </div>
  );
};

export default BlockedGroups;
