import React from 'react';
import PropTypes from 'prop-types';

const LinnworksIcon = ({ className = '', ...props }) => (
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      transform="scale(0.450000)"
      stroke="none"
      d="M22 22.6h2.2l.2-.2v-2.8l.3-.3h2.8l.2-.2v-2.2l-.2-.2h-2.8l-.3-.3v-2.8l-.2-.2H22l-.2.2v2.8l-.3.3h-2.8l-.2.2v2.2l.2.2h2.8l.3.3v2.8zM4.6 16.9v2.2l.3.2h8.7l.3-.2v-2.2l-.3-.2H4.9zM30.2 24.5c-.2.1-.3.1-.5.2-.3.6-.6 1.2-1 1.7-.5.8-1.2 1.5-1.8 2.2-.7.7-1.4 1.3-2.2 1.8-.8.5-1.7 1-2.6 1.4-1.9.8-3.8 1.2-5.9 1.2s-4-.4-5.9-1.2c-.9-.4-1.8-.8-2.6-1.4-.8-.5-1.5-1.2-2.2-1.8-.7-.7-1.3-1.4-1.8-2.2-.4-.5-.7-1.1-1-1.7-.2-.1-.3-.1-.5-.2l-2 .9c-.1.2-.1.4-.2.6 2.9 5.9 9.1 10 16.1 10s13.2-4.1 16.1-10c-.1-.2-.1-.3-.2-.5l-1.8-1zM2.2 11.5c.2-.1.3-.1.5-.2.3-.6.6-1.2 1-1.7.5-.8 1.2-1.6 1.8-2.3C6.2 6.7 7 6 7.8 5.5s1.7-1 2.6-1.4c1.9-.8 3.8-1.2 5.9-1.2s4 .4 5.9 1.2c.9.4 1.8.8 2.6 1.4.8.5 1.5 1.2 2.2 1.8.7.7 1.3 1.4 1.8 2.2.4.5.7 1.1 1 1.7.2.1.3.1.5.2l2-.9c.1-.2.1-.3.2-.5-3.1-5.9-9.2-10-16.3-10S3 4.1 0 10c.1.2.1.3.2.5l2 1z"
    />
  </svg>
);

LinnworksIcon.propTypes = {
  className: PropTypes.string,
};

LinnworksIcon.defaultProps = {
  className: null,
};

export default LinnworksIcon;
