import React from 'react';
import { gql, useMutation } from '@apollo/client';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import Toggle from '../Components/Toggle.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const UPDATE_BACKORDERS = gql`
  mutation UpdatePreferences($input: UpdatePreferencesInput!) {
    updateAccountPreferences(input: $input) {
      averageBackorderBufferTime
      outOfStockText
      backorder
    }
  }
`;

const Backorders = () => {
  const { addAlert } = useAlerts();
  const { account } = useAccount();
  useDocumentTitle('Backorder Settings');

  const [updateAccountPreferences] = useMutation(UPDATE_BACKORDERS);
  const [backorderToggle, setBackorderToggle] = React.useState(account.preferences.backorder);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const onSubmit = async (data) => {
    try {
      await updateAccountPreferences({
        variables: { input: { ...data, backorder: backorderToggle } },
      });
      addAlert('Changes saved successfully', { level: 'success' });
    } catch (error) {
      addAlert('An error has occurred! Please refresh the page and try again.', { error });
    }
  };

  const handleBackorderToggle = React.useCallback((data) => setBackorderToggle(data), []);

  return (
    <div className="col-span-4 text-midnight-100">
      <h5 className="text-xl">Backorders</h5>

      <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Toggle
            label="Enable Backorders"
            enabled={backorderToggle}
            onChange={handleBackorderToggle}
            spaceBetween
          />
        </div>

        <div className="mt-6">
          <label htmlFor="average_backorder_buffer_time">
            Average Processing Buffer Time
            <div className="relative">
              <input
                id="average_backorder_buffer_time"
                type="number"
                step="1"
                min="0"
                className={cn('input pr-12', errors.averageBackorderBufferTime && 'input-error')}
                defaultValue={account.preferences.averageBackorderBufferTime}
                {...register('averageBackorderBufferTime', {
                  valueAsNumber: true,
                  required: 'Please provide average processing buffer time',
                  min: {
                    value: 0,
                    message: "Average processing buffer time value can't be negative",
                  },
                })}
              />
              <span className="absolute right-4 top-4">days</span>
            </div>
          </label>
          <InputErrorMessage message={errors.averageBackorderBufferTime?.message} />
        </div>
        <div className="mt-4">
          <label htmlFor="out_of_stock_text">
            Out of Stock text
            <input
              type="text"
              id="out_of_stock_text"
              className={cn('input', errors.outOfStockText && 'input-error')}
              defaultValue={account.preferences.outOfStockText}
              {...register('outOfStockText', {
                required: 'Please provide out of stock text',
              })}
            />
          </label>
          <InputErrorMessage message={errors.outOfStockText?.message} />
          <p className="mt-2 text-gray-100">
            e.g.: <em>Estimated Shipping Date</em> or <em>Next available on</em>
          </p>
        </div>
        <div className="mt-8">
          <Button type="submit" fullWidth variant="primary" label="Save changes" />
        </div>
      </form>
    </div>
  );
};

export default Backorders;
