import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { FiExternalLink } from 'react-icons/fi';
import { isBefore } from 'date-fns';
import { useAccount } from '../AccountProvider.jsx';
import EmptyTable from '../Components/EmptyTable.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { PrevNextButtons } from '../Components/Pagination.jsx';
import SkuDetails from '../Components/SkuDetails.jsx';
import Spinner from '../Components/Spinner.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import { getSkuPagePath } from '../Skus/utils/helpers.js';

const BACKORDERS = gql`
  query GetBackorders(
    $ordersAfter: String
    $ordersBefore: String
    $isBackorder: Boolean
    $isFulfilled: Boolean
    $ordersFirst: Int
    $sortKey: OrderSortKeys
  ) {
    orders(
      after: $ordersAfter
      before: $ordersBefore
      isBackorder: $isBackorder
      isFulfilled: $isFulfilled
      first: $ordersFirst
      sortKey: $sortKey
      reverse: true
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          sourceUrl
          name
          orderId
          nextShippingDate
          totalPriceSet
          processedAt
        }
      }
    }
  }
`;

const SKUS_ON_BACKORDER = gql`
  query GetSkusOnBackorder(
    $skusAfter: String
    $skusBefore: String
    $skusFirst: Int
    $isOnBackorder: Boolean
  ) {
    skus(isOnBackorder: $isOnBackorder, first: $skusFirst, after: $skusAfter, before: $skusBefore) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          sku
          productName
          variantName
          inventoryQuantity
          isBundle
          stats {
            nextShippingDate
          }
          inventoryBreakdown {
            _id
            nextShippingDate
          }
          vendor {
            name
          }
        }
      }
    }

    locations(first: 100, onlyWithBackorderEnabled: true) {
      edges {
        node {
          _id
        }
      }
    }
  }
`;

const OrderRow = ({
  order: { orderId, name, sourceUrl, totalPriceSet, nextShippingDate, processedAt },
}) => {
  const { formatCurrency } = useAccount();

  const handleRowClick = React.useCallback(() => {
    window.open(sourceUrl, '_blank');
  }, []);

  return (
    <tr
      className={`${sourceUrl ? 'cursor-pointer' : ''} hover:bg-gray-10`}
      onClick={sourceUrl ? handleRowClick : null}
    >
      <td className="w-1/3 max-w-[1px] py-2.5 pl-6 text-xs">
        {name || orderId.replace('gid://shopify/Order/', '')}
      </td>
      <td className="px-4 text-center text-xs">{totalPriceSet && formatCurrency(totalPriceSet)}</td>
      <td className="px-4 py-2 text-center text-xs">
        {processedAt ? new Date(processedAt).toLocaleDateString() : 'N/A'}
      </td>
      <td className="px-4 py-2 text-center text-xs">
        {nextShippingDate ? new Date(nextShippingDate).toLocaleDateString() : null}
      </td>
      <td className="pr-6">
        {sourceUrl && (
          <div className="flex h-6 w-6 items-center justify-center rounded-full text-xs">
            <FiExternalLink className="stroke-gray-100" />
          </div>
        )}
      </td>
    </tr>
  );
};

OrderRow.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string,
    name: PropTypes.string,
    sourceUrl: PropTypes.string,
    totalPriceSet: PropTypes.number,
    nextShippingDate: PropTypes.string,
    processedAt: PropTypes.string,
  }).isRequired,
};

const SkuRow = ({ locationIdsWithBackorder, sku }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // We default to the globally calculated next shipping date, but if there are a
  // subset of locations that are marked with isBackorderEnabled, we take the minimum
  // date from those
  let nextShippingDate = sku?.stats?.nextShippingDate;

  if (locationIdsWithBackorder.length > 0 && Array.isArray(sku.inventoryBreakdown)) {
    nextShippingDate = sku.inventoryBreakdown
      .filter(({ _id }) => locationIdsWithBackorder.includes(String(_id)))
      .reduce((date, locationStats) => {
        if (date === null || isBefore(locationStats.nextShippingDate, date)) {
          return locationStats.nextShippingDate;
        }

        return date;
      }, null);
  }

  return (
    <tr
      className="w-full cursor-pointer p-2 text-left hover:bg-gray-10"
      onClick={() => navigate(getSkuPagePath(sku), { state: { fromPath: pathname } })}
    >
      <td className="w-1/2 max-w-[1px] py-2.5 pl-6 text-xs">
        <SkuDetails sku={sku} />
      </td>
      <td className="pl-8 pr-6 text-center text-xs">
        {nextShippingDate ? new Date(nextShippingDate).toLocaleDateString() : 'N/A'}
      </td>
    </tr>
  );
};

SkuRow.propTypes = {
  locationIdsWithBackorder: PropTypes.arrayOf(PropTypes.string).isRequired,
  sku: PropTypes.shape({
    sku: PropTypes.string,
    sourceUrl: PropTypes.string,
    productName: PropTypes.string,
    variantName: PropTypes.string,
    isBundle: PropTypes.bool,
    stats: PropTypes.shape({
      nextShippingDate: PropTypes.string,
    }),
    inventoryBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        nextShippingDate: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const Index = () => {
  useDocumentTitle('Backorders');
  const { search } = useLocation();

  const [backordersVariables, setBackordersVariables] = React.useState({
    isBackorder: true,
    isFulfilled: false,
    ordersFirst: 20,
  });

  const [skusVariables, setSkusVariables] = React.useState({
    isOnBackorder: true,
    skusFirst: 10,
  });

  const { loading: backordersLoading, data: backordersData } = useQuery(BACKORDERS, {
    variables: backordersVariables,
  });

  const { loading: skusLoading, data: skusData } = useQuery(SKUS_ON_BACKORDER, {
    variables: skusVariables,
  });

  React.useEffect(() => {
    const query = new URLSearchParams(search);

    setBackordersVariables({
      ...backordersVariables,
      sortKey: query.get('sortKey') || 'processedAt',
      ordersAfter: query.get('ordersAfter'),
      ordersBefore: query.get('ordersBefore'),
    });

    setSkusVariables({
      ...skusVariables,
      skusAfter: query.get('skusAfter'),
      skusBefore: query.get('skusBefore'),
    });
  }, [search]);

  const locationIdsWithBackorder = skusData?.locations.edges.map(({ node: { _id } }) => _id);

  return (
    <>
      <PageHeader text="Backorders" />
      <div className="mt-8 grid grid-cols-12 gap-x-6">
        <div className="col-span-6">
          {backordersLoading && (
            <div className="mt-8">
              <Spinner />
            </div>
          )}
          {backordersData && backordersData.orders.edges.length === 0 && (
            <div className="mt-8">
              <EmptyTable title="Backorders" bodyText="No orders to show at this time" />
            </div>
          )}
          {backordersData && backordersData.orders.edges.length !== 0 && (
            <>
              <div className="mt-8 rounded-lg bg-white pb-7 pt-5 text-sm text-midnight-100 shadow">
                <h5 className="mx-6 mb-3 border-b border-gray-50 pb-4 font-bold">Backorders</h5>
                <table className="min-w-full table-fixed">
                  <thead>
                    <tr className="text-left">
                      <th className="pb-9 pl-6 text-xs font-normal">Order ID</th>
                      <th className="px-4 pb-9 text-center text-xs font-normal">Value</th>
                      <th className="px-4 pb-9 text-center text-xs font-normal">Order Date</th>
                      <th className="px-4 pb-9 text-center text-xs font-normal">
                        Planned Shipping Date
                      </th>
                      <th className="pb-9 pr-6" aria-label="Order url" />
                    </tr>
                  </thead>
                  <tbody className="mt-9">
                    {backordersData.orders.edges.map(({ node: order }) => (
                      <OrderRow key={order.orderId} order={order} />
                    ))}
                  </tbody>
                </table>
              </div>
              <PrevNextButtons
                edges={backordersData.orders.edges}
                pageInfo={backordersData.orders.pageInfo}
                prefix="orders"
              />
            </>
          )}
        </div>

        <div className="col-span-6">
          {skusLoading && (
            <div className="mt-8">
              <Spinner />
            </div>
          )}
          {skusData && skusData.skus.edges.length === 0 && (
            <div className="mt-8">
              <EmptyTable
                title="Currently on Backorder"
                bodyText="No products to show at this time"
              />
            </div>
          )}
          {skusData && skusData.skus.edges.length !== 0 && (
            <>
              <div className="mt-8 rounded-lg bg-white pb-7 pt-5 text-sm text-midnight-100 shadow">
                <h5 className="mx-6 mb-3 border-b border-gray-50 pb-4 font-bold">
                  Currently on Backorder
                </h5>
                <table className="min-w-full table-fixed">
                  <thead>
                    <tr className="text-left">
                      <th className="pb-9 pl-6 text-xs font-normal">Product</th>
                      <th className="pb-9 pl-8 pr-6 text-center text-xs font-normal">
                        Estimated Shipping Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="mt-9">
                    {skusData.skus.edges.map(({ node: sku }) => (
                      <SkuRow
                        key={sku.sku}
                        sku={sku}
                        locationIdsWithBackorder={locationIdsWithBackorder}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <PrevNextButtons
                edges={skusData.skus.edges}
                pageInfo={skusData.skus.pageInfo}
                prefix="skus"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
