import React from 'react';
import { FiRotateCw } from 'react-icons/fi';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';

const SyncPurchaseOrdersButton = () => {
  const { account, axios } = useAccount();
  const { addAlert } = useAlerts();

  const triggerSync = async (source) => {
    await axios.post(`/api/sources/${source.sourceId}/sync/purchaseOrders`, { full: true });
  };

  const handleSyncPurchaseOrders = async () => {
    addAlert('The sync has been triggered in background, please hold on...', { level: 'success' });
    await Promise.all((account.sources || []).map(async (source) => {
      if (source.syncPurchaseOrders) {
        await triggerSync(source);
      }
    }));
  };

  return (
    <Button variant="tertiary" slim icon={FiRotateCw} label="Sync POs" onClick={handleSyncPurchaseOrders} />
  );
};

export default SyncPurchaseOrdersButton;
