import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import { useAlerts } from '../../Components/AlertsProvider.jsx';
import Button from '../../Components/Button.jsx';

const SET_SKU_NAME = gql`
  mutation SetSkuName($_id: ID!, $productNameVariantId: String!) {
    setDefaultSkuName(_id: $_id, productNameVariantId: $productNameVariantId) {
      _id
      sku
      productName
    }
  }
`;

const SelectSkuNamePopup = ({ open, onClose, sku }) => {
  const skuNames = sku.sources.map((s) => ({ productName: s.productName, variantId: s.variantId }));
  const [selectedName, setSelectedName] = useState(
    sku.productNameVariantId || skuNames[0]?.variantId,
  );
  const [setSkuName, { loading }] = useMutation(SET_SKU_NAME);

  const { addAlert } = useAlerts();

  const handleSetSkuName = React.useCallback((e) => {
    e.preventDefault();

    const doSetSkuName = async () => {
      const productNameVariantId = skuNames.find(
        (name) => name.variantId === selectedName,
      ).variantId;
      await setSkuName({ variables: { _id: sku._id, productNameVariantId } });

      addAlert('Product name selected successfully', {
        level: 'success',
      });

      onClose();
    };

    doSetSkuName();
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-xl rounded bg-white shadow">
          <div className="z-10 m-0 mx-auto w-full rounded bg-purple-10 p-5">
            <Dialog.Title className="ml-3 text-lg font-bold text-purple-100">
              Select Default Product Name
            </Dialog.Title>
            <p className="ml-3 mt-2 text-xs text-gray-100">
              Select your preferred product name below.
            </p>
            <p className="ml-3 text-xs text-gray-100">
              Once set, this property can be changed again later.
            </p>
          </div>

          <form className="mt-4 text-xs" onSubmit={handleSetSkuName}>
            <div className="p-8">
              <RadioGroup
                className="max-h-60 space-y-5 overflow-y-auto font-normal"
                onChange={setSelectedName}
                value={selectedName}
              >
                {skuNames.map(({ productName, variantId }) => (
                  <RadioGroup.Option value={variantId} key={variantId}>
                    {({ checked }) => (
                      <div className="flex cursor-pointer items-center">
                        <div
                          className={classnames(
                            'shrink-0 rounded-full',
                            checked &&
                              'ml-5 mr-3 h-2 w-2 bg-purple-100 ring-2 ring-purple-100 ring-offset-2 ring-offset-gray-10',
                            !checked && 'ml-4 mr-3 h-4 w-4 border border-gray-75 bg-white',
                          )}
                        />
                        <span className="ml-3 text-xs text-gray-100">{productName}</span>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>

            <div className="my-4 mr-3 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Save Changes'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

SelectSkuNamePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sku: PropTypes.shape({
    _id: PropTypes.string,
    sku: PropTypes.string,
    productName: PropTypes.string,
    productNameVariantId: PropTypes.string,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        variantId: PropTypes.string,
        productName: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SelectSkuNamePopup;
