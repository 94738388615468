import React from 'react';
import PropTypes from 'prop-types';

const FlexeIcon = ({ className = '', ...props }) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <g transform="translate(0.692000, 0.217000)">
      <path d="M4.20350849,5.46990732 C5.44206213,6.084172 8.52118223,6.08138749 9.75973587,5.46990732 C11.3095986,4.70305104 13.8852779,0 13.8852779,0 L11.1776125,0 C11.1776125,0 9.57762839,3.07355106 8.58188472,3.57921954 C7.9163849,3.91614623 6.24845857,3.91614623 5.58017423,3.57921954 C4.58108914,3.07689248 2.96773937,0 2.96773937,0 L0.114722145,0 C0.114722145,0 2.66032857,4.70305104 4.20295159,5.46990732 L4.20350849,5.46990732 Z" />
      <path d="M5.78622857,10.3907077 C6.30971797,10.1267354 7.61621385,10.1267354 8.13970325,10.3907077 C9.30753013,10.9860376 11.1776125,14.6047973 11.1776125,14.6081387 L14,14.6081387 C14,14.6081387 11.2488961,9.35987907 9.54365727,8.50057679 C8.44321572,7.94534389 5.69545328,7.95146983 4.59111341,8.50057679 C2.86304149,9.35653765 0.0033414217,14.6081387 0,14.6081387 L2.70766538,14.6081387 C2.70766538,14.6047973 4.61171884,10.9826962 5.78622857,10.3907077 Z" />
    </g>
  </svg>
);

FlexeIcon.propTypes = {
  className: PropTypes.string,
};

FlexeIcon.defaultProps = {
  className: null,
};

export default FlexeIcon;
