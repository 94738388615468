import React from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert.jsx';

const Toast = ({ autoHideDuration, children, onClose, open, level }) => {
  React.useEffect(() => {
    setTimeout(() => {
      onClose();
    }, autoHideDuration);
  }, [autoHideDuration]);

  return open ? (
    <Alert level={level} onClose={onClose}>
      {children}
    </Alert>
  ) : null;
};

Toast.propTypes = {
  autoHideDuration: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  level: PropTypes.string,
};

Toast.defaultProps = {
  autoHideDuration: 5000,
  level: 'error',
};

export default Toast;
