import { gql } from '@apollo/client';
import { FRAGMENT_TRANSFER } from '../InternalTransfers/Queries.js';

const FRAGMENT_PO = gql`
  ${FRAGMENT_TRANSFER}

  fragment PurchaseOrderFields on PurchaseOrder {
    _id
    sourceId
    vendor {
      _id
      name
      leadTime
    }
    purchaseOrderId
    customPurchaseOrderNumber
    dateCreated
    issueDate
    expectedDeliveryDate
    statusText
    itemsCount
    items {
      sku
      quantity
      unitCost
    }
    updatedAt
    totalCost
    location {
      _id
      name
    }
    costAdjustments {
      label
      amount
    }
    status
    transfers(first: 50) {
      edges {
        node {
          ...TransferFields
        }
      }
    }
    trailLogs {
      _id
      logType
      logContext
      createdAt
      user {
        email
        fullName
      }
    }
  }
`;

export const PURCHASE_ORDERS = gql`
  ${FRAGMENT_PO}

  query purchaseOrders(
    $after: String
    $before: String
    $first: Int
    $sortKey: PurchaseOrderSortKeys
    $location: String
    $vendor: String
    $statuses: [PurchaseOrderStatus]
  ) {
    purchaseOrders(
      after: $after
      before: $before
      first: $first
      sortKey: $sortKey
      reverse: true
      location: $location
      vendor: $vendor
      statuses: $statuses
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          ...PurchaseOrderFields
        }
      }
    }
  }
`;

export const REMOVE_PURCHASE_ORDER = gql`
  mutation RemovePurchaseOrder($purchaseOrderId: String!) {
    removePurchaseOrder(_id: $purchaseOrderId) {
      _id
    }
  }
`;

export const COMPLETE_DRAFT_PURCHASE_ORDER = gql`
  ${FRAGMENT_PO}

  mutation CompleteDraftPurchaseOrder($_id: ID!) {
    submitPurchaseOrder(_id: $_id) {
      ...PurchaseOrderFields
    }
  }
`;

export const VENDORS = gql`
  query getVendors {
    skuVendors {
      vendorId
      vendorName
      replenishNow
    }
  }
`;

export const CREATE_DRAFT_PURCHASE_ORDER = gql`
  mutation CreateDraftPurchaseOrder($locationId: ID!, $vendorId: ID!) {
    createPurchaseOrder(locationId: $locationId, vendorId: $vendorId) {
      _id
      customPurchaseOrderNumber
      issueDate
      expectedDeliveryDate
      updatedAt
      vendor {
        _id
        name
      }
      recommendInventoryForLocations
      items {
        sku
        quantity
      }
    }
  }
`;

export const PO = gql`
  ${FRAGMENT_PO}

  query GetPO($_id: String!) {
    purchaseOrder(_id: $_id) {
      ...PurchaseOrderFields
      expectedShipmentDate
      expectedProductionCompletionDate
      recommendInventoryForLocations
    }
  }
`;

export const UPDATE_PURCHASE_ORDER = gql`
  ${FRAGMENT_PO}

  mutation UpdatePurchaseOrder($input: PurchaseOrderInput!, $purchaseOrderId: String!) {
    updatePurchaseOrder(input: $input, _id: $purchaseOrderId) {
      ...PurchaseOrderFields
      recommendInventoryForLocations
      expectedShipmentDate
      expectedProductionCompletionDate
    }
  }
`;

export const SKU_QUERY = gql`
  query GetSku($sku: String!) {
    sku(sku: $sku) {
      _id
      sku
      productName
      variantName
      variantId
      inventoryQuantity
      cost
      type
      sources {
        sourceId
        variantId
        productName
        variantName
      }
      stats {
        targetInventoryQuantity
        targetBundleInventoryQuantity
        targetMissingInventoryQuantity
        incomingInventoryQuantity
      }
      inventoryBreakdown(onlyActive: true) {
        _id
        name
        inventoryQuantity
        targetInventoryQuantity
        targetBundleInventoryQuantity
        targetMissingInventoryQuantity
        incomingInventoryQuantity
        replenishmentStatus
        sourceCount
      }
      vendor {
        name
        _id
      }
    }
  }
`;

export const SKU_NAMES_QUERY = gql`
  query GetSkuNames($skus: [String]!) {
    skuNames(skus: $skus) {
      _id
      sku
      productName
    }
  }
`;

export const clearPurchaseOrdersCache = (cache) => {
  cache.evict({ fieldName: 'purchaseOrders' });
  cache.gc();
};
