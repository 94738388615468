import React from 'react';

// eslint-disable-next-line react/prop-types
const BlankWrapper = ({ children }) => (
  <div className="flex min-h-screen flex-col items-center justify-center">
    <img src="/logo.svg" alt="Cogsy" className="max-w-xs" />
    <div>
      {children}
    </div>
  </div>
);

export default BlankWrapper;
