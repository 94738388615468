/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { FiAlertTriangle, FiChevronDown, FiShoppingBag } from 'react-icons/fi';
import { Listbox } from '@headlessui/react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { missingSkuOrVendorCopy } from '../Skus/Product.jsx';
import Rank from '../Skus/Rank.jsx';
import { getSafetyStock } from '../Utils/subscriptions.js';
import { useDemo } from './DemoProvider.jsx';
import { SkeletonText } from './Skeletons.jsx';
import SubscriptionIndicator from './SubscriptionIndicator.jsx';
import Tooltip from './Tooltip.jsx';

const SkuDetails = ({
  sku,
  variantId,
  isRankVisible,
  hasDuplicates,
  handleOnClick,
  handleVariantSelect,
}) => {
  const { isDemo } = useDemo();

  const { stats: { subscriptions = null } = {} } = sku;

  const currentSource = sku.sources?.find((s) => s.variantId === (variantId ?? sku.variantId));
  const skuDuplicateVariants = sku.sources
    // currentSource can be undefined if the variantId is not supplied
    ?.filter((s) => s.sourceId === currentSource?.sourceId)
    .map((s) => ({ productName: s.productName, variantId: s.variantId }));

  const productName = variantId
    ? sku.sources?.find((s) => s.variantId === variantId).productName
    : sku.productName;
  const variantName = variantId
    ? sku.sources?.find((s) => s.variantId === variantId).variantName
    : sku.variantName;

  return (
    <div className="flex" onClick={() => (handleOnClick ? handleOnClick(sku) : undefined)}>
      {isRankVisible && (
        <div className="mr-2 mt-1">
          <Rank category={sku.stats.category} velocity={sku.stats.velocity} />
        </div>
      )}
      <div className="max-w-[232px]">
        <div className="flex items-center">
          <div
            className={classnames('truncate font-bold uppercase leading-8', isDemo && 'blur-sm')}
            title={!isDemo ? sku.sku.toUpperCase() : undefined}
          >
            <span>{sku.sku}</span>
          </div>
          {sku.isBundle && (
            <Tooltip className="ml-2">
              <Tooltip.Element className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-50">
                <FiShoppingBag className="stroke-gray-100" />
              </Tooltip.Element>
              <Tooltip.Body>This product is a bundle</Tooltip.Body>
            </Tooltip>
          )}
          {hasDuplicates && (
            <Tooltip className="ml-2">
              <Tooltip.Element className="flex h-6 w-6 items-center justify-center rounded-full bg-red-25">
                <FiAlertTriangle className="text-red-100" />
              </Tooltip.Element>
              <Tooltip.Body align="left">
                Duplicate variant detected. You may have multiple products that share this SKU.
              </Tooltip.Body>
            </Tooltip>
          )}
          {(sku.isWithoutSku || !sku.vendor) && (
            <Tooltip className="ml-2">
              <Tooltip.Element className="flex h-6 w-6 items-center justify-center rounded-full bg-red-25">
                <FiAlertTriangle className="text-red-100" />
              </Tooltip.Element>
              <Tooltip.Body align="left">
                {missingSkuOrVendorCopy(sku.isWithoutSku, !sku.vendor)}&nbsp;
              </Tooltip.Body>
            </Tooltip>
          )}
          {subscriptions && (
            <div className="ml-2">
              <SubscriptionIndicator
                isAtRisk={sku.hasAtRiskSubscriptions}
                info={
                  sku.hasAtRiskSubscriptions
                    ? `This product is at-risk of being out of stock soon. Reserve ${pluralize(
                        'unit',
                        sku.stats.firstOutOfStockDate
                          ? getSafetyStock(
                              sku.stats.firstOutOfStockDate,
                              subscriptions.reserveInventoryHorizon,
                              {
                                reserveInventoryByDate: subscriptions.reserveInventoryByDate,
                                bundleReserveInventoryByDate:
                                  subscriptions.bundleReserveInventoryByDate || [],
                              },
                            )
                          : subscriptions.aggregateReserveInventory +
                              subscriptions.aggregateBundleReserveInventory,
                        true,
                      )} as safety stock to fulfil subscription renewals until ${new Date(
                        subscriptions.reserveInventoryHorizon,
                      ).toLocaleDateString()} when new stock will be available.`
                    : null
                }
              />
            </div>
          )}
        </div>
        {hasDuplicates ? (
          <Listbox value={variantId} onChange={handleVariantSelect}>
            <div className="relative text-xs">
              <Listbox.Button className="relative h-8 w-full cursor-default rounded-lg border border-midnight-10 bg-white px-4 py-2 pr-10 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-2">
                <div className="w-full truncate">
                  {variantId
                    ? skuDuplicateVariants.find((variant) => variant.variantId === variantId)
                        .productName
                    : 'Duplicate variant, select one.'}
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <FiChevronDown className="text-purple-100" size={20} />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-2 ring-purple-100 ring-opacity-5 focus:outline-none">
                {skuDuplicateVariants.map((variant) => (
                  <Listbox.Option
                    key={variant.variantId}
                    value={variant.variantId}
                    className={({ active }) =>
                      classnames(
                        'px-4 py-2',
                        active ? 'bg-purple-100 text-white' : 'bg-white text-midnight-100',
                      )
                    }
                  >
                    <div className="truncate">{variant.productName}</div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        ) : (
          <>
            <div
              className={classnames('truncate', isDemo && 'blur-sm')}
              title={!isDemo ? productName : undefined}
            >
              {productName}
            </div>
            <div
              className={classnames('truncate text-gray-100', isDemo && 'blur-sm')}
              title={!isDemo ? variantName : undefined}
            >
              {variantName}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SkuDetails.propTypes = {
  sku: PropTypes.shape({
    sku: PropTypes.string,
    productName: PropTypes.string,
    variantName: PropTypes.string,
    variantId: PropTypes.string,
    hasAtRiskSubscriptions: PropTypes.bool,
    stats: PropTypes.shape({
      category: PropTypes.string,
      velocity: PropTypes.string,
      replenishmentStatus: PropTypes.string,
      firstOutOfStockDate: PropTypes.string,
      subscriptions: PropTypes.shape({
        aggregateBundleReserveInventory: PropTypes.number,
        aggregateReserveInventory: PropTypes.number,
        bundleReserveInventoryByDate: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            reserveInventory: PropTypes.number,
          }),
        ),
        reserveInventoryByDate: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            reserveInventory: PropTypes.number,
          }),
        ),
        reserveInventoryHorizon: PropTypes.string,
      }),
    }),
    sources: PropTypes.arrayOf(PropTypes.shape({})),
    isBundle: PropTypes.bool,
    isWithoutSku: PropTypes.bool,
    vendor: PropTypes.shape({}),
  }).isRequired,
  variantId: PropTypes.string,
  isRankVisible: PropTypes.bool,
  hasDuplicates: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  handleOnClick: PropTypes.any,
  handleVariantSelect: PropTypes.func,
};

SkuDetails.defaultProps = {
  variantId: null,
  isRankVisible: false,
  hasDuplicates: false,
  handleOnClick: undefined,
  handleVariantSelect: undefined,
};

const Skeleton = () => (
  <>
    <div className="mr-2 truncate font-bold uppercase leading-8">
      <SkeletonText />
    </div>
    <div className="truncate">
      <SkeletonText />
    </div>
    <div className="text-gray-100">
      <SkeletonText />
    </div>
  </>
);

SkuDetails.Skeleton = Skeleton;

export default SkuDetails;
