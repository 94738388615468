import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown } from 'react-icons/fi';
import { Combobox, Transition } from '@headlessui/react';
import classnames from 'classnames';
import { useAccount } from '../AccountProvider.jsx';
import MapPinIcon from '../Icons/MapPinIcon.jsx';

const LocationFilter = ({ value, onChange, label, showAllLocations, disabled }) => {
  const {
    locations: { edges: allLocations },
  } = useAccount();
  const comboboxInputRef = useRef();
  const [locationQuery, setLocationQuery] = useState('');
  const [dataValueAttr, setDataValueAttr] = useState('');

  const locations = allLocations.filter(({ node: location }) => !location.hideReplenishment);

  if (locations?.length < 2) return null;

  const filteredLocations =
    locationQuery === ''
      ? locations
      : locations.filter(({ node: { name } }) =>
          name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(locationQuery.toLowerCase().replace(/\s+/g, '')),
        );

  const getLocationNameById = (id) =>
    locations.find(({ node: { _id } }) => _id === id)?.node?.name || label;

  useEffect(() => {
    setDataValueAttr(getLocationNameById(value));
  }, [value]);

  return (
    <Combobox
      value={value}
      onChange={onChange}
      as="div"
      className="relative flex items-center font-medium text-midnight-100"
      disabled={disabled}
    >
      <Combobox.Label aria-label="Select location">
        <MapPinIcon height="19" className="text-purple-100" />
      </Combobox.Label>

      <div
        className="relative ml-2 h-7 min-w-[5rem] max-w-[12rem] text-sm font-bold transition-[max-width] after:invisible after:content-[attr(data-value)]"
        data-value={dataValueAttr}
      >
        <Combobox.Input
          ref={comboboxInputRef}
          displayValue={(locationId) => getLocationNameById(locationId)}
          onChange={(e) => {
            setLocationQuery(e.target.value);
            setDataValueAttr(e.target.value);
          }}
          className="absolute left-0 top-0 h-full w-full text-ellipsis border-0 border-b border-transparent bg-transparent p-0 pt-px text-sm font-bold transition-colors focus:border-b focus:border-gray-100 focus:ring-0"
        />
      </div>

      {disabled ? null : (
        <Combobox.Button className="ml-1">
          {({ open }) => (
            <FiChevronDown
              className={classnames(
                'h-6 w-6 text-midnight-100 transition-transform',
                open && '-rotate-180',
              )}
              aria-hidden="true"
              strokeWidth={1.5}
            />
          )}
        </Combobox.Button>
      )}

      <Transition
        as={Fragment}
        enter="transition-opacity"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => {
          comboboxInputRef.current.blur();
          setLocationQuery('');
        }}
      >
        <Combobox.Options className="absolute left-3 top-9 z-10 max-h-[21.2rem] max-w-[20rem] overflow-y-auto rounded-lg border border-midnight-10 bg-white text-xs shadow-md">
          {filteredLocations.length === 0 && locationQuery !== '' && (
            <div className="select-none px-3 py-1.5 first-of-type:pt-2.5 last-of-type:pb-2.5">
              Nothing found
            </div>
          )}

          {showAllLocations &&
            (!!locationQuery || (
              <Combobox.Option
                value=""
                className={({ active }) =>
                  classnames(
                    'px-3 py-1.5 font-bold first-of-type:pt-2.5 last-of-type:pb-2.5',
                    active && 'bg-purple-100 text-white',
                  )
                }
              >
                All Locations
              </Combobox.Option>
            ))}

          {filteredLocations.map(({ node: { _id, name } }) => (
            <Combobox.Option
              key={_id}
              value={_id}
              className={({ active }) =>
                classnames(
                  'px-3 py-1.5 first-of-type:pt-2.5 last-of-type:pb-2.5',
                  active && 'bg-purple-100 text-white',
                  !_id && 'font-bold',
                )
              }
            >
              {name}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
};

LocationFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  showAllLocations: PropTypes.bool,
  disabled: PropTypes.bool,
};

LocationFilter.defaultProps = {
  value: '',
  label: 'All Locations',
  showAllLocations: true,
  disabled: false,
};

export default LocationFilter;
