export const PLANS = {
  free: {
    name: 'Free',
    hidden: true,
    tiers: [
      { revenueTo: Number.MAX_SAFE_INTEGER, price: 0 },
    ],
    optionsIncluded: [
      'forecast-aws',
      'production-plans',
      'growth-plans',
      'marketing-events',
      'new-product-plans',
      'linnworks',
      'skubana',
      'fulfil',
      'anvyl',
      'cin7',
      'flexe',
    ],
    hasAllOptions: true,
  },
  manual: {
    name: 'Custom',
    hidden: true,
    tiers: [
      { revenueTo: Number.MAX_SAFE_INTEGER, price: null },
    ],
    optionsIncluded: [
      'forecast-aws',
      'production-plans',
      'growth-plans',
      'marketing-events',
      'new-product-plans',
      'linnworks',
      'skubana',
      'fulfil',
      'anvyl',
      'cin7',
      'flexe',
    ],
    hasAllOptions: true,
  },
  legacy2021: {
    name: 'Full',
    hidden: true,
    tiers: [
      { revenueTo: 1000, price: 200 },
      { revenueTo: 5000, price: 200 },
      { revenueTo: 10000, price: 400 },
      { revenueTo: 15000, price: 700 },
      { revenueTo: 20000, price: 1000 },
      { revenueTo: Number.MAX_SAFE_INTEGER, price: 1500 },
    ],
    optionsIncluded: [
      'forecast-aws',
      'production-plans',
      'growth-plans',
      'marketing-events',
      'new-product-plans',
      'linnworks',
      'skubana',
      'fulfil',
      'anvyl',
      'cin7',
      'flexe',
    ],
    hasAllOptions: true,
  },
  growth: {
    name: 'Growth',
    tiers: [
      { revenueTo: 500, price: 49 },
      { revenueTo: 1000, price: 69 },
      { revenueTo: 2500, price: 99 },
      { revenueTo: 5000, price: 169 },
    ],
    optionsIncluded: [],
  },
  scale: {
    name: 'Scale',
    tiers: [
      { revenueTo: 5000, price: 199 },
      { revenueTo: 10000, price: 399 },
      { revenueTo: 15000, price: 699 },
      { revenueTo: 20000, price: 999 },
    ],
    optionsIncluded: [
      'forecast-aws',
      'production-plans',
      'growth-plans',
      'marketing-events',
      'new-product-plans',
      'linnworks',
      'skubana',
      'fulfil',
      'anvyl',
      'cin7',
      'flexe',
    ],
    hasAllOptions: true,
  },
};
