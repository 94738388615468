import React from 'react';
import BlankWrapper from './BlankWrapper.jsx';
import Spinner from './Spinner.jsx';

const LoadingPage = () => (
  <BlankWrapper>
    <Spinner />
  </BlankWrapper>
);

export default LoadingPage;
