import React from 'react';
import PropTypes from 'prop-types';
import { FiArrowDown, FiArrowRight, FiExternalLink } from 'react-icons/fi';
import Button from '../Components/Button.jsx';

const toAwsParameter = (arn) => String(arn).replaceAll('/', '$');

const AwsLink = ({ label, link, arn }) =>
  arn && (
    <a href={link} target="_blank" rel="noreferrer">
      <FiExternalLink className="inline" /> {label}
    </a>
  );

AwsLink.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  arn: PropTypes.string,
};

AwsLink.defaultProps = {
  arn: null,
};

const ForecastRun = ({ forecastRun }) => {
  const [metricsExpanded, setMetricsExpanded] = React.useState(false);

  return (
    <table className="min-w-full table-fixed">
      <tbody>
        <tr>
          <td className="w-32 pb-2 align-top">Status</td>
          <td className="pb-2 align-top font-bold">{forecastRun.status}</td>
        </tr>
        <tr>
          <td className="w-32 pb-2 align-top">Forecast Type</td>
          <td className="pb-2 align-top">{forecastRun.forecastType}</td>
        </tr>
        <tr>
          <td className="w-32 pb-2 align-top">Starting Date</td>
          <td className="pb-2 align-top">
            {forecastRun.startedAt && new Date(forecastRun.startedAt).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td className="w-32 pb-2 align-top">Completed Date</td>
          <td className="pb-2 align-top">
            {forecastRun.startedAt && new Date(forecastRun.startedAt).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td className="w-32 pb-2 align-top">Error</td>
          <td className="pb-2 align-top">{forecastRun.errorMessage}</td>
        </tr>
        {forecastRun.forecastType === 'aws' && (
          <>
            <tr>
              <td className="w-32 pb-2 align-top">AWS Forecast</td>
              <td className="pb-2 align-top">
                <AwsLink
                  label="Dataset group"
                  link={`https://console.aws.amazon.com/forecast/home?region=us-east-1#datasetGroups/${toAwsParameter(
                    forecastRun.datasetGroupArn,
                  )}/dashboard`}
                  arn={forecastRun.datasetGroupArn}
                />
              </td>
            </tr>
            <tr>
              <td className="w-32 pb-2 align-top">WAPE</td>
              <td className="pb-2 align-top">
                {forecastRun.accuracyMetrics?.length > 0 &&
                  forecastRun.accuracyMetrics[0]?.TestWindows[0]?.Metrics?.ErrorMetrics[0]?.WAPE}
              </td>
            </tr>
            <tr>
              <td className="w-32 pb-2 align-top">Accuracy Metrics</td>
              <td className="pb-2 align-top">
                {forecastRun.accuracyMetrics && (
                  <>
                    <Button
                      label={metricsExpanded ? 'Close' : 'Show'}
                      slim
                      variant="tertiary"
                      icon={metricsExpanded ? FiArrowDown : FiArrowRight}
                      onClick={() => setMetricsExpanded(!metricsExpanded)}
                    />

                    {metricsExpanded && (
                      <pre className="mt-2 max-h-36 overflow-y-auto text-xs">
                        {JSON.stringify(forecastRun.accuracyMetrics, null, 2)}
                      </pre>
                    )}
                  </>
                )}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

ForecastRun.propTypes = {
  forecastRun: PropTypes.shape({
    status: PropTypes.string.isRequired,
    startedAt: PropTypes.string,
    errorMessage: PropTypes.string,
    datasetGroupArn: PropTypes.string,
    datasetArn: PropTypes.string,
    datasetMetadataArn: PropTypes.string,
    datasetImportJobArn: PropTypes.string,
    metadataImportJobArn: PropTypes.string,
    predictorArn: PropTypes.string,
    forecastArn: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    accuracyMetrics: PropTypes.any,
    forecastType: PropTypes.string,
  }).isRequired,
};

export default ForecastRun;
