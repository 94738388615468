/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Combobox, Transition } from '@headlessui/react';
import classnames from 'classnames';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import pluralize from 'pluralize';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import { useDebounce } from '../Hooks/index.js';
import { SKU_SEARCH_QUERY } from './Form/AdditionalSkus.jsx';
import { calculateTargetMissingQuantity } from './helpers.js';

const SkuSelector = ({
  items,
  vendor,
  onItemsAdd,
  locations = [],
  onlyFromLocations,
  inputClassNames = '',
}) => {
  const {
    locations: { edges: accountLocations },
  } = useAccount();
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQueryImmediately] = useDebounce(query, 300);

  const { x, y, strategy, reference, floating } = useFloating({
    placement: 'bottom-start',
    middleware: [flip(), shift({ padding: 5 }), offset(4)],
    whileElementsMounted: autoUpdate,
  });

  const [getSkus, { loading, data }] = useLazyQuery(SKU_SEARCH_QUERY);

  useEffect(() => {
    if (debouncedQuery.length > 2) {
      getSkus({
        variables: {
          sku: debouncedQuery,
          ...(vendor ? { vendor: vendor._id } : {}),
          excludeBundles: true,
          category: null,
          exclude: items.map(({ sku }) => sku),
          ...(onlyFromLocations ? { locations } : {}),
        },
      });
    }
  }, [debouncedQuery]);

  const allSelected =
    !loading &&
    data?.skus &&
    data?.skus.edges.every(({ node }) => selected.some(({ sku }) => sku === node.sku));

  return (
    <Combobox value={selected} onChange={setSelected} multiple by="sku">
      <Combobox.Input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        autoComplete="off"
        className={classnames(
          'block w-full rounded border-midnight-10 px-6 py-2 text-sm focus:border-midnight-10 focus:ring-1 focus:ring-purple-100',
          inputClassNames,
        )}
        placeholder="Search for a product"
        ref={reference}
      />

      <Transition
        show={debouncedQuery.length > 2}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Combobox.Options
          className="z-20 w-[520px] overflow-hidden rounded-lg border border-midnight-10 bg-white shadow-lg"
          ref={floating}
          style={{ top: y, left: x, position: strategy }}
        >
          {loading && <p className="px-6 py-12">Loading products...</p>}

          {!loading && data && data.skus.edges.length > 0 && (
            <>
              <div className="max-h-72 w-full overflow-y-auto overflow-x-hidden">
                {data.skus.edges.length > 1 && (
                  <li
                    onClick={() =>
                      setSelected(allSelected ? [] : data.skus.edges.map(({ node }) => node))
                    }
                    className="flex cursor-default select-none items-center px-6 py-4 text-midnight-100 hover:bg-purple-10 focus:bg-purple-10 active:bg-purple-10"
                    role="option"
                    aria-selected={allSelected}
                    tabIndex={-1}
                  >
                    <input
                      type="checkbox"
                      checked={allSelected}
                      readOnly
                      className="mb-0.5 rounded border-gray-75 text-purple-100"
                    />
                    <span className="ml-3">{allSelected ? 'Unselect' : 'Select'} All</span>
                  </li>
                )}
                {data.skus.edges.map(({ node }) => (
                  <Combobox.Option
                    key={node.sku}
                    value={node}
                    className={({ active }) =>
                      classnames(
                        'flex cursor-default select-none items-start px-6 py-2 text-midnight-100 focus:bg-purple-10 active:bg-purple-10',
                        active && 'bg-purple-10',
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <input
                          type="checkbox"
                          checked={selected}
                          readOnly
                          className="rounded border-gray-75 text-purple-100"
                        />
                        <article className="ml-3 w-full text-left">
                          <h1 className="font-bold uppercase">{node.sku}</h1>
                          <p className="space-x-2 truncate pr-6">
                            <span className="font-medium">{node.productName}</span>
                            <span className="font-normal text-gray-100">{node.variantName}</span>
                          </p>
                        </article>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </div>
              <div className="flex items-center justify-end space-x-3 bg-midnight-5 px-3 py-4">
                {selected.length > 0 && (
                  <span className="ml-3 mr-auto">
                    {pluralize('products', selected.length, true)} selected
                  </span>
                )}
                <Button
                  onClick={() => {
                    setSelected([]);
                    setDebouncedQueryImmediately('');
                    setQuery('');
                  }}
                  variant="tertiary"
                  slim
                  label="Cancel"
                />
                <Button
                  variant="primary"
                  disabled={selected.length === 0}
                  onClick={() => {
                    onItemsAdd(
                      selected.map((sku) => [
                        sku.sku,
                        calculateTargetMissingQuantity(sku, accountLocations, locations),
                        { highlight: true },
                      ]),
                    );
                    setSelected([]);
                    setDebouncedQueryImmediately('');
                    setQuery('');
                  }}
                  slim
                  label="Add"
                />
              </div>
            </>
          )}
          {!loading && data && query.length > 2 && data.skus.edges.length === 0 && (
            <>
              <p className="px-6 py-12">We couldn&apos;t find any products...</p>
              <div className="flex items-center justify-end space-x-3 bg-midnight-5 px-3 py-4">
                <Button
                  onClick={() => {
                    setSelected([]);
                    setDebouncedQueryImmediately('');
                    setQuery('');
                  }}
                  variant="tertiary"
                  slim
                  label="Cancel"
                />
              </div>
            </>
          )}
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
};

SkuSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  vendor: PropTypes.shape({ _id: PropTypes.string }),
  onItemsAdd: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onlyFromLocations: PropTypes.bool,
  inputClassNames: PropTypes.string,
};

SkuSelector.defaultProps = {
  vendor: null,
  onlyFromLocations: false,
  inputClassNames: '',
};

export default SkuSelector;
