import React from 'react';
import classnames from 'classnames';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import cogsyLogo from '../../public/cogsy-logo-on-white.svg';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ mode: 'all' });

  const [loading, setLoading] = React.useState(false);
  const [requestComplete, setRequestComplete] = React.useState(false);

  const requestPasswordReset = async (formData) => {
    setLoading(true);

    try {
      await axios('/auth/forgot_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(formData),
      });
      setLoading(false);
      setRequestComplete(true);
    } catch (e) {
      setLoading(false);
      setError('email', {
        type: 'manual',
        message: 'Something went wrong!',
      });
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <main className="mx-auto mt-20 max-w-md px-6 pb-12 text-midnight-100 md:px-0">
      <div>
        <img src={cogsyLogo} alt="Cogsy Logo" className="mx-auto" />
      </div>

      {requestComplete && (
        <>
          <h1 className="mt-20 text-3xl">Check your email</h1>
          <div className="mt-4 text-xs">
            If the submitted email is associated to any Cogsy account, You will receive an email to
            reset your password shortly. Please check your email to continue.
          </div>
        </>
      )}
      {!requestComplete && (
        <>
          <h1 className="mt-20 text-3xl">Forgot your password?</h1>
          <div className="mt-4 text-xs">
            Enter your email and we&rsquo;ll send you a link to reset your password.
          </div>
          <form className="mt-8" noValidate onSubmit={handleSubmit(requestPasswordReset)}>
            <div>
              <label htmlFor="email" className="text-xs">
                Email*
                <input
                  type="email"
                  id="email"
                  className={classnames('input', errors.email && 'input-error')}
                  placeholder="john@acme.com"
                  {...register('email', {
                    required: 'Please provide a valid email',
                    pattern: {
                      value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                      message: 'Please provide a valid email',
                    },
                  })}
                />
              </label>
              <InputErrorMessage message={errors.email?.message} />
            </div>
            <div className="mt-8 space-y-4">
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                label={loading ? 'Please hold...' : 'Reset password'}
              />
              <Button href="/login" fullWidth variant="tertiary" label="Log in" />
            </div>
          </form>
        </>
      )}
    </main>
  );
};

export default ForgotPassword;
