import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FiChevronDown, FiPlus } from 'react-icons/fi';
import { Dialog, Listbox } from '@headlessui/react';
import classnames from 'classnames';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import { clearPurchaseOrdersCache, CREATE_DRAFT_PURCHASE_ORDER, VENDORS } from './Queries.js';

const CreateDraftPopupBody = ({ onClose, defaultLocation }) => {
  const { data, loading, error } = useQuery(VENDORS, {
    fetchPolicy: 'cache-and-network',
  });

  const [vendorId, setVendorId] = useState(null);
  const [locationId, setLocationId] = useState(defaultLocation);

  const navigate = useNavigate();

  const {
    locations: { edges: allLocations },
  } = useAccount();

  const [createPurchaseOrder, { loading: loadingCreate }] = useMutation(
    CREATE_DRAFT_PURCHASE_ORDER,
    {
      update: clearPurchaseOrdersCache,
      onCompleted: (data) => navigate(`/purchase-orders/${data.createPurchaseOrder._id}`),
    },
  );

  const locations = allLocations.filter(({ node: location }) => !location.hideReplenishment);

  const vendorsNeedReplenishment = data?.skuVendors.filter(({ replenishNow }) => replenishNow);
  const otherVendors = data?.skuVendors.filter(({ replenishNow }) => !replenishNow);

  return (
    <div className="z-10 mx-auto w-full max-w-md rounded bg-white shadow">
      <Dialog.Title className="rounded-t bg-purple-10 p-6 text-xl font-medium text-purple-100">
        Generate Purchase Order
      </Dialog.Title>

      <div className="mt-6 px-6">
        {error && (
          <p className="text-xs">
            There has been an error:&nbsp;
            <pre className="my-2 block whitespace-pre-wrap rounded bg-gray-50 px-2 py-1">
              {error.message}
            </pre>
            &nbsp;Please try again later.
          </p>
        )}

        <Listbox value={locationId} onChange={setLocationId}>
          <div className="relative mt-1 text-xs">
            <Listbox.Label>
              <p className="font-bold">I want this PO delivered to</p>
            </Listbox.Label>
            <Listbox.Button className="relative mt-2 h-8 w-full cursor-default rounded-lg border border-midnight-10 bg-white px-4 py-2 pr-10 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-2">
              <div className="truncate">
                {locationId
                  ? locations.find(({ node: { _id } }) => locationId === _id).node.name
                  : 'Select a location'}
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FiChevronDown className="text-midnight-100" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-2 ring-purple-100 ring-opacity-5 focus:outline-none">
              {locations.map(({ node: location }) => (
                <Listbox.Option
                  key={location._id}
                  value={location._id}
                  className={({ active }) =>
                    classnames(
                      'px-4 py-2',
                      active ? 'bg-purple-100 text-white' : 'bg-white text-midnight-100',
                    )
                  }
                >
                  <div className="truncate">{location.name}</div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
            <p className="mt-2 italic opacity-60">You can add shipments for more locations later</p>
          </div>
        </Listbox>

        <Listbox value={vendorId} onChange={setVendorId}>
          <div className="relative mt-6 text-xs">
            <Listbox.Label>
              <p className="font-bold">This PO is for the vendor</p>
            </Listbox.Label>
            <Listbox.Button className="relative mt-1 h-8 w-full cursor-default rounded-lg border border-midnight-10 bg-white px-4 py-2 pr-10 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-2">
              <div className="truncate">
                {loading
                  ? 'Loading vendors...'
                  : (vendorId &&
                      data.skuVendors.find(({ vendorId: _v }) => vendorId === _v).vendorName) ||
                    'Select vendor'}
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FiChevronDown className="text-midnight-100" aria-hidden="true" />
              </span>
            </Listbox.Button>
            {data && (
              <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-2 ring-purple-100 ring-opacity-5 focus:outline-none">
                {vendorsNeedReplenishment.length > 0 && (
                  <Listbox.Option
                    key="vendorsNeedReplenishment"
                    value={null}
                    disabled
                    className="bg-gray-50 px-4 py-2 font-bold"
                  >
                    Vendors that need replenishment
                  </Listbox.Option>
                )}
                {vendorsNeedReplenishment.map((vendor) => (
                  <Listbox.Option
                    key={vendor.vendorId}
                    value={vendor.vendorId}
                    className={({ active }) =>
                      classnames(
                        'px-4 py-2 first-of-type:pt-2.5 last-of-type:pb-2.5',
                        active ? 'bg-purple-100 text-white' : 'bg-white text-midnight-100',
                      )
                    }
                  >
                    <div className="truncate">{vendor.vendorName}</div>
                  </Listbox.Option>
                ))}

                {/* If there are no vendors that need replenishment, there's no point to show the section title "Other vendors" */}
                {otherVendors.length > 0 && vendorsNeedReplenishment.length > 0 && (
                  <Listbox.Option
                    key="otherVendors"
                    value={null}
                    disabled
                    className="bg-gray-50 px-4 py-2 font-bold"
                  >
                    Other vendors
                  </Listbox.Option>
                )}
                {otherVendors.map((vendor) => (
                  <Listbox.Option
                    key={vendor.vendorId}
                    value={vendor.vendorId}
                    className={({ active }) =>
                      classnames(
                        'px-4 py-2 first-of-type:pt-2.5 last-of-type:pb-2.5',
                        active ? 'bg-purple-100 text-white' : 'bg-white text-midnight-100',
                      )
                    }
                  >
                    <div className="truncate">{vendor.vendorName}</div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            )}
          </div>
        </Listbox>
      </div>
      <div className="mt-12 flex justify-end space-x-2 px-6 pb-8">
        <Button slim label="Cancel" variant="text" onClick={onClose} />
        <Button
          slim
          label="Create Purchase Order"
          disabled={!vendorId || !locationId || loadingCreate}
          onClick={() => createPurchaseOrder({ variables: { locationId, vendorId } })}
        />
      </div>
    </div>
  );
};

CreateDraftPopupBody.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultLocation: PropTypes.string.isRequired,
};

const CreateDraftPopup = ({ open, onClose, location }) =>
  open && (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <CreateDraftPopupBody onClose={onClose} defaultLocation={location} />
      </div>
    </Dialog>
  );

CreateDraftPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
};

const CreateDraftPopupButton = ({ location }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const togglePopupOpen = useCallback(() => {
    setPopupOpen(!popupOpen);
  }, [popupOpen]);

  return (
    <>
      <Button slim icon={FiPlus} label="Generate Purchase Order" onClick={togglePopupOpen} />

      <CreateDraftPopup open={popupOpen} onClose={togglePopupOpen} location={location} />
    </>
  );
};

CreateDraftPopupButton.propTypes = {
  location: PropTypes.string,
};

CreateDraftPopupButton.defaultProps = {
  location: '',
};

export default CreateDraftPopupButton;
