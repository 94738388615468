import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineTable } from 'react-icons/ai';
import classnames from 'classnames';
import Spinner from './Spinner.jsx';

const TableContext = createContext();

const useTableContext = () => {
  const context = React.useContext(TableContext);
  if (!context) {
    throw new Error('Table compound components cannot be rendered outside the Table component');
  }
  return context;
};

const Table = ({ columns, rows, isLoading, noDataText, noDataCtaText, children }) => {
  const value = React.useMemo(
    () => ({
      columns,
      rows,
      isLoading,
      noDataText,
      noDataCtaText,
    }),
    [rows, isLoading, noDataText, noDataCtaText],
  );

  return (
    <div className="w-full rounded-lg bg-white text-midnight-100 shadow">
      <TableContext.Provider value={value}>{children}</TableContext.Provider>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      field: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      widthClass: PropTypes.string,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        className: PropTypes.string,
        onClick: PropTypes.func,
      }),
      PropTypes.objectOf(PropTypes.node),
    ]),
  ),
  isLoading: PropTypes.bool,
  noDataText: PropTypes.string,
  noDataCtaText: PropTypes.string,
  children: PropTypes.node,
};

Table.defaultProps = {
  columns: null,
  rows: null,
  isLoading: false,
  noDataText: null,
  noDataCtaText: null,
  children: null,
};

const Header = ({ children }) => (
  <div className="border-b border-gray-50 p-5 pb-4 text-sm font-bold">{children}</div>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

const LinkOrDiv = ({ link, state, children, ...restProps }) =>
  link ? (
    <Link to={link} state={state} {...restProps}>
      {children}
    </Link>
  ) : (
    <div {...restProps}>{children}</div>
  );

LinkOrDiv.propTypes = {
  link: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.shape({ fromPath: PropTypes.object }),
  children: PropTypes.node.isRequired,
};

LinkOrDiv.defaultProps = {
  link: null,
  state: null,
};

const Body = ({ children }) => {
  const { columns, rows, isLoading, noDataText, noDataCtaText } = useTableContext();
  const pathname = useLocation();
  const alignMap = { left: 'justify-start', center: 'justify-center', right: 'justify-end' };

  const tableRender = (
    <table className="-mx-5 -my-2 w-[calc(100%+2.5rem)] table-fixed border-spacing-0">
      <thead>
        <tr className="text-left align-top">
          {columns.map(({ field, content, widthClass, align }) => (
            <th key={field} className={classnames('px-5 py-2', widthClass)}>
              <div className={classnames('flex', alignMap[align])}>{content}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows &&
          rows.map((row) => (
            <tr key={row.id} className={row.className} onClick={row.onClick}>
              {columns.map(({ field, align }) => (
                <td key={field} className="p-0">
                  <LinkOrDiv
                    link={row.link}
                    state={{ fromPath: pathname }}
                    className="block px-5 py-2"
                  >
                    <div className={classnames('flex', alignMap[align])}>{row[field]}</div>
                  </LinkOrDiv>
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );

  const tableNoDataRender = (
    <div className="flex h-48 flex-col items-center space-y-6 text-center text-sm text-gray-100">
      {isLoading ? null : (
        <>
          <div className="mt-7 flex h-16 w-16 items-center justify-center rounded-full bg-gray-50">
            <AiOutlineTable className="text-gray-75" size={32} />
          </div>
          <div>
            <p>{noDataText || 'No products to show at this time'}</p>
            {noDataCtaText && <p className="mt-2">{noDataCtaText}</p>}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="relative p-5 text-xs">
      {isLoading && (
        <>
          <div className="absolute left-0 top-0 z-10 h-full w-full bg-white opacity-70" />
          <div className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2">
            <Spinner className="opacity-30" color="gray" />
          </div>
        </>
      )}

      {children}

      {!children && columns && (rows ? tableRender : tableNoDataRender)}
    </div>
  );
};

Body.propTypes = {
  children: PropTypes.node,
};

Body.defaultProps = {
  children: null,
};

Table.Header = Header;
Table.Body = Body;

export default Table;
