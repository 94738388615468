import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { BiArchiveOut } from 'react-icons/bi';
import { FiCheck, FiMinus } from 'react-icons/fi';
import { Dialog, RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import LoadingIcon from '../Components/LoadingIcon.jsx';
import RadioButton from '../Components/RadioButton.jsx';
import SplitButton from '../Components/SplitButton.jsx';
import Table from '../Components/Table.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import { formatSourceName } from '../Skus/utils/formatSourceNames.js';

const MERGE_LOCATIONS = gql`
  mutation MergeLocations($primaryLocationId: String!, $secondaryLocationIds: [String]!) {
    mergeLocations(
      primaryLocationId: $primaryLocationId
      secondaryLocationIds: $secondaryLocationIds
    ) {
      _id
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation UpdateLocation($locationId: String!, $hideReplenishment: Boolean) {
    updateLocation(locationId: $locationId, input: { hideReplenishment: $hideReplenishment }) {
      _id
    }
  }
`;

const buttonOptions = [
  {
    key: 'merge',
    label: 'Merge',
    menuTitle: 'Merge',
    menuDescription:
      'Merge selected locations. You will choose a primary location in the next step',
  },
  {
    key: 'disable',
    label: 'Disable',
    loadingLabel: 'Disabling…',
    menuTitle: 'Disable',
    menuDescription:
      'Disable selected location(s). Disabled locations will be hidden from replenishment recommendations',
  },
];

const Locations = () => {
  useDocumentTitle('Locations Settings');

  const { addAlert } = useAlerts();

  const {
    locations: { edges: allLocations },
    account,
  } = useAccount();
  const locations = allLocations.filter(({ node: location }) => !location.hideReplenishment);
  const disabledLocations = allLocations.filter(({ node: location }) => location.hideReplenishment);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [rows, setRows] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [primaryLocationId, setPrimaryLocationId] = useState('');

  const [mergeLocations, { loading: mergeLocationsLoading }] = useMutation(MERGE_LOCATIONS);
  const [updateLocation, { loading: updateLocationLoading }] = useMutation(UPDATE_LOCATION);

  useEffect(() => {
    setPrimaryLocationId(selectedLocations[0]?._id);
  }, [selectedLocations]);

  const toggleIsSelected = (location) => {
    if (selectedLocations.some((selectedLocation) => selectedLocation._id === location._id)) {
      setSelectedLocations(
        selectedLocations.filter((selectedLocation) => selectedLocation._id !== location._id),
      );
    } else {
      setSelectedLocations([...selectedLocations, location]);
    }
  };

  const handleSubmit = async (mode) => {
    if (mode === 'disable') {
      if (selectedLocations.length < 1)
        return addAlert('Select at least 1 location to be disabled', { level: 'warning' });

      setIsSubmitting(true);

      await Promise.all(
        selectedLocations.map(({ _id: locationId }) =>
          updateLocation({
            variables: {
              locationId,
              hideReplenishment: true,
            },
            update: (cache) => {
              cache.evict({ _id: cache.identify({ _id: account._id, __typename: 'Account' }) });
              cache.gc();
            },
          }),
        ),
      );

      addAlert(`${pluralize('Location', selectedLocations.length)} disabled succesfully`, {
        level: 'success',
      });
      setSelectedLocations([]);

      setIsSubmitting(false);
    }

    if (mode === 'merge') {
      if (selectedLocations.length < 2)
        return addAlert('Select at least 2 locations to perform a merge', { level: 'warning' });

      setIsDialogOpen(true);
    }

    return null;
  };

  const handleMerge = async () => {
    const secondaryLocationIds = selectedLocations.reduce((ids, selectedLocation) => {
      if (selectedLocation._id !== primaryLocationId) return ids.concat(selectedLocation._id);

      return ids;
    }, []);

    await mergeLocations({
      variables: {
        primaryLocationId,
        secondaryLocationIds,
      },
      update: (cache) => {
        cache.evict({ _id: cache.identify({ _id: account._id, __typename: 'Account' }) });
        cache.gc();
      },
    });

    setIsDialogOpen(false);
    setSelectedLocations([]);
    setPrimaryLocationId('');
    addAlert(`Locations merged succesfully`, { level: 'success' });
  };

  const handleEnableLocation = async (locationId) => {
    await updateLocation({
      variables: {
        locationId,
        hideReplenishment: false,
      },
      update: (cache) => {
        cache.evict({ _id: cache.identify({ _id: account._id, __typename: 'Account' }) });
        cache.gc();
      },
    });

    addAlert('Location enabled', { level: 'success' });
  };

  const columns = [
    {
      field: 'checkbox',
      content: (
        <button
          type="button"
          className={classnames(
            'flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded border text-white outline-none ring-purple-100 ring-offset-2 focus-visible:ring-2',
            selectedLocations.length > 0 ? 'border-purple-100 bg-purple-100' : 'border-gray-75',
          )}
          onClick={() =>
            selectedLocations.length === 0
              ? setSelectedLocations(
                  locations?.edges.map(({ node: { _id, name } }) => ({ _id, name })),
                )
              : setSelectedLocations([])
          }
        >
          {selectedLocations.length === locations?.edges?.length ? (
            <FiCheck strokeWidth={4} size={11} />
          ) : (
            <FiMinus strokeWidth={4} size={11} />
          )}
        </button>
      ),
      widthClass: 'w-10',
    },
    { field: 'location', content: 'Location', widthClass: 'w-3/5' },
    { field: 'source', content: 'Integration Source', widthClass: 'w-2/5' },
  ];

  useEffect(() => {
    setRows(
      locations.map(({ node: { _id, name, sources } }) => {
        const isSelected = selectedLocations.some(
          (selectedLocation) => selectedLocation._id === _id,
        );
        const sourceId = sources[0]?.sourceId;

        return {
          id: _id,
          className: classnames('cursor-pointer hover:bg-gray-10', isSelected && 'bg-purple-10'),
          onClick: () => toggleIsSelected({ _id, name }),
          checkbox: (
            <input
              type="checkbox"
              readOnly
              checked={isSelected}
              className="cursor-pointer rounded border-gray-75 text-purple-100 focus:ring-0 focus:ring-offset-0 focus-visible:ring-2 focus-visible:ring-offset-2"
            />
          ),
          location: <div className="truncate">{name}</div>,
          source: (
            <div className="truncate capitalize">
              {formatSourceName({
                sourceType:
                  account?.sources.find((source) => source.sourceId === sourceId)?.sourceType ||
                  '-',
              })}
            </div>
          ),
        };
      }),
    );
  }, [selectedLocations, allLocations]);

  const disabledLocationsColumns = [
    {
      field: 'location',
      content: <span className="text-gray-100">Location</span>,
      widthClass: 'w-3/5',
    },
    {
      field: 'source',
      content: <span className="text-gray-100">Integration Source</span>,
      widthClass: 'w-2/5',
    },
    { field: 'enable', content: '', widthClass: 'w-20', align: 'center' },
  ];

  const disabledLocationsRows = disabledLocations.map(({ node: { _id, name, sources } }) => {
    const sourceId = sources[0]?.sourceId;

    return {
      id: _id,
      location: <div className="truncate text-gray-100">{name}</div>,
      source: (
        <div className="truncate capitalize text-gray-100">
          {account?.sources.find((source) => source.sourceId === sourceId)?.sourceType || '-'}
        </div>
      ),
      enable: (
        <div className="group text-purple-100">
          <BiArchiveOut className="h-4 w-4 group-hover:hidden" />
          <button
            type="button"
            className="hidden text-xs font-semibold group-hover:block"
            onClick={() => handleEnableLocation(_id)}
            disabled={updateLocationLoading}
          >
            Enable
          </button>
        </div>
      ),
    };
  });

  return (
    <>
      <div className="col-span-6 space-y-8 text-midnight-100">
        <h4 className="text-xl">Locations</h4>

        <div className="mt-6 flex flex-col items-end space-y-4">
          <Table
            columns={columns}
            rows={rows}
            noDataText="There are no locations to show"
            noDataCtaText="Connect an integration and sync your locations"
          >
            <Table.Header>Merge or disable locations</Table.Header>
            <Table.Body />
          </Table>

          <SplitButton
            options={buttonOptions}
            onClick={(option) => handleSubmit(option)}
            isLoading={isSubmitting}
          />
        </div>

        {disabledLocationsRows?.length > 0 ? (
          <Table columns={disabledLocationsColumns} rows={disabledLocationsRows}>
            <Table.Header>
              <span className="text-gray-100">Disabled Locations</span>
            </Table.Header>
            <Table.Body />
          </Table>
        ) : null}
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="z-50 text-xs text-midnight-100"
        style={{ fontFamily: 'Gilroy, sans-serif' }}
      >
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="mx-auto max-w-xl overflow-hidden rounded bg-white shadow">
              <div className="bg-purple-10 p-8 py-6">
                <Dialog.Title className="text-lg font-bold text-purple-100">
                  Merging Locations
                </Dialog.Title>

                <div className="mt-2 text-xs">
                  <p>Select the primary location below.</p>
                  <p>This will be the location into which the others will be merged.</p>
                  <p>
                    Once merged, the other locations will be deleted from Cogsy. Other associated
                    data, such as inventory and order data, will be mapped to the Primary location.
                  </p>
                </div>
              </div>

              <div className="p-8">
                <RadioGroup
                  className="max-h-[14.7rem] space-y-3 overflow-y-auto py-px pr-2"
                  onChange={setPrimaryLocationId}
                  value={primaryLocationId}
                >
                  {selectedLocations
                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                    .map(({ _id, name }) => (
                      <div key={_id} className="flex">
                        <div className="w-[4.25em] shrink-0 font-bold text-purple-100">
                          {_id === primaryLocationId ? 'Primary' : null}
                        </div>

                        <RadioGroup.Option value={_id} key={_id} className="flex cursor-pointer">
                          {({ checked }) => (
                            <>
                              <RadioButton isChecked={checked} />
                              <div className="vertical-fix ml-2">{name}</div>
                            </>
                          )}
                        </RadioGroup.Option>
                      </div>
                    ))}
                </RadioGroup>

                <div className="mt-8 flex justify-end space-x-2">
                  <Button
                    label="Cancel"
                    variant="text"
                    slim
                    onClick={() => setIsDialogOpen(false)}
                  />
                  <Button
                    label={
                      mergeLocationsLoading ? (
                        <>
                          <LoadingIcon className="-ml-1 mr-2" />
                          Merging…
                        </>
                      ) : (
                        'Merge Locations'
                      )
                    }
                    slim
                    onClick={handleMerge}
                    disabled={mergeLocationsLoading}
                  />
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Locations;
