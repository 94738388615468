import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { FiBox, FiCalendar } from 'react-icons/fi';
import { isSameDay } from 'date-fns';
import { useAccount } from '../../AccountProvider.jsx';
import { SkeletonBlock } from '../../Components/Skeletons.jsx';
import Tag from '../../Components/Tag.jsx';
import VideoPopup from '../../Components/VideoPopup.jsx';
import {
  marketingEvents as marketingEventsConfig,
  plannedProducts as plannedProductsConfig,
} from '../../config.js';
import Widget from './Widget.jsx';

const getFormattedDate = (startDate, endDate) => {
  const options = {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
  };

  const start = new Date(startDate);
  const end = new Date(endDate);

  let formattedDate = start.toLocaleDateString(navigator.language, options);

  if (endDate && !isSameDay(start, end))
    formattedDate += ` \u2013 ${end.toLocaleDateString(navigator.language, options)}`;

  return formattedDate;
};

const UpcomingEventsWidget = ({
  marketingEvents,
  plannedProducts,
  currentMonthPlanTotal,
  loading,
}) => {
  const { account } = useAccount();
  const [marketingEventsVideoPopupOpen, setMarketingEventsVideoPopupOpen] = useState(false);
  const [plannedProductsVideoPopupOpen, setPlannedProductsVideoPopupOpen] = useState(false);

  const toggleMarketingEventsVideoPopupOpen = () => {
    setMarketingEventsVideoPopupOpen(!marketingEventsVideoPopupOpen);
  };

  const togglePlannedProductsVideoPopupOpen = () => {
    setPlannedProductsVideoPopupOpen(!plannedProductsVideoPopupOpen);
  };

  return (
    <Widget barColor="gradient" className="h-auto">
      <Widget.Title>Upcoming Events</Widget.Title>
      {loading && <SkeletonBlock className="mt-6 h-[476px]" />}
      {!loading && (
        <>
          <Widget.Content className="relative">
            <div className="absolute top-0 z-10 h-2 w-full bg-gradient-to-b from-white to-transparent" />
            <div className="absolute bottom-0 z-10 h-4 w-full bg-gradient-to-b from-transparent to-white" />

            <div className="-mr-4 max-h-[348px] overflow-y-auto pb-4 pr-4">
              {marketingEvents.length === 0 && (
                <>
                  <Tag>Marketing Events</Tag>
                  <div className="mt-6">
                    You don’t have any upcoming marketing events in the next 30 days. Learn how you
                    can use Cogsy’s marketing events to avoid stockouts on the big days! 👇
                  </div>
                  <button
                    type="button"
                    className="mt-5 flex w-full justify-center rounded-md bg-leafy-85 py-8 focus:outline-none"
                    onClick={toggleMarketingEventsVideoPopupOpen}
                  >
                    <BsFillPlayCircleFill size={64} className="text-white" />
                  </button>
                  <VideoPopup
                    videoUrl={marketingEventsConfig.videoUrl}
                    open={marketingEventsVideoPopupOpen}
                    onClose={toggleMarketingEventsVideoPopupOpen}
                  />
                </>
              )}

              {marketingEvents.length > 0 && (
                <>
                  <div className="space-y-4">
                    {marketingEvents.map(({ node: event }) => (
                      <div key={event._id} className="flex">
                        <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-leafy-50">
                          <FiCalendar strokeWidth={2} size={12} className="mb-px text-leafy-100" />
                        </div>
                        <div className="ml-3 leading-5">
                          <Tag className="-ml-1 px-1">Marketing Event</Tag>
                          <div className="text-midnight-75">
                            {getFormattedDate(event.startsAt, event.endsAt)}
                          </div>
                          <div className="font-bold">{event.title}</div>
                          <div className="font-bold text-gray-100">
                            {(event.percentageLift / 100).toFixed(1)}x Lift
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Widget.Link to="/planning/marketing-events" className="ml-8 mt-2">
                    View All Events
                  </Widget.Link>
                </>
              )}

              {plannedProducts.length === 0 && (
                <div className="mt-7">
                  <Tag color="purple">Planned Products</Tag>
                  <div className="mt-6">
                    You don’t have any planned products. We can use the data we already have in
                    Cogsy to give you estimates of how much of a new product you will need to order.
                  </div>
                  <button
                    type="button"
                    className="mt-5 flex w-full justify-center rounded-md bg-purple-100 py-8 focus:outline-none"
                    onClick={togglePlannedProductsVideoPopupOpen}
                  >
                    <BsFillPlayCircleFill size={64} className="text-white" />
                  </button>
                  <VideoPopup
                    videoUrl={plannedProductsConfig.videoUrl}
                    open={plannedProductsVideoPopupOpen}
                    onClose={togglePlannedProductsVideoPopupOpen}
                  />
                </div>
              )}

              {plannedProducts.length > 0 && (
                <>
                  <div className="mt-7 space-y-4">
                    {plannedProducts.map(({ node: product }) => (
                      <div key={product._id} className="flex">
                        <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-purple-10">
                          <FiBox strokeWidth={2} size={12} className="mb-px text-purple-100" />
                        </div>
                        <div className="ml-3 leading-5">
                          <Tag color="purple" className="-ml-1 px-1">
                            Planned Product
                          </Tag>
                          <div className="text-midnight-75">
                            {getFormattedDate(product.launchDate)}
                          </div>
                          <div className="font-bold">{product.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Widget.Link to="/planning/planned-products" className="ml-8 mt-2">
                    View All Planned Products
                  </Widget.Link>
                </>
              )}
            </div>
          </Widget.Content>

          <div className="mt-auto text-sm">
            <span className="text-xl">👉</span>
            <span className="leading-6">{` Your target revenue this month is ${currentMonthPlanTotal?.toLocaleString(
              navigator.language,
              {
                style: 'currency',
                currency: account.preferences.currency,
                notation: 'compact',
                maximumFractionDigits: 2,
              },
            )}, are there any${marketingEvents.length > 0 ? ' other' : ''}
      marketing events which might affect that?`}</span>
            <div className="mt-5 flex">
              <Widget.Link to="/planning/marketing-events/new">Add Event</Widget.Link>
              <Widget.Link to="/planning/planned-products/new" className="ml-4">
                Add Planned Product
              </Widget.Link>
            </div>
          </div>
        </>
      )}
    </Widget>
  );
};

UpcomingEventsWidget.propTypes = {
  marketingEvents: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        startsAt: PropTypes.string.isRequired,
        endsAt: PropTypes.string.isRequired,
        percentageLift: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  plannedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        launchDate: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  currentMonthPlanTotal: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UpcomingEventsWidget;
