const isPlainObject = (value) => typeof value === 'object'
  && value !== null
  && value.constructor === Object
  && Object.prototype.toString.call(value) === '[object Object]';

const omitDeep = (value, keys) => {
  if (Array.isArray(value)) return value.map((entry) => omitDeep(entry, keys));

  if (!isPlainObject(value)) return value;

  return Object.keys(value)
    .filter((key) => !keys.includes(key))
    .map((key) => ({ [key]: omitDeep(value[key], keys) }))
    .reduce((acc, entry) => ({ ...acc, ...entry }), { });
};

export default omitDeep;
