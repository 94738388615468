import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useProductFruitsApi } from 'react-product-fruits';
import { useAccount } from '../AccountProvider.jsx';

const OnboardingChecklists = ({ children = () => {} }) => {
  const { showHints } = useAccount();

  const firstChecklistContainer = useRef();
  const secondChecklistContainer = useRef();
  const thirdChecklistContainer = useRef();

  const [productFruitsHaveLoaded, setProductFruitsHaveLoaded] = useState(false);
  const [showFirstChecklist, setShowFirstChecklist] = useState(false);
  const [showSecondChecklist, setShowSecondChecklist] = useState(false);
  const [showThirdChecklist, setShowThirdChecklist] = useState(false);

  const [firstProductFruitsRender, setFirstProductFruitsRender] = useState(true);

  useProductFruitsApi(
    async (api) => {
      api.hints.toggleVisibility(showHints);
      // setTimeout is used to deal with the Product Fruits bug. Their CSS loads a split second later than the markup and for a moment SVGs don't have the right size making them huge.
      setTimeout(() => {
        setProductFruitsHaveLoaded(true);
      }, 500);

      if (firstProductFruitsRender) {
        const [isFirstEligible, isSecondEligible, isThirdEligible] = await Promise.all(
          // map checklist IDs to async eligibility calls
          [643, 663, 710].map((id) => {
            if (api.checklists.getChecklists().some((checklist) => checklist.id === id)) {
              return api.checklists.isChecklistEligible(id);
            }
            return 'not_eligible';
          }),
        );

        setShowFirstChecklist(isFirstEligible === 'eligible');
        setShowSecondChecklist(isSecondEligible === 'eligible');
        setShowThirdChecklist(isThirdEligible === 'eligible');

        setFirstProductFruitsRender(false);
      }

      if (productFruitsHaveLoaded && firstChecklistContainer && showFirstChecklist) {
        api.checklists.injectToElement(643, firstChecklistContainer.current);
      }

      if (productFruitsHaveLoaded && secondChecklistContainer && showSecondChecklist) {
        api.checklists.injectToElement(663, secondChecklistContainer.current);
      }

      if (productFruitsHaveLoaded && thirdChecklistContainer && showThirdChecklist) {
        api.checklists.injectToElement(710, thirdChecklistContainer.current);
      }

      api.checklists.listen('dismissed', (id, reason) => {
        if (id === 643 && reason === 'completed') {
          setShowFirstChecklist(false);
        }

        if (id === 663) {
          setShowSecondChecklist(false);
        }

        if (id === 710) {
          setShowThirdChecklist(false);
        }
      });
    },
    [
      productFruitsHaveLoaded,
      showFirstChecklist,
      showSecondChecklist,
      showThirdChecklist,
      firstProductFruitsRender,
      showHints,
    ],
  );

  return children({
    showFirstChecklist,
    showSecondChecklist,
    showThirdChecklist,
    firstChecklistContainer,
    secondChecklistContainer,
    thirdChecklistContainer,
    productFruitsHaveLoaded,
  });
};

OnboardingChecklists.propTypes = {
  children: PropTypes.func.isRequired,
};

export default OnboardingChecklists;
