import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useAccount } from '../../AccountProvider.jsx';
import { SkeletonBlock } from '../../Components/Skeletons.jsx';
import Tag from '../../Components/Tag.jsx';
import Widget from './Widget.jsx';

const ReplenishNowWidget = ({ stats, loading }) => {
  const { account } = useAccount();
  const { count, workingCapital } = stats;

  const currencyOptions = {
    style: 'currency',
    currency: account.preferences?.currency || 'USD',
    notation: 'compact',
    maximumFractionDigits: 2,
  };

  return (
    <Widget barColor="red">
      <Tag color="red" hasDot>
        Replenish now
      </Tag>
      {loading && <SkeletonBlock className="mt-6 h-[91px]" />}
      {!loading && (
        <>
          <Widget.Content>
            {count > 0 ? (
              <>
                <p>
                  <span className="text-2xl font-bold leading-6">{count}</span>&nbsp;
                  {pluralize('product', count)}
                </p>
                <p>
                  Estimated working capital needed:&nbsp;
                  <span className="text-base font-bold leading-6">
                    {workingCapital.toLocaleString(navigator.language, currencyOptions)}
                  </span>
                </p>
              </>
            ) : (
              <p>
                No products need replenishing now, so there&apos;s&nbsp;
                {(0).toLocaleString(navigator.language, currencyOptions)} revenue loss from
                stockouts.
              </p>
            )}
          </Widget.Content>
          {count > 0 && (
            <Widget.Link to="/skus/replenishment?category=replenishNow">View Products</Widget.Link>
          )}
        </>
      )}
    </Widget>
  );
};

ReplenishNowWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  stats: PropTypes.shape({
    count: PropTypes.number,
    workingCapital: PropTypes.number,
  }).isRequired,
};

export default ReplenishNowWidget;
