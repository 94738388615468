import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { FiRefreshCw } from 'react-icons/fi';
import { useAccount } from '../../AccountProvider.jsx';
import Button from '../../Components/Button.jsx';

const ENQUEUE_REFRESH_VIEW_MUTATION = gql`
  mutation enqueueRefreshViews {
    enqueueRefreshViews
  }
`;

const RESET_AND_RESYNC_ALL_SKUS = gql`
  mutation resetAndReSyncAllSkus {
    resetAndReSyncAllSkus
  }
`;

const StatsInfo = () => {
  const { account } = useAccount();

  const {
    refreshViews,
    refreshViewsStartedAt,
    refreshViewsEndedAt,
    refreshViewsLastError,
    updatedData,
  } = account.statsInfo;

  let statsStatus = 'done';

  if (refreshViewsStartedAt) statsStatus = 'started';
  if (refreshViewsEndedAt) statsStatus = 'done';
  if (refreshViewsLastError) statsStatus = 'error';
  if (refreshViews) statsStatus = 'queued';
  if (account.isSyncInProgress) statsStatus = 'sync-in-progress';

  const [enqueueRefreshViews] = useMutation(ENQUEUE_REFRESH_VIEW_MUTATION);
  const [resetAndReSyncAllSkus] = useMutation(RESET_AND_RESYNC_ALL_SKUS);

  return (
    <div className="m-3 mt-6 rounded-lg bg-white px-6 pb-7 pt-5 text-midnight-100 shadow">
      <h5 className="mb-3 border-b border-gray-50 pb-4 text-sm font-bold">Stats panel</h5>

      <div className="text-sm">
        <p className="mb-1">
          <strong>Status:</strong> {statsStatus}
        </p>

        {refreshViewsStartedAt && (
          <p className="mb-1">
            <strong>Started at:</strong> {new Date(refreshViewsStartedAt).toLocaleString()}
          </p>
        )}
        {refreshViewsEndedAt && (
          <p className="mb-1">
            <strong>Ended at:</strong> {new Date(refreshViewsEndedAt).toLocaleString()}
          </p>
        )}
        {refreshViewsLastError && (
          <p className="mb-1">
            <strong>Last error:</strong> {refreshViewsLastError}
          </p>
        )}
        {updatedData && (
          <p className="mb-1">
            <strong>Updated data:</strong> {updatedData.join(', ')}
          </p>
        )}

        <Button
          label="Enqueue refresh stats"
          slim
          icon={FiRefreshCw}
          disabled={['started', 'queued', 'sync-in-progress'].includes(statsStatus)}
          onClick={enqueueRefreshViews}
        />

        <br />
        <br />

        <Button
          label="Reset and re-sync all products"
          slim
          icon={FiRefreshCw}
          onClick={resetAndReSyncAllSkus}
        />
      </div>
    </div>
  );
};

export default StatsInfo;
