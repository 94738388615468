import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FiChevronLeft, FiEdit2, FiPlus, FiUser } from 'react-icons/fi';
import { Dialog } from '@headlessui/react';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { useForm } from 'react-hook-form';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import confirm from '../Components/ConfirmDialog.jsx';
import DeleteButton from '../Components/DeleteButton.jsx';
import { useDemo } from '../Components/DemoProvider.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import POTable from '../PurchaseOrders/POTable.jsx';
import { formatSourceName } from '../Skus/utils/formatSourceNames.js';
import VendorForm from './Form.jsx';
import { FRAGMENT_VENDOR } from './Index.jsx';

const GET_VENDOR = gql`
  ${FRAGMENT_VENDOR}

  query getVendor($_id: String!) {
    vendor(_id: $_id) {
      ...VendorFields
      address
      phoneNumber
      contactEmail
      contactName
      sources {
        sourceId
        isDefault
      }
      contacts {
        _id
        name
        email
        phoneNumber
      }
    }
  }
`;

const UPDATE_VENDOR = gql`
  ${FRAGMENT_VENDOR}

  mutation UpdateVendor($vendor: VendorInputType!) {
    updateVendor(vendor: $vendor) {
      ...VendorFields
      contactName
      contactEmail
      phoneNumber
      address
    }
  }
`;
const ADD_CONTACT = gql`
  mutation AddVendorContact($_id: ID!, $contact: VendorContactInput!) {
    addVendorContact(_id: $_id, contact: $contact) {
      _id
    }
  }
`;

const UPDATE_CONTACT = gql`
  mutation UpdateVendorContact($_id: ID!, $contactId: String!, $contact: VendorContactInput!) {
    updateVendorContact(_id: $_id, contactId: $contactId, contact: $contact) {
      _id
    }
  }
`;

const REMOVE_CONTACT = gql`
  mutation RemoveVendorContact($_id: ID!, $contactId: String!) {
    removeVendorContact(_id: $_id, contactId: $contactId) {
      _id
    }
  }
`;

const Vendor = () => {
  const { addAlert } = useAlerts();
  const { _id: parameter } = useParams();
  const { data } = useQuery(GET_VENDOR, {
    variables: { _id: parameter },
  });
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);
  const [contactDialog, setContactDialog] = useState();
  const [hidePOTable, setHidePOTable] = useState(false);
  const [updateVendor] = useMutation(UPDATE_VENDOR);
  const [addContact] = useMutation(ADD_CONTACT, {
    refetchQueries: [GET_VENDOR],
  });
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    refetchQueries: [GET_VENDOR],
  });
  const [removeContact] = useMutation(REMOVE_CONTACT, {
    refetchQueries: [GET_VENDOR],
  });

  useDocumentTitle(
    data ? `${data.vendor.customName || data.vendor.name} | Edit Vendor` : 'Edit Vendor',
  );

  const onSubmit = async (vendor) => {
    await updateVendor({ variables: { vendor } });
    setVendorDialogOpen(false);
    addAlert('Vendor information saved successfully', { level: 'success' });
  };

  const {
    register: registerContactForm,
    handleSubmit: handleContactFormSubmit,
    formState: { errors: contactFormErrors },
    reset: resetContactForm,
  } = useForm({ mode: 'onSubmit' });

  const onContactSubmit = async ({ _id: contactId, ...contact }) => {
    if (contactId) {
      await updateContact({
        variables: { _id: data.vendor._id, contactId, contact },
      });
    } else {
      await addContact({ variables: { _id: data.vendor._id, contact } });
    }
    resetContactForm();
    setContactDialog();
    addAlert('Contact information saved successfully', { level: 'success' });
  };

  const onRemoveContact = async (contact) => {
    if (await confirm(`Are you sure you want to remove ${contact.name} from contacts?`)) {
      removeContact({ variables: { _id: data.vendor._id, contactId: contact._id } });
    }
  };

  const handleAddContact = () => {
    resetContactForm();
    setContactDialog({}); // i.e. truthy but no existing record
  };

  const onCloseContactModal = () => {
    resetContactForm();
    setContactDialog();
  };

  const { isDemo } = useDemo();
  const location = useLocation();
  const fallbackText = '-';

  const purchaseOrderStatuses = [
    'PRE_PRODUCTION',
    'PRODUCTION',
    'PRE_SHIPMENT',
    'SHIPMENT',
    'PARTIALLY_DELIVERED',
  ];

  return (
    data && (
      <div className="-mb-12 min-h-screen bg-white">
        <div className="-mt-12 mb-6 bg-purple-120 px-8 py-6 text-white">
          <div className="flex items-center justify-between">
            <Link
              to={location?.state?.fromPath || '/vendors'}
              className="group -ml-5 inline-flex items-center rounded-full px-4 py-2 text-sm font-bold transition-colors hover:bg-purple-110"
            >
              <FiChevronLeft
                className="mr-1 transition-transform group-hover:-translate-x-1"
                size={20}
              />
              <span>{`${location?.state?.fromPath ? 'Back' : 'All Vendors'}`}</span>
            </Link>
            <Button
              label="Edit Vendor"
              icon={FiEdit2}
              slim
              onClick={() => setVendorDialogOpen(true)}
            />
          </div>
          <h2 className="mt-4 max-w-2xl text-2xl font-medium">
            <span className={classnames(isDemo && 'blur')}>{data.vendor.name}</span>
          </h2>
          <div className="mt-2 flex space-x-4 text-sm">
            <div>
              Lead Time:&nbsp;
              <span className="font-bold">
                {data.vendor.leadTime ? `${data.vendor.leadTime} days` : '-'}
              </span>
            </div>
          </div>
          <div className="mt-16 flex space-x-8 text-sm font-bold">
            <div className="w-3/12 border-t-2 border-white/50 pt-1">
              <p className="text-white/70">Contact Name</p>
              <p className="mt-2">{data.vendor.contactName || fallbackText}</p>
            </div>
            <div className="w-3/12 border-t-2 border-white/50 pt-1">
              <p className="text-white/70">Email Address</p>
              {data.vendor.contactEmail ? (
                <a href={`mailto:${data.vendor.contactEmail}`} className="mt-2 inline-block">
                  {data.vendor.contactEmail}
                </a>
              ) : (
                <span className="mt-2 inline-block">{fallbackText}</span>
              )}
            </div>
            <div className="w-2/12 border-t-2 border-white/50 pt-1">
              <p className="text-white/70">Telephone</p>
              {data.vendor.contactEmail ? (
                <a href={`tel:${data.vendor.phoneNumber}`} className="mt-2 inline-block">
                  {data.vendor.phoneNumber}
                </a>
              ) : (
                <span className="mt-2 inline-block">{fallbackText}</span>
              )}
            </div>
            <div className="w-4/12 border-t-2 border-white/50 pt-1">
              <p className="text-white/70">Shipping Address</p>
              <p className="mt-2">{data.vendor.address || fallbackText}</p>
            </div>
          </div>
          <div className="mt-16 flex text-sm">
            <span className="font-bold text-white/70">
              {pluralize('Source', data.vendor.sources.length)}:&nbsp;
            </span>
            <div className="divide-x-2">
              {data.vendor.sources.map((source) => (
                <div key={source.sourceId} className="inline px-1 first:pl-0">
                  <span className="font-bold">{formatSourceName(source) || source.sourceId}</span>
                  {!source.isDefault || <>&nbsp;(Default Source)</>}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 flex justify-between px-8">
          <p className="font-bold text-midnight-100">Other Contacts</p>
          {data.vendor.contacts?.length > 0 && (
            <button
              type="button"
              onClick={handleAddContact}
              className="flex items-center text-sm font-bold text-purple-100 hover:text-purple-110"
            >
              <FiPlus size={18} className="mr-1" />
              Add Contact
            </button>
          )}
        </div>
        {data.vendor.contacts?.length > 0 ? (
          <div className="mb-6 mt-8 grid grid-cols-4 gap-4 px-8 text-midnight-100">
            {data.vendor.contacts?.map((contact) => (
              <div
                className="h-full rounded-b-lg bg-white text-midnight-100 shadow"
                key={contact._id}
              >
                <div className="h-1 bg-purple-100" />
                <div className="flex h-full flex-col p-6">
                  <header className="flex items-start justify-between">
                    <span className="mr-4 text-base font-bold">{contact.name}</span>
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={() => setContactDialog(contact)}
                        className="mr-2 text-sm font-bold leading-none text-purple-100 hover:text-purple-110"
                      >
                        Edit
                      </button>
                      <DeleteButton onDelete={() => onRemoveContact(contact)} />
                    </div>
                  </header>
                  <div className="mt-auto text-sm">
                    <p className="mt-2 font-bold text-purple-100">{contact.email}</p>
                    <p className="mt-2 text-gray-100">{contact.phoneNumber}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-14 text-center text-sm">
            <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-gray-50">
              <FiUser className="text-gray-75" size={32} />
            </div>
            <p className="mt-6 text-gray-100">
              Use this space to add any other contacts relevant to this vendor.
              <br />
              {hidePOTable && (
                <>Your Purchase Orders for this vendor will also appear below when created.</>
              )}
            </p>
            <button
              type="button"
              onClick={handleAddContact}
              className="mx-auto mt-6 flex items-center text-sm font-bold text-purple-100 hover:text-purple-110"
            >
              <FiPlus size={18} className="mr-1" />
              Add Contact
            </button>
          </div>
        )}
        {!hidePOTable && (
          <div className="mt-12">
            <h1 className="my-6 px-8 font-bold text-midnight-100">Open Purchase Orders</h1>
            <div className="pb-12">
              <POTable
                vendor={data.vendor._id}
                statuses={purchaseOrderStatuses}
                noResultsText="No purchase orders are available for this vendor"
                noResultsCallback={() => setHidePOTable(!(data.vendor.contacts?.length > 0))}
              />
            </div>
          </div>
        )}
        <Dialog
          open={!!vendorDialogOpen}
          onClose={() => setVendorDialogOpen(false)}
          className="text-xs text-midnight-100"
          style={{ fontFamily: 'Gilroy, sans-serif' }}
        >
          <div className="fixed inset-0 top-12 z-30 overflow-y-auto">
            {data && (
              <Dialog.Panel className="mx-auto max-w-xl overflow-hidden rounded bg-white shadow">
                <div className="bg-purple-10 p-8 py-6">
                  <Dialog.Title className="text-lg font-bold text-purple-100">
                    Edit Vendor
                  </Dialog.Title>
                </div>
                <VendorForm data={data} onSubmit={onSubmit} onCancel={setVendorDialogOpen} />
              </Dialog.Panel>
            )}
          </div>
          <div className="fixed inset-0 z-20 bg-black/50" aria-hidden="true" />
        </Dialog>
        <Dialog
          open={!!contactDialog}
          onClose={() => onCloseContactModal()}
          className="text-xs text-midnight-100"
          style={{ fontFamily: 'Gilroy, sans-serif' }}
        >
          <div className="fixed inset-0 top-12 z-30 overflow-y-auto">
            {contactDialog && (
              <Dialog.Panel className="mx-auto max-w-xl overflow-hidden rounded bg-white shadow">
                <form onSubmit={handleContactFormSubmit(onContactSubmit)}>
                  <div className="bg-purple-10 p-8 py-6">
                    <Dialog.Title className="text-lg font-bold text-purple-100">
                      {contactDialog._id ? 'Edit Contact' : 'New Contact'}
                    </Dialog.Title>
                  </div>
                  <div className="p-8">
                    <input
                      type="hidden"
                      defaultValue={contactDialog._id}
                      {...registerContactForm('_id')}
                    />
                    <div className="space-y-8 pt-4 text-sm">
                      <div>
                        <label htmlFor="contactName">
                          <span className="font-bold">Contact Name</span>
                          <input
                            type="text"
                            id="contactName"
                            placeholder="John Smith"
                            className={classnames('input', contactFormErrors.name && 'input-error')}
                            defaultValue={contactDialog.name}
                            {...registerContactForm('name', {
                              required: 'Please provide a contact name',
                            })}
                          />
                        </label>
                        <InputErrorMessage message={contactFormErrors.name?.message} />
                      </div>
                      <div>
                        <label htmlFor="contactEmail">
                          <span className="font-bold">Contact Email</span>
                          <input
                            type="email"
                            id="contactEmail"
                            placeholder="john@mystore.com"
                            className={classnames(
                              'input',
                              contactFormErrors.email && 'input-error',
                            )}
                            defaultValue={contactDialog.email}
                            {...registerContactForm('email', {
                              pattern: {
                                value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                                message: 'Please provide a valid email',
                              },
                            })}
                          />
                        </label>
                        <InputErrorMessage message={contactFormErrors.email?.message} />
                      </div>
                      <div>
                        <label htmlFor="phoneNumber">
                          <span className="font-bold">Contact Phone Number</span>
                          <input
                            type="tel"
                            id="phoneNumber"
                            placeholder="+44 20 7946 0933"
                            className={classnames(
                              'input',
                              contactFormErrors.phoneNumber && 'input-error',
                            )}
                            defaultValue={contactDialog.phoneNumber}
                            {...registerContactForm('phoneNumber')}
                          />
                        </label>
                        <InputErrorMessage message={contactFormErrors.phoneNumber?.message} />
                      </div>
                    </div>
                    <div className="mt-12 text-right">
                      <Button
                        variant="text"
                        slim
                        label="Cancel"
                        onClick={() => onCloseContactModal()}
                      />
                      <Button type="submit" variant="primary" slim label="Save changes" />
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            )}
          </div>
          <div className="fixed inset-0 z-20 bg-black/50" aria-hidden="true" />
        </Dialog>
      </div>
    )
  );
};
export default Vendor;
