import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonBlock } from '../../../Components/Skeletons.jsx';
import Widget from '../Widget.jsx';
import Heatmap from './Heatmap.jsx';

const Index = ({ draftPurchaseOrders, heatmapData, loading }) => (
  <Widget>
    <Widget.Title>Stock Levels</Widget.Title>
    {loading && <SkeletonBlock className="mt-6 h-[292px]" />}
    {!loading && (
      <>
        <Widget.Content>
          <Heatmap draftPurchaseOrders={draftPurchaseOrders} heatmapData={heatmapData} />
        </Widget.Content>
        <Widget.Link to="/purchase-orders">Manage my Purchase Orders</Widget.Link>
      </>
    )}
  </Widget>
);

Index.propTypes = {
  heatmapData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      percentage: PropTypes.number,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  draftPurchaseOrders: PropTypes.shape({}).isRequired,
};

export default Index;
