import React from 'react';
import PropTypes from 'prop-types';
import { FiInfo } from 'react-icons/fi';
import classnames from 'classnames';

const Element = ({ children, className }) => <div className={`peer ${className}`}>{children}</div>;

Element.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Element.defaultProps = {
  children: <FiInfo className="stroke-gray-100" size={12} />,
  className: 'w-5 h-5 flex justify-center items-center bg-gray-50 rounded-full',
};

const alignments = {
  bottom: 'left-[50%] -translate-x-1/2 bottom-0 -mb-2 translate-y-full flex-col-reverse',
  left: 'left-0 -ml-2 top-0 -mt-2 -translate-y-full flex-col',
  center: 'left-[50%] -translate-x-1/2 top-0 -mt-2 -translate-y-full flex-col',
  right: 'right-0 -mr-2 top-0 -mt-2 -translate-y-full flex-col',
  rightCenter: 'right-0 -mr-2 translate-x-full top-0 flex-col',
};

const Body = ({ children, align, bgColorClass, textColorClass }) => (
  <div className={`absolute z-10 hidden items-center peer-hover:flex ${alignments[align]}`}>
    <div
      className={`relative z-10 w-[max-content] rounded p-2 text-left text-xs shadow-lg ${textColorClass} ${bgColorClass}`}
    >
      {children}
    </div>
    <div
      className={classnames(
        `-mt-2 h-3 w-3 origin-center rotate-45 ${bgColorClass} z-20 shadow-lg`,
        align === 'right' && 'ml-auto mr-[13px]',
        align === 'left' && 'ml-[13px] mr-auto',
        align === 'bottom' ? 'z-0 -mb-2' : 'z-20 -mt-2',
        align === 'rightCenter' && 'absolute -left-[5px] top-[50%] -mt-1.5',
      )}
    />
  </div>
);

Body.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  bgColorClass: PropTypes.string,
  textColorClass: PropTypes.string,
};

Body.defaultProps = {
  align: 'center',
  bgColorClass: 'bg-purple-100',
  textColorClass: 'text-white',
};

const Tooltip = ({ children, className }) => (
  <div className={`relative ${className}`}>{children}</div>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  className: '',
};

Tooltip.Body = Body;
Tooltip.Element = Element;

export default Tooltip;
