import React from 'react';
import PropTypes from 'prop-types';

const Cin7Icon = ({ className = '', ...props }) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      opacity="0.5"
      d="M4.64162 12.4954L5.88386 13.0964L6.24541 13.2714L4.34179 4.8922H2.91455L4.64162 12.4954Z"
    />
    <path opacity="0.5" d="M1.39313 10.9292L3.01859 11.71L1.44679 4.84015H0L1.39313 10.9292Z" />
    <path
      opacity="0.5"
      d="M8.11898 8.82935L7.21657 4.8922H5.77698L7.39916 11.9703L8.11898 8.82935Z"
    />
    <path d="M9.77802 12.2831L8.37915 12.9591L11.0542 1.19703L12.0223 2.41488L9.77802 12.2831Z" />
    <path d="M12.6038 10.9303L11.1896 11.6059L13.0213 3.64312L14 4.8608L12.6038 10.9303Z" />
    <path d="M3.87758 0L2.90821 1.21661H8.57253L8.2951 2.43317H1.93884L0.969525 3.64979H8.01767L7.74024 4.8664H0L0.277431 6.08296H7.4628L5.8639 13.0943L6.98704 13.6357L10.0967 0H3.87758Z" />
  </svg>
);

Cin7Icon.propTypes = {
  className: PropTypes.string,
};

Cin7Icon.defaultProps = {
  className: null,
};

export default Cin7Icon;
