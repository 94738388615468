import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../Components/LoadingPage.jsx';

const CONNECT_WITH_FULFIL = gql`
  mutation connectWithFulfil($state: String!, $authCode: String!) {
    connectWithFulfil(state: $state, authCode: $authCode) {
      _id,
    }
  }
`;

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);

  const [connectWithFulfil] = useMutation(CONNECT_WITH_FULFIL);
  let isFirstRender = true;

  useEffect(() => {
    const doConnect = async () => {
      await connectWithFulfil({
        variables: {
          state: query.get('state'),
          authCode: query.get('code'),
        },
      });
      navigate('/settings/sources');
    };

    if (isFirstRender) {
      doConnect();
      isFirstRender = false;
    }
  }, []);

  return <LoadingPage />;
};

export default Callback;
