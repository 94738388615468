/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { FiChevronDown, FiPlus } from 'react-icons/fi';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { useAccount } from '../../AccountProvider.jsx';
import Button from '../../Components/Button.jsx';
import SkuDetails from '../../Components/SkuDetails.jsx';
import OnHandTarget from '../../Skus/OnHandTarget.jsx';
import { calculateTargetMissingQuantity } from '../helpers.js';
import { byTargetAndLocationName, LocationRow } from './PurchaseOrderItem.jsx';

export const SKU_SEARCH_QUERY = gql`
  query GetSku(
    $sku: String!
    $vendor: String
    $exclude: [String]!
    $excludeBundles: Boolean
    $category: SkuFilterCategories
    $locations: [ID]
  ) {
    skus(
      first: 10
      search: $sku
      vendor: $vendor
      exclude: $exclude
      excludeBundles: $excludeBundles
      category: $category
      locations: $locations
      sortKey: score
      reverse: true
    ) {
      edges {
        node {
          sku
          productName
          variantName
          variantId
          inventoryQuantity
          isBundle
          stats {
            targetInventoryQuantity
            targetBundleInventoryQuantity
            targetMissingInventoryQuantity
            incomingInventoryQuantity
          }
          vendor {
            name
          }
          inventoryBreakdown(onlyActive: true) {
            _id
            name
            inventoryQuantity
            targetInventoryQuantity
            targetBundleInventoryQuantity
            targetMissingInventoryQuantity
            incomingInventoryQuantity
            replenishmentStatus
          }
        }
      }
    }
  }
`;

const SkuRow = ({ sku, onItemAdd, locations }) => {
  const [showLocations, setShowLocations] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const {
    formatNumber,
    locations: { edges: allLocations },
  } = useAccount();

  const accountLocations = allLocations.filter((location) => !location.node.hideReplenishment);

  const selectedLocations = sku.inventoryBreakdown
    .filter((location) => accountLocations.some(({ node }) => node._id === location._id))
    .filter((location) =>
      locations.length > 0
        ? locations.includes(location._id)
        : location.replenishmentStatus === 'replenishNow',
    )
    .sort(byTargetAndLocationName(accountLocations));

  const otherLocations = sku.inventoryBreakdown
    .filter((location) => !!accountLocations.find(({ node }) => node._id === location._id))
    .filter((l) => !selectedLocations.find(({ _id }) => l._id === _id))
    .sort(byTargetAndLocationName(accountLocations));

  const inventoryQuantity = selectedLocations.reduce(
    (acc, location) => acc + location.inventoryQuantity,
    0,
  );

  const target = selectedLocations.reduce(
    (acc, location) =>
      acc + location.targetInventoryQuantity + location.targetBundleInventoryQuantity,
    0,
  );

  const targetMissing = selectedLocations.reduce(
    (acc, location) => acc + location.targetMissingInventoryQuantity,
    0,
  );

  const incomingInventory = selectedLocations.reduce(
    (acc, location) => acc + location.incomingInventoryQuantity,
    0,
  );

  return (
    <>
      <tr
        className={classnames('border-t border-midnight-5', showLocations && 'bg-leafy-10')}
        key={sku.sku}
      >
        <td className="py-2 pl-6 pr-3 text-left align-top">
          <div className="flex items-start">
            <SkuDetails sku={sku} />
            <button
              className="ml-auto inline-flex items-center whitespace-nowrap rounded-full p-1 text-purple-75 opacity-70 transition-colors duration-150 ease-out hover:bg-purple-75 hover:text-white"
              type="button"
              title={`${showLocations ? 'Hide' : 'Show'} locations`}
              onClick={() => setShowLocations((v) => !v)}
            >
              <div>
                <FiChevronDown
                  className={classnames(
                    'transform stroke-current transition-transform duration-200 ease-out',
                    showLocations && 'rotate-180',
                  )}
                  size={20}
                  strokeWidth={3}
                />
              </div>
            </button>
          </div>
        </td>
        <td className="px-3">
          <OnHandTarget quantity={inventoryQuantity} target={target} />
        </td>
        <td className="px-3">
          <div className="inline-block w-12 rounded-lg bg-gray-100 py-1 text-center font-semibold text-white">
            {formatNumber(targetMissing)}
          </div>
        </td>
        <td className="px-3 text-right">{formatNumber(incomingInventory)}</td>
        <td colSpan={6} className="text-left">
          <button
            type="button"
            className="group mx-auto ml-3 inline-flex h-10 w-10 items-center justify-center rounded-full border border-transparent text-purple-75 transition-colors duration-150 ease-out hover:bg-purple-75 hover:text-white active:bg-midnight-75"
          >
            <FiPlus
              className="stroke-current"
              strokeWidth={3}
              size={20}
              onClick={() => onItemAdd([[sku.sku, targetMissing, { highlight: true }]])}
            />
          </button>
        </td>
      </tr>
      {showLocations &&
        selectedLocations.map((loc) => <LocationRow location={loc} key={loc._id} />)}
      {showLocations && otherLocations.length > 0 && (
        <tr>
          <td colSpan={10}>
            <p
              className="flex cursor-pointer items-center px-6 py-3 text-left italic opacity-70"
              onClick={() => setShowOthers((v) => !v)}
            >
              <span className="mr-2">
                {`${showOthers ? 'Hide' : 'Show'} ${otherLocations.length} other ${pluralize(
                  'location',
                  otherLocations.length,
                )} not needing or not selected for replenishment`}
              </span>
              <FiChevronDown
                className={classnames(
                  'transition-transform duration-200 ease-out',
                  showOthers && 'rotate-180',
                )}
              />
            </p>
          </td>
        </tr>
      )}
      {showLocations &&
        showOthers &&
        otherLocations.map((loc) => (
          <LocationRow location={loc} key={loc._id} className="opacity-60" />
        ))}
      {/* additional vetical spacer element for when the location breakdown is expanded */}
      {showOthers && (
        <tr>
          <td className="h-4" />
        </tr>
      )}
    </>
  );
};

SkuRow.propTypes = {
  sku: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    productName: PropTypes.string,
    variantName: PropTypes.string,
    inventoryQuantity: PropTypes.number,
    stats: PropTypes.shape({
      targetInventoryQuantity: PropTypes.number,
      targetBundleInventoryQuantity: PropTypes.number,
    }),
    inventoryBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        inventoryQuantity: PropTypes.number,
        targetInventoryQuantity: PropTypes.number,
        targetBundleInventoryQuantity: PropTypes.number,
        targetMissingInventoryQuantity: PropTypes.number,
        incomingInventoryQuantity: PropTypes.number,
        replenishmentStatus: PropTypes.oneOf([
          'replenishNow',
          'replenishSoon',
          'overstocked',
          'healthy',
        ]),
      }),
    ),
  }).isRequired,
  onItemAdd: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const AdditionalSkus = ({ vendor, locations = [], currentItems, onItemAdd }) => {
  const {
    locations: { edges: accountLocations },
  } = useAccount();
  const { data } = useQuery(SKU_SEARCH_QUERY, {
    variables: {
      sku: '',
      vendor,
      exclude: currentItems.map(({ sku }) => sku),
      category: 'replenishNow',
      locations,
    },
  });

  const addAll = () => {
    const items = data.skus.edges.map(({ node }) => [
      node.sku,
      calculateTargetMissingQuantity(node, accountLocations, locations),
      { highlight: true }, // highliht the row on add
    ]);
    onItemAdd(items);
  };

  if (!data || data.skus.edges.length === 0) return null;

  return (
    <>
      <tr className="">
        <td className="pt-6" colSpan={9}>
          <div className="ml-6 flex text-xs">
            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-red-10">
              <div className="h-2 w-2 rounded-full bg-red-75" />
            </div>
            <p className="ml-1 text-sm font-medium text-midnight-85">
              Here are some products that also need replenishment
            </p>
          </div>
        </td>
      </tr>

      <tr className="">
        <td className="pb-4 pl-6 pr-3 pt-8 text-left font-bold">Product</td>
        <td className="px-3 pb-4 pt-8 font-bold">On hand / Target</td>
        <td className="px-3 pb-4 pt-8 font-bold">Recommended</td>
        <td className="px-3 pb-4 pt-8 font-bold">In Transit</td>
        <td className="pb-4 pt-8" colSpan="2">
          <Button
            slim
            label="Add all"
            icon={FiPlus}
            variant="primary"
            className="!px-3"
            iconSize={16}
            onClick={addAll}
          />
        </td>
      </tr>
      {data?.skus.edges.map(({ node: sku }) => (
        <SkuRow key={sku.sku} sku={sku} onItemAdd={onItemAdd} locations={locations} />
      ))}
    </>
  );
};

AdditionalSkus.propTypes = {
  vendor: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentItems: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onItemAdd: PropTypes.func.isRequired,
};

export default AdditionalSkus;
