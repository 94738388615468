export const calculateNewPlan = (initialValue, oldPlan, updatedIndex, newLift) => {
  let previousMonthValue = initialValue;

  return oldPlan.map((month, i) => {
    // Default is to not change the data saved into the plan
    let newPlanMonth = month;

    // If we need to update this month, we apply the new lift to the previous month value
    // (with a fallback to the last datapoint of the growth data as initial value)
    if (i === updatedIndex) newPlanMonth = {
      date: month.date,
      value: previousMonthValue * newLift,
      monthGrowth: newLift,
    };

    // For subsequent months we apply the existing lift to the new calculated value so that
    // the new lift is updated in cascade
    if (i > updatedIndex) newPlanMonth = {
      date: month.date,
      value: previousMonthValue * month.monthGrowth,
      monthGrowth: month.monthGrowth,
    };

    previousMonthValue = newPlanMonth.value;

    return newPlanMonth;
  });
};
