import React from 'react';
import Button from '../Components/Button.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const NotFound = () => {
  useDocumentTitle('Page not found');
  return (
    <div className="flex items-center justify-center" style={{ minHeight: 'calc(100vh - 6rem)' }}>
      <div className="text-center text-midnight-100">
        <h1 className="text-6xl font-bold text-purple-100">404</h1>
        <p className="mt-8 text-lg">The page you are looking for does not exist.</p>
        <p className="text-lg">You can click the button below to go back to the homepage.</p>
        <div className="mt-8">
          <Button label="Home" href="/" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
