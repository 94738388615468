import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { BiArchiveOut } from 'react-icons/bi';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import { UNMERGE_SKUS, UPDATE_SKUS } from './Queries.js';

const RestoreProductButton = ({ account, sku, isMerged }) => {
  const [unMergeSku] = useMutation(UNMERGE_SKUS);
  const [updateSkus] = useMutation(UPDATE_SKUS);
  const { addAlert } = useAlerts();

  const handleUnMergeSku = async (e) => {
    e.stopPropagation();

    await unMergeSku({
      variables: { skuFrom: sku.sku },
      update: (cache) => {
        cache.evict({ _id: cache.identify({ _id: account._id, __typename: 'Account' }) });
        cache.gc();
      },
    });
    addAlert('Product un-merged successfully', { level: 'success' });
  };

  const handleUnarchiveSku = async (e) => {
    e.stopPropagation();
    await updateSkus({
      variables: { skus: [sku.sku], input: { isArchived: false } },
      update: (cache) => {
        cache.evict({ _id: cache.identify({ __typename: 'SkuConnection' }) });
        cache.evict({ _id: cache.identify({ __typename: 'SkuCategoryConnection' }) });
        cache.gc();
      },
    });
    addAlert('Product restored successfully', { level: 'success' });
  };
  return (
    <div className="group">
      <BiArchiveOut className="block h-4 w-4 text-purple-100 group-hover:hidden" />
      <button
        type="button"
        className="hidden text-xs font-semibold text-purple-100 group-hover:block"
        onClick={isMerged ? handleUnMergeSku : handleUnarchiveSku}
      >
        {isMerged ? 'Unmerge' : 'Restore'}
      </button>
    </div>
  );
};

RestoreProductButton.propTypes = {
  account: PropTypes.shape().isRequired,
  sku: PropTypes.shape().isRequired,
  isMerged: PropTypes.bool,
};

RestoreProductButton.defaultProps = {
  isMerged: false,
};

export default RestoreProductButton;
