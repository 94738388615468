import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FiChevronDown, FiPlus } from 'react-icons/fi';
import { Dialog, Listbox } from '@headlessui/react';
import classnames from 'classnames';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import { clearTransfersCache, CREATE_TRANSFER } from './Queries.js';

const CreateTransferPopupBody = ({ onClose }) => {
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);

  const navigate = useNavigate();

  const {
    locations: { edges: allLocations },
  } = useAccount();

  const [createTransfer, { loading }] = useMutation(CREATE_TRANSFER, {
    update: clearTransfersCache,
    onCompleted: (data) => navigate(`/transfers/${data.createTransfer._id}`),
  });

  const locations = allLocations.filter(({ node: location }) => !location.hideReplenishment);

  return (
    <div className="z-10 mx-auto w-full max-w-md rounded bg-white shadow">
      <Dialog.Title className="rounded-t bg-purple-10 p-6 text-xl font-medium text-purple-100">
        Create internal transfer
      </Dialog.Title>

      <div className="mt-6 px-6">
        <Listbox value={fromLocation} onChange={setFromLocation}>
          <div className="relative mt-8 text-xs">
            <Listbox.Label>
              <p className="font-bold">Source location</p>
            </Listbox.Label>
            <Listbox.Button className="relative mt-2 h-8 w-full cursor-default rounded-lg border border-midnight-10 bg-white px-4 py-2 pr-10 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-2">
              <div className="truncate">
                {fromLocation
                  ? locations.find(({ node: { _id } }) => fromLocation === _id).node.name
                  : 'Select location'}
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FiChevronDown className="text-midnight-100" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-2 ring-purple-100 ring-opacity-5 focus:outline-none">
              {locations.map(({ node: location }) => (
                <Listbox.Option
                  key={location._id}
                  value={location._id}
                  className={({ active }) =>
                    classnames(
                      'px-4 py-2',
                      active ? 'bg-purple-100 text-white' : 'bg-white text-midnight-100',
                    )
                  }
                >
                  <div className="truncate">{location.name}</div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
        <Listbox value={toLocation} onChange={setToLocation}>
          <div className="relative mt-8 text-xs">
            <Listbox.Label>
              <p className="font-bold">Destination location</p>
            </Listbox.Label>
            <Listbox.Button className="relative mt-2 h-8 w-full cursor-default rounded-lg border border-midnight-10 bg-white px-4 py-2 pr-10 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-2">
              <div className="truncate">
                {toLocation
                  ? locations.find(({ node: { _id } }) => toLocation === _id).node.name
                  : 'Select location'}
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FiChevronDown className="text-midnight-100" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-2 ring-purple-100 ring-opacity-5 focus:outline-none">
              {locations.map(({ node: location }) => (
                <Listbox.Option
                  key={location._id}
                  value={location._id}
                  className={({ active }) =>
                    classnames(
                      'px-4 py-2',
                      active ? 'bg-purple-100 text-white' : 'bg-white text-midnight-100',
                    )
                  }
                >
                  <div className="truncate">{location.name}</div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
      <div className="mt-12 flex justify-end space-x-2 px-6 pb-8">
        <Button slim label="Cancel" variant="text" onClick={onClose} />
        <Button
          slim
          label="Create Transfer"
          disabled={!fromLocation || !toLocation || fromLocation === toLocation || loading}
          onClick={() =>
            createTransfer({
              variables: {
                input: {
                  fromLocation,
                  toLocation,
                  items: [],
                },
              },
            })
          }
        />
      </div>
    </div>
  );
};

CreateTransferPopupBody.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const CreateTransferPopup = ({ open, onClose }) =>
  open && (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <CreateTransferPopupBody onClose={onClose} />
      </div>
    </Dialog>
  );

CreateTransferPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CreateTransferPopupButton = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const togglePopupOpen = useCallback(() => {
    setPopupOpen(!popupOpen);
  }, [popupOpen]);

  return (
    <>
      <Button slim icon={FiPlus} label="Create a Transfer" onClick={togglePopupOpen} />

      <CreateTransferPopup open={popupOpen} onClose={togglePopupOpen} />
    </>
  );
};

export default CreateTransferPopupButton;
