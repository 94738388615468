import { isDate } from 'date-fns';

/**
 * Converts a parameter into date, keeping the original day if no time is specified
 * @param {Date|String|null} date
 * @returns Date
 */
export const toDate = (date) => {
  if (isDate(date)) return date;

  if (Number.isInteger(date)) return new Date(date);

  if (typeof date !== 'string') return null;

  // This is to prevent timezone issues with browsers interpreting the date
  // with implicit time
  if (date.length === 10) return new Date(`${date}T12:00:00.000Z`);

  return new Date(date);
};
