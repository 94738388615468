import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const RadioButton = ({ isChecked }) => (
  <div
    className={classnames(
      'flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full',
      isChecked ? 'border-2 border-purple-100' : 'border border-gray-75',
    )}
  >
    {isChecked ? <div className="h-2 w-2 rounded-full bg-purple-100" /> : null}
  </div>
);

export default RadioButton;

RadioButton.propTypes = {
  isChecked: PropTypes.bool,
};

RadioButton.defaultProps = {
  isChecked: false,
};
