import React from 'react';
import { useDocumentTitle } from '../Hooks/index.js';
import BlockedGroups from './Tools/BlockedGroups.jsx';
import BundleImports from './Tools/BundlesImport.jsx';
import DataExport from './Tools/DataExport.jsx';
import Planning from './Tools/Planning.jsx';
import StatsInfo from './Tools/StatsInfo.jsx';

const Tools = () => {
  useDocumentTitle('Tools');

  return (
    <div className="col-span-9 self-start text-midnight-100">
      <h5 className="text-xl">Tools</h5>

      <StatsInfo />
      <BundleImports />
      <Planning />
      <DataExport />
      <BlockedGroups />
    </div>
  );
};

export default Tools;
