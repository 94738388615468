import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../Components/LoadingPage.jsx';

const CONNECT_WITH_AMAZON = gql`
  mutation connectWithAmazon(
      $state: String!
      $oAuthCode: String!
      $partnerId: String!
      $mwsAuthToken: String
      $marketPlaceId: String!
      $region: String!
  ) {
    connectWithAmazon(
      state: $state, 
      oAuthCode:$oAuthCode, 
      partnerId:$partnerId, 
      mwsAuthToken:$mwsAuthToken, 
      marketPlaceId: $marketPlaceId,
      region:$region
    ) {
        _id,
    }
  }
`;

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);

  const [connectWithAmazon] = useMutation(CONNECT_WITH_AMAZON);
  let isFirstRender = true;

  useEffect(() => {
    const doConnect = async () => {
      const marketPlace = JSON.parse(window.localStorage.getItem('marketPlace'));

      await connectWithAmazon({
        variables: {
          state: query.get('state'),
          oAuthCode: query.get('spapi_oauth_code'),
          partnerId: query.get('selling_partner_id'),
          mwsAuthToken: query.get('mws_auth_token'),
          marketPlaceId: marketPlace.id,
          region: marketPlace.region,
        },
      });
      window.localStorage.removeItem('marketPlace');
      navigate('/settings/sources');
    };

    if (isFirstRender) {
      doConnect();
      isFirstRender = false;
    }
  }, []);

  return <LoadingPage />;
};

export default Callback;
