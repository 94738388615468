import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { PLANS } from '../../shared/billing/plans.js';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import AccountSwitcher from '../Layout/AccountSwitcher.jsx';
import { compactNumber } from '../Planning/PlannedProducts/Index.jsx';
import BillingWidget from './BillingWidget.jsx';

const cogsyLogo = '/cogsy-logo-on-white.svg';

const availablePlans = ['growth', 'scale'];
const combinedPlanTiers = [];

availablePlans.forEach((plan) => {
  PLANS[plan].tiers.forEach((tier) => {
    const existingTierIndex = combinedPlanTiers.findIndex(
      (existingTier) => existingTier.revenueTo === tier.revenueTo,
    );

    if (existingTierIndex === -1) {
      combinedPlanTiers.push({ revenueTo: tier.revenueTo, [plan]: tier.price });
    } else {
      combinedPlanTiers[existingTierIndex] = {
        ...combinedPlanTiers[existingTierIndex],
        [plan]: tier.price,
      };
    }
  });
});

const CREATE_CHECKOUT_URL = gql`
  mutation CreateBillingCheckoutUrl($plan: BillingPlan!, $revenueTo: Int!) {
    createBillingCheckoutUrl(plan: $plan, revenueTo: $revenueTo) {
      checkoutUrl
    }
  }
`;

const BillingPlans = () => {
  const { addAlert } = useAlerts();

  const [createBillingCheckoutUrl] = useMutation(CREATE_CHECKOUT_URL);

  const [planTiersIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleCreateRecurringCharge = (plan) => {
    setLoading(true);

    const doCreate = async () => {
      try {
        const { data } = await createBillingCheckoutUrl({
          variables: { plan, revenueTo: combinedPlanTiers[planTiersIndex].revenueTo },
        });
        window.location.assign(data.createBillingCheckoutUrl.checkoutUrl);
      } catch (error) {
        addAlert('An error has occurred! Please refresh the page and try again.', { error });
        setLoading(false);
      }
    };

    doCreate();
  };

  return (
    <main className="mx-auto mt-12 flex flex-col items-center pb-20 text-midnight-75">
      <div>
        <img src={cogsyLogo} alt="Cogsy Logo" className="mx-auto" />
      </div>

      <div className="mt-10 flex justify-center space-x-10">
        <BillingWidget
          hasAllOptions
          title="Scale"
          description="Everything"
          price={combinedPlanTiers[planTiersIndex].scale || PLANS.scale.tiers[0].price}
          revenueLimit={compactNumber(
            combinedPlanTiers[planTiersIndex].revenueTo > PLANS.scale.tiers[0].revenueTo
              ? combinedPlanTiers[planTiersIndex].revenueTo * 1000
              : PLANS.scale.tiers[0].revenueTo * 1000,
          )}
          onClick={() => handleCreateRecurringCharge('scale')}
          loading={loading}
        />
      </div>

      <div className="mt-8 max-w-md text-center text-xs font-medium text-gray-100">
        Credit card required. Monthly fee will be taken at the end of your free trial. If you cancel
        within your trial period you won’t pay anything.
      </div>

      <div className="max-w-md">
        <AccountSwitcher className="my-8 flex flex-wrap place-content-center text-center" />
      </div>
    </main>
  );
};

export default BillingPlans;
