/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { AiOutlineTable } from 'react-icons/ai';
import classnames from 'classnames';
import pluralize from 'pluralize';
import {
  orderedCompact as orderedCompactStatuses,
  STATUSES,
} from '../../shared/purchaseOrders/index.js';
import { useAccount } from '../AccountProvider.jsx';
import confirm from '../Components/ConfirmDialog.jsx';
import DeleteButton from '../Components/DeleteButton.jsx';
import { useDemo } from '../Components/DemoProvider.jsx';
import { Pagination } from '../Components/Pagination.jsx';
import Spinner from '../Components/Spinner.jsx';
import { StatusListBox } from './Form.jsx';
import { clearPurchaseOrdersCache, PURCHASE_ORDERS, REMOVE_PURCHASE_ORDER } from './Queries.js';

const POTable = ({
  location,
  statuses,
  vendor,
  onPagination,
  pageAfter,
  pageBefore,
  noResultsText,
  noResultsCallback,
}) => {
  const navigate = useNavigate();
  const { account, user } = useAccount();
  const { isDemo } = useDemo();
  const [after, setAfter] = useState(pageAfter);
  const [before, setBefore] = useState(pageBefore);

  const { data, previousData, loading } = useQuery(PURCHASE_ORDERS, {
    variables: {
      first: 20,
      after,
      before,
      location,
      statuses,
      vendor,
    },
  });
  const purchaseOrders = data?.purchaseOrders.edges || previousData?.purchaseOrders.edges;
  const pageInfo = data?.purchaseOrders.pageInfo;

  React.useEffect(() => {
    if (!loading && purchaseOrders.length === 0 && noResultsCallback) {
      noResultsCallback();
    }
  }, [loading, purchaseOrders]);

  React.useEffect(() => {
    setAfter(undefined);
    setBefore(undefined);
  }, [location, statuses, vendor]);

  const [removePurchaseOrder] = useMutation(REMOVE_PURCHASE_ORDER, {
    update: clearPurchaseOrdersCache,
    onCompleted: () => {
      // check if this is the only item on the current page
      // and hasPreviousPage = true
      // and navigate manually to the first page because it's impossible to go to the
      // previous with cursor based pagination
      if (purchaseOrders?.length === 1 && pageInfo?.hasPreviousPage) {
        navigate('/purchase-orders');
      }
    },
  });

  const showActions = !user.isReadOnly;

  const handleRemoveClick = async (purchaseOrder) => {
    if (await confirm('Are you sure you want to delete this purchase order?')) {
      removePurchaseOrder({ variables: { purchaseOrderId: purchaseOrder._id } });
    }
  };

  return (
    <div className="col-span-12 text-midnight-100">
      {purchaseOrders?.length > 0 && (
        <>
          <div className="grid grid-cols-[13%,12%,12%,repeat(5,9%),12.5%,5%] px-9 pb-2 text-left text-sm font-bold">
            <div>Purchase Order ID</div>
            <div>Vendor</div>
            <div>Destination</div>
            <div>Draft</div>
            <div>Production</div>
            <div>Pre-Ship</div>
            <div>Shipment</div>
            <div>Delivered</div>
            <div>Status</div>
            {showActions && <div>&nbsp;</div>}
          </div>
          {purchaseOrders.map(({ node: purchaseOrder }) => (
            <Link
              className="mx-6 mt-4 grid cursor-pointer grid-cols-[13%,12%,12%,repeat(5,9%),12.5%,5%] items-center rounded-lg border border-transparent bg-white p-4 text-left text-xs shadow hover:border-purple-90 hover:bg-leafy-10"
              to={`/purchase-orders/${purchaseOrder._id}`}
              key={purchaseOrder._id}
            >
              <div>
                <span
                  className="mr-2 block truncate text-sm font-bold"
                  title={purchaseOrder.customPurchaseOrderNumber}
                >
                  {purchaseOrder.customPurchaseOrderNumber}
                </span>
                <span className="font-bold text-gray-100">{`${purchaseOrder.itemsCount.toLocaleString()} ${pluralize(
                  'product',
                  purchaseOrder.itemsCount,
                )}`}</span>
              </div>
              <div
                className={classnames(isDemo && 'blur')}
                title={!isDemo ? purchaseOrder.vendor.name : ''}
              >
                <span className="mr-2 block truncate">{purchaseOrder.vendor.name}</span>
              </div>
              <div title={purchaseOrder.location?.name}>
                <span className="mr-2 block truncate">{purchaseOrder.location?.name}</span>
              </div>
              {orderedCompactStatuses.map((status, index) => {
                let bg = 'gray';
                let statusColumn = purchaseOrder.status;
                if (purchaseOrder.status === 'PRE_PRODUCTION') statusColumn = 'PRODUCTION';
                else if (purchaseOrder.status === 'PARTIALLY_DELIVERED') statusColumn = 'DELIVERED';
                else if (purchaseOrder.status === 'PRE_SHIPMENT') statusColumn = 'PRE-SHIP';
                const statusIndex = orderedCompactStatuses.indexOf(statusColumn);
                if (status === purchaseOrder.status) {
                  bg = 'green';
                } else if (statusIndex > -1 && statusIndex >= index) bg = 'green';

                return (
                  <div key={status} className="w-[80%]">
                    <div
                      className={classnames(
                        'mt-1 h-1 overflow-hidden rounded-sm bg-gray-75/25',
                        bg === 'green' &&
                          !['PRE_PRODUCTION', 'PARTIALLY_DELIVERED'].includes(
                            purchaseOrder.status,
                          ) &&
                          !statusIndex === index &&
                          '!bg-leafy-50',
                      )}
                    >
                      <div
                        className={classnames(
                          'h-full',
                          bg === 'green' &&
                            ['PRE_PRODUCTION', 'PARTIALLY_DELIVERED'].includes(
                              purchaseOrder.status,
                            ) &&
                            statusIndex === index
                            ? 'w-1/2'
                            : 'w-full',
                          bg === 'green' && '!bg-leafy-75',
                        )}
                      />
                    </div>
                  </div>
                );
              })}
              <div>
                <StatusListBox
                  selected={purchaseOrder.status}
                  options={[{ name: STATUSES[purchaseOrder.status], value: purchaseOrder.status }]}
                  disabled
                />
              </div>
              {showActions && purchaseOrder.sourceId === 'cogsy' ? (
                <div className="text-right">
                  <DeleteButton
                    onDelete={(e) => {
                      e.preventDefault();
                      handleRemoveClick(purchaseOrder);
                    }}
                  />
                </div>
              ) : null}
            </Link>
          ))}
        </>
      )}
      {!loading && purchaseOrders?.length === 0 && (
        <div className="my-12 flex flex-col items-center space-y-6 text-center text-xs text-gray-100">
          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-gray-50">
            <AiOutlineTable className="text-gray-75" size={32} />
          </div>
          <div className="text-sm">
            {noResultsText || (
              <>
                <p>There are no purchase orders for selected criteria.</p>
                {account.preferences.forecastEnabled !== false ? (
                  <p className="mt-2">Click the ”Generate Purchase Order” button for some magic.</p>
                ) : null}
              </>
            )}
          </div>
        </div>
      )}
      {loading && (
        <div className="absolute left-0 top-14 flex h-[calc(100%-3.5rem)] w-full items-center justify-center bg-white opacity-70">
          <Spinner className="opacity-30" />
        </div>
      )}
      <div className="mb-8 flex justify-center">
        {data?.purchaseOrders && (
          <Pagination
            items={data?.purchaseOrders}
            isLoading={loading}
            className="mt-6 flex justify-center space-x-4"
          >
            <Pagination.PrevButton
              onClick={(cursor) => {
                setBefore(cursor);
                setAfter(undefined);
                if (onPagination) {
                  onPagination(undefined, cursor);
                }
              }}
            />
            <Pagination.NextButton
              onClick={(cursor) => {
                setAfter(cursor);
                setBefore(undefined);
                if (onPagination) {
                  onPagination(cursor, undefined);
                }
              }}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

POTable.propTypes = {
  location: PropTypes.string,
  vendor: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
  noResultsText: PropTypes.string,
  noResultsCallback: PropTypes.func,
  onPagination: PropTypes.func,
  pageAfter: PropTypes.string,
  pageBefore: PropTypes.string,
};

POTable.defaultProps = {
  location: undefined,
  vendor: undefined,
  statuses: undefined,
  noResultsText: undefined,
  noResultsCallback: undefined,
  onPagination: undefined,
  pageAfter: undefined,
  pageBefore: undefined,
};

export default POTable;
