/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { RadioGroup } from '@headlessui/react';
import classnames from 'classnames';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import cogsyLogo from '../../public/cogsy-logo-on-white.svg';
import Alert from '../Components/Alert.jsx';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import { partnerStack } from '../config.js';
import { trackGoogleAnalyticsEvent } from '../GoogleAnalytics.js';

const initializePartnerStack = () => {
  if (!partnerStack.publicKey) return;

  const gs = document.createElement('script');
  gs.src = 'https://js.partnerstack.com/v1/';
  gs.type = 'text/javascript';
  gs.async = 'true';

  // eslint-disable-next-line func-names
  gs.onreadystatechange = function () {
    const rs = this.readyState;

    if (rs && rs !== 'complete' && rs !== 'loaded') return;

    try {
      window.growsumo._initialize(partnerStack.publicKey);
      if (typeof window.growsumoInit === 'function') {
        window.growsumoInit();
      }
    } catch (e) {
      // Ignore error
    }
  };

  gs.onload = gs.onreadystatechange;

  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(gs, s);
};

const trackSignupOnPartnerStack = ({ name, email }) => {
  if (!window.growsumo) return;

  window.growsumo.data.name = name;
  window.growsumo.data.email = email;
  window.growsumo.data.customer_key = email;

  window.growsumo.createSignup();
};

const isValidHttpUrl = (string) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const _ = new URL(string);
    return (
      string.startsWith('http://') ||
      string.startsWith('https://') ||
      'Please enter a valid URL (including https://)'
    );
  } catch {
    return 'Please enter a valid URL';
  }
};

const Register = ({ invitationCode, email = '' }) => {
  const [loading, setLoading] = React.useState(false);
  const [passwordShow, setPasswordShow] = React.useState(false);

  const channels = [
    'Search engine (Google, Bing, etc.)',
    'LinkedIn',
    'Twitter',
    'TikTok',
    'Word of mouth',
    'Youtube',
  ];

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setError,
    clearErrors,
  } = useForm({ mode: 'all' });

  React.useEffect(() => {
    initializePartnerStack();
  }, []);

  const handleRegister = React.useCallback((formData) => {
    setLoading(true);

    const doRegister = async () => {
      try {
        const names = formData.name.split(' ');

        const {
          data: { token },
        } = await axios('/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            ...formData,
            firstName: names[0],
            lastName: names.slice(1).join(' '),
            invitationCode,
          }),
        });

        trackSignupOnPartnerStack(formData);

        trackGoogleAnalyticsEvent('registration_completed');

        window.localStorage.setItem('token', token);

        window.location.assign('/');
      } catch (e) {
        setLoading(false);

        if (e.response?.status === 400) {
          setError('serverResponse', {
            type: 'manual',
            message: e.response.data.message,
          });
        } else {
          setError('serverResponse', {
            type: 'manual',
            message: `Internal server error: ${e.response?.status}`,
          });

          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    };

    doRegister();
  }, []);

  const signUpButtonText = invitationCode ? 'Join Cogsy' : 'Start trial';

  return (
    <main className="mx-auto mt-12 max-w-md px-6 pb-12 text-midnight-100 md:px-0">
      <div className="md:absolute md:-ml-48">
        <img src={cogsyLogo} alt="Cogsy Logo" className="mx-auto" />
      </div>

      <form noValidate onSubmit={handleSubmit(handleRegister)}>
        <section className="mt-12">
          {invitationCode ? (
            <h1 className="text-3xl">You have been invited to Cogsy.</h1>
          ) : (
            <h1 className="text-3xl">Try Cogsy free for 14 days</h1>
          )}
          <p className="mt-3 text-xl">
            Hey, friend &ndash; just need a few things to get you going.
          </p>
          <div className="mt-8">
            <label htmlFor="name" className="text-sm">
              Name*
              <input
                type="text"
                id="name"
                {...register('name', {
                  required: 'Please provide your name',
                })}
                className={classnames('input', errors.name && 'input-error')}
                placeholder="Donald Trotter"
                onFocus={() => clearErrors('serverResponse')}
              />
            </label>
            <InputErrorMessage message={errors.name?.message} />
          </div>
          <div className="mt-4">
            <label htmlFor="email" className="text-sm">
              Email*
              <input
                type="email"
                id="email"
                defaultValue={email}
                {...register('email', {
                  required: 'Please provide a valid email',
                  pattern: {
                    value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                    message: 'Please provide a valid email',
                  },
                })}
                className={classnames('input', errors.email && 'input-error')}
                placeholder="donald@acme.com"
                onFocus={() => clearErrors('serverResponse')}
              />
            </label>
            <InputErrorMessage message={errors.email?.message} />
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="text-sm">
              Password*
              <div className="relative">
                <input
                  type={passwordShow ? 'text' : 'password'}
                  id="password"
                  placeholder="8 characters minimum"
                  className={classnames('input', errors.password && 'input-error')}
                  onBlur={() => setPasswordShow(false)}
                  {...register('password', {
                    required: 'Please provide a password',
                    minLength: 8,
                  })}
                  onFocus={() => clearErrors('serverResponse')}
                />
                {passwordShow ? (
                  <button
                    type="button"
                    className="absolute right-4 top-4"
                    onClick={() => setPasswordShow(false)}
                  >
                    <FiEyeOff size={16} className="stroke-gray-100" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="absolute right-4 top-4"
                    onClick={() => setPasswordShow(true)}
                  >
                    <FiEye size={16} className="stroke-gray-100" />
                  </button>
                )}
              </div>
            </label>
            <InputErrorMessage message={errors.password?.message} />
          </div>
          {!invitationCode && (
            <div>
              <div className="mt-4">
                <label htmlFor="storeName" className="text-sm">
                  Store Name*
                  <input
                    type="text"
                    id="storeName"
                    {...register('storeName', {
                      required: 'Please provide your store name',
                    })}
                    className={classnames('input', errors.storeName && 'input-error')}
                    placeholder="Acme"
                    onFocus={() => clearErrors('serverResponse')}
                  />
                </label>
                <InputErrorMessage message={errors.storeName?.message} />
              </div>
              <div className="mt-4">
                <label htmlFor="storeUrl" className="text-sm">
                  Shopify Store URL*
                  <input
                    type="url"
                    id="storeUrl"
                    {...register('storeUrl', {
                      required: 'Please provide your store URL',
                      validate: isValidHttpUrl,
                    })}
                    className={classnames('input', errors.storeUrl && 'input-error')}
                    placeholder="https://acme.myshopify.com"
                    onFocus={() => clearErrors('serverResponse')}
                  />
                </label>
                <InputErrorMessage message={errors.storeUrl?.message} />
              </div>
              <div className="mt-4 text-xs">
                <Controller
                  name="channel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <RadioGroup value={field.value ?? ''} onChange={field.onChange}>
                      <RadioGroup.Label className="text-sm">
                        How Did You Hear About Us?*
                      </RadioGroup.Label>
                      {channels.map((channel) => (
                        <RadioGroup.Option key={channel} value={channel}>
                          {({ checked }) => (
                            <div className="mt-4 flex cursor-pointer items-center">
                              <div
                                className={classnames(
                                  checked
                                    ? 'ml-1 mr-3 h-2 w-2 rounded-full bg-purple-100 ring-2 ring-purple-100 ring-offset-2 ring-offset-gray-10'
                                    : 'mr-2 h-4 w-4 rounded-full border border-gray-75 bg-white',
                                )}
                              />
                              <span>{channel}</span>
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  )}
                />
              </div>
            </div>
          )}

          {errors.serverResponse?.message && (
            <div className="-mb-2 mt-6 flex">
              <Alert level="error" className="">
                {errors.serverResponse?.message}
              </Alert>
            </div>
          )}

          <div className="mt-8">
            <Button
              type="submit"
              disabled={!isValid || loading}
              fullWidth
              label={loading ? 'Please hold...' : signUpButtonText}
            />
          </div>
        </section>
      </form>

      {!invitationCode && (
        <div className="mt-8 text-center text-xs">
          Already have an account?&nbsp;
          <Link to="/login" className="text-purple-100">
            Log in
          </Link>
        </div>
      )}
    </main>
  );
};

Register.propTypes = {
  invitationCode: PropTypes.string,
  email: PropTypes.string,
};

Register.defaultProps = {
  invitationCode: null,
  email: '',
};

export default Register;
