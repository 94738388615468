import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';

const CREATE_GOOGLE_REDIRECT_URL = gql`
  mutation {
    createGoogleRedirectUrl(what: "sheets") {
      redirectUrl
    }
  }
`;

const GoogleSheetsPopup = ({ open, onClose }) => {
  const [createGoogleRedirectUrl] = useMutation(CREATE_GOOGLE_REDIRECT_URL);

  const handleGoogleSheetsConnect = React.useCallback(() => {
    const doConnect = async () => {
      const {
        data: {
          createGoogleRedirectUrl: { redirectUrl },
        },
      } = await createGoogleRedirectUrl();

      window.location.assign(redirectUrl);
    };

    doConnect();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="mb-2 text-xl">Add Google Sheets integration</Dialog.Title>

          <p className="mb-2">
            You&rsquo;ll be prompted to connect with your Google Account and you&rsquo;re be able to
            choose the Google Sheets view you want to connect to.
          </p>

          <button type="button" onClick={handleGoogleSheetsConnect}>
            <img src="/google-signin.png" alt="Google Sign-in" style={{ width: '191px' }} />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

GoogleSheetsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GoogleSheetsPopup;
