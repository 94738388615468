import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import {
  FiAlertTriangle,
  FiChevronDown,
  FiChevronLeft,
  FiExternalLink,
  FiInfo,
  FiPlus,
  FiRepeat,
  FiShoppingBag,
} from 'react-icons/fi';
import { Menu } from '@headlessui/react';
import classnames from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import pluralize from 'pluralize';
import { useAccount } from '../../AccountProvider.jsx';
import Button from '../../Components/Button.jsx';
import DeleteButton from '../../Components/DeleteButton.jsx';
import { useDemo } from '../../Components/DemoProvider.jsx';
import SubscriptionIndicator from '../../Components/SubscriptionIndicator.jsx';
import Tooltip from '../../Components/Tooltip.jsx';
import { getSafetyStock } from '../../Utils/subscriptions.js';
import Rank from '../Rank.jsx';
import { formatSourceName } from '../utils/formatSourceNames.js';

const replenishmentColorsMap = {
  replenishNow: 'bg-red-75 border-red-75',
  replenishSoon: 'bg-yellow-75 border-yellow-75',
  healthy: 'bg-leafy-75 border-leafy-75',
  overstocked: 'bg-leafy-100 border-white',
};

const missingSkuOrVendorCopy = (withoutSku, withoutVendor) => {
  if (withoutSku && withoutVendor) {
    return 'This product is missing a SKU code and vendor information. Please add them';
  }
  if (withoutSku) {
    return 'This product has a missing SKU code. Please add it';
  }
  if (withoutVendor) {
    return 'This product has no vendor information. Please add it';
  }
  return '';
};

const formatSourceDescription = (skuSourceId, accountSources = []) => {
  const accountSource = accountSources?.find((source) => source.sourceId === skuSourceId) ?? {};

  if (accountSource.syncSkus && accountSource.syncInventoryQuantities)
    return '(Product and Inventory sync)';
  if (accountSource.syncSkus) return '(Product sync)';
  if (accountSource.syncInventoryQuantities) return '(Inventory sync)';
  return null;
};

const Summary = ({ expectedRevenueLoss, handleSkuNamePopup, sku, handleRemoveVendor }) => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { formatCurrency, account } = useAccount();
  const { isDemo } = useDemo();

  const isComponent = sku.type === 'component';

  const secondaryVendors = sku.vendors?.filter((v) => v._id !== sku.vendor?._id) || [];

  const subscriptionsEnabled = useFeature('subscriptions').on;
  const recipesEnabled = useFeature('sku-recipes').on;

  const sourcesGroups = sku.sources.reduce((acc, source) => {
    const accSource = acc.find((item) => item.sourceType === source.sourceType);

    if (accSource) {
      return acc.map((s) => ({
        sourceType: s.sourceType,
        count: s.sourceType === source.sourceType ? s.count + 1 : s.count,
      }));
    }
    return [...acc, { sourceType: source.sourceType, count: 1 }];
  }, []);

  const bundleQuantitySold = sku.bundlesContainingSku?.reduce((qtySold, currentBundle) => {
    const { bundledQuantity } = currentBundle.bundledSkus.find(
      ({ sku: bundledSku }) => bundledSku === sku.sku,
    );

    return qtySold + currentBundle.stats.totalQuantitySold * bundledQuantity;
  }, 0);

  const {
    stats: { firstOutOfStockDate = null, subscriptions = null },
  } = sku;
  const {
    reserveInventoryHorizon,
    reserveInventoryByDate,
    bundleReserveInventoryByDate,
    aggregateReserveInventory,
    aggregateBundleReserveInventory,
  } = subscriptions || {};

  const subscriptionUnitsToReserve =
    subscriptions &&
    (sku.hasAtRiskSubscriptions && firstOutOfStockDate
      ? getSafetyStock(firstOutOfStockDate, reserveInventoryHorizon, {
          reserveInventoryByDate,
          bundleReserveInventoryByDate,
        })
      : aggregateReserveInventory + aggregateBundleReserveInventory);

  const subscriptionIndicator = (
    <SubscriptionIndicator
      isAtRisk={sku.hasAtRiskSubscriptions}
      info={
        sku.stats.subscriptions &&
        `This product is at-risk of being out of stock soon. Reserve ${pluralize(
          'unit',
          subscriptionUnitsToReserve,
          true,
        )} as safety stock to fulfil subscription renewals until ${new Date(
          sku.stats.subscriptions.reserveInventoryHorizon,
        ).toLocaleDateString()} when new stock will be available.`
      }
      tooltipBgColorClass="bg-white"
      tooltipTextColorClass="text-midnight-100"
    />
  );

  return (
    <div className="-mt-12 flex bg-purple-100 text-white">
      <div className="w-3/4 px-8 py-6">
        <div className="flex">
          <Link
            to={location?.state?.fromPath || '/skus'}
            className="group -ml-5 inline-flex items-center rounded-full px-4 py-2 text-sm font-bold transition-colors hover:bg-purple-110"
          >
            <FiChevronLeft
              className="mr-1 transition-transform group-hover:-translate-x-1"
              size={20}
            />
            <span>{`${location?.state?.fromPath ? 'Back' : 'All Products'}`}</span>
          </Link>

          {!isComponent && recipesEnabled && (sku.recipe ?? []).length === 0 && (
            <Button
              className="ml-auto"
              label="Create Recipe"
              variant="primary"
              icon={FiPlus}
              iconSize={16}
              slim
              onClick={() => setSearchParams({ createRecipe: true })}
            />
          )}
        </div>

        <h2 className="mt-4 max-w-2xl text-2xl font-medium">
          <span className={classnames(isDemo && 'blur')}>{sku.productName}</span>
          {sku.sources.length > 1 && (
            <button
              type="button"
              className="ml-4 inline-flex items-center rounded-full px-2 py-1 text-xs font-bold text-white/70 transition-colors hover:bg-purple-110"
              onClick={handleSkuNamePopup}
            >
              <FiRepeat size={12} />
              &nbsp; Change name
            </button>
          )}
        </h2>

        <div className="mt-2 flex items-center space-x-5 text-sm font-bold leading-none">
          {sku.stats.category && sku.stats.velocity && !isComponent ? (
            <Rank category={sku.stats.category} velocity={sku.stats.velocity} />
          ) : null}

          {sku.isBundle && (
            <Tooltip>
              <Tooltip.Element className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-50">
                <FiShoppingBag className="stroke-gray-100" size={14} />
              </Tooltip.Element>
              <Tooltip.Body bgColorClass="bg-white" textColorClass="text-midnight-100">
                This product is a bundle
              </Tooltip.Body>
            </Tooltip>
          )}

          {subscriptionsEnabled && sku.stats.subscriptions && (
            <div className="pl-0.5">{subscriptionIndicator}</div>
          )}
          <div className="flex">
            <span className="text-white/70">Variant:&nbsp;</span>
            <span className="inline-block max-w-[248px] truncate">{sku.variantName}</span>
          </div>
          <div>
            <span className="text-white/70">SKU:&nbsp;</span>
            <span className="uppercase">{sku.sku}</span>
          </div>

          {sku.stats.replenishmentStatus && (
            <div className="flex items-center text-xs uppercase">
              <div
                className={classnames(
                  replenishmentColorsMap[sku.stats.replenishmentStatus],
                  'h-3 w-3 rounded-full border',
                )}
              />
              <span className="ml-1 font-bold">
                {sku.stats.replenishmentStatus.replace('replenish', 'replenish ')}
              </span>
            </div>
          )}
        </div>
        {isComponent && (
          <div className="mt-4 space-x-5">
            <span className="inline-block whitespace-nowrap rounded bg-white px-2 py-1 text-xs font-bold uppercase text-purple-100">
              {sku.type}
            </span>
          </div>
        )}
        <div className="mt-4 flex items-center space-x-5 text-sm font-bold leading-none">
          {sku.vendor && (
            <div>
              <span className="text-white/70">Primary Vendor:&nbsp;</span>
              {sku.vendor.name}
            </div>
          )}
          {secondaryVendors.length > 0 && (
            <div>
              <span>|</span>
              <Menu className="relative inline-flex" as="div">
                {({ open }) => (
                  <>
                    <Menu.Button
                      className={classnames(
                        open && 'bg-purple-110',
                        'group flex items-center rounded-full px-4 py-1.5 text-sm font-bold transition-colors hover:bg-purple-110 focus:outline-none',
                      )}
                    >
                      <span className="text-white/70">Other Vendors:&nbsp;</span>
                      <div className="flex divide-x-2">{secondaryVendors.length}</div>
                      <FiChevronDown
                        size={20}
                        className={classnames(open && 'rotate-180', 'ml-2 transition-transform')}
                      />
                    </Menu.Button>
                    <Menu.Items
                      as="ul"
                      className="absolute z-20 mt-10 max-w-[210px] rounded-lg bg-white py-2 text-sm shadow-md"
                    >
                      {secondaryVendors.map((vendor) => (
                        <Menu.Item
                          key={vendor._id}
                          as="li"
                          className="flex py-2 text-midnight-100 first:rounded-t-md last:rounded-b-md"
                        >
                          <span
                            className="truncate pl-4 pr-8 text-xs font-bold capitalize"
                            title="Secondary vendor"
                          >
                            {vendor.name}&nbsp;
                          </span>
                          <div className="absolute right-1 -mt-1">
                            <DeleteButton onDelete={() => handleRemoveVendor(vendor._id)} />
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </>
                )}
              </Menu>
            </div>
          )}
        </div>

        <div className="mt-4 flex space-x-8">
          <div className="w-1/4 border-t-2 border-white/50 pt-3 text-sm font-bold">
            <p>Units on Hand</p>
            <div className="mt-4 text-2xl">{sku.inventoryQuantity}</div>
          </div>
          <div className="w-1/4 border-t-2 border-white/50 pt-3 text-sm font-bold">
            <p>Units Target</p>
            <div className="mt-4 text-2xl">{sku.stats.targetInventoryQuantity}</div>
          </div>
          {!isComponent && (
            <div className="w-1/4 border-t-2 border-red-50 pt-3 text-sm font-bold">
              <p>Potential Revenue Loss</p>
              <div className="mt-4 inline-block text-2xl">
                {formatCurrency(expectedRevenueLoss, { notation: 'compact' })}
              </div>
            </div>
          )}
          {subscriptionsEnabled && sku.hasAtRiskSubscriptions && sku.stats.subscriptions && (
            <div className="w-1/4 border-t-2 border-red-50 pt-3 text-sm font-bold">
              <div className="flex items-center space-x-2">
                {subscriptionIndicator}
                <span className="pl-1">Reserve Now</span>
              </div>
              <div className="mt-4 flex items-center">
                <span className="mr-2 text-2xl">{subscriptionUnitsToReserve}</span>
                Units
              </div>
            </div>
          )}
        </div>

        <div className="mt-4">
          <Menu className="relative" as="div">
            {({ open }) => (
              <>
                <Menu.Button
                  className={classnames(
                    open && 'bg-purple-110',
                    'group -ml-4 flex items-center rounded-full px-4 py-1.5 text-sm font-bold transition-colors hover:bg-purple-110 focus:outline-none',
                  )}
                >
                  <span className="text-white/70">{pluralize('Source', sku.sources.length)}:</span>
                  <div className="ml-2 flex divide-x-2">
                    {sourcesGroups.map((group) => (
                      <div
                        className="flex items-center px-2 first:pl-0 last:pr-0"
                        key={group.sourceType}
                      >
                        <span className="capitalize">{formatSourceName(group)}</span>
                        <div
                          className={classnames(
                            open ? 'bg-purple-100' : 'bg-purple-110',
                            'ml-2 flex h-5 w-5 items-center justify-center rounded-full text-xs transition-colors group-hover:bg-purple-100',
                          )}
                        >
                          {group.count}
                        </div>
                      </div>
                    ))}
                  </div>
                  <FiChevronDown
                    size={20}
                    className={classnames(open && 'rotate-180', 'ml-2 transition-transform')}
                  />
                </Menu.Button>
                <Menu.Items
                  as="ul"
                  className="absolute z-20 -ml-4 mt-4 rounded-lg bg-white text-sm shadow-md"
                >
                  {sku.sources.map((source) => {
                    if (source.sourceUrl) {
                      return (
                        <Menu.Item
                          key={source.sourceId}
                          as="li"
                          className="text-midnight-100 first:rounded-t-md last:rounded-b-md hover:bg-purple-100 hover:text-white"
                        >
                          <a
                            href={source.sourceUrl}
                            className="flex items-center px-4 py-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="font-bold capitalize">
                              {formatSourceName(source)}&nbsp;
                            </span>
                            {formatSourceDescription(source.sourceId, account.sources)}
                            <FiExternalLink className="ml-2" size={12} />
                          </a>
                        </Menu.Item>
                      );
                    }
                    return (
                      <Menu.Item
                        key={source.sourceId}
                        as="li"
                        className="px-4 py-2 text-midnight-100 first:rounded-t-md last:rounded-b-md"
                      >
                        <span className="font-bold capitalize">
                          {formatSourceName(source)}&nbsp;
                        </span>
                        {formatSourceDescription(source.sourceId, account.sources)}
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </>
            )}
          </Menu>
        </div>

        {(sku.isWithoutSku || !sku.vendor) && (
          <div className="mt-4 flex items-center">
            <span className="inline-flex items-center rounded-lg bg-red-10 px-4 py-3 text-sm font-medium text-red-100 shadow">
              <FiAlertTriangle size={18} />
              <span className="ml-2">
                {missingSkuOrVendorCopy(sku.isWithoutSku, !sku.vendor)}&nbsp;
                {sku.sourceType === 'shopify' ? (
                  <a
                    className="font-bold underline"
                    target="_blank"
                    href={sku.sources.find((s) => s.sourceType === 'shopify')?.sourceUrl}
                    rel="noreferrer"
                  >
                    in your Shopify store
                  </a>
                ) : (
                  <span>
                    in your <span className="capitalize">{formatSourceName(sku)}</span> account
                  </span>
                )}
                .
              </span>
            </span>
            <Tooltip className="ml-2">
              <Tooltip.Element className="cursor-default text-xs text-white underline">
                Why do we need this?
              </Tooltip.Element>
              <Tooltip.Body bgColorClass="bg-white" textColorClass="text-midnight-100">
                <article className="z-50 p-3">
                  <h3 className="text-sm font-bold">SKU code</h3>
                  <p className="mt-1 max-w-sm">
                    We use SKU codes to identify products and match them to line items in orders.
                    When you add more than one data source (Shopify, Amazon, Shipbob, etc.) we use
                    SKU codes to cross-reference products between different data sources.
                  </p>
                  <h3 className="mt-3 text-sm font-bold">Vendor</h3>
                  <p className="mt-1 max-w-sm">
                    Without vendor details, you will not be able to create a purchase order. We also
                    use vendor information to give suggestions for recommended purchase orders.
                  </p>
                </article>
              </Tooltip.Body>
            </Tooltip>
          </div>
        )}
      </div>

      {!isComponent && (
        <div className="w-1/4 border-l border-white/20 p-8 text-lg font-bold">
          Prioritization Stats:
          <div className="mt-8 space-y-2">
            <div>
              <div className="text-sm text-white/70">Revenue</div>
              {formatCurrency(sku.stats.totalSkuSales ?? '-')}
            </div>
            <div className="font-normal">
              <div className="text-sm font-bold text-white/70">Units Sold</div>
              <div className="flex items-center">
                <span className="vertical-fix font-bold">
                  {sku.stats.totalQuantitySold + (bundleQuantitySold || 0)}
                </span>
                {sku.bundlesContainingSku.length > 0 && bundleQuantitySold ? (
                  <Tooltip className="ml-1">
                    <Tooltip.Element className="flex items-center">
                      <FiInfo className="stroke-white" size={16} />
                    </Tooltip.Element>
                    <Tooltip.Body bgColorClass="bg-white" textColorClass="text-midnight-100">
                      <div>Product sales: {sku.stats.totalQuantitySold ?? 0}</div>
                      <div>Bundle sales: {bundleQuantitySold ?? 0}</div>
                    </Tooltip.Body>
                  </Tooltip>
                ) : null}
              </div>
            </div>
            <div>
              <div className="text-sm uppercase text-white/70">ltv</div>
              {formatCurrency(sku.ltv ?? '-')}
            </div>
            <div>
              <div className="text-sm uppercase text-white/70">aov</div>
              {formatCurrency(sku.stats.averageOrderValue ?? '-')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Summary.propTypes = {
  expectedRevenueLoss: PropTypes.number.isRequired,
  handleSkuNamePopup: PropTypes.func.isRequired,
  sku: PropTypes.shape({
    type: PropTypes.string,
    bundlesContainingSku: PropTypes.arrayOf(PropTypes.shape({})),
    inventoryQuantity: PropTypes.number,
    isBundle: PropTypes.bool,
    isWithoutSku: PropTypes.bool,
    productName: PropTypes.string,
    ltv: PropTypes.number,
    sku: PropTypes.string,
    sourceType: PropTypes.string,
    hasAtRiskSubscriptions: PropTypes.bool,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        sourceId: PropTypes.string,
        sourceType: PropTypes.string,
        sourceUrl: PropTypes.string,
      }),
    ),
    stats: PropTypes.shape({
      averageOrderValue: PropTypes.number,
      category: PropTypes.string,
      firstOutOfStockDate: PropTypes.string,
      replenishmentStatus: PropTypes.string,
      targetInventoryQuantity: PropTypes.number,
      totalQuantitySold: PropTypes.number,
      totalSkuSales: PropTypes.number,
      velocity: PropTypes.string,
      subscriptions: PropTypes.shape({
        aggregateBundleReserveInventory: PropTypes.number,
        aggregateReserveInventory: PropTypes.number,
        bundleReserveInventoryByDate: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            reserveInventory: PropTypes.number,
          }),
        ),
        reserveInventoryByDate: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            reserveInventory: PropTypes.number,
          }),
        ),
        reserveInventoryHorizon: PropTypes.string,
      }),
    }),
    variantName: PropTypes.string,
    vendor: PropTypes.shape({
      leadTime: PropTypes.number,
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    vendors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
      }),
    ),
    recipe: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleRemoveVendor: PropTypes.func.isRequired,
};

export default Summary;
