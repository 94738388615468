import React from 'react';
import PropTypes from 'prop-types';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAccount } from '../../../AccountProvider.jsx';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GrowthGraph = ({ growthChartData }) => {
  const { formatCurrency } = useAccount();

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
          color: 'white',
          padding: 5,
          font: {
            size: 10,
            lineHeight: 1,
          },
        },
      },
      revenue: {
        type: 'linear',
        display: false,
        position: 'left',
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const { label } = context[0];
            return label.replaceAll(',', ' ');
          },
          label: (context) => {
            const { dataset, raw } = context;
            return `${dataset.label}: ${formatCurrency(raw, {
              notation: 'compact',
              maximumFractionDigits: 2,
            })}`;
          },
        },
      },
    },
  };

  const labels = growthChartData?.map((item) => item.name);
  const sales = growthChartData?.map((item) => item.monthlySales);

  const graphData = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        data: sales,
        borderColor: ['rgb(105, 211, 188)', 'rgb(142, 83, 255)'],
        backgroundColor: ['rgb(105, 211, 188)', 'rgb(142, 83, 255)'],
        borderRadius: 3,
        barThickness: 40,
      },
    ],
  };

  return (
    <div>
      <div className="relative z-10 -ml-7 h-[122px]">
        <Bar options={options} data={graphData} />
      </div>
    </div>
  );
};

GrowthGraph.propTypes = {
  growthChartData: PropTypes.arrayOf(
    PropTypes.shape({
      monthlySales: PropTypes.number,
      name: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default GrowthGraph;
