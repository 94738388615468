import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { FiInfo } from 'react-icons/fi';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import Tooltip from '../Components/Tooltip.jsx';
import { useDocumentTitle } from '../Hooks/index.js';
import { countries } from '../Utils/countries.js';
import { currencies } from '../Utils/currencies.js';

const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($input: UpdatePreferencesInput!) {
    updateAccountPreferences(input: $input) {
      countryCode
      currency
      inventorySafetyMargin
    }
  }
`;

const Inventory = () => {
  const { addAlert } = useAlerts();
  useDocumentTitle('Inventory Settings');

  const [updateAccountPreferences] = useMutation(UPDATE_PREFERENCES);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const onSubmit = async (data) => {
    try {
      await updateAccountPreferences({ variables: { input: data } });
      addAlert('Changes saved successfully', { level: 'success' });
    } catch (error) {
      addAlert('An error has occurred! Please refresh the page and try again.', { error });
    }
  };

  const { account } = useAccount();

  return (
    <div className="col-span-4 text-midnight-100">
      <h5 className="text-xl">Inventory</h5>
      <form className="mt-4 text-xs" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="inventory_safety_margin">
            <div className="flex items-center">
              Inventory Safety Margin
              <Tooltip className="ml-1">
                <Tooltip.Element className="group relative flex h-4 w-4 items-center justify-center rounded-full bg-gray-50">
                  <FiInfo className="stroke-gray-100" size={10} />
                </Tooltip.Element>
                <Tooltip.Body>
                  <div className="max-w-xs">
                    An inventory buffer to prevent a possible stockout. It&apos;s a percentage of
                    the units required for the period of the lead time. If I need 600 units and your
                    Safety Margin is 10%, you&apos;ll need 660 units for the period of the lead
                    time.
                  </div>
                </Tooltip.Body>
              </Tooltip>
            </div>
            <div className="relative">
              <input
                id="inventory_safety_margin"
                type="number"
                step="1"
                min="0"
                className={cn('input pr-8', errors.inventorySafetyMargin && 'input-error')}
                defaultValue={account.preferences.inventorySafetyMargin}
                {...register('inventorySafetyMargin', {
                  valueAsNumber: true,
                  required: 'Please provide inventory safety margin',
                  min: {
                    value: 0,
                    message: "Inventory safety margin value can't be negative",
                  },
                })}
              />
              <span className="absolute right-4 top-4">%</span>
            </div>
          </label>
          <InputErrorMessage message={errors.inventorySafetyMargin?.message} />
        </div>
        <div className="mt-4">
          <label htmlFor="country_code">
            Country Code
            <select
              id="country_code"
              className="input"
              defaultValue={account.preferences.countryCode}
              {...register('countryCode', { required: true })}
            >
              {Object.keys(countries).map((countryCode) => (
                <option value={countryCode} key={countryCode}>
                  {countries[countryCode]}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="mt-4">
          <label htmlFor="currency">
            Currency
            <select
              id="currency"
              className="input"
              defaultValue={account.preferences.currency}
              {...register('currency', { required: true })}
            >
              {currencies.map((currency) => (
                <option value={currency} key={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="mt-8">
          <Button type="submit" fullWidth variant="primary" label="Save changes" />
        </div>
      </form>
    </div>
  );
};

export default Inventory;
