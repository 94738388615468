import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Components/Tooltip.jsx';

const InventoryBreakdown = ({ children, locations = [], align }) => (
  <Tooltip>
    <Tooltip.Element className="w-full max-w-[92px]">{children}</Tooltip.Element>
    {locations.length > 0 && (
      <Tooltip.Body align={align}>
        <ul className="pl-2">
          {[...locations]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ _id, name, inventoryQuantity }) => (
              <li className="mt-2 flex font-semibold first:mt-0" key={_id}>
                <span className="mr-5 capitalize">{name}</span>
                <span className="ml-auto">{inventoryQuantity ?? '-'}</span>
              </li>
            ))}
        </ul>
      </Tooltip.Body>
    )}
  </Tooltip>
);

InventoryBreakdown.propTypes = {
  children: PropTypes.node.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

InventoryBreakdown.defaultProps = {
  align: 'center',
};

export default InventoryBreakdown;
