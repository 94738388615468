import env from './env.js';

export const apiUrl = env.VITE_PUBLIC_API_URL;

// It's currently not possible to mock WebSockets in Node, so
// we disable these during tests
export const disableWss = env.VITE_PUBLIC_DISABLE_WSS;

export const sentry = {
  dsn: env.VITE_PUBLIC_SENTRY_DSN_PUBLIC,
  tracingOrigin: env.VITE_PUBLIC_SENTRY_TRACING_ORIGIN,
  tracingSampleRate: Number(env.VITE_PUBLIC_SENTRY_TRACE_SAMPLE_RATE || 1.0),
};

export const shopify = {
  appUrl: env.VITE_PUBLIC_SHOPIFY_APP_URL,
};

export const skubana = {
  appUrl: env.VITE_PUBLIC_SKUBANA_APP_URL,
};

export const linnworks = {
  appUrl: env.VITE_PUBLIC_LINNWORKS_APP_URL,
};

export const planning = {
  videoUrl: 'https://www.youtube-nocookie.com/embed/vHdorvubGw8',
};

export const marketingEvents = {
  videoUrl: 'https://www.youtube-nocookie.com/embed/5MzgCBZnqkQ',
};

export const plannedProducts = {
  videoUrl: 'https://www.youtube-nocookie.com/embed/BPhDirbxFhg',
};

export const partnerStack = {
  publicKey: env.VITE_PUBLIC_PARTNER_STACK_PUBLIC_KEY,
};

export const growthbook = {
  apiEndpoint:
    env.VITE_PUBLIC_GROWTHBOOK_API_ENDPOINT ||
    'https://cdn.growthbook.io/api/features/key_deve_81d0ae25a6cd908a',
};

export const fileUploadOptions = {
  maxFileSize: env.VITE_PUBLIC_BUNDLE_MAX_FILESIZE || 100000,
};
