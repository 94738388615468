import React from 'react';
import PropTypes from 'prop-types';
import { addDays, subDays } from 'date-fns';
import pluralize from 'pluralize';

const isBetweenDates = (fromDate, toDate) => (date) => date > fromDate && date <= toDate;

const CustomTooltip = ({
  active,
  payload,
  transfers,
  sku,
  showPageViews,
  hasBundles,
  hasSubscriptions,
}) => {
  if (!payload || !active) return null;

  const {
    date,
    weeklyPageViews,
    individualWeeklySales,
    bundleWeeklySales,
    individualWeeklySalesPrediction,
    bundleWeeklySalesPrediction,
    inventoryQuantity,
    weeklySubscriptionSales,
    weeklySubscriptionSalesPrediction,
  } = payload[0].payload;

  if (date === new Date().toISOString().substring(0, 10)) return null;

  const yesterday = subDays(new Date(), 1).toISOString().substring(0, 10);
  const tomorrow = addDays(new Date(), 1).toISOString().substring(0, 10);

  const realDate = [yesterday, tomorrow].includes(date)
    ? new Date().toISOString().substring(0, 10)
    : date;

  const isInPeriod = isBetweenDates(subDays(new Date(realDate), 7), new Date(realDate));

  const totalIncomingQuantity = transfers
    .filter(({ expectedDeliveryDate }) => isInPeriod(new Date(expectedDeliveryDate)))
    .reduce((acc, { items }) => acc + items.find((item) => item.sku === sku).quantity, 0);

  return (
    <div className="rounded-lg bg-midnight-100/90 p-2 text-xs text-white shadow">
      <div className="text-sm font-bold">
        {new Date(realDate).toLocaleDateString(navigator.language, {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}
      </div>
      {typeof individualWeeklySales === 'number' && (
        <div>
          <div className="mt-3 font-bold">Actual Units Sold:</div>
          Individual product: <span className="font-bold">{individualWeeklySales}</span>
        </div>
      )}
      {typeof bundleWeeklySales === 'number' && hasBundles && (
        <div>
          Bundles: <span className="font-bold">{bundleWeeklySales}</span>
        </div>
      )}
      {typeof weeklySubscriptionSales === 'number' && hasSubscriptions && (
        <div>
          Subscriptions: <span className="font-bold">{weeklySubscriptionSales}</span>
        </div>
      )}
      {typeof individualWeeklySales === 'number' &&
        ((typeof bundleWeeklySales === 'number' && hasBundles) ||
          typeof weeklySubscriptionSales === 'number') && (
          <div>
            Total:{' '}
            <span className="font-bold">
              {individualWeeklySales + bundleWeeklySales + weeklySubscriptionSales}
            </span>
          </div>
        )}
      {typeof individualWeeklySalesPrediction === 'number' && (
        <div>
          <div className="mt-3 font-bold">Forecasted Units Sold:</div>
          Individual product: <span className="font-bold">{individualWeeklySalesPrediction}</span>
        </div>
      )}
      {typeof bundleWeeklySalesPrediction === 'number' && hasBundles && (
        <div>
          Bundles: <span className="font-bold">{bundleWeeklySalesPrediction}</span>
        </div>
      )}
      {typeof weeklySubscriptionSalesPrediction === 'number' && hasSubscriptions && (
        <div>
          Subscriptions: <span className="font-bold">{weeklySubscriptionSalesPrediction}</span>
        </div>
      )}
      {typeof individualWeeklySalesPrediction === 'number' &&
        ((typeof bundleWeeklySalesPrediction === 'number' && hasBundles) ||
          typeof weeklySubscriptionSalesPrediction === 'number') && (
          <div>
            Total:&nbsp;
            <span className="font-bold">
              {individualWeeklySalesPrediction +
                bundleWeeklySalesPrediction +
                weeklySubscriptionSalesPrediction}
            </span>
          </div>
        )}
      {showPageViews && typeof weeklyPageViews === 'number' && (
        <div className="mt-3 font-bold">
          Page {pluralize('view', weeklyPageViews)}: {weeklyPageViews}
        </div>
      )}
      {typeof inventoryQuantity === 'number' && (
        <div className="mt-3 font-bold">Forecasted units on hand: {inventoryQuantity}</div>
      )}
      {typeof individualWeeklySalesPrediction === 'number' && totalIncomingQuantity !== 0 && (
        <div className="mt-3 font-bold">
          Incoming {pluralize('unit', totalIncomingQuantity)}: {totalIncomingQuantity}
        </div>
      )}
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        date: PropTypes.string,
        weeklyPageViews: PropTypes.number,
        individualWeeklySales: PropTypes.number,
        bundleWeeklySales: PropTypes.number,
        individualWeeklySalesPrediction: PropTypes.number,
        bundleWeeklySalesPrediction: PropTypes.number,
        inventoryQuantity: PropTypes.number,
        weeklySubscriptionSales: PropTypes.number,
        weeklySubscriptionSalesPrediction: PropTypes.number,
      }),
    }),
  ),
  transfers: PropTypes.arrayOf(
    PropTypes.shape({
      expectedDeliveryDate: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  sku: PropTypes.string.isRequired,
  showPageViews: PropTypes.bool.isRequired,
  hasBundles: PropTypes.bool.isRequired,
  hasSubscriptions: PropTypes.bool.isRequired,
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

export default CustomTooltip;
