/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FaSortAmountDown } from 'react-icons/fa';
import {
  FiAlertTriangle,
  FiArrowLeft,
  FiChevronDown,
  FiChevronUp,
  FiSettings,
} from 'react-icons/fi';
import { Listbox, Transition } from '@headlessui/react';
import classnames from 'classnames';
import { useFeature } from '@growthbook/growthbook-react';
import { isSameDay } from 'date-fns';
import { DEFAULT_PAGE_SIZE } from '../../shared/skus.js';
import { hasSupportFor } from '../../shared/sources.js';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import PageHeader from '../Components/PageHeader.jsx';
import { Pagination } from '../Components/Pagination.jsx';
import Search from '../Components/Search.jsx';
import Spinner from '../Components/Spinner.jsx';
import Tooltip from '../Components/Tooltip.jsx';
import { getDemoCategories, getDemoSkus } from '../Demo/skus.js';
import { useCmdDown, useDocumentTitle, useQueryParams } from '../Hooks/index.js';
import CreateDraftPopupButton from '../PurchaseOrders/CreateDraftPopup.jsx';
import DaysLeft from './DaysLeft.jsx';
import EmptyResult from './EmptyResult.jsx';
import InventoryBreakdown from './InventoryBreakdown.jsx';
import OnHandTarget from './OnHandTarget.jsx';
import ProductLink from './ProductLink.jsx';
import { SKU_CATEGORIES, SKUS, SKUS_WITH_ERRORS } from './Queries.js';
import RestoreProductButton from './RestoreProductButton.jsx';
import SkusReplenishmentSettings from './SkusReplenishmentSettings.jsx';
import SkusSubscriptionTable from './SkusSubscriptionTable.jsx';
import { getSkuPagePath, isMergedSku } from './utils/helpers.js';
import WowChart from './WowChart.jsx';
import WowPercentage from './WowPercentage.jsx';

const REPLENISHMENT_NAVS = {
  all: 'All',
  replenishNow: 'Replenish Now',
  replenishSoon: 'Replenish Soon',
  healthy: 'Healthy Products',
  overstocked: 'Overstocked',
  subscriptions: 'Subscriptions',
  archived: 'Archived',
};

const viewOptions = { list: 'List', categories: 'Grouped by Categories' };

const getSortKeyForColumn = (columnName) => {
  switch (columnName) {
    case 'Product':
      return 'sku';
    case 'On Hand':
      return 'inventoryQuantity';
    case 'Target':
      return 'statsTargetInventoryQuantity';
    case 'In Transit':
      return 'statsIncomingInventoryQuantity';
    case 'Days Left':
      return 'statsDaysLeft';
    case 'OOS':
      return 'statsFirstOutOfStockDate';
    case 'Added':
      return 'statsReplenishmentStatusUpdatedAt';
    case 'In Production':
      return 'statsInProductionInventoryQuantity';
    default:
      return '';
  }
};

const SkuReplenishmentRow = ({
  sku,
  isSettingsActive,
  handleSkuSelectedChange,
  selectedSkus,
  showDateAddedColumn,
  selectedLocationId,
}) => {
  const { account } = useAccount();

  const cmdDown = useCmdDown();

  const navigate = useNavigate();
  const pathname = useLocation();

  const isMerged = isMergedSku(account, sku.sku);
  const skuMergedTo = isMerged ? account.skuMerges.find((s) => s.skuFrom === sku.sku).skuTo : null;
  const isArchived = isMerged || sku.isArchived;

  const updatedAt = new Date(sku.stats.replenishmentStatusUpdatedAt || sku.updatedAt);
  const statusUpdatedAt = isSameDay(new Date(), updatedAt)
    ? 'Today'
    : updatedAt.toLocaleDateString(navigator.language, { month: 'short', day: 'numeric' });

  const skuMetrics = selectedLocationId
    ? { ...sku.inventoryBreakdown.find((location) => location._id === selectedLocationId) }
    : {
        inventoryQuantity: sku.inventoryQuantity,
        targetInventoryQuantity: sku.stats.targetInventoryQuantity,
        incomingInventoryQuantity: sku.stats.incomingInventoryQuantity,
        inProductionInventoryQuantity: sku.stats.inProductionInventoryQuantity,
        daysLeft: sku.stats.daysLeft,
        firstOutOfStockDate: sku.stats.firstOutOfStockDate,
      };

  const handleClick = () => {
    if (isSettingsActive) {
      if (!isMerged) handleSkuSelectedChange(sku);
      return;
    }
    if (isArchived) return;
    if (cmdDown) {
      window.open(getSkuPagePath(sku), '_blank');
    } else {
      navigate(getSkuPagePath(sku), { state: { fromPath: pathname } });
    }
  };

  const handleMouseDown = ({ button }) => {
    if (isSettingsActive || isArchived) return;
    if (button === 1) {
      window.open(getSkuPagePath(sku), '_blank');
    }
  };

  return (
    <tr
      className={classnames('group contents', !isArchived && 'cursor-pointer')}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <td
        className="h-[4.5rem] rounded-l pl-3 pr-5 group-hover:bg-leafy-10"
        onClick={!isSettingsActive ? (e) => e.stopPropagation() : undefined}
      >
        <div className="flex items-center space-x-5 ">
          <div className="flex items-center space-x-5 ">
            <ProductLink
              sku={sku}
              isSettingsActive={isSettingsActive}
              isArchivedView={isArchived}
              selectedSkus={selectedSkus}
              pathname={pathname}
              isRankVisible
            />
          </div>
        </div>
      </td>
      {!isArchived && (
        <>
          <td className="flex flex-col items-center justify-center group-hover:bg-leafy-10">
            <WowChart weeklySales={sku.weeklySales} />
            <div className="mt-1">
              <WowPercentage weeklySales={sku.weeklySales} />
            </div>
          </td>
          <td className="flex items-center justify-center group-hover:bg-leafy-10">
            {!skuMetrics.inventoryQuantity && !skuMetrics.targetInventoryQuantity ? (
              '-'
            ) : (
              <InventoryBreakdown locations={selectedLocationId ? [] : sku.inventoryBreakdown}>
                <div className="w-[92px] text-center">
                  <OnHandTarget
                    quantity={skuMetrics.inventoryQuantity}
                    target={skuMetrics.targetInventoryQuantity}
                    format={{ notation: 'compact' }}
                  />
                </div>
              </InventoryBreakdown>
            )}
          </td>
          <td className="flex items-center justify-center text-xs group-hover:bg-leafy-10">
            {skuMetrics.inProductionInventoryQuantity >= 0
              ? skuMetrics.inProductionInventoryQuantity
              : '-'}
          </td>
          <td className="flex items-center justify-center text-xs group-hover:bg-leafy-10">
            {skuMetrics.incomingInventoryQuantity >= 0 ? skuMetrics.incomingInventoryQuantity : '-'}
          </td>
          <td className="flex items-center justify-center text-xs group-hover:bg-leafy-10">
            <DaysLeft daysLeft={skuMetrics.daysLeft} />
          </td>
          {selectedLocationId ? null : (
            <td className="flex items-center justify-center text-xs group-hover:bg-leafy-10">
              {skuMetrics.firstOutOfStockDate &&
                new Date(skuMetrics.firstOutOfStockDate).toLocaleDateString()}
            </td>
          )}
        </>
      )}
      {isArchived && (
        <>
          <td className="col-span-2 flex items-center group-hover:bg-leafy-10">
            {isMerged && (
              <p>
                This product has been merged into:&nbsp;
                <Link
                  to={getSkuPagePath(skuMergedTo)}
                  state={{ fromPath: pathname }}
                  className="font-bold text-purple-100 underline"
                >
                  {skuMergedTo}
                </Link>
              </p>
            )}
          </td>
          <td className="col-span-4 flex items-center justify-end rounded-r pr-12 text-xs group-hover:bg-leafy-10">
            <RestoreProductButton account={account} sku={sku} isMerged={isMerged} />
          </td>
        </>
      )}
      {showDateAddedColumn && (
        <td className="flex items-center justify-center text-xs group-hover:bg-leafy-10">
          {statusUpdatedAt}
        </td>
      )}
    </tr>
  );
};

SkuReplenishmentRow.propTypes = {
  sku: PropTypes.shape({
    sku: PropTypes.string,
    productName: PropTypes.string,
    variantName: PropTypes.string,
    stats: PropTypes.shape({
      category: PropTypes.string,
      velocity: PropTypes.string,
      targetInventoryQuantity: PropTypes.number,
      incomingInventoryQuantity: PropTypes.number,
      daysLeft: PropTypes.number,
      firstOutOfStockDate: PropTypes.string,
      inProductionInventoryQuantity: PropTypes.number,
      replenishmentStatusUpdatedAt: PropTypes.string,
      locations: PropTypes.arrayOf(PropTypes.shape()),
    }),
    isBundle: PropTypes.bool,
    weeklySales: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    inventoryQuantity: PropTypes.number,
    sources: PropTypes.arrayOf(PropTypes.shape()),
    inventoryBreakdown: PropTypes.arrayOf(PropTypes.shape()),
    isArchived: PropTypes.bool,
    updatedAt: PropTypes.string,
  }).isRequired,
  isSettingsActive: PropTypes.bool.isRequired,
  handleSkuSelectedChange: PropTypes.func.isRequired,
  selectedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      productName: PropTypes.string,
      variantId: PropTypes.string,
    }),
  ).isRequired,
  showDateAddedColumn: PropTypes.bool.isRequired,
  selectedLocationId: PropTypes.string,
};

SkuReplenishmentRow.defaultProps = {
  selectedLocationId: null,
};

const SkuReplenishmentCategoryRow = ({
  category,
  isInitiallyOpen,
  isSettingsActive,
  handleSkuSelectedChange,
  selectedSkus,
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [after, setAfter] = useState(undefined);
  const [before, setBefore] = useState(undefined);

  const [getSkusForCategory, { data, previousData, loading }] = useLazyQuery(SKUS, {
    variables: {
      productType: category,
      after,
      before,
    },
  });

  const skus = data?.skus || previousData?.skus;

  useEffect(() => {
    if (isOpen) getSkusForCategory();
  }, [isOpen]);

  return (
    <>
      <tr className="contents" onClick={() => setIsOpen(!isOpen)}>
        <td
          className={classnames(
            'col-[span_7] flex h-8 items-center rounded-lg bg-purple-10 px-3 text-sm font-bold text-purple-100',
            isOpen && skus && 'mb-1',
          )}
        >
          <FiChevronDown
            className={classnames(
              'h-6 w-6 transition-transform duration-200 ease-out',
              isOpen && '-rotate-180',
            )}
            aria-hidden="true"
          />
          <div className="vertical-fix ml-3 flex">
            {category}
            {loading && <Spinner className="ml-4 h-4 w-4" />}
          </div>
        </td>
      </tr>

      {isOpen &&
        skus?.edges?.map(({ node: sku }) => (
          <SkuReplenishmentRow
            key={sku.sku}
            sku={sku}
            isSettingsActive={isSettingsActive}
            handleSkuSelectedChange={handleSkuSelectedChange}
            selectedSkus={selectedSkus}
          />
        ))}

      {isOpen && skus && (skus.pageInfo.hasNextPage || skus.pageInfo.hasPreviousPage) && (
        <tr className="contents">
          <td className="col-[span_7] mb-2">
            <Pagination
              items={skus}
              isLoading={loading}
              size={24}
              className="flex justify-center space-x-2"
            >
              <Pagination.PrevButton
                onClick={(cursor) => {
                  setBefore(cursor);
                  setAfter(undefined);
                }}
              />
              <Pagination.NextButton
                onClick={(cursor) => {
                  setAfter(cursor);
                  setBefore(undefined);
                }}
              />
            </Pagination>
          </td>
        </tr>
      )}
    </>
  );
};

SkuReplenishmentCategoryRow.propTypes = {
  category: PropTypes.string.isRequired,
  isInitiallyOpen: PropTypes.bool,
  isSettingsActive: PropTypes.bool.isRequired,
  handleSkuSelectedChange: PropTypes.func.isRequired,
  selectedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      productName: PropTypes.string,
      variantId: PropTypes.string,
    }),
  ).isRequired,
};

SkuReplenishmentCategoryRow.defaultProps = {
  isInitiallyOpen: false,
};

const SkuReplenishment = () => {
  useDocumentTitle('Products | Replenishment');

  const { account, user, locations: locationData } = useAccount();
  const { addAlert } = useAlerts();

  const subscriptionsEnabled =
    useFeature('subscriptions').on &&
    account.sources?.some(({ sourceType }) => hasSupportFor(sourceType, 'subscriptions'));

  const skuSettingsEnabled = useFeature('sku-settings-index-page').on;

  const [queryParams, setQueryParams] = useQueryParams([
    'view',
    'location',
    'category',
    'skusAfter',
    'skusBefore',
    'categoriesAfter',
    'categoriesBefore',
    'search',
    'sortKey',
    'reverse',
  ]);

  const view = queryParams.category || queryParams.view !== 'categories' ? 'list' : 'categories';

  const { data: syncErrors } = useQuery(SKUS_WITH_ERRORS, {
    variables: {
      isWithSyncErrors: true,
      first: 1,
    },
  });

  const replenishmentNavs = {
    ...REPLENISHMENT_NAVS,
    ...(syncErrors?.skus?.edges?.length && { syncErrors: 'Sync issues' }),
  };

  if (!subscriptionsEnabled) delete replenishmentNavs.subscriptions;
  if (skuSettingsEnabled) {
    delete replenishmentNavs.archived;
    delete replenishmentNavs.syncErrors;
  }

  const [getSkus, { data: dataSkus, previousData: previousDataSkus, loading: loadingSkus }] =
    useLazyQuery(SKUS, {
      variables: {
        after: queryParams.skusAfter,
        before: queryParams.skusBefore,
        search: queryParams.search,
        sortKey: queryParams.sortKey || 'sku',
        reverse: queryParams.reverse === 'true',
        category: ['archived', 'syncErrors', 'subscriptions'].includes(queryParams.category)
          ? undefined
          : queryParams.category,
        // Pass the location variable only if there's a category too so that we don't filter the main list
        // but only when you click on e.g. "Replenish now"
        location: (queryParams.category && queryParams.location) || undefined,
        isArchived: queryParams.category === 'archived',
        isWithSyncErrors: queryParams.category === 'syncErrors',
      },
    });

  // Demo data is loaded here if first sku sync has not completed
  let skus = dataSkus?.skus || previousDataSkus?.skus;
  if (!loadingSkus && !skus?.edges?.length && !account.onboarding?.hasSkusSyncCompleted) {
    skus = getDemoSkus();
  }

  const [
    getSkuCategories,
    {
      data: dataSkuCategories,
      previousData: previousDataSkuCategories,
      loading: loadingSkuCategories,
    },
  ] = useLazyQuery(SKU_CATEGORIES, {
    variables: {
      after: queryParams.categoriesAfter,
      before: queryParams.categoriesBefore,
    },
  });

  // Demo data is loaded here if first sku sync has not completed
  let skuCategories = dataSkuCategories?.skuCategories || previousDataSkuCategories?.skuCategories;
  if (!loadingSkuCategories && !skuCategories?.edges.length && !account.onboarding?.hasSkusSynced) {
    skuCategories = getDemoCategories();
  }

  useEffect(() => {
    getSkuCategories();
    if (view === 'list') {
      getSkus();
    }
  }, [view]);

  useEffect(() => {
    if (
      dataSkuCategories?.skuCategories?.edges?.length === 1 &&
      dataSkuCategories.skuCategories.edges[0].node.name === 'Uncategorized'
    ) {
      setQueryParams({ view: 'list' });
    }
  }, [dataSkuCategories]);

  const [isSettingsActive, setIsSettingsActive] = React.useState(false);
  const [displaySettingsOptions, setDisplaySettingsOptions] = React.useState(false);
  const [selectedSkus, setSelectedSkus] = useState([]);

  const handleToggleSettingsMode = React.useCallback(() => {
    setIsSettingsActive(!isSettingsActive);
    setDisplaySettingsOptions(!displaySettingsOptions);
    setSelectedSkus([]);
  }, [isSettingsActive]);

  const handleSkuSelectedChange = (selectedSku) => {
    if (selectedSkus.find(({ sku }) => sku === selectedSku.sku)) {
      setSelectedSkus(selectedSkus.filter(({ sku }) => sku !== selectedSku.sku));
    } else {
      if (selectedSkus.length === DEFAULT_PAGE_SIZE) {
        addAlert(`Cannot edit more than ${DEFAULT_PAGE_SIZE} products at once.`, {
          level: 'warning',
        });
        return;
      }
      setSelectedSkus([...selectedSkus, selectedSku]);
    }
  };

  const handleSearch = (searchQuery) => {
    setQueryParams({
      skusAfter: undefined,
      skusBefore: undefined,
      categoriesAfter: undefined,
      categoriesBefore: undefined,
      search: searchQuery || undefined,
      sortKey: searchQuery ? 'score' : 'sku',
      reverse: searchQuery ? true : undefined,
      category: queryParams.category,
    });
  };

  const handleSort = (sortKey) => {
    const { reverse, sortKey: querySortKey, search, category } = queryParams;
    setQueryParams({
      skusAfter: undefined,
      skusBefore: undefined,
      categoriesAfter: undefined,
      categoriesBefore: undefined,
      search,
      sortKey,
      reverse: (querySortKey || 'sku') === sortKey ? reverse !== 'true' : sortKey !== 'sku',
      category,
    });
  };

  const getSortableHeader = (name) => {
    if (queryParams.category === 'archived' && name !== 'Product') return '';

    // When filtering by location, disable all sort options except by sku
    if (queryParams.location && name !== 'Product') return name;

    const reverse = queryParams.reverse === 'true';
    const querySortKey = queryParams.sortKey || 'sku';
    if (name !== 'On Hand / Target') {
      const sortKey = getSortKeyForColumn(name);
      if (sortKey) {
        return (
          <button type="button" onClick={() => handleSort(sortKey)}>
            <span className="border-b border-dotted">{name}</span>
            {querySortKey === sortKey && reverse && (
              <FiChevronDown className="inline-block text-purple-100" size={18} />
            )}
            {querySortKey === sortKey && !reverse && (
              <FiChevronUp className="inline-block text-purple-100" size={18} />
            )}
          </button>
        );
      }
      return name;
    }
    if (name === 'On Hand / Target') {
      const headers = name.split(' / ');
      const onHandSortKey = getSortKeyForColumn(headers[0]);
      const targetSortKey = getSortKeyForColumn(headers[1]);
      return (
        <>
          <button type="button" onClick={() => handleSort(onHandSortKey)}>
            <span className="border-b border-dotted">{headers[0]}</span>
            {querySortKey === onHandSortKey && reverse && (
              <FiChevronDown className="inline-block text-purple-100" size={18} />
            )}
            {querySortKey === onHandSortKey && !reverse && (
              <FiChevronUp className="inline-block text-purple-100" size={18} />
            )}
          </button>
          {' / '}
          <button type="button" onClick={() => handleSort(targetSortKey)}>
            <span className="border-b border-dotted">{headers[1]}</span>
            {querySortKey === targetSortKey && reverse && (
              <FiChevronDown className="inline-block text-purple-100" size={18} />
            )}
            {querySortKey === targetSortKey && !reverse && (
              <FiChevronUp className="inline-block text-purple-100" size={18} />
            )}
          </button>
        </>
      );
    }
    return name;
  };

  const disableCategoryView =
    (skuCategories?.edges?.length === 1 && skuCategories.edges[0].node.name === 'Uncategorized') ||
    queryParams.category ||
    queryParams.search;

  const handleChangeView = (view) => {
    setQueryParams({
      view,
      skusAfter: undefined,
      skusBefore: undefined,
      categoriesAfter: undefined,
      categoriesBefore: undefined,
      search: undefined,
      sortKey: view === 'list' ? 'sku' : undefined,
      reverse: view === 'list' ? false : undefined,
    });
  };

  const handleChangeLocation = (location) => {
    setQueryParams({
      location: location || undefined,
      sortKey: view === 'list' ? 'sku' : undefined,
      reverse: false,
    });
  };

  const getNavUrl = (category) => {
    const params = new URLSearchParams({
      ...(category !== 'all' && { category }),
      ...(queryParams.location && { location: queryParams.location }),
    });

    return `?${params.toString()}`;
  };

  const onSaveSettings = () => {
    setSelectedSkus([]);
  };

  const onToggleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedSkus(skus.edges.map(({ node: sku }) => sku));
    } else {
      setSelectedSkus([]);
    }
  };

  const tableHeaders = [
    'Product',
    'WoW Trend',
    'On Hand / Target',
    'In Production',
    'In Transit',
    'Days Left',
  ];

  if (!queryParams.location) tableHeaders.push('OOS');

  const showDateAddedColumn =
    queryParams.category !== 'archived' && queryParams.category !== undefined;

  if (showDateAddedColumn) tableHeaders.push('Added');

  // dynamic TailwindCSS classes are not supported, complete class strings must be used instead
  const tableGridSetupClasses = {
    3: `grid-cols-[20rem,1fr,1.5fr,repeat(3,1fr)]`,
    4: `grid-cols-[20rem,1fr,1.5fr,repeat(4,1fr)]`,
    5: `grid-cols-[20rem,1fr,1.5fr,repeat(5,1fr)]`,
  };
  const tableGridSetupClass = tableGridSetupClasses[tableHeaders.length - 3];

  const resultData = view === 'categories' ? skuCategories?.edges : skus?.edges;

  const loadingData = loadingSkus || loadingSkuCategories;

  const displaySearch =
    view === 'list' && (resultData?.length > 0 || queryParams.search || queryParams.location);
  const displaySettings =
    !user.isReadOnly && queryParams.category !== 'archived' && resultData?.length > 0;
  const displaySelectAll =
    isSettingsActive &&
    displaySettings &&
    (selectedSkus.length === 0 || selectedSkus.length === skus.edges.length);
  const displayClearSelected =
    isSettingsActive &&
    displaySettings &&
    selectedSkus.length > 0 &&
    selectedSkus.length !== skus.edges.length;

  const sourcesWithInventorySync =
    account.sources?.filter((s) => s.syncInventoryQuantities).map((s) => s.sourceId) ?? [];
  const locationsWithInventorySync = locationData.edges.filter(
    ({ node: l }) =>
      l.sources.some(({ sourceId }) => sourcesWithInventorySync.includes(sourceId)) &&
      !l.hideReplenishment,
  );

  return (
    <>
      <PageHeader text="Products" />
      <div className="mt-20 flex justify-between font-bold text-midnight-75">
        <div className="flex items-center space-x-4 pt-[3px] text-base">
          {Object.entries(replenishmentNavs).map(([navKey, navLabel]) => (
            <Link
              key={navKey}
              to={getNavUrl(navKey)}
              className={classnames(
                'pb-[3px] outline-none ring-purple-100 ring-offset-4 focus-visible:rounded focus-visible:border-none focus-visible:pb-0 focus-visible:ring-2',
                (navKey === queryParams.category || (navKey === 'all' && !queryParams.category)) &&
                  'border-b-2 border-purple-100 text-purple-100',
                navKey === 'syncErrors' && 'flex',
              )}
            >
              {navKey === 'syncErrors' && (
                <Tooltip className="mr-1 flex items-center">
                  <Tooltip.Element className="w-5">
                    <FiAlertTriangle className="text-red-100" />
                  </Tooltip.Element>
                  <Tooltip.Body bgColorClass="bg-red-25">
                    <div className="relative text-red-100">
                      These products are missing some data and need fixing
                    </div>
                  </Tooltip.Body>
                </Tooltip>
              )}
              {navLabel}
            </Link>
          ))}
        </div>
        {account.preferences.forecastEnabled !== false && !user.isReadOnly && (
          <CreateDraftPopupButton />
        )}
      </div>

      {loadingData && queryParams.category !== 'subscriptions' && (
        <div className="mt-7">
          <Spinner />
        </div>
      )}

      {queryParams.category !== 'subscriptions' && !loadingData && (
        <div className="mt-7 text-xs text-midnight-100">
          <div className="rounded-lg bg-white shadow">
            {(resultData?.length > 0 || displaySearch) && (
              <div className="flex items-center justify-between border-b border-gray-50 p-5 pb-4">
                <div className="flex">
                  {queryParams.search && (
                    <div className="group mr-5 flex items-center text-sm font-bold text-purple-100">
                      <button
                        type="button"
                        onClick={() => handleSearch(undefined)}
                        className="flex items-center py-1.5 font-bold"
                      >
                        <FiArrowLeft
                          size={18}
                          className="mr-1 transition-transform group-hover:-translate-x-1"
                        />
                        Back
                      </button>
                    </div>
                  )}

                  {locationsWithInventorySync.length > 1 && queryParams.view !== 'categories' && (
                    <Listbox value={queryParams.location} onChange={handleChangeLocation}>
                      {({ open }) => (
                        <div className="mr-5 flex items-center font-medium">
                          <Listbox.Label className="text-sm font-bold">Location</Listbox.Label>

                          <div className="relative ml-2.5">
                            <Listbox.Button className="flex h-8 items-center rounded-lg border border-midnight-10 pl-3 pr-2.5 font-medium outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-0">
                              {queryParams.location
                                ? locationsWithInventorySync.find(
                                    ({ node: location }) => location._id === queryParams.location,
                                  ).node.name
                                : 'All'}
                              <FiChevronDown
                                className={classnames(
                                  'ml-2 h-4 w-4 text-purple-100 transition-transform duration-200 ease-out',
                                  open && '-rotate-180',
                                )}
                                aria-hidden="true"
                              />
                            </Listbox.Button>
                            <Transition
                              leave="transition-opacity"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full min-w-[10rem] overflow-y-auto rounded-lg border border-midnight-10 bg-white leading-3 shadow-md outline-none">
                                <Listbox.Option
                                  key="all"
                                  value=""
                                  className={({ active }) =>
                                    classnames(
                                      'px-3 py-2 first-of-type:pt-2.5 last-of-type:pb-2.5',
                                      active && 'bg-purple-100 text-white',
                                    )
                                  }
                                >
                                  All
                                </Listbox.Option>
                                {locationsWithInventorySync.map(({ node: location }) => (
                                  <Listbox.Option
                                    key={location._id}
                                    value={location._id}
                                    className={({ active }) =>
                                      classnames(
                                        'px-3 py-2 first-of-type:pt-2.5 last-of-type:pb-2.5',
                                        active && 'bg-purple-100 text-white',
                                      )
                                    }
                                  >
                                    {location.name}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </div>
                      )}
                    </Listbox>
                  )}

                  {!disableCategoryView && (
                    <Listbox value={view} onChange={handleChangeView}>
                      {({ open }) => (
                        <div className="flex items-center font-medium">
                          <Listbox.Label className="text-sm font-bold">Products View</Listbox.Label>

                          <div className="relative ml-2.5">
                            <Listbox.Button className="flex h-8 items-center rounded-lg border border-midnight-10 pl-3 pr-2.5 font-medium outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-purple-100 focus-visible:ring-offset-0">
                              {viewOptions[view || Object.keys(viewOptions)[0]]}
                              <FiChevronDown
                                className={classnames(
                                  'ml-2 h-4 w-4 text-purple-100 transition-transform duration-200 ease-out',
                                  open && '-rotate-180',
                                )}
                                aria-hidden="true"
                              />
                            </Listbox.Button>
                            <Transition
                              leave="transition-opacity"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute mt-1 max-h-60 w-full min-w-[10rem] overflow-y-auto rounded-lg border border-midnight-10 bg-white leading-3 shadow-md outline-none">
                                {Object.entries(viewOptions).map(([value, name]) => (
                                  <Listbox.Option
                                    key={value}
                                    value={value}
                                    className={({ active }) =>
                                      classnames(
                                        'px-3 py-2 first-of-type:pt-2.5 last-of-type:pb-2.5',
                                        active && 'bg-purple-100 text-white',
                                      )
                                    }
                                  >
                                    {name}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </div>
                      )}
                    </Listbox>
                  )}
                </div>

                <div className="flex">
                  {displaySearch && <Search handleSearch={handleSearch} />}
                  {queryParams.search && queryParams.sortKey !== 'score' && (
                    <button
                      type="button"
                      onClick={() => handleSort('score')}
                      className="ml-5 flex items-center text-sm font-bold text-purple-100 hover:text-purple-110"
                    >
                      <FaSortAmountDown className="mr-1" />
                      Sort by Relevance
                    </button>
                  )}
                  {!skuSettingsEnabled && displaySettings && (
                    <Button
                      variant="text"
                      slim
                      label={isSettingsActive ? 'Exit Settings' : 'Settings'}
                      icon={FiSettings}
                      iconSize={16}
                      className="ml-2 text-sm"
                      onClick={handleToggleSettingsMode}
                    />
                  )}
                </div>
              </div>
            )}

            {displaySettingsOptions && displaySettings && (
              <SkusReplenishmentSettings
                selectedSkus={selectedSkus}
                onSaveSettings={onSaveSettings}
                removeSku={handleSkuSelectedChange}
              />
            )}

            <div className="p-5 pt-4 text-xs">
              {resultData?.length === 0 && <EmptyResult />}
              {resultData?.length > 0 && (
                <table className={classnames('grid gap-y-1.5 pt-4', tableGridSetupClass)}>
                  <thead className="contents">
                    <tr className="contents whitespace-nowrap">
                      {tableHeaders.map((item, index) => (
                        <th key={item} className={classnames('h-8', index === 0 && 'text-left')}>
                          {view === 'categories' && `${item}`}
                          {view === 'list' && item !== 'Product' && getSortableHeader(item)}
                          {view === 'list' && item === 'Product' && (
                            <div className="flex items-center">
                              {displayClearSelected && (
                                <button
                                  type="button"
                                  className="ml-[11px] flex items-center font-bold"
                                  onClick={() => setSelectedSkus([])}
                                >
                                  <div className="mr-4 flex h-4 w-4 items-center justify-center rounded bg-purple-100">
                                    <div className="h-0.5 w-2 rounded bg-white" />
                                  </div>
                                </button>
                              )}
                              {displaySelectAll && (
                                <input
                                  type="checkbox"
                                  readOnly
                                  className="ml-[11px] mr-4 rounded border-gray-75 text-purple-100 focus:ring-purple-100"
                                  onChange={(evt) => onToggleSelectAll(evt)}
                                  checked={selectedSkus.length === skus.edges.length}
                                />
                              )}
                              {getSortableHeader(item)}
                              {isSettingsActive && displaySettings && selectedSkus.length > 0 && (
                                <span className="ml-2">({selectedSkus.length} selected)</span>
                              )}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="contents">
                    {view === 'categories'
                      ? skuCategories?.edges?.map(({ node: { name: category } }, index) => (
                          <SkuReplenishmentCategoryRow
                            key={category}
                            category={category}
                            isInitiallyOpen={index === 0}
                            isSettingsActive={isSettingsActive}
                            handleSkuSelectedChange={handleSkuSelectedChange}
                            selectedSkus={selectedSkus}
                          />
                        ))
                      : skus?.edges?.map(({ node: sku }) => (
                          <SkuReplenishmentRow
                            key={sku.sku}
                            sku={sku}
                            isSettingsActive={isSettingsActive && displaySettings}
                            handleSkuSelectedChange={handleSkuSelectedChange}
                            selectedSkus={selectedSkus}
                            showDateAddedColumn={showDateAddedColumn}
                            selectedLocationId={queryParams.location}
                          />
                        ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {view === 'list' && skus && (
            <Pagination
              prefix="skus"
              items={skus}
              isLoading={loadingSkus}
              className="mt-6 flex justify-center space-x-4"
            >
              <Pagination.PrevButton />
              <Pagination.NextButton />
            </Pagination>
          )}

          {view === 'categories' && skuCategories && (
            <Pagination
              prefix="categories"
              items={skuCategories}
              isLoading={loadingSkuCategories}
              className="mt-6 flex justify-center space-x-4"
            >
              <Pagination.PrevButton />
              <Pagination.NextButton />
            </Pagination>
          )}
        </div>
      )}

      {queryParams.category === 'subscriptions' && (
        <>
          <SkusSubscriptionTable isAtRisk />
          <SkusSubscriptionTable />
        </>
      )}
    </>
  );
};

export default SkuReplenishment;
