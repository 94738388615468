import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useFeature } from '@growthbook/growthbook-react';
import { useAccount } from '../AccountProvider.jsx';
import Spinner from '../Components/Spinner.jsx';
import { getDemoSingleSku } from '../Demo/skus.js';
import { useDocumentTitle } from '../Hooks/index.js';
import NotFound from '../Layout/NotFound.jsx';
import SelectSkuNamePopup from './Popups/SelectSkuNamePopup.jsx';
import ProductChart from './Product/Chart/ProductChart.jsx';
import POsWidget from './Product/POsWidget.jsx';
import ProductWidget from './Product/ProductWidget.jsx';
import ReplenishmentTable from './Product/ReplenishmentTable.jsx';
import Summary from './Product/Summary.jsx';
import { calculateLostSales } from './Product/utils.js';
import { REMOVE_VENDOR_SKU, SKU } from './Queries.js';
import Recipe from './Recipe.jsx';

export const missingSkuOrVendorCopy = (withoutSku, withoutVendor) => {
  if (withoutSku && withoutVendor) {
    return 'This product is missing sku code and vendor information. Please add them';
  }
  if (withoutSku) {
    return 'This product is missing sku code. Please add it';
  }
  if (withoutVendor) {
    return 'This product is missing vendor information. Please add it';
  }
  return '';
};

const Product = () => {
  /* eslint-disable-next-line prefer-const */
  let { account, locations } = useAccount();
  const { sku: parameter } = useParams();

  const recipesEnabled = useFeature('sku-recipes').on;
  const [removeVendor] = useMutation(REMOVE_VENDOR_SKU);

  const [skuNamePopupOpen, setSkuNamePopupOpen] = useState(false);

  const toggleSkuNamePopup = React.useCallback(() => {
    setSkuNamePopupOpen(!skuNamePopupOpen);
  }, [skuNamePopupOpen]);

  /* eslint-disable prefer-const */
  let { loading, data, refetch } = useQuery(SKU, {
    variables: { sku: decodeURIComponent(parameter) },
  });
  /* eslint-enable prefer-const */

  // if demo then use demo locations
  if (!loading && !data?.sku && !account.onboarding?.hasSkusSyncCompleted) {
    data = getDemoSingleSku(decodeURIComponent(parameter));
    locations = data.locations;
  }

  useDocumentTitle(data?.sku?.productName || 'Loading...');

  const handleRemoveVendor = async (vendorId) => {
    await removeVendor({
      variables: { sku: data.sku.sku, vendorId },
    });
    refetch();
  };

  const expectedRevenueLoss = React.useMemo(() => {
    if (!data?.sku) return 0;
    return calculateLostSales(data.sku, data.sku.inventoryBreakdown) * data.sku.price;
  }, [data]);

  if (loading || !data) return <Spinner />;

  if (!data.sku) return <NotFound />;

  const inTransitPurchaseOrders = data.sku.purchaseOrders.edges.map(({ node: po }) => ({
    ...po,
    item: po.items.find((item) => item.sku === parameter),
  }));

  return (
    <div>
      <Summary
        sku={data.sku}
        handleSkuNamePopup={toggleSkuNamePopup}
        expectedRevenueLoss={expectedRevenueLoss}
        handleRemoveVendor={handleRemoveVendor}
      />

      <div className="text-midnight-100">
        <div className="self-start bg-white">
          <ProductChart sku={data.sku} />
        </div>

        {account.preferences.forecastEnabled !== false && (
          <ReplenishmentTable
            data={data}
            expectedRevenueLoss={expectedRevenueLoss}
            locations={locations}
          />
        )}

        <div className="mt-6 grid grid-cols-12 gap-7">
          {inTransitPurchaseOrders.length > 0 && (
            <POsWidget
              purchaseOrders={inTransitPurchaseOrders}
              transfers={data.sku.transfers.edges}
            />
          )}
          {data.sku.transfers.edges.length > 0 && (
            <ProductWidget
              items={data.sku.transfers.edges.map(({ node }) => node)}
              title="Incoming Transfers/Shipments"
              parameter={parameter}
            />
          )}
          {recipesEnabled && (
            <Recipe
              sku={data.sku.sku}
              recipeItems={data.sku.recipe?.map(({ __typename, ...item }) => item) ?? []}
              // this is to avoid circular dependencies
              // Chris is working on a PR that moves queries to a separate file
              // so once that's merged we can refetch SKU query inside Recipe component
              onUpdate={() => refetch()}
            />
          )}
        </div>
      </div>

      <SelectSkuNamePopup open={skuNamePopupOpen} onClose={toggleSkuNamePopup} sku={data.sku} />
    </div>
  );
};

export default Product;
