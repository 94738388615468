import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { FiSearch, FiX } from 'react-icons/fi';

const SearchInput = ({ handleSearch, placeHolder }) => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const [query, setQuery] = React.useState(params.get('search') || '');

  const handleFormSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      handleSearch(query);
    },
    [handleSearch, query],
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="relative flex items-center">
        <FiSearch size={14} className="absolute left-4 top-4 text-gray-100" />
        <input
          type="text"
          placeholder={placeHolder ?? 'Search products'}
          className="w-64 rounded-full border-0 px-10 py-3 text-sm shadow focus:ring-2 focus:ring-purple-100"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {query && (
          <FiX
            size={14}
            className="absolute right-4 top-4 w-4 rounded-full text-gray-100 hover:bg-gray-50"
            onClick={() => {
              handleSearch('');
              setQuery('');
            }}
          />
        )}
      </div>
    </form>
  );
};

SearchInput.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
};

SearchInput.defaultProps = {
  placeHolder: null,
};

export default SearchInput;
