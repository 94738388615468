import React from 'react';
import PropTypes from 'prop-types';

const AnvylIcon = ({ className = '', ...props }) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M11.3422 14.4677H15L9.32895 0H5.66424L0 14.4677H3.65787L4.63376 11.9833H10.3731L11.3422 14.4677ZM5.66424 9.36384L7.49317 4.6988L9.32212 9.36384H5.66424Z" />
  </svg>
);

AnvylIcon.propTypes = {
  className: PropTypes.string,
};

AnvylIcon.defaultProps = {
  className: null,
};

export default AnvylIcon;
