import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../config.js';
import LoadingPage from '../Components/LoadingPage.jsx';

const Auth = () => {
  const location = useLocation();

  useEffect(() => {
    const auth = async () => {
      const query = new URLSearchParams(location.search);

      let url = `${apiUrl}/auth/skubana/callback?cid=${query.get('cid')}&code=${query.get('code')}`;

      if (window.localStorage.getItem('token')) url += `&token=${window.localStorage.getItem('token')}`;

      const res = await axios({
        url,
        method: 'GET',
      });

      return window.location.assign(res.data.redirectUrl);
    };

    auth();
  }, []);

  return <LoadingPage />;
};

export default Auth;
