import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { FiCheck, FiInfo } from 'react-icons/fi';
import { RadioGroup, Transition } from '@headlessui/react';
import {
  arrow,
  FloatingArrow,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useAccount } from '../AccountProvider.jsx';
import { useAlerts } from '../Components/AlertsProvider.jsx';
import Button from '../Components/Button.jsx';
import Toggle from '../Components/Toggle.jsx';
import { useDocumentTitle } from '../Hooks/index.js';

const Option = ({ children, value }) => (
  <RadioGroup.Option value={value} className="cursor-default">
    {({ checked }) => (
      <div
        className={`rounded-lg border-2 px-6 py-4 ${
          checked ? 'border-purple-100 bg-purple-10 text-purple-110' : 'border-gray-50 bg-white'
        }`}
      >
        <div className="flex items-center">
          <div className="w-5/6">{children}</div>
          {checked ? (
            <div className="ml-auto rounded-full bg-purple-100 p-1 text-white">
              <FiCheck size={14} />
            </div>
          ) : (
            <div className="ml-auto h-5 w-5 rounded-full border border-gray-75" />
          )}
        </div>
      </div>
    )}
  </RadioGroup.Option>
);

Option.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const UPDATE_NOTIFICATIONS = gql`
  mutation UpdateNotifications($input: NotificationsInput!) {
    updateNotifications(input: $input) {
      replenishmentAlerts {
        enabled
        frequency
      }
      smartPrompts
    }
  }
`;

const SEND_TEST_ALERT = gql`
  mutation TestReplenishmentAlert($input: TestReplenishmentAlertInput!) {
    testReplenishmentAlert(input: $input)
  }
`;

const Notifications = () => {
  useDocumentTitle('Notifications Settings');
  const [search, setSearchParams] = useSearchParams();
  const { addAlert } = useAlerts();
  const { account, user } = useAccount();
  const [enabled, setEnabled] = useState(account.preferences.replenishmentAlerts.enabled);
  const [frequency, setFrequency] = useState(
    account.preferences.replenishmentAlerts.frequency || 'daily',
  );
  const [smartPrompts, setSmartPrompts] = useState(account.preferences.smartPrompts ?? false);
  const [testEmail, setTestEmail] = useState(user.email);

  const [showTooltip, setShowTooltip] = useState(false);
  const arrowRef = useRef(null);
  const { x, y, strategy, refs, context } = useFloating({
    open: showTooltip,
    onOpenChange: (isOpen) => {
      setShowTooltip(isOpen);
      if (!isOpen) {
        setSearchParams({});
      }
    },
    placement: 'right',
    middleware: [
      arrow({
        element: arrowRef,
      }),
      offset(10),
    ],
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS);
  const [sendTestAlert] = useMutation(SEND_TEST_ALERT);

  const handleNotificationsSubmit = async (e) => {
    e.preventDefault();
    await updateNotifications({
      variables: { input: { replenishmentAlerts: { enabled, frequency }, smartPrompts } },
    });
    addAlert('Changes saved successfully', { level: 'success' });
  };

  const handleTestEmailSend = async (e) => {
    e.preventDefault();
    await sendTestAlert({ variables: { input: { email: testEmail } } });
    addAlert('Email sent', { level: 'success' });
  };

  useEffect(() => {
    if (search.has('unsubscribeFromPrompts')) {
      setShowTooltip(true);
    }
  }, [search]);

  return (
    <div className="col-span-4 text-midnight-100">
      <h4 className="text-xl">Notifications</h4>
      <form className="mt-8 text-xs" onSubmit={handleNotificationsSubmit}>
        <Toggle
          label="Replenishment notification emails"
          labelStyle="text-sm"
          spaceBetween
          enabled={enabled}
          onChange={() => setEnabled((value) => !value)}
        />
        <p className="mt-2 w-5/6 text-gray-100">
          Enable this to receive emails containing products that need to be replenished.
        </p>

        {enabled && (
          <div className="mt-8">
            <RadioGroup value={frequency} onChange={setFrequency} className="mt-8 text-xs">
              <RadioGroup.Label className="text-sm">Email frequency</RadioGroup.Label>
              <RadioGroup.Description className="mt-2 w-5/6 text-gray-100">
                Use the options below to select the frequency in which you would like to receive the
                notification emails.
              </RadioGroup.Description>
              <div className="mt-8 space-y-4">
                <Option value="daily">
                  <p className="font-bold">Weekdays</p>
                  <p className="mt-2">Receive an email every weekday.</p>
                </Option>
                <Option value="weekly">
                  <p className="font-bold">Once a week</p>
                  <p className="mt-2">Receive an email every Monday.</p>
                </Option>
              </div>
            </RadioGroup>
          </div>
        )}

        <div className="my-8 h-px w-full bg-gray-50" />
        <div ref={refs.setReference} {...getReferenceProps()}>
          <Toggle
            label="Smart Prompts"
            labelStyle="text-sm"
            spaceBetween
            enabled={smartPrompts}
            onChange={() => setSmartPrompts((value) => !value)}
          />
        </div>
        <p className="mt-2 w-5/6 text-gray-100">
          Receive email notifications about upcoming milestone dates for Purchase Orders and
          Shipments
        </p>
        <Transition
          show={showTooltip}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white/50" />
          <div
            className="absolute flex flex-1 items-center justify-between space-x-2 rounded-lg border-2 border-purple-100 bg-white px-4 py-3 text-xs font-bold"
            ref={refs.setFloating}
            style={{ position: strategy, left: x, top: y }}
            {...getFloatingProps()}
          >
            <FiInfo className="text-purple-75" size={16} />
            <p>Use this toggle to unsubscribe from Smart Prompts emails</p>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              className="
              fill-purple-100 
              [&>path:first-of-type]:stroke-purple-100
              [&>path:last-of-type]:stroke-purple-100
            "
            />
          </div>
        </Transition>

        <div className="mt-8">
          <Button type="submit" fullWidth variant="primary" label="Save changes" />
        </div>
      </form>
      {user.isAdmin && (
        <div className="mt-8 border-t border-gray-50 pt-8">
          <h5 className="text-sm">Test Replenishment Alerts Email</h5>
          <form className="mt-2" onSubmit={handleTestEmailSend}>
            <label htmlFor="replenishmentAlertsTestEmail" className="block text-xs">
              <p className="w-5/6 text-gray-100">
                Test the generated Replenishment Alerts email for this account in your inbox.
              </p>
              <div className="mt-4">
                <input
                  type="email"
                  value={testEmail}
                  onChange={(e) => setTestEmail(e.target.value)}
                  required
                  placeholder="admin@cogsy.com"
                  id="replenishmentAlertsTestEmail"
                  className="input mr-4 mt-0"
                />
                <p className="mt-4 w-5/6 text-xs text-gray-100">
                  If there are no products in Replenish Now or Replenish Soon categories for this
                  account, email will not be sent.
                </p>
                <div className="mt-8">
                  <Button type="submit" variant="primary" label="Send test email" fullWidth />
                </div>
              </div>
            </label>
          </form>
        </div>
      )}
    </div>
  );
};

export default Notifications;
