import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { FaExclamationCircle } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import cn from 'classnames';
import { differenceInCalendarDays } from 'date-fns';
import { useCombobox } from 'downshift';
import { useDebounce } from '../Hooks/index.js';
import { useDemo } from './DemoProvider.jsx';
import InputErrorMessage from './InputErrorMessage.jsx';

const SELECT_ALL = 'Select All';

const SKUS = gql`
  query GetSkus($search: String) {
    skus(search: $search, sortKey: score, first: 100, reverse: true) {
      edges {
        node {
          _id
          sku
          productName
          stats {
            firstSaleAt
          }
        }
      }
    }
  }
`;

const SKU = gql`
  query GetSku($sku: String!) {
    sku(sku: $sku) {
      _id
      productName
      sku
      stats {
        firstSaleAt
      }
    }
  }
`;

const hasProperFirstSaleDate = (sku) =>
  sku?.stats?.firstSaleAt &&
  differenceInCalendarDays(new Date(), new Date(sku?.stats?.firstSaleAt)) >= 90;

const WarningIcon = <FaExclamationCircle className="ml-2 text-yellow-100" />;

const SelectedSkuRow = ({ selectedSku, removeSelectedSku, setSelectedSkusWithWarning }) => {
  const { isDemo } = useDemo();

  const { data: { sku } = {} } = useQuery(SKU, {
    variables: { sku: selectedSku },
  });

  useEffect(() => {
    if (sku && !hasProperFirstSaleDate(sku)) {
      setSelectedSkusWithWarning((prevState) => [...new Set([...prevState, sku.sku])]);
    }
  }, [sku]);

  return (
    <li className="flex h-9 items-center">
      <div className={cn('w-32 shrink-0 truncate font-bold uppercase', isDemo && 'blur-sm')}>
        {selectedSku}
      </div>

      <div className={cn('truncate max-w-[320px] mx-2', isDemo && 'blur-sm')}>
        {sku?.productName || (
          <div className="mb-0.5 h-4 w-48 animate-pulse rounded-md bg-[rgb(195,177,251)]" />
        )}
      </div>
      {!hasProperFirstSaleDate(sku) && WarningIcon}
      <button
        type="button"
        className="group ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full hover:bg-midnight-10 focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2 active:bg-midnight-75"
        onClick={() => removeSelectedSku(selectedSku)}
      >
        <FiPlus strokeWidth={2.5} className="h-4 w-4 rotate-45 group-active:text-gray-10" />
      </button>
    </li>
  );
};

SelectedSkuRow.propTypes = {
  selectedSku: PropTypes.string.isRequired,
  removeSelectedSku: PropTypes.func.isRequired,
  setSelectedSkusWithWarning: PropTypes.func.isRequired,
};

const SkuSelectSearch = ({ selectedSkus, setSelectedSkus, placeholder, errorMessage }) => {
  const { isDemo } = useDemo();

  const [skuQuery, setSkuQuery] = useState('');
  const [lastSelectedItemIndex, setLastSelectedItemIndex] = useState(0);
  const [selectedSkusWithWarning, setSelectedSkusWithWarning] = useState([]);

  const [getSkus, { loading, data, previousData }] = useLazyQuery(SKUS);

  const skus = data?.skus?.edges || previousData?.skus?.edges;

  const [debouncedSkuQuery] = useDebounce(skuQuery);

  useEffect(() => {
    if (skuQuery) {
      getSkus({ variables: { search: debouncedSkuQuery } });
    }
  }, [debouncedSkuQuery]);

  const removeSelectedSku = (skuToRemove) => {
    setSelectedSkus((prevState) => prevState.filter((sku) => sku !== skuToRemove));
    setSelectedSkusWithWarning((prevState) => prevState.filter((sku) => sku !== skuToRemove));
  };

  const handleClearAll = () => {
    setSelectedSkus([]);
    setSelectedSkusWithWarning([]);
  };

  const handleSkuSelectedChange = (skuToChange) => {
    if (skuToChange === SELECT_ALL) {
      // handle Select All action
      if (skus?.every(({ node: sku }) => selectedSkus.includes(sku.sku))) {
        // remove all queried skus from selectedSkus
        setSelectedSkus([
          ...selectedSkus.filter(
            (selectedItem) => !skus?.map(({ node }) => node.sku).includes(selectedItem),
          ),
        ]);
        setSelectedSkusWithWarning((prevState) => [
          ...prevState.filter(
            (selectedSku) => !skus?.map(({ node }) => node.sku).includes(selectedSku),
          ),
        ]);
      } else {
        // add all queried skus to selectedSkus
        setSelectedSkus([...new Set([...selectedSkus, ...skus.map(({ node: { sku } }) => sku)])]);
      }
    } else if (selectedSkus.includes(skuToChange)) {
      removeSelectedSku(skuToChange);
    } else {
      setSelectedSkus((prevState) => [...prevState, skuToChange]);
    }
  };

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    inputValue: skuQuery,
    defaultHighlightedIndex: lastSelectedItemIndex,
    items: [SELECT_ALL, ...(skus || []).map(({ node: { sku } }) => sku)],
    onHighlightedIndexChange: ({ highlightedIndex }) => setLastSelectedItemIndex(highlightedIndex),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
        default:
          break;
      }
      return changes;
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      const selectedSku = selectedItem || inputValue;

      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setSkuQuery(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (selectedSku) {
            handleSkuSelectedChange(selectedSku);
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <div>
      {((skus?.some(({ node: sku }) => !hasProperFirstSaleDate(sku)) &&
        debouncedSkuQuery &&
        isOpen) ||
        selectedSkusWithWarning.length > 0) && (
        <div className="mt-2 flex items-center">
          {WarningIcon}
          <span className="ml-2 text-xs">
            Some products do not have enough data to predict sales accurately.
          </span>
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label {...getLabelProps({ className: 'sr-only' })}>{placeholder || 'Search SKUs'}</label>
      <div
        className={cn(
          'mt-2 relative z-10',
          !errorMessage && 'drop-shadow',
          isOpen && skus?.length > 0 && debouncedSkuQuery && 'drop-shadow-lg',
        )}
      >
        <div>
          <input
            {...getInputProps({
              onFocus: openMenu,
              placeholder,
              className: cn(
                'input mt-2 placeholder-gray-75 text-sm py-3 outline-none shadow-none focus:ring-2 focus:ring-purple-100',
                isOpen &&
                  skus?.length > 0 &&
                  debouncedSkuQuery &&
                  'rounded-b-none ring-2 ring-purple-100',
                errorMessage && !isOpen && 'input-error',
              ),
            })}
          />
          {(skus?.length === 0 || !debouncedSkuQuery || !isOpen) && (
            <InputErrorMessage message={errorMessage} />
          )}
          {loading && skuQuery && (
            <div
              style={{ borderTopColor: 'transparent' }}
              className="absolute right-4 top-3.5 h-4 w-4 animate-spin rounded-full border-2 border-solid border-purple-100"
            />
          )}
        </div>

        <ul
          {...getMenuProps({
            className: cn(
              'absolute bottom-px space-y-px max-h-[130px] translate-y-full w-full rounded-b-lg bg-white border-t border-t-gray-50 overflow-y-scroll',
              (isOpen && skus?.length > 0 && !!debouncedSkuQuery) || 'invisible',
              errorMessage && !isOpen && 'input-error',
            ),
            style: {
              boxShadow: '0px -2px 0 0px white, 0px 0px 0 2px rgb(122, 77, 255)',
            },
          })}
        >
          <li
            {...getItemProps({
              item: SELECT_ALL,
              index: 0,
              onClick: () => handleSkuSelectedChange(SELECT_ALL),
              className: cn(
                'flex items-center hover:bg-leafy-10 h-9 space-x-3 pl-2.5 pr-3',
                highlightedIndex === 0 && 'bg-leafy-10',
              ),
            })}
          >
            <input
              type="checkbox"
              checked={skus?.every(({ node: sku }) => selectedSkus.includes(sku.sku)) || false}
              readOnly
              className="mb-1 rounded border-gray-75 text-purple-100"
            />
            <div className={cn('w-32 truncate font-bold flex-shrink-0', isDemo && 'blur-sm')}>
              Select All
            </div>
          </li>
          {skus?.length > 0 &&
            debouncedSkuQuery &&
            skus.map(({ node: sku }, index) => (
              <li
                key={sku._id}
                {...getItemProps({
                  item: sku.sku,
                  index: index + 1,
                  onClick: () => handleSkuSelectedChange(sku.sku),
                  className: cn(
                    'flex items-center hover:bg-leafy-10 h-9 space-x-3 pl-2.5 pr-3',
                    selectedSkus.includes(sku) && 'bg-leafy-10',
                    highlightedIndex === index + 1 && 'bg-leafy-10',
                  ),
                })}
              >
                <input
                  type="checkbox"
                  checked={selectedSkus.includes(sku.sku)}
                  readOnly
                  className="mb-1 rounded border-gray-75 text-purple-100"
                />
                <div
                  className={cn(
                    'w-32 truncate font-bold uppercase flex-shrink-0',
                    isDemo && 'blur-sm',
                  )}
                >
                  {sku.sku}
                </div>
                <div className={cn('truncate', isDemo && 'blur-sm')}>{sku.productName}</div>
                {!hasProperFirstSaleDate(sku) && WarningIcon}
              </li>
            ))}
        </ul>
      </div>

      {selectedSkus?.length > 0 && (
        <div className="mt-6 rounded bg-purple-10 p-4 pr-3">
          <div className="flex items-center justify-between">
            <div className="font-bold text-purple-100">
              Selected Products ({selectedSkus.length})
            </div>

            <button
              type="button"
              className="inline-flex items-center justify-center rounded-full border-2 border-purple-100 bg-transparent py-1 pl-3 pr-2 text-xs font-bold text-purple-100 ring-offset-white hover:border-purple-90 hover:bg-purple-90 hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-100 focus:ring-offset-2 active:border-purple-110 active:bg-purple-110 disabled:cursor-not-allowed disabled:border-gray-75 disabled:bg-gray-75 disabled:text-gray-100"
              onClick={handleClearAll}
            >
              Clear all
              <FiPlus
                strokeWidth={2.5}
                className="ml-1 h-4 w-4 rotate-45 group-active:text-gray-10"
              />
            </button>
          </div>

          <div className="relative">
            <ul className="-ml-1 mt-2 max-h-[140px] space-y-0.5 overflow-y-auto pb-2.5 pl-1 pr-1.5 pt-1.5">
              {selectedSkus.map(
                (selectedSku) =>
                  selectedSku !== SELECT_ALL && (
                    <SelectedSkuRow
                      key={selectedSku}
                      selectedSku={selectedSku}
                      removeSelectedSku={removeSelectedSku}
                      setSelectedSkusWithWarning={setSelectedSkusWithWarning}
                    />
                  ),
              )}
            </ul>
            <div className="absolute -left-3 top-0 h-2 w-[calc(100%+0.75rem)] bg-gradient-to-b from-purple-10 to-transparent" />
            <div className="absolute -left-3 bottom-0 h-3 w-[calc(100%+0.75rem)] bg-gradient-to-b from-transparent to-purple-10" />
          </div>
        </div>
      )}
    </div>
  );
};

SkuSelectSearch.propTypes = {
  selectedSkus: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedSkus: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

SkuSelectSearch.defaultProps = {
  placeholder: '',
  errorMessage: '',
};

export default SkuSelectSearch;
