import React, { useMemo, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FiCheck, FiMoreVertical } from 'react-icons/fi';
import { Popover } from '@headlessui/react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { checkStatus, STATUSES } from '../../shared/purchaseOrders/index.js';
import { useAccount } from '../AccountProvider.jsx';
import Button from '../Components/Button.jsx';
import Milestone from '../Components/Milestone.jsx';

const TEST_PROMPT = gql`
  mutation TestPrompt(
    $email: EmailAddress!
    $resourceId: String!
    $resourceType: PromptResourceType!
    $milestone: String!
  ) {
    testPrompt(
      email: $email
      resourceId: $resourceId
      resourceType: $resourceType
      milestone: $milestone
    ) {
      _id
      milestone
    }
  }
`;

const lowerCaseFirst = (str) => str.charAt(0).toLowerCase() + str.slice(1);

const PromptTestForm = ({ milestones, email, resource }) => {
  const emailInput = useRef();

  const [sendPrompt, { loading, called: emailSent }] = useMutation(TEST_PROMPT);
  const [milestone, setMilestone] = useState(milestones[0]);

  const handleSubmit = () => {
    if (!emailInput.current.checkValidity()) return;

    const resourceType = lowerCaseFirst(resource.__typename);

    sendPrompt({
      variables: {
        email: emailInput.current.value,
        milestone,
        resourceId: resource._id,
        resourceType,
      },
    });
  };

  return (
    <Popover className="relative -mr-3 -mt-2 ml-auto">
      <Popover.Button className="p-2">
        <FiMoreVertical />
      </Popover.Button>
      <Popover.Panel className="absolute right-0 z-10 w-[350px] overflow-hidden rounded-lg border border-midnight-10 bg-white shadow-lg">
        <article className="p-4 font-medium">
          <h2 className="text-base font-bold">
            Test <span className="uppercase">{milestone.toLowerCase()}</span> prompt
          </h2>
          <p className="mt-2 text-sm leading-normal opacity-75">
            If you change a date or click on action buttons in the prompt, you will update the PO.{' '}
            <br />
            Be careful and use this for visual testing ONLY.
          </p>
          <div className="-mx-4 -mb-4 mt-4 bg-purple-5 p-4">
            {milestones.length > 1 && (
              <div className="mt-1 flex space-x-4">
                {milestones.map((m) => (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label className="inline-flex items-center space-x-2 text-sm opacity-75" key={m}>
                    <input
                      type="radio"
                      name="milestone"
                      className="text-purple-100 focus:ring-purple-100"
                      value={m}
                      onChange={() => setMilestone(m)}
                      checked={milestone === m}
                    />
                    <span>{m}</span>
                  </label>
                ))}
              </div>
            )}
            <div className="mt-3 flex items-center space-x-2">
              <input
                type="email"
                name="email"
                className="flex-1 rounded border border-midnight-10 px-2 py-1 text-xs focus:border-transparent focus:ring-2 focus:ring-purple-100"
                defaultValue={email}
                placeholder="Your e-mail address..."
                ref={emailInput}
              />
              {emailSent ? (
                <p className="flex w-[105px] items-center justify-center space-x-1 text-xs font-bold text-leafy-85">
                  <span className="leading-7">Email sent</span>
                  <FiCheck size={16} />
                </p>
              ) : (
                <Button
                  onClick={handleSubmit}
                  type="button"
                  className="w-[105px] !bg-purple-5 !px-3 !transition-colors !duration-200 !ease-out hover:bg-purple-75"
                  label={loading ? 'Sending' : 'Send prompt'}
                  disabled={loading}
                  variant="text"
                  slim
                />
              )}
            </div>
          </div>
        </article>
      </Popover.Panel>
    </Popover>
  );
};

PromptTestForm.propTypes = {
  milestones: PropTypes.arrayOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
  resource: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }).isRequired,
};

const POMilestone = ({ milestone, resource, resourceStatus, validateDate, isReadOnly = false }) => {
  const { user } = useAccount();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const description =
    typeof milestone.description === 'function'
      ? milestone.description(resource)
      : milestone.description;

  const inputRequired =
    typeof milestone?.input?.required === 'function'
      ? milestone.input.required(resource)
      : milestone?.input?.required ?? false;

  const { isActiveOrCompleted, isCompleted } = useMemo(
    () => checkStatus(resourceStatus ?? resource.status),
    [resourceStatus, resource.status],
  );

  const showPrompt = user.isAdmin && milestone.prompts && milestone.prompts.length > 0;

  return (
    <Milestone
      completed={isActiveOrCompleted(milestone.key)}
      className="flex h-full min-w-[140px] flex-col"
      key={milestone.key}
    >
      <Milestone.Title className="flex">
        <span className="shrink-0">
          {milestone.input?.name === 'expectedDeliveryDate' ? 'Expected' : STATUSES[milestone.key]}
        </span>
        {showPrompt && (
          <PromptTestForm milestones={milestone.prompts} email={user.email} resource={resource} />
        )}
      </Milestone.Title>
      <Milestone.Description className="mb-2 mt-4">{description}</Milestone.Description>
      {milestone.input && (
        <div className="mt-auto">
          <input
            className={classnames(
              'w-full rounded-lg border-midnight-10 px-1 text-xs font-bold tracking-tighter text-midnight-100/75 focus:border-midnight-10 focus:ring-2 focus:ring-purple-100',
              errors[milestone.input.name] && 'input-error',
              (isReadOnly || isCompleted(milestone.key) || milestone.input.readOnly) &&
                '-ml-1.5 border-transparent',
            )}
            type="date"
            min={
              validateDate ? resource.issuedAt || new Date().toISOString().slice(0, 10) : undefined
            }
            {...register(milestone.input.name, { required: inputRequired })}
            disabled={isReadOnly || isCompleted(milestone.key) || milestone.input.readOnly}
          />
        </div>
      )}
    </Milestone>
  );
};

POMilestone.propTypes = {
  milestone: PropTypes.shape({
    key: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      readOnly: PropTypes.bool,
    }),
    prompts: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  resource: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    issuedAt: PropTypes.string,
  }).isRequired,
  isReadOnly: PropTypes.bool,
  resourceStatus: PropTypes.string,
  validateDate: PropTypes.bool,
};

POMilestone.defaultProps = {
  isReadOnly: false,
  resourceStatus: null,
  validateDate: false,
};

export default POMilestone;
