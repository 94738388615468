import React from 'react';
import PropTypes from 'prop-types';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAccount } from '../../../AccountProvider.jsx';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RevenueGraph = ({ revenueChartData }) => {
  const { account } = useAccount();
  const currencyOptions = {
    style: 'currency',
    currency: account.preferences?.currency || 'USD',
    notation: 'compact',
    maximumFractionDigits: 2,
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      revenue: {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
        title: {
          display: true,
          text: 'Revenue',
        },
        ticks: {
          callback(value) {
            return value.toLocaleString(undefined, {
              ...currencyOptions,
              maximumFractionDigits: 0,
            });
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataset, raw } = context;
            return `${dataset.label}: ${raw.toLocaleString(navigator.language, currencyOptions)}`;
          },
        },
      },
    },
  };

  const labels = revenueChartData?.map((item) => item.name);
  const sales = revenueChartData?.map((item) => item.monthlySales);

  const graphData = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        data: sales,
        borderColor: 'rgb(122, 77, 255)',
        backgroundColor: 'rgb(122, 77, 255)',
        borderRadius: 3,
      },
    ],
  };

  return <Bar options={options} data={graphData} />;
};

RevenueGraph.propTypes = {
  revenueChartData: PropTypes.arrayOf(
    PropTypes.shape({
      monthlySales: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default RevenueGraph;
