import React from 'react';
import PropTypes from 'prop-types';

const DaysLeft = ({ daysLeft }) => {
  if (daysLeft === null) return <>-</>;

  const days = parseInt(daysLeft, 10);

  if (days > 365) {
    return <>&gt; 1 year</>;
  }

  return (
    <>
      {Math.max(0, days)} day{days === 1 ? '' : 's'}
    </>
  );
};

DaysLeft.propTypes = {
  daysLeft: PropTypes.number,
};

DaysLeft.defaultProps = {
  daysLeft: null,
};

export default DaysLeft;
