import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import cogsyLogo from '../../public/cogsy-logo-on-white.svg';
import Button from '../Components/Button.jsx';
import InputErrorMessage from '../Components/InputErrorMessage.jsx';
import Toast from '../Components/Toast.jsx';

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'all' });
  const { token } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [requestComplete, setRequestComplete] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const doPasswordsMatch = (password) => {
    const formData = getValues();
    return password === formData.password || 'Passwords do not match';
  };

  const requestPasswordReset = async (formData) => {
    setLoading(true);
    const data = {
      token,
      password: formData.password,
    };
    try {
      await axios('/auth/reset_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      });
      setLoading(false);
      setRequestComplete(true);
    } catch (e) {
      setLoading(false);
      setIsError(true);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <main className="mx-auto mt-20 w-full max-w-md text-midnight-100">
      <div>
        <img src={cogsyLogo} alt="Cogsy Logo" className="mx-auto" />
      </div>

      {requestComplete && (
        <>
          <h1 className="mt-20 text-3xl">Password Changed!</h1>
          <div className="mt-4 text-xs">
            You have successfully reset your password. Please login again.
          </div>
        </>
      )}
      {!requestComplete && (
        <>
          <h1 className="mt-20 text-3xl">Reset Password</h1>
          {isError && (
            <Toast open level="error" onClose={() => setIsError(false)}>
              You have used invalid link, please request for new link to reset your password.
            </Toast>
          )}
          <form className="mt-8" noValidate onSubmit={handleSubmit(requestPasswordReset)}>
            <div>
              <label htmlFor="password" className="text-xs">
                New Password*
                <input
                  type="password"
                  id="password"
                  placeholder="Your password"
                  className={cn('input', errors.password && 'input-error')}
                  {...register('password', {
                    required: 'Please provide your new password',
                    minLength: 8,
                  })}
                />
              </label>
              <InputErrorMessage message={errors.password?.message} />
            </div>
            <div className="mt-4">
              <label htmlFor="confirmPassword" className="text-xs">
                Confirm New Password*
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm password"
                  className={cn('input', errors.confirmPassword && 'input-error')}
                  {...register('confirmPassword', {
                    required: 'Please confirm your password',
                    validate: doPasswordsMatch,
                  })}
                />
              </label>
              <InputErrorMessage message={errors.confirmPassword?.message} />
            </div>
            <div className="mt-8 space-y-4">
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                label={loading ? 'Please hold...' : 'Save Password'}
              />
            </div>
          </form>
        </>
      )}
      <div className="mt-4 text-center text-xs">
        <Button href="/login" fullWidth variant="tertiary" label="Go back to login" />
      </div>
    </main>
  );
};

export default ResetPassword;
