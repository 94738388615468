import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FiCheck, FiChevronDown } from 'react-icons/fi';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import marketplaces from '../../../shared/amazon/marketplaces.js';
import Button from '../../Components/Button.jsx';

const CREATE_AMAZON_REDIRECT_URL = gql`
  mutation CreateAmazonRedirectUrl($sellerCentralUrl: String!) {
    createAmazonRedirectUrl(sellerCentralUrl: $sellerCentralUrl) {
      redirectUrl
    }
  }
`;

const AmazonPopup = ({ open, onClose }) => {
  const [createAmazonRedirectUrl, { loading }] = useMutation(CREATE_AMAZON_REDIRECT_URL);
  const [selectedMarketplace, setSelectedMarketplace] = useState(
    marketplaces.find((marketplace) => marketplace.id === 'ATVPDKIKX0DER'),
  );
  const [queryMarketplace, setQueryMarketplace] = useState('');

  const handleAmazonConnect = React.useCallback(
    (e) => {
      e.preventDefault();
      const doConnect = async () => {
        const sellerCentralUrl = selectedMarketplace.sellerCentralUrl;

        const {
          data: {
            createAmazonRedirectUrl: { redirectUrl },
          },
        } = await createAmazonRedirectUrl({ variables: { sellerCentralUrl } });

        window.localStorage.setItem('marketPlace', JSON.stringify(selectedMarketplace));
        window.location.assign(redirectUrl);
      };

      doConnect();
    },
    [selectedMarketplace, open],
  );

  const filteredMarketplaces =
    queryMarketplace === ''
      ? marketplaces
      : marketplaces.filter((marketplace) =>
          marketplace.name.toLowerCase().includes(queryMarketplace?.toLowerCase()),
        );

  const onSearchQuery = (e) => setQueryMarketplace(e?.target?.value || '');

  const onSelectMarketplace = (value) => setSelectedMarketplace(value);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 overflow-y-auto text-midnight-100"
      style={{ fontFamily: 'Gilroy, sans-serif' }}
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="z-10 mx-auto w-full max-w-md rounded bg-gray-10 p-12 shadow">
          <Dialog.Title className="text-xl">Connect with Amazon</Dialog.Title>

          <form className="mt-4 text-xs" onSubmit={handleAmazonConnect}>
            <div>
              <p className="mt-2 text-gray-100">Select your marketplace location</p>
              <br />
              <Combobox value={selectedMarketplace} onChange={onSelectMarketplace}>
                {({ open }) => (
                  <div className="relative mt-1">
                    <Combobox.Input
                      className="input text-sm"
                      displayValue={(marketplace) => marketplace.name}
                      onChange={onSearchQuery}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <FiChevronDown
                        className={cn(
                          'ml-2 text-purple-100 transition-transform duration-200 ease-out w-5 h-5',
                          open && '-rotate-180',
                        )}
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => onSearchQuery('')}
                    >
                      <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredMarketplaces.length === 0 && queryMarketplace !== '' ? (
                          <div className="relative cursor-default select-none px-4 py-2">
                            Nothing found.
                          </div>
                        ) : (
                          filteredMarketplaces.map((marketplace) => (
                            <Combobox.Option
                              key={marketplace.id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active ? 'text-white bg-purple-100' : 'text-midnight-100'
                                }`
                              }
                              value={marketplace}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {marketplace.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                        active ? 'text-white' : 'text-midnight-100'
                                      }`}
                                    >
                                      <FiCheck className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                )}
              </Combobox>
            </div>
            <div className="mt-8 flex items-center justify-end space-x-2">
              <Button label="Cancel" variant="text" onClick={onClose} slim />
              <Button
                type="submit"
                label={loading ? 'Please wait...' : 'Connect'}
                disabled={loading}
                slim
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

AmazonPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AmazonPopup;
